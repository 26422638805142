import React, { Component } from "react";
import ReactTable from "react-table";
import "react-table/react-table.css";
import { PopUpPortal } from "../../containers/popups/PopUpPortal";
import saveAs from "save-as";
import { ReattemptTestService } from "../../services/reattemptTest.service";
import JSZipUtils from "jszip-utils";
import Finxlogo from "../../assets/images/dapIcon.png";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import Select, { createFilter } from "react-select";
import {
  success,
  successNotification,
  error,
  errorNotification,
  warning,
  warningNotification,
} from "../notification/notifications";
import jsPDF from "jspdf";
import { ExcelService } from "../Excel/excel";
import Modal from "react-responsive-modal";
import "jspdf-autotable";
import { RegionManagementService } from "../../services/regionManagement.service";
import { LookUpService } from "../../services/lookupService";
import { LoginService } from "../../services/login.service";
import { RegisterUserService } from "../../services/registration.service";
import * as moment from "moment";
import { Warning } from "@material-ui/icons";
import FileBase64 from "react-file-base64";
import { updateStatus } from "../../services/preAssessment.Service";
import { CSVLink, CSVDownload } from "react-csv";
import { Dropdown } from "react-bootstrap";
import { AssessmentReport } from "../../services/fetchAssessmentReport";
import ciellogo from "../../assets/images/ciel-logo.png";
import CielSignature from "../../assets/images/ciel-sign.png";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import { Global_var } from "../../global/global_var";
// import {  } from "fusioncharts";
// import { confirmAlert } from 'react-confirm-alert'; // Import
// import 'react-confirm-alert/src/react-confirm-alert.css';
var JSZip = require("jszip");
class dateRequest extends Component {
  constructor(props) {
    super(props);
    if (localStorage.getItem("login") !== null) {
      this.state = {
        loading: false,
        dataChange1:"",
        selected: {},
        selectAll: 0,
        data: [],
        productData: [],
        stateListForPer: [],
        lookingForList: [],
        action: "",
        selected1: {},
        dataChange: [],
        mailData: [],
        cityList: [],
        centerList: [],
        centerListNew: [],
        acceptFlag: false,
        examDay:"",
        examMonth: "",
        examYear:"",
        stateName: "",
        acceptData: [],
        AcceptdataChange: [],
        acceptStatusData: [],
        acceptDataUpload: [],
        AcceptmailData: [],
        isConfirm: false,
        Scoredata: [],
        loading1: "none",

        listOfYear1: [],
        listOfYear2: [],
        listOfMonth: [],
        listOfDay: [],
      };
    } else {
      window.location.href = "/";
    }
  }
  titleCase(str) {
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }
  componentDidMount() {
    // console.log("this.props.attemptedList::::: ", this.props.attemptedList)
    var year = new Date().getFullYear();
    year = year + 1;
    var listOfYear = [];
    for (var i = 0; i < 13; i++) {
      var current = year - i;
      listOfYear.push(current);
    }

    var listOfYear1 = [];
    for (var i1 = 0; i1 < 2; i1++) {
      var current1 = year + i1;
      // console.log(current1)
      listOfYear1.push(current1);
    }
    // var listOfMonth = [];

    var listOfMonth = [
      { key: 1, value: "Jan" },
      { key: 2, value: "Feb" },
      { key: 3, value: "Mar" },
      { key: 4, value: "Apr" },
      { key: 5, value: "May" },
      { key: 6, value: "Jun" },
      { key: 7, value: "Jul" },
      { key: 8, value: "Aug" },
      { key: 9, value: "Sep" },
      { key: 10, value: "Oct" },
      { key: 11, value: "Nov" },
      { key: 12, value: "Dec" },
    ];

    var listOfDay = [];
    for (var i = 1; i <= 31; i++) {
      //    var current = year - i;
      listOfDay.push(i);
    }
    this.setState({ listOfMonth: listOfMonth });
    this.setState({ listOfYear1: listOfYear });
    this.setState({ listOfYear2: listOfYear1 });
    this.setState({ listOfDay: listOfDay });

    LoginService.TokenUser((res) => {
      localStorage.setItem("jwt-token", res.responseObject);
      //    localStorage.setItem("jti-token", res.responseObject);
      RegionManagementService.getCity("", (res) => {
        // let city = []
        // for (let i = 0; i < res.responseListObject.length; i++) {
        //     let obj = {}
        //     obj['value'] = res.responseListObject[i].cityId
        //     obj['label'] = res.responseListObject[i].cityName
        //     city.push(obj)
        // }
        this.setState({
          //  cityList: city,
          allCityList: res.responseListObject,
        });
      });
      RegionManagementService.getState("countryId=1", (res) => {
        let city = [];
        for (let i = 0; i < res.responseListObject.length; i++) {
          let obj = {};
          obj["value"] = res.responseListObject[i].stateId;
          obj["label"] = this.titleCase(res.responseListObject[i].stateName);
          city.push(obj);
        }
        this.setState({
          cityList: city,
          stateListForPer: res.responseListObject,
        });
      });
    });

    LookUpService.getLookingFor("codeTypeId=100", (res) => {
      this.setState({ lookingForList: res.responseListObject });
    });

    //The function is called

    let product = [];
    var productdata = this.props.fetchUserAddressData;
    if (productdata) {
      for (let i = 0; i < productdata.length; i++) {
        let obj = {};
        obj["value"] = productdata[i].productID;
        obj["label"] = productdata[i].productName;
        product.push(obj);
      }
      this.setState({
        productData: product,
      });
    }
  }

  toggleSelectAll() {
    let newSelected = {};
    let data = [];
    if (this.state.selectAll === 0) {
      this.props.attemptedList.forEach((x) => {
        let obj = {};
        let temp = {
          // "action": x.executionTracker[0].action,
          action: "CANCEL",
          workflowId: XMLDocument.learnerID,
          additionalDetails: {
            stateId: x.executionTracker
              ? x.executionTracker[0].additionalDetails.stateId
              : "",
            center: x.executionTracker
              ? x.executionTracker[0].additionalDetails.centre
              : "",
            flag: x.executionTracker
              ? x.executionTracker[0].additionalDetails.flag
              : "",
            //   "isconfirmbycilopps": x.executionTracker ? x.executionTracker[0].additionalDetails.isconfirmbycilopps : '',
            requestedDate: x.executionTracker
              ? x.executionTracker[0].additionalDetails.RequestedDat.split(
                  " "
                )[0]
              : "",
            //     "fromTime": "",
            //     "toTime": "",
            //     "stateName": original.executionTracker ? original.executionTracker[0].additionalDetails.stateName : '',
            // }
          },
        };
        data.push(obj);
      });
    }

    this.setState({
      selected: newSelected,
      selectAll: this.state.selectAll === 0 ? 1 : 0,
      data: data,
    });
  }

  filterCfxReport(emailId, productID) {
    LoginService.TokenUser((res) => {
      localStorage.setItem("jwt-token", res.responseObject);
      //let params = "emailid=dapsachin@test.com" //this.props.match.params.id
      let params = "emailid=" + emailId;
      params += "&productid=" + productID; // this.props.match.params.prodid //"&productid=1"; //

      let cfxDataArray = [];
      AssessmentReport.fetchCfxAssessmentReport(
        params,
        (res) => {
          //console.log(res);
          if (res.reasonText !== "Record not found") {
            res.responseListObject.forEach((element) => {
              let cfxData = {
                learnerReportId: element.learnerReportId,
                learneremailid: element.emailid,
                learnerlearningid: element.learningid,
                learnerlearningName: element.learningName,
                learnerlearningProgress: element.learningProgress,
                //  learnerlearningCompletionDate: element.learningCompletionDate != null ? moment(element.learningCompletionDate).format("DD-MM-YYYY") : "",
                learnerlearningStatus: element.learningStatus,
                learnerlearningType: element.learningType,
                learnerproductid: element.productid,
                learnernode: JSON.parse(element.node),
              };
              cfxDataArray.push(cfxData);
            });
            this.setState({ Scoredata: cfxDataArray });
            window.$("#progressBarPopup").modal("show");
          } else {
            // window.$('#progressBarPopup').modal('show')
            warning(res.reasonText, warningNotification);
          }
        },
        (error) => {
          console.log(error);
        }
      );
    });
  }
  toggleRow(original) {
    // emailID
    var ceildata2 = localStorage.getItem("PersonnelData");
    let data = this.state.data;
    let temp_data = [];
    let AcceptmailData = this.state.AcceptmailData;
    let obj = {};
    let mailData = this.state.mailData;
    const newSelected = Object.assign({}, this.state.selected);
    newSelected[original.learnerID] = !this.state.selected[original.learnerID];
    //data.push(original)
    // this.setState({
    //     selected: newSelected,

    // });
    let newDate = "";
    let fromTime = "";
    let toTime = "";
    let uploadData = "";
    // if (original.action == "ACCEPT") {
    //     this.state.AcceptdataChange.length > 0 && this.state.AcceptdataChange.map(e => {
    //
    //         if (e.learnerID == original.learnerID) {
    //             uploadData = e.docId
    //             // fromTime = e.fromTime,
    //             // toTime = e.toTime
    //         }
    //         //  alert(newDate)
    //     })

    // }
    if (original.action == "ACCEPT") {
      this.state.acceptStatusData.length > 0 &&
        this.state.acceptStatusData.map((e) => {
          if (e.learnerID == original.learnerID) {
            fromTime = e.fromTime;
            //toTime = e.toTime
          }
          //  alert(newDate)
        });
    }
    if (original.action == "ACCEPT") {
      this.state.acceptStatusData.length > 0 &&
        this.state.acceptStatusData.map((e) => {
          if (e.learnerID == original.learnerID) {
            //fromTime = e.fromTime,
            toTime = e.toTime;
          }
          //  alert(newDate)
        });
    }
    if (original.action == "CHANGE") {
      mailData.map((e) => {
        if (e.learnerId == original.learnerID) {
          newDate = e.selectedNewDate;
          // fromTime = e.fromTime,
          // toTime = e.toTime
        }
        //  alert(newDate)
      });
    }
    if (original.action == "CHANGE") {
      mailData.map((e) => {
        if (e.learnerId == original.learnerID) {
          //newDate = e.selectedNewDate
          // fromTime = e.fromTime,
          toTime = e.toTime;
        }
        //  alert(newDate)
      });
    }

    if (original.action == "CHANGE") {
      mailData.map((e) => {
        if (e.learnerId == original.learnerID) {
          //   newDate = e.selectedNewDate
          fromTime = e.fromTime;
          // toTime = e.toTime
        }
        //  alert(newDate)
      });
    }

    if (data.length > 0) {
      for (let i = 0; i < this.state.data.length; i++) {
        if (data[i].workflowId == original.learnerID) {
          data.splice(i, 1);
        } else {
          let obj1 = {
            userName: original.firstName,
            panNumber: original.panNumber == null ? "-" : original.panNumber,
            emailId: original.emailID,
            templateId: "1960260",
            mobileNumber: original.mobileNo,
            status:
              original.action == "ACCEPT"
                ? "NISM_Exam_confirm"
                : original.action == "CANCEL"
                ? "Cancel_NISM_exam"
                : "",
            location: original.executionTracker[0].additionalDetails.center,
            selectedDate:
              original.executionTracker[0].additionalDetails.requestedDate,
            partyId: original.learnerPartyID,
            ceilopsPartyId: JSON.parse(localStorage.login).partyId,
          };

          let obj2 = {
            userName: original.firstName,

            emailId: original.emailID,
            templateId: "1960460",
            status: "NISM_exam_date_accepted",
            selectedDate:
              original.executionTracker[0].additionalDetails.requestedDate,
            partyId: original.learnerPartyID,
          };

          let temp = {
            action: "NISM PROFILE CREATE",
            // original.executionTracker[0].action,
            workflowId: original.learnerID,
            additionalDetails: {
              action: original.action,
              stateId: original.executionTracker
                ? original.executionTracker[0].additionalDetails.stateId
                : "",
              center: original.executionTracker
                ? original.executionTracker[0].additionalDetails.center
                : "",
              flag: original.executionTracker
                ? original.executionTracker[0].additionalDetails.flag
                : "",
              //  "isconfirmbycilopps": original.executionTracker ? original.executionTracker[0].additionalDetails.isconfirmbycilopps : '',
              // "requestedDate":
              location: original.executionTracker[0].additionalDetails.center,
              requestedDate:
                newDate !== ""
                  ? newDate
                  : original.executionTracker
                  ? original.executionTracker[0].additionalDetails.requestedDate.split(
                      " "
                    )[0]
                  : "",
              fromTime: fromTime,
              toTime: toTime,
              stateName: original.executionTracker
                ? original.executionTracker[0].additionalDetails.stateName
                : "",
              admitCardDocId: uploadData,
            },
          };
          //executionTracker[0].additionalDetails.requestedDate
          // obj['emailId'] = original.emailID
          // obj['userName'] = original.emailID
          // obj['assessmentModuleID'] = original.assessmentModuleID
          // obj['moduleScore'] = original.moduleScore
          // obj['productID'] = original.productID
          // obj['productModuleID'] = original.productModuleID
          // obj['learnerPartyID'] = original.learnerPartyID
          // obj['overallModuleScore'] = original.overallModuleScore
          temp_data.push(temp);
          if (original.action !== "CHANGE") {
            mailData.push(obj1);
          }
          if (original.action === "ACCEPT") {
            AcceptmailData.push(obj2);
          }
        }
      }
    } else {
      let obj2 = {
        userName: original.firstName,

        emailId: original.emailID,
        templateId: "1960460",
        status: "NISM_exam_date_accepted",
        selectedDate:
          original.executionTracker[0].additionalDetails.requestedDate,
        partyId: original.learnerPartyID,
      };
      let obj1 = {
        userName: original.firstName,
        panNumber: original.panNumber == null ? "-" : original.panNumber,
        emailId: original.emailID,
        templateId: "1960260",
        mobileNumber: original.mobileNo,
        status:
          original.action == "ACCEPT"
            ? "NISM_Exam_confirm"
            : original.action == "CANCEL"
            ? "Cancel_NISM_exam"
            : "",
        location: original.executionTracker[0].additionalDetails.center,
        selectedDate:
          original.executionTracker[0].additionalDetails.requestedDate,
        partyId: original.learnerPartyID,
        ceilopsPartyId: JSON.parse(localStorage.login).partyId,
        //  "selectedDate": newDate !== "" ? newDate : original.executionTracker[0].additionalDetails.requestedDate
      };

      let temp = {
        action: "NISM PROFILE CREATE",
        // "action": original.executionTracker[0].action,
        workflowId: original.learnerID,
        additionalDetails: {
          action: original.action,
          stateId: original.executionTracker
            ? original.executionTracker[0].additionalDetails.stateId
            : "",
          center: original.executionTracker
            ? original.executionTracker[0].additionalDetails.center
            : "",
          flag: original.executionTracker
            ? original.executionTracker[0].additionalDetails.flag
            : "",

          //  "isconfirmbycilopps": original.executionTracker ? original.executionTracker[0].additionalDetails.isconfirmbycilopps : '',
          //  "requestedDate": original.executionTracker ? original.executionTracker[0].additionalDetails.requestedDate.split(" ")[0] : '',
          requestedDate:
            newDate !== ""
              ? newDate
              : original.executionTracker
              ? original.executionTracker[0].additionalDetails.requestedDate.split(
                  " "
                )[0]
              : "",
          fromTime: fromTime,
          toTime: toTime,
          stateName: original.executionTracker
            ? original.executionTracker[0].additionalDetails.stateName
            : "",
          admitCardDocId: uploadData,
        },
      };
      temp_data.push(temp);
      if (original.action !== "CHANGE") {
        mailData.push(obj1);
      }
      if (original.action === "ACCEPT") {
        AcceptmailData.push(obj2);
      }
    }
    let d1 = data.concat(temp_data);
    this.setState({
      selected: newSelected,
      data: d1,
      selectAll: 2,
    });
    if (original.action !== "CHANGE") {
      this.setState({
        mailData: mailData,
        AcceptmailData: AcceptmailData,
      });
    }
  }

  renderEditable = (cellInfo) => {
    let currentValue = "";
    if (cellInfo) {
      if (cellInfo.original.executionTracker.length > 0) {
        let e = cellInfo.original.executionTracker;
        for (let i = 0; i < e.length; i++) {
          // for (let i = e.length - 1; i >= 0; i--) {
          if (e[i].hasOwnProperty("action")) {
            // debugger
            //alert("e[i].action"+e[i].action)
            // if (e[i].action == "NISM PROFILE CREATE") {
            if (
              e[i].action == "PROFILE COMPLETE" ||
              e[i].action == "NISM VA SUBSCRIBE" ||
              e[i].action == "NISM VA PAYMENT COMPLETE" ||
              e[i].action == "NISM PROFILE CREATE"
            ) {
              if (
                e[i].additionalDetails &&
                e[i].additionalDetails.hasOwnProperty("action")
              ) {
                currentValue = e[i].additionalDetails.action;
              }
              // else {
              //     return ('')
              // }
            }
          } else {
            return "";
          }
        }
      } else {
        currentValue = "";
      }
      //     let currentValue = cellInfo.original.executionTracker ? cellInfo.original.executionTracker[0].additionalDetails.hasOwnProperty('action') === false ? "" : cellInfo.original.executionTracker[0].additionalDetails.action ? cellInfo.original.executionTracker[0].additionalDetails.action : '' : ''
      //debugger;
      const cellValue =
        this.props.attemptedList === null
          ? ""
          : this.props.attemptedList[cellInfo.index] === undefined
          ? ""
          : this.props.attemptedList[cellInfo.index][cellInfo.column.id] ==
            undefined
          ? currentValue
          : this.props.attemptedList[cellInfo.index][cellInfo.column.id];

      return (
        <div>
          <select
            name="action"
            id="action"
            value={cellValue}
            onChange={this.handleInputChange.bind(null, cellInfo)}
            //  onClick={e => { this.handleInputChange.bind(null, cellInfo) }}

            disabled={currentValue ? true : false}
          >
            <option value="">Select</option>
            <option value="ACCEPT">Accept</option>
            <option value="CHANGE">Change</option>
            <option value="CANCEL">Cancel</option>
          </select>
          {/* <i class="fa fa-ellipsis-h actionIcon" aria-hidden="true"></i> */}
        </div>
      );
    }
    // else {
    //     return ('')
    // }
  };
  handleInputChange = (cellInfo, event) => {
    // debugger
    //alert("hhh")
    let temp = [];
    let cancelData = [];
    let data1 = [...this.props.attemptedList];
    data1[cellInfo.index][cellInfo.column.id] = event.target.value;
    //   console.log(data1)
    temp.push(cellInfo.original);
    let temp2 = [];

    temp[0].executionTracker.map((e) => {
      // if (e.action == "NISM PROFILE CREATE") {
      if (
        e.action == "PROFILE COMPLETE" ||
        e.action == "NISM VA SUBSCRIBE" ||
        e.action == "NISM VA PAYMENT COMPLETE" ||
        e.action == "NISM PROFILE CREATE"
      ) {
        temp2.push(e);
      }
    });
    temp[0].executionTracker.map((e) => {
      //if (e.action == "NISM PROFILE CREATE") {
      if (
        e.action == "PROFILE COMPLETE" ||
        e.action == "NISM VA SUBSCRIBE" ||
        e.action == "NISM VA PAYMENT COMPLETE" ||
        e.action == "NISM PROFILE CREATE"
      ) {
        cancelData.push(e);
      }
    });
    let temp3 = [];
    temp2.map((e) => {
      if (e.hasOwnProperty("additionalDetails")) {
        temp3.push(e);
      }
    });
    this.setState({
      dataChange: temp,
      dataChange1: temp3,
    });
    // this.setState({ dataChange: temp });
    console.log(this.state.dataChange);
    if (event.target.value == "CHANGE") {
      window.$("#changeStatus").modal("show");
    }
    if (event.target.value == "ACCEPT") {
      // this.setState({
      //     acceptData: temp
      // })
      let temp1 = [];
      temp[0].executionTracker.map((e) => {
        //if (e.action == "NISM PROFILE CREATE") {
        if (
          e.action == "PROFILE COMPLETE" ||
          e.action == "NISM VA SUBSCRIBE" ||
          e.action == "NISM VA PAYMENT COMPLETE" ||
          e.action == "NISM PROFILE CREATE"
        ) {
          temp1.push(e);
        }
      });
      // debugger
      let temp4 = [];
      let loc = "";
      let stateName = "";
      temp1.map((e) => {
        if (e.hasOwnProperty("additionalDetails")) {
          temp4.push(e);
          loc = e.additionalDetails.stateId;
          stateName = e.additionalDetails.stateName;
          this.setState({
            acceptFlag: e.additionalDetails.flag,
          });
        }
      });
      this.setState({
        acceptData1: temp,
      });

      this.setState({
        acceptData: temp4,
      });
      this.setState({
        stateName: stateName,
      });

      RegionManagementService.getCenter("stateId=" + loc, (res) => {
        if (res.reasonText === "get state data successfully") {
          this.setState({
            centerListNew: res.responseListObject,
          });
        } else {
          this.setState({
            centerListNew: [],
          });
        }
      });

      setTimeout(function () {
        window.$("#AcceptchangeStatus").modal("show");
      }, 1500);
    }

    let original = cellInfo.original;
    if (event.target.value == "CANCEL") {
      var txt = "Are you sure you want to cancel this request?";
      var r = window.confirm(txt);

      if (r == true) {
        this.setState({
          loading: true,
        });

        let obj = [
          {
            userName: original.firstName,
            panNumber: original.panNumber == null ? "-" : original.panNumber,
            emailId: original.emailID,
            templateId: "1960290",
            mobileNumber: original.mobileNo,
            status: "Cancel_NISM_exam",
            location: cancelData[0].additionalDetails.center,
            selectedDate: moment(
              cancelData[0].additionalDetails.requestedDate
            ).format("YYYY-MM-DD"),
            partyId: original.learnerPartyID,
            ceilopsPartyId: JSON.parse(localStorage.login).partyId,

            //    "fromTime":cancelData[0].executionTracker ? cancelData[0].executionTracker[0].additionalDetails.fromTime : '',
            //    "toTime": cancelData[0].executionTracker ? cancelData[0].executionTracker[0].additionalDetails.toTime : '',
            time: cancelData[0].executionTracker
              ? cancelData[0].executionTracker[0].additionalDetails.fromTime
              : +"-" + cancelData[0].executionTracker
              ? cancelData[0].executionTracker[0].additionalDetails.toTime
              : "",
            //  "selectedDate": newDate !== "" ? newDate : original.executionTracker[0].additionalDetails.requestedDate
          },
        ];

        let smsCancel = [
          {
            userName: original.firstName,
            templateId: "40090",
            mobileNumber: original.mobileNo,
            status: "Cancel_NISM_exam",
            partyId: original.learnerPartyID,
          },
        ];

        let temp = {
          action: "NISM PROFILE CREATE",
          // "action": original.executionTracker[0].action,
          workflowId: original.learnerID,
          additionalDetails: {
            action: original.action,
            stateId: original.executionTracker
              ? cancelData[0].additionalDetails.stateId
              : "",
            center: original.executionTracker
              ? cancelData[0].additionalDetails.center
              : "",
            flag: original.executionTracker
              ? cancelData[0].additionalDetails.flag
              : "",

            //  "isconfirmbycilopps": original.executionTracker ? original.executionTracker[0].additionalDetails.isconfirmbycilopps : '',
            //  "requestedDate": original.executionTracker ? original.executionTracker[0].additionalDetails.requestedDate.split(" ")[0] : '',
            requestedDate: original.executionTracker
              ? moment(
                  cancelData[0].additionalDetails.requestedDate.split(" ")[0]
                ).format("YYYY-MM-DD")
              : "",
            fromTime: "",
            toTime: "",
            stateName: original.executionTracker
              ? cancelData[0].additionalDetails.stateName
              : "",
            admitCardDocId: "",
          },
        };
        // let obj = [{

        //     "userName": original.firstName,
        //     "panNumber": original.panNumber == null ? "-" : original.panNumber,
        //     "emailId": original.emailID,
        //     "templateId": "1960260",
        //     "mobileNumber": original.mobileNo,
        //     "status": 'Cancel_NISM_exam',
        //     "location": original.executionTracker[0].additionalDetails.center,
        //     "selectedDate": original.executionTracker[0].additionalDetails.requestedDate,
        //     "partyId": original.learnerPartyID,
        //     "ceilopsPartyId": JSON.parse(localStorage.login).partyId
        //     //  "selectedDate": newDate !== "" ? newDate : original.executionTracker[0].additionalDetails.requestedDate

        // }]

        // let temp = {
        //     "action": "NISM PROFILE CREATE",
        //     // "action": original.executionTracker[0].action,
        //     "workflowId": original.learnerID,
        //     "additionalDetails": {
        //         "action": original.action,
        //         "stateId": original.executionTracker ? original.executionTracker[0].additionalDetails.stateId : '',
        //         "center": original.executionTracker ? original.executionTracker[0].additionalDetails.center : '',
        //         "flag": original.executionTracker ? original.executionTracker[0].additionalDetails.flag : '',

        //         //  "isconfirmbycilopps": original.executionTracker ? original.executionTracker[0].additionalDetails.isconfirmbycilopps : '',
        //         //  "requestedDate": original.executionTracker ? original.executionTracker[0].additionalDetails.requestedDate.split(" ")[0] : '',
        //         "requestedDate": original.executionTracker ? original.executionTracker[0].additionalDetails.requestedDate.split(" ")[0] : '',
        //         "fromTime": "",
        //         "toTime": "",
        //         "stateName": original.executionTracker ? original.executionTracker[0].additionalDetails.stateName : '',
        //         "admitCardDocId": ""
        //     }
        // }
        ReattemptTestService.UpdateWorkflow(temp, (res) => {
          success("Request Cancelled Successfully.", successNotification);
          setTimeout(() => {
            window.location.reload();
          }, 5000);

          this.setState({
            loading: false,
          });
          RegisterUserService.sendMail(obj, (res) => {
            //   this.setState({ dataChange: [] })
          });

          RegisterUserService.sendSms(smsCancel, (res3) => {
            //success("Feat completed successfully.", successNotification);
            // if(res3.status === "success"){
            //     success("Request Cancelled Successfully sms sent", successNotification);
            // } else {
            //     error("Request Cancelled Successfully sms failed .", errorNotification);
            // }
          });
        });
      }
    }
  };

  renderEditableDownload = (cellInfo) => {
    if (cellInfo) {
      //  let currentValue = cellInfo.original.executionTracker ? cellInfo.original.executionTracker[0].additionalDetails.hasOwnProperty('action') === false ? "" : cellInfo.original.executionTracker[0].additionalDetails.action ? cellInfo.original.executionTracker[0].additionalDetails.action : '' : ''
      const cellValue =
        this.props.attemptedList === null
          ? ""
          : this.props.attemptedList[cellInfo.index] === undefined
          ? ""
          : this.props.attemptedList[cellInfo.index][cellInfo.column.id] ==
            undefined
          ? ""
          : this.props.attemptedList[cellInfo.index][cellInfo.column.id];

      return (
        <div>
          <select
            name="download"
            id="download"
            value={cellValue}
            onChange={this.handleInputChangeDownload.bind(null, cellInfo)}
          >
            <option value="">Select</option>
            <option value="All">All</option>
            <option value="Pan">PAN</option>
            <option value="Aadhar">Aadhaar front part</option>
            <option value="Aadharback">Aadhaar back part</option>
          </select>
          {/* <i class="fa fa-ellipsis-h actionIcon" aria-hidden="true"></i> */}
        </div>
      );
    } else {
      return "";
    }
  };
  handleInputChangeDownload = (cellInfo, event) => {
    let temp = [];
    let data1 = [...this.props.attemptedList];
    data1[cellInfo.index][cellInfo.column.id] = event.target.value;
    temp.push(cellInfo.original);
    this.setState({ dataChange: temp });
    this.downloadURI(cellInfo.original, event.target.value);
    //   console.log(data1)
    // temp.push(cellInfo.original)
  };

  renderEditableUpload = (cellInfo) => {
    if (cellInfo) {
      let temp = [];
      //  let currentValue = cellInfo.original.executionTracker ? cellInfo.original.executionTracker[0].additionalDetails.hasOwnProperty('action') === false ? "" : cellInfo.original.executionTracker[0].additionalDetails.action ? cellInfo.original.executionTracker[0].additionalDetails.action : '' : ''
      //  const cellValue = this.props.attemptedList === null ? '' : this.props.attemptedList[cellInfo.index] === undefined ? "" : this.props.attemptedList[cellInfo.index][cellInfo.column.id] == undefined ? "" : this.props.attemptedList[cellInfo.index][cellInfo.column.id];
      let action = "";
      //  cellInfo.original.executionTracker.length > 0 ?
      //     cellInfo.original.executionTracker[0].additionalDetails.hasOwnProperty('action') === false ? '' :
      //         cellInfo.original.executionTracker[0].additionalDetails.action == "ACCEPT" || cellInfo.original.executionTracker[0].additionalDetails.action == "CHANGE" ? true : false : false

      // let currentValue = ""
      // if (cellInfo) {

      if (cellInfo.original.executionTracker.length > 0) {
        let e = cellInfo.original.executionTracker;
        //      alert(e.length)
        for (let i = 0; i < e.length; i++) {
          if (e[i].hasOwnProperty("action")) {
            if (
              e[i].action == "ADMIT CARD AVAILABLE" ||
              e[i].action == "NISM CERTIFICATION COMPLETE"
            ) {
              action = false;
            } else {
              if (e[i].action == "NISM PROFILE CREATE") {
                if (e[i].additionalDetails.hasOwnProperty("action")) {
                  if (
                    (e[i].additionalDetails.action == "ACCEPT" &&
                      e[i].additionalDetails.flag === false) ||
                    (e[i].additionalDetails.action == "CHANGE" &&
                      e[i].additionalDetails.flag == false)
                  ) {
                    action = true;
                  } else {
                    action = false;
                  }
                }
                // else {
                //     return ('')
                // }
              }
            }
          }
          // else {
          //     return ('')
          // }
        }
      }
      // alert(action)
      if (action == true) {
        return (
          <div>
            <a
              onClick={(e) => {
                temp.push(cellInfo.original);
                this.setState({
                  acceptDataUpload: temp,
                });
                window.$("#uploadAdmitCard").modal("show");
              }}
            >
              Upload File
            </a>

            {/* <select name="download" id="download" value={cellValue}
                        onChange={this.handleInputChangeUpload.bind(null, cellInfo)}
                    >
                        <option value="">Select</option>
                        <option value="All">All</option>
                        <option value="Pan">PAN</option>
                        <option value="Aadhar">Aadhar</option>
                    </ select>
                    <i class="fa fa-ellipsis-h actionIcon" aria-hidden="true"></i> */}
          </div>
        );
      } else {
        return <span>-</span>;
      }
    } else {
      return "";
    }
  };
  handleInputChangeUpload = (cellInfo, event) => {
    let temp = [];
    let data1 = [...this.props.attemptedList];
    data1[cellInfo.index][cellInfo.column.id] = event.target.value;
    temp.push(cellInfo.original);
    this.setState({ dataChange: temp });
    this.downloadURI(cellInfo.original, event.target.value);
    //   console.log(data1)
    // temp.push(cellInfo.original)
  };
  WhyTheFillForm = () => {
    this.setState({ linkModalloading: true, showWhyTheFillForm: true });
  };

  onWhyTheFillFormCloseModal = () => {
    this.setState({ linkModalloading: false, showWhyTheFillForm: false });
  };

  filterCaseInsensitive = ({ id, value }, row) =>
    row[id] ? row[id].toLowerCase().includes(value.toLowerCase()) : true;

  exportToCSV = () => {
    let modifiedData = [];
    let data = this.props.attemptedList;
    console.log("datajjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjj", data);
    let toTime = "";
    let fromTime = "";
    let requestedDate = "";
    if (data !== null) {
      let lookingFor = "";

      data.forEach((element, index) => {
        // if (this.state.lookingForList.length > 0) {
        //     for (let i = 0; i < this.state.lookingForList.length; i++) {

        //         if (this.state.lookingForList[i].codeValueId === Number(element.lookingFor)) {
        //             //    return this.state.lookingForList[i].codeValue
        //             lookingFor = this.state.lookingForList[i].codeValue ? this.state.lookingForList[i].codeValue : ''
        //         }

        //     }
        // }
        // if (element.executionTracker[0].additionalDetails.hasOwnProperty("requestedDate")) {
        //     let date= element.executionTracker[0].additionalDetails.requestedDate.split("-")
        //     requestedDate= date[2]+"-"+date[1]+"-"+date[0]
        // }

        // if (element.executionTracker[0].additionalDetails.hasOwnProperty("fromTime")) {
        //     fromTime = element.executionTracker[0].additionalDetails.fromTime
        // }
        // if (element.executionTracker[0].additionalDetails.hasOwnProperty("toTime")) {
        //     toTime = element.executionTracker[0].additionalDetails.toTime
        // }    excelto

        if (element.executionTracker.length > 0) {
          let e2 = element.executionTracker;
          for (let i = 0; i < e2.length; i++) {
            if (e2[i].action === "NISM PROFILE CREATE") {
              if (
                e2[i].additionalDetails &&
                e2[i].additionalDetails.hasOwnProperty("requestedDate")
              ) {
                let date = e2[i].additionalDetails.requestedDate.split("-");
                requestedDate = date[2] + "-" + date[1] + "-" + date[0];
              }
            } else {
              if (
                e2[i].additionalDetails &&
                e2[i].additionalDetails.hasOwnProperty("requestedDate")
              ) {
                let date = e2[i].additionalDetails.requestedDate.split("-");
                requestedDate = date[2] + "-" + date[1] + "-" + date[0];
              }
            }
          }
        }

        if (element.executionTracker.length > 0) {
          let e2 = element.executionTracker;
          fromTime = "";
          //for (let i = 0; i < e2.length; i++) {
          for (let i = e2.length - 1; i >= 0; i--) {
            if (e2[i].action === "NISM PROFILE CREATE") {
              if (
                e2[i].additionalDetails &&
                e2[i].additionalDetails.hasOwnProperty("fromTime")
              ) {
                fromTime = e2[i].additionalDetails.fromTime;
              }
            }
          }
        }

        if (element.executionTracker.length > 0) {
          let e2 = element.executionTracker;
          toTime = "";
          //for (let i = 0; i < e2.length; i++) {
          for (let i = e2.length - 1; i >= 0; i--) {
            if (e2[i].action === "NISM PROFILE CREATE") {
              if (
                e2[i].additionalDetails &&
                e2[i].additionalDetails.hasOwnProperty("toTime")
              ) {
                toTime = e2[i].additionalDetails.toTime;
              }
            }
          }
        }
        let score = "";
        let examStatus = "";
        let center = "";
        let enrollmentDate = "";
        let mode = "";
        if (element.executionTracker.length > 0) {
          //     props.original.executionTracker.map(e => {

          let e1 = element.executionTracker;

          for (let i = 0; i < e1.length; i++) {
            if (e1[i].action === "NISM CERTIFICATION COMPLETE") {
              if (
                e1[i].additionalDetails &&
                e1[i].additionalDetails.hasOwnProperty("candidateScores")
              ) {
                score = e1[i].additionalDetails.candidateScores;
              }
              if (
                e1[i].additionalDetails &&
                e1[i].additionalDetails.hasOwnProperty("examinationStatus")
              ) {
                examStatus = e1[i].additionalDetails.examinationStatus;
              } else {
                //  score=0
              }
            }
            if (
              e1[i].additionalDetails &&
              e1[i].additionalDetails.hasOwnProperty("center")
            ) {
              center = e1[i].additionalDetails.center;
            }
            if (e1[i].action === "NISM EXAM DATE CONFIRM") {
              enrollmentDate = e1[i].createdDate.split(" ")[0];
            }
            if (
              e1[i].action == "PROFILE COMPLETE" ||
              e1[i].action == "NISM VA SUBSCRIBE" ||
              e1[i].action == "NISM VA PAYMENT COMPLETE" ||
              e1[i].action == "NISM PROFILE CREATE"
            ) {
              if (
                e1[i].additionalDetails &&
                e1[i].additionalDetails.hasOwnProperty("flag")
              ) {
                mode =
                  e1[i].additionalDetails.flag === true ? "Online" : "Offline";
              }
            }
          }
          // score=0
        }
        var data1 = {
          "Sr. No.": index + 1,
          "First Name": element.firstName,
          "Last Name": element.lastName,
          "Email ID": element.emailID,
          "PAN No": element.panNumber,
          "Mobile No": element.mobileNo,
          Center: center,
          Mode: mode,
          //  "Candidate Type": lookingFor,
          "Exam Date": requestedDate,
          "Enrollment Number": element.enrollmentNumber,
          "Enrollment Date": enrollmentDate,
          //"Exam Date": element.executionTracker ? moment(element.executionTracker[0].additionalDetails.requestedDate,"DD/MM/YYYY").format("DD-MM-YYYY") : '',
          "From Time": fromTime,
          "To Time": toTime,

          "Candidate Scores": score,
          "Examination Status": examStatus,

          //   "action": element.action,
        };
        modifiedData.push(data1);
      });
      let date = new Date();
      let time = new Date().toLocaleString().split(",")[1];

      let time1 = time.split(" ")[2];
      let time2 = time.split(":")[0] + "-" + time.split(":")[1] + "-" + time1;
      ///  alert(time)
      time2 = time2.split(" ")[1];

      let date1 = moment(date).format("DD-MM-YYYY");
      //  let d2 = date1

      let title1 = "NISM Exam Date Request List-" + time2 + "-";
      //   let title1 = "ExpertMFD-DateRequestList-" + date1

      ExcelService.ExcelData(modifiedData, title1);
    } else {
      warning("No record found", warningNotification);
    }
  };

  exportPDF = () => {
    console.log(
      "datajjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjj",
      this.props.attemptedList
    );
    if (this.props.attemptedList !== null) {
      const unit = "pt";
      const size = "A3"; // Use A1, A2, A3 or A4
      const orientation = "landscape"; // portrait or landscape aasim

      const marginLeft = 40;
      const doc = new jsPDF(orientation, unit, size);

      doc.setFontSize(15);
      let modifiedData = [];
      const title = "NISM Exam Date Request List";
      //const headers = [["Sr. No.", "First Name", "Last Name", "Email ID", "PAN No", "Mobile No", "Center", "Exam Date", "From Time", "To Time", "Mode", "Examination Status", "Candidate Scores"]];
      const headers = [
        [
          "Sr. No.",
          "First Name",
          "Last Name",
          "Email ID",
          "PAN No",
          "Mobile No",
          "Center",
          "Mode",
          "Exam Date",
          "Enrollment Number",
          "Enrollment Date",
          "From Time",
          "To Time",
          "Candidate Scores",
          "Examination Status",
        ],
      ];
      let lookingFor = "";
      let fromTime = "";
      let toTime = "";
      let requestedDate = "";

      let examinationStatus = "";

      this.props.attemptedList.forEach((element, index) => {
        // console.log(this.state.allCityList[0].cityId)
        if (this.state.lookingForList.length > 0) {
          for (let i = 0; i < this.state.lookingForList.length; i++) {
            if (
              this.state.lookingForList[i].codeValueId ===
              Number(element.lookingFor)
            ) {
              //    return this.state.lookingForList[i].codeValue
              lookingFor = this.state.lookingForList[i].codeValue
                ? this.state.lookingForList[i].codeValue
                : "";
            }
          }
        }
        // if (element.executionTracker[0].additionalDetails.hasOwnProperty("requestedDate")) {
        //     let date= element.executionTracker[0].additionalDetails.requestedDate.split("-")
        //     requestedDate= date[2]+"-"+date[1]+"-"+date[0]
        // }

        if (element.executionTracker.length > 0) {
          let e2 = element.executionTracker;
          for (let i = 0; i < e2.length; i++) {
            if (e2[i].action === "NISM PROFILE CREATE") {
              if (
                e2[i].additionalDetails &&
                e2[i].additionalDetails.hasOwnProperty("requestedDate")
              ) {
                let date = e2[i].additionalDetails.requestedDate.split("-");
                requestedDate = date[2] + "-" + date[1] + "-" + date[0];
              }
            } else {
              if (
                e2[i].additionalDetails &&
                e2[i].additionalDetails.hasOwnProperty("requestedDate")
              ) {
                let date = e2[i].additionalDetails.requestedDate.split("-");
                requestedDate = date[2] + "-" + date[1] + "-" + date[0];
              }
            }
          }
        }

        // if (element.executionTracker[0].additionalDetails.hasOwnProperty("fromTime")) {
        //     fromTime = element.executionTracker[0].additionalDetails.fromTime
        // }

        if (element.executionTracker.length > 0) {
          let e2 = element.executionTracker;
          fromTime = "";
          //for (let i = 0; i < e2.length; i++) {
          for (let i = e2.length - 1; i >= 0; i--) {
            if (e2[i].action === "NISM PROFILE CREATE") {
              if (
                e2[i].additionalDetails &&
                e2[i].additionalDetails.hasOwnProperty("fromTime")
              ) {
                fromTime = e2[i].additionalDetails.fromTime;
              }
            }
          }
        }

        // if (element.executionTracker[0].additionalDetails.hasOwnProperty("toTime")) {
        //     toTime = element.executionTracker[0].additionalDetails.toTime
        // }

        if (element.executionTracker.length > 0) {
          let e2 = element.executionTracker;
          toTime = "";
          //for (let i = 0; i < e2.length; i++) {
          for (let i = e2.length - 1; i >= 0; i--) {
            if (e2[i].action === "NISM PROFILE CREATE") {
              if (
                e2[i].additionalDetails &&
                e2[i].additionalDetails.hasOwnProperty("toTime")
              ) {
                toTime = e2[i].additionalDetails.toTime;
              }
            }
          }
        }
        // if (element.executionTracker[0].additionalDetails.hasOwnProperty("examinationStatus")) {
        //     examinationStatus = element.executionTracker[0].additionalDetails.examinationStatus
        // }
        let score = "";
        let examStatus = "";
        let center = "";
        let enrollmentDate = "";
        let mode = "";
        if (element.executionTracker.length > 0) {
          //     props.original.executionTracker.map(e => {

          let e1 = element.executionTracker;

          for (let i = 0; i < e1.length; i++) {
            if (e1[i].action === "NISM CERTIFICATION COMPLETE") {
              if (
                e1[i].additionalDetails &&
                e1[i].additionalDetails.hasOwnProperty("candidateScores")
              ) {
                score = e1[i].additionalDetails.candidateScores;
              }
              if (
                e1[i].additionalDetails &&
                e1[i].additionalDetails.hasOwnProperty("examinationStatus")
              ) {
                examStatus = e1[i].additionalDetails.examinationStatus;
              } else {
                //  score=0
              }
            }
            if (
              e1[i].additionalDetails &&
              e1[i].additionalDetails.hasOwnProperty("center")
            ) {
              center = e1[i].additionalDetails.center;
            }
            if (e1[i].action === "NISM EXAM DATE CONFIRM") {
              enrollmentDate = e1[i].createdDate.split(" ")[0];
            }
            if (
              e1[i].action == "PROFILE COMPLETE" ||
              e1[i].action == "NISM VA SUBSCRIBE" ||
              e1[i].action == "NISM VA PAYMENT COMPLETE" ||
              e1[i].action == "NISM PROFILE CREATE"
            ) {
              if (
                e1[i].additionalDetails &&
                e1[i].additionalDetails.hasOwnProperty("flag")
              ) {
                mode =
                  e1[i].additionalDetails.flag === true ? "Online" : "Offline";
              }
            }
          }
          // score=0
        }
        modifiedData.push([
          index + 1,
          element.firstName,
          element.lastName,
          element.emailID,
          element.panNumber,
          element.mobileNo,
          center,
          mode,
          // lookingFor,
          // element.executionTracker ? moment(element.executionTracker[0].additionalDetails.requestedDate,"DD/MM/YYYY").format("DD-MM-YYYY") : '',
          // element.executionTracker ? moment(element.executionTracker[0].additionalDetails.requestedDate) : '',
          requestedDate,
          element.enrollmentNumber,
          enrollmentDate,
          fromTime,
          toTime,
          // examinationStatus,
          score,
          examStatus,

          //      element.action,
        ]);
      });

      let content = {
        startY: 70,
        head: headers,
        body: modifiedData,
        // styles: { halign: 'center' }
      };

      doc.text(title, marginLeft, 40);
      doc.autoTable(content);
      let date = new Date();
      let time = new Date().toLocaleString().split(",")[1];

      let time1 = time.split(" ")[2];
      let time2 = time.split(":")[0] + "-" + time.split(":")[1] + "-" + time1;
      ///  alert(time)
      time2 = time2.split(" ")[1];

      let date1 = moment(date).format("DD-MM-YYYY");
      // let d2 = date1

      //    let title = "Feat-List-" + time2 + "-"
      let title1 =
        "NISM Exam Date Request List-" + date1 + "-" + time2 + ".pdf";
      doc.save(title1);
    } else {
      warning("No record found", warningNotification);
    }
  };
  getCity = (data) => {
    let cityName = "";
    for (let i = 0; i < this.state.allCityList.length; i++) {
      if (this.state.allCityList[i].cityId === Number(data)) {
        cityName = cityName + this.state.allCityList[i].cityName;
      }
    }
    return cityName;
  };
  getState = (data) => {
    let StateName = "";
    for (let i = 0; i < this.state.stateListForPer.length; i++) {
      if (this.state.stateListForPer[i].stateId === Number(data)) {
        StateName = StateName + this.state.stateListForPer[i].stateName;
      }
    }
    return StateName;
  };
  onChangeParameter(selected, setFieldValue) {
    setFieldValue("city", selected);
    if (selected !== undefined) {
      // setFieldValue(
      //     (values.city =
      //         value)
      // );

      RegionManagementService.getCenter("stateId=" + selected.value, (res) => {
        let center = [];
        for (let i = 0; i < res.responseListObject.length; i++) {
          let obj = {};
          obj["value"] = res.responseListObject[i].centerName;
          obj["label"] = res.responseListObject[i].centerName;
          center.push(obj);
        }
        this.setState({
          centerList: center,
          centerListNew: res.responseListObject,
          //  stateListForPer: res.responseListObject
        });

        // setFieldValue(
        //     ('centerList', res.responseListObject)
        // );
        // this.setState({ centerList: res.responseListObject })
      });
    }
    //  this.props.getDevices(selected.value)
  }

  onChangeParameter1(selected, setFieldValue) {
    debugger;
    setFieldValue("location", selected);
    if (selected !== undefined) {
      LoginService.TokenUser((res) => {
        localStorage.setItem("jwt-token", res.responseObject);
        RegionManagementService.getCenter("stateId=" + selected, (res) => {
          let center = [];
          for (let i = 0; i < res.responseListObject.length; i++) {
            let obj = {};
            obj["value"] = res.responseListObject[i].centerName;
            obj["label"] = res.responseListObject[i].centerName;
            center.push(obj);
          }
          setFieldValue("centerList", center);
          if (res.reasonText === "get state data successfully") {
            this.setState({
              centerListNew: res.responseListObject,
            });
          } else {
            this.setState({
              centerListNew: [],
            });
          }
        });
      });
    }
  }

  onChangeParameterCenter(selected, setFieldValue) {
    setFieldValue("center", selected);
  }

  downloadURI(data, name1) {
    let uri = "";
    let name = "";
    this.setState({
      loading: true,
    });

    if (name1 == "Pan") {
      if (data.panCardURL === undefined) {
        uri = "#";
        warning("No document available", warningNotification);
      } else {
        uri = data.panCardURL === undefined ? "#" : data.panCardURL;
        name = "PAN Card";
      }
    } else if (name1 == "Aadhar") {
      uri = data.aadharCardURL;
      name = "Aadhar Card";
    } else if (name1 == "Aadharback") {
      uri = data.aadharCardBackURL;
      name = "Aadhar Card Back";
    } else {
      // uri = "#"
      // warning("Coming Soon", warningNotification)
      let data1 = {
        partyId: data.learnerPartyID,
      };
      // this.setState({
      //     loading: true
      // })

      RegisterUserService.downloadZip(data1, (res) => {
        if (res.responseObject.documentId !== 0) {
          let DocId = res.responseObject.documentId;
          RegisterUserService.downloadDocumet(
            "documentId=" +
              DocId +
              "&bucketTag=" +
              Global_var.CONST_BUCKET_NAME,
            (res1) => {
              this.setState({
                loading: false,
              });
              window.location.href = res1.responseObject.documentUrl;
            }
          );
        } else {
          this.setState({
            loading: false,
          });
          warning("No document available", warningNotification);
        }
      });
    }

    if (name1 == "Pan" || name1 == "Aadhar" || name1 == "Aadharback") {
      this.setState({
        loading: false,
      });

      window.location.href = uri;
    }
  }
  render() {
    let title =
      "Score Range     0-22  23-45  46-68  69-90" +
      "\u000d" +
      "Result Category   Q1     Q2       Q3       Q4";

    let product = [];
    let fromTime = "";
    let toTime = "";
    let requestedDate = "";
    var roledata = this.props.userRoleAccess;
    console.log(this.props.productDetailsAll);

    var productdata = this.props.productDetailsAll;
    if (productdata) {
      for (let i = 0; i < productdata.length; i++) {
        let obj = {};
        obj["value"] = productdata[i].productID;
        obj["label"] = productdata[i].productName;
        product.push(obj);
      }
      // this.setState({
      //     productData:product
      // })
    }

    let tempcsvData2 = [];

    let tempcsvData1 = [];
    // let data = ["Sr. No.", "First Name", "Last Name", "Email ID", "PAN No", "Mobile No", "Center", "Candidate Type", "Exam Date", "From Time", "To Time","mode","Examination Status","Candidate Score"]
    let data = [
      "Sr. No.",
      "First Name",
      "Last Name",
      "Email ID",
      "PAN No",
      "Mobile No",
      "Center",
      "mode",
      "Exam Date",
      "Enrollment Number",
      "Enrollment Date",
      "From Time",
      "To Time",
      "Candidate Score",
      "Examination Status",
    ];
    this.props.attemptedList !== [] &&
      this.props.attemptedList !== null &&
      this.props.attemptedList.map((element, index) => {
        // debugger;
        //console.log("PPPPPPPPPPPPPPPP element::::",element);
        // let lookingFor = ""
        // if (this.state.lookingForList.length > 0) {
        //     for (let i = 0; i < this.state.lookingForList.length; i++) {

        //         if (this.state.lookingForList[i].codeValueId === Number(element.lookingFor)) {
        //             //    return this.state.lookingForList[i].codeValue
        //             lookingFor = this.state.lookingForList[i].codeValue ? this.state.lookingForList[i].codeValue : ''
        //         }

        //     }
        // }
        // if (element.executionTracker[0].additionalDetails.hasOwnProperty("requestedDate")) {
        //     let date= element.executionTracker[0].additionalDetails.requestedDate.split("-")
        //     requestedDate= date[2]+"-"+date[1]+"-"+date[0]
        // }
        // if (element.executionTracker[0].additionalDetails.hasOwnProperty("fromTime")) {
        //     fromTime = element.executionTracker[0].additionalDetails.fromTime
        // }
        // if (element.executionTracker[0].additionalDetails.hasOwnProperty("toTime")) {
        //     toTime = element.executionTracker[0].additionalDetails.toTime
        // } aasim ori
        if (element.executionTracker.length > 0) {
          let e2 = element.executionTracker;
          for (let i = 0; i < e2.length; i++) {
            if (e2[i].action === "NISM PROFILE CREATE") {
              if (
                e2[i].additionalDetails &&
                e2[i].additionalDetails.hasOwnProperty("requestedDate")
              ) {
                let date = e2[i].additionalDetails.requestedDate.split("-");
                requestedDate = date[2] + "-" + date[1] + "-" + date[0];
              }
            } else {
              if (
                e2[i].additionalDetails &&
                e2[i].additionalDetails.hasOwnProperty("requestedDate")
              ) {
                let date = e2[i].additionalDetails.requestedDate.split("-");
                requestedDate = date[2] + "-" + date[1] + "-" + date[0];
              }
            }
          }
        }

        if (element.executionTracker.length > 0) {
          let e2 = element.executionTracker;
          fromTime = "";
          //for (let i = 0; i < e2.length; i++) {
          for (let i = e2.length - 1; i >= 0; i--) {
            if (e2[i].action === "NISM PROFILE CREATE") {
              if (
                e2[i].additionalDetails &&
                e2[i].additionalDetails.hasOwnProperty("fromTime")
              ) {
                fromTime = e2[i].additionalDetails.fromTime;
              }
            }
          }
        }

        if (element.executionTracker.length > 0) {
          let e2 = element.executionTracker;
          toTime = "";
          //for (let i = 0; i < e2.length; i++) {
          for (let i = e2.length - 1; i >= 0; i--) {
            if (e2[i].action === "NISM PROFILE CREATE") {
              if (
                e2[i].additionalDetails &&
                e2[i].additionalDetails.hasOwnProperty("toTime")
              ) {
                toTime = e2[i].additionalDetails.toTime;
              }
            }
          }
        }

        let score = "";
        let examStatus = "";
        let center = "";
        let enrollmentDate = "";
        let mode = "";
        if (element.executionTracker.length > 0) {
          //     props.original.executionTracker.map(e => {
          //debugger;
          let e1 = element.executionTracker;

          for (let i = 0; i < e1.length; i++) {
            //debugger
            if (e1[i].action === "NISM CERTIFICATION COMPLETE") {
              if (
                e1[i].additionalDetails &&
                e1[i].additionalDetails.hasOwnProperty("candidateScores")
              ) {
                score = e1[i].additionalDetails.candidateScores;
              }
              if (
                e1[i].additionalDetails &&
                e1[i].additionalDetails.hasOwnProperty("examinationStatus")
              ) {
                examStatus = e1[i].additionalDetails.examinationStatus;
              } else {
                //  score=0
              }
            }
            if (
              e1[i].additionalDetails &&
              e1[i].additionalDetails.hasOwnProperty("center")
            ) {
              center = e1[i].additionalDetails.center;
            }
            if (e1[i].action === "NISM EXAM DATE CONFIRM") {
              enrollmentDate = e1[i].createdDate.split(" ")[0];
            }
            if (
              e1[i].action == "PROFILE COMPLETE" ||
              e1[i].action == "NISM VA SUBSCRIBE" ||
              e1[i].action == "NISM VA PAYMENT COMPLETE" ||
              e1[i].action == "NISM PROFILE CREATE"
            ) {
              if (
                e1[i].additionalDetails &&
                e1[i].additionalDetails.hasOwnProperty("flag")
              ) {
                mode =
                  e1[i].additionalDetails.flag === true ? "Online" : "Offline";
              }
            }
          }
          // score=0
        }
        let csvData = [];
        csvData.push(
          index + 1,
          element.firstName,
          element.lastName,
          element.emailID,
          element.panNumber,
          element.mobileNo,
          center,
          mode,
          // lookingFor,
          requestedDate,
          element.enrollmentNumber,
          enrollmentDate,
          // element.executionTracker ? moment(element.executionTracker[0].additionalDetails.requestedDate,"DD/MM/YYYY").format("DD-MM-YYYY") : '',
          fromTime,
          toTime,

          score,
          examStatus
        );
        console.log(csvData);
        //csvData.push(['John', 'Doe', 'john.doe@xyz.com'])
        tempcsvData2.push(csvData);
      });
    let array = [data, ...tempcsvData2];

    let date = new Date();
    let time = new Date().toLocaleString().split(",")[1];

    let time1 = time.split(" ")[2];
    let time2 = time.split(":")[0] + "-" + time.split(":")[1] + "-" + time1;
    ///  alert(time)
    time2 = time2.split(" ")[1];

    let date1 = moment(date).format("DD-MM-YYYY");
    // let d2 = date1

    //    let title = "Feat-List-" + time2 + "-"
    let title1 = "NISM Exam Date Request List-" + date1 + "-" + time2 + ".csv";
    //let title1 = "ExpertMFD-DateRequestList-" + date1 + ".csv"

    // tempcsvData2.map(e => {
    //     array.push(
    //         e

    //     )
    // })

    console.log(array);
    return (
      <React.Fragment>
        {this.state.loading && (
          <PopUpPortal
            HidePopup={this.state.loading}
            IsVisible={this.state.loading}
          />
        )}
        <section class="dashboard-tab user-tab pt-50 mb-50">
          <div class="padding-rl-50">
            <div class="container-fluid">
              {/* <input type="submit" name="click" onClick={this.download()} />               */}

              <div class="row">
                <div class="col-md-4 col-sm-4 col-xs-12 col-md-offset-8 pull-right"></div>
                <div class="col-md-12 col-sm-12 col-xs-12">
                  <Formik
                    enableReinitialize={true}
                    initialValues={{
                      status: "",
                      fname: "",
                      lname: "",
                      fromDate: "",
                      toDate: "",
                      examFromDate: "",
                      examToDate: "",
                      examFromDate1: "",
                      examToDate1: "",
                      emailId: "",
                      panNo: "",
                      cityId: "",
                      city: "",
                      parameter: "",
                      centerList: [],
                      centerListNew: [],
                      listOfMonth: this.state.listOfMonth,
                      listOfDay: this.state.listOfDay,
                      listOfMonth1: this.state.listOfMonth,
                      listOfDay1: this.state.listOfDay,
                      day: "",
                      month: "",
                      year: "",
                      today: "",
                      tomonth: "",
                      toyear: "",
                    }}
                    validationSchema={Yup.object().shape({
                      //static validations
                      fname: Yup.string().matches(
                        /^[a-zA-Z ]{1,180}$/,
                        "you must specify text"
                      ),
                      emailId: Yup.string().email("Invalid E-Mail"),
                      lname: Yup.string().matches(
                        /^[a-zA-Z ]{1,180}$/,
                        "you must specify text"
                      ),
                      panNo: Yup.string().matches(
                        /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/,
                        "Please enter the valid PAN number"
                      ),
                    })}
                    onSubmit={(fields, { resetForm }) => {
                      let examFromDate1 = "";
                      if (
                        (fields["year"] !== undefined &&
                          fields["year"] !== "") ||
                        (fields["month"] !== undefined &&
                          fields["month"] !== "") ||
                        (fields["day"] !== undefined && fields["day"] !== "")
                      ) {
                        examFromDate1 =
                          fields["year"] +
                          "-" +
                          fields["month"] +
                          "-" +
                          fields["day"];
                      } else {
                        // warning("Please select valid exam from date", warningNotification);

                        examFromDate1 = "";
                      }

                      let examToDate1 = "";
                      if (
                        (fields["toyear"] !== undefined &&
                          fields["toyear"] !== "") ||
                        (fields["tomonth"] !== undefined &&
                          fields["tomonth"] !== "") ||
                        (fields["today"] !== undefined &&
                          fields["today"] !== "")
                      ) {
                        examToDate1 =
                          fields["toyear"] +
                          "-" +
                          fields["tomonth"] +
                          "-" +
                          fields["today"];
                      } else {
                        //   warning("Please select valid exam to date", warningNotification);

                        examToDate1 = "";
                      }

                      if (examFromDate1 !== "" && examToDate1 !== "") {
                        if (new Date(examFromDate1) > new Date(examToDate1)) {
                          warning(
                            "To Date should be greater than From Date",
                            warningNotification
                          );
                          examFromDate1 = "";
                          examToDate1 = "";
                        }
                      }
                      //   else {
                      //     warning("Please select valid Date", warningNotification);

                      //   }
                      if (fields.status == "SEARCH") {
                        let param = "";
                        let isblank = false;
                        if (fields.fname !== undefined || fields.fname !== "") {
                          param = param + "firstName=" + fields.fname + "&";
                        }
                        if (fields.lname !== undefined || fields.lname !== "") {
                          param = param + "lastName=" + fields.lname + "&";
                        }
                        if (
                          fields.fromDate !== undefined ||
                          fields.fromDate !== ""
                        ) {
                          param = param + "fromDate=" + fields.fromDate + "&";
                        }
                        if (
                          fields.toDate !== undefined ||
                          fields.toDate !== ""
                        ) {
                          param = param + "toDate=" + fields.toDate + "&";
                        }
                        if (
                          fields.emailId !== undefined ||
                          fields.emailId !== ""
                        ) {
                          param = param + "emailId=" + fields.emailId + "&";
                        }
                        if (fields.panNo !== undefined || fields.panNo !== "") {
                          param = param + "panNumber=" + fields.panNo + "&";
                        }
                        // if ((fields.city !== '')) {
                        //     //     param = param + "cityId=" + fields.city.value + '&'
                        //     // } else {

                        //     param = param + "cityId=0" + '&'
                        // }
                        if (fields.stage !== "" && fields.stage !== undefined) {
                          param = param + "stage=" + fields.stage + "&";
                        } else {
                          param = param + "stage=" + "&";
                        }
                        if (
                          fields.center !== "" &&
                          fields.center !== undefined
                        ) {
                          param =
                            param + "examLocation=" + fields.center.value + "&";
                        } else {
                          param = param + "examLocation=" + "&";
                        }
                        if (fields.city !== "" && fields.city !== undefined) {
                          param = param + "stateId=" + fields.city.value + "&";
                        } else {
                          param = param + "stateId=" + "&";
                        }
                        if (
                          examFromDate1 !== "" &&
                          examFromDate1 !== undefined
                        ) {
                          param = param + "examFromDate=" + examFromDate1 + "&";
                        } else {
                          param = param + "examFromDate=" + "&";
                        }

                        if (examToDate1 !== "" && examToDate1 !== undefined) {
                          param = param + "examToDate=" + examToDate1 + "&";
                        } else {
                          param = param + "examToDate=" + "&";
                        }
                        if (localStorage.getItem("roleId")) {
                          param =
                            param +
                            "roleId=6&applicationStatus=&additionalDetail=true&cityId=0&learnerId=0"; //+ localStorage.getItem('roleId')
                        }

                        if (
                          fields.stage !== "" ||
                          fields.city !== "" ||
                          fields.fname !== "" ||
                          fields.lname !== "" ||
                          fields.panNo !== "" ||
                          fields.emailId !== "" ||
                          fields.toDate !== "" ||
                          fields.fromDate !== "" ||
                          examToDate1 !== "" ||
                          examFromDate1 !== ""
                        ) {
                          param = param + "&isMandateUser=1";
                          this.props.fetchDistributor(param);
                          //    window.location.reload()
                        } else {
                          // warning("Atleast one parameter is required to fetch the data", warningNotification);
                        }
                      } else {
                        //this.props.fetchDistributor("firstName=&lastName=&fromDate=&toDate=&emailId=&panNumber=&cityId=0");
                        this.props.fetchDistributor(
                          "firstName=&lastName=&fromDate=&toDate=&emailId=&panNumber=&cityId=0&roleId=6&applicationStatus=&stage=&additionalDetail=true&examLocation=&examFromDate=&examToDate=&stateId=&amcId=39&isMandateUser=1"
                        );
                        // + localStorage.getItem("roleId") +
                      }
                    }}
                    render={({
                      errors,
                      touched,
                      handleSubmit,
                      values,
                      setFieldValue,
                    }) => (
                      <React.Fragment>
                        <Form onSubmit={handleSubmit} className="form-group">
                          <section class="updateGDPI">
                            <div class="modal-body">
                              <div class="row">
                                <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                                  <div class="product-add">
                                    <div class="form-group">
                                      <div class="row">
                                        <div class="col-md-4 col-sm-4 col-xs-12">
                                          <label>First Name</label>
                                          <Field
                                            type="text"
                                            name="fname"
                                            id="fname"
                                            autoComplete="fname"
                                            className={
                                              "form-control" +
                                              (errors.fname && touched.text
                                                ? " is-invalid"
                                                : "")
                                            }
                                            placeholder="First Name"
                                            className="form-control"
                                          />
                                          <ErrorMessage
                                            name="fname"
                                            component="div"
                                            className="validation"
                                          />
                                        </div>
                                        <div class="col-md-4 col-sm-4 col-xs-12">
                                          <label>Last name</label>
                                          <Field
                                            type="lname"
                                            name="lname"
                                            id="lname"
                                            autoComplete="lname"
                                            className={
                                              "form-control" +
                                              (errors.lname && touched.lname
                                                ? " is-invalid"
                                                : "")
                                            }
                                            placeholder="Last Name"
                                          />
                                          <ErrorMessage
                                            name="lname"
                                            component="div"
                                            className="validation"
                                          />
                                        </div>
                                        <div class="col-md-4 col-sm-4 col-xs-12">
                                          <label>Email Id</label>
                                          <Field
                                            type="emailId"
                                            name="emailId"
                                            id="emailId"
                                            autoComplete="emailId"
                                            className={
                                              "form-control" +
                                              (errors.emailId && touched.emailId
                                                ? " is-invalid"
                                                : "")
                                            }
                                            placeholder="Email Id"
                                          />
                                          <ErrorMessage
                                            name="emailId"
                                            component="div"
                                            className="validation"
                                          />
                                        </div>
                                        <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12"></div>
                                        <div class="col-md-4 col-sm-4 col-xs-12">
                                          <label>Requested From Date</label>
                                          {/* <DatePicker selected={values.examFromDate}
                                                                                        autoComplete="off"
                                                                                        placeholderText="dd-mm-yyyy"
                                                                                        dateFormat="dd-MM-yyyy"
                                                                                        maxLength="50"
                                                                                        //   maxDate={new Date()}

                                                                                        value={values.examFromDate}
                                                                                        onChangeRaw={event => {

                                                                                            let temp = event.target.value

                                                                                            if (temp !== undefined) {
                                                                                                if (temp.includes("/")) {

                                                                                                    setFieldValue(values.examFromDate = "")
                                                                                                    setFieldValue(values.errmsg1 = "Date should be in dd-mm-yyyy format.")

                                                                                                }
                                                                                                else {
                                                                                                    // setFieldValue(values.toDate = date)
                                                                                                    setFieldValue(values.errmsg1 = "")

                                                                                                }
                                                                                            }
                                                                                        }
                                                                                        }
                                                                                        onChange={date => {

                                                                                            setFieldValue(values.examFromDate = date)


                                                                                            setFieldValue(values.examFromDate1 = moment(date).format("YYYY-MM-DD"))

                                                                                            // setFieldValue(values.dateOfBirth = date)
                                                                                        }}
                                                                                        className={
                                                                                            "form-control" +
                                                                                            (errors.examFromDate &&
                                                                                                touched.examFromDate
                                                                                                ? " is-invalid"
                                                                                                : "")
                                                                                        }

                                                                                    /> */}

                                          <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 pl pr">
                                            <div className="col-md-3 col-lg-3 col-sm-3 col-xs-3 pl pr">
                                              <Field
                                                as="select"
                                                name="day"
                                                id="day"
                                                // onChange={handleChange}
                                                //  disabled={this.state.isDisabled}
                                                //  disabled
                                                onChange={(e) => {
                                                  var listOfMonth = [];
                                                  setFieldValue(
                                                    (values.day =
                                                      e.target.value)
                                                  );
                                                  if (e.target.value == 31) {
                                                    listOfMonth = [
                                                      { key: 1, value: "Jan" },

                                                      { key: 3, value: "Mar" },

                                                      { key: 5, value: "May" },

                                                      { key: 7, value: "Jul" },
                                                      { key: 8, value: "Aug" },

                                                      { key: 10, value: "Oct" },

                                                      { key: 12, value: "Dec" },
                                                    ];
                                                  } else if (
                                                    e.target.value == 30
                                                  ) {
                                                    listOfMonth = [
                                                      { key: 1, value: "Jan" },

                                                      { key: 3, value: "Mar" },
                                                      { key: 4, value: "Apr" },
                                                      { key: 5, value: "May" },
                                                      { key: 6, value: "Jun" },
                                                      { key: 7, value: "Jul" },
                                                      { key: 8, value: "Aug" },
                                                      { key: 9, value: "Sep" },
                                                      { key: 10, value: "Oct" },
                                                      { key: 11, value: "Nov" },
                                                      { key: 12, value: "Dec" },
                                                    ];
                                                  } else if (
                                                    e.target.value == 28 ||
                                                    e.target.value == 29
                                                  ) {
                                                    listOfMonth = [
                                                      { key: 1, value: "Jan" },
                                                      { key: 2, value: "Feb" },
                                                      { key: 3, value: "Mar" },
                                                      { key: 4, value: "Apr" },
                                                      { key: 5, value: "May" },
                                                      { key: 6, value: "June" },
                                                      { key: 7, value: "Jul" },
                                                      { key: 8, value: "Aug" },
                                                      { key: 9, value: "Sep" },
                                                      { key: 10, value: "Oct" },
                                                      { key: 11, value: "Nov" },
                                                      { key: 12, value: "Dec" },
                                                    ];
                                                  } else {
                                                    listOfMonth = [
                                                      { key: 1, value: "Jan" },
                                                      { key: 2, value: "Feb" },
                                                      { key: 3, value: "Mar" },
                                                      { key: 4, value: "Apr" },
                                                      { key: 5, value: "May" },
                                                      { key: 6, value: "Jun" },
                                                      { key: 7, value: "Jul" },
                                                      { key: 8, value: "Aug" },
                                                      { key: 9, value: "Sep" },
                                                      { key: 10, value: "Oct" },
                                                      { key: 11, value: "Nov" },
                                                      { key: 12, value: "Dec" },
                                                    ];
                                                  }
                                                  setFieldValue(
                                                    (values.listOfMonth =
                                                      listOfMonth)
                                                  );
                                                  // this.setState({
                                                  //   listOfMonth: listOfMonth
                                                  // })
                                                }}
                                                className={
                                                  "form-control" +
                                                  (errors.day && touched.day
                                                    ? "is-invalid"
                                                    : "")
                                                }
                                                className="form-control"
                                              >
                                                <option value="">DD</option>
                                                {(values.listOfDay || []).map(
                                                  (d) => (
                                                    <option value={d}>
                                                      {d}
                                                    </option>
                                                  )
                                                )}
                                              </Field>
                                            </div>
                                            <div className="col-md-4 col-lg-4 col-sm-4 col-xs-4 pl pr">
                                              <Field
                                                as="select"
                                                name="month"
                                                id="month"
                                                //   onChange={handleChange}
                                                //     disabled={this.state.isDisabled}
                                                //  disabled
                                                onChange={(e) => {
                                                  setFieldValue(
                                                    (values.month =
                                                      e.target.value)
                                                  );
                                                  if (e.target.value == 2) {
                                                    var listOfDay = [];
                                                    for (
                                                      var i = 1;
                                                      i <= 29;
                                                      i++
                                                    ) {
                                                      //    var current = year - i;
                                                      listOfDay.push(i);
                                                      // this.setState({ listOfDay: listOfDay });
                                                    }
                                                  } else if (
                                                    e.target.value == 1 ||
                                                    e.target.value == 3 ||
                                                    e.target.value == 5 ||
                                                    e.target.value == 7 ||
                                                    e.target.value == 8 ||
                                                    e.target.value == 10 ||
                                                    e.target.value == 12
                                                  ) {
                                                    var listOfDay = [];
                                                    for (
                                                      var i = 1;
                                                      i <= 31;
                                                      i++
                                                    ) {
                                                      //    var current = year - i;
                                                      listOfDay.push(i);
                                                      //  this.setState({ listOfDay: listOfDay });
                                                    }
                                                  } else {
                                                    var listOfDay = [];
                                                    for (
                                                      var i = 1;
                                                      i <= 30;
                                                      i++
                                                    ) {
                                                      //    var current = year - i;
                                                      listOfDay.push(i);
                                                      //  this.setState({ listOfDay: listOfDay });
                                                    }
                                                  }
                                                  setFieldValue(
                                                    (values.listOfDay =
                                                      listOfDay)
                                                  );
                                                }}
                                                className={
                                                  "form-control" +
                                                  (errors.month && touched.month
                                                    ? "is-invalid"
                                                    : "")
                                                }
                                                className="form-control"
                                              >
                                                <option value="">MM</option>
                                                {(values.listOfMonth || []).map(
                                                  (m) => (
                                                    <option value={m.key}>
                                                      {m.value}
                                                    </option>
                                                  )
                                                )}
                                              </Field>
                                            </div>
                                            <div className="col-md-5 col-lg-5 col-sm-5 col-xs-5 pl pr">
                                              <Field
                                                as="select"
                                                name="year"
                                                id="year"
                                                // onChange={handleChange}
                                                //    disabled={this.state.isDisabled}
                                                //  disabled
                                                className={
                                                  "form-control" +
                                                  (errors.year && touched.year
                                                    ? "is-invalid"
                                                    : "")
                                                }
                                                className="form-control"
                                              >
                                                <option value="">YYYY</option>
                                                {(
                                                  this.state.listOfYear1 || []
                                                ).map((year) => (
                                                  <option value={year}>
                                                    {year}
                                                  </option>
                                                ))}
                                              </Field>
                                            </div>
                                          </div>
                                          <p className="validation">
                                            {" "}
                                            {values.errmsg1}
                                          </p>

                                          {errors.examFromDate && (
                                            <div className="validation">
                                              {errors.examFromDate}
                                            </div>
                                          )}
                                          {/* <Field
                                                                                        type="date"
                                                                                        name="examFromDate"
                                                                                        id="examFromDate"
                                                                                        autoComplete="examFromDate"
                                                                                        className={
                                                                                            "form-control" +
                                                                                            (errors.examFromDate &&
                                                                                                touched.examFromDate
                                                                                                ? " is-invalid"
                                                                                                : "")
                                                                                        }

                                                                                        onChange={(e) => {

                                                                                            var FromDate = e.target.value

                                                                                            var Todate = values.toDate
                                                                                            if (Todate !== '' && FromDate !== '') {
                                                                                                if ((Date.parse(Todate) < Date.parse(FromDate))) {

                                                                                                    //  alert();
                                                                                                    warning("Requested To date should be greater than Registered From date", warningNotification);
                                                                                                    setFieldValue(
                                                                                                        (values.examFromDate = "")
                                                                                                    )
                                                                                                    setFieldValue(
                                                                                                        (values.examToDate = "")
                                                                                                    );
                                                                                                }
                                                                                                else {
                                                                                                    setFieldValue(
                                                                                                        (values.examFromDate = FromDate)
                                                                                                    )
                                                                                                }
                                                                                            }
                                                                                            else {
                                                                                                setFieldValue(
                                                                                                    (values.examFromDate = FromDate)
                                                                                                )
                                                                                            }
                                                                                        }
                                                                                        }
                                                                                        placeholder="From Date"
                                                                                    />
                                                                                    <ErrorMessage
                                                                                        name="fromDate"
                                                                                        component="div"
                                                                                        className="validation"
                                                                                    /> */}
                                        </div>

                                        <div class="col-md-4 col-sm-4 col-xs-12">
                                          <label>Requested To Date</label>
                                          {/* <DatePicker selected={values.examToDate}
                                                                                        minDate={values.examFromDate}
                                                                                        autoComplete="off"
                                                                                        placeholderText="dd-mm-yyyy"
                                                                                        dateFormat="dd-MM-yyyy"
                                                                                        maxLength="50"
                                                                                        value={values.examToDate}
                                                                                        onChangeRaw={event => {

                                                                                            let temp = event.target.value

                                                                                            if (temp !== undefined) {
                                                                                                if (temp.includes("/")) {

                                                                                                    setFieldValue(values.examToDate = "")
                                                                                                    setFieldValue(values.errmsg = "Date should be in dd-mm-yyyy format.")

                                                                                                }
                                                                                                else {
                                                                                                    // setFieldValue(values.toDate = date)
                                                                                                    setFieldValue(values.errmsg = "")

                                                                                                }
                                                                                            }
                                                                                        }
                                                                                        }
                                                                                        onChange={date => {

                                                                                            setFieldValue(values.examToDate = date)


                                                                                            setFieldValue(values.examToDate1 = moment(date).format("YYYY-MM-DD"))

                                                                                            // setFieldValue(values.dateOfBirth = date)
                                                                                        }}
                                                                                        className={
                                                                                            "form-control" +
                                                                                            (errors.examToDate &&
                                                                                                touched.examToDate
                                                                                                ? " is-invalid"
                                                                                                : "")
                                                                                        }

                                                                                    /> */}

                                          <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 pl pr">
                                            <div className="col-md-3 col-lg-3 col-sm-3 col-xs-3 pl pr">
                                              <Field
                                                as="select"
                                                name="today"
                                                id="today"
                                                // onChange={handleChange}
                                                //  disabled={this.state.isDisabled}
                                                //  disabled

                                                onChange={(e) => {
                                                  var listOfMonth = [];
                                                  setFieldValue(
                                                    (values.today =
                                                      e.target.value)
                                                  );
                                                  if (e.target.value == 31) {
                                                    listOfMonth = [
                                                      { key: 1, value: "Jan" },

                                                      { key: 3, value: "Mar" },

                                                      { key: 5, value: "May" },

                                                      { key: 7, value: "Jul" },
                                                      { key: 8, value: "Aug" },

                                                      { key: 10, value: "Oct" },

                                                      { key: 12, value: "Dec" },
                                                    ];
                                                  } else if (
                                                    e.target.value == 30
                                                  ) {
                                                    listOfMonth = [
                                                      { key: 1, value: "Jan" },

                                                      { key: 3, value: "Mar" },
                                                      { key: 4, value: "Apr" },
                                                      { key: 5, value: "May" },
                                                      { key: 6, value: "Jun" },
                                                      { key: 7, value: "Jul" },
                                                      { key: 8, value: "Aug" },
                                                      { key: 9, value: "Sep" },
                                                      { key: 10, value: "Oct" },
                                                      { key: 11, value: "Nov" },
                                                      { key: 12, value: "Dec" },
                                                    ];
                                                  } else if (
                                                    e.target.value == 28 ||
                                                    e.target.value == 29
                                                  ) {
                                                    listOfMonth = [
                                                      { key: 1, value: "Jan" },
                                                      { key: 2, value: "Feb" },
                                                      { key: 3, value: "Mar" },
                                                      { key: 4, value: "Apr" },
                                                      { key: 5, value: "May" },
                                                      { key: 6, value: "Jun" },
                                                      { key: 7, value: "Jul" },
                                                      { key: 8, value: "Aug" },
                                                      { key: 9, value: "Sep" },
                                                      { key: 10, value: "Oct" },
                                                      { key: 11, value: "Nov" },
                                                      { key: 12, value: "Dec" },
                                                    ];
                                                  } else {
                                                    listOfMonth = [
                                                      { key: 1, value: "Jan" },
                                                      { key: 2, value: "Feb" },
                                                      { key: 3, value: "Mar" },
                                                      { key: 4, value: "Apr" },
                                                      { key: 5, value: "May" },
                                                      { key: 6, value: "Jun" },
                                                      { key: 7, value: "Jul" },
                                                      { key: 8, value: "Aug" },
                                                      { key: 9, value: "Sep" },
                                                      { key: 10, value: "Oct" },
                                                      { key: 11, value: "Nov" },
                                                      { key: 12, value: "Dec" },
                                                    ];
                                                  }
                                                  setFieldValue(
                                                    (values.listOfMonth1 =
                                                      listOfMonth)
                                                  );
                                                  // this.setState({
                                                  //   listOfMonth: listOfMonth
                                                  // })
                                                }}
                                                className={
                                                  "form-control" +
                                                  (errors.today && touched.today
                                                    ? "is-invalid"
                                                    : "")
                                                }
                                                className="form-control"
                                              >
                                                <option value="">DD</option>
                                                {(values.listOfDay1 || []).map(
                                                  (d) => (
                                                    <option value={d}>
                                                      {d}
                                                    </option>
                                                  )
                                                )}
                                              </Field>
                                            </div>
                                            <div className="col-md-4 col-lg-4 col-sm-4 col-xs-4 pl pr">
                                              <Field
                                                as="select"
                                                name="tomonth"
                                                id="tomonth"
                                                //   onChange={handleChange}
                                                //     disabled={this.state.isDisabled}
                                                //  disabled
                                                onChange={(e) => {
                                                  setFieldValue(
                                                    (values.tomonth =
                                                      e.target.value)
                                                  );

                                                  if (e.target.value == 2) {
                                                    var listOfDay = [];
                                                    for (
                                                      var i = 1;
                                                      i <= 29;
                                                      i++
                                                    ) {
                                                      //    var current = year - i;
                                                      listOfDay.push(i);
                                                      // this.setState({ listOfDay: listOfDay });
                                                    }
                                                  } else if (
                                                    e.target.value == 1 ||
                                                    e.target.value == 3 ||
                                                    e.target.value == 5 ||
                                                    e.target.value == 7 ||
                                                    e.target.value == 8 ||
                                                    e.target.value == 10 ||
                                                    e.target.value == 12
                                                  ) {
                                                    var listOfDay = [];
                                                    for (
                                                      var i = 1;
                                                      i <= 31;
                                                      i++
                                                    ) {
                                                      //    var current = year - i;
                                                      listOfDay.push(i);
                                                      //  this.setState({ listOfDay: listOfDay });
                                                    }
                                                  } else {
                                                    var listOfDay = [];
                                                    for (
                                                      var i = 1;
                                                      i <= 30;
                                                      i++
                                                    ) {
                                                      //    var current = year - i;
                                                      listOfDay.push(i);
                                                      //  this.setState({ listOfDay: listOfDay });
                                                    }
                                                  }
                                                  setFieldValue(
                                                    (values.listOfDay1 =
                                                      listOfDay)
                                                  );
                                                }}
                                                className={
                                                  "form-control" +
                                                  (errors.tomonth &&
                                                  touched.tomonth
                                                    ? "is-invalid"
                                                    : "")
                                                }
                                                className="form-control"
                                              >
                                                <option value="">MM</option>
                                                {(
                                                  values.listOfMonth1 || []
                                                ).map((m) => (
                                                  <option value={m.key}>
                                                    {m.value}
                                                  </option>
                                                ))}
                                              </Field>
                                            </div>
                                            <div className="col-md-5 col-lg-5 col-sm-5 col-xs-5 pl pr">
                                              <Field
                                                as="select"
                                                name="toyear"
                                                id="toyear"
                                                // onChange={handleChange}
                                                //    disabled={this.state.isDisabled}
                                                //  disabled
                                                className={
                                                  "form-control" +
                                                  (errors.toyear &&
                                                  touched.toyear
                                                    ? "is-invalid"
                                                    : "")
                                                }
                                                className="form-control"
                                              >
                                                <option value="">YYYY</option>
                                                {(
                                                  this.state.listOfYear1 || []
                                                ).map((year) => (
                                                  <option value={year}>
                                                    {year}
                                                  </option>
                                                ))}
                                              </Field>
                                            </div>
                                          </div>

                                          <p className="validation">
                                            {" "}
                                            {values.errmsg}
                                          </p>

                                          {errors.toDate && (
                                            <div className="validation">
                                              {errors.toDate}
                                            </div>
                                          )}
                                          {/* <Field
                                                                                        type="date"
                                                                                        name="examToDate"
                                                                                        id="examToDate"
                                                                                        autoComplete="examToDate"
                                                                                        className={
                                                                                            "form-control" +
                                                                                            (errors.examToDate &&
                                                                                                touched.examToDate
                                                                                                ? " is-invalid"
                                                                                                : "")
                                                                                        }

                                                                                        onChange={(e) => {

                                                                                            var Todate = e.target.value

                                                                                            var FromDate = values.fromDate
                                                                                            if (Todate !== '' && FromDate !== '') {
                                                                                                if ((Date.parse(Todate) < Date.parse(FromDate))) {
                                                                                                    //  alert();
                                                                                                    warning("Requested To date should be greater than Registered From date", warningNotification);
                                                                                                    setFieldValue(
                                                                                                        (values.fromDate = "")
                                                                                                    )
                                                                                                    setFieldValue(
                                                                                                        (values.examToDate = "")
                                                                                                    );
                                                                                                }
                                                                                                else {
                                                                                                    setFieldValue(
                                                                                                        (values.examToDate = Todate)
                                                                                                    )
                                                                                                }
                                                                                            }
                                                                                            else {
                                                                                                setFieldValue(
                                                                                                    (values.examToDate = Todate)
                                                                                                )
                                                                                            }
                                                                                        }
                                                                                        }
                                                                                        placeholder="To Date"
                                                                                    />
                                                                                    <ErrorMessage
                                                                                        name="toDate"
                                                                                        component="div"
                                                                                        className="validation"
                                                                                    /> */}
                                        </div>

                                        <div class="col-md-4 col-sm-4 col-xs-12">
                                          <label>Pan No</label>
                                          <Field
                                            type="text"
                                            name="panNo"
                                            id="panNo"
                                            autoComplete="panNo"
                                            className={
                                              "form-control" +
                                              (errors.panNo && touched.panNo
                                                ? " is-invalid"
                                                : "")
                                            }
                                            placeholder="PAN No"
                                          />
                                          <ErrorMessage
                                            name="panNo"
                                            component="div"
                                            className="validation"
                                          />
                                        </div>

                                        <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12"></div>
                                        <div class="col-md-4 col-sm-4 col-xs-12">
                                          <label>Location</label>

                                          <Select
                                            //   onBlur={() => setFieldTouched("parameter", true)}

                                            onChange={(value) => {
                                              this.onChangeParameter(
                                                value,
                                                setFieldValue
                                              );
                                            }}
                                            id="city"
                                            name="city"
                                            options={this.state.cityList}
                                            filterOption={createFilter({
                                              matchFrom: "start",
                                            })}
                                            value={values.city}
                                          />
                                          <ErrorMessage
                                            name="location"
                                            className="validation"
                                            component="div"
                                          />
                                        </div>
                                        <div class="col-md-4 col-sm-4 col-xs-12">
                                          <label>Center</label>

                                          <Select
                                            onChange={(value) => {
                                              this.onChangeParameterCenter(
                                                value,
                                                setFieldValue
                                              );
                                            }}
                                            name="center"
                                            options={this.state.centerList}
                                            filterOption={createFilter({
                                              matchFrom: "start",
                                            })}
                                            value={values.center}
                                          />
                                          <ErrorMessage
                                            name="center"
                                            className="validation"
                                            component="div"
                                          />
                                        </div>

                                        <div class="col-md-4 col-sm-4 col-xs-12">
                                          <label>Current Stages</label>

                                          {/* <Select
           //   onBlur={() => setFieldTouched("parameter", true)}
            //  onChange={(value) => this.onChangeParameter(value, setFieldValue)}
              name="stages"
              options={this.state.Stage}
              filterOption={createFilter({
                matchFrom: 'start'
                })}
             // value={values.city}
            /> */}

                                          <Field
                                            as="select"
                                            name="stage"
                                            id="stage"
                                            autoComplete="Quartlet"
                                            className={
                                              "form-control" +
                                              (errors.Quartlet &&
                                              touched.Quartlet
                                                ? " is-invalid"
                                                : "")
                                            }
                                            placeholder="Quartlet"
                                          >
                                            <option value="">--Select--</option>

                                            {/* <option value="REGISTERED">REGISTERED</option>
                                                                                        <option value="PROFILE COMPLETED">PROFILE COMPLETED</option>
                                                                                        <option value="FEAT COMPLETED">FEAT COMPLETED</option>
                                                                                        <option value="NISM VA SUBSCRIBED">NISM VA SUBSCRIBED</option>


                                                                                        <option value="NISM VA PAYMENT COMPLETED">NISM VA PAYMENT COMPLETED</option>
                                                                                        <option value="NISM PROFILES CREATED">NISM PROFILES CREATED</option>
                                                                                        <option value="NISM EXAM DATE CONFIRMED">NISM EXAM DATE CONFIRMED</option>
                                                                                        <option value="ADMIT CARD AVAILABLE">ADMIT CARD AVAILABLE</option>
                                                                                        <option value="NISM VA CERTIFICATION">NISM VA CERTIFICATION</option>
                                                                                        <option value="KYD-ARN APPLIED">KYD-ARN APPLIED</option>

                                                                                        <option value="KYD-ARN ACCEPTED">KYD-ARN ACCEPTED</option>
                                                                                        <option value="UNDER EMPANELMENT">UNDER EMPANELMENT</option>
                                                                                        <option value="EMPANELLED">EMPANELLED</option> */}

                                            <option value="REGISTERED">
                                              REGISTERED
                                            </option>
                                            <option value="PROFILE COMPLETED">
                                              PROFILE COMPLETED
                                            </option>
                                            <option value="NISM VA SUBSCRIBED">
                                              NISM VA SUBSCRIBED
                                            </option>
                                            <option value="NISM VA PAYMENT COMPLETED">
                                              NISM VA PAYMENT COMPLETED
                                            </option>
                                            <option value="NISM PROFILE CREATED">
                                              NISM PROFILE CREATED
                                            </option>
                                            <option value="NISM EXAM DATE CONFIRMED">
                                              NISM EXAM DATE CONFIRMED
                                            </option>
                                            <option value="NISM EXAM ENROLMENT COMPLETED">
                                              NISM EXAM ENROLMENT COMPLETED
                                            </option>
                                            <option value="NISM CERTIFICATION COMPLETED">
                                              NISM CERTIFICATION COMPLETED
                                            </option>
                                            <option value="EUIN NUMBER APPLIED">
                                              EUIN NUMBER APPLIED
                                            </option>
                                            <option value="EUIN NUMBER GENERATED">
                                              EUIN NUMBER GENERATED
                                            </option>
                                            <option value="EUIN NUMBER REJECTED">
                                              EUIN NUMBER REJECTED
                                            </option>
                                            <option value="EUIN NUMBER FAILED">
                                              EUIN NUMBER FAILED
                                            </option>
                                            <option value="EUIN RENEWAL APPLIED">
                                              EUIN RENEWAL APPLIED
                                            </option>
                                            <option value="EUIN RENEWED">
                                              EUIN RENEWED
                                            </option>
                                            <option value="EUIN RENEWAL REJECTED">
                                              EUIN RENEWAL REJECTED
                                            </option>
                                            <option value="EUIN RENEWAL FAILED">
                                              EUIN RENEWAL FAILED
                                            </option>
                                          </Field>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 pl pr mb-20">
                                      <button
                                        type="submit"
                                        class="btn-4 pull-right"
                                        onClick={async () => {
                                          setFieldValue(
                                            (values.status = "SEARCH")
                                          );
                                        }}
                                      >
                                        Search
                                      </button>
                                      <button
                                        type="submit"
                                        id="close"
                                        class="btn-4 pull-right"
                                        onClick={async () => {
                                          setFieldValue(
                                            (values.status = "RESET")
                                          );

                                          window.location.reload();
                                          // setFieldValue(
                                          //     (values.lname = '')
                                          // );
                                          // setFieldValue(
                                          //     (values.fromDate = '')
                                          // ); setFieldValue(
                                          //     (values.toDate = '')
                                          // );
                                          // setFieldValue(
                                          //     (values.emailId = '')
                                          // ); setFieldValue(
                                          //     (values.panNo = '')
                                          // );

                                          // setFieldValue(
                                          //     (values.city = '')
                                          // );
                                          // setFieldValue(
                                          //     (values.stage = '')
                                          // );
                                        }}
                                      >
                                        Reset
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </section>
                        </Form>
                      </React.Fragment>
                    )}
                  />
                  <div class="table-header">
                    <h3>
                      <div class="row">
                        <div class="col-md-6 col-sm-6 col-xs-12">
                          <span class="left-icon">
                            <i class="fa fa-list" aria-hidden="true"></i>
                          </span>{" "}
                          NISM Exam Date Request List
                        </div>
                        <div class="col-md-6 col-sm-6 col-xs-12">
                          <span class="right-icon">
                            <a title="Export to CSV">
                              <CSVLink data={array} filename={title1}>
                                {" "}
                                <i class="fa fa-file-code-o" aria-hidden="true">
                                  {" "}
                                </i>
                              </CSVLink>
                            </a>
                          </span>
                          <span class="right-icon">
                            <a title="Export to Excel">
                              <i
                                onClick={() => this.exportToCSV()}
                                class="fa fa-file-excel-o"
                                aria-hidden="true"
                              ></i>
                            </a>
                          </span>
                          <span class="right-icon">
                            <a title="Export to PDF">
                              <i
                                onClick={() => this.exportPDF()}
                                class="fa fa-file-pdf-o"
                                aria-hidden="true"
                              ></i>
                            </a>
                          </span>
                        </div>
                      </div>
                    </h3>
                  </div>
                  <div class="bg-box mb-30">
                    <div class="table-responsive">
                      <ReactTable
                        minRows={2}
                        data={
                          this.props.attemptedList === null
                            ? []
                            : this.props.attemptedList
                        }
                        className="-striped -highlight"
                        filterable={false}
                        onPageChange={this.stateonpagechange}
                        defaultFilterMethod={this.filterCaseInsensitive}
                        ref={(refReactTable) => {
                          this.refReactTable = refReactTable;
                        }}
                        columns={[
                          {
                            columns: [
                              // {

                              //     id: "checkbox",
                              //     accessor: "",
                              //     Cell: ({ original }) => {

                              //         return (
                              //             <input
                              //                 type="checkbox"
                              //                 className="checkbox"
                              //                 checked={this.state.selected[original.learnerID] === true}
                              //                 onChange={() => this.toggleRow(original)}

                              //                 disabled={
                              //                     original.executionTracker.length > 0 ? original.executionTracker[0].additionalDetails.hasOwnProperty('action') === false ? false : true : false
                              //                 }
                              //             />
                              //         );
                              //     },
                              //     Header: x => {
                              //         return (
                              //             <input
                              //                 type="checkbox"
                              //                 className="checkbox"
                              //                 checked={this.state.selectAll === 1}
                              //                 ref={input => {
                              //                     if (input) {
                              //                         input.indeterminate = this.state.selectAll === 2;
                              //                     }
                              //                 }}
                              //                 onChange={() => this.toggleSelectAll()}
                              //                 disabled={true}
                              //             // style={{
                              //             //     padding:"12px",
                              //             //     paddingTop:"2px"
                              //             // }

                              //             // }
                              //             />
                              //         );
                              //     },
                              //     sortable: false,

                              //     filterable: false,
                              //     width: 45,

                              // },
                              {
                                Header: "Sr No",
                                id: "row",
                                width: 50,
                                Cell: (row) => {
                                  return <div>{row.index + 1}</div>;
                                },
                              },
                              {
                                Header: "First Name",
                                id: "firstName",
                                accessor: (d) => d.firstName,
                              },

                              {
                                Header: "Last Name",
                                id: "lastname",
                                accessor: (d) => d.lastName,
                              },
                              {
                                Header: "Email Id",
                                id: "email",
                                accessor: (d) => d.emailID,
                              },
                              {
                                Header: "PAN No",
                                id: "pan",
                                accessor: (d) => d.panNumber,
                              },
                              {
                                Header: "Mobile No",
                                id: "mobileNo",
                                accessor: (d) => d.mobileNo,
                              },
                              {
                                Header: "Center",
                                id: "center",
                                //   accessor: d => d.executionTracker ? d.executionTracker[0].additionalDetails.centre : '',
                                //  accessor: d => d.executionTracker ? d.executionTracker[0].additionalDetails.center : ''
                                // Cell: props => {
                                //     //
                                //     // console.log(props.original.executionTracker[0].additionalDetails.centre)
                                //     if (props.original.executionTracker.length > 0) {

                                //         return props.original.executionTracker[0].additionalDetails.hasOwnProperty("center") ? props.original.executionTracker[0].additionalDetails.center : ''

                                //     }

                                //     else {
                                //         return ('')
                                //     }

                                // }

                                Cell: (props) => {
                                  //

                                  // console.log(props.original.executionTracker[0].additionalDetails.centre)
                                  if (
                                    props.original.executionTracker.length > 0
                                  ) {
                                    let e = props.original.executionTracker;
                                    for (let i = 0; i < e.length; i++) {
                                      //   if (e[i].action == "NISM PROFILE CREATE") {
                                      if (
                                        e[i].additionalDetails.hasOwnProperty(
                                          "center"
                                        )
                                      ) {
                                        return e[i].additionalDetails.center;
                                      }
                                    }
                                    return "";
                                  } else {
                                    return "";
                                  }
                                },
                              },
                              {
                                Header: "Candidate Type",
                                id: "typeOfCandidate",
                                Cell: (props) => {
                                  if (this.state.lookingForList.length > 0) {
                                    for (
                                      let i = 0;
                                      i < this.state.lookingForList.length;
                                      i++
                                    ) {
                                      console.log(
                                        this.state.lookingForList[i]
                                          .codeValueId ===
                                          props.original.lookingFor
                                      );
                                      if (
                                        this.state.lookingForList[i]
                                          .codeValueId ===
                                        Number(props.original.lookingFor)
                                      ) {
                                        return this.state.lookingForList[i]
                                          .codeValue;
                                        //return this.state.lookingForList[i].codeValue?this.state.lookingForList[i].codeValue:''
                                      }
                                      // else {
                                      //     return ('')
                                      // }
                                    }
                                  } else {
                                    return "";
                                  }
                                },
                              },

                              {
                                Header: "Mode",
                                id: "mode",
                                //       accessor: d => d.executionTracker ? d.executionTracker[0].additionalDetails.requestedDate : ''
                                // Cell: props => {
                                //     //
                                //     // console.log(props.original.executionTracker[0].additionalDetails.centre)
                                //     if (props.original.executionTracker.length > 0) {

                                //         if (props.original.executionTracker.length > 0) {
                                //             props.original.executionTracker.map(e => {
                                //                 if (e.additionalDetails.hasOwnProperty("fromTime")) {
                                //                     if (e.action = "ADMIT CARD AVAILABLE") {
                                //                         e.additionalDetails.additionalDetails.fromTime
                                //                     }
                                //                 }
                                //                 else {
                                //                     return ('')
                                //                 }
                                //             }
                                //         }

                                //     }
                                //     // if (props.original.executionTracker[0].additionalDetails.hasOwnProperty("fromTime")) {
                                //     //     return props.original.executionTracker[0].additionalDetails.fromTime
                                //     // }

                                //     // else {
                                //     //     return ('')
                                //     // }

                                // }
                                Cell: (props) => {
                                  //

                                  // console.log(props.original.executionTracker[0].additionalDetails.centre)
                                  if (
                                    props.original.executionTracker.length > 0
                                  ) {
                                    let e = props.original.executionTracker;
                                    for (let i = 0; i < e.length; i++) {
                                      if (
                                        e[i].action == "NISM PROFILE CREATE"
                                      ) {
                                        if (
                                          e[i].additionalDetails.hasOwnProperty(
                                            "flag"
                                          )
                                        ) {
                                          return e[i].additionalDetails.flag ===
                                            true
                                            ? "Online"
                                            : "Offline";
                                        } else {
                                          return "";
                                        }
                                      }
                                      // else {
                                      //     return ('')
                                      // }
                                    }
                                  } else {
                                    return "";
                                  }
                                },
                                //.split(" ")[0] : ''
                              },
                              {
                                Header: "Requested Date",
                                id: "requestedDate",
                                //       accessor: d => d.executionTracker ? d.executionTracker[0].additionalDetails.requestedDate : ''
                                Cell: (props) => {
                                  //
                                  // console.log(props.original.executionTracker[0].additionalDetails.centre)

                                  if (
                                    props.original.executionTracker.length > 0
                                  ) {
                                    let e = props.original.executionTracker;
                                    for (let i = 0; i < e.length; i++) {
                                      if (
                                        e[i].action == "NISM PROFILE CREATE"
                                      ) {
                                        if (
                                          e[i].additionalDetails.hasOwnProperty(
                                            "requestedDate"
                                          )
                                        ) {
                                          let date =
                                            e[
                                              i
                                            ].additionalDetails.requestedDate.split(
                                              "-"
                                            );

                                          return (
                                            date[2] +
                                            "-" +
                                            date[1] +
                                            "-" +
                                            date[0]
                                          );
                                          // moment(e[i].additionalDetails.requestedDate,"YYYY-MM-DD").format("DD-MM-YYYY")
                                        } else {
                                          return "";
                                        }
                                      }
                                      // else {
                                      //     return ('')
                                      // }
                                    }
                                  } else {
                                    return "";
                                  }
                                },
                                //.split(" ")[0] : ''
                              },
                              {
                                Header: "From Time",
                                id: "fromTime",
                                //       accessor: d => d.executionTracker ? d.executionTracker[0].additionalDetails.requestedDate : ''
                                // Cell: props => {
                                //     //
                                //     // console.log(props.original.executionTracker[0].additionalDetails.centre)
                                //     if (props.original.executionTracker.length > 0) {

                                //         if (props.original.executionTracker.length > 0) {
                                //             props.original.executionTracker.map(e => {
                                //                 if (e.additionalDetails.hasOwnProperty("fromTime")) {
                                //                     if (e.action = "ADMIT CARD AVAILABLE") {
                                //                         e.additionalDetails.additionalDetails.fromTime
                                //                     }
                                //                 }
                                //                 else {
                                //                     return ('')
                                //                 }
                                //             }
                                //         }

                                //     }
                                //     // if (props.original.executionTracker[0].additionalDetails.hasOwnProperty("fromTime")) {
                                //     //     return props.original.executionTracker[0].additionalDetails.fromTime
                                //     // }

                                //     // else {
                                //     //     return ('')
                                //     // }

                                // }
                                Cell: (props) => {
                                  //

                                  // console.log(props.original.executionTracker[0].additionalDetails.centre)
                                  if (
                                    props.original.executionTracker.length > 0
                                  ) {
                                    let e = props.original.executionTracker;
                                    for (let i = 0; i < e.length; i++) {
                                      if (
                                        e[i].action == "NISM PROFILE CREATE"
                                      ) {
                                        if (
                                          e[i].additionalDetails.hasOwnProperty(
                                            "fromTime"
                                          )
                                        ) {
                                          return e[i].additionalDetails
                                            .fromTime;
                                        } else {
                                          return "";
                                        }
                                      }
                                      // else {
                                      //     return ('')
                                      // }
                                    }
                                  } else {
                                    return "";
                                  }
                                },
                                //.split(" ")[0] : ''
                              },
                              {
                                Header: "To Time",
                                id: "toTime",
                                //       accessor: d => d.executionTracker ? d.executionTracker[0].additionalDetails.requestedDate : ''
                                Cell: (props) => {
                                  //
                                  // console.log(props.original.executionTracker[0].additionalDetails.centre)
                                  if (
                                    props.original.executionTracker.length > 0
                                  ) {
                                    //     props.original.executionTracker.map(e => {
                                    let e = props.original.executionTracker;
                                    for (let i = 0; i < e.length; i++) {
                                      if (
                                        e[i].action == "NISM PROFILE CREATE"
                                      ) {
                                        if (
                                          e[i].additionalDetails.hasOwnProperty(
                                            "toTime"
                                          )
                                        ) {
                                          return e[i].additionalDetails.toTime;
                                          //  e.additionalDetails.additionalDetails.toTime
                                        } else {
                                          return "";
                                        }
                                      }
                                      // else {
                                      //     return ('')
                                      // }
                                    }

                                    // )
                                    // if (props.original.executionTracker[0].additionalDetails.hasOwnProperty("toTime")) {
                                    //     return props.original.executionTracker[0].additionalDetails.toTime
                                    // }
                                  } else {
                                    return "";
                                  }
                                },
                                //.split(" ")[0] : ''
                              },
                              {
                                Header: "NISM Score",
                                id: "nismScore",
                                accessor: (d) => "NA",
                                Cell: (props) => {
                                  //
                                  // console.log(props.original.executionTracker[0].additionalDetails.centre)
                                  if (
                                    props.original.executionTracker.length > 0
                                  ) {
                                    //     props.original.executionTracker.map(e => {
                                    let e = [];
                                    let e1 = props.original.executionTracker;

                                    for (let i = 0; i < e1.length; i++) {
                                      if (
                                        e1[i].action ===
                                        "NISM CERTIFICATION COMPLETE"
                                      ) {
                                        if (
                                          e1[
                                            i
                                          ].additionalDetails.hasOwnProperty(
                                            "candidateScores"
                                          )
                                        ) {
                                          return e1[i].additionalDetails
                                            .candidateScores;
                                          //e[i].additionalDetails.candidateScores
                                          //  e.additionalDetails.additionalDetails.toTime
                                        } else {
                                          return "";
                                        }
                                      }
                                    }
                                    return "";
                                  }
                                  // else {
                                  //     return ('')
                                  // }
                                },
                              },
                              {
                                Header: "NISM Status",
                                id: "nismProgress",
                                accessor: (d) => "NA",
                                Cell: (props) => {
                                  //
                                  // console.log(props.original.executionTracker[0].additionalDetails.centre)
                                  if (
                                    props.original.executionTracker.length > 0
                                  ) {
                                    //     props.original.executionTracker.map(e => {

                                    //
                                    let e = props.original.executionTracker;

                                    //   alert(e.length)
                                    for (let i = 0; i < e.length; i++) {
                                      //   alert(e[i].action)
                                      //    alert(i)
                                      if (
                                        e[i].action ==
                                        "NISM CERTIFICATION COMPLETE"
                                      ) {
                                        if (
                                          e[i].additionalDetails.hasOwnProperty(
                                            "examinationStatus"
                                          )
                                        ) {
                                          return e[i].additionalDetails
                                            .examinationStatus;
                                          //  e.additionalDetails.additionalDetails.toTime
                                        } else {
                                          return "";
                                        }
                                      }
                                      // else {
                                      //     return ('')
                                      // }
                                    }
                                    return "";
                                  }
                                  // else {
                                  //     return ('')
                                  // }
                                },
                              },
                              {
                                Header: "NISM Progress",
                                id: "progress",
                                filterable: false,
                                sortable: false,
                                Cell: (props) => {
                                  return (
                                    <div>
                                      <a class="mar-left">
                                        <i
                                          title="NISM Progress"
                                          className="fa fa-eye"
                                          aria-hidden="false"
                                          onClick={(e) =>
                                            this.filterCfxReport(
                                              props.original.emailID,
                                              203401
                                            )
                                          }
                                        ></i>
                                      </a>
                                    </div>
                                  );
                                },
                              },
                              {
                                Header: "Action",
                                id: "action",
                                accessor: "",
                                Cell: this.renderEditable,
                                filterable: false,
                                // Cell: ({ original }) => {
                                // return (
                                // <select name="action" id="action" value={this.state.action}
                                //             value={

                                //                 this.state.selected1[original.learnerID] === true ? this.state.action : ''}
                                //             onChange={e => { this.toggleRowForAction(original, e.target.value) }}

                                //         // onChange={e => {
                                //         //     this.setState({ action: e.target.value })
                                //         // }}

                                //         >
                                //             <option value="">...</option>
                                //             <option value="ACCEPT">Accept</option>
                                //             <option value="CHANGE">Change</option>
                                //             <option value="CANCEL">Cancel</option>

                                //         </select>
                                // );
                                //             },

                                // Cell: ({ original }) => {

                                //     return (
                                //         <select name="action" id="action" value={this.state.action}
                                //             value={

                                //                 this.state.selected1[original.learnerID] === true ? this.state.action : ''}
                                //             onChange={e => { this.toggleRowForAction(original, e.target.value) }}

                                //         // onChange={e => {
                                //         //     this.setState({ action: e.target.value })
                                //         // }}

                                //         >
                                //             <option value="">...</option>
                                //             <option value="ACCEPT">Accept</option>
                                //             <option value="CHANGE">Change</option>
                                //             <option value="CANCEL">Cancel</option>

                                //         </select>
                                //     );
                                // },
                              },

                              {
                                Header: "Upload Admit Card",
                                id: "upload",
                                accessor: "",
                                filterable: false,
                                Cell: this.renderEditableUpload,
                                // Cell: original.executionTracker.length > 0 ?
                                //     original.executionTracker[0].additionalDetails.hasOwnProperty('action') === false ? '' :
                                //         original.executionTracker[0].additionalDetails.action == "ACCEPT" ? this.renderEditableUpload : 'NO Data' : false
                              },
                              // {
                              //     Header: "Download",
                              //     id: "download",
                              //     width: 200,
                              //     accessor: "",
                              //     filterable: false,
                              //     Cell: this.renderEditableDownload

                              // },
                            ],
                          },
                        ]}
                        defaultPageSize={10}
                        style={{
                          width: "100%",
                          maxHeight: "500px",
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 mb-20 pl pr reattempt-footer ">
                {/* <button
                                    type="submit"
                                    id="close"
                                    class="btn-4 pull-right"
                                    onClick={async () => {
                                        
                                        let acceptData = []
                                        console.log(this.state.mailData)
                                        let flag = false
                                        this.state.data.map(e => {
                                            if (e.additionalDetails.action == undefined) {
                                                flag = true
                                            }
                                        })
                                        this.state.data.map(e => {
                                            
                                            if (e.additionalDetails.action == "ACCEPT") {

                                                let obj = {
                                                    "action": "NISM EXAM DATE CONFIRM",
                                                    "workflowId": e.workflowId,
                                                  
                                                    "comment": "test"
                                                }
                                                acceptData.push(obj)
                                            }
                                        })

                                        if (this.state.data.length > 0 && flag == false) {

                                            ReattemptTestService.UpdateWorkflow(this.state.data, res => {

                                                success("Records submitted successfully.", successNotification);



                                                RegisterUserService.sendMail(this.state.mailData, res => {
                                                    this.setState({ mailData: [] })
                                                    window.location.reload();

                                                })




                                            }
                                            );
                                        }

                                        else {
                                            warning("Select atleast one option", warningNotification);
                                        }
                                        if (
                                            acceptData.length > 0
                                        ) {
                                            let workflowtemp = {
                                                "ExcecutionTracker": acceptData
                                            }
                                            updateStatus(workflowtemp, res1 => {
                                                window.location.reload()


                                            })
                                        }
                                        if (this.state.AcceptmailData.length > 0) {
                                            RegisterUserService.sendMail(this.state.AcceptmailData, res => {
                                                this.setState({ AcceptmailData: [] })


                                            })
                                        }

                                    }}
                                >
                                    Submit
</button> */}
              </div>
            </div>
          </div>
        </section>
        <Formik
          enableReinitialize={true}
          initialValues={{
            testDate: "",
            // "currDate": this.state.dataChange.length > 0 ? new Date(this.state.dataChange1.additionalDetails.requestedDate) : "",
            fromTime: "",
            toTime: "",
            listOfMonth: this.state.listOfMonth,
            listOfDay: this.state.listOfDay,

            day: "",
            month: "",
            year: "",
          }}
          validationSchema={Yup.object().shape({
            // testDate: Yup.string()
            //     .required('You must select a Test date'),
            //  day: Yup.string()
            //     .required('You must select a Test date'),
            fromTime: Yup.string().required("You must select from time"),
            toTime: Yup.string().required("You must select to time"),
          })}
          onSubmit={(fields) => {
            let testDate1 = "";
            if (
              (fields["year"] !== undefined && fields["year"] !== "") ||
              (fields["month"] !== undefined && fields["month"] !== "") ||
              (fields["day"] !== undefined && fields["day"] !== "")
            ) {
              testDate1 =
                fields["year"] + "-" + fields["month"] + "-" + fields["day"];
            } else {
              warning("Please select valid exam date", warningNotification);
              testDate1 = "";
            }

            if (testDate1 !== "") {
              let Date11 =
                this.state.dataChange1[0].additionalDetails.requestedDate.split(
                  "-"
                );
              let Date1 = Date11[0] + "-" + Date11[1] + "-" + Date11[2];

              let tempDate1 = new Date(testDate1);
              let tempDate2 = new Date(Date1);
              // if(tempDate1<tempDate2 ){
              //     warning('Selected Test date should be  greater than the existing  Test date.', warningNotification);
              //     testDate1=""

              // }

              let currentDateTime =
                this.state.dataChange1[0].createdDate.split(" ");
              let currentDate = currentDateTime[0];

              let splitCurrentDate = currentDate.split("/");
              var finalCreatedDate =
                splitCurrentDate[2] +
                "-" +
                splitCurrentDate[1] +
                "-" +
                splitCurrentDate[0];

              var someDate = new Date(finalCreatedDate);

              someDate.setDate(someDate.getDate() + 15); //number  of days to add, e.x. 15 days
              var dateFormated = someDate.toISOString().substr(0, 10);

              let tempDate3 = new Date(dateFormated);

              // Take created date , Add 15 days to it
              // if selected date is less than created date then user not allowed to go ahead

              if (tempDate1 < tempDate3) {
                let formattedDate = dateFormated.split("-");
                let dtToShow =
                  formattedDate[2] +
                  "-" +
                  formattedDate[1] +
                  "-" +
                  formattedDate[0];

                var toasterMsg =
                  "Selected date should be greater than " + dtToShow;
                warning(toasterMsg, warningNotification);
                testDate1 = "";
              } else {
                let selDate = new Date(testDate1);
                var date2 = new Date();
                var date2 = date2.setDate(date2.getDate() + 181);
                if (selDate > date2) {
                  warning(
                    "Date Should less than 180 days from current days.",
                    warningNotification
                  );
                } else {
                  // let selDate = fields['year'] + "-" + fields['month'] + "-" + fields['day']
                  // selDate = new Date(selDate)
                  // var date = new Date();

                  // if (selDate > date) {

                  var txt = "Are you sure you want to continue?";
                  var r = window.confirm(txt);
                  // console.log(this.state.dataChange[0])
                  if (r == true) {
                    this.setState({
                      loading1: "block",
                    });
                    let mailData = [
                      {
                        userName: this.state.dataChange[0].firstName,
                        selectedDate: moment(
                          this.state.dataChange1[0].additionalDetails
                            .requestedDate
                        ).format("YYYY-MM-DD"),
                        selectedNewDate: moment(testDate1).format("YYYY-MM-DD"),
                        emailId: this.state.dataChange[0].emailID,
                        templateId: "1960270",
                        status: "Potential_Distributor_Changed",
                        location:
                          this.state.dataChange1[0].additionalDetails.center,
                        panNumber:
                          this.state.dataChange[0].panNumber == null
                            ? "-"
                            : this.state.dataChange[0].panNumber,
                        learnerId: this.state.dataChange[0].learnerID,
                        fromTime: fields.fromTime,
                        toTime: fields.toTime,
                        partyId: this.state.dataChange[0].learnerPartyID,
                        // "requestedDate":moment( testDate1).format("YYYY-MM-DD"),
                        time: fields.fromTime + "-" + fields.toTime,
                      },
                    ];

                    let smsChange = [
                      {
                        userName: this.state.dataChange[0].firstName,
                        templateId: "40080",
                        mobileNumber: this.state.dataChange[0].mobileNo,
                        status: "Potential_Distributor_Changed",
                        partyId: this.state.dataChange[0].learnerPartyID,
                      },
                    ];

                    console.log(this.state.dataChange);

                    let temp = {
                      action: "NISM PROFILE CREATE",
                      // original.executionTracker[0].action,
                      workflowId: this.state.dataChange[0].learnerID,
                      additionalDetails: {
                        action: this.state.dataChange[0].action,
                        stateId: this.state.dataChange[0]
                          ? this.state.dataChange1[0].additionalDetails.stateId
                          : "",
                        center: this.state.dataChange[0]
                          ? this.state.dataChange1[0].additionalDetails.center
                          : "",
                        flag: this.state.dataChange[0]
                          ? this.state.dataChange1[0].additionalDetails.flag
                          : "",

                        location: this.state.dataChange[0]
                          ? this.state.dataChange1[0].additionalDetails.center
                          : "",
                        requestedDate: testDate1,
                        fromTime: fields.fromTime,
                        toTime: fields.toTime,
                        stateName: this.state.dataChange[0]
                          ? this.state.dataChange1[0].additionalDetails
                              .stateName
                          : "",
                        admitCardDocId: "",
                      },
                    };

                    let acceptData = [];

                    let obj = {
                      action: "NISM EXAM DATE CONFIRM",
                      workflowId: this.state.dataChange[0].learnerID,
                      workflowName: this.state.dataChange1[0].wfName,
                      comment: "",
                    };
                    acceptData.push(obj);

                    if (acceptData.length > 0) {
                      let workflowtemp = {
                        ExcecutionTracker: acceptData,
                      };
                      updateStatus(workflowtemp, (res1) => {
                        //                                    window.location.reload()
                        if (this.state.dataChange.length > 0) {
                          if (
                            this.state.dataChange1[0].additionalDetails.flag ==
                            true
                          ) {
                            let workflowtemp = {
                              ExcecutionTracker: [
                                {
                                  action: "ADMIT CARD AVAILABLE",
                                  workflowId:
                                    this.state.dataChange[0].learnerID,
                                  workflowName:
                                    this.state.dataChange1[0].wfName,

                                  comment: "",
                                  additionalDetails: {
                                    docId: 0,
                                  },
                                },
                              ],
                            };
                            updateStatus(workflowtemp, (res1) => {
                              //    window.location.reload();
                              setTimeout(() => {
                                window.location.reload();
                              }, 5000);
                              this.setState({
                                loading1: "none",
                              });
                              this.setState({
                                //  acceptStatusData: tempData,
                                dataChange: [],
                              });
                            });
                          } else {
                            setTimeout(() => {
                              window.location.reload();
                            }, 5000);
                            this.setState({
                              loading1: "none",
                            });
                          }
                        }
                      });
                    }

                    ReattemptTestService.UpdateWorkflow(temp, (res) => {
                      success(
                        "Request Changed Successfully",
                        successNotification
                      );

                      this.setState({
                        loading1: "none",
                      });
                      window.$("#changeStatus").modal("hide");

                      RegisterUserService.sendMail(mailData, (res) => {});

                      RegisterUserService.sendSms(smsChange, (res3) => {
                        // if(res3.status === "success"){
                        //     success("Request changed Successfully sms sent", successNotification);
                        // } else {
                        //     error("Request changed Successfully sms failed .", errorNotification);
                        // }
                      });
                    });
                  }
                  // } else {
                  //     warning("Please select Date Of Exam greater than current date", warningNotification);
                  //     //window.location.reload()
                  // }
                }
              }
            }
          }}
          render={({
            errors,
            touched,
            handleSubmit,
            setFieldValue,
            values,
          }) => (
            <React.Fragment>
              <div
                className="modal fade"
                id="changeStatus"
                tabindex="-1"
                role="dialog"
                aria-labelledby="modalLabel"
                data-keyboard="true"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-md" role="document">
                  <Form onSubmit={handleSubmit} className="form_name">
                    <div className="modal-content">
                      <div
                        id="loading-bar-spinner"
                        class="spinner"
                        style={{ display: this.state.loading1 }}
                      >
                        <div class="spinner-icon"></div>
                      </div>
                      <button
                        type="button"
                        class="close"
                        //   aria-label="Close"
                        onClick={(e) => {
                          window.$("#changeStatus").modal("hide");
                        }}
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>

                      <section className="registration">
                        <div className="modal-body">
                          <div
                            className="modal-head"
                            style={{
                              padding: "9px 8px",
                            }}
                          >
                            <div
                              className="section-title-black"
                              style={{ textAlign: "center" }}
                            >
                              <h2>Change Date</h2>
                            </div>
                          </div>
                          {/* {this.state.dataChange.length > 0 ? this.state.dataChange1.additionalDetails.requestedDate : ''} */}
                          {/* {this.state.dataChange.length > 0 ? moment(this.state.dataChange.additionalDetails.requestedDate).format("DD/MM/YYYY") : ''} */}
                          <div className="">
                            <div className="form-group">
                              <label>
                                Existing Exam Date{" "}
                                <span className="validation"> *</span>
                              </label>
                              <Field
                                type="text"
                                name="currDate"
                                id="currDate"
                                autoComplete="currDate"
                                disabled={true}
                                value={
                                  this.state.dataChange1
                                    ? this.state.dataChange1[0].additionalDetails.requestedDate.split(
                                        "-"
                                      )[2] +
                                      "-" +
                                      this.state.dataChange1[0].additionalDetails.requestedDate.split(
                                        "-"
                                      )[1] +
                                      "-" +
                                      this.state.dataChange1[0].additionalDetails.requestedDate.split(
                                        "-"
                                      )[0]
                                    : ""
                                }
                                //moment(this.state.dataChange1[0].additionalDetails.requestedDate,"YYYY-MM-DD").format("DD-MM-YYYY") : ''}
                                className={
                                  "form-control" +
                                  (errors.testDate && touched.testDate
                                    ? " is-invalid"
                                    : "")
                                }
                                // placeholder="Email ID"
                              />

                              {/* <DatePicker selected={new Date()}
                                            //   maxDate={new Date()}
                                            disabled={true}
                                            //  value={this.state.dataChange.length > 0 ? new Date(this.state.dataChange[0].executionTracker[0].additionalDetails.requestedDate) : ''}

                                            className={
                                                "form-control" +
                                                (errors.currDate &&
                                                    touched.currDate
                                                    ? " is-invalid"
                                                    : "")
                                            }

                                        />  */}
                              <label>
                                New Exam Date{" "}
                                <span className="validation"> *</span>
                              </label>
                              {/* <DatePicker selected={values.testDate}
                                                                autoComplete="off"
                                                                placeholderText="dd-mm-yyyy"
                                                                dateFormat="dd-MM-yyyy"
                                                                maxLength="50"
                                                                value={values.testDate}
                                                                onChangeRaw={event => {
                    
                                                                    let temp = event.target.value
                    
                                                                    if (temp !== undefined) {
                                                                        if (temp.includes("/")) {
                    
                                                                            setFieldValue(values.testDate = "")
                                                                            setFieldValue(values.errmsg1 = "Date should be in dd-mm-yyyy format.")
                    
                                                                        }
                                                                        else {
                                                                            // setFieldValue(values.toDate = date)
                                                                            setFieldValue(values.errmsg1 = "")
                    
                                                                        }
                                                                    }
                                                                }
                                                                }
                                                                onChange={date => {
                                                                    setFieldValue(values.errmsg1 = "")
                                                                    
                                                                    var GivenDate = this.state.dataChange.length > 0 ? this.state.dataChange1[0].additionalDetails.requestedDate : '';
                                                                    var CurrentDate = moment(date).format("YYYY-MM-DD")
                                                                    GivenDate = new Date(GivenDate);
                                                                    CurrentDate = new Date(CurrentDate);

                                                                    if (GivenDate >= CurrentDate) {
                                                                        warning('Selected Test date should be  greater than the existing  Test date.', warningNotification);
                                                                    } else {
                                                                        // setFieldValue(values.testDate, e.target.value)
                                                                        setFieldValue(values.testDate = date)


                                                                        setFieldValue(values.testDate1 = moment(date).format("YYYY-MM-DD"))

                                                                    }

                                                                    // setFieldValue(values.dateOfBirth = date)
                                                                }}
                                                                className={
                                                                    "form-control" +
                                                                    (errors.testDate &&
                                                                        touched.testDate
                                                                        ? " is-invalid"
                                                                        : "")
                                                                }

                                                            /> */}
                              <p className="validation"> {values.errmsg1}</p>

                              <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 pl pr">
                                <div className="col-md-3 col-lg-3 col-sm-3 col-xs-3 pl pr">
                                  <Field
                                    as="select"
                                    name="day"
                                    id="day"
                                    // onChange={handleChange}
                                    //  disabled={this.state.isDisabled}
                                    //  disabled
                                    onChange={(e) => {
                                      var listOfMonth = [];
                                      setFieldValue(
                                        (values.day = e.target.value)
                                      );
                                      if (e.target.value == 31) {
                                        listOfMonth = [
                                          { key: 1, value: "Jan" },

                                          { key: 3, value: "Mar" },

                                          { key: 5, value: "May" },

                                          { key: 7, value: "Jul" },
                                          { key: 8, value: "Aug" },

                                          { key: 10, value: "Oct" },

                                          { key: 12, value: "Dec" },
                                        ];
                                      } else if (e.target.value == 30) {
                                        listOfMonth = [
                                          { key: 1, value: "Jan" },

                                          { key: 3, value: "Mar" },
                                          { key: 4, value: "Apr" },
                                          { key: 5, value: "May" },
                                          { key: 6, value: "Jun" },
                                          { key: 7, value: "Jul" },
                                          { key: 8, value: "Aug" },
                                          { key: 9, value: "Sep" },
                                          { key: 10, value: "Oct" },
                                          { key: 11, value: "Nov" },
                                          { key: 12, value: "Dec" },
                                        ];
                                      } else if (
                                        e.target.value == 28 ||
                                        e.target.value == 29
                                      ) {
                                        listOfMonth = [
                                          { key: 1, value: "Jan" },
                                          { key: 2, value: "Feb" },
                                          { key: 3, value: "Mar" },
                                          { key: 4, value: "Apr" },
                                          { key: 5, value: "May" },
                                          { key: 6, value: "Jun" },
                                          { key: 7, value: "Jul" },
                                          { key: 8, value: "Aug" },
                                          { key: 9, value: "Sep" },
                                          { key: 10, value: "Oct" },
                                          { key: 11, value: "Nov" },
                                          { key: 12, value: "Dec" },
                                        ];
                                      } else {
                                        listOfMonth = [
                                          { key: 1, value: "Jan" },
                                          { key: 2, value: "Feb" },
                                          { key: 3, value: "Mar" },
                                          { key: 4, value: "Apr" },
                                          { key: 5, value: "May" },
                                          { key: 6, value: "Jun" },
                                          { key: 7, value: "Jul" },
                                          { key: 8, value: "Aug" },
                                          { key: 9, value: "Sep" },
                                          { key: 10, value: "Oct" },
                                          { key: 11, value: "Nov" },
                                          { key: 12, value: "Dec" },
                                        ];
                                      }
                                      setFieldValue(
                                        (values.listOfMonth = listOfMonth)
                                      );
                                      // this.setState({
                                      //   listOfMonth: listOfMonth
                                      // })
                                    }}
                                    className={
                                      "form-control" +
                                      (errors.day && touched.day
                                        ? "is-invalid"
                                        : "")
                                    }
                                    className="form-control"
                                  >
                                    <option value="">DD</option>
                                    {(values.listOfDay || []).map((d) => (
                                      <option value={d}>{d}</option>
                                    ))}
                                  </Field>
                                </div>
                                <div className="col-md-4 col-lg-4 col-sm-4 col-xs-4 pl pr">
                                  <Field
                                    as="select"
                                    name="month"
                                    id="month"
                                    //   onChange={handleChange}
                                    //     disabled={this.state.isDisabled}
                                    //  disabled
                                    onChange={(e) => {
                                      setFieldValue(
                                        (values.month = e.target.value)
                                      );
                                      if (e.target.value == 2) {
                                        var listOfDay = [];
                                        for (var i = 1; i <= 29; i++) {
                                          //    var current = year - i;
                                          listOfDay.push(i);
                                          // this.setState({ listOfDay: listOfDay });
                                        }
                                      } else if (
                                        e.target.value == 1 ||
                                        e.target.value == 3 ||
                                        e.target.value == 5 ||
                                        e.target.value == 7 ||
                                        e.target.value == 8 ||
                                        e.target.value == 10 ||
                                        e.target.value == 12
                                      ) {
                                        var listOfDay = [];
                                        for (var i = 1; i <= 31; i++) {
                                          //    var current = year - i;
                                          listOfDay.push(i);
                                          //  this.setState({ listOfDay: listOfDay });
                                        }
                                      } else {
                                        var listOfDay = [];
                                        for (var i = 1; i <= 30; i++) {
                                          //    var current = year - i;
                                          listOfDay.push(i);
                                          //  this.setState({ listOfDay: listOfDay });
                                        }
                                      }
                                      setFieldValue(
                                        (values.listOfDay = listOfDay)
                                      );
                                    }}
                                    className={
                                      "form-control" +
                                      (errors.month && touched.month
                                        ? "is-invalid"
                                        : "")
                                    }
                                    className="form-control"
                                  >
                                    <option value="">MM</option>
                                    {(values.listOfMonth || []).map((m) => (
                                      <option value={m.key}>{m.value}</option>
                                    ))}
                                  </Field>
                                </div>
                                <div className="col-md-5 col-lg-5 col-sm-5 col-xs-5 pl pr">
                                  <Field
                                    as="select"
                                    name="year"
                                    id="year"
                                    // onChange={handleChange}
                                    //    disabled={this.state.isDisabled}
                                    //  disabled
                                    className={
                                      "form-control" +
                                      (errors.year && touched.year
                                        ? "is-invalid"
                                        : "")
                                    }
                                    className="form-control"
                                  >
                                    <option value="">YYYY</option>
                                    {(this.state.listOfYear2 || []).map(
                                      (year) => (
                                        <option value={year}>{year}</option>
                                      )
                                    )}
                                  </Field>
                                </div>
                              </div>

                              {/* <Field type="date" name="testDate"
                                            id="testDate"
                                            autoComplete="testDate"
                                            min={this.state.dataChange.length > 0 ? moment(this.state.dataChange[0].executionTracker[0].additionalDetails.requestedDate).format("YYYY-MM-DD") : ''}
                                            onChange={
                                                e => {
                                                    var GivenDate = this.state.dataChange.length > 0 ? this.state.dataChange[0].executionTracker[0].additionalDetails.requestedDate : '';
                                                    var CurrentDate = e.target.value;
                                                    GivenDate = new Date(GivenDate);
                                                    CurrentDate = new Date(CurrentDate);

                                                    if (GivenDate >= CurrentDate) {
                                                        warning('Selected Test date should be  greater than the existing  Test date.', warningNotification);
                                                    } else {
                                                        // setFieldValue(values.testDate, e.target.value)
                                                        setFieldValue(
                                                            (values.testDate = e.target.value)
                                                        );
                                                    }
                                                }
                                            }
                                            className={
                                                "form-control" +
                                                (errors.testDate && touched.testDate
                                                    ? " is-invalid"
                                                    : "")
                                            }
                                        // placeholder="Email ID"
                                        /> */}
                              <ErrorMessage
                                name="day"
                                className="validation"
                                component="div"
                              />
                              <label>
                                From Time
                                <span className="validation"> *</span>
                              </label>
                              <Field
                                type="time"
                                name="fromTime"
                                id="fromTime"
                                //  disabled={this.state.disabledAll}
                                autoComplete="fromTime"
                                onBlur={(e) => {
                                  if (
                                    e.target.value !== "" &&
                                    values.toTime !== ""
                                  ) {
                                    if (e.target.value >= values.toTime) {
                                      //warning("", warningNotification)
                                      warning(
                                        "From Time should be less than To Time",
                                        warningNotification
                                      );
                                    } else {
                                      setFieldValue(
                                        (values.fromTime = e.target.value)
                                      );
                                    }
                                  }
                                }}
                                disabled={
                                  values.disabled == "true"
                                    ? true
                                    : this.state.disabledAll
                                }
                                className={
                                  "form-control" +
                                  (errors.fromTime && touched.fromTime
                                    ? " is-invalid"
                                    : "")
                                }
                              />
                              <ErrorMessage
                                name="fromTime"
                                className="validation"
                                component="div"
                              />
                              <label>
                                To Time
                                <span className="validation"> *</span>
                              </label>
                              <Field
                                type="time"
                                name="toTime"
                                id="toTime"
                                //  disabled={this.state.disabledAll}
                                autoComplete="toTime"
                                onBlur={(e) => {
                                  if (
                                    e.target.value !== "" &&
                                    values.fromTime !== ""
                                  ) {
                                    if (e.target.value <= values.fromTime) {
                                      warning(
                                        "From Time should be less than To Time",
                                        warningNotification
                                      );
                                      setFieldValue((values.toTime = ""));
                                    } else {
                                      setFieldValue(
                                        (values.toTime = e.target.value)
                                      );
                                    }
                                  }
                                }}
                                disabled={
                                  values.disabled == "true"
                                    ? true
                                    : this.state.disabledAll
                                }
                                className={
                                  "form-control" +
                                  (errors.toTime && touched.toTime
                                    ? " is-invalid"
                                    : "")
                                }
                              />
                              <div className="col-md-12 col-sm-12 pl">
                                <span class="file-size-limit validation">
                                  Note: Please provide Time in 24hrs format
                                </span>
                              </div>
                              <ErrorMessage
                                name="toTime"
                                className="validation"
                                component="div"
                              />
                            </div>
                          </div>
                          {/* <div>
                                                    Please provide Time in 24hrs format
                                                    </div> */}
                          <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 mb-20 text-center">
                            {/* <center> */}

                            <button type="submit" className="btn-5 mr-20">
                              Submit
                            </button>
                            {/* <button type="button" className="btn-5 mt-10" onClick={
                                    e=>{
                                        setFieldValue(
                                            (values.emailId = " ")
                                          );
                                          this.handleClose()
                                    }
                                }>Cancel</button> */}
                            {/* </center> */}
                          </div>
                          <br />
                          <div style={{ textAlign: "center" }}>
                            <h5> </h5>
                          </div>
                          <br /> <br />
                        </div>
                      </section>
                    </div>
                  </Form>
                </div>
              </div>
            </React.Fragment>
          )}
        />
        <Formik
          enableReinitialize={true}
          initialValues={{
            fromTime: "",
            toTime: "",
            picked: this.state.acceptFlag,

            testDate:
              this.state.acceptData.length > 0
                ? new Date(
                    this.state.acceptData[0].additionalDetails.requestedDate
                  )
                : "",

            location:
              this.state.acceptData.length > 0 &&
              this.state.acceptData[0].additionalDetails.stateId !== ""
                ? this.state.acceptData[0].additionalDetails.stateId
                : "",
            center:
              this.state.acceptData.length > 0 &&
              this.state.acceptData[0].additionalDetails.center !== ""
                ? this.state.acceptData[0].additionalDetails.center
                : "",
          }}
          validationSchema={
            this.state.acceptFlag === false
              ? Yup.object().shape({
                  fromTime: Yup.string().required("You must select from time"),
                  toTime: Yup.string().required("You must select to time"),
                  location: Yup.string().required("You must specify location"),
                  center: Yup.string().required("You must specify center"),
                })
              : Yup.object().shape({
                  fromTime: Yup.string().required("You must select from time"),
                  toTime: Yup.string().required("You must select to time"),
                })
          }
          onSubmit={(fields) => {
            debugger;
            //alert("ddfgdgfdgdg"+ )
            // var data1 = localStorage.getItem("login")
            // var data2 = localStorage.getItem("PersonnelData")

            // let tempData = []
            // let data = {

            //     "fromTime": fields.fromTime,
            //     "toTime": fields.toTime,
            //     "learnerID": this.state.acceptData[0].learnerID

            // }
            // tempData.push(data)

            // this.setState({
            //     acceptStatusData: tempData,
            //     acceptData: []
            // })
            // window.$('#confirmation').modal('show')
            // if (this.state.isConfirm == true) {

            // console.log("this.state.acceptData1 ::: "+ JSON.stringify(this.state.acceptData1))
            //console.log("sneha ::: "+ JSON.stringify(this.state.acceptData1[0].learnerWorkflowMap[0].status))
if(this.state.examDay===""){
debugger
    fields["day"] 
    // === undefined ?this.state.dataChange1[0].additionalDetails.requestedDate.split("-")[2]= this.state.examDay:fields["day"]
    =this.state.examDay=this.state.dataChange1[0].additionalDetails.requestedDate.split("-")[2];
    fields["month"]
    // ===undefined ?this.state.dataChange1[0].additionalDetails.requestedDate.split("-")[1]= this.state.examDay:fields["month"]
    =this.state.examMonth=this.state.dataChange1[0].additionalDetails.requestedDate.split("-")[1];
    fields["year"]
    // ===undefined ?this.state.dataChange1[0].additionalDetails.requestedDate.split("-")[0]= this.state.examDay:fields["year"]
    =this.state.examYear=this.state.dataChange1[0].additionalDetails.requestedDate.split("-")[0];
}else{
    fields["day"] =this.state.examDay;
    fields["month"] =this.state.examMonth;
    fields["year"]=this.state.examYear;
}
            if (this.state.acceptFlag === true) {
              fields["stateId"] = "";
              fields["stateName"] = "";
              fields["center"] = "";
            }
            console.log("fields:::", fields);
            if (
              fields['year'] === undefined ||
              fields['year'] === '' ||
              (fields['month'] === undefined || fields['month'] === '') ||
              (fields['day'] === undefined || fields['day'] === '')
            //   this.state.dataChange1 === undefined ||
            //   this.state.dataChange1 === ""
              //   ? this.state.dataChange1[0].additionalDetails.requestedDate.split("-")[2]
              // +"-"+this.state.dataChange1[0].additionalDetails.requestedDate.split("-")[1]+
              // "-"+this.state.dataChange1[0].additionalDetails.requestedDate.split("-")[0]:''
            ) {
              warning("Please select Date Of Exam", warningNotification);
            } else {
              // var stage = JSON.stringify(this.state.acceptData[0].action)
              var stage = JSON.stringify(
                this.state.acceptData1[0].learnerWorkflowMap[0].status
              );
              var newStr = stage.replaceAll('"', "");
              if (newStr === "NISM PROFILE CREATED") {
                let selDate =
            //     this.state.dataChange1[0].additionalDetails.requestedDate.split("-")[2]
            //   +"-"+this.state.dataChange1[0].additionalDetails.requestedDate.split("-")[1]+
            //   "-"+this.state.dataChange1[0].additionalDetails.requestedDate.split("-")[0]
                  fields["year"] + 
                  "-" + fields["month"] + 
                  "-" + fields["day"];
                selDate = new Date(selDate);
                var date = new Date();
debugger
                if (selDate > date) {
                // if (selDate !== date) {
                  // var txt = "Please Comfirm Following Details-1.Selected Timing is " + fields.fromTime + " to " + fields.toTime
                  var txt = "Are you sure you want to continue?";
                  var r = window.confirm(txt);
                  if (r == true) {
                    this.setState({
                      loading1: "block",
                    });

                    let fTime = fields.fromTime;
                    let Ttime = fields.toTime;
                    //this.state.acceptDataUpload[0].executionTracker ? this.state.acceptDataUpload[0].executionTracker[0].additionalDetails.toTime : ''

                    let mailData = [
                      {
                        userName: this.state.acceptData1[0].firstName,
                        panNumber:
                          this.state.acceptData1[0].panNumber == null
                            ? "-"
                            : this.state.acceptData1[0].panNumber,
                        emailId: this.state.acceptData1[0].emailID,
                        templateId: "1960260",
                        mobileNumber: this.state.acceptData1[0].mobileNo,
                        status: "NISM_Exam_confirm",
                        location:
                          fields.center == undefined ? "" : fields.center,
                        selectedDate:
                          fields["year"] +
                          "-" +
                          fields["month"] +
                          "-" +
                          fields["day"],
                        partyId: this.state.acceptData1[0].learnerPartyID,
                        ceilopsPartyId: JSON.parse(localStorage.login).partyId,
                        learnerId: this.state.acceptData1[0].learnerID,
                        time: fTime + "-" + Ttime,
                        // "location": this.state.acceptDataUpload[0].executionTracker[0].additionalDetails.center,
                        // "requestedDate": this.state.acceptDataUpload[0].executionTracker ? this.state.acceptDataUpload[0].executionTracker[0].additionalDetails.requestedDate.split(" ")[0] : '',
                        //    "fromTime": this.state.acceptDataUpload[0].executionTracker ? this.state.acceptDataUpload[0].executionTracker[0].additionalDetails.fromTime : '',
                        //    "toTime": this.state.acceptDataUpload[0].executionTracker ? this.state.acceptDataUpload[0].executionTracker[0].additionalDetails.toTime : '',

                        //  "requestedDate":moment( this.state.acceptData[0].additionalDetails.requestedDate).format("YYYY-MM-DD"),
                        //  "selectedDate": newDate !== "" ? newDate : original.executionTracker[0].additionalDetails.requestedDate
                      },
                    ];

                    let acceptedDate =
                      fields["year"] +
                      "-" +
                      fields["month"] +
                      "-" +
                      fields["day"];

                    let AcceptMailData = [
                      {
                        userName: this.state.acceptData1[0].firstName,
                        emailId: this.state.acceptData1[0].emailID,
                        templateId: "1960460",
                        status: "NISM_exam_date_accepted",
                        //"selectedDate": moment(this.state.acceptData[0].additionalDetails.requestedDate).format("YYYY-MM-DD"),
                        // "selectedDate": fields['year'] + "-" + fields['month'] + "-" + fields['day'],
                        selectedDate: moment(acceptedDate).format("YYYY-MM-DD"),
                        partyId: this.state.acceptData1[0].learnerPartyID,
                        location:
                          fields.center == undefined ? "" : fields.center,
                        time: fTime + "-" + Ttime,
                      },
                    ];

                    let smsAccept = [
                      {
                        userName: this.state.acceptData1[0].firstName,
                        templateId: "40070",
                        mobileNumber: this.state.acceptData1[0].mobileNo,
                        status: "NISM_exam_date_accepted",
                        partyId: this.state.acceptData1[0].learnerPartyID,
                      },
                    ];

                    let temp = {
                      action: "NISM PROFILE CREATE",
                      // original.executionTracker[0].action,
                      workflowId: this.state.acceptData1[0].learnerID,
                      additionalDetails: {
                        action: this.state.acceptData1[0].action,
                        // stateId: this.state.acceptData[0]
                        //     ? this.state.acceptData[0].additionalDetails.stateId
                        //     : '',
                        stateId:
                          fields.location == undefined ? "" : fields.location,
                        // center: this.state.acceptData[0]
                        //     ? this.state.acceptData[0].additionalDetails.center
                        //     : '',
                        center: fields.center == undefined ? "" : fields.center,
                        // flag: this.state.acceptData[0]
                        //     ? this.state.acceptData[0].additionalDetails.flag
                        //     : '',
                        flag: this.state.acceptFlag,
                        //location: this.state.acceptData[0].additionalDetails.center,
                        location:
                          fields.center == undefined ? "" : fields.center,
                        // "requestedDate": this.state.acceptData[0] ? this.state.acceptData[0].additionalDetails.requestedDate.split(" ")[0] : '',
                        requestedDate:
                          fields["year"] +
                          "-" +
                          fields["month"] +
                          "-" +
                          fields["day"],
                        fromTime: fields.fromTime,
                        toTime: fields.toTime,
                        // stateName: this.state.acceptData[0]
                        //     ? this.state.acceptData[0].additionalDetails.stateName
                        //     : '',
                        stateName:
                          this.state.stateName === ""
                            ? ""
                            : this.state.stateName,
                        admitCardDocId: "",
                      },
                    };

                    let acceptData = [];

                    let obj = {
                      action: "NISM EXAM DATE CONFIRM",
                      workflowId: temp.workflowId,
                      workflowName: this.state.acceptData[0].wfName,
                      comment: "",
                    };
                    acceptData.push(obj);
                    ReattemptTestService.UpdateWorkflow(temp, (res) => {
                      success(
                        "Request Accepted Successfully",
                        successNotification
                      );
                      this.setState({
                        loading1: "none",
                      });
                      window.$("#AcceptchangeStatus").modal("hide");
                    });
                    //    }

                    if (acceptData.length > 0) {
                      let workflowtemp = {
                        ExcecutionTracker: acceptData,
                      };
                      updateStatus(workflowtemp, (res1) => {
                        if (this.state.acceptData.length > 0) {
                          if (
                            this.state.acceptData[0].additionalDetails.flag ==
                            true
                          ) {
                            let workflowtemp = {
                              ExcecutionTracker: [
                                {
                                  action: "ADMIT CARD AVAILABLE",
                                  workflowId:
                                    this.state.acceptData1[0].learnerID,
                                  workflowName: this.state.acceptData[0].wfName,
                                  comment: "",
                                  additionalDetails: {
                                    docId: 0,
                                  },
                                },
                              ],
                            };

                            updateStatus(workflowtemp, (res1) => {
                              setTimeout(() => {
                                window.location.reload();
                              }, 4000);
                              this.setState({
                                loading1: "none",
                              });
                            });
                          } else {
                            setTimeout(() => {
                              window.location.reload();
                            }, 4000);
                            this.setState({
                              loading1: "none",
                            });
                          }
                        }
                      });
                    }

                    RegisterUserService.sendMail(mailData, (res) => {
                      //  this.setState({ mailData: [] })
                      //   window.location.reload();
                    });

                    RegisterUserService.sendMail(AcceptMailData, (res) => {
                      //this.setState({ AcceptmailData: [] })
                      //   window.location.reload()
                      // window.location.reload()
                    });

                    RegisterUserService.sendSms(smsAccept, (res3) => {
                      setTimeout(() => {
                        window.location.reload();
                      }, 4000);
                    });

                    //window.$('#AcceptchangeStatus').modal('hide')
                  }
                  // else {
                  //     window.$('#confirmation').modal('hide')
                  // }
                } else {
                  warning(
                    "Please select Date Of Exam greater than current date",
                    warningNotification
                  );
                  //window.location.reload()
                }
              } else {
                warning(
                  "Action is invalid for Selected Distributors Current Stage should be NISM PROFILE CREATED",
                  warningNotification
                );
              }
            }
          }}
          render={({
            errors,
            touched,
            handleSubmit,
            setFieldValue,
            values,
            handleChange,
          }) => (
            <React.Fragment>
              <div
                className="modal fade"
                id="AcceptchangeStatus"
                tabindex="-1"
                role="dialog"
                aria-labelledby="modalLabel"
                data-keyboard="true"
                aria-hidden="true"
              >
                <div
                  className="modal-dialog modal-md"
                  role="document"
                  id="date-request-popup"
                >
                  <Form onSubmit={handleSubmit} className="form_name">
                    <div className="modal-content">
                      <div
                        id="loading-bar-spinner"
                        class="spinner"
                        style={{ display: this.state.loading1 }}
                      >
                        <div class="spinner-icon" />
                      </div>
                      <button
                        type="button"
                        class="close"
                        onClick={(e) => {
                          window.$("#AcceptchangeStatus").modal("hide");
                        }}
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>

                      <section className="registration">
                        <div className="modal-body">
                          <div
                            className="modal-head"
                            style={{
                              padding: "9px 8px",
                            }}
                          >
                            <div
                              className="section-title-black"
                              style={{ textAlign: "center" }}
                            >
                              {/* <h2>Exam Date Sneha</h2> */}
                              <h2>Exam Date</h2>
                            </div>
                          </div>
                          <div className="">
                            <div className="form-group">
                              <label
                                class="form-check-label"
                                htmlFor="gridCheck"
                                style={{ marginRight: "15px" }}
                              >
                                Exam Mode
                                <span className="validation"> *</span>
                                <span className="validation">
                                                      {" "}
                                                      With effect from January 1, 2023 NISM will stop conducting remote proctored mode of exam (Online mode).
                                                      Please select an Offline mode only to  appear for the test center based examinations.
                                                    </span>
                              </label>

                              <div>
                                <sapn>
                                  <Field
                                    type="radio"
                                    // checked={
                                    //     this.state.acceptData.length > 0
                                    //         ? this.state.acceptData[0].additionalDetails
                                    //             .flag == true
                                    //             ? ''
                                    //             : 'checked'
                                    //         : 'offline'
                                    // }
                                    checked={
                                      values.picked === false ? "true" : ""
                                    }
                                    onClick={(e) => {
                                      this.setState({
                                        acceptFlag: true,
                                      });
                                      setFieldValue(
                                        (values.disabled = "false")
                                      );
                                      this.setState({
                                        acceptFlag: false,
                                      });
                                    }}
                                    name="picked"
                                    // disabled={true}
                                    value="offline"
                                    style={{ marginRight: "10px" }}
                                    className="form-check-input"
                                  />
                                  <label
                                    class="form-check-label"
                                    htmlFor="gridCheck"
                                    style={{ marginRight: "15px" }}
                                  >
                                    Offline
                                  </label>
                                </sapn>

                                <span>
                                  <Field
                                    // disabled
                                    type="radio"
                                    name="picked"
                                    value="online"
                                    // checked={
                                    //     this.state.acceptData.length > 0
                                    //         ? this.state.acceptData[0].additionalDetails
                                    //             .flag == true
                                    //             ? 'checked'
                                    //             : ''
                                    //         : 'offline'
                                    // }
                                    checked={
                                      values.picked === true ? "true" : ""
                                    }
                                    disabled={true}
                                    onClick={(e) => {
                                      setFieldValue((values.testDate = ""));
                                      setFieldValue((values.location = ""));
                                      setFieldValue((values.center = ""));
                                      setFieldValue((values.disabled = "true"));
                                      this.setState({
                                        acceptFlag: true,
                                      });
                                    }}
                                    className="form-check-input"
                                    style={{ marginRight: "10px" }}
                                    value="online"
                                  />
                                  <label
                                    class="form-check-label"
                                    htmlFor="gridCheck"
                                    style={{ marginRight: "15px" }}
                                  >
                                    Online
                                  </label>
                                  <ErrorMessage
                                    name="picked"
                                    className="validation"
                                    component="div"
                                  />
                                </span>
                              </div>
                              <label>
                                Existing Exam Date{" "}
                                <span className="validation"> *</span>
                              </label>
                              <Field
                                type="text"
                                name="currDate"
                                id="currDate"
                                autoComplete="currDate"
                                disabled={true}
                                value={
                                  this.state.dataChange1
                                    ? this.state.dataChange1[0].additionalDetails.requestedDate.split(
                                        "-"
                                      )[2] +
                                      "-" +
                                      this.state.dataChange1[0].additionalDetails.requestedDate.split(
                                        "-"
                                      )[1] +
                                      "-" +
                                      this.state.dataChange1[0].additionalDetails.requestedDate.split(
                                        "-"
                                      )[0]
                                    : ""
                                }
                                //moment(this.state.dataChange1[0].additionalDetails.requestedDate,"YYYY-MM-DD").format("DD-MM-YYYY") : ''}
                                className={
                                  "form-control" +
                                  (errors.testDate && touched.testDate
                                    ? " is-invalid"
                                    : "")
                                }
                                // placeholder="Email ID"
                              />

                              <label>
                                {" "}
                                Exam date 
                              </label>

                              <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 pl pr">
                                <div className="col-md-3 col-lg-3 col-sm-3 col-xs-3 pl pr">
                                  <Field
                                    as="select"
                                    name="day"
                                    id="day"
                                    value={this.state.examDay}
                                    onChange={(e) => {
                                      var listOfMonth = [];
                                      this.setState({
                                        examDay: e.target.value,
                                      },()=>{
                                        console.log(this.state.examDay); debugger
                                      }
                                      );
                                      setFieldValue(
                                        (values.day = e.target.value)
                                      );
                                      if (e.target.value == 31) {
                                        listOfMonth = [
                                          { key: 1, value: "Jan" },

                                          { key: 3, value: "Mar" },

                                          { key: 5, value: "May" },

                                          { key: 7, value: "Jul" },

                                          { key: 8, value: "Aug" },

                                          { key: 10, value: "Oct" },

                                          { key: 12, value: "Dec" },
                                        ];
                                      } else if (e.target.value == 30) {
                                        listOfMonth = [
                                          { key: 1, value: "Jan" },

                                          { key: 3, value: "Mar" },
                                          { key: 4, value: "Apr" },
                                          { key: 5, value: "May" },
                                          { key: 6, value: "Jun" },
                                          { key: 7, value: "Jul" },
                                          { key: 8, value: "Aug" },
                                          { key: 9, value: "Sep" },
                                          { key: 10, value: "Oct" },
                                          { key: 11, value: "Nov" },
                                          { key: 12, value: "Dec" },
                                        ];
                                      } else if (
                                        e.target.value == 28 ||
                                        e.target.value == 29
                                      ) {
                                        listOfMonth = [
                                          { key: 1, value: "Jan" },
                                          { key: 2, value: "Feb" },
                                          { key: 3, value: "Mar" },
                                          { key: 4, value: "Apr" },
                                          { key: 5, value: "May" },
                                          { key: 6, value: "June" },
                                          { key: 7, value: "Jul" },
                                          { key: 8, value: "Aug" },
                                          { key: 9, value: "Sep" },
                                          { key: 10, value: "Oct" },
                                          { key: 11, value: "Nov" },
                                          { key: 12, value: "Dec" },
                                        ];
                                      } else {
                                        listOfMonth = [
                                          { key: 1, value: "Jan" },
                                          { key: 2, value: "Feb" },
                                          { key: 3, value: "Mar" },
                                          { key: 4, value: "Apr" },
                                          { key: 5, value: "May" },
                                          { key: 6, value: "Jun" },
                                          { key: 7, value: "Jul" },
                                          { key: 8, value: "Aug" },
                                          { key: 9, value: "Sep" },
                                          { key: 10, value: "Oct" },
                                          { key: 11, value: "Nov" },
                                          { key: 12, value: "Dec" },
                                        ];
                                      }
                                      setFieldValue(
                                        (values.listOfMonth = listOfMonth)
                                      );
                                      this.setState({
                                        listOfMonth: listOfMonth,
                                      });
                                    }}
                                    className={
                                      "form-control" +
                                      (errors.day && touched.day
                                        ? "is-invalid"
                                        : "")
                                    }
                                    className="form-control"
                                  >
                                    <option value="">DD</option>
                                    {(this.state.listOfDay || []).map((d) => (
                                      <option value={d}>{d}</option>
                                    ))}
                                  </Field>
                                </div>
                                <div className="col-md-4 col-lg-4 col-sm-4 col-xs-4 pl pr">
                                  <Field
                                    as="select"
                                    name="month"
                                    id="month"
                                    // value={this.state.dataChange1?this.state.dataChange1[0].additionalDetails.requestedDate.split("-")[1]:''}
                                    onChange={(e) => {
                                      this.setState({
                                        examMonth: e.target.value,
                                      });
                                      setFieldValue(
                                        (values.month = e.target.value)
                                      );
                                      if (e.target.value == 2) {
                                        var listOfDay = [];
                                        for (var i = 1; i <= 29; i++) {
                                          listOfDay.push(i);
                                        }
                                      } else if (
                                        e.target.value == 1 ||
                                        e.target.value == 3 ||
                                        e.target.value == 5 ||
                                        e.target.value == 7 ||
                                        e.target.value == 8 ||
                                        e.target.value == 10 ||
                                        e.target.value == 12
                                      ) {
                                        var listOfDay = [];
                                        for (var i = 1; i <= 31; i++) {
                                          listOfDay.push(i);
                                        }
                                      } else {
                                        var listOfDay = [];
                                        for (var i = 1; i <= 30; i++) {
                                          listOfDay.push(i);
                                        }
                                      }
                                      setFieldValue(
                                        (values.listOfDay = listOfDay)
                                      );
                                    }}
                                    className={
                                      "form-control" +
                                      (errors.month && touched.month
                                        ? "is-invalid"
                                        : "")
                                    }
                                    className="form-control"
                                  >
                                    <option value="">MM</option>
                                    {(this.state.listOfMonth || []).map((m) => (
                                      <option value={m.key}>{m.value}</option>
                                    ))}
                                  </Field>
                                </div>
                                <div className="col-md-5 col-lg-5 col-sm-5 col-xs-5 pl pr">
                                  <Field
                                    as="select"
                                    name="year"
                                    id="year"
                                    // value={this.state.dataChange1?this.state.dataChange1[0].additionalDetails.requestedDate.split("-")[0]:''}
                                    className={
                                      "form-control" +
                                      (errors.year && touched.year
                                        ? "is-invalid"
                                        : "")
                                    }
                                    className="form-control"
                                    onChange={(e) => {
                                      this.setState({
                                        examYear: e.target.value,
                                      });
                                    }}
                                  >
                                    <option value="">YYYY</option>
                                    {(this.state.listOfYear1 || []).map(
                                      (year) => (
                                        <option value={year}>{year}</option>
                                      )
                                    )}
                                  </Field>
                                </div>
                              </div>

                              <br />
                              {this.state.acceptFlag === false ? (
                                <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 pl pr">
                                  <label
                                  // style={
                                  //     this.state.acceptData.length > 0
                                  //         ? this.state.acceptData[0].additionalDetails.flag ==
                                  //             true
                                  //             ? { display: 'none' }
                                  //             : { display: 'block' }
                                  //         : { display: 'block' }
                                  // }
                                  >
                                    Location{" "}
                                    <span className="validation"> *</span>
                                  </label>
                                  <span
                                  // style={
                                  //     this.state.acceptData.length > 0
                                  //         ? this.state.acceptData[0].additionalDetails.flag ==
                                  //             true
                                  //             ? { display: 'none' }
                                  //             : { display: 'block' }
                                  //         : { display: 'block' }
                                  // }
                                  >
                                    <Field
                                      as="select"
                                      name="location"
                                      id="location"
                                      onChange={(event) => {
                                        this.onChangeParameter1(
                                          event.target.value,
                                          setFieldValue
                                        );
                                        this.setState({
                                          stateName: event.target.value,
                                        });
                                        this.setState({
                                          centerListNew: [],
                                        });
                                      }}
                                      className={
                                        "form-control" +
                                        (errors.location && touched.location
                                          ? " is-invalid"
                                          : "")
                                      }
                                    >
                                      <option value="0">---Select----</option>
                                      {(this.state.stateListForPer || []).map(
                                        (team) => (
                                          <option
                                            key={team.stateId}
                                            value={team.stateId}
                                          >
                                            {team.stateName}
                                          </option>
                                        )
                                      )}
                                    </Field>
                                    <ErrorMessage
                                      name="location"
                                      component="div"
                                      className="validation"
                                    />

                                    {/* <Field
                                                                            type="text"
                                                                            name="location"
                                                                            id="location"
                                                                            autoComplete="location"
                                                                            disabled={true}
                                                                            // value={
                                                                            //     this.state.acceptData.length > 0
                                                                            //         ? this.state.acceptData[0].additionalDetails
                                                                            //             .stateId
                                                                            //         : ''
                                                                            // }
                                                                            value={
                                                                                this.state.loc
                                                                            }
                                                                            className={
                                                                                'form-control' +
                                                                                (errors.location && touched.location
                                                                                    ? ' is-invalid'
                                                                                    : '')
                                                                            }
                                                                        /> */}
                                  </span>
                                </div>
                              ) : (
                                ""
                              )}

                              {this.state.acceptFlag === false ? (
                                <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 pl pr">
                                  <label
                                  // style={
                                  //     this.state.acceptData.length > 0
                                  //         ? this.state.acceptData[0].additionalDetails.flag ==
                                  //             true
                                  //             ? { display: 'none' }
                                  //             : { display: 'block' }
                                  //         : { display: 'block' }
                                  // }
                                  >
                                    Center<span className="validation"> *</span>
                                  </label>
                                  <span
                                  // style={
                                  //     this.state.acceptData.length > 0
                                  //         ? this.state.acceptData[0].additionalDetails.flag ==
                                  //             true
                                  //             ? { display: 'none' }
                                  //             : { display: 'block' }
                                  //         : { display: 'block' }
                                  // }
                                  >
                                    <Field
                                      as="select"
                                      name="center"
                                      id="center"
                                      onChange={handleChange}
                                      className={
                                        "form-control" +
                                        (errors.center && touched.center
                                          ? " is-invalid"
                                          : "")
                                      }
                                    >
                                      <option value="">---Select----</option>
                                      {(this.state.centerListNew || []).map(
                                        (team) => (
                                          <option
                                            key={team.centerName}
                                            value={team.centerName}
                                          >
                                            {team.centerName}
                                          </option>
                                        )
                                      )}
                                    </Field>
                                    <ErrorMessage
                                      name="center"
                                      component="div"
                                      className="validation"
                                    />

                                    {/* <Field
                                                                            type="text"
                                                                            name="center"
                                                                            id="center"
                                                                            autoComplete="center"
                                                                            disabled={true}
                                                                            value={
                                                                                this.state.acceptData.length > 0
                                                                                    ? this.state.acceptData[0].additionalDetails
                                                                                        .center
                                                                                    : ''
                                                                            }
                                                                            className={
                                                                                'form-control' +
                                                                                (errors.center && touched.center
                                                                                    ? ' is-invalid'
                                                                                    : '')
                                                                            }
                                                                        /> */}
                                  </span>
                                </div>
                              ) : (
                                ""
                              )}

                              <label>
                                From Time
                                <span className="validation"> *</span>
                              </label>
                              <Field
                                type="time"
                                name="fromTime"
                                id="fromTime"
                                autoComplete="fromTime"
                                onBlur={(e) => {
                                  if (
                                    e.target.value !== "" &&
                                    values.toTime !== ""
                                  ) {
                                    if (e.target.value >= values.toTime) {
                                      warning(
                                        "From Time should be less than To Time",
                                        warningNotification
                                      );
                                    } else {
                                      setFieldValue(
                                        (values.fromTime = e.target.value)
                                      );
                                    }
                                  }
                                }}
                                // disabled={
                                //     values.disabled == 'true'
                                //         ? true
                                //         : this.state.disabledAll
                                // }
                                className={
                                  "form-control" +
                                  (errors.fromTime && touched.fromTime
                                    ? " is-invalid"
                                    : "")
                                }
                              />
                              <ErrorMessage
                                name="fromTime"
                                className="validation"
                                component="div"
                              />
                              <label>
                                To Time
                                <span className="validation"> *</span>
                              </label>
                              <Field
                                type="time"
                                name="toTime"
                                id="toTime"
                                autoComplete="toTime"
                                onBlur={(e) => {
                                  if (
                                    e.target.value !== "" &&
                                    values.fromTime !== ""
                                  ) {
                                    if (e.target.value <= values.fromTime) {
                                      warning(
                                        "From Time should be less than To Time",
                                        warningNotification
                                      );
                                      setFieldValue((values.toTime = ""));
                                    } else {
                                      setFieldValue(
                                        (values.toTime = e.target.value)
                                      );
                                    }
                                  }
                                }}
                                // disabled={
                                //     values.disabled == 'true'
                                //         ? true
                                //         : this.state.disabledAll
                                // }
                                className={
                                  "form-control" +
                                  (errors.toTime && touched.toTime
                                    ? " is-invalid"
                                    : "")
                                }
                              />
                              <div className="col-md-12 col-sm-12 pl">
                                <span class="file-size-limit validation">
                                  Note: Please provide Time in 24hrs format
                                </span>
                              </div>
                              <ErrorMessage
                                name="toTime"
                                className="validation"
                                component="div"
                              />
                            </div>
                          </div>
                          <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 mb-20 text-center">
                            <button type="submit" className="btn-5 mt-10">
                              Submit
                            </button>
                          </div>
                          <br />
                          <div style={{ textAlign: "center" }}>
                            <h5> </h5>
                          </div>
                          <br /> <br />
                        </div>
                      </section>
                    </div>
                  </Form>
                </div>
              </div>
            </React.Fragment>
          )}
        />
        ;
        <Formik
          initialValues={{
            document: [],
          }}
          onSubmit={(fields) => {
            // var data1 = localStorage.getItem("login")
            // var data2 = localStorage.getItem("PersonnelData")

            let mailData = this.state.mailData;
            let data = {
              userName: this.state.dataChange[0].firstName,
              selectedDate:
                this.state.dataChange[0].executionTracker[0].additionalDetails
                  .requestedDate,
              selectedNewDate: fields.testDate,
              emailId: this.state.dataChange[0].emailID,
              templateId: "1960270",
              status: "Potential_Distributor_Changed",
              location:
                this.state.dataChange[0].executionTracker[0].additionalDetails
                  .center,
              panNumber:
                this.state.dataChange[0].panNumber == null
                  ? "-"
                  : this.state.dataChange[0].panNumber,
              learnerId: this.state.dataChange[0].learnerID,
              fromTime: fields.fromTime,
              toTime: fields.toTime,
              partyId: this.state.dataChange[0].learnerPartyID,
            };
            mailData.push(data);

            window.$("#uploadAdmitCard").modal("hide");
          }}
          render={({
            errors,
            touched,
            handleSubmit,
            setFieldValue,
            values,
            handleChange,
          }) => (
            <React.Fragment>
              <div
                className="modal fade"
                id="uploadAdmitCard"
                tabindex="-1"
                role="dialog"
                aria-labelledby="modalLabel"
                data-keyboard="true"
                aria-hidden="true"
              >
                <div
                  className="modal-dialog modal-md"
                  role="document"
                  id="date-request-popup"
                >
                  <Form onSubmit={handleSubmit} className="form_name">
                    <div className="modal-content">
                      <button
                        type="button"
                        class="close"
                        aria-label="Close"
                        onClick={(e) => {
                          window.$("#uploadAdmitCard").modal("hide");
                          // window.location.reload()
                        }}
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>

                      <section className="registration">
                        <div className="modal-body">
                          <div
                            className="modal-head"
                            style={{
                              padding: "9px 8px",
                            }}
                          >
                            <div
                              className="section-title-black"
                              style={{ textAlign: "center" }}
                            >
                              <h2>Upload Admit Card</h2>
                            </div>
                          </div>
                          <div className="text-center">
                            <div className="form-group">
                              <label>
                                File Upload
                                <span className="validation"> *</span>
                              </label>

                              <div
                                className="profile-add"
                                style={{
                                  wordBreak: "break-all",
                                  padding: "0px 25px 11px 6px",
                                }}
                              >
                                {/* {values.docName ?
                                                <h4>{values.docName}</h4> : */}
                                <a className="icon btn-file">
                                  <FileBase64
                                    multiple={true}
                                    name="uploadAdmitCard"
                                    onChange={handleChange}
                                    onDone={(event) => {
                                      const checkType =
                                        event[0].name.split(".");
                                      // alert()
                                      if (
                                        [
                                          "jpg",
                                          "JPG",
                                          "jpeg",
                                          "JPEG",
                                          "png",
                                          "PNG",
                                          "pdf",
                                        ].indexOf(checkType[1]) < 0
                                      ) {
                                        warning(
                                          "Invalid file format",
                                          warningNotification
                                        );
                                      } else if (
                                        event[0].file.size <= 1000000
                                      ) {
                                        //
                                        // alert()

                                        values.document.push({
                                          documentHash: event[0].base64,
                                          documentType: "Admit Card",
                                          documentName: event[0].name,
                                        });
                                        console.log(values.document);
                                        setFieldValue(
                                          (values.docName = event[0].name)
                                        );
                                        //this.updateAddressWeightage("addressProofURL", event[0].name)
                                        // setFieldValue(
                                        //     (values.documentTypeCheckForaddressProofCardURLImage = false)
                                        // );
                                        // setFieldValue(
                                        //     (values.documentSizeCheckForaddressProofCardURLCard = false)
                                        // );
                                        // setFieldValue(
                                        //     (values.AressProofDocumentNameValidation = false)
                                        // );

                                        // //setFieldValue((values.panCardDocumentName = event[0].name));
                                        // setFieldValue(
                                        //     (values.addressProofCardURL =
                                        //         event[0].base64)
                                        // );
                                        // }
                                        // else {
                                        //     setFieldValue(
                                        //         (values.AressProofDocumentNameValidation = true)
                                        //     );

                                        //     //  warning("Document name should contains numbers, letters, hypens, spaces.", warningNotification);
                                        // }
                                      } else {
                                        warning(
                                          " File Size is too large(Maximum File Size:1MB).",
                                          warningNotification
                                        );
                                      }
                                    }}
                                  />
                                  <span>
                                    {/* <span className="icon">
                                                                        <i
                                                                            className="fa fa-times-circle"
                                                                            aria-hidden="true"
                                                                        ></i>
                                                                    </span> */}
                                    {values.docName ? (
                                      <h4>{values.docName}</h4>
                                    ) : (
                                      <h4>No File Chosen</h4>
                                    )}
                                  </span>

                                  <i
                                    className="fa fa-paperclip color_magento"
                                    aria-hidden="true"
                                  ></i>
                                  <p>
                                    Supported Formats .jpg, .jpeg, .png, .pdf
                                    only
                                    <br />
                                    File Size max 1 MB
                                  </p>
                                </a>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 mb-20 text-center">
                            {/* <center> */}

                            {/* <button type="submit" className="btn-5 mr-20">Submit</button> */}
                            <button type="button" className="btn-5 mt-10" onClick={
                                                            e => {
                                                                // alert("hii")
                                                                //  this.setState({loading1:"block"})
                                                                let exedocId = -1

                                                                console.log(this.state.acceptDataUpload)
                                                                let addData = []
                                                                if (this.state.acceptDataUpload[0].executionTracker.length > 0) {
                                                                    let e = this.state.acceptDataUpload[0].executionTracker
                                                                    for (let i = 0; i < e.length; i++) {
                                                                        //   if (e[i].action == "NISM PROFILE CREATE") {
                                                                        if (e[i].additionalDetails.hasOwnProperty("center")) {
                                                                            addData.push(e[i].additionalDetails)


                                                                        }

                                                                    }


                                                                }
                                                                console.log(addData)


                                                                if (values.document.length == 0) {
                                                                    warning("Please upload at least one document.", warningNotification)
                                                                }
                                                                else {
                                                                    //  if(values.document[0].documentName.split('.')[1])

                                                                    // this.state.acceptDataUpload[0].executionTracker.map(e => {
                                                                    //     if (e.additionalDetails.action == "ADMIT CARD AVAILABLE") {
                                                                    //         exedocId = e.additionalDetails.docId
                                                                    //     }
                                                                    // })
                                                                    for (let i = 0; i < this.state.acceptDataUpload[0].executionTracker.length; i++) {
                                                                        if (this.state.acceptDataUpload[0].executionTracker[i].action == "ADMIT CARD AVAILABLE") {
                                                                            exedocId = this.state.acceptDataUpload[0].executionTracker[i].additionalDetails.docId
                                                                        }
                                                                    }
                                                                    let partyIdData = JSON.parse(localStorage.getItem("login"))
                                                                    let data =
                                                                    {

                                                                        "storageLocation": "AWS",
                                                                        "documentType": "Admit Card",
                                                                        "partyId": partyIdData.partyId.toString(),
                                                                        "documentKey": partyIdData.partyId.toString(),
                                                                        "documentName": values.document[0].documentName,
                                                                        "replaceIfExists": "1",
                                                                        "documentHash": values.document[0].documentHash,
                                                                        "buId": "2",
                                                                        "bucketTag": Global_var.CONST_BUCKET_NAME,
                                                                        "documentTag": "Admit Card"
                                                                    }
                                                                    console.log(data)
                                                                    RegisterUserService.uploadDocumet(data, res => {


                                                                        // window.$('#uploadAdmitCard').modal('hide')
                                                                        let temp = []
                                                                        // let data1 = {
                                                                        //     docId: res.responseObject.documentId,
                                                                        //     learnerID: this.state.acceptDataUpload[0].learnerID
                                                                        // }
                                                                        // temp.push(data1)


                                                                        // let partyIdData = JSON.parse(localStorage.getItem("login"))
                                                                        let Maildata = [{
                                                                            "userName": this.state.acceptDataUpload[0].firstName,
                                                                            "emailId": this.state.acceptDataUpload[0].emailID,
                                                                            "templateId": "196039",
                                                                            "status": "Upload_Admit_Card",
                                                                            "mobileNumber": this.state.acceptDataUpload[0].mobileNo,
                                                                            "url": "https://www.expertmfd.com",
                                                                            "partyId": this.state.acceptDataUpload[0].learnerPartyID,
                                                                            "time": addData[0].fromTime + "-" + addData[0].toTime,
                                                                            "selectedDate": moment(addData[0].requestedDate).format("YYYY-MM-DD"),
                                                                            "location": addData[0].center
                                                                        }
                                                                        ]
                                                                        let admitCardData = [{
                                                                            "userName": this.state.acceptDataUpload[0].firstName,
                                                                            "templateId": "4010",
                                                                            "mobileNumber": this.state.acceptDataUpload[0].mobileNo,
                                                                            "status": 'Download_Admit_card',
                                                                            "partyId": this.state.acceptDataUpload[0].learnerPartyID,
                                                                        }]
                                                                        // let workflowtemp = {
                                                                        //     "action": "NISM PAYMENT COMPLETED",
                                                                        //     // original.executionTracker[0].action,
                                                                        //     "workflowId": this.state.acceptDataUpload[0].learnerID,
                                                                        //     "additionalDetails": {
                                                                        //         "action": this.state.acceptDataUpload[0].action,
                                                                        //         "stateId": this.state.acceptDataUpload[0].executionTracker ? this.state.acceptDataUpload[0].executionTracker[0].additionalDetails.stateId : '',
                                                                        //         "center": this.state.acceptDataUpload[0].executionTracker ? this.state.acceptDataUpload[0].executionTracker[0].additionalDetails.center : '',
                                                                        //         "flag": this.state.acceptDataUpload[0].executionTracker ? this.state.acceptDataUpload[0].executionTracker[0].additionalDetails.flag : '',
                                                                        //         //  "isconfirmbycilopps": original.executionTracker ? original.executionTracker[0].additionalDetails.isconfirmbycilopps : '',
                                                                        //         // "requestedDate":
                                                                        //         "location": this.state.acceptDataUpload[0].executionTracker[0].additionalDetails.center,
                                                                        //         "requestedDate": this.state.acceptDataUpload[0].executionTracker ? this.state.acceptDataUpload[0].executionTracker[0].additionalDetails.requestedDate.split(" ")[0] : '',
                                                                        //         "fromTime": this.state.acceptDataUpload[0].executionTracker ? this.state.acceptDataUpload[0].executionTracker[0].additionalDetails.fromTime : '',
                                                                        //         "toTime": this.state.acceptDataUpload[0].executionTracker ? this.state.acceptDataUpload[0].executionTracker[0].additionalDetails.toTime : '',
                                                                        //         "stateName": this.state.acceptDataUpload[0].executionTracker ? this.state.acceptDataUpload[0].executionTracker[0].additionalDetails.stateName : '',
                                                                        //         "admitCardDocId": res.responseObject.documentId,
                                                                        //     }
                                                                        // }
                                                                        if (exedocId == -1) {
                                                                            let workflowtemp = {
                                                                                "ExcecutionTracker": [
                                                                                    {
                                                                                        "action": "ADMIT CARD AVAILABLE",
                                                                                        "workflowId": this.state.acceptDataUpload[0].learnerID,
                                                                                        "workflowName": this.state.acceptDataUpload[0].executionTracker[0].wfName,

                                                                                        "comment": "",
                                                                                        "additionalDetails": {
                                                                                            "docId": res.responseObject.documentId,
                                                                                        }
                                                                                    }
                                                                                ]
                                                                            }
                                                                            updateStatus(workflowtemp, res1 => {
                                                                                RegisterUserService.sendSms(admitCardData, res3 => {
                                                                                    // if(res3.status === "success"){
                                                                                    //     success("Upload Admit Card sms sent", successNotification);
                                                                                    // } else {
                                                                                    //     error("Upload Admit Card sms failed .", errorNotification);
                                                                                    // }
                                                                                })

                                                                                //  this.setState({loading1:"none"})
                                                                                RegisterUserService.sendMail(Maildata, res2 => {
                                                                                    success("Admitcard uploaded successfully.", successNotification);
                                                                                    window.location.reload();

                                                                                    this.setState({ acceptDataUpload: [] })
                                                                                    // if (res.reasonText == "success") {
                                                                                    //     success("Action Changed successfully.", successNotification);
                                                                                    // }
                                                                                })




                                                                            })
                                                                        }
                                                                        else {
                                                                            let data =

                                                                                [{
                                                                                    "action": "ADMIT CARD AVAILABLE",
                                                                                    "workflowId": this.state.acceptDataUpload[0].learnerID,
                                                                                    "additionalDetails": {
                                                                                        "docId": res.responseObject.documentId,
                                                                                    }
                                                                                }]
                                                                            let workflowtemp = {
                                                                                "ExcecutionTracker": [
                                                                                    {
                                                                                        "action": "ADMIT CARD AVAILABLE",
                                                                                        "workflowId": this.state.acceptDataUpload[0].learnerID,
                                                                                        "workflowName": this.state.acceptDataUpload[0].executionTracker[0].wfName,
                                                                                        "comment": "",
                                                                                        "additionalDetails": {
                                                                                            "docId": res.responseObject.documentId,
                                                                                        }
                                                                                    }
                                                                                ]
                                                                            }
                                                                            updateStatus(workflowtemp, res1 => {

                                                                                //     this.setState({loading1:"none"})



                                                                            })






                                                                            ReattemptTestService.UpdateWorkflow(data, res => {

                                                                                // success("Admitcard uploaded successfully.", successNotification);
                                                                                RegisterUserService.sendSms(admitCardData, res3 => {
                                                                                    // if(res3.status === "success"){
                                                                                    //     success("Upload Admit Card sms sent", successNotification);
                                                                                    // } else {
                                                                                    //     error("Upload Admit Card sms failed .", errorNotification);
                                                                                    // }
                                                                                })

                                                                                RegisterUserService.sendMail(Maildata, res2 => {
                                                                                    success("Admitcard uploaded successfully.", successNotification);
                                                                                    window.location.reload();

                                                                                    this.setState({ acceptDataUpload: [] })
                                                                                    // if (res.reasonText == "success") {
                                                                                    //     success("Action Changed successfully.", successNotification);
                                                                                    // }
                                                                                })







                                                                            }
                                                                            );
                                                                        }
                                                                    })



                                                                    // this.handleClose()
                                                                }
                                                            }
                                                        }>Submit</button>
                            {/* </center> */}
                          </div>
                          <br />
                          <div style={{ textAlign: "center" }}>
                            <h5> </h5>
                          </div>
                          <br /> <br />
                        </div>
                      </section>
                    </div>
                  </Form>
                </div>
              </div>
            </React.Fragment>
          )}
        />
        <Formik
          initialValues={{
            document: [],
          }}
          render={({
            errors,
            touched,
            handleSubmit,
            setFieldValue,
            values,
            handleChange,
          }) => (
            <React.Fragment>
              <div
                className="modal fade"
                id="confirmation"
                tabindex="-1"
                role="dialog"
                aria-labelledby="modalLabel"
                data-keyboard="true"
                aria-hidden="true"
              >
                <div
                  className="modal-dialog modal-md"
                  role="document"
                  id="date-request-popup"
                >
                  <Form onSubmit={handleSubmit} className="form_name">
                    <div className="modal-content">
                      <button
                        type="reset"
                        class="close"
                        aria-label="Close"
                        onClick={(e) => {
                          window.$("#confirmation").modal("hide");
                        }}
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>

                      <section className="registration">
                        <div className="modal-body">
                          <div
                            className="modal-head"
                            style={{
                              padding: "9px 8px",
                            }}
                          >
                            <div
                              className="section-title-black"
                              style={{ textAlign: "center" }}
                            >
                              <h2>Confirmation!!!</h2>
                            </div>
                          </div>

                          <div className="text-center">
                            <div className="form-group">
                              Do you want to submit the data?
                              <div></div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 mb-20 text-center">
                          {/* <center> */}

                          {/* <button type="submit" className="btn-5 mr-20">Submit</button> */}
                          <button
                            type="button"
                            className="btn-5 mt-10"
                            onClick={(e) => {
                              this.setState({
                                isConfirm: false,
                              });
                              window.$("#confirmation").modal("hide");
                            }}
                          >
                            No
                          </button>
                          <button
                            type="button"
                            className="btn-5 mt-10"
                            onClick={(e) => {
                              this.setState({
                                isConfirm: true,
                              });
                              window.$("#confirmation").modal("hide");
                            }}
                          >
                            Yes
                          </button>
                          {/* </center> */}
                        </div>
                        <br />
                        <div style={{ textAlign: "center" }}>
                          <h5> </h5>
                        </div>
                        <br /> <br />
                      </section>
                    </div>
                  </Form>
                </div>
              </div>
            </React.Fragment>
          )}
        />
        <Formik
          initialValues={{
            data: [
              {
                nodeName: "Chapter 1 Growth Mindset",
                score: 0.0,
                nodeProgress: 0,
                nodeFirstAccessDate: null,
                nodeType: "Scorm 1.2",
                nodeStatus: "Not Started",
                nodeLastAccessDate: null,
                learningId: 435,
                nodeId: 1730,
              },
              {
                nodeName: "Chapter 2 Grit and Resilience",
                score: 0.0,
                nodeProgress: 0,
                nodeFirstAccessDate: null,
                nodeType: "Scorm 1.2",
                nodeStatus: "Not Started",
                nodeLastAccessDate: null,
                learningId: 435,
                nodeId: 1731,
              },
              {
                nodeName: "Chapter 3 Communication Presentation and Networking",
                score: 0.0,
                nodeProgress: 0,
                nodeFirstAccessDate: null,
                nodeType: "Scorm 1.2",
                nodeStatus: "Not Started",
                nodeLastAccessDate: null,
                learningId: 435,
                nodeId: 1732,
              },
              {
                nodeName: "Chapter 4 Grooming",
                score: 0.0,
                nodeProgress: 0,
                nodeFirstAccessDate: null,
                nodeType: "Scorm 1.2",
                nodeStatus: "Not Started",
                nodeLastAccessDate: null,
                learningId: 435,
                nodeId: 1733,
              },
              {
                nodeName: "Chapter 5 Time Management",
                score: 0.0,
                nodeProgress: 0,
                nodeFirstAccessDate: null,
                nodeType: "Scorm 1.2",
                nodeStatus: "Not Started",
                nodeLastAccessDate: null,
                learningId: 435,
                nodeId: 1734,
              },
              {
                nodeName:
                  "Session 1 - Investor Psychology; An Introduction - QUIZ",
                score: 0.0,
                nodeProgress: 0,
                nodeFirstAccessDate: null,
                nodeType: "Assessment",
                nodeStatus: "Not Started",
                nodeLastAccessDate: null,
                learningId: 435,
                nodeId: 1735,
              },
              {
                nodeName:
                  "Session 2 - Investor Psychology Understanding Biases and Avoiding Pitfalls_QUIZ",
                score: 0.0,
                nodeProgress: 0,
                nodeFirstAccessDate: null,
                nodeType: "Assessment",
                nodeStatus: "Not Started",
                nodeLastAccessDate: null,
                learningId: 435,
                nodeId: 1736,
              },
              {
                nodeName:
                  "Session 3 for Developing the RIGHT Mindset Consultative Selling_QUIZ",
                score: 0.0,
                nodeProgress: 0,
                nodeFirstAccessDate: null,
                nodeType: "Assessment",
                nodeStatus: "Not Started",
                nodeLastAccessDate: null,
                learningId: 435,
                nodeId: 1737,
              },
              {
                nodeName: "Session 4 - Risk, Return and Asset Allocation_ Quiz",
                score: 0.0,
                nodeProgress: 0,
                nodeFirstAccessDate: null,
                nodeType: "Assessment",
                nodeStatus: "Not Started",
                nodeLastAccessDate: null,
                learningId: 435,
                nodeId: 1738,
              },
              {
                nodeName: "Session 5 - Personal finance & investment planning",
                score: 0.0,
                nodeProgress: 0,
                nodeFirstAccessDate: null,
                nodeType: "Assessment",
                nodeStatus: "Not Started",
                nodeLastAccessDate: null,
                learningId: 435,
                nodeId: 1739,
              },
            ],
          }}
          onSubmit={(fields) => {
            // reattemptSendMail(data, this.props.history, res => {
            //   console.log(res)
            //   // this.props.history.push("/learnerDashboard");
            //   // window.location.reload()
            //   // if (res.status === "success") {
            //   //   success(
            //   //     "Mail send successfully",
            //   //     successNotification
            //   //   );
            //   //   this.props.history.push("/learnerDashboard");
            //   //   window.location.reload()
            //   // }
            //   // else {
            //   //   warning(
            //   //     "",
            //   //     warningNotification
            //   //   );
            //   // }
            // });
          }}
          render={({
            errors,
            touched,
            handleSubmit,
            setFieldValue,
            values,
          }) => (
            <React.Fragment>
              <div class="modal hide fade" id="myModal">
                <div class="modal-header">
                  <a class="close" data-dismiss="modal">
                    ×
                  </a>
                  <h3>Modal header</h3>
                </div>
                <div class="modal-body">
                  <p>One fine body…</p>
                </div>
                <div class="modal-footer">
                  <a href="#" class="btn">
                    Close
                  </a>
                  <a href="#" class="btn btn-primary">
                    Save changes
                  </a>
                </div>
              </div>
              <div
                className="modal fade"
                id="progressBarPopup"
                tabindex="-1"
                role="dialog"
                aria-labelledby="modalLabel"
                data-keyboard="true"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-lg" role="document">
                  <Form onSubmit={handleSubmit} className="form_name">
                    <div className="modal-content" id="autoScroll">
                      <button
                        type="reset"
                        class="close"
                        aria-label="Close"
                        onClick={(e) => {
                          window.$("#progressBarPopup").modal("hide");
                        }}
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>

                      <section className="">
                        <div className="modal-body">
                          <div classNames="">
                            <div
                              className="modal-head"
                              style={{
                                padding: "9px 8px",
                              }}
                            >
                              <div className="" style={{ textAlign: "center" }}>
                                <img
                                  src={ciellogo}
                                  className="img-responsive cielLogo"
                                />
                                {/* <h5 style={{ color: "black" }} >Company Slogan</h5> */}
                                <div></div>
                                {/* <h5 style={{ padding: "25px 10px" }} >This certificate is awaited to{" "}
                                                <b style={{ color: "black", fontSize: "20px" }}>{JSON.parse(Pdata).responseObject.firstName} {" "} {JSON.parse(Pdata).responseObject.lastName}
                                                </b> {" "}for successfully completing the NISM training</h5> */}
                              </div>
                            </div>
                            <div className="">
                              <center>
                                <h5 style={{ color: "black" }}>
                                  NISM VA Content Node{" "}
                                  {this.state.Scoredata.length > 0 &&
                                    this.state.Scoredata[0]
                                      .learnerlearningProgress}
                                  %
                                </h5>
                                <table id="progressstatusPopup">
                                  <tr></tr>
                                  {this.state.Scoredata.length > 0 &&
                                    this.state.Scoredata[0].learnernode.map(
                                      (e, index) => {
                                        return (
                                          <tr>
                                            <td>{e.nodeName}</td>
                                            <td>{e.score}</td>
                                            <td>{e.nodeStatus}</td>
                                          </tr>
                                        );
                                      }
                                    )}
                                </table>
                              </center>
                              {/* <button className="btn-5 mr-10" onClick={this.downloadAsPdf}>Download Scorecard
                                        </button> */}
                              {/* {flag == true ? <button className="btn-5" onClick={this.downloadAsCertificate}>Download Certificate  </button> : ''} */}

                              {/* <div style={{ textAlign: "right", paddingTop: "15px" }}>

                                                                <img src={CielSignature} className="img-responsive cielSign" />
                                            CIEL Signatory
                                                                </div> */}
                              <br />
                            </div>
                          </div>
                        </div>
                      </section>
                    </div>
                  </Form>
                </div>
              </div>
            </React.Fragment>
          )}
        />
      </React.Fragment>
    );
  }
}

export default dateRequest;