import React, { Component } from "react";
import Finxlogo from "../../assets/images/dapIcon.png";
// import Finxlogo from "../../assets/images/course_logo.png";
import $ from "jquery";
import ReactHTMLParser from "react-html-parser";
import { LoginService } from "../../services/login.service";
import { sessionerror, sessionNotification, warningNotification, warning, error, errorNotification, success, successNotification } from '../../components/notification/notifications';
import { getUserRoleAcess, getUserRoleAcessError, getUserRoleAcessPending } from "../../reducer/roleAccessReducer";
import { fetchUserRoleAccess } from '../../services/userAccess.service';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as moment from "moment";
import { FetchNotificationService } from "../../services/fetchnotification";
const mapStateToProps = state => ({
  userRoleAccess: getUserRoleAcess(state.roleAccessReducer),
  pendingUserRoleAccess: getUserRoleAcessPending(state.roleAccessReducer),
  errorUserRoleAccess: getUserRoleAcessError(state.roleAccessReducer),

});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      //fetchUserRoleAccess: fetchUserRoleAccess
    }, dispatch)

const connectFunction = connect(mapStateToProps, mapDispatchToProps);

const DashboardHeader = connectFunction(

  class extends Component {
    constructor(props) {
      super(props);
      //console.log("dashboard", props);

      this.state = {
        alertcount: "",
        notificationcount: "",
        alertNotificationEvents: JSON.parse(localStorage.getItem('AdminAlertNotification')),
        //notificationEvents: JSON.parse(localStorage.getItem('AdminNotification')),
        inboxCount:"",
        loading1: "none",
      };
      //localStorage.setItem('checkedtab',false);
    }

    componentDidMount() {
      this.setState({
        //loading1:"block"
      })
      let partyID = localStorage.getItem("ciel-userid-Token");
      FetchNotificationService.fetchNotification("partyId=" + partyID, (res1) => {
        this.setState({
          notificationEvents: res1.responseListObject,
          loading1:"none"
        })
      })

      let RoleId = localStorage.getItem('roleId')

      const { fetchUserRoleAccess } = this.props
      const roleDetails = {
        roleId: {
          roleId: RoleId
        }
      }
      //fetchUserRoleAccess(roleDetails);
      this.extentendedFun();

      let senderPartyId = localStorage.getItem("ciel-userid-Token");
    FetchNotificationService.getCount("senderPartyId=" + senderPartyId, (res1) => {
      if(res1.status==="success"){

        this.setState({
          inboxCount: res1?.responseObject?.unreadCount
        })
      }
    })


      $('button').bind('click', function (e) {
        if ($(e.target).closest('#navbar')?.length == 0) {
          // click happened outside of .navbar, so hide
          var opened = $('.navbar-collapse').hasClass('collapse in');
          if (opened === true) {
            $('.navbar-collapse').removeClass('in');
          }
        }
      });
      //   $('button').on('click', function () {
      //     $('.navbar-collapse').toggleClass('in')
      // });

      $('body').bind('click', function (e) {
        if ($(e.target).closest('#navbar')?.length == 0) {
          // click happened outside of .navbar, so hide
          var opened = $('.navbar-collapse').hasClass('collapse in');
          if (opened === true) {
            $('.navbar-collapse').removeClass('in');
          }
        }
      });
    }

    extentendedFun() {

      $(window).blur(function () {
        window.addEventListener('storage', e => {
          if (localStorage.getItem('userid-Token') === null && localStorage.getItem('login') === null) {
            if (localStorage.getItem("windowloggedout") === true) {
              window.location.href = '/';
            }
          }
          e.currentTarget.removeEventListener("storage", e)
        });
      });
      if (localStorage.getItem('userid-Token') !== null && localStorage.getItem('login') !== null) {
        if (localStorage.getItem('roleId') == 5) {
          //window.location.href = '/learnerDashboard';
          if (localStorage.getItem('checkedtab') === false) {
            localStorage.setItem('checkedtab', true);
            window.location.replace('/learnerDashboard');
          }
        }
        else {
          if (localStorage.getItem('checkedtab') === false) {
            localStorage.setItem('checkedtab', true);
            window.location.replace('/admin-dashboard');
          }
        }
      }
      else {
        if (localStorage.getItem('checkedtab') == 'false') {
          localStorage.setItem('checkedtab', false);
          window.location.href = '/';
        }
      }

      $(".popup10").click(function () {
        $("#myPopup10").toggleClass("show");
        setTimeout(function () {
          $('#myPopup10').toggleClass('show');
        }, 2000)
      });


      $(".popup11").click(function () {
        $("#myPopup11").toggleClass("show");
        setTimeout(function () {
          $('#myPopup11').toggleClass('show');
        }, 2000)
      });

      $(document).ready(function () {
        $(".popup13").click(function () {
          $("#myPopup13").toggleClass("show");
          setTimeout(function () {
            $('#myPopup13').toggleClass('show');
          }, 2000)
        });

        $("#myBtn").click(function () {
          $("#mySidenav").toggleClass("opensidenav");
          $(".dropdown-container").hide();
        });

        $(".popup14").click(function () {
          $("#myPopup14").toggleClass("show");
          setTimeout(function () {
            $('#myPopup14').toggleClass('show');
          }, 2000)
        });

        $(".popup15").click(function () {
          $("#myPopup15").toggleClass("show");
          setTimeout(function () {
            $('#myPopup15').toggleClass('show');
          }, 2000)
        });

        $(".popup16").click(function () {
          $("#myPopup16").toggleClass("show");
          setTimeout(function () {
            $('#myPopup16').toggleClass('show');
          }, 2000)
        });

        $(".popup17").click(function () {
          $("#myPopup17").toggleClass("show");
          setTimeout(function () {
            $('#myPopup17').toggleClass('show');
          }, 2000)
        });

        $(".popup18").click(function () {
          $("#myPopup18").toggleClass("show");
          setTimeout(function () {
            $('#myPopup18').toggleClass('show');
          }, 2000)
        });

        $(".popup19").click(function () {
          $("#myPopup19").toggleClass("show");
          setTimeout(function () {
            $('#myPopup19').toggleClass('show');
          }, 2000)
        });

        $(".popup20").click(function () {
          $("#myPopup20").toggleClass("show");
          setTimeout(function () {
            $('#myPopup20').toggleClass('show');
          }, 2000)
        });

        $(".popup21").click(function () {
          $("#myPopup21").toggleClass("show");
          setTimeout(function () {
            $('#myPopup21').toggleClass('show');
          }, 2000)
        });
        $(".popup22").click(function () {
          $("#myPopup22").toggleClass("show");
          setTimeout(function () {
            $('#myPopup22').toggleClass('show');
          }, 2000)
        });
        $(".popup23").click(function () {
          $("#myPopup23").toggleClass("show");
          setTimeout(function () {
            $('#myPopup23').toggleClass('show');
          }, 2000)
        });

      });

      //  $('body').on('click',function(event){
      //     if(!$(event.target).is('#myBtn')){
      //       $("#mySidenav").removeClass("opensidenav");
      //     }
      //  });

      //  $('#mySidenav').click(function(event){
      //     event.stopPropagation();
      // });  

      $('#return-to-top').click(function () {
        $('body,html').animate({
          scrollTop: 0
        }, 500);
      });

      var dropdown = document.getElementsByClassName("dropdown-btn");
      for (var i = 0; i < dropdown?.length; i++) {
        dropdown[i].addEventListener("click", function () {
          $(".dropdown-container").hide("active");

          this.classList.toggle("active");
          var dropdownContent = this.nextElementSibling;
          if (dropdownContent.style.display === "none") {
            dropdownContent.style.display = "block";
          } else {
            dropdownContent.style.display = "none";
          }

        });
      }

    }

    // comingsoon = () => {
    //   $(".popup23").click(function () {
    //     $("#myPopup23").toggleClass("show");
    //     setTimeout(function () {
    //       $('#myPopup23').toggleClass('show');
    //     }, 2000)
    //   });

    // };

    signout = event => {
      event.preventDefault();
      this.setState({ loading: true });
      if (localStorage.getItem('roleName') === "DISTRIBUTOR" && localStorage.getItem('login') !== null) {
        var data1 = localStorage.getItem("PersonnelData")
        var data = {
          userName: JSON.parse(data1).responseObject.emailID,
          // userName: JSON.parse(localStorage.getItem('login'))["userName"],
          userId: JSON.parse(localStorage.getItem('login'))["userId"]
        };

        LoginService.LogoutUser(data, false, res => {
          if (res.status === "success") {
            localStorage.clear();
            localStorage.setItem("windowloggedout", true)
            window.location.href = "/";
          } else {
            warning(res.reasonText, warningNotification);
          }
        });
      }
      else if (localStorage.getItem('roleName') === "CIEL OPS" && localStorage.getItem('login') !== null) {
        var data = {
          userName: JSON.parse(localStorage.getItem('login'))["userName"],
          userId: JSON.parse(localStorage.getItem('login'))["userId"]
        };

        LoginService.LogoutUser(data, false, res => {
          if (res.status === "success") {
            localStorage.clear();
            localStorage.setItem("windowloggedout", true)
            window.location.href = "/";
          }
          else {
            warning(res.reasonText, warningNotification);
          }
        });
      }
      else {
        window.location.href = '/';
      }
    };

    changepassword = () => {
      this.props.history.push("/changepassword", {
        alerts: this.state.alertNotificationEvents,
        notification: this.state.notificationEvents
      })
    };

    userprofile = () => {
      this.props.history.push("/userprofile", {
        alertNotificationEvents: this.state.alertNotificationEvents,
        notificationEvents: this.state.notificationEvents
      })
    }

    alerts = (key) => {
      if (this.state.alertNotificationEvents?.length > 0) {
        this.props.history.push("/myalerts", {
          alertid: key,
          alerts: this.state.alertNotificationEvents,
        })
      }
    };

    alert = () => {
      if (this.state.alertNotificationEvents?.length > 0) {
        this.props.history.push("/myalerts", {
          alertid: 0,
          alerts: this.state.alertNotificationEvents,
        })
      }
    }


    notifications = () => {


      var notealertdata = JSON.parse(localStorage.getItem("AdminNotification"));

      // alert(this.state.notificationEvents.length)
      if (this.state.notificationEvents?.length > 0 || notealertdata?.length > 0) {
        this.props.history.push("/my-inbox", {
          alertid: 0,
          notification: notealertdata
        })
      }
      else {
        warning("No record found", warningNotification)
      }
    }

    notification = (key, object) => {
      // console.log("object", object)
      // for(let i= 0; i< this.state.notificationEvents.length; i++){
      //   if(key=== this.state.notificationEvents[i].notifyHistoryId && this.state.notificationEvents[i].read_unread === false){
      //     let notifyHistory = {
      //       "notifyHistoryId": key,
      //     }
      //       FetchNotificationService.updateReadUnread(notifyHistory, (res2) => {
      //         if(res2.status === "success"){
      //         let senderPartyId = localStorage.getItem("ciel-userid-Token");
      //           FetchNotificationService.getCount("senderPartyId=" + senderPartyId, (res1) => {
      //             this.setState({
      //               inboxCount: res1.responseObject.unreadCount
      //             })
      //           })
      //         }
      //       })     
      //   }
      // }


      // if (this.state.notificationEvents.length > 0) {
      //   let arrayObj =[]
      //   arrayObj.push(object)
      //   this.props.history.push("/notification", {
      //   alertid: key,
      //   notification: arrayObj,
      //   value:"Inbox",
      //   inboxCount:this.state.inboxCount,
      //   })
      // }
      if(object.read_unread === false){
        // 
         //alert("kkk")
         let notifyHistory = {
           "notifyHistoryId": key,
         }
           FetchNotificationService.updateReadUnread(notifyHistory, (res2) => {
             //alert("ppp")
             if(res2.status === "success"){
             //  alert("lll")
             let senderPartyId = localStorage.getItem("ciel-userid-Token");
               FetchNotificationService.getCount("senderPartyId=" + senderPartyId, (res1) => {
                if(res1.status==="success"){
                  this.setState({
                    inboxCount: res1?.responseObject?.unreadCount
                  })
                }
               })
             }
           })     
       } 
       console.log("object ::", object)
     
       if (this.state.notificationEvents?.length > 0) {
         let arrayObj =[]
         arrayObj.push(object)
         this.props.history.push("/notification", {
           alertid: key,
           notification: arrayObj,
           value:"Inbox",
           inboxCount:this.state.inboxCount,
           
         })
          setTimeout(function () {
         window.location.reload()
          }, 1000)
       }
    
    }

    // alerts = (key) => {
    //   this.props.history.push("/myalerts", {
    //     alertid: key,
    //     alerts: this.props.alertNotificationEvents,
    //     notification: this.props.notificationEvents,
    //   })
    // };
    // alert = () => {
    //   this.props.history.push("/myalerts", {
    //     alerts: this.props.alertNotificationEvents,
    //     notification: this.props.notificationEvents,
    //   });
    // };
    // notifications = () => {
    //   this.props.history.push("/notification"
    //     , {
    //       alerts: this.props.alertNotificationEvents,
    //       notification: this.props.notificationEvents
    //     });
    // };
    // notification = key => {
    //   this.props.history.push("/notification", {
    //     alertid: key,
    //     alerts: this.props.alertNotificationEvents,
    //     notification: this.props.notificationEvents
    //   });
    // }
    order = () => {
      this.props.history.push("/my-orders", {
        alerts: this.props.alertNotificationEvents,
        notification: this.props.notificationEvents
      }
      )
    }

    inbox = () => {
      this.props.history.push("/my-inbox", {
        alerts: this.props.alertNotificationEvents,
        notification: this.props.notificationEvents
      }
      )
    }
    reattempt = () => {

      this.props.history.push("/PreassessmentView"
      )
    }
    dateRequest = () => {
      // this.props.history.push("/dateRequest"
      // )
      window.location.href = "/dateRequest"
    }

    updateScore = () => {
      // this.props.history.push("/dateRequest"
      // )
      window.location.href = "/uploadScore"
    }

    updateBulkPayment = () => {
      window.location.href = "/bulkPayment"
    }
    updateBulkExamDate = () => {
      window.location.href = "/bulkExamDate"
    }

    createMail = () => {

      this.props.history.push("/my-inbox-create", {
        alerts: this.props.alertNotificationEvents,
        notification: this.props.notificationEvents,
        userRoleAccess: this.props.userRoleAccess,

      }
      )
    }
    schedular = () => {
      // this.props.history.push("/dateRequest"
      // )
      window.location.href = "/Schedular"
    }
    cielReport = () => {
      window.location.href = "/ciel-report"
    }

    empanellementReport = () => {
      window.location.href = "/empanellement-report"
    }
    render() {
      // const {
      //   userRoleAccess,
      //   pendingUserRoleAccess,
      //   errorUserRoleAccess
      // } = this.props;

      var roledata = this.props.userRoleAccess;
      var alertdata = JSON.parse(localStorage.getItem("AdminAlertNotification"));
     // var notealertdata = JSON.parse(localStorage.getItem("AdminNotification"));
     var notealertdata = this.state.notificationEvents;
      //if (!pendingUserRoleAccess) {
      return (
        <div>
          <React.Fragment>
            {/* <!------ header section ----------> */}
            <section className="header">
              <nav className="navbar navbar navbar-fixed-top">
                <span id="myBtn" className="sidenva-icon sidebartogglexyz">
                  &#9776;
            </span>

                <div className="container-fluid">
                  {/* <!-- Brand/logo --> */}
                  <div className="navbar-header">
                    <button
                      type="button"
                      className="navbar-toggle collapsed"
                      data-toggle="collapse"
                      data-target="#navbar"
                      aria-expanded="false"
                      aria-controls="navbar"
                    >
                      <span className="sr-only">Toggle navigation</span>
                      <span className="icon-bar"></span>
                      <span className="icon-bar"></span>
                      <span className="icon-bar"></span>
                    </button>
                    {localStorage.getItem('roleId') == 5 ?
                      <a className="navbar-brand" href="/learnerdashboard">
                        <img src={Finxlogo} />
                      </a> : <a className="navbar-brand" href="/dashboard">
                        <img src={Finxlogo} />
                      </a>}
                  </div>
                  <button id="md-toggle" class="navbar-toggler hidden-sm hidden-xs" type="button" data-toggle="collapse" data-target="#navbar"
                    aria-controls="navbar" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="dark-blue-text"><i
                      class="fa fa-bars"></i></span>
                  </button>
                  <div id="navbar" className="navbar-collapse collapse">
                    <ul className="nav navbar-nav navbar-right">
                      {/* <li className="row">
                        <div className="right-search"> */}
                      {/* <!-- Search form --> */}
                      {/* <form >
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Search.."
                            name="search"
					              		disabled
                          />
                          <button type="submit">
                            <i className="fa fa-search"></i>
                          </button>
                        </form> */}
                      {/* </div>
                      </li> */}
                      {/* <li className="nav-item dropdown notification">
                        <a
                          className="nav-link text-light myPopup11"
                          // href="#"
                          className="notification"
                          id="navbarDropdown"
                          role="button"
                          // data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                          title="Alerts"
                        >
                          <i className="fa fa-bell-o" aria-hidden="true" ></i>
                          <span class="popuptext" id="myPopup11">Coming Soon...</span>
                        </a> */}
                      {/* <li className="nav-item dropdown notification">
                        <a
                          className="nav-link text-light popup11"
                          //href="#"
                          id="navbarDropdown"
                          role="button"
                          // data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                          title="Alerts"
                        >
                          <i className="fa fa-bell-o" aria-hidden="true"></i>
                          <span class="popuptext" id="myPopup11">Coming Soon...</span>
                        </a> */}

                      {/* <a
                          className="nav-link text-light"
                          // href="#"
                          className="notification"
                          id="navbarDropdown"
                          role="button"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                          title="Alerts"
                        >
                          <i className="fa fa-bell-o" ></i>
                           </a> */}
                      {/* <span className="badge hidden-xs">
                          {" "}
                          {(alertdata || []).length}{" "}
                        </span> */}

                      {/* <ul className="dropdown-menu" id="notification-alert">
                          <li className="head text-light bg-dark">
                            <div className="row">
                              <div className="col-lg-12 col-sm-12 col-12">
                                <span>Alerts({(alertdata || []).length})</span>
                              </div>
                            </div>
                          </li>
                          <li className="notification-box">
                            <div className="notification-content">
                              <div className="row">
                                <div class="col-lg-12 col-sm-12 col-12">
                                  {alertdata
                                    ? alertdata.slice(0, 3).map((item, key) => (
                                      <div
                                        className="notification-content"
                                        id={item.alertID}
                                      >
                                        <a
                                          onClick={() =>
                                            this.alerts(item.alertID)
                                          }
                                        >
                                          {" "}
                                          <div className="notification-body">

                                            <strong class="text-info">
                                              {item.alertName}{" "}
                                            </strong>

                                          </div>
                                        </a>
                                      </div>
                                    ))
                                    : ""}
                                </div>
                              </div>
                            </div>
                          </li>

                          <li className="footer bg-dark text-center">
                            <a onClick={this.alert} className="text-light">
                              View All
                        </a>
                          </li>
                        </ul>
                      </li> */}

                      <li className="nav-item dropdown notification" id="user-option">
                        <a
                          //onClick={this.signout}
                          className="nav-link text-light"
                          className="notification"
                          id="navbarDropdown"
                          role="button"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                          title="My Profile"
                        >
                          <i className="fa fa-user" aria-hidden="true"></i> Profile
                          <span class="popup23 popuptext" id="">Coming Soon...</span>
                        </a>

                        <ul className="dropdown-menu" id="notification-profile" style={{ width: 'auto !important' }}>
                          <li className="notification-box">
                            <div class="row">
                              <div class="panel-body">
                                <div className="notification-content">
                                  <div class="col-lg-12 col-sm-12 col-12">
                                    {localStorage.getItem('roleId') == 5 ?
                                      <a onClick={this.userprofile} className="text-light">
                                        My Profile</a> : ''}

                                    <a onClick={this.changepassword} className="text-light ">
                                      Change Password</a>

                                    <a onClick={this.signout} className="text-light">
                                      Sign Out</a>

                                    {/* <a onClick={e => {
                                      warning("Coming Soon", warningNotification
                                      )
                                    }} className="text-light popup23">
                                      Ask For Help
                                     </a> */}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </li>
                      <li className="nav-item dropdown notification">
                      {/* <div>this.state.inboxCount</div> */}
                        <a
                          className="nav-link text-light"
                          // href="#"
                          className="notification"
                          id="navbarDropdown"
                          role="button"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                          title="Messages"
                        >
                          <i className="fa fa-envelope-o" aria-hidden="true"></i> Messages ({this.state.inboxCount})


                        </a>
                        <ul className="dropdown-menu" id="notification-alert">
                          <li className="head text-light bg-dark">
                            <div className="row">
                              <div className="col-lg-12 col-sm-12 col-12">
                                <span>
                                  {/* Messages ({(notealertdata || []).length})({this.state.inboxCount}) */}
                                  Messages ({this.state.inboxCount})
                              </span>
                                <a href="" className="text-light pull-right"></a>
                              </div>
                            </div>
                          </li>

                          <li class="notification-box">
                            <div class="row">
                              <div class="panel-body">
                                <div class="col-lg-12 col-sm-12 col-12">
                                  {notealertdata
                                    ? notealertdata.slice(0, 3).map((item, key) => (
                                      <div
                                        className="notification-content"
                                        id={item.notifyHistoryId}
                                      >
                                        <a
                                          onClick={() =>
                                            this.notification(
                                              item.notifyHistoryId,item
                                            )
                                          }
                                        >
                                          <div className="notification-body">
                                            {/* <strong class="text-info unread"> */}
                                            <strong className={"text-info " +(item.read_unread ? 'unread': '' )}>
                                            {/* <div className={"btn-group pull-right " + (this.props.showBulkActions ? 'show' : 'hidden')}> */}

                                              {item.fromEmailId}
                                            </strong>
                                            <div class="notification-body">
                                              {ReactHTMLParser(
                                                item.emailSubjectText
                                              )}
                                            </div>
                                            <small class="text-warning">
                                              {moment(item.lastAttemptDatetime.split(" ")[0], "DD/MM/YYYY").format("DD-MM-YYYY") + " " + item.lastAttemptDatetime.split(" ")[1]}
                                            </small>
                                          </div>
                                        </a>
                                      </div>
                                    ))
                                    : ""}
                                </div>
                              </div>
                            </div>
                          </li>
                          <li className="footer bg-dark text-center">
                            <a
                              onClick={this.notifications}
                              className="text-light"
                            >
                              View All
                        </a>
                          </li>
                        </ul>
                      </li>

                      {/* <li className="nav-item dropdown notification">
                        <a
                          className="nav-link text-light popup22"
                          //href="#"
                          id="navbarDropdown"
                          role="button"
                          // data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                          title="Chat"
                        >
                          <i className="fa fa-comments-o" aria-hidden="true"></i>
                          <span class="popuptext" id="myPopup22">Coming Soon...</span>
                        </a>
                      </li> */}


                      {/* <li className="nav-item dropdown notification">
                        <a
                          className="nav-link text-light popup10"
                          // href="#"
                          id="navbarDropdown"
                          role="button"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                          title="Setting"
                        >
                          <i className="fa fa-cogs" aria-hidden="true"></i>
                          <span class="popuptext" id="myPopup10">Coming Soon...</span>

                        </a>
                      </li> */}
                      <li className="nav-item dropdown notification">
                        <a
                          onClick={this.signout}
                          className="nav-link text-light"
                          className="notification"
                          id="navbarDropdown"
                          role="button"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                          title="Sign-Out"
                        >
                          <i className="fa fa-sign-out" aria-hidden="true"></i> Sign Out
                        </a>
                      </li>
                    </ul>
                  </div>
                  {/* <!--/.nav-collapse --> */}
                </div>
              </nav>
            </section>

            {/* <!--------------- Left Side navbar -------------> */}
            <div id="mySidenav" className="sidenav sidebartogglexyz">

              {/* <a onClick={this.learnerDashboard}> */}
              {localStorage.getItem('roleId') == 5 ?
                <a href="/learnerDashboard" className="sidebartogglexyz">
                  <i className="fa fa-pie-chart" aria-hidden="true"></i> Dashboard
          </a>
                :
                <a href="/dashboard" className="sidebartogglexyz">
                  <i className="fa fa-pie-chart" aria-hidden="true"></i> Dashboard
    </a>}
              {localStorage.getItem("roleId") == 5 ?
                <div>
                  <button className="dropdown-btn sidebartogglexyz">
                    <i className="fa fa-book sidebartogglexyz" aria-hidden="true"></i> Reports
            <i className="fa fa-caret-down sidebartogglexyz"></i>
                  </button>
                  <div className="dropdown-container sidebartogglexyz">

                    <a onClick={this.order} className="sidebartogglexyz">
                      My Payment</a>

                  </div>

                </div> : ""}

              <div><a href="/my-inbox" className="sidebartogglexyz">
                <i className="fa fa-bell sidebartogglexyz" aria-hidden="true"></i> Notification Manager</a></div>


              {/* 
              <button className="dropdown-btn sidebartogglexyz">
                <i className="fa fa-leanpub sidebartogglexyz" aria-hidden="true"></i> Messages
            <i className="fa fa-caret-down sidebartogglexyz"></i>
              </button>  */}

              {/* <div className="dropdown-container sidebartogglexyz">
                {
                  
                  <div>
                   
                    <a onClick={this.inbox} className="sidebartogglexyz">
                      My Inbox</a>
                  
                  </div>
                  
                }
                <a onClick={this.createMail} className="sidebartogglexyz">
                  Compose</a>
                  </div> */}
              {/* <a className="popup13 sidebartogglexyz">My Inbox
              <span class="popuptext" id="myPopup13">Coming Soon...</span>
              </a> */}
              {/* <a className="popup14 sidebartogglexyz">Sent
                            <span class="popuptext" id="myPopup14">Coming Soon...</span>
                </a>
                <a className="popup15 sidebartogglexyz">Draft
                            <span class="popuptext" id="myPopup15">Coming Soon...</span>
                </a> */}


              {/* <a onclick={this.createMail} className="sidebartogglexyz">Create</a> */}
              {/* <span class="popuptext" id="myPopup16">Coming Soon...</span> */}

              {/* <button className="dropdown-btn sidebartogglexyz">
                <i className="fa fa-leanpub sidebartogglexyz" aria-hidden="true"></i> Referral Section
            <i className="fa fa-caret-down sidebartogglexyz"></i>
              </button>
              <div className="dropdown-container sidebartogglexyz">
                <a className="popup17 sidebartogglexyz">Generate New Link
                            <span class="popuptext" id="myPopup17">Coming Soon...</span>
                </a>
                <a className="popup18 sidebartogglexyz">Track Referral Bonus
                            <span class="popuptext" id="myPopup18">Coming Soon...</span>
                </a>
                <a className="popup19 sidebartogglexyz">Draft
                            <span class="popuptext" id="myPopup19">Coming Soon...</span>
                </a>
                <a className="popup20 sidebartogglexyz">Create
                            <span class="popuptext" id="myPopup20">Coming Soon...</span>
                </a>
              </div>
              <button className="dropdown-btn sidebartogglexyz">
                <i className="fa fa-leanpub sidebartogglexyz" aria-hidden="true"></i> Status
            <i className="fa fa-caret-down sidebartogglexyz"></i>
              </button>
              <div className="dropdown-container sidebartogglexyz">
                <a className="popup21 sidebartogglexyz">Status
                            <span class="popuptext" id="myPopup21">Coming Soon...</span>
                </a>
              </div> */}
              {localStorage.getItem('roleId') == 5 || localStorage.getItem('roleId') == 7 ? '' :
                <div>
                  <button className="dropdown-btn sidebartogglexyz">
                    <i className="fa fa-file sidebartogglexyz" aria-hidden="true"></i> Feat Details
            <i className="fa fa-caret-down sidebartogglexyz"></i>
                  </button>


                  <div className="dropdown-container sidebartogglexyz">
                    <a onClick={this.reattempt} className="sidebartogglexyz">
                      Reattempt Test</a>

                  </div>
                </div>
              }

              {localStorage.getItem('roleId') == 6 ?
                <div>
                  <button className="dropdown-btn sidebartogglexyz" >
                    <i className="fa fa-leanpub sidebartogglexyz" aria-hidden="true" ></i> NISM Exam Date Request
                    <i className="fa fa-caret-down sidebartogglexyz"></i>
                  </button>


                  <div className="dropdown-container sidebartogglexyz">
                    <a onClick={this.dateRequest} className="sidebartogglexyz">
                      NISM Exam Date Request List</a>

                  </div>
                </div>
                : ''}

              {localStorage.getItem('roleId') == 6 ?
                <div>
                  <button className="dropdown-btn sidebartogglexyz" >
                    <i className="fa fa-edit sidebartogglexyz" aria-hidden="true" ></i> Update NISM Scores
                    <i className="fa fa-caret-down sidebartogglexyz"></i>
                  </button>


                  <div className="dropdown-container sidebartogglexyz">
                    <a onClick={this.updateScore} className="sidebartogglexyz">
                      Update NISM Scores</a>

                  </div>
                </div>
                : ''}

                {localStorage.getItem('roleId') == 6 ?
                <div>
                  <button className="dropdown-btn sidebartogglexyz" >
                    <i className="fa fa-edit sidebartogglexyz" aria-hidden="true" ></i> Update Bulk Payment
                    <i className="fa fa-caret-down sidebartogglexyz"></i>
                  </button>


                  <div className="dropdown-container sidebartogglexyz">
                    <a onClick={this.updateBulkPayment} className="sidebartogglexyz">
                      Update Bulk Payment</a>

                  </div>
                </div>
                : ''}
                {localStorage.getItem('roleId') == 6 ?
                <div>
                  <button className="dropdown-btn sidebartogglexyz" >
                    <i className="fa fa-edit sidebartogglexyz" aria-hidden="true" ></i> Update Bulk Exam Date
                    <i className="fa fa-caret-down sidebartogglexyz"></i>
                  </button>


                  <div className="dropdown-container sidebartogglexyz">
                    <a onClick={this.updateBulkExamDate} className="sidebartogglexyz">
                      Update Bulk Exam Date</a>

                  </div>
                </div>
                : ''}

              {localStorage.getItem('roleId') == 6 ?
                <div>
                  <button className="dropdown-btn sidebartogglexyz" >
                    <i className="fa fa-calendar sidebartogglexyz" aria-hidden="true" ></i> Schedular
    <i className="fa fa-caret-down sidebartogglexyz"></i>
                  </button>


                  <div className="dropdown-container sidebartogglexyz">
                    <a onClick={this.schedular} className="sidebartogglexyz">
                      Schedular</a>

                  </div>
                </div>
                : ''}
              {localStorage.getItem('roleId') == 6 ?
                <div>
                  <button className="dropdown-btn sidebartogglexyz" >
                    <i className="fa fa-book sidebartogglexyz" aria-hidden="true" ></i> Reports
    <i className="fa fa-caret-down sidebartogglexyz"></i>
                  </button>


                  <div className="dropdown-container sidebartogglexyz">
                    <a onClick={this.cielReport} className="sidebartogglexyz">
                    Distributor Details</a>

                  </div>

                </div>
                : ''}

                {localStorage.getItem('roleId') == 6 ?
                <div>
                  <button className="dropdown-btn sidebartogglexyz" >
                    <i className="fa fa-book sidebartogglexyz" aria-hidden="true" ></i> Empanelment
    <i className="fa fa-caret-down sidebartogglexyz"></i>
                  </button>


                  <div className="dropdown-container sidebartogglexyz">
              
                    <a onClick={this.empanellementReport} className="sidebartogglexyz">
                    Empanelment Details</a>

                  </div>

                </div>
                : ''}
            </div>
            <div id="loading-bar-spinner" class="spinner" style={{ display: this.state.loading1 }}>
                <div class="spinner-icon"></div>
            </div>
          </React.Fragment >
        </div>
      );
      // }
      // else {
      //   return null;
      // }
    }
  }
);

export default DashboardHeader;

// onClfromEmailNameick
