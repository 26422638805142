import React from 'react';
import { useState, useRef} from 'react';
import logoImg from '../../../assets/images/Mutual-Funds-logo.png'
import hamImg from '../../../assets/images/ham-menu.png'
import './Header.css';

function Header() {
    const [isActive, setActive] = useState(false);

    const toggleClass = () => {
        setActive(!isActive);
    }


    return (
        <header className='col-100 floatLft flexDisplay justifyCenter'>
            <div className="wrapper">
                <div className="ap-headerWrp col-100 floatLft flexDisplay justifySpace alignCenter">
                <div className="ap-logo floatLft"><a href="https://www.ciel.co.in/"><img src={logoImg} alt="Site Logo" /></a></div>
                <nav className={
                    isActive ? 'ap-navMenu floatRgt open':
                     'ap-navMenu floatRgt'}>
                {/* Menu links updated 28-09-2022 */}
                    <ul className="ap-navigation col-100 floatLft">
                        <li className='inlineBlk'><a onClick={toggleClass} href="https://www.ciel.co.in/" target="_blank" className='primary-dark'>About us</a></li>
                        <li className='inlineBlk'><a onClick={toggleClass} href="#benefits" className='primary-dark'>Benefits</a></li>
                        <li className='inlineBlk'><a onClick={toggleClass} href="https://mfdkareinshuru.com/en/stories/" target="_blank" className='primary-dark'>Videos</a></li>
                        <li className='inlineBlk'><a onClick={toggleClass} href="#process" className='primary-dark'>Process</a></li>
                        <li className='inlineBlk'><a onClick={toggleClass} href="mailto:expertmfd@ciel.co.in" target="_blank" className='primary-dark'>Contact</a></li>
                        <li className='inlineBlk ap-loginBtnWrp'><a href="#" className='ap-loginClick bg-primary' data-keyboard="true" data-toggle="modal"
                                data-keyboard="true" data-target="#amfi-Reg" data-backdrop="static" data-keyboard="false">Login</a></li>
                    </ul>
                </nav>
                <div className="ap-mobBtnWrp floatRgt">
                
                    <a href="#" className='ap-mobLogin-btn bg-primary'data-keyboard="true" data-toggle="modal"
                                data-keyboard="true" data-target="#amfi-Reg" data-backdrop="static" data-keyboard="false">Login</a>
                    <button className="ap-hamBurgerClick" onClick={toggleClass}><img src={hamImg} alt="Hamburger Menu" /></button>
                </div>
                </div>
            </div>
        </header>
    )
}

export default Header