import React, { Component } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Paper from '@material-ui/core/Paper';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import learnercryIcon from '../../assets/images/learner-cry.png';

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {' © '}
            {' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const useStyles = makeStyles(theme => ({
    appBar: {
        position: 'relative',
    },
    layout: {
        width: 'auto',
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
            width: 600,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
    paper: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        padding: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
            marginTop: theme.spacing(6),
            marginBottom: theme.spacing(6),
            padding: theme.spacing(3),
        },
    },
    stepper: {
        padding: theme.spacing(3, 0, 5),
    },
    buttons: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    button: {
        marginTop: theme.spacing(3),
        marginLeft: theme.spacing(1),
    },
}));

const steps = ['Payment', 'Error'];



const Learner = (props) => {
    const classes = useStyles();
    const [activeStep] = React.useState(2);

    const handleBack = () => {
        if (localStorage.getItem('login') !== null) {
            if (JSON.parse(localStorage.getItem('login'))['role'][0].roleId === 5) {
                localStorage.setItem('reload', 'true');
                setTimeout(() => {
                    window.location.href = '/learnerDashboard'
                }, 1000);
            }
        } else {
            window.location.href = '/';
        }
    };

    return (
        <React.Fragment>
            {/* <CssBaseline /> */}
            {/* <AppBar position="absolute" color="default" className={classes.appBar}>
                <Toolbar>
                    <Typography variant="h6" color="inherit" noWrap>
                        {props.name}
                    </Typography>
                </Toolbar>
            </AppBar> */}
            <div className="container">
                <div className="col-md-7 col-sm-7 col-md-offset-3 col-sm-offset-3 mt-30">
                    <div className="reset">
                        <h3 align="center">
                            Oops! </h3>
                    </div>
                    <Stepper activeStep={activeStep} className={classes.stepper}>
                        {steps.map(label => (
                            <Step key={label}>
                                <StepLabel>{label}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                    <React.Fragment>
                        {(
                            <React.Fragment>

                                <div className="modal-content">
                                    <section className="registration resetpass-modal">
                                        <div className="modal-body">
                                            <div className="row">
                                                <div className="col-md-4 col-sm-6 col-xs-12">
                                                    <div className="modal-head">
                                                    </div>
                                                    <img src={learnercryIcon} class="img-responsive" />
                                                </div>
                                                <div className="col-md-7 col-md-offset-1 col-sm-6 col-xs-12" >
                                                    <div className="reset">
                                                        <h3>Something went wrong</h3>
                                                        <p><b>Please contact ExpertMFD support</b></p>

                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-10 col-sm-10 col-xs-12 mt-50">
                                                            <button type="submit" onClick={handleBack} class="btn-4 pull-right" name="Submit">Ok</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                </div>


                            </React.Fragment >
                        )}
                    </React.Fragment>


                </div>
            </div>
        </React.Fragment >
    );

}


export default class ErrorPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            learner: {
                id: "",
                firstName: "",
                lastName: "",
                email: "",
                mobile: ""
            },
            flag: false
        }
        //this.dataSource = new RestDataSource("http://192.168.10.103:8080/Dap-usermanagment/learner/");
    }
    render() {
        return (
            <Learner key={this.state.learner.id} name={this.props.name} id={this.props.id} {...this.props}
            />
        )
    }
}