import React, { Component } from "react";
import ReactTable from "react-table";
import "react-table/react-table.css";
import { PopUpPortal } from "../../containers/popups/PopUpPortal";
import saveAs from 'save-as'
import { ReattemptTestService } from "../../services/reattemptTest.service"
import JSZipUtils from 'jszip-utils'
import Finxlogo from "../../assets/images/dapIcon.png";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import Select, { createFilter } from 'react-select';
import { success, successNotification, error, errorNotification, warning, warningNotification } from "../notification/notifications";
import jsPDF from "jspdf";
import { ExcelService } from "../Excel/excel";
import Modal from "react-responsive-modal";
import "jspdf-autotable";
import * as moment from "moment";
import DatePicker from "react-datepicker";
import DashboardHeader from "../../components/dashboardheader/dashboardheader";

import "react-datepicker/dist/react-datepicker.css";
import { LookUpService } from "../../services/lookupService";
import { RegisterUserService } from "../../services/registration.service";
import { Global_var } from "../../global/global_var";
var JSZip = require("jszip");

class ReattemptTest extends Component {
  constructor(props) {
    super(props);
    if (localStorage.getItem('login') !== null) {
      this.state = {

        loading: false,


        selected: {},
        selectAll: 0,
        data: [],
        productData: [],
        exportData: [],
        listOfYear1: [],
        listOfMonth: [],
        listOfDay: [],
        jobData: []

      }

    }
    else {
      window.location.href = '/';
    }
  }
  componentDidMount() {

    LookUpService.getJob(
      "jobId=0&fromDate=&toDate=&status=",
      res => {

        this.setState({
          jobData: res.responseListObject,
          // countryListForPer: res.responseListObject
        });

      }
    );

    var year = new Date().getFullYear();
    year = year
    var listOfYear = [];
    for (var i = 0; i < 100; i++) {
      var current = year - i;
      listOfYear.push(current);

    }
    var listOfMonth = [];
    // for (var i = 1; i <= 12; i++) {
    //   // var current = year - i;
    //   listOfMonth.push(i);

    // }

    var listOfMonth = [{ "key": 1, "value": "Jan" },
    { "key": 2, "value": "Feb" },
    { "key": 3, "value": "Mar" },
    { "key": 4, "value": "Apr" },
    { "key": 5, "value": "May" },
    { "key": 6, "value": "Jun" },
    { "key": 7, "value": "Jul" },
    { "key": 8, "value": "Aug" },
    { "key": 9, "value": "Sep" },
    { "key": 10, "value": "Oct" },
    { "key": 11, "value": "Nov" },
    { "key": 12, "value": "Dec" },
    ];
    var listOfDay = [];
    for (var i = 1; i <= 31; i++) {
      //    var current = year - i;
      listOfDay.push(i);

    }
    this.setState({ listOfMonth: listOfMonth });
    this.setState({ listOfYear1: listOfYear });
    this.setState({ listOfDay: listOfDay });

    //The function is called

    let product = []
    var productdata = this.props.fetchUserAddressData;
    if (productdata) {
      for (let i = 0; i < productdata.length; i++) {
        let obj = {}
        obj['value'] = productdata[i].productID
        obj['label'] = productdata[i].productName
        product.push(obj)
      }
      this.setState({
        productData: product
      })
    }
  }


  // toggleRow(original) {

  //   var ceildata2 = localStorage.getItem("PersonnelData")
  //   let data = this.state.data
  //   let exportData = this.state.exportData
  //   let AllData = []
  //   let temp_data = []
  //   let obj = {}
  //   const newSelected = Object.assign({}, this.state.selected);
  //   newSelected[original.learnerPartyID] = !this.state.selected[original.learnerPartyID];
  //   //data.push(original)
  //   // this.setState({
  //   //     selected: newSelected,

  //   // });


  //   if (data.length > 0) {
  //     for (let i = 0; i < this.state.data.length; i++) {
  //       if (data[i].learnerPartyID == original.learnerPartyID) {
  //         data.splice(i, 1);
  //         exportData.splice(i, 1)
  //         this.setState({
  //           selected: newSelected,
  //           data: data,
  //           selectAll: 2,
  //           exportData: exportData
  //         });
  //       } else {
  //         obj['emailId'] = original.emailID
  //         obj['userName'] = original.emailID
  //         obj['assessmentModuleID'] = original.assessmentModuleID
  //         obj['moduleScore'] = original.moduleScore
  //         obj['productID'] = original.productID
  //         obj['productModuleID'] = original.productModuleID
  //         obj['learnerPartyID'] = original.learnerPartyID
  //         obj['overallModuleScore'] = original.overallModuleScore
  //         obj["ceilopsPartyId"] = JSON.parse(localStorage.login).partyId
  //         temp_data.push(obj)
  //         AllData.push(original)
  //       }
  //     }
  //     let d1 = data.concat(temp_data)
  //     let exportData1 = exportData.concat(AllData)
  //     this.setState({
  //       selected: newSelected,
  //       data: d1,
  //       selectAll: 2,
  //       exportData: exportData1
  //     });
  //   }
  //   else {
  //     obj['emailId'] = original.emailID
  //     obj['userName'] = original.emailID
  //     obj['assessmentModuleID'] = original.assessmentModuleID
  //     obj['moduleScore'] = original.moduleScore
  //     obj['productID'] = original.productID
  //     obj['productModuleID'] = original.productModuleID
  //     obj['learnerPartyID'] = original.learnerPartyID
  //     obj['overallModuleScore'] = original.overallModuleScore
  //     obj["ceilopsPartyId"] = JSON.parse(localStorage.login).partyId
  //     temp_data.push(obj)
  //     AllData.push(original)
  //     let d1 = data.concat(temp_data)
  //     let exportData1 = exportData.concat(AllData)
  //     this.setState({
  //       selected: newSelected,
  //       data: d1,
  //       selectAll: 2,
  //       exportData: exportData1
  //     });
  //   }



  // }

  // toggleRow(original) {

  //   let data = this.state.data
  //   let temp_data = []
  //   let mailData = []
  //   let obj1 = {}
  //   let flag = true
  //   const newSelected = Object.assign({}, this.state.selected);
  //   newSelected[original.learnerPartyID] = !this.state.selected[original.learnerPartyID];

  //   this.state.data.map((e, i) => {
  //     if (e.learnerPartyID == original.learnerPartyID) {
  //       data.splice(i, 1);
  //       flag = false;
  //     }
  //   })
  //   if (data.length > 0) {

  //     if (flag == true) {
  //       //   data.splice(i, 1);
  //       // } else {
  //       obj1['emailId'] = original.emailID
  //       obj1['userName'] = original.firstName
  //       obj1['password'] = ""
  //       obj1['templateId'] = "196043"
  //       obj1['status'] = "NISM_Profiles_Created"

  //       obj1['partyId'] = original.learnerPartyID



  //       let obj = {
  //         "action": original.learnerWorkflowMap[0] ? original.learnerWorkflowMap[0].status.toUpperCase() === "NISM VA PAYMENT COMPLETED" ? "NISM PROFILE CREATE" : original.learnerWorkflowMap[0].status.toUpperCase() : '',
  //         "workflowId": original.learnerID,
  //         "workflowName": "ARN",
  //         "comment": "test",
  //         "learnerPartyID": original.learnerPartyID
  //       }



  //       temp_data.push(obj)
  //       mailData.push(obj1)

  //     }
  //     // }
  //   }
  //   else {
  //     obj1['emailId'] = original.emailID
  //     obj1['userName'] = original.firstName
  //     obj1['password'] = ""
  //     obj1['templateId'] = "196043"
  //     obj1['status'] = "NISM_Profiles_Created"

  //     obj1['partyId'] = original.learnerPartyID
  //     let obj = {
  //       "action": original.learnerWorkflowMap[0] ? original.learnerWorkflowMap[0].status.toUpperCase() === "NISM VA PAYMENT COMPLETED" ? "NISM PROFILE CREATE" : original.learnerWorkflowMap[0].status.toUpperCase() : '',

  //       "workflowId": original.learnerID,
  //       "workflowName": "ARN",
  //       "comment": "test",
  //       "learnerPartyID": original.learnerPartyID
  //     }

  //     temp_data.push(obj)
  //     mailData.push(obj1)
  //   }
  //   console.log(data)
  //   let d1 = data.concat(temp_data)
  //   this.setState({
  //     selected: newSelected,
  //     data: d1,
  //     selectAll: 2,
  //     mailData: mailData
  //   });
  //   console.log('dataaaaaaaaaaaaaaaaaaaaaaa')
  //   console.log(this.state.data)
  // }
  WhyTheFillForm = () => {
    this.setState({ linkModalloading: true, showWhyTheFillForm: true });
  }

  onWhyTheFillFormCloseModal = () => {
    this.setState({ linkModalloading: false, showWhyTheFillForm: false });
  };
  filterCaseInsensitive = ({ id, value }, row) =>
    row[id] ? row[id].toLowerCase().includes(value.toLowerCase()) : true;

  onChangeParameter(selected, setFieldValue) {


    setFieldValue('product', selected)
    //  this.props.getDevices(selected.value)
  }
  urlToPromise(url) {
    return new Promise(function (resolve, reject) {
      JSZipUtils.getBinaryContent(url, function (err, data) {
        if (err) {
          reject(err);
        } else {
          resolve(data);
        }
      });
    });
  }






  exportToCSV = () => {

    console.log("data")
    console.log(this.state.exportData)
    let modifiedData = [];
    let data = this.state.exportData.length > 0 ? this.state.exportData : this.props.attemptedList;
    // this.props.attemptedList;
    //   console.log(data)
    if (data.length > 0) {

      data.forEach((element, index) => {

        var data1 = {
          "Sr. No.": index + 1,
          "First Name": element.firstName,
          "Last Name": element.lastName,
          "Email ID": element.emailID,
          "PAN No": element.panNumber,

          "Registration Date": element.moduleStartDate ? moment(element.moduleStartDate.split(" ")[0], "DD/MM/YYYY").format("DD-MM-YYYY") : '',
          "Attempted Date": element.attemptedDate ? moment(element.attemptedDate.split(" ")[0], "DD/MM/YYYY").format("DD-MM-YYYY") : '',
          "Score": element.score,
          "Quartlet": element.resultCategory,

        };
        modifiedData.push(data1);
      });

      let date = new Date();
      let time = new Date().toLocaleString().split(",")[1]

      let time1 = time.split(" ")[2]
      let time2 = time.split(":")[0] + "-" + time.split(":")[1] + "-" + time1
      ///  alert(time)
      time2 = time2.split(" ")[1]

      let date1 = moment(date).format("DD-MM-YYYY")
      let d2 = date1

      let title = "Feat-List-" + time2 + "-"


      ExcelService.ExcelData(modifiedData, title);
    }
    else {
      warning("No record found", warningNotification)
    }
  };


  exportPDF = () => {

    if (this.props.attemptedList.length > 0) {
      const unit = "pt";
      const size = "A4"; // Use A1, A2, A3 or A4
      const orientation = "landscape"; // portrait or landscape

      const marginLeft = 40;
      const doc = new jsPDF(orientation, unit, size);

      doc.setFontSize(15);
      let modifiedData = [];
      const title = "Feat List";
      let data = this.state.exportData.length > 0 ? this.state.exportData : this.props.attemptedList;

      const headers = [["Sr. No.", "First Name", "Last Name", "Email ID", "PAN No", "Registration Date", "Attempted Date", "Score", "Quartlet"]];
      data.forEach((element, index) => {
        // console.log(this.state.allCityList[0].cityId)

        modifiedData.push([
          index + 1,
          element.firstName,
          element.lastName,
          element.emailID,
          element.panNumber,

          element.moduleStartDate ? moment(element.moduleStartDate.split(" ")[0], "DD/MM/YYYY").format("DD-MM-YYYY") : '',
          element.attemptedDate ? moment(element.attemptedDate.split(" ")[0], "DD/MM/YYYY").format("DD-MM-YYYYY") : '',
          element.score,
          element.resultCategory,

        ])

      });

      let content = {
        startY: 50,
        head: headers,
        body: modifiedData
      };

      doc.text(title, marginLeft, 40);
      doc.autoTable(content);

      let date = new Date();
      let time = new Date().toLocaleString().split(",")[1]

      let time1 = time.split(" ")[2]
      time = time.split(":")[0] + "-" + time.split(":")[1] + "-" + time1
      time = time.split(" ")

      let date1 = moment(date).format("DD-MM-YYYY")
      let title1 = "Feat-List-" + date1 + "-" + time[1] + ".pdf"

      doc.save(title1)
    }
    else {
      warning("No record found", warningNotification)
    }
  }

  downloadFile = (id) => {
    let admitCardDocId = id
    RegisterUserService.downloadDocumet("documentId=" + admitCardDocId + "&bucketTag=" + Global_var.CONST_BUCKET_NAME, res1 => {

      window.location.href = res1.responseObject.documentUrl
      //window.$('#downloadAdmitCard').modal('hide')
    })

  }

  isValidDate = (fromDate, toDate) => {
    
    if (fromDate !== "") {
      let fromDate1 = fromDate.split('-')
      if (fromDate1[1] == 2 && fromDate1[2] > 29) {
        warning("Please select valid Date", warningNotification)
        return false
      } else if (fromDate1[1] == 4 || fromDate1[1] == 6 || fromDate1[1] == 9 || fromDate1[1] == 11) {
        if (fromDate1[2] > 30) {
          warning("Please select valid Date", warningNotification)
          return false
        } else {
          return true
        }
      }
      else {
        return true
      }
    }

    if (toDate !== "") {
      let toDate1 = fromDate.split('-')
      if (toDate1[1] == 2 && toDate1[2] > 29) {
        warning("Please select valid Date", warningNotification)
        return false

      } else if (toDate1[1] == 4 || toDate1[1] == 6 || toDate1[1] == 9 || toDate1[1] == 11) {
        if (toDate1[2] > 30) {
          warning("Please select valid Date", warningNotification)
          return false
        } else {
          return true
        }
      } else {
        return true
      }
    }
  }

  calculateDate = (fromYear, fromMonth, fromDay, toYear, toMonth, toDay) => {

    fromYear = this.state.fromYear
    fromMonth = this.state.fromMonth
    fromDay = this.state.fromDay
    toYear = this.state.toYear
    toMonth = this.state.toMonth
    toDay = this.state.toDay
    
    let fromDate1 = ""
    if ((fromYear !== undefined && fromYear !== "" && fromYear !== "0") && (fromMonth !== undefined && fromMonth !== "" && fromMonth !== "0") && (fromDay !== undefined && fromDay !== "" && fromDay !== "0")) {
      fromDate1 = fromYear + "-" + fromMonth + "-" + fromDay
      let flag = this.isValidDate(fromDate1, "")
      this.setState({
        fromDate: fromDate1
      })
    } else {
      fromDate1 = ""

    }

    let toDate1 = ""
    if ((toYear !== undefined && toYear !== "" && toYear !== "0") && (toMonth !== undefined && toMonth !== "" && toMonth !== "0") && (toDay !== undefined && toDay !== "" && toDay !== "0")) {
      toDate1 = toYear + "-" + toMonth + "-" + toDay
      let flag = this.isValidDate("", toDate1)
      this.setState({
        toDate: toDate1
      })
    } else {
      toDate1 = ""

    }



    if (fromDate1 != "" && toDate1 != "") {
      if (fromDate1 > toDate1) {
        // warning("To Date should be greater than from date ",warningNotification)
        return false
      } else {

        return true

      }
      //alert(yearsDiff)

    }

    // alert(yearsDiff)
    // if(yearsDiff=="NaN.NaN"){
    // setFieldValue(
    // (values.professionalQualification[index].totalExperience = 0.0)
    // );
    // }
    //  }
  }

  render() {
    let title = 'Score Range     0-22  23-45  46-68  69-90' + "\u000d" + 'Result Category   Q1     Q2       Q3       Q4';

    let product = []


    var roledata = this.props.userRoleAccess;
    console.log(this.props.productDetailsAll)
    var productdata = this.props.productDetailsAll;
    if (productdata) {
      for (let i = 0; i < productdata.length; i++) {
        let obj = {}
        obj['value'] = productdata[i].productID
        obj['label'] = productdata[i].productName
        product.push(obj)
      }
      // this.setState({
      //     productData:product
      // })
    }

    //     var urls = [
    //         "images/20170420_145140.jpg", 
    //         "https://finx-uat.s3.ap-south-1.amazonaws.com/uploads/2/1/ProfilePhoto/198553/2mbimg%201.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20201126T114757Z&X-Amz-SignedHeaders=host&X-Amz-Expires=1800&X-Amz-Credential=AKIAWDORVJCXNFMLWQWG%2F20201126%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Signature=0d4a316f51961d6b6a149818d1e8c8156cc67557d4167a03bdab736e41fc75f9"

    //     ]
    // var nombre = "Zip_img";
    return (
      <React.Fragment>
        <DashboardHeader

          {...this.props}

        />
        <PopUpPortal
          HidePopup={this.state.loading}
          IsVisible={this.state.loading}
        />
        <section class="dashboard-tab user-tab pt-50 mb-50">
          <div class="padding-rl-50">
            <div class="container-fluid">
              <div class="row">
                <div class="col-md-12 col-sm-12 col-xs-12">
                  <Formik
                    enableReinitialize={true}
                    initialValues={{

                      fname: '',
                      lname: '',
                      fromDate: "",
                      toDate: "",
                      emailId: '',
                      totalScore: '',
                      Quartlet: '',

                      listOfMonth: this.state.listOfMonth,
                      listOfDay: this.state.listOfDay,
                      listOfMonth1: this.state.listOfMonth,
                      listOfDay1: this.state.listOfDay,
                      day: '',
                      month: '',
                      year: '',
                      today: '',
                      tomonth: '',
                      toyear: '',
                      status1: ''

                    }}
                    validationSchema={Yup.object().shape({
                      //static validations
                      fname: Yup.string()
                        .matches(/^[a-zA-Z ]{1,180}$/, "you must specify text"),
                      emailId: Yup.string()
                        .email('Invalid E-Mail'),
                      lname: Yup.string()
                        .matches(/^[a-zA-Z ]{1,180}$/, "you must specify text"),
                      totalScore: Yup.string()
                        .matches(/^[0-9 ]{1,180}$/, "you must specify number"),
                      Quartlet: Yup.string()
                        .matches(/^[a-zA-Z0-9 ]{1,180}$/, "you must specify alphanumeric Value eg-Q1,Q2 etc."),

                    }
                    )}

                    onSubmit={(fields, { resetForm }) => {
                      

                      let flag = false
                      let flag1 = false

                      let fromDate1 = ""

                      //                       if ((fields['year'] === undefined && fields['year'] === "") && (fields['month'] === undefined && fields['month'] === "") && (fields['day'] === undefined && fields['day'] === "")) 

                      //                       {

                      // flag=true;
                      //                       }
                      //                       if ((fields['toyear'] === undefined && fields['toyear'] === "") && (fields['tomonth'] === undefined && fields['tomonth'] === "") && (fields['today'] === undefined && fields['today'] === "")) {
                      // {
                      //   flag1=true; 
                      // }
                      // if( flag === true &&flag1 === true){

                      // }
                      // else{

                      if ((fields['year'] !== undefined && fields['year'] !== "") || (fields['month'] !== undefined && fields['month'] !== "") || (fields['day'] !== undefined && fields['day'] !== "")) {
                        fromDate1 = fields['year'] + "-" + fields['month'] + "-" + fields['day']

                      } else {

                        fromDate1 = ""

                      }

                      let toDate1 = ""
                      if ((fields['toyear'] !== undefined && fields['toyear'] !== "") || (fields['tomonth'] !== undefined && fields['tomonth'] !== "") || (fields['today'] !== undefined && fields['today'] !== "")) {
                        toDate1 = fields['toyear'] + "-" + fields['tomonth'] + "-" + fields['today']

                      } else {


                        toDate1 = ""

                      }




                      if (fromDate1 !== "" && toDate1 !== "") {
                        if (new Date(fromDate1) > new Date(toDate1)) {
                          warning("End Date should be greater than Start Date", warningNotification)
                          fromDate1 = ""
                          toDate1 = ""
                        }
                      }



                      if (fields.status == "SEARCH") {
                        let param = ''
                        param = param + 'jobId=0&'

                        if (fromDate1 !== undefined && fromDate1 !== "") {
                          param = param + "fromDate=" + fromDate1 + '&'
                        }
                        else {

                          param = param + "fromDate=" + '&'
                        }
                        if (toDate1 !== undefined && toDate1 !== "") {
                          param = param + "toDate=" + toDate1 + '&'
                        }
                        else {

                          param = param + "toDate=" + '&'
                        }
                        if (fields.status1 !== undefined || fields.status1 !== '') {
                          param = param + "status=" + fields.status1
                        }
                        LookUpService.getJob(
                          param,
                          res => {

                            this.setState({
                              jobData: res.responseListObject,
                              // countryListForPer: res.responseListObject
                            });

                          }
                        )

                      }


                      else {
                        LookUpService.getJob(
                          "jobId=0&fromDate=&toDate=&status=",
                          res => {

                            this.setState({
                              jobData: res.responseListObject,
                              // countryListForPer: res.responseListObject
                            });

                          }
                        )

                          //  this.props.fetchDataByProduct("productID=187422&firstName=&lastName=&fromDate=&toDate=&emailId=&score=0&Quatlets=&learnerPartyID=0&learnerID=0");
                          ;

                      }





                    }}

                    render={({
                      errors, touched, handleSubmit, values, setFieldValue
                    }) => (
                      <React.Fragment>
                        <Form onSubmit={handleSubmit} className="form-group">
                          <section class="updateGDPI">
                            <div class="modal-body">
                              <div class="row">
                                <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                                  <div class="product-add">
                                    <div class="form-group">
                                      <div class="row">

                                        <div class="col-md-4 col-sm-4 col-xs-12">
                                          <label>
                                            Start Date
                        </label>
                                          <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 pl pr">
                                            <div className="col-md-3 col-lg-3 col-sm-3 col-xs-3 pl pr">
                                              <Field
                                                as="select"
                                                name="day"
                                                id="day"
                                                // onChange={handleChange}
                                                //  disabled={this.state.isDisabled}
                                                //  disabled
                                                onChange={
                                                  e => {

                                                    
                                                    var listOfMonth = []
                                                    setFieldValue(values.day = e.target.value)
                                                    if (e.target.value == 31) {
                                                      listOfMonth = [{ "key": 1, "value": "Jan" },

                                                      { "key": 3, "value": "Mar" },

                                                      { "key": 5, "value": "May" },

                                                      { "key": 7, "value": "Jul" },
                                                      { "key": 8, "value": "Aug" },

                                                      { "key": 10, "value": "Oct" },

                                                      { "key": 12, "value": "Dec" },
                                                      ];


                                                    } else if (e.target.value == 30) {
                                                      listOfMonth = [

                                                        { "key": 1, "value": "Jan" },

                                                        { "key": 3, "value": "Mar" },
                                                        { "key": 4, "value": "Apr" },
                                                        { "key": 5, "value": "May" },
                                                        { "key": 6, "value": "Jun" },
                                                        { "key": 7, "value": "Jul" },
                                                        { "key": 8, "value": "Aug" },
                                                        { "key": 9, "value": "Sep" },
                                                        { "key": 10, "value": "Oct" },
                                                        { "key": 11, "value": "Nov" },
                                                        { "key": 12, "value": "Dec" },

                                                      ];


                                                    }
                                                    else if (e.target.value == 28 || e.target.value == 29) {
                                                      listOfMonth = [{ "key": 1, "value": "Jan" },
                                                      { "key": 2, "value": "Feb" },
                                                      { "key": 3, "value": "Mar" },
                                                      { "key": 4, "value": "Apr" },
                                                      { "key": 5, "value": "May" },
                                                      { "key": 6, "value": "Jun" },
                                                      { "key": 7, "value": "Jul" },
                                                      { "key": 8, "value": "Aug" },
                                                      { "key": 9, "value": "Sep" },
                                                      { "key": 10, "value": "Oct" },
                                                      { "key": 11, "value": "Nov" },
                                                      { "key": 12, "value": "Dec" },
                                                      ];
                                                    } else {
                                                      listOfMonth = [{ "key": 1, "value": "Jan" },
                                                      { "key": 2, "value": "Feb" },
                                                      { "key": 3, "value": "Mar" },
                                                      { "key": 4, "value": "Apr" },
                                                      { "key": 5, "value": "May" },
                                                      { "key": 6, "value": "Jun" },
                                                      { "key": 7, "value": "Jul" },
                                                      { "key": 8, "value": "Aug" },
                                                      { "key": 9, "value": "Sep" },
                                                      { "key": 10, "value": "Oct" },
                                                      { "key": 11, "value": "Nov" },
                                                      { "key": 12, "value": "Dec" },
                                                      ];

                                                    }
                                                    setFieldValue(values.listOfMonth = listOfMonth)
                                                    // this.setState({
                                                    //   listOfMonth: listOfMonth
                                                    // })
                                                  }}
                                                className={
                                                  "form-control" +
                                                  (errors.day && touched.day
                                                    ? "is-invalid"
                                                    : "")
                                                }
                                                className="form-control"
                                              >
                                                <option value="">DD</option>
                                                {(
                                                  values.listOfDay ||
                                                  []
                                                ).map(d => (
                                                  <option
                                                    value={d}
                                                  >
                                                    {d}
                                                  </option>
                                                ))}
                                              </Field>
                                            </div>
                                            <div className="col-md-4 col-lg-4 col-sm-4 col-xs-4 pl pr">
                                              <Field
                                                as="select"
                                                name="month"
                                                id="month"
                                                //   onChange={handleChange}
                                                //     disabled={this.state.isDisabled}
                                                //  disabled
                                                onChange={
                                                  e => {
                                                    

                                                    setFieldValue(values.month = e.target.value)
                                                    if (e.target.value == 2) {
                                                      var listOfDay = [];
                                                      for (var i = 1; i <= 29; i++) {
                                                        //    var current = year - i;
                                                        listOfDay.push(i);
                                                        // this.setState({ listOfDay: listOfDay });
                                                      }
                                                    } else if (e.target.value == 1 || e.target.value == 3 || e.target.value == 5 || e.target.value == 7 || e.target.value == 8 || e.target.value == 10 || e.target.value == 12) {
                                                      var listOfDay = [];
                                                      for (var i = 1; i <= 31; i++) {
                                                        //    var current = year - i;
                                                        listOfDay.push(i);
                                                        //  this.setState({ listOfDay: listOfDay });
                                                      }
                                                    }
                                                    else {
                                                      var listOfDay = [];
                                                      for (var i = 1; i <= 30; i++) {
                                                        //    var current = year - i;
                                                        listOfDay.push(i);
                                                        //  this.setState({ listOfDay: listOfDay });
                                                      }
                                                    }
                                                    setFieldValue(values.listOfDay = listOfDay)
                                                  }
                                                }
                                                className={
                                                  "form-control" +
                                                  (errors.month && touched.month
                                                    ? "is-invalid"
                                                    : "")
                                                }
                                                className="form-control"
                                              >
                                                <option value="">MM</option>
                                                {(
                                                  values.listOfMonth ||
                                                  []
                                                ).map(m => (
                                                  <option
                                                    value={m.key}
                                                  >
                                                    {m.value}
                                                  </option>
                                                ))}
                                              </Field>
                                            </div>
                                            <div className="col-md-5 col-lg-5 col-sm-5 col-xs-5 pl pr">
                                              <Field
                                                as="select"
                                                name="year"
                                                id="year"
                                                // onChange={handleChange}
                                                //    disabled={this.state.isDisabled}
                                                //  disabled
                                                className={
                                                  "form-control" +
                                                  (errors.year && touched.year
                                                    ? "is-invalid"
                                                    : "")
                                                }
                                                className="form-control"
                                              >
                                                <option value="">YYYY</option>
                                                {(
                                                  this.state.listOfYear1 ||
                                                  []
                                                ).map(year => (
                                                  <option
                                                    value={year}
                                                  >
                                                    {year}
                                                  </option>
                                                ))}
                                              </Field>
                                            </div>
                                          </div>


                                          {/* <DatePicker selected={values.fromDate}
                                            //   maxDate={new Date()}
                                            autoComplete="off"
                                            placeholderText="dd-mm-yyyy"
                                            dateFormat="dd-MM-yyyy"
                                            maxLength="50"
                                            value={values.fromDate}
                                            onChangeRaw={event => {

                                              let temp = event.target.value

                                              if (temp !== undefined) {
                                                if (temp.includes("/")) {

                                                  setFieldValue(values.fromDate = "")
                                                  setFieldValue(values.errmsg1 = "Date should be in dd-mm-yyyy format.")

                                                }
                                                else {
                                                  // setFieldValue(values.toDate = date)
                                                  setFieldValue(values.errmsg1 = "")

                                                }
                                              }
                                            }
                                            }
                                            onChange={date => {

                                              setFieldValue(values.fromDate = date)


                                              setFieldValue(values.fromDate1 = moment(date).format("YYYY-MM-DD"))

                                              // setFieldValue(values.dateOfBirth = date)
                                            }}
                                            className={
                                              "form-control" +
                                              (errors.fromDate &&
                                                touched.fromDate
                                                ? " is-invalid"
                                                : "")
                                            }

                                          /> */}
                                          <p className="validation">  {values.errmsg1}</p>

                                          {errors.fromDate && <div className="validation">{errors.fromDate}</div>}
                                          {/* <Field
                                            type="date"
                                            name="fromDate"
                                            id="fromDate"
                                            autoComplete="fromDate"
                                            className={
                                              "form-control" +
                                              (errors.fromDate &&
                                                touched.fromDate
                                                ? " is-invalid"
                                                : "")
                                            }

                                            onChange={(e) => {

                                              var FromDate = e.target.value

                                              var Todate = values.toDate
                                              if (Todate !== '' && FromDate !== '') {
                                                if ((Date.parse(Todate) < Date.parse(FromDate))) {

                                                  //  alert();
                                                  warning("Attempted To date should be greater than Attempted From date", warningNotification);
                                                  setFieldValue(
                                                    (values.fromDate = "")
                                                  )
                                                  setFieldValue(
                                                    (values.toDate = "")
                                                  );
                                                }
                                                else {
                                                  setFieldValue(
                                                    (values.fromDate = FromDate)
                                                  )
                                                }
                                              }
                                              else {
                                                setFieldValue(
                                                  (values.fromDate = FromDate)
                                                )
                                              }
                                            }
                                            }
                                            placeholder="From Date"
                                          />
                                          <ErrorMessage
                                            name="fromDate"
                                            component="div"
                                            className="validation"
                                          /> */}
                                        </div>
                                        <div class="col-md-4 col-sm-4 col-xs-12">
                                          <label>
                                            End Date
                                      </label>
                                          {/* <DatePicker selected={values.toDate}
                                            autoComplete="off"
                                            placeholderText="dd-mm-yyyy"
                                            dateFormat="dd-MM-yyyy"
                                            maxLength="50"
                                            minDate={values.fromDate}
                                            value={values.toDate}
                                            onChangeRaw={event => {

                                              let temp = event.target.value

                                              if (temp !== undefined) {
                                                if (temp.includes("/")) {

                                                  setFieldValue(values.toDate = "")
                                                  setFieldValue(values.errmsg = "Date should be in dd-mm-yyyy format.")

                                                }
                                                else {
                                                  // setFieldValue(values.toDate = date)
                                                  setFieldValue(values.errmsg1 = "")

                                                }
                                              }
                                            }
                                            }
                                            onChange={date => {

                                              setFieldValue(values.toDate = date)


                                              setFieldValue(values.toDate1 = moment(date).format("YYYY-MM-DD"))

                                              // setFieldValue(values.dateOfBirth = date)
                                            }}
                                            className={
                                              "form-control" +
                                              (errors.toDate &&
                                                touched.toDate
                                                ? " is-invalid"
                                                : "")
                                            }

                                          /> */}

                                          <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 pl pr">
                                            <div className="col-md-3 col-lg-3 col-sm-3 col-xs-3 pl pr">
                                              <Field
                                                as="select"
                                                name="today"
                                                id="today"
                                                // onChange={handleChange}
                                                //  disabled={this.state.isDisabled}
                                                //  disabled
                                                onChange={
                                                  e => {

                                                    
                                                    var listOfMonth = []
                                                    setFieldValue(values.today = e.target.value)
                                                    if (e.target.value == 31) {
                                                      listOfMonth = [{ "key": 1, "value": "Jan" },

                                                      { "key": 3, "value": "Mar" },

                                                      { "key": 5, "value": "May" },

                                                      { "key": 7, "value": "Jul" },
                                                      { "key": 8, "value": "Aug" },

                                                      { "key": 10, "value": "Oct" },

                                                      { "key": 12, "value": "Dec" },
                                                      ];


                                                    } else if (e.target.value == 30) {
                                                      listOfMonth = [

                                                        { "key": 1, "value": "Jan" },

                                                        { "key": 3, "value": "Mar" },
                                                        { "key": 4, "value": "Apr" },
                                                        { "key": 5, "value": "May" },
                                                        { "key": 6, "value": "Jun" },
                                                        { "key": 7, "value": "Jul" },
                                                        { "key": 8, "value": "Aug" },
                                                        { "key": 9, "value": "Sep" },
                                                        { "key": 10, "value": "Oct" },
                                                        { "key": 11, "value": "Nov" },
                                                        { "key": 12, "value": "Dec" },

                                                      ];


                                                    }
                                                    else if (e.target.value == 28 || e.target.value == 29) {
                                                      listOfMonth = [{ "key": 1, "value": "Jan" },
                                                      { "key": 2, "value": "Feb" },
                                                      { "key": 3, "value": "Mar" },
                                                      { "key": 4, "value": "Apr" },
                                                      { "key": 5, "value": "May" },
                                                      { "key": 6, "value": "Jun" },
                                                      { "key": 7, "value": "Jul" },
                                                      { "key": 8, "value": "Aug" },
                                                      { "key": 9, "value": "Sep" },
                                                      { "key": 10, "value": "Oct" },
                                                      { "key": 11, "value": "Nov" },
                                                      { "key": 12, "value": "Dec" },
                                                      ];
                                                    } else {
                                                      listOfMonth = [{ "key": 1, "value": "Jan" },
                                                      { "key": 2, "value": "Feb" },
                                                      { "key": 3, "value": "Mar" },
                                                      { "key": 4, "value": "Apr" },
                                                      { "key": 5, "value": "May" },
                                                      { "key": 6, "value": "Jun" },
                                                      { "key": 7, "value": "Jul" },
                                                      { "key": 8, "value": "Aug" },
                                                      { "key": 9, "value": "Sep" },
                                                      { "key": 10, "value": "Oct" },
                                                      { "key": 11, "value": "Nov" },
                                                      { "key": 12, "value": "Dec" },
                                                      ];

                                                    }
                                                    setFieldValue(values.listOfMonth1 = listOfMonth)
                                                    // this.setState({
                                                    //   listOfMonth: listOfMonth
                                                    // })
                                                  }}
                                                className={
                                                  "form-control" +
                                                  (errors.today && touched.today
                                                    ? "is-invalid"
                                                    : "")
                                                }
                                                className="form-control"
                                              >
                                                <option value="">DD</option>
                                                {(
                                                  values.listOfDay1 ||
                                                  []
                                                ).map(d => (
                                                  <option
                                                    value={d}
                                                  >
                                                    {d}
                                                  </option>
                                                ))}
                                              </Field>
                                            </div>
                                            <div className="col-md-4 col-lg-4 col-sm-4 col-xs-4 pl pr">
                                              <Field
                                                as="select"
                                                name="tomonth"
                                                id="tomonth"
                                                //   onChange={handleChange}
                                                //     disabled={this.state.isDisabled}
                                                //  disabled
                                                onChange={
                                                  e => {
                                                    

                                                    setFieldValue(values.tomonth = e.target.value)

                                                    if (e.target.value == 2) {
                                                      var listOfDay = [];
                                                      for (var i = 1; i <= 29; i++) {
                                                        //    var current = year - i;
                                                        listOfDay.push(i);
                                                        // this.setState({ listOfDay: listOfDay });
                                                      }
                                                    } else if (e.target.value == 1 || e.target.value == 3 || e.target.value == 5 || e.target.value == 7 || e.target.value == 8 || e.target.value == 10 || e.target.value == 12) {
                                                      var listOfDay = [];
                                                      for (var i = 1; i <= 31; i++) {
                                                        //    var current = year - i;
                                                        listOfDay.push(i);
                                                        //  this.setState({ listOfDay: listOfDay });
                                                      }
                                                    }
                                                    else {
                                                      var listOfDay = [];
                                                      for (var i = 1; i <= 30; i++) {
                                                        //    var current = year - i;
                                                        listOfDay.push(i);
                                                        //  this.setState({ listOfDay: listOfDay });
                                                      }
                                                    }
                                                    setFieldValue(values.listOfDay1 = listOfDay)
                                                  }
                                                }
                                                className={
                                                  "form-control" +
                                                  (errors.tomonth && touched.tomonth
                                                    ? "is-invalid"
                                                    : "")
                                                }
                                                className="form-control"
                                              >
                                                <option value="">MM</option>
                                                {(
                                                  values.listOfMonth1 ||
                                                  []
                                                ).map(m => (
                                                  <option
                                                    value={m.key}
                                                  >
                                                    {m.value}
                                                  </option>
                                                ))}
                                              </Field>
                                            </div>
                                            <div className="col-md-5 col-lg-5 col-sm-5 col-xs-5 pl pr">
                                              <Field
                                                as="select"
                                                name="toyear"
                                                id="toyear"
                                                // onChange={handleChange}
                                                //    disabled={this.state.isDisabled}
                                                //  disabled
                                                className={
                                                  "form-control" +
                                                  (errors.toyear && touched.toyear
                                                    ? "is-invalid"
                                                    : "")
                                                }
                                                className="form-control"
                                              >
                                                <option value="">YYYY</option>
                                                {(
                                                  this.state.listOfYear1 ||
                                                  []
                                                ).map(year => (
                                                  <option
                                                    value={year}
                                                  >
                                                    {year}
                                                  </option>
                                                ))}
                                              </Field>
                                            </div>
                                          </div>
                                          <p className="validation">  {values.errmsg}</p>

                                          {errors.toDate && <div className="validation">{errors.toDate}</div>}
                                          {/* <Field
                                            type="date"
                                            name="toDate"
                                            id="toDate"
                                            autoComplete="toDate"
                                            className={
                                              "form-control" +
                                              (errors.toDate &&
                                                touched.toDate
                                                ? " is-invalid"
                                                : "")
                                            }
                                            onChange={(e) => {

                                              var Todate = e.target.value

                                              var FromDate = values.fromDate
                                              if (Todate !== '' && FromDate !== '') {
                                                if ((Date.parse(Todate) < Date.parse(FromDate))) {
                                                  //  alert();
                                                  warning("Attempted To date should be greater than Attempted From date", warningNotification);
                                                  setFieldValue(
                                                    (values.fromDate = "")
                                                  )
                                                  setFieldValue(
                                                    (values.toDate = "")
                                                  );
                                                }
                                                else {
                                                  setFieldValue(
                                                    (values.toDate = Todate)
                                                  )
                                                }
                                              }
                                              else {
                                                setFieldValue(
                                                  (values.toDate = Todate)
                                                )
                                              }
                                            }
                                            }
                                            placeholder="To Date"
                                          />
                                          <ErrorMessage
                                            name="toDate"
                                            component="div"
                                            className="validation"
                                          /> */}
                                        </div>



                                        <div class="col-md-4 col-sm-4 col-xs-12">
                                          <label>
                                            Status
                        </label>



                                          <Field
                                            as="select"
                                            name="status1"
                                            id="status1"
                                            autoComplete="status"
                                            className={
                                              "form-control" +
                                              (errors.status &&
                                                touched.status
                                                ? " is-invalid"
                                                : "")
                                            }
                                            placeholder="status"
                                          >
                                            <option>--Select--</option>
                                            <option value="Success">Success</option>
                                            <option value="Fail">Fail</option>

                                          </Field>
                                          <ErrorMessage
                                            name="Quartlet"
                                            component="div"
                                            className="validation"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 mb-20">
                                      <button
                                        type="submit"
                                        class="btn-4 pull-right"
                                        onClick={async () => {
                                          setFieldValue(
                                            (values.status = 'SEARCH')
                                          );
                                          //                                             
                                          // if( setFieldValue(
                                          //     (values.fromDate )
                                          //   )&& setFieldValue(
                                          //     (values.toDate)
                                          //   ))
                                          //   {
                                          //     setFieldValue(
                                          //         (values.fromDate )
                                          //       )&& setFieldValue(
                                          //         (values.toDate)
                                          //       )


                                          //   }


                                        }}
                                      >Search</button>
                                      <button
                                        type="submit"
                                        id="close"
                                        class="btn-4 mr-20 pull-right"
                                        onClick={async () => {
                                          setFieldValue(
                                            (values.status = 'RESET')
                                          );

                                          setFieldValue(
                                            (values.status1 = '')
                                          );
                                          setFieldValue(
                                            (values.lname = '')
                                          );
                                          setFieldValue(
                                            (values.fromDate = '')
                                          ); setFieldValue(
                                            (values.toDate = '')
                                          );

                                          setFieldValue(
                                            (values.emailId = '')
                                          );

                                          setFieldValue(
                                            (values.totalScore = '')
                                          ); setFieldValue(
                                            (values.Quartlet = '')
                                          );

                                          setFieldValue(
                                            (values.day = '')
                                          );
                                          setFieldValue(
                                            (values.month = '')
                                          );
                                          setFieldValue(
                                            (values.year = '')
                                          );


                                          setFieldValue(
                                            (values.today = '')
                                          );
                                          setFieldValue(
                                            (values.tomonth = '')
                                          );
                                          setFieldValue(
                                            (values.toyear = '')
                                          );

                                        }}
                                      >
                                        Reset
                  </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </section>
                        </Form>
                      </React.Fragment>
                    )}
                  />
                  <div class="table-header">
                    <h3>
                      <div class="row">
                        <div class="col-md-6 col-sm-6 col-xs-12">
                          <span class="left-icon">
                            <i class="fa fa-list" aria-hidden="true"></i>
                          </span>{" "}
                        Schedular List
                        </div>
                        <div class="col-md-6 col-sm-6 col-xs-12">
                          {/* <span class="right-icon">
                          


                            <a onClick={this.WhyTheFillForm}>

                              <i

                                class="fa fa-info"
                                aria-hidden="true"
                              ></i>
                            </a>
                          </span> */}
                          {/* <span class="right-icon">
                            <a title="Export to Excel">
                              <i
                                onClick={() => this.exportToCSV()}
                                class="fa fa-file-excel-o"
                                aria-hidden="true"
                              ></i>
                            </a>
                          </span> */}



                        </div>
                      </div>
                    </h3>
                  </div>
                  <div class="bg-box mb-30">
                    <div class="table-responsive">

                      <ReactTable
                        minRows={2}
                        data={this.state.jobData === null ? [] : this.state.jobData}


                        className="-striped -highlight"
                        filterable={false}
                        onPageChange={this.stateonpagechange}
                        defaultFilterMethod={this.filterCaseInsensitive}
                        ref={(refReactTable) => { this.refReactTable = refReactTable; }}

                        columns={[
                          {
                            columns: [

                              {
                                Header: "Sr No",
                                id: "row",
                                width: 50,
                                Cell: (row) => {
                                  return <div>{row.index + 1}</div>;
                                }
                              },
                              {
                                Header: "Date Of Job",
                                id: "DOJ",
                                accessor: d => d.dateOfJob ? moment(d.dateOfJob.split(" ")[0], "DD/MM/YYYY").format("DD-MM-YYYY") : ''
                              },
                              {
                                Header: "Job Name",
                                id: "jobName",
                                accessor: d => d.jobName,

                              },


                              {
                                Header: "Status",
                                id: "status",
                                accessor: d =>
                                  d.ftpStatus,

                              },

                              {
                                Header: "Action",
                                id: "action",
                                filterable: false,
                                sortable: false,
                                Cell: props => {
                                  return (
                                    <div>
                                      <a class="mar-left">

                                        <i
                                          title="Download Csv"
                                          className="fa fa-download"
                                          aria-hidden="true"

                                          onClick={e => this.downloadFile(props.original.documentId)}
                                        >
                                        </i>

                                      </a>
                                    </div>
                                  )
                                }
                              }



                            ]
                          }
                        ]}
                        defaultPageSize={10}
                        style={{
                          width: "100%",
                          maxHeight: "500px"
                        }}
                      />


                    </div>
                  </div>
                </div>

              </div>

            </div>
          </div>
        </section>


      </React.Fragment>
    );
  }
}

export default ReattemptTest;
