import React, { Component } from "react";
import DashboardHeader from "../../components/dashboardheader/dashboardheader";
import Finxfooter from "../finxfooter/finxfooter";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import DateRequest from "../../components/dateRequest/dateRequest";
import { LoginService } from "../../services/login.service";
import { PopUpPortal } from "../popups/PopUpPortal";
import $ from "jquery";
import { getAlertNotificationEvents, getAlertNotificationEventsPending, getAlertNotificationEventsError } from "../../reducer/alertsNotificationReducer"
import { getNotificationEvents, getNotificationEventsPending, getNotificationEventsError } from "../../reducer/notificationReducer";
import { fetchDistributor } from "../../services/distributor.service";
import { fetchCalenderEvents, fetchAlertNotificationEvents, fetchNotificationEvents, fetchProductDetailsByPartyId, fetchProductDetailsAllIsActive, getProgressOverview } from "../../services/fetchLearnerDashboard.service";
import Unauthorized from "../../components/unauthorized/Unauthorized";

const mapStateToProps = state => (
    {
        distributorProfileUser: state.distributorListReducer.distributorList,
        statusCountList: state.funnelStatusCountReducer.statusCountList,
        alertNotificationEvents: getAlertNotificationEvents(
            state.alertNotificationEventsReducer
        ),
        pendingAlertNotificationEvents: getAlertNotificationEventsPending(
            state.alertNotificationEventsReducer
        ),
        errorAlertNotificationEvents: getAlertNotificationEventsError(
            state.alertNotificationEventsReducer
        ),

        notificationEvents: getNotificationEvents(state.notificationEventsReducer),
        pendingNotificationEvents: getNotificationEventsPending(
            state.notificationEventsReducer
        ),
        errorNotificationEvents: getNotificationEventsError(
            state.notificationEventsReducer
        ),
    });

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            fetchDistributor: fetchDistributor,
            fetchAlertNotificationEvents: fetchAlertNotificationEvents,
            fetchNotificationEvents: fetchNotificationEvents,


        },
        dispatch
    );

const connectFunction = connect(mapStateToProps, mapDispatchToProps);

const dateRequest = connectFunction(
    class extends Component {
        constructor(props) {
            super(props);
            this.state = {
                loading: true
            };
        }

        componentDidMount() {
            let RoleId = localStorage.getItem('roleId')
            let partyID = localStorage.getItem("userid-Token");
            const { fetchNotificationEvents,
                fetchDistributor } = this.props;
            LoginService.TokenUser(res => {
                if (res.status === "success") {
                    localStorage.setItem("jwt-token", res.responseObject);

                    //  fetchDistributor("firstName=&lastName=&fromDate=&toDate=&emailId=&panNumber=&cityId=0");
                    //    fetchDistributor("firstName=&lastName=&fromDate=&toDate=&emailId=&panNumber=&cityId=0&roleId=6&applicationStatus=&stage=&additionalDetail=true");
                    fetchDistributor("firstName=&lastName=&fromDate=&toDate=&emailId=&panNumber=&cityId=0&roleId=6&applicationStatus=&stage=&additionalDetail=true&examLocation=&examFromDate=&examToDate=&stateId=&learnerId=0&amcId=0");

                    // + localStorage.getItem("roleId")
                    fetchAlertNotificationEvents();
                    fetchNotificationEvents("partyId=" + partyID);


                }
            });
        }

        render() {

            const { distributorProfileUser, pending, fetchUserAddressData,
                alertNotificationEvents,
                pendingAlertNotificationEvents,
                notificationEvents,
                pendingNotificationEvents,
                pendingUserRoleAccess,
                statusCountList

            } = this.props;
            localStorage.setItem("PersonnelData", JSON.stringify(this.props.personnelData))
            localStorage.setItem('AdminAlertNotification', JSON.stringify(alertNotificationEvents))
            //localStorage.setItem('AdminNotification', JSON.stringify(notificationEvents))

            let __FOUNDENCROACH;
            if (!pendingNotificationEvents && !pendingAlertNotificationEvents) {
                return (


                    <React.Fragment>
                        {/* {localStorage.getItem("roleId") == 6 ? */}
                        <React.Fragment>
                            <DashboardHeader
                                alertNotificationEvents={this.props.alertNotificationEvents}
                                statusCountList={statusCountList}
                                notificationEvents={this.props.notificationEvents}
                                adminUser={distributorProfileUser}
                                //adminProfileUser={this.props.adminProfileUser}
                                {...this.props}

                            />
                            <DateRequest
                                attemptedList={distributorProfileUser}
                                // statusCountList={statusCountList}
                                getAdminAddress={fetchUserAddressData}
                                fetchDistributor={fetchDistributor}
                                userRoleAccess={this.props.userRoleAccess}
                                {...this.props}
                            />
                            <Finxfooter />
                        </React.Fragment>
                        {/* : <Unauthorized /> */}

                        {/* } */}
                    </React.Fragment>

                );
            } else {
                return (<React.Fragment>
                    <DashboardHeader
                        adminProfileUser={this.props.pending}
                        {...this.props}

                    />
                </React.Fragment>)
            }
        }
    }
);

export default dateRequest;
