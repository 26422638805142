import React, { Component } from "react";
// import ReactExport from "react-data-export";
import XLSX from "xlsx";
import Finxfooter from "../../containers/finxfooter/finxfooter";
// import AdminHeader from "../../components/dashboardheader/adminheader";
import ReactTable from "react-table";
import { RegisterUserService } from "../../services/registration.service";
import { LoginService } from "../../services/login.service";
import {
    success,
    successNotification,
    errorNotification,
    error,
    warning,
    warningNotification
} from "../notification/notifications";
import { PopUpPortal } from "../../containers/popups/PopUpPortal";
import DashboardHeader from "../dashboardheader/dashboardheader";
// import Dropzone from "react-dropzone";
import { FileDrop } from 'react-file-drop';
import { ExcelService } from "../Excel/excel";
import * as moment from "moment";
import NISMScoreUploadTemplate from '../../assets/pdf/NISM Score Upload Template.xlsx'

// const ExcelFile = ReactExport.ExcelFile;
// const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
// const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
// const dataSet1 = [
//     {
//         srno: "1",
//         candidateName: "vaishnavi zadpe",
//         enrollmentNo: "2000059537",
//         candidateScores: "38/100",
//         ExaminationName: "NISM-Series-V-A: Mutual Fund Distributors Certification Examination",
//         examinationDate: "2021-06-18",
//         NISMRegistrationNumber: "NISM-201800131832",
//         enrollmentDate: "2021-06-18",
//         panNo: "AMAPH1411J",
//         examinationStatus: "Failed",
//         candidateEmail: "vaishnavizadpe@gmail.com",
//         InvigilationStatus: "Approved",
//     }
//     ,
//     {
//         srno: "2",
//         candidateName: "SUNNY HINDUJA",
//         enrollmentNo: "2000059537",
//         candidateScores: "50/100",
//         ExaminationName: "NISM-Series-V-A: Mutual Fund Distributors Certification Examination",
//         examinationDate: "2021-06-18",
//         NISMRegistrationNumber: "NISM-201800131832",
//         enrollmentDate: "2021-06-18",
//         panNo: "AMAPH1411S",
//         examinationStatus: "passed",
//         candidateEmail: "sunnyhinduja1991@gmail.com",
//         InvigilationStatus: "Approved",
//     }
//     ,
//     {
//         srno: "3",
//         candidateName: "dhanaji patil",
//         enrollmentNo: "2000059537",
//         candidateScores: "0/100",
//         ExaminationName: "NISM-Series-V-A: Mutual Fund Distributors Certification Examination",
//         examinationDate: "2021-06-18",
//         NISMRegistrationNumber: "NISM-201800131832",
//         enrollmentDate: "2021-06-18",
//         panNo: "AMAPH1411K",
//         examinationStatus: "absent",
//         candidateEmail: "dhanajipatil@gmail.com",
//         InvigilationStatus: "Approved",
//     }


// ];
const SheetJSFT = [
    "xlsx",
    "xlsb",
    "xlsm",
    "xls",
    "xml",
    "csv",
    "txt",
    "ods",
    "fods",
    "uos",
    "sylk",
    "dif",
    "dbf",
    "prn",
    "qpw",
    "123",
    "wb*",
    "wq*",
    "html",
    "htm"
]
    .map(function (x) {
        return "." + x;
    })
    .join(",");
const make_cols = refstr => {
    let o = [],
        C = XLSX.utils.decode_range(refstr).e.c + 1;

    for (var i = 0; i < C; ++i) o[i] = { name: XLSX.utils.encode_col(i), key: i };
    return o;
};



class AdminBulkUploadUser extends Component {
    constructor(props) {
        super(props);

        //console.log("props", this.props);
        // if (localStorage.getItem('login') !== null) {
        this.state = {
            file: {},
            data: [],
            cols: [],
            isFileUploaded: false,
            checkFileSize: false,
            totalRecords: "",
            successfullyUploaded: 0,
            faildRecords: 0,
            invalidData: [],
            checkDocumentType: false,
            hideUI: false,
            showUploadButton: false
        };
        // }
        // else {
        //   window.location.href = '/';
        // }
        this.handleFile = this.handleFile.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
    }

    handleCancel = () => {
       // this.props.history.goBack('-1');
       window.location.replace('/dashboard');
    };
    exportToCSV = () => {

        let modifiedData = [];
        let data = this.state.invalidData;

        if (data.length > 0) {


            data.forEach((element, index) => {

                var data1 = {

                    "Name": element['Candidate Name'],
                    "PAN No": element['Candidate PAN no'],
                    "Email ID": element['Candidate Email'],
                    //  "Mobile No": element.mobileNo,
                    "NISM Entrollment Number": element['Enrollment No'],
                    "NISM Entrollment Date": moment(element['Examination Date']).format("DD-MM-YYYY"),
                    "Failure Date": element.error[0]

                    //   "action": element.action,






                };
                modifiedData.push(data1);
            });
            // let date = new Date();
            let time = new Date().toLocaleString().split(",")[1]

            let time1 = time.split(" ")[2]
            let time2 = time.split(":")[0] + "-" + time.split(":")[1] + "-" + time1
            ///  alert(time)
            time2 = time2.split(" ")[1]

            // let date1 = moment(date).format("DD-MM-YYYY")
            //  let d2 = date1

            let title1 = "ScoreReport" + time2 + "-"
            //   let title1 = "ExpertMFD-DateRequestList-" + date1

            ExcelService.ExcelData(modifiedData, title1);
        }
        else {
            warning("No record found", warningNotification)
        }
    };

    handleChange(e) {

        const files = e.target.files;
        if (files.length > 0) {
            const checkType = files[0].name.split(".");
            if (["xls", "xlsx", "csv"].indexOf(checkType[1]) < 0) {
                this.setState({
                    checkDocumentType: true,
                    file: files[0],
                    isFileUploaded: false,
                    showUploadButton: false
                });
            } else if (files && files[0]) {
                //files[0].size < 500000000 &&
                this.setState({
                    checkDocumentType: false,
                    file: files[0],
                    isFileUploaded: true,
                    showUploadButton: true
                });
            }
        }
        e.target.value = null;
    }

    handleFile() {

        const reader = new FileReader(this.state.file);
        const rABS = !!reader.readAsBinaryString;

        reader.onload = e => {
            //
            /* Parse data */
            const bstr = e.target.result;
            const wb = XLSX.read(bstr, {
                type: rABS ? "binary" : "array",
                bookVBA: true
            });
            e = "";

            /* Get first worksheet */
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            /* Convert array of arrays */
            const data = XLSX.utils.sheet_to_json(ws);
            console.log(data)

            data.map(e => {
                let examDate = moment(new Date(Math.round((e["Examination Date"] - 25569) * 86400 * 1000))).format("YYYY-M-D")
                let enrollDate = moment(new Date(Math.round((e["Enrollment Date"] - 25569) * 86400 * 1000))).format("D-M-YYYY")
                e["Examination Date"] = examDate
                e["Enrollment Date"] = enrollDate
                return null;
            })

            //      console.log(new Date((data[0]["Enrollment No"] - (25567 + 1)) * 86400 * 1000))
            console.log(new Date(Math.round((43902 - 25569) * 86400 * 1000)))
            /* Update state */
            this.setState({ loading: true });
            this.setState({ data: data, cols: make_cols(ws["!ref"]) }, () => {

                LoginService.TokenUser(res => {
                    if (res.status === "success") {
                        let flag = true
                        localStorage.setItem("jwt-token", res.responseObject);
                        let head =
                            ["Sr. No", "Candidate Name", "Enrollment No", "Examination Name", "Examination Date", "Invigilation Status",
                                "Examination Status", "Candidate's Scores",
                                "NISM Registration Number", "Candidate PAN no", "Candidate Email", "Enrollment Date"]
                        if (data.length > 0) {
                            var keys = Object.keys(data[0]);

                            // for (let i = 0; i < head.length; i++) {
                            // if (!keys.includes(head)) {
                            //     console.log("false")
                            //     flag = false
                            // } else {
                            //     console.log("true")
                            //     flag = true
                            // }

                            keys.some(item => {
                                if (head.includes(item)) {
                                    console.log("false")
                                    // flag = false
                                } else {
                                    console.log("true")

                                    flag = false
                                }

                            return null;
                            })
                            // };
                            if (flag === true) {
                                data.map(e => {
                                    let partyId = JSON.parse(localStorage.getItem("login") || [])["partyId"]
                                    e["emailId"] = JSON.parse(localStorage.getItem("login") || [])["emailId"]
                                    e["partyId"] = partyId.toString()
                                    return null;
                                })
                                console.log(keys)
                                RegisterUserService.bulkUpload(data, res1 => {
                                    if (res1.data.status === "success") {
                                        // success(
                                        //     "Data Uploaded successfully",
                                        //     successNotification
                                        // );
                                        if(res1.data.responseObject.recordFailCount > 0){                                     
                                            error(
                                                "There are some records that were not uploaded successfully",
                                                errorNotification
                                            );
                                        } else {
                                            success(
                                                "Data Uploaded successfully",
                                                successNotification
                                            );
                                        }
                                        
                                        this.setState({ loading: false });
                                        this.setState({
                                            hideUI: true,
                                            file: {},
                                            data: [],
                                            cols: [],
                                            faildRecords: res1.data.responseObject.recordFailCount || 0,
                                            successfullyUploaded: res1.data.responseObject
                                                .recordSuccessCount
                                                ? res1.data.responseObject.recordSuccessCount
                                                : 0,
                                            invalidData:
                                                res1.data.responseObject.failList || [],
                                            isFileUploaded: false,
                                            checkFileSize: false,
                                            checkDocumentType: false,
                                            showUploadButton: false
                                        });
                                    } else {
                                        this.setState({ loading: false });

                                        if (res1.data.reasonText === "Invalid Token") {
                                            error("Session Expired", errorNotification);
                                            setTimeout(() => {
                                                window.location.href = "/";
                                            }, 5000);
                                        } else {
                                            this.setState({
                                                successfullyUploaded: res1.data.responseObject
                                                    .successfullRecords
                                                    ? res1.data.responseObject.successfullRecords
                                                    : " "
                                            });

                                            warning(
                                                "Due to errors in records, file uploading has been failed. Kindly check the grid for further process",
                                                warningNotification
                                            );
                                            this.setState({ loading: false });
                                            this.setState({
                                                hideUI: true,
                                                file: {},
                                                data: [],
                                                cols: [],
                                                faildRecords: res1.data.responseObject.invalidRecords || "",
                                                successfullyUploaded:
                                                    res1.data.responseObject.successfullRecords,
                                                invalidData:
                                                    res1.data.responseObject.invalidLearnerData || [],
                                                isFileUploaded: false,
                                                checkFileSize: false,
                                                checkDocumentType: false,
                                                showUploadButton: false
                                            });
                                        }
                                    }
                                });
                            }
                            else {
                                this.setState({ loading: false });
                                this.setState({
                                    hideUI: true,

                                    invalidData: [],

                                });
                                warning("Please upload valid template", warningNotification)
                            }
                        }
                        else {
                            this.setState({ loading: false });
                            this.setState({

                                invalidData: [],

                            });
                            warning("Please upload valid template", warningNotification)
                        }
                    }
                    else {

                        this.setState({ loading: false });
                        this.setState({

                            invalidData: [],

                        });
                        warning("Please upload valid template", warningNotification)
                    }

                });
            });
            this.setState({ totalRecords: this.state.data.length });
        };

        if (rABS) {
            reader.readAsBinaryString(this.state.file);
        } else {
            reader.readAsArrayBuffer(this.state.file);
        }
    }
    filterCaseInsensitive = ({ id, value }, row) => {
        return row[id] !== undefined
            ? String(row[id].toLowerCase()).includes(value.toLowerCase())
            : true;
    };

    render() {
        // var alertdata = JSON.parse(localStorage.getItem("alertd"));
        // var notealertdata = JSON.parse(localStorage.getItem("notealert"));
        // var alertdat = JSON.parse(localStorage.getItem("alertheader"));
        //alert(this.state.successfullyUploaded)
        return (
            <React.Fragment>
                {/* <AdminHeader
                    //alertNotificationEvents={this.props.location.state.alerts}
                    //notificationEvents={this.props.location.state.notification}
                    {...this.props}
                /> */}
                <DashboardHeader
                    // alertNotificationEvents={this.props.alertNotificationEvents}
                    // notificationEvents={this.props.notificationEvents}
                    // adminUser={attemptedList}
                    //adminProfileUser={this.props.adminProfileUser}
                    {...this.props}

                />
                {this.state.loading && (
                    <PopUpPortal
                        HidePopup={this.state.loading}
                        IsVisible={this.state.loading}
                    />
                )}
                <section className="dashboard-tab bulk-summary pt-50">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 col-sm-12 col-xs-12">
                                <section className="create-user" id="bulkUploadNISM">
                                    <div className="bg-box bulk-box">
                                        <div className="row">
                                            <div className="col-md-5 col-sm-5 col-xs-12">
                                                <div className="scr-board scr_bulk">
                                                    {/* <h4 className="bulk_head">
                                Download Excel Template{" "}
                                <i
                                  className="fa fa-download"
                                  aria-hidden="true"
                                ></i>
                              </h4> */}
                                                    <h4 className="bulk_head">
                                                        Download Excel Template{" "}
                                                        <a 
                                                            download="NISM Score upload Template" 
                                                            href={NISMScoreUploadTemplate}
                                                            style={{ cursor:"pointer",fontSize:"18px"}}>
                                                        <i className="fa fa-download" aria-hidden="true"></i>
                                                        </a>
                                                    </h4>
                                                    {/* <ExcelFile
                                                        element={
                                                            <h4 className="bulk_head">
                                                                Download NISM Score Template{" "}
                                                                <i
                                                                    className="fa fa-download"
                                                                    aria-hidden="true"
                                                                ></i>
                                                            </h4>
                                                        }
                                                        filename="NISM Score upload Template"
                                                    > 
                                                        <ExcelSheet data={dataSet1} name="Employees">

                                                            <ExcelColumn
                                                                label="Sr. No"
                                                                value="srno"

                                                            />
                                                            <ExcelColumn
                                                                label="Candidate Name"
                                                                value="candidateName"
                                                            />
                                                            <ExcelColumn
                                                                label="Enrollment No"
                                                                value="enrollmentNo"
                                                            />
                                                            <ExcelColumn label="Examination Name" value="ExaminationName" />
                                                            <ExcelColumn label="Examination Date" value="examinationDate" />
                                                            <ExcelColumn label="Invigilation Status" value="InvigilationStatus" />
                                                            <ExcelColumn label="Examination Status" value="examinationStatus" />
                                                            <ExcelColumn label="Candidate's Scores" value="candidateScores" />
                                                            <ExcelColumn label="NISM Registration Number" value="NISMRegistrationNumber" />


                                                            <ExcelColumn
                                                                label="Candidate PAN no"
                                                                value="panNo"
                                                            />
                                                            <ExcelColumn
                                                                label="Candidate Email"
                                                                value="candidateEmail"
                                                            />
                                                            <ExcelColumn
                                                                label="Enrollment Date"
                                                                value="enrollmentDate"
                                                            />
                                                        </ExcelSheet>
                                                     </ExcelFile> */}
                                                    <div className="lock-img">
                                                        <i className="fa fa-upload" aria-hidden="true"></i>
                                                    </div>
                                                    <div className="bottom-line"></div>
                                                    <h2 className="color_magento">NISM Score BULK UPLOAD</h2>
                                                    <div className="scr-form">
                                                        {this.state.isFileUploaded ? (
                                                            <div>
                                                                {/* <span className="text-center> */}
                                                                <p className="text-center color_gray text-wrap-bulk-upload" id="uploadedFileName">
                                                                    {this.state.file.name}
                                                                    {/* File Name: {this.state.file.name} */}
                                                                    <br />
                                                                    <br />
                                                                    {/* Please Upload. */}
                                                                </p>
                                                                {/* </span> */}
                                                                {/* <span className="icon btn-file btn-file3">
                                                                    <i
                                                                        className="fa fa-paperclip"
                                                                        aria-hidden="true"
                                                                    ></i>
                                                                </span> */}
                                                                {/* <input
                                                                    type="file"
                                                                    name="file"
                                                                    accept={SheetJSFT}
                                                                    onChange={this.handleChange}
                                                                /> */}
                                                                <div className="icon btn-file btn-file1">
                                                                    <p className="color_red">
                                                                        <button className="browse-text"><i
                                                                            className="fa fa-paperclip"
                                                                            aria-hidden="true"
                                                                        ></i>
                                                                         Select File To Upload</button>
                                                                        <input
                                                                            type="file"
                                                                            name="file"
                                                                            accept={SheetJSFT}
                                                                            onChange={this.handleChange}
                                                                        />
                                                                    </p>
                                                                </div>
                                                                <FileDrop
                                                                    onFrameDragEnter={(event) => console.log('onFrameDragEnter', event)}
                                                                    onFrameDragLeave={(event) => console.log('onFrameDragLeave', event)}
                                                                    onFrameDrop={(event) => console.log('onFrameDrop', event)}
                                                                    onDragOver={(event) => console.log('onDragOver', event)}
                                                                    onDragLeave={(event) => console.log('onDragLeave', event)}
                                                                    onDrop={(files, e) => {


                                                                        //  const files = e.target.files;
                                                                        if (files.length > 0) {
                                                                            const checkType = files[0].name.split(".");
                                                                            if (["xls", "xlsx"].indexOf(checkType[1]) < 0) {
                                                                                this.setState({
                                                                                    checkDocumentType: true,
                                                                                    file: files[0],
                                                                                    isFileUploaded: false,
                                                                                    showUploadButton: false
                                                                                });
                                                                            } else if (files && files[0]) {
                                                                                //files[0].size < 500000000 &&
                                                                                this.setState({
                                                                                    checkDocumentType: false,
                                                                                    file: files[0],
                                                                                    isFileUploaded: true,
                                                                                    showUploadButton: true
                                                                                });
                                                                            }
                                                                        }
                                                                        // e.target.value = null;
                                                                    }
                                                                        // this.handleChange(event)

                                                                    }
                                                                    accept={SheetJSFT}

                                                                >
                                                                    Drop files here!
        </FileDrop>

                                                            </div>

                                                        ) : (
                                                                <div>
                                                                    {/* <div className="col-md-6 col-lg-6 col-sm-6 col-xs-6"> */}
                                                                    <div className="icon btn-file btn-file1">
                                                                        <p className="color_red">
                                                                            <button className="browse-text"><i
                                                                                className="fa fa-paperclip"
                                                                                aria-hidden="true"
                                                                            ></i>
                                                                         Select File To Upload</button>
                                                                            <input
                                                                                type="file"
                                                                                name="file"
                                                                                accept={SheetJSFT}
                                                                                onChange={this.handleChange}
                                                                            />
                                                                        </p>
                                                                    </div>
                                                                    <FileDrop
                                                                        onFrameDragEnter={(event) => console.log('onFrameDragEnter', event)}
                                                                        onFrameDragLeave={(event) => console.log('onFrameDragLeave', event)}
                                                                        onFrameDrop={(event) => console.log('onFrameDrop', event)}
                                                                        onDragOver={(event) => console.log('onDragOver', event)}
                                                                        onDragLeave={(event) => console.log('onDragLeave', event)}
                                                                        onDrop={(files, e) => {

                                                                            //  const files = e.target.files;
                                                                            if (files.length > 0) {
                                                                                const checkType = files[0].name.split(".");
                                                                                if (["xls", "xlsx"].indexOf(checkType[1]) < 0) {
                                                                                    this.setState({
                                                                                        checkDocumentType: true,
                                                                                        file: files[0],
                                                                                        isFileUploaded: false,
                                                                                        showUploadButton: false
                                                                                    });
                                                                                } else if (files && files[0]) {
                                                                                    //files[0].size < 500000000 &&
                                                                                    this.setState({
                                                                                        checkDocumentType: false,
                                                                                        file: files[0],
                                                                                        isFileUploaded: true,
                                                                                        showUploadButton: true
                                                                                    });
                                                                                }
                                                                            }
                                                                            // e.target.value = null;
                                                                        }
                                                                            // this.handleChange(event)

                                                                        }
                                                                        accept={SheetJSFT}

                                                                    >
                                                                        Drop files here!
        </FileDrop>

                                                                    {/* </div>
                                  <div className="col-md-6 col-lg-6 col-sm-6 col-xs-6"> */}
                                                                    {/* <span className="icon btn-file btn-file2">
                                                                        <i
                                                                            className="fa fa-paperclip"
                                                                            aria-hidden="true"
                                                                        ></i>

                                                                        <input
                                                                            type="file"
                                                                            name="file"
                                                                            accept={SheetJSFT}
                                                                            onChange={this.handleChange}
                                                                        />
                                                                    </span>
                                                                    <FileDrop
                                                                        onFrameDragEnter={(event) => console.log('onFrameDragEnter', event)}
                                                                        onFrameDragLeave={(event) => console.log('onFrameDragLeave', event)}
                                                                        onFrameDrop={(event) => console.log('onFrameDrop', event)}
                                                                        onDragOver={(event) => console.log('onDragOver', event)}
                                                                        onDragLeave={(event) => console.log('onDragLeave', event)}
                                                                        onDrop={(files, e) => {

                                                                            //  const files = e.target.files;
                                                                            if (files.length > 0) {
                                                                                const checkType = files[0].name.split(".");
                                                                                if (["xls", "xlsx"].indexOf(checkType[1]) < 0) {
                                                                                    this.setState({
                                                                                        checkDocumentType: true,
                                                                                        file: files[0],
                                                                                        isFileUploaded: false,
                                                                                        showUploadButton: false
                                                                                    });
                                                                                } else if (files && files[0]) {
                                                                                    //files[0].size < 500000000 &&
                                                                                    this.setState({
                                                                                        checkDocumentType: false,
                                                                                        file: files[0],
                                                                                        isFileUploaded: true,
                                                                                        showUploadButton: true
                                                                                    });
                                                                                }
                                                                            }
                                                                            // e.target.value = null;
                                                                        }
                                                                            // this.handleChange(event)

                                                                        }
                                                                        accept={SheetJSFT}

                                                                    >
                                                                        Drop some files here!
        </FileDrop> */}

                                                                </div>
                                                                // </div>

                                                            )}


                                                        {

                                                            this.state.checkDocumentType ? (
                                                                <p className="text-center validation1">
                                                                    Invalid File Format
                                                                </p>
                                                            ) : (
                                                                    ""
                                                                )}
                                                        {/* {this.state.checkFileSize ? (
                              <p className="text-center color_gray">
                                File Size is too large(Maximum File Size:5MB).
                              </p>
                            ) : (
                                ""
                              )} */}

                                                        {/* <span className="icon btn-file btn-file2">
                              <i
                                className="fa fa-paperclip"
                                aria-hidden="true"
                              ></i>
                              <input
                                type="file"
                                name="file"
                                accept={SheetJSFT}
                                onChange={this.handleChange}
                              />
                            </span> */}


                                                    </div>
                                                    <div className="">
                                                        <span class="file-size-limit-bulkupload">
                                                            Supported Formats: .xls, .xlsx ,.csv only. <br />
                                                        </span>
                                                    </div>
                                                    {this.state.showUploadButton ? (
                                                        <button
                                                            type="submit"
                                                            onClick={this.handleFile}
                                                            className="btn-1"
                                                        // disabled={!this.state.isFileUploaded}
                                                        >
                                                            Upload
                                                        </button>
                                                    ) : (
                                                            ""
                                                        )}
                                                </div>
                                            </div>

                                            <div className="col-md-7 col-sm-7 col-xs-12">
                                                <div className="table-header">
                                                    <h3>
                                                        <div class="row">
                                                            <div class="col-md-9 col-sm-9 col-xs-12">
                                                                <span class="left-icon">
                                                                    <i class="fa fa-list" aria-hidden="true"></i>
                                                                </span> NISM Score Bulk Upload Summary
                              </div>
                                                            <div class="col-md-3 col-sm-3 col-xs-12">
                                                                <span class="right-icon">
                                                                    <a href>
                                                                        <i
                                                                            className="fa fa-info"
                                                                            aria-hidden="true"
                                                                        ></i>
                                                                    </a>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </h3>
                                                    {/* <h3 className="text-center">
                            <span className="left-icon">
                              <i className="fa fa-list" aria-hidden="true"></i>
                            </span>
                            Bulk Upload Summary
                            </h3>
                          <span className="right-icon">
                            <a href="">
                              <i
                                className="fa fa-info"
                                aria-hidden="true"
                              ></i>
                            </a>
                          </span> */}

                                                </div>
                                                <div className="mr-20 ml-20">
                                                    <div className="table-responsive">
                                                        <table className="user-table bulkupload-tab">
                                                            <tr>
                                                                <td>
                                                                    <p>Total Records</p>
                                                                </td>
                                                                <td>
                                                                    {" "}
                                                                    <span className="records">
                                                                        {this.state.totalRecords || 0}
                                                                    </span>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    <p>Successfully Uploaded </p>
                                                                </td>
                                                                <td>
                                                                    <span className="success">

                                                                        {this.state.successfullyUploaded === null ? 0 : this.state.successfullyUploaded || 0}
                                                                    </span>{" "}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    <p>Fail to Upload </p>
                                                                </td>
                                                                <td>
                                                                    <span className="fail">
                                                                        {this.state.faildRecords || 0}
                                                                    </span>
                                                                </td>
                                                            </tr>
                                                        </table>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <button className="btn-5 mr-20 ml-20 mt-10 pull-right" onClick={this.handleCancel}><span aria-hidden="true">Back</span></button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                        {
                            this.state.invalidData.length > 0 ? (
                                <div className="row">
                                    <div className="col-md-12 col-sm-12 col-xs-12">
                                        <div className="table-header">
                                            <h3>
                                                <div className="row">
                                                    <div className="col-md-12 col-sm-12 col-xs-12">
                                                        <span className="left-icon">
                                                            <i
                                                                className="fa fa-question-circle-o fa-lg"
                                                                aria-hidden="true"
                                                            ></i>
                                                        </span>{" "}
                          NISM Score Failed Bulk Upload Report
                        </div>
                                                    <span class="right-icon">

                                                        <a title="Export to Excel" href>
                                                            <i
                                                                onClick={() => this.exportToCSV()}
                                                                class="fa fa-file-excel-o"
                                                                aria-hidden="true"

                                                            ></i>
                                                        </a>
                                                    </span>
                                                </div>
                                            </h3>
                                        </div>
                                        {this.state.invalidData.length > 0 ? (
                                            <div className="bg-box">
                                                <div className="table-responsive">
                                                    <ReactTable
                                                        data={this.state.invalidData}
                                                        className="-striped -highlight"
                                                        filterable
                                                        defaultFilterMethod={this.filterCaseInsensitive}
                                                        columns={[
                                                            {
                                                                columns: [
                                                                    {
                                                                        Header: "Name",
                                                                        id: "name",
                                                                        accessor: d =>
                                                                            d['Candidate Name']

                                                                    },

                                                                    {
                                                                        Header: "Pan No.",
                                                                        id: "pan",
                                                                        accessor: d => d['Candidate PAN no']

                                                                    },

                                                                    {
                                                                        Header: "Email ID",
                                                                        id: "email",
                                                                        accessor: d => d['Candidate Email']
                                                                    },


                                                                    {
                                                                        Header: "NISM Enrollment Number",
                                                                        id: "enrollNo",
                                                                        accessor: d => d['Enrollment No']
                                                                    },
                                                                    {
                                                                        Header: "NISM Exam date",
                                                                        id: "examDate",
                                                                        accessor: d => d['Examination Date']
                                                                    },

                                                                    {
                                                                        Header: "Failure Reason",
                                                                        id: "reason",
                                                                        accessor: d => d.error[0],
                                                                        style: { "white-space": "unset" }
                                                                    }
                                                                ]
                                                            }
                                                        ]}
                                                        defaultPageSize={5}
                                                        style={{
                                                            width: "100%",
                                                            height: "500px"
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        ) : null}
                                    </div>
                                </div>
                            ) : (
                                    ""
                                )
                        }
                    </div>
                </section>
                <Finxfooter />
            </React.Fragment>
        );
    }
}

export default AdminBulkUploadUser;
