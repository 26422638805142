import React, { Component } from "react";
import ReactExport from "react-data-export";
import XLSX from "xlsx";
import Finxfooter from "../../containers/finxfooter/finxfooter";
import AdminHeader from "../../components/dashboardheader/adminheader";
import ReactTable from "react-table";
import { RegisterUserService } from "../../services/registration.service";
import { LoginService } from "../../services/login.service";
import {
    success,
    successNotification,
    errorNotification,
    error,
    warning,
    warningNotification
} from "../notification/notifications";
import { PopUpPortal } from "../../containers/popups/PopUpPortal";
import DashboardHeader from "../dashboardheader/dashboardheader";
import Dropzone from "react-dropzone";
import { FileDrop } from 'react-file-drop';
import { ExcelService } from "../Excel/excel";
import * as moment from "moment";
import masterData from "../../assets/pdf/NISM-location-master.xls";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
const dataSet1 = [
    {
        email: "a@gmail.com",
        mode: 0,
        stateName: "Andhra Pradesh",
        stateId: 2,
        center: "Karimnagar-NSEiT,Karimnagar",
        requestedDate: "2021-06-18",
        fromTime: "10:56",
        toTime: "11:56"
    },
    {
        email: "abc@gmail.com",
        mode: 1,
        stateName: "",
        stateId: "",
        center: "",
        requestedDate: "2021-06-18",
        fromTime: "10:56",
        toTime: "11:56"
    }
];
const SheetJSFT = [
    "xlsx",
    "xlsb",
    "xlsm",
    "xls",
    "xml",
    "csv",
    "txt",
    "ods",
    "fods",
    "uos",
    "sylk",
    "dif",
    "dbf",
    "prn",
    "qpw",
    "123",
    "wb*",
    "wq*",
    "html",
    "htm"
]
    .map(function (x) {
        return "." + x;
    })
    .join(",");
const make_cols = refstr => {
    let o = [],
        C = XLSX.utils.decode_range(refstr).e.c + 1;

    for (var i = 0; i < C; ++i) o[i] = { name: XLSX.utils.encode_col(i), key: i };
    return o;
};



class BulkExamDate extends Component {
    constructor(props) {
        super(props);

        this.state = {
            file: {},
            data: [],
            cols: [],
            isFileUploaded: false,
            checkFileSize: false,
            totalRecords: "",
            successfullyUploaded: 0,
            faildRecords: 0,
            invalidData: [],
            checkDocumentType: false,
            hideUI: false,
            showUploadButton: false
        };

        this.handleFile = this.handleFile.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
    }

    handleCancel = () => {
        window.location.replace('/dashboard');
    };
    exportToCSV = () => {

        let modifiedData = [];
        let data = this.state.invalidData;

        if (data.length > 0) {
            data.forEach((element, index) => {
                var data1 = {
                    "PAN No": element.panNo,
                    "Failure Reason": element.fail
                };
                modifiedData.push(data1);
            });
            let date = new Date();
            let time = new Date().toLocaleString().split(",")[1]

            let time1 = time.split(" ")[2]
            let time2 = time.split(":")[0] + "-" + time.split(":")[1] + "-" + time1
            time2 = time2.split(" ")[1]

            let date1 = moment(date).format("DD-MM-YYYY")

            let title1 = "BulkExamDateReport" + time2 + "-"

            ExcelService.ExcelData(modifiedData, title1);
        }
        else {
            warning("No record found", warningNotification)
        }
    };

    excelDateToJSDate(excel_date, time = false) {
        let day_time = excel_date % 1
        let meridiem = "AMPM"
        let hour = Math.floor(day_time * 24)
        let minute = Math.floor(Math.abs(day_time * 24 * 60) % 60)
        let second = Math.floor(Math.abs(day_time * 24 * 60 * 60) % 60)
        hour >= 12 ? meridiem = meridiem.slice(2, 4) : meridiem = meridiem.slice(0, 2)
        hour > 12 ? hour = hour - 12 : hour = hour
        hour = hour < 10 ? "0" + hour : hour
        minute = minute < 10 ? "0" + minute : minute
        second = second < 10 ? "0" + second : second
        let daytime = "" + hour + ":" + minute + ":" + second + " " + meridiem
        return time ? daytime : (new Date(0, 0, excel_date, 0, -new Date(0).getTimezoneOffset(), 0)).toLocaleDateString(navigator.language, {}) + " " + daytime
    };

    handleChange(e) {

        const files = e.target.files;
        if (files.length > 0) {
            const checkType = files[0].name.split(".");
            if (["xls", "xlsx", "csv"].indexOf(checkType[1]) < 0) {
                this.setState({
                    checkDocumentType: true,
                    file: files[0],
                    isFileUploaded: false,
                    showUploadButton: false
                });
            } else if (files && files[0]) {
                this.setState({
                    checkDocumentType: false,
                    file: files[0],
                    isFileUploaded: true,
                    showUploadButton: true
                });
            }
        }
        e.target.value = null;
    }



    handleFile() {
debugger
        const reader = new FileReader(this.state.file);
        const rABS = !!reader.readAsBinaryString;

        reader.onload = e => {
            /* Parse data */
            const bstr = e.target.result;
            const wb = XLSX.read(bstr, {
                type: rABS ? "binary" : "array",
                bookVBA: true
            });
            e = "";

            /* Get first worksheet */
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            /* Convert array of arrays */
            const data = XLSX.utils.sheet_to_json(ws);
            console.log("Data::: ", data)


            data.map(e => {
                let examDate = moment(new Date(Math.round((e["Examination_Date"] - 25569) * 86400 * 1000))).format("YYYY-M-D")
                e["Examination_Date"] = examDate

                let newFromTime = (this.excelDateToJSDate(e["From_Time"], true));
                e["From_Time"] = moment(newFromTime, ["h:mm:ss A"]).format("HH:mm");

                let newToTime = (this.excelDateToJSDate(e["To_Time"], true));
                e["To_Time"] = moment(newToTime, ["h:mm:ss A"]).format("HH:mm");

            })

            /* Update state */
            this.setState({ loading: true });
            this.setState({ data: data, cols: make_cols(ws["!ref"]) }, () => {

                LoginService.TokenUser(res => {
                    if (res.status === "success") {
                        let flag = true
                        localStorage.setItem("jwt-token", res.responseObject);
                        let head = ["email", "Mode", "State", "Examination_Center", "Examination_Date", "From_Time", "To_Time", , "State_Id"]

                        if (data.length > 0) {
                            var keys = Object.keys(data[0]);
                            keys.some(item => {
                                if (head.includes(item)) {
                                    console.log("false")
                                } else {
                                    console.log("true")
                                    flag = false
                                }
                            })
                            if (flag === true) {
                                let dataList = []
                                for (let i = 0; i < data.length; i++) {
                                    let temp = {}
                                    debugger
                                    temp = {
                                        email: data[i].email !== undefined ? data[i].email : "",
                                        action: 'NISM PROFILE CREATE',
                                        workflowId: "",
                                        additionalDetails: {
                                            action: "ACCEPT",
                                            stateId: data[i].Mode === 0 ? data[i].State_Id!==undefined ? data[i].State_Id : 0:"",
                                            center: data[i].Mode === 0 ? data[i].Examination_Center !== undefined ? data[i].Examination_Center : "" : "",
                                            flag: data[i].Mode === 0 ? false : data[i].Mode === 1 ? true : "",
                                            location: data[i].Mode === 0 ?  data[i].Examination_Center : "",
                                            requestedDate: data[i].Examination_Date,
                                            fromTime: data[i].From_Time,
                                            toTime: data[i].To_Time,
                                            stateName: data[i].Mode === 0  ? data[i].State !== undefined ? data[i].State : "":"",
                                            admitCardDocId: '',
                                        },
                                    };
                                    dataList.push(temp);
                                }

                                RegisterUserService.examDateUpload(dataList, res1 => {
                                    if (res1.data.status === "success") {
                                        if (res1.data.responseObject.failCount > 0) {
                                            error(
                                                "There are some records that were not uploaded successfully",
                                                errorNotification
                                            );
                                        } else {
                                            success(
                                                "Data Uploaded successfully",
                                                successNotification
                                            );
                                        }

                                        this.setState({ loading: false });
                                        this.setState({
                                            hideUI: true,
                                            file: {},
                                            data: [],
                                            cols: [],
                                            faildRecords: res1.data.responseObject.failCount || 0,
                                            successfullyUploaded: res1.data.responseObject
                                                .successCount
                                                ? res1.data.responseObject.successCount
                                                : 0,
                                            invalidData:
                                                res1.data.responseObject.failList || [],
                                            isFileUploaded: false,
                                            checkFileSize: false,
                                            checkDocumentType: false,
                                            showUploadButton: false
                                        });
                                    } else {
                                        this.setState({ loading: false });

                                        if (res1.data.reasonText === "Invalid Token") {
                                            error("Session Expired", errorNotification);
                                            setTimeout(() => {
                                                window.location.href = "/";
                                            }, 5000);
                                        } else {
                                            this.setState({
                                                successfullyUploaded: res1.data.responseObject
                                                    .successfullRecords
                                                    ? res1.data.responseObject.successfullRecords
                                                    : " "
                                            });

                                            warning(
                                                "Due to errors in records, file uploading has been failed. Kindly check the grid for further process",
                                                warningNotification
                                            );
                                            this.setState({ loading: false });
                                            this.setState({
                                                hideUI: true,
                                                file: {},
                                                data: [],
                                                cols: [],
                                                faildRecords: res1.data.responseObject.invalidRecords || "",
                                                successfullyUploaded:
                                                    res1.data.responseObject.successfullRecords,
                                                invalidData:
                                                    res1.data.responseObject.invalidLearnerData || [],
                                                isFileUploaded: false,
                                                checkFileSize: false,
                                                checkDocumentType: false,
                                                showUploadButton: false
                                            });
                                        }
                                    }
                                });
                            }
                            else {
                                this.setState({ loading: false });
                                this.setState({
                                    hideUI: true,

                                    invalidData: [],

                                });
                                warning("Please upload valid template", warningNotification)
                            }
                        }
                        else {
                            this.setState({ loading: false });
                            this.setState({

                                invalidData: [],

                            });
                            warning("Please upload valid template", warningNotification)
                        }
                    }
                    else {

                        this.setState({ loading: false });
                        this.setState({

                            invalidData: [],

                        });
                        warning("Please upload valid template", warningNotification)
                    }

                });
            });
            this.setState({ totalRecords: this.state.data.length });
        };

        if (rABS) {
            reader.readAsBinaryString(this.state.file);
        } else {
            reader.readAsArrayBuffer(this.state.file);
        }
    }
    filterCaseInsensitive = ({ id, value }, row) => {
        return row[id] !== undefined
            ? String(row[id].toLowerCase()).includes(value.toLowerCase())
            : true;
    };

    render() {
        var alertdata = JSON.parse(localStorage.getItem("alertd"));
        var notealertdata = JSON.parse(localStorage.getItem("notealert"));
        var alertdat = JSON.parse(localStorage.getItem("alertheader"));
        //alert(this.state.successfullyUploaded)
        return (
            <React.Fragment>
                <DashboardHeader
                    {...this.props}

                />
                {this.state.loading && (
                    <PopUpPortal
                        HidePopup={this.state.loading}
                        IsVisible={this.state.loading}
                    />
                )}
                <section className="dashboard-tab bulk-summary pt-50">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 col-sm-12 col-xs-12">
                                <section className="create-user" id="bulkUploadNISM">
                                    <div className="bg-box bulk-box">
                                        <div className="row">
                                            <div className="col-md-5 col-sm-5 col-xs-12">
                                                <div className="scr-board scr_bulk">
                                                    <h4 className="bulk_head">
                                                        <a 
                                                        href={masterData} 
                                                        target="_blank" style={{color:'#111', fontSize: "18px" }}>
                                                            {" "}
                                                            Download Master Exam Center Data {" "}
                                                            <i
                                                                className="fa fa-download"
                                                                aria-hidden="true"
                                                            ></i>
                                                        </a>
                                                    </h4>
                                                    <ExcelFile
                                                        element={
                                                            <h4 className="bulk_head">
                                                                Download NISM Exam Date Template{" "}
                                                                <i
                                                                    className="fa fa-download"
                                                                    aria-hidden="true"
                                                                ></i>
                                                            </h4>
                                                        }
                                                        filename="NISM Exam Date upload Template"
                                                    >
                                                        <ExcelSheet data={dataSet1} name="Employees">

                                                            <ExcelColumn
                                                                label="email"
                                                                value="email"
                                                            />
                                                            <ExcelColumn
                                                                label="Mode"
                                                                value="mode"
                                                            />
                                                            <ExcelColumn
                                                                label="State"
                                                                value="stateName"
                                                            />
                                                            <ExcelColumn
                                                                label="Examination_Center"
                                                                value="center"
                                                            />
                                                            <ExcelColumn
                                                                label="Examination_Date"
                                                                value="requestedDate"
                                                            />
                                                            <ExcelColumn
                                                                label="From_Time"
                                                                value="fromTime"
                                                            />
                                                            <ExcelColumn
                                                                label="To_Time"
                                                                value="toTime"
                                                            />
                                                            <ExcelColumn
                                                                label="State_Id"
                                                                value="stateId"
                                                            />
                                                        </ExcelSheet>
                                                    </ExcelFile>
                                                    <div className="lock-img">
                                                        <i className="fa fa-upload" aria-hidden="true"></i>
                                                    </div>
                                                    <div className="bottom-line"></div>
                                                    <h2 className="color_magento">NISM Exam Date BULK UPLOAD</h2>
                                                    <div className="scr-form">
                                                        {this.state.isFileUploaded ? (
                                                            <div>
                                                                <p className="text-center color_gray text-wrap-bulk-upload" id="uploadedFileName">
                                                                    {this.state.file.name}
                                                                    <br />
                                                                    <br />
                                                                </p>
                                                                <div className="icon btn-file btn-file1">
                                                                    <p className="color_red">
                                                                        <button className="browse-text"><i
                                                                            className="fa fa-paperclip"
                                                                            aria-hidden="true"
                                                                        ></i>
                                                                            Select File To Upload</button>
                                                                        <input
                                                                            type="file"
                                                                            name="file"
                                                                            accept={SheetJSFT}
                                                                            onChange={this.handleChange}
                                                                        />
                                                                    </p>
                                                                </div>
                                                                <FileDrop
                                                                    onFrameDragEnter={(event) => console.log('onFrameDragEnter', event)}
                                                                    onFrameDragLeave={(event) => console.log('onFrameDragLeave', event)}
                                                                    onFrameDrop={(event) => console.log('onFrameDrop', event)}
                                                                    onDragOver={(event) => console.log('onDragOver', event)}
                                                                    onDragLeave={(event) => console.log('onDragLeave', event)}
                                                                    onDrop={(files, e) => {

                                                                        if (files.length > 0) {
                                                                            const checkType = files[0].name.split(".");
                                                                            if (["xls", "xlsx"].indexOf(checkType[1]) < 0) {
                                                                                this.setState({
                                                                                    checkDocumentType: true,
                                                                                    file: files[0],
                                                                                    isFileUploaded: false,
                                                                                    showUploadButton: false
                                                                                });
                                                                            } else if (files && files[0]) {
                                                                                this.setState({
                                                                                    checkDocumentType: false,
                                                                                    file: files[0],
                                                                                    isFileUploaded: true,
                                                                                    showUploadButton: true
                                                                                });
                                                                            }
                                                                        }
                                                                    }

                                                                    }
                                                                    accept={SheetJSFT}

                                                                >
                                                                    Drop files here!
                                                                </FileDrop>

                                                            </div>

                                                        ) : (
                                                            <div>
                                                                <div className="icon btn-file btn-file1">
                                                                    <p className="color_red">
                                                                        <button className="browse-text"><i
                                                                            className="fa fa-paperclip"
                                                                            aria-hidden="true"
                                                                        ></i>
                                                                            Select File To Upload</button>
                                                                        <input
                                                                            type="file"
                                                                            name="file"
                                                                            accept={SheetJSFT}
                                                                            onChange={this.handleChange}
                                                                        />
                                                                    </p>
                                                                </div>
                                                                <FileDrop
                                                                    onFrameDragEnter={(event) => console.log('onFrameDragEnter', event)}
                                                                    onFrameDragLeave={(event) => console.log('onFrameDragLeave', event)}
                                                                    onFrameDrop={(event) => console.log('onFrameDrop', event)}
                                                                    onDragOver={(event) => console.log('onDragOver', event)}
                                                                    onDragLeave={(event) => console.log('onDragLeave', event)}
                                                                    onDrop={(files, e) => {

                                                                        if (files.length > 0) {
                                                                            const checkType = files[0].name.split(".");
                                                                            if (["xls", "xlsx"].indexOf(checkType[1]) < 0) {
                                                                                this.setState({
                                                                                    checkDocumentType: true,
                                                                                    file: files[0],
                                                                                    isFileUploaded: false,
                                                                                    showUploadButton: false
                                                                                });
                                                                            } else if (files && files[0]) {
                                                                                this.setState({
                                                                                    checkDocumentType: false,
                                                                                    file: files[0],
                                                                                    isFileUploaded: true,
                                                                                    showUploadButton: true
                                                                                });
                                                                            }
                                                                        }
                                                                    }
                                                                    }
                                                                    accept={SheetJSFT}

                                                                >
                                                                    Drop files here!
                                                                </FileDrop>

                                                            </div>
                                                        )}
                                                        {

                                                            this.state.checkDocumentType ? (
                                                                <p className="text-center validation1">
                                                                    Invalid File Format
                                                                </p>
                                                            ) : (
                                                                ""
                                                            )}
                                                    </div>
                                                    <div className="">
                                                        <span class="file-size-limit-bulkupload">
                                                            Supported Formats: .xls, .xlsx ,.csv only. <br />
                                                        </span>
                                                    </div>
                                                    {this.state.showUploadButton ? (
                                                        <button
                                                            type="submit"
                                                            onClick={this.handleFile}
                                                            className="btn-1"
                                                        >
                                                            Upload
                                                        </button>
                                                    ) : (
                                                        ""
                                                    )}
                                                </div>
                                            </div>

                                            <div className="col-md-7 col-sm-7 col-xs-12">
                                                <div className="table-header">
                                                    <h3>
                                                        <div class="row">
                                                            <div class="col-md-9 col-sm-9 col-xs-12">
                                                                <span class="left-icon">
                                                                    <i class="fa fa-list" aria-hidden="true"></i>
                                                                </span> NISM Exam Date Bulk Upload Summary
                                                            </div>
                                                            <div class="col-md-3 col-sm-3 col-xs-12">
                                                                <span class="right-icon">
                                                                    <a title="">
                                                                        <i
                                                                            className="fa fa-info"
                                                                            aria-hidden="true"
                                                                        ></i>
                                                                    </a>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </h3>

                                                </div>
                                                <div className="mr-20 ml-20">
                                                    <div className="table-responsive">
                                                        <table className="user-table bulkupload-tab">
                                                            <tr>
                                                                <td>
                                                                    <p>Total Records</p>
                                                                </td>
                                                                <td>
                                                                    {" "}
                                                                    <span className="records">
                                                                        {this.state.totalRecords || 0}
                                                                    </span>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    <p>Successfully Uploaded </p>
                                                                </td>
                                                                <td>
                                                                    <span className="success">

                                                                        {this.state.successfullyUploaded === null ? 0 : this.state.successfullyUploaded || 0}
                                                                    </span>{" "}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    <p>Fail to Upload </p>
                                                                </td>
                                                                <td>
                                                                    <span className="fail">
                                                                        {this.state.faildRecords || 0}
                                                                    </span>
                                                                </td>
                                                            </tr>
                                                        </table>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <button className="btn-5 mr-20 ml-20 mt-10 pull-right" onClick={this.handleCancel}><span aria-hidden="true">Back</span></button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                        {
                            this.state.invalidData.length > 0 ? (
                                <div className="row">
                                    <div className="col-md-12 col-sm-12 col-xs-12">
                                        <div className="table-header">
                                            <h3>
                                                <div className="row">
                                                    <div className="col-md-12 col-sm-12 col-xs-12">
                                                        <span className="left-icon">
                                                            <i
                                                                className="fa fa-question-circle-o fa-lg"
                                                                aria-hidden="true"
                                                            ></i>
                                                        </span>{" "}
                                                        NISM Exam Date Failed Bulk Upload Report
                                                    </div>
                                                    <span class="right-icon">

                                                        <a title="Export to Excel" >
                                                            <i
                                                                onClick={() => this.exportToCSV()}
                                                                class="fa fa-file-excel-o"
                                                                aria-hidden="true"

                                                            ></i>
                                                        </a>
                                                    </span>
                                                </div>
                                            </h3>
                                        </div>
                                        {this.state.invalidData.length > 0 ? (
                                            <div className="bg-box">
                                                <div className="table-responsive">
                                                    <ReactTable
                                                        data={this.state.invalidData}
                                                        className="-striped -highlight"
                                                        filterable
                                                        defaultFilterMethod={this.filterCaseInsensitive}
                                                        columns={[
                                                            {
                                                                columns: [
                                                                    {
                                                                        Header: "Email",
                                                                        id: "email",
                                                                        accessor: d => d.email
                                                                    },


                                                                    {
                                                                        Header: "Failure Reason",
                                                                        id: "reason",
                                                                        accessor: d => d.fail,
                                                                        style: { "white-space": "unset" }
                                                                    }
                                                                ]
                                                            }
                                                        ]}
                                                        defaultPageSize={5}
                                                        style={{
                                                            width: "100%",
                                                            height: "500px"
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        ) : null}
                                    </div>
                                </div>
                            ) : (
                                ""
                            )
                        }
                    </div>
                </section>
                <Finxfooter />
            </React.Fragment>
        );
    }
}

export default BulkExamDate;
