import { Global_var } from "../global/global_var";
import RestDataSource from "../services/restdatasource";
import { LoginService } from "./login.service";
export const RegionManagementService = {
  getCountry,
  getState,
  getCity,
  getAddressByPin,
  getCityById,
  getCenter,
  generateOTPMail,
  validateOTPMail,
  emailAuthntication,
  validateFalsePositiveEmail,
  validateEmail,
  getUserEmailFlag,
  isOtpValidated
};

function getCountry(fn, fnError) {
  var url = Global_var.BASEURL + Global_var.URL_GETCOUNTRY;
  return new RestDataSource(url, fnError).GetData(res => {
    if (res != null) {
      if (res.headers["jtitoken"] != null) {
        localStorage.setItem("jti-token", res.headers.jtitoken);
      }
      fn(res.data);
    }
  });
}
// function getState(country, fn, fnError) {
//   var url = Global_var.BASEURL + Global_var.URL_GETSTATE;
//   return new RestDataSource(url, fnError).GetOneByParam(country, res => {
//     if (res != null) {
//       if (res.headers["jtitoken"] != null) {
//         localStorage.setItem("jti-token", res.headers.jtitoken);
//       }
//       fn(res.data);
//     }
//   });
// }

function getState(country, fn, fnError) {
  LoginService.TokenUser(res => {
    if (res.status === "success") {
      localStorage.setItem("jwt-token", res.responseObject);
      var url = Global_var.BASEURL + Global_var.URL_GETSTATE;
        return new RestDataSource(url, fnError).GetOneByParam(country, res => {
          if (res != null) {
            if (res.headers["jtitoken"] != null) {
              localStorage.setItem("jti-token", res.headers.jtitoken);
            }
            fn(res.data);
          }
        }
      );
    }
  }, fnError);
}

// function getCity(stateId, fn, fnError) {

//   var url = Global_var.BASEURL + Global_var.URL_GETCITY;
//   return new RestDataSource(url, fnError).GetOneByParam(stateId, res => {
//     if (res != null) {

//       if (res.headers["jtitoken"] != null) {
//         localStorage.setItem("jti-token", res.headers.jtitoken);
//       }
//       fn(res.data);
//     }
//   });
// }


function getCity(stateId, fn, fnError) {
  LoginService.TokenUser(res => {
    if (res.status === "success") {
      localStorage.setItem("jwt-token", res.responseObject);
      var url = Global_var.BASEURL + Global_var.URL_GETCITY;
        return new RestDataSource(url, fnError).GetOneByParam(stateId, res => {
          if (res != null) {
            if (res.headers["jtitoken"] != null) {
              localStorage.setItem("jti-token", res.headers.jtitoken);
            }
            fn(res.data);
          }
        }
      );
    }
  }, fnError);
}

function getAddressByPin(pinno, fn, fnError) {
  var url = Global_var.BASEURL + Global_var.URL_REGION_MANAGEMENT_BY_PIN;
  //  var url = Global_var.BASEURL+ Global_var.URL_GETADDRESSBYPIN;
  // Global_var.BASEURL
  return new RestDataSource(url, fnError).GetOneByParam(pinno, res => {
    if (res != null) {
      if (res.headers["jtitoken"] != null) {
        localStorage.setItem("jti-token", res.headers.jtitoken);
      }
      fn(res.data);
    }
  });
}


function getCityById(stateId, fn, fnError) {
  var url = Global_var.BASEURL + Global_var.URL_CITY_BY_ID;
  return new RestDataSource(url, fnError).GetOneByParam(stateId, res => {
    if (res != null) {
      if (res.headers["jtitoken"] != null) {
        localStorage.setItem("jti-token", res.headers.jtitoken);
      }
      fn(res.data);
    }
  });
}


function getCenter(stateId, fn, fnError) {

  //var url = "http://192.168.12.100:9007/fc-learner-dashboard/api/v1/NISM_Location/get"
  var url = Global_var.BASEURL + Global_var.URL_CENTER;
  return new RestDataSource(url, fnError).GetOneByParam(stateId, res => {
    if (res != null) {

      if (res.headers["jtitoken"] != null) {
        localStorage.setItem("jti-token", res.headers.jtitoken);
      }
      fn(res.data);
    }
  });
}

function generateOTPMail(user, fn, fnError) { 
  var url = Global_var.BASEURL + Global_var.URL_OTP_EMAIL;
  return new RestDataSource(url, fnError).Store(user, res => {
    if (res != null) {
      if (res.headers["jtitoken"] != null) {
        localStorage.setItem("jti-token", res.headers.jtitoken);
      }  
      fn(res.data);
    }
  });
}

function validateOTPMail(user, fn, fnError) { 
  var url = Global_var.BASEURL + Global_var.URL_VALIDATE_OTP_EMAIL;
  return new RestDataSource(url, fnError).Store(user, res => {
    if (res != null) {
      if (res.headers["jtitoken"] != null) {
        localStorage.setItem("jti-token", res.headers.jtitoken);
      }  
      fn(res.data);
    }
  });
}

function emailAuthntication(user, fn, fnError) { 
  var url = Global_var.BASEURL + Global_var.URL_AUTHENTICATE_EMAIL;
  return new RestDataSource(url, fnError).GetOneByParam(user, res => {
    if (res != null) {
      if (res.headers["jtitoken"] != null) {
        localStorage.setItem("jti-token", res.headers.jtitoken);
      }  
      fn(res.data);
    }
  });
}

function validateFalsePositiveEmail(user, fn, fnError) { 
  var url = Global_var.BASEURL + Global_var.URL_CREATED_BY;
  return new RestDataSource(url, fnError).GetOneByParam(user, res => {
    if (res != null) {
      if (res.headers["jtitoken"] != null) {
        localStorage.setItem("jti-token", res.headers.jtitoken);
      }  
      fn(res.data);
    }
  });
}

function validateEmail(data, fn, fnError) {
  var url = Global_var.BASEURL + Global_var.URL_VALIDATE_EMAIL;
  return new RestDataSource(url, fnError).GetOneByParam(data, (res) => {
    if (res != null) {
      if (res.headers["jtitoken"] != null) {
        localStorage.setItem("jti-token", res.headers.jtitoken);
      }
      fn(res.data);
    }
  });
}

function getUserEmailFlag(stateId, fn){
  var url = Global_var.BASEURL + Global_var.URL_USER_EMAIL_FLAG;
  console.log(url);
  return new RestDataSource(url).GetOneByParam(stateId, res => {
    if (res != null) {
      if (res.headers["jtitoken"] != null) {
        localStorage.setItem("jti-token", res.headers.jtitoken);
      }
      fn(res.data);
    }
  });
}

function isOtpValidated(mobileNo, fn, fnError) { 
  var url = Global_var.BASEURL + Global_var.URL_IS_OTP_VALIDATED;
  return new RestDataSource(url, fnError).GetOneByParam(mobileNo, (res) => {
    if (res != null) {
      if (res.headers["jtitoken"] != null) {
        localStorage.setItem("jti-token", res.headers.jtitoken);
      }
      fn(res.data);
    }
  });
}