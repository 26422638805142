import React from "react";
import { withRouter } from "react-router-dom";
import { LoginService } from "../../services/login.service";
import {
  sessionerror,
  sessionNotification,
  warningNotification,
  warning,
  error,
  errorNotification,
  success,
  successNotification,
} from "../../components/notification/notifications";
import Modal from "react-responsive-modal";
import { MobileValidationService } from "../../services/mobile.validation.service";
import $ from "jquery";
import { RegisterUserService } from "../../services/registration.service";
import featmodal from "../../assets/images/feat-modal.png";

class amfifinxheader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userLoginName: "",
      userPassword: "",
      userLoginName1: "",
      userPassword1: "",
      companyid: "",
      countryCode: "91",
      showResendOtp: false,
      showTimer: false,
      isMobile: false,
      isEmail: false,
      isPasswordfocused: false,
      isLogined: false,
      OTPTokenCount: 0,
      loading: false,
      time: {},
      seconds: 46,
      isMaintainenceActivated: false,
      warning: false,
      rbackState: [],
      enableSubmit: false,
      signuptabName: "tab active",
      showGenerateOtp: true,
      showResendOtp: false,
      showOTPBox: false,
      paymentLink: false,
    };
    this.timer = 0;
    this.startTimer = this.startTimer.bind(this);
    this.countDown = this.countDown.bind(this);
    this.handleuserLoginName = this.handleuserLoginName.bind(this);
    this.handleUserPassword = this.handleUserPassword.bind(this);
    this.handleuserLoginName1 = this.handleuserLoginName1.bind(this);
    this.handleUserPassword1 = this.handleUserPassword1.bind(this);
    this._handleKeyDown = this._handleKeyDown.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.handleBlur1 = this.handleBlur1.bind(this);

  }
  forgotpassword = (event) => {
    localStorage.setItem("reload", "true");
    this.props.history.push("/forgotpassword", {
      isloaded: true,
    });
  };

  makePayment = (event) => {
    let paymentUrl = "https://rzp.io/l/mfd-karein-shuru"
    window.open(paymentUrl, "_blank");
  };

  secondsToTime = (secs) => {
    let hours = Math.floor(secs / (60 * 60));
    let divisor_for_minutes = secs % (60 * 60);
    let minutes = Math.floor(divisor_for_minutes / 60);
    let divisor_for_seconds = divisor_for_minutes % 60;
    let seconds = Math.ceil(divisor_for_seconds);
    let obj = {
      h: hours,
      m: minutes,
      s: seconds,
    };
    return obj;
  };
  componentWillReceiveProps(nextProps) {
    if (nextProps.showModal === true) {
      window.$('#amfi-Reg').modal('show');
    }
  }
  componentDidMount() {

    window.$('#amfi-Reg').modal('show');

    $(window).blur(function () {
      window.addEventListener("storage", (e) => {
        if (
          localStorage.getItem("userid-Token") === null &&
          localStorage.getItem("login") === null
        ) {
          if (localStorage.getItem("windowloggedout") === false) {
          } else {
            localStorage.clear();

            window.location.href = "/";
          }
        }
        e.currentTarget.removeEventListener("storage", e);
      });
    });

    if (
      localStorage.getItem("userid-Token") !== null &&
      localStorage.getItem("login") !== null
    ) {
      if (localStorage.getItem("roleId") == "5") {
        if (localStorage.getItem("checkedtab") == "false") {
          localStorage.setItem("checkedtab", true);
          window.location.href = "/learnerDashboard";
        }
      } else if (localStorage.getItem("roleId") === "6") {
        $(".modal-backdrop").hide();
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        let page = urlParams.get("page");
        let UrllearnerPartyId = urlParams.get("learnerPartyId");
        localStorage.setItem("UrlPage", page);
        localStorage.setItem("UrllearnerPartyId", UrllearnerPartyId);
        if (page == "profile") {
          this.props.history.push("/userprofile");
        } else {
          window.location.href = "/dashboard";
        }

        localStorage.setItem("reload", "true");
      } else if (localStorage.getItem("roleId") === "7") {
        $(".modal-backdrop").hide();
        this.props.history.push("/dashboard");
        localStorage.setItem("reload", "true");
      } else {
        if (localStorage.getItem("checkedtab") == "false") {
          localStorage.setItem("checkedtab", true);
          window.location.href = "/admin-dashboard";
        }
      }
    } else {
      if (localStorage.getItem("checkedtab") == "false") {
        localStorage.setItem("checkedtab", true);
        window.location.href = "/";
      }
    }

    let timeLeftVar = this.secondsToTime(this.state.seconds);
    this.setState({ time: timeLeftVar });

    // Check session timeout URL
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const sessiontimeout = urlParams.get("sessiontimeout");
    if (sessiontimeout) {
    }

    $("#return-to-top").click(function () {
      $("body,html").animate(
        {
          scrollTop: 0,
        },
        500
      );
    });
  }

  startTimer() {
    this.handleGenerateOtp();
    this.setState({
      time: {},
      seconds: 46,
      userPassword: "",
      OTPTokenCount: 0,
    });
    if (this.timer == 0 && this.state.seconds == 46) {
      this.timer = setInterval(this.countDown, 1000);
    } else {
      clearInterval(this.timer);
      this.timer = setInterval(this.countDown, 1000);
    }
  }

  countDown() {
    // Remove one second, set state so a re-render happens.
    let seconds = this.state.seconds - 1 <= 0 ? 0 : this.state.seconds - 1;

    this.setState({
      time: this.secondsToTime(seconds),
      seconds: seconds,
    });

    // Check if we're at zero.
    if (seconds == 0) {
      this.setState({ showResendOtp: true, showTimer: true });
      clearInterval(this.timer);
    }
  }

  handleuserLoginName(e) {
    let isMobile = this.isNumber(e.target.value);

    if (isMobile) {
      this.setState({
        userPassword: "",
      });
    }
    if (isMobile && !this.state.showResendOtp) {
    } else if (!isMobile) {
      this.setState({
        isMobile: false,
        showResendOtp: false,
        showTimer: false,
      });
      let Emailvalid = this.isEmail(e.target.value);
      if (Emailvalid) {
      }
    }
    this.setState({ userLoginName: e.target.value });
  }

  handleuserLoginName1(e) {
    this.setState({ isMobile: false, showResendOtp: false, showTimer: false });
    let Emailvalid = this.isEmail(e.target.value);
    if (Emailvalid) {
      this.ValidateEmail(e.target.value);
    }

    this.setState({ userLoginName1: e.target.value });
  }

  handleBlur(e) {
    if (this.state.isMobile) {
      this.setState({
        userPassword: "",
        showGenerateOtp: true,
      });
    }

    if (this.state.isMobile) {
      if (e.target.value.length < 10 || e.target.value.length > 10) {
        this.setState({
          showOTPBox: false,
        });
        warning("Mobile number should be 10 digits", warningNotification);
      } else {
        if (this.state.isMobile && !this.state.showResendOtp) {
          if (e.target.value.length === 10) {
            this.setState({ isMobile: true, showTimer: true });
          }
        }
      }
    }
  }
  handleBlur1(e) {
    this.ValidateEmail(e.target.value);
  }
  ValidateEmail(value) {
    let emailvaidateParam = "emailID=" + value.toLowerCase();
    LoginService.TokenUser((res) => {
      if (res.status === "success") {
        localStorage.setItem("jwt-token", res.responseObject);
        RegisterUserService.validateUserData(emailvaidateParam, (res1) => {
          if (res1.status === "success") {
            this.setState({
              enableSubmit: false,
            });
            warning(
              "Your Email id is not registered with us. Please register yourself.",
              warningNotification
            );
          } else {
            this.setState({
              enableSubmit: true,
            });
          }
        });
      }
    });
  }

  handleGenerateOtp = () => {
    localStorage.setItem("TokenName", "jwt");
    LoginService.TokenUser((res) => {
      if (res.status === "success") {
        localStorage.setItem("jti-token", res.responseObject);
        localStorage.setItem("jwt-token", res.responseObject);
        let mobile = this.state.userLoginName;
        mobile = "mobileNo=" + mobile + "&productID";
        MobileValidationService.MobileValidation(mobile, (res1) => {
          if (res1.status === "success") {
            if (res1.reasonCode === "FC-02") {
              MobileValidationService.MobileOTP(
                this.state.userLoginName,
                this.state.countryCode,
                (res2) => {
                  if (res2.status === "success") {
                    success("You will receive otp in 45 secs.", {
                      ...successNotification,
                      dismiss: {
                        duration: 3000,
                        onScreen: false,
                        showIcon: true,
                      },
                    });
                    this.setState({
                      showGenerateOtp: false,
                      showOTPBox: true,
                    });
                  }
                  return true;
                },
                (error) => {
                  error(error, errorNotification);
                }
              );
              this.setState({
                showResendOtp: false,
                showTimer: true,
                enableSubmit: true,
              });
            } else if (res1.reasonCode === "FC-01") {
              warning("Mobile number not registered. Please try again", {
                ...warningNotification,
                dismiss: {
                  duration: 3000,
                  onScreen: false,
                  showIcon: true,
                },
              });
              this.setState({
                showResendOtp: false,
                showTimer: false,
                enableSubmit: false,
              });
            } else {
              warning(res.reasonText, {
                ...warningNotification,
                container: "top-center",
              });
              this.setState({ showResendOtp: false, showTimer: false });
            }
          } else {
            let reasonText =
              res1.reasonText === null
                ? "Duplicate Records Issue"
                : res1.reasonText;
            warning(reasonText, {
              ...warningNotification,
              container: "top-center",
            });
            this.setState({ showResendOtp: false, showTimer: false });
          }
        });
      }
    });
  };

  isEmail(e) {
    const re =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

    if (re.test(e)) {
      this.setState({ isEmail: true });
      return true;
    } else {
      this.setState({ isEmail: false });
      return false;
    }
  }

  isNumber(e) {
    const re = /^[0-9\b]+$/;
    if (re.test(e)) {
      this.setState({ isMobile: true });
      return true;
    } else {
      this.setState({ isMobile: false });
      return false;
    }
  }
  isMaxLen(e) {
    const re = /^[0-9\b]+$/;
    if (re.test(e)) {
      return "4";
    } else {
      return "100";
    }
  }
  handleUserPassword(e) {
    this.setState({ isPasswordfocused: true, userPassword: e.target.value });
  }
  handleUserPassword1(e) {
    this.setState({ isPasswordfocused: true, userPassword1: e.target.value });
  }
  _handleKeyDown(e) {
    if (e.key === "Enter") {
      if (e.target.name === "password") {
        this.setState({
          isPasswordfocused: true,
          userPassword: e.target.value,
        });
        this.loginHandler(e);
      }
    }
    if (e.getModifierState("CapsLock")) {
      this.setState({ warning: true });
    } else {
      this.setState({ warning: false });
    }
  }

  learnerDashboardpro = () => {
    this.props.history.push("/learnerDashboard");
  };

  loginHandler = (event) => {
    if (event !== undefined) {
      event.preventDefault();
    }
    if (!this.state.isMaintainenceActivated) {
      if (this.state.userLoginName !== "" && this.state.userPassword !== "") {
        LoginService.paymentLoginCheck(
          "emailId=" + this.state.userLoginName,
          (payResponse) => {
            if (payResponse.reasonCode === "02") {
              this.setState({ loading: true });
              const LoginDetails = {
                userLoginName: this.state.userLoginName.trim(),
                emailId: this.state.userLoginName.trim(),
                userPassword: this.state.userPassword.trim(),
              };
              console.log(LoginDetails);
              var logincon = [];
              this.setState({ loading: true });
              LoginService.SendUser(
                LoginDetails,
                this.state.isMobile,
                (res) => {
                  if (res.status === "success") {
                    logincon = res;

                    this.setState({ credentials: logincon });
                    localStorage.setItem(
                      "eMail",
                      logincon.responseObject.emailId
                    );
                    localStorage.setItem(
                      "userid-Token",
                      logincon.responseObject.partyId
                    );
                    localStorage.setItem(
                      "userid",
                      logincon.responseObject.userId
                    );
                    localStorage.setItem(
                      "ciel-userid-Token",
                      logincon.responseObject.partyId
                    );

                    localStorage.setItem(
                      "roleId",
                      logincon.responseObject.role[0].roleId
                    );
                    localStorage.setItem(
                      "ischangepass",
                      logincon.responseObject.forcePasswordChangeOnNextLoginFlag
                    );
                    localStorage.setItem(
                      "roleName",
                      logincon.responseObject.role[0].roleName
                    );
                    localStorage.setItem("checkedtab", false);
                    localStorage.setItem("TokenName", "jti");

                    this.setState((state) => {
                      let oldstate = Object.assign({}, state);
                      localStorage.setItem(
                        "login",
                        JSON.stringify(oldstate.credentials.responseObject)
                      );
                    });
                    LoginService.TokenUser((res) => {
                      this.setState((state) => {
                        let oldstate = Object.assign({}, state);
                        oldstate.credentials.responseObject.token =
                          res.responseObject;
                        this.state = oldstate;
                        localStorage.setItem("jti-token", res.responseObject);
                        localStorage.setItem(
                          "login",
                          JSON.stringify(oldstate.credentials.responseObject)
                        );
                      });
                    });
                    localStorage.setItem("TokenName", "jwt");

                    LoginService.TokenUser((res) => {
                      localStorage.setItem("jwt-token", res.responseObject);
                      this.setState({ loading: false });
                      if (
                        this.state.credentials.responseObject[
                        "forcePasswordChangeOnNextLoginFlag"
                        ] === "1"
                      ) {
                        localStorage.setItem("reload", "true");
                        this.props.history.push("/changepassword", {
                          forcePasswordChangeOnNextLoginFlag:
                            this.state.credentials.responseObject[
                            "forcePasswordChangeOnNextLoginFlag"
                            ],
                        });
                      } else {
                        if (
                          this.state.credentials.responseObject.role[0]
                            .roleId === 5
                        ) {
                          $(".modal-backdrop").hide();
                          this.props.history.push("/learnerDashboard");
                          localStorage.setItem("reload", "true");
                        } else if (
                          this.state.credentials.responseObject.role[0]
                            .roleId === 6
                        ) {
                          $(".modal-backdrop").hide();
                          const queryString = window.location.search;
                          const urlParams = new URLSearchParams(queryString);
                          let page = urlParams.get("page");
                          let UrllearnerPartyId =
                            urlParams.get("learnerPartyId");
                          localStorage.setItem("UrlPage", page);
                          localStorage.setItem(
                            "UrllearnerPartyId",
                            UrllearnerPartyId
                          );
                          if (page == "profile") {
                            this.props.history.push("/userprofile");
                          } else {
                            this.props.history.push("/dashboard");
                          }
                          localStorage.setItem("reload", "true");
                        } else if (
                          this.state.credentials.responseObject.role[0]
                            .roleId === 7
                        ) {
                          $(".modal-backdrop").hide();
                          this.props.history.push("/dashboard");
                          localStorage.setItem("reload", "true");
                        } else if (
                          this.state.credentials.responseObject.role[0]
                            .roleId === 2
                        ) {
                          $(".modal-backdrop").hide();
                          this.props.history.push("/admin-dashboard");
                          localStorage.setItem("reload", "true");
                        }
                      }
                    });
                  } else {
                    this.setState({ loading: false });

                    if (res.reasonCode === "DM_02") {
                      let otpcounter = parseInt(this.state.OTPTokenCount) + 1;
                      this.setState({ OTPTokenCount: otpcounter });
                      if (this.state.OTPTokenCount >= 4) {
                        this.setState({
                          loading: false,
                          isLogined: false,
                          showResendOtp: true,
                          showTimer: false,
                        });
                        warning(
                          "You have made 10 unsuccessful attempts to Login to ExpertMFD. Click on Resend OTP",
                          warningNotification
                        );
                      } else {
                        warning(
                          res.reasonText + " count " + this.state.OTPTokenCount,
                          warningNotification
                        );
                      }
                    } else if (res.reasonCode === "SM_11") {
                      warning(res.reasonText, {
                        ...warningNotification,
                        container: "top-center",
                      });

                      window.location.href = "/";
                    } else if (res.reasonCode === "SM-03") {
                      if (res.reasonText === "InActive user") {
                        warning(
                          "Access unauthorised, Please contact expertmfd@ciel.co.in or 9922953305 for login support",
                          warningNotification
                        );
                      } else {
                        warning(res.reasonText, warningNotification);
                      }
                      this.setState({
                        loading: false,
                        isLogined: false,
                        showResendOtp: false,
                        showTimer: false,
                      });
                    } else if (res.reasonCode === "SM-07") {
                      this.setState({
                        loading: false,
                        isLogined: true,
                        showResendOtp: false,
                        showTimer: false,
                      });
                    } else if (res.reasonCode === "SM-12") {
                      this.setState({
                        loading: false,
                        isLogined: false,
                        showResendOtp: false,
                        showTimer: false,
                      });
                      error(
                        "You have made 10 unsuccessful attempts to Login to ExpertMFD, Your account has been locked. Please contact expertmfd@ciel.co.in or 9922953305 for login support",
                        errorNotification
                      );
                    } else if (res.reasonCode === "SM-13") {
                      this.setState({
                        loading: false,
                        isLogined: false,
                        showResendOtp: false,
                        showTimer: false,
                      });
                      warning(
                        "Your Account has been Suspended. Please contact expertmfd@ciel.co.in or 9922953305 for login support",
                        warningNotification
                      );
                    } else if (res.reasonCode === "SM-04") {
                      this.setState({
                        loading: false,
                        isLogined: false,
                        showResendOtp: false,
                        showTimer: false,
                      });
                      warning(
                        "Your Account has been Suspended. Please contact expertmfd@ciel.co.in or 9922953305 for login support",
                        warningNotification
                      );
                    } else {
                      this.setState({
                        loading: false,
                        isLogined: false,
                        showResendOtp: false,
                        showTimer: false,
                      });
                      warning(res.reasonText, warningNotification);
                    }
                  }
                }
              );
            } else {
              // payResponse.reasonCode === "01" Don't allow user to login Payment not done
              this.setState({ paymentLink: true });
              error("Please make payment first", errorNotification);
            }
          }
        );
      } else if (
        this.state.userLoginName1 !== "" &&
        this.state.userPassword1 !== ""
      ) {
        LoginService.paymentLoginCheck(
          "emailId=" + this.state.userLoginName1,
          (payResponse) => {
            if (payResponse.reasonCode === "02") {
              this.setState({ loading: true });
              let regExp = /[a-zA-Z]/g;
              if (this.state.userLoginName1 !== "") {
                let testString = this.state.userLoginName1;
                if (regExp.test(testString)) {
                  LoginService.updateFlag(
                    "emailID=" + this.state.userLoginName1,
                    (response) => { }
                  );
                }
              }
              setTimeout(() => {
                this.setState({ loading: true });
                this.setState({ loading1: "block" });
                const LoginDetails = {
                  userLoginName: this.state.userLoginName1.trim(),
                  emailId: this.state.userLoginName1.trim(),
                  userPassword: this.state.userPassword1.trim(),
                };
                console.log(LoginDetails);
                var logincon = [];
                this.setState({ loading: true });
                LoginService.SendUser(
                  LoginDetails,
                  this.state.isMobile,
                  (res) => {
                    if (res.status === "success") {
                      logincon = res;

                      this.setState({ credentials: logincon });
                      localStorage.setItem(
                        "eMail",
                        logincon.responseObject.emailId
                      );
                      localStorage.setItem(
                        "userid-Token",
                        logincon.responseObject.partyId
                      );
                      localStorage.setItem(
                        "userid",
                        logincon.responseObject.userId
                      );
                      localStorage.setItem(
                        "ciel-userid-Token",
                        logincon.responseObject.partyId
                      );

                      localStorage.setItem(
                        "roleId",
                        logincon.responseObject.role[0].roleId
                      );
                      localStorage.setItem(
                        "ischangepass",
                        logincon.responseObject
                          .forcePasswordChangeOnNextLoginFlag
                      );
                      localStorage.setItem(
                        "roleName",
                        logincon.responseObject.role[0].roleName
                      );
                      localStorage.setItem("checkedtab", false);
                      localStorage.setItem("TokenName", "jti");

                      this.setState((state) => {
                        let oldstate = Object.assign({}, state);
                        localStorage.setItem(
                          "login",
                          JSON.stringify(oldstate.credentials.responseObject)
                        );
                      });
                      LoginService.TokenUser((res) => {
                        this.setState((state) => {
                          let oldstate = Object.assign({}, state);
                          oldstate.credentials.responseObject.token =
                            res.responseObject;
                          this.state = oldstate;
                          localStorage.setItem("jti-token", res.responseObject);
                          localStorage.setItem(
                            "login",
                            JSON.stringify(oldstate.credentials.responseObject)
                          );
                        });
                      });
                      localStorage.setItem("TokenName", "jwt");

                      LoginService.TokenUser((res) => {
                        localStorage.setItem("jwt-token", res.responseObject);
                        this.setState({ loading: false });
                        if (
                          this.state.credentials.responseObject[
                          "forcePasswordChangeOnNextLoginFlag"
                          ] === "1"
                        ) {
                          localStorage.setItem("reload", "true");
                          this.props.history.push("/changepassword", {
                            forcePasswordChangeOnNextLoginFlag:
                              this.state.credentials.responseObject[
                              "forcePasswordChangeOnNextLoginFlag"
                              ],
                          });
                        } else {
                          if (
                            this.state.credentials.responseObject.role[0]
                              .roleId === 5
                          ) {
                            $(".modal-backdrop").hide();
                            this.props.history.push("/learnerDashboard");
                            localStorage.setItem("reload", "true");
                          } else if (
                            this.state.credentials.responseObject.role[0]
                              .roleId === 6
                          ) {
                            $(".modal-backdrop").hide();
                            const queryString = window.location.search;
                            const urlParams = new URLSearchParams(queryString);
                            let page = urlParams.get("page");
                            let UrllearnerPartyId =
                              urlParams.get("learnerPartyId");
                            localStorage.setItem("UrlPage", page);
                            localStorage.setItem(
                              "UrllearnerPartyId",
                              UrllearnerPartyId
                            );
                            if (page == "profile") {
                              this.props.history.push("/userprofile");
                            } else {
                              this.props.history.push("/dashboard");
                            }
                            localStorage.setItem("reload", "true");
                          } else if (
                            this.state.credentials.responseObject.role[0]
                              .roleId === 7
                          ) {
                            $(".modal-backdrop").hide();
                            this.props.history.push("/dashboard");
                            localStorage.setItem("reload", "true");
                          } else if (
                            this.state.credentials.responseObject.role[0]
                              .roleId === 2
                          ) {
                            $(".modal-backdrop").hide();
                            this.props.history.push("/admin-dashboard");
                            localStorage.setItem("reload", "true");
                          }
                        }
                      });
                    } else {
                      this.setState({ loading: false });

                      if (res.reasonCode === "DM_02") {
                        let otpcounter = parseInt(this.state.OTPTokenCount) + 1;
                        this.setState({ OTPTokenCount: otpcounter });
                        if (this.state.OTPTokenCount >= 4) {
                          this.setState({
                            loading: false,
                            isLogined: false,
                            showResendOtp: true,
                            showTimer: false,
                          });
                          warning(
                            "You have made 10 unsuccessful attempts to Login to ExpertMFD. Click on Resend OTP",
                            warningNotification
                          );
                        } else {
                          warning(
                            res.reasonText +
                            " count " +
                            this.state.OTPTokenCount,
                            warningNotification
                          );
                        }
                      } else if (res.reasonCode === "SM_11") {
                        warning(res.reasonText, {
                          ...warningNotification,
                          container: "top-center",
                        });

                        window.location.href = "/";
                      } else if (res.reasonCode === "SM-03") {
                        if (res.reasonText === "InActive user") {
                          warning(
                            "Access unauthorised, Please contact expertmfd@ciel.co.in or 9922953305 for login support",
                            warningNotification
                          );
                        } else {
                          warning(res.reasonText, warningNotification);
                        }
                        this.setState({
                          loading: false,
                          isLogined: false,
                          showResendOtp: false,
                          showTimer: false,
                        });
                      } else if (res.reasonCode === "SM-07") {
                        this.setState({
                          loading: false,
                          isLogined: true,
                          showResendOtp: false,
                          showTimer: false,
                        });
                      } else if (res.reasonCode === "SM-12") {
                        this.setState({
                          loading: false,
                          isLogined: false,
                          showResendOtp: false,
                          showTimer: false,
                        });
                        error(
                          "You have made 10 unsuccessful attempts to Login to ExpertMFD, Your account has been locked. Please contact expertmfd@ciel.co.in or 9922953305 for login support",
                          errorNotification
                        );
                      } else if (res.reasonCode === "SM-13") {
                        this.setState({
                          loading: false,
                          isLogined: false,
                          showResendOtp: false,
                          showTimer: false,
                        });
                        warning(
                          "Your Account has been Suspended. Please contact expertmfd@ciel.co.in or 9922953305 for login support",
                          warningNotification
                        );
                      } else if (res.reasonCode === "SM-04") {
                        this.setState({
                          loading: false,
                          isLogined: false,
                          showResendOtp: false,
                          showTimer: false,
                        });
                        warning(
                          "Your Account has been Suspended. Please contact expertmfd@ciel.co.in or 9922953305 for login support",
                          warningNotification
                        );
                      } else {
                        this.setState({
                          loading: false,
                          isLogined: false,
                          showResendOtp: false,
                          showTimer: false,
                        });
                        warning(res.reasonText, warningNotification);
                      }
                    }
                  }
                );
              }, 5000);
            } else {
              // payResponse.reasonCode === "01" Don't allow user to login Payment not done
              this.setState({ paymentLink: true });
              error("Please make payment first", errorNotification);
            }
          }
        );
      } else {
        error("You must specify a valid credentials", errorNotification);
      }
    } else {
      error(
        "System is unavailable on 2nd ( Monday ) March 2020 for upgradation starting from 7.00 PM to 10.00 PM. Inconvenience is regretted.Reach out to ExpertMFD Support Team for any clarifications.",
        errorNotification
      );
    }
  };

  onCloseModal = () => {
    this.setState({ isLogined: false });
  };

  handleYes = () => {
    let isMobile = this.state.isMobile;
    const data = {
      userName: this.state.userLoginName, // 'PramilaPatil',
    };
    localStorage.setItem("TokenName", "jwt");
    LoginService.TokenUser(
      (res) => {
        if (res.status === "success") {
          localStorage.setItem("jwt-token", res.responseObject);

          LoginService.LogoutUser(
            data,
            isMobile,
            (res) => {
              if (res.status === "success") {
                let subscribe = localStorage.getItem("nwesLetterEmailId");
                localStorage.clear();
                localStorage.setItem("windowloggedout", true);
                window.location.href = "/";
              } else {
                warning(res.reasonText, warningNotification);
              }
            },
            (error) => {
              error(error, errorNotification);
            }
          );
        }
      },
      (error) => {
        error(error, errorNotification);
      }
    );
  };

  render() {
    return (
      <React.Fragment>
        <div
          className="modal fade"
          id="amfi-Reg"
          tabindex="-1"
          role="dialog"
          aria-labelledby="modalLabel"
          data-keyboard="true"
          aria-hidden="true"

        >
          <div className="modal-dialog modal-lg" role="document">
            <div
              style={{
                boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                position: "absolute",
              }}
            >
              <section className="registration">
                <div className="modal-body" style={{ background: 'white', borderRadius: '5px' }}>
                  <div className="row">
                    <button
                      type="button"
                      class="close"
                      aria-label="Close"
                      onClick={
                        e => {
                          window.$('#amfi-Reg').modal('hide')
                        }
                      }
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                    <div className="col-md-4 col-sm-5 col-xs-12">
                      <div className="modal-head">
                        <h3>ExpertMFD Login</h3>
                        <p>
                          First step towards realising your
                          professional/entrepreneurial dreams in indian BFSI
                          sector
                        </p>
                      </div>
                      <img src={featmodal} className="img-responsive" />
                    </div>
                    <div className="col-md-8 col-sm-7 col-xs-12">
                      <div className="form-group">
                        <div className="row">
                          <div className="login">
                            <form>
                              <div className="col-md-6 col-sm-12 col-xs-12">
                                <label>
                                  ENTER EMAIL ID{" "}
                                  <span className="validation"> *</span>
                                </label>
                                <input
                                  type="text"
                                  name="userLoginName"
                                  className="form-control"
                                  placeholder="Enter Email Id"
                                  onChange={this.handleuserLoginName1}
                                  value={this.state.userLoginName1}
                                  onKeyDown={this._handleKeyDown}
                                  onBlur={this.handleBlur1}
                                  autoComplete="off"
                                  disabled={this.state.isMaintainenceActivated}
                                />
                                {this.state.warning ? (
                                  <span style={{ color: "white" }}>
                                    Caps Lock On!
                                  </span>
                                ) : (
                                    ""
                                  )}
                                <span class="file-size-limit">
                                  Login with your Email ID & Current Password{" "}
                                </span>
                              </div>

                              <div className="col-md-6 col-sm-12 col-xs-12">
                                <label>
                                  ENTER PASSWORD{" "}
                                  <span className="validation"> *</span>
                                </label>
                                <input
                                  type="password"
                                  name="password"
                                  id="password"
                                  className="form-control"
                                  maxlength={"100"}
                                  placeholder="Enter Password"
                                  onChange={this.handleUserPassword1}
                                  value={this.state.userPassword1}
                                  onKeyDown={this._handleKeyDown}
                                  autoComplete="off"
                                  disabled={this.state.isMaintainenceActivated}
                                />
                              </div>

                              {!this.state.isMaintainenceActivated ? (
                                <span>
                                  <div className="otp-resend">
                                    <a
                                      onClick={this.forgotpassword}
                                      className="sidebartogglexyz otp-resend"
                                    >
                                      Forgot Password?
                                    </a>
                                  </div>
                                </span>
                              ) : (
                                  ""
                                )}

                              <div className="col-md-12 col-sm-12 col-xs-12">
                                <h3 className="text-center">
                                  ---------------------OR---------------------
                                </h3>
                              </div>

                              <div className="col-md-6 col-sm-12 col-xs-12">
                                <label>
                                  ENTER MOBILE NO.{" "}
                                  <span className="validation"> *</span>
                                </label>
                                <input
                                  type="text"
                                  name="userLoginName"
                                  className="form-control"
                                  placeholder="Enter Mobile No."
                                  maxlength="10"
                                  pattern="[7-9]{10}"
                                  onChange={this.handleuserLoginName}
                                  value={this.state.userLoginName}
                                  onKeyDown={this._handleKeyDown}
                                  onBlur={this.handleBlur}
                                  autoComplete="off"
                                  disabled={this.state.isMaintainenceActivated}
                                />
                                {this.state.warning ? (
                                  <span style={{ color: "white" }}>
                                    Caps Lock On!
                                  </span>
                                ) : (
                                    ""
                                  )}
                                <span class="file-size-limit">
                                  Login with an OTP with your Mobile Number
                                </span>
                              </div>
                              {this.state.showGenerateOtp &&
                                !this.state.isOTPGenerated && (
                                  <div className="col-md-6 col-sm-6 col-xs-12">
                                    <button
                                      type="button"
                                      className="btn-1 mt-40"
                                      onClick={this.startTimer}
                                      value="Generate OTP"
                                    >
                                      Generate OTP{" "}
                                    </button>
                                  </div>
                                )}
                              {this.state.showOTPBox && (
                                <div className="col-md-6 col-sm-12 col-xs-12">
                                  <label>
                                    ENTER OTP{" "}
                                    <span className="validation"> *</span>
                                  </label>
                                  <input
                                    type="text"
                                    name="password"
                                    id="password"
                                    className="form-control"
                                    maxlength={"4"}
                                    placeholder="Enter OTP"
                                    onChange={this.handleUserPassword}
                                    value={this.state.userPassword}
                                    onKeyDown={this._handleKeyDown}
                                    autoComplete="off"
                                    disabled={
                                      this.state.isMaintainenceActivated
                                    }
                                  />
                                  {this.state.showResendOtp ? (
                                    <React.Fragment>
                                      <div className="otp-resend">
                                        <a
                                          className="otp-resend"
                                          onClick={this.startTimer}
                                        >
                                          {" "}
                                          Resend OTP
                                        </a>
                                      </div>
                                    </React.Fragment>
                                  ) : this.state.showTimer ? (
                                    <div>
                                      <h5 className="timer1">
                                        {this.state.time.m} :{" "}
                                        {this.state.time.s} Time Left
                                      </h5>
                                    </div>
                                  ) : (
                                        ""
                                      )}
                                </div>
                              )}

                              <div className="col-md-12 col-sm-12 col-xs-12">
                                {!this.state.isMaintainenceActivated ? (
                                  this.state.enableSubmit == true ? (
                                    <button
                                      type="submit"
                                      onClick={this.loginHandler}
                                      className="btn-4 mt-30 pull-right"
                                    >
                                      Submit
                                    </button>
                                  ) : (
                                      ""
                                    )
                                ) : (
                                    ""
                                  )}
                              </div>
                              <div className="col-md-12 col-sm-12 col-xs-12"></div>
                              <div className="col-md-12 col-sm-12 col-xs-12">
                                {this.state.paymentLink ? (
                                  <span>
                                    <a
                                      onClick={this.makePayment}
                                      className="sidebartogglexyz pull-right"
                                      style={{ marginTop: "20px", paddingRight: "10px", fontSize: "20px" }}
                                    >
                                      Make Payment
                                          </a>
                                  </span>
                                ) : (
                                    ""
                                  )}
                              </div>
                            </form>
                          </div>

                          <div
                            className="modal fade"
                            id="login"
                            tabindex="-1"
                            role="dialog"
                            aria-labelledby="modalLabel"
                            data-keyboard="true"
                            aria-hidden="true"
                          >
                            <div
                              className="modal-dialog modal-sm"
                              role="document"
                            >
                              <div className="modal-content login-modal">
                                <div className="modal-header">
                                  <button
                                    type="button"
                                    className="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                  >
                                    <span aria-hidden="true">&times;</span>
                                  </button>
                                  <h4 className="modal-title">
                                    Login ExpertMFD
                                  </h4>
                                </div>
                                <div className="modal-body">
                                  <div className="row">
                                    <div className="col-md-12">
                                      <input
                                        type="text"
                                        name="userLoginName"
                                        className="form-control"
                                        placeholder="Username/Mobile No."
                                        onChange={this.handleuserLoginName}
                                        value={this.state.userLoginName}
                                        onKeyDown={this._handleKeyDown}
                                        autocomplete="nope"
                                        disabled={
                                          this.state.isMaintainenceActivated
                                        }
                                      />
                                      {this.state.showGenerateOtp &&
                                        !this.state.isOTPGenerated && (
                                          <div className="col-md-6 col-sm-6 col-xs-12">
                                            <button
                                              type="button"
                                              className="btn-1 mt-40"
                                              onClick={this.startTimer}
                                              value="Generate OTP"
                                            >
                                              Generate OTP{" "}
                                            </button>
                                          </div>
                                        )}
                                      {this.state.showOTPBox && (
                                        <input
                                          type="password"
                                          name="password"
                                          className="form-control"
                                          placeholder={
                                            !this.state.isMobile
                                              ? "Password"
                                              : "OTP"
                                          }
                                          onChange={this.handleUserPassword}
                                          value={this.state.userPassword}
                                          onKeyDown={this._handleKeyDown}
                                          autocomplete="nope"
                                          disabled={
                                            this.state.isMaintainenceActivated
                                          }
                                        />
                                      )}
                                      {this.state.showResendOtp ? (
                                        <React.Fragment>
                                          <div className="otp-resend">
                                            <a onClick={this.startTimer}>
                                              {" "}
                                              Resend OTP
                                            </a>
                                          </div>
                                        </React.Fragment>
                                      ) : this.state.showTimer ? (
                                        <div>
                                          <h5 className="timer2">
                                            {this.state.time.m} :{" "}
                                            {this.state.time.s} Time Left
                                          </h5>
                                        </div>
                                      ) : !this.state
                                        .isMaintainenceActivated ? (
                                              <span>
                                                <a
                                                  onClick={this.forgotpassword}
                                                  className="sidebartogglexyz"
                                                >
                                                  Forgot Password?
                                          </a>
                                              </span>
                                            ) : (
                                              ""
                                            )}
                                      {!this.state.isMaintainenceActivated ? (
                                        <button className="btn-1 mt-30">
                                          {" "}
                                          <a
                                            href="#"
                                            onClick={this.loginHandler}
                                          >
                                            {" "}
                                            Login{" "}
                                            <i
                                              className="fa fa-sign-in"
                                              aria-hidden="true"
                                            ></i>{" "}
                                          </a>{" "}
                                        </button>
                                      ) : (
                                          ""
                                        )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          {this.state.isLogined ? (
                            <Modal
                              open={this.state.isLogined}
                              onClose={this.onCloseModal}
                              center
                            >
                              <div
                                className=""
                                id=""
                                tabindex="-1"
                                role="dialog"
                                aria-labelledby="modalLabel"
                                data-keyboard="true"
                                aria-hidden="true"
                              >
                                <div
                                  className="modal-dialog modal-md"
                                  role="document"
                                >
                                  <div className="modal-content">
                                    <div className="modal-header">
                                      <div className="scr-board">
                                        <h2>ExpertMFD Login</h2>
                                        <div className="bottom-line"></div>
                                      </div>
                                    </div>
                                    <div className="modal-body">
                                      <div className="">
                                        <div className="">
                                          <div className="">
                                            <div className="reset">
                                              <h3 className="word-break">
                                                You have already been logged in
                                                other machine. Do you want to
                                                Logout?
                                              </h3>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="modal-footer">
                                      <button
                                        type="button"
                                        className="btn-5 mt-40 mr-20"
                                        name="btnYes"
                                        onClick={this.handleYes}
                                      >
                                        Yes
                                      </button>
                                      <button
                                        type="button"
                                        className="btn-5 mt-40"
                                        name="btnNo"
                                        onClick={this.onCloseModal}
                                      >
                                        No
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Modal>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>

      </React.Fragment>
    );
  }
}

export default withRouter(amfifinxheader);
