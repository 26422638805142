import React from 'react';
import Slider from 'react-slick';
import quoteImg from '../../../assets/images/Testimonials-icon.png'
import testimonImg from '../../../assets/images/testimonial-img-1.png'
import './TestiSlider.css';

function TestiSlider() {

    const settings = {
        dots: true,
        arrows: true,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        SlidesToScroll: 1,
        responsive: [
            {
                breakpoint: 750,
                settings: {
                    autoplay: true,
                    autoplaySpeed: 2000,
                }
            }
        ]
    };

  return (
    <div className="ap-testimonial-slider col-100 floatLft">
        <Slider {...settings}>
            <div className="ap-testimonItem col-100 floatLft">
                <div className="ap-testiMon-inner col-100 floatLft flexDisplay justifySpace alignCenter">
                    <div className="ap-testimon-text floatLft">
                        <h2 className="ap-testmonTitle col-100 floatLft"><span><img src={quoteImg} alt="Quote Image 1" /></span> Testimonials <span><img src={quoteImg} alt="Quote Image 2" /></span> </h2>
                        <div className="ap-testimon-para col-100 floatLft">
                            <h2 className="ap-testimon-name col-100 floatLft">Abhijoy Srinivasan</h2>
                            <p className='ap-testimonText col-100 floatLft'>
                                I thank CIEL team for handholding me for the entire with an excellent learning experience. It is a one stop shop for MFD <br />
                                I recommend this business opportunity to my friends and family  who aspire to be MFD or financial independent.
                            </p>
                        </div>
                    </div>
                    <div className="ap-testimon-image floatRgt">
                        <img src={testimonImg} alt="" />
                    </div>
                </div>
            </div>
            {/* <div className="ap-testimonItem col-100 floatLft">
                <div className="ap-testiMon-inner col-100 floatLft flexDisplay justfySpace alignCenter">
                    <div className="ap-testimon-text floatLft">
                        <h2 className="ap-testmonTitle col-100 floatLft"><span><img src={quoteImg} alt="Quote Image 1" /></span> Testimonials <span><img src={quoteImg} alt="Quote Image 2" /></span> </h2>
                        <div className="ap-testimon-para col-100 floatLft">
                            <h2 className="ap-testimon-name col-100 floatLft">Abhijoy Srinivasan</h2>
                            <p className='ap-testimonText col-100 floatLft'>
                                I thank CIEL team for handholding me for the entire with an excellent learning experience. It is a one stop shop for MFD <br />
                                I recommend this business opportunity to my friends and family  who aspire to be MFD or financial independent.
                            </p>
                        </div>
                    </div>
                    <div className="ap-testimon-image floatRgt">
                        <img src={testimonImg} alt="" />
                    </div>
                </div>
            </div> */}
            {/* <div className="ap-testimonItem col-100 floatLft">
                <div className="ap-testiMon-inner col-100 floatLft flexDisplay justifySpace alignCenter">
                    <div className="ap-testimon-text floatLft">
                        <h2 className="ap-testmonTitle col-100 floatLft"><span><img src={quoteImg} alt="Quote Image 1" /></span> Testimonials <span><img src={quoteImg} alt="Quote Image 2" /></span> </h2>
                        <div className="ap-testimon-para col-100 floatLft">
                            <h2 className="ap-testimon-name col-100 floatLft">Abhijoy Srinivasan</h2>
                            <p className='ap-testimonText col-100 floatLft'>
                                I thank CIEL team for handholding me for the entire with an excellent learning experience. It is a one stop shop for MFD <br />
                                I recommend this business opportunity to my friends and family  who aspire to be MFD or financial independent.
                            </p>
                        </div>
                    </div>
                    <div className="ap-testimon-image floatRgt">
                        <img src={testimonImg} alt="" />
                    </div>
                </div>
            </div> */}
            {/* <div className="ap-testimonItem col-100 floatLft">
                <div className="ap-testiMon-inner col-100 floatLft flexDisplay justfySpace alignCenter">
                    <div className="ap-testimon-text floatLft">
                        <h2 className="ap-testmonTitle col-100 floatLft"><span><img src={quoteImg} alt="Quote Image 1" /></span> Testimonials <span><img src={quoteImg} alt="Quote Image 2" /></span> </h2>
                        <div className="ap-testimon-para col-100 floatLft">
                            <h2 className="ap-testimon-name col-100 floatLft">Abhijoy Srinivasan</h2>
                            <p className='ap-testimonText col-100 floatLft'>
                                I thank CIEL team for handholding me for the entire with an excellent learning experience. It is a one stop shop for MFD <br />
                                I recommend this business opportunity to my friends and family  who aspire to be MFD or financial independent.
                            </p>
                        </div>
                    </div>
                    <div className="ap-testimon-image floatRgt">
                        <img src={testimonImg} alt="" />
                    </div>
                </div>
            </div> */}
        </Slider>
    </div>
  )
}

export default TestiSlider