import RestDataSource from "./restdatasource";
import { Global_var } from "../global/global_var";

export const PaymentHistorySearchService = {
  fetchPaymentSearchResult
};

function fetchPaymentSearchResult(data, fn, fnError) {
  var url = Global_var.BASEURL + Global_var.URL_LEARNER_PAYMENT_HISTORY;
  return new RestDataSource(url, fnError).GetOneByParam(data, res => {
    if (res != null) {
      if (res.headers["jtitoken"] != null) {
        localStorage.setItem("jti-token", res.headers.jtitoken);
      }
      fn(res.data);
    }
  });
}
