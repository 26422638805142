import React from "react";
// import updated 28-09-2022

import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";

import Amfifinxheader from "../../containers/finxheader/amfifinxheader";
import Header from "../../components/amfi/header/Header";
import benefitsImg1 from "../../assets/images/benefits-img1.png";
import benefitsImg2 from "../../assets/images/benefits-img2.png";
import benefitsImg3 from "../../assets/images/benefits-img3.png";
import benefitsImg4 from "../../assets/images/benefits-img4.png";
import benefitsImg5 from "../../assets/images/benefits-img5.png";
import benefitsImg6 from "../../assets/images/benefits-img6.png";
import startedImg1 from "../../assets/images/started-ico1.png";
import startedImg2 from "../../assets/images/started-ico2.png";
import startedImg3 from "../../assets/images/started-ico3.png";
import startedImg4 from "../../assets/images/started-ico4.png";
import arrowLeft from "../../assets/images/arro-r.png";
import arrowRight from "../../assets/images/arro-l-png.png";
import curveArrow from "../../assets/images/arro-ro.png";
import supportIco1 from "../../assets/images/support-ico-1.png";
import supportIco2 from "../../assets/images/support-ico-2.png";
import supportIco3 from "../../assets/images/support-ico-3.png";
import whatsappIco from "../../assets/images/whatsapp-icon.png";
import OrientationPDF from "../../assets/pdf/OrientationPDF.pdf";
import TC from "../../assets/pdf/T-C_ExpertMFD.pdf";
import whyMFD from "../../../src/assets/pdf/MFD shuru.pdf";
import $ from "jquery";
import * as Yup from "yup";
import { LoginService } from "../../services/login.service";
import { Formik, Field, Form, ErrorMessage } from "formik";
import {
  success,
  successNotification,
  error,
  errorNotification,
  warning,
  warningNotification,
} from "../../../src/components/notification/notifications";
import { GeneralEnquiryService } from "../../services/generalenquiryService";
import * as moment from "moment";
import { LookUpService } from "../../services/lookupService";
import { MobileValidationService } from "../../services/mobile.validation.service";
import "./Homepage.css";
import Footer from "../../components/amfi/footer/Footer";
import TestiSlider from "../../components/amfi/testimon-slider/TestiSlider";
import BannerSlider from "../../components/amfi/banner-slider/BannerSlider";
import AccordionSect from "../../components/amfi/accordion/Accordion";
import "../../assets/css/init.css";

class amfiPage extends React.Component {
  constructor(props) {
    var currentDate = new Date();
    super(props);
    this.state = {
      open: false,
      showModal: false,
      isMaintainenceActivated: false,
      display: "none",
      mobileNumber: "",
      fullName: "",
      message: "",
      countryCode: "91",
      OTP: "",
      firstName: "",
      lastName: "",
      emailId: "",
      today: moment(currentDate).format("YYYY-MM-DD"),
      popupVisibility: false,
      productId: "",
      checkout: [],
      showGenerateOtp: false,
      showResendOtp: false,
      MobileVerified: false,
      isMobileUnique: false,
      showOTPBox: false,
      showTimer: false,
      isOTPGenerated: false,
      loading: false,
      flag: "false",
      user_obj: [],
      OTPTokenCount: 0,
      time: {},
      seconds: 31,
      disableopt: false,
      btnDisabled: false,
      countotp: 0,
      isValidOtp: false,
      lookingForList: [],
      style : {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 450,
        bgcolor: "background.paper",
        border: "2px solid #000",
        boxShadow: 24,
        p: 4,
        textAlign: "center",
      }
    };
    this.timer = 0;
    this.startTimer = this.startTimer.bind(this);
    this.countDown = this.countDown.bind(this);
  }
  handleOpen = () => {
    this.setState({ open: true });
  };
  handleClose1 = () =>{
      this.setState({ open: false });
  }

  handleClick = () => {
    this.setState({ showModal: true }, () => {});
  };
  secondsToTime = (secs) => {
    let hours = Math.floor(secs / (60 * 60));

    let divisor_for_minutes = secs % (60 * 60);
    let minutes = Math.floor(divisor_for_minutes / 60);

    let divisor_for_seconds = divisor_for_minutes % 60;
    let seconds = Math.ceil(divisor_for_seconds);

    let obj = {
      h: hours,
      m: minutes,
      s: seconds,
    };
    return obj;
  };
  handleValidateOtp = (e) => {
    this.setState({
      countotp: this.state.countotp + 1,
    });
    let mobile = this.state.mobileNumber;
    let countrycd = this.state.countryCode;
    let validateOtp = e.target.value;

    if (validateOtp !== "") {
      MobileValidationService.MobileOTPValidation(
        mobile,
        countrycd,
        validateOtp,
        (res) => {
          if (res.status === "success") {
            this.setState({ time: {}, seconds: 0, showTimer: false });
            this.setState({ isValidOtp: true });
            this.setState({
              showGenerateOtp: false,
              showResendOtp: false,
              MobileVerified: true,
              showOTPBox: true,
            });

            if (this.state.isRegistered) {
              let Trd = moment(this.state.user_obj.dob, "DD/MM/YYYY");
              success(
                "You are already registered with ExpertMFD. To start BFSI journey, please login to ExpertMFD.",
                warningNotification
              );

              $("#amfi-Reg").modal("hide");
            }
            if (this.state.educationQualification === "under Graduate") {
              localStorage.setItem("flag", "true");
            } else {
              localStorage.setItem("flag", "false");
            }
          } else {
            this.setState({ isValidOtp: false });
            this.setState({ loading: false });

            if (res.reasonCode === "DM_02") {
              let otpcounter = parseInt(this.state.OTPTokenCount) + 1;
              this.setState({ OTPTokenCount: otpcounter });
              warning(
                res.reasonText + " count " + this.state.OTPTokenCount,
                warningNotification
              );
            }
          }
          return true;
        },
        (error) => {}
      );
    } else {
      if (this.state.countotp == 0) {
        warning("Please Enter OTP", warningNotification);
      }
    }
  };
  isNumber(e) {
    const re = /^[0-9\b]+$/;
    if (re.test(e)) {
      return true;
    }
  }
  handleClose = () => {
    
    if (this.state.display === "none") {
      this.setState({
        display: "block",
      });
    } else {
      this.setState({
        display: "none",
      });
      window.location.reload();
    }
  };
  startTimer() {
    this.handleGenerateOtp();
    this.setState({ time: {}, seconds: 46, disableopt: true });
    if (this.timer == 0 && this.state.seconds == 46) {
      this.timer = setInterval(this.countDown, 1000);
    } else {
      clearInterval(this.timer);
      this.timer = setInterval(this.countDown, 1000);
    }
  }
  handleGenerateOtp = () => {
    let mobile = this.state.mobileNumber;

    if (mobile.toString().length === 10) {
      mobile = "mobileNo=" + mobile + "&productID=0";
      MobileValidationService.MobileValidation(
        mobile,
        (res) => {
          if (res.status === "success") {
            this.GenerateOTP();
            if (res.reasonCode === "FC-01") {
            }
          }
        },
        (error) => {}
      );
    }
  };

  countDown() {
    // Remove one second, set state so a re-render happens.
    let seconds = this.state.seconds - 1 <= 0 ? 0 : this.state.seconds - 1;
    this.setState({ time: this.secondsToTime(seconds), seconds: seconds });

    // Check if we're at zero.
    if (seconds == 0) {
      clearInterval(this.timer);
    }
    if (seconds === 0) {
      this.setState({
        showResendOtp: true,
      });
    } else {
      this.setState({
        showResendOtp: false,
      });
    }
  }

  GenerateOTP = () => {
    LookUpService.getLookingFor("codeTypeId=100", (res) => {
      this.setState({
        lookingForList: res.responseListObject,
      });
    });
    LookUpService.getLookingFor("codeTypeId=107", (res) => {
      this.setState({
        currentProfession: res.responseListObject,
        // countryListForPer: res.responseListObject
      });
    });

    this.setState({ isMobileUnique: true, isOTPGenerated: true });

    if (this.state.isMobileUnique) {
      MobileValidationService.MobileOTP(
        this.state.mobileNumber,
        this.state.countryCode,
        (res) => {
          if (res.status === "success") {
            success("You will receive otp in 45 secs.", successNotification);
          }
          return true;
        }
      );
      this.setState({
        showGenerateOtp: false,
        showResendOtp: false,
        showOTPBox: true,
        showTimer: true,
      });
    }
  };
  componentDidMount() {
    var self = this;
    $(document).mouseup(function (e) {
      var container = $("#getintouch");
      if (!container.is(e.target) && container.has(e.target).length === 0) {
        self.setState({
          display: "none",
        });
      }
    });
    $(document).on("keydown", function (e) {
      if (e.keyCode === 27) {
        // ESC
        self.setState({
          display: "none",
        });
      }
    });

    $(".accordion__button").on("click", function () {
      $(".accordion__button").removeClass("active");
      $(this).addClass("active");
    });
  }
  state={
    openModal : false
}

onClickButton = e =>{
    e.preventDefault()
    this.setState({openModal : true})
}

onCloseModal = ()=>{
    this.setState({openModal : false})
}
  

  render() {
    return (
      <React.Fragment>
        <Amfifinxheader showModal={this.state.showModal} />

        <div>
          <Header />
          {/* <a href="#" className="ap-whatsApp-click">
            <img src={whatsappIco} alt="WhatsApp Icon" />
          </a> */}
          <a
            target="_blank"
            href={whyMFD}
            className="ap-commonFloatbtn ap-firstFloat"
          >
            Why MFD Karein Shuru?
          </a>
          <a
            
            onClick={
              this.onClickButton
            }
            className="ap-commonFloatbtn ap-secondFloat"
          >
            Get In Touch
          </a>
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={this.state.openModal}
            onClose={this.onCloseModal}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <Fade in={this.state.openModal}>
              <Box sx={this.state.style}>
                <Typography id="transition-modal-description" sx={{ mt: 2 }}>
                  Leave us a message and we will reach out to you.
                </Typography>
              </Box>
            </Fade>
          </Modal>
          <div className="ap-topStrip bg-primary textCenter col-100 floatLft">
            <div className="wrapper">
              <h2 className="ap-stripHead col-100 floatLft">
                Mutual Fund Industry Initiative | Free Training
              </h2>
            </div>
          </div>
          <section className="ap-banner-main col-100 floatLft flexDisplay justifyCenter">
            <div className="wrapper">
              <div className="ap-bannerCnt-wrp col-100 floatLft flexDisplay justifySpace alignStart">
                <div className="ap-bannerFixed-txt floatLft">
                  <h2 className="ap-common-head col-100 floatLft textLeft primary-dark">
                    Welcome,{" "}
                    <span className="primary-green">
                      future Mutual <br /> Fund Distributors
                    </span>
                  </h2>
                  <p className="ap-bannerPara col-100 floatLft primary-dark">
                    Toh, aapki journey karein shuru?
                  </p>
                  <div className="ap-payFeesBtn-wrp col-100 floatLft textLeft">
                            <a href="https://rzp.io/l/mfd-karein-shuru" target="_blank" className='inlineBlk bg-primary'>Pay NISM Exam Fees</a>
                        </div>
                </div>
                <div className="ap-banner-sliderWrp floatRgt">
                  <BannerSlider />
                </div>
              </div>
            </div>
          </section>
          <div className="ap-topStrip ap-mt-none bg-primary textCenter col-100 floatLft">
            <div className="wrapper">
              <h2 className="ap-stripHead col-100 floatLft">
                Training for NISM VA Exam, Handholding for Obtaining ARN,
                Enabling Empanelment with all AMCs and Operational Support
              </h2>
            </div>
          </div>
          <section
            className="ap-benefits col-100 floatLft flexDisplay justifyCenter"
            id="benefits"
          >
            <div className="wrapper ">
              <br />
              <br />
              <br />
              <br />
              <br />
              <h3 className="ap-benefitsSmall col-100 floatLft textCenter primary-dark">
                Benefits of Becoming
              </h3>
              <h2 className="ap-benefintsBig col-100 floatLft textCenter primary-green">
                Mutual Fund Distributer
              </h2>
              <ul className="col-100 floatLft flexDisplay justifySpace alignStart">
                <li className="flexDisplay flexColumn alignCenter textCenter">
                  <div className="ap-benefitsIco bg-primary flexDisplay justifyCenter alignCenter">
                    <img src={benefitsImg1} alt="Benefits Icon" />
                  </div>
                  <h2 className="ap-benefitName primary-dark col-100 floatLft">
                    Be your own boss
                  </h2>
                </li>
                <li className="flexDisplay flexColumn alignCenter textCenter">
                  <div className="ap-benefitsIco bg-primary flexDisplay justifyCenter alignCenter">
                    <img src={benefitsImg2} alt="Benefits Icon" />
                  </div>
                  <h2 className="ap-benefitName primary-dark col-100 floatLft">
                    Continuous lifelong earnings
                  </h2>
                </li>
                <li className="flexDisplay flexColumn alignCenter textCenter">
                  <div className="ap-benefitsIco bg-primary flexDisplay justifyCenter alignCenter">
                    <img src={benefitsImg3} alt="Benefits Icon" />
                  </div>
                  <h2 className="ap-benefitName primary-dark col-100 floatLft">
                    Flexibility to work anytime anywhere
                  </h2>
                </li>
                <li className="flexDisplay flexColumn alignCenter textCenter">
                  <div className="ap-benefitsIco bg-primary flexDisplay justifyCenter alignCenter">
                    <img src={benefitsImg4} alt="Benefits Icon" />
                  </div>
                  <h2 className="ap-benefitName primary-dark col-100 floatLft">
                    No capital requirement to start business
                  </h2>
                </li>
                <li className="flexDisplay flexColumn alignCenter textCenter">
                  <div className="ap-benefitsIco bg-primary flexDisplay justifyCenter alignCenter">
                    <img src={benefitsImg5} alt="Benefits Icon" />
                  </div>
                  <h2 className="ap-benefitName primary-dark col-100 floatLft">
                    Help others manage their finances
                  </h2>
                </li>
                <li className="flexDisplay flexColumn alignCenter textCenter">
                  <div className="ap-benefitsIco bg-primary flexDisplay justifyCenter alignCenter">
                    <img src={benefitsImg6} alt="Benefits Icon" />
                  </div>
                  <h2 className="ap-benefitName primary-dark col-100 floatLft">
                    Gain recognition for your efforts
                  </h2>
                </li>
              </ul>
            </div>
          </section>

          <section
            className="ap-aboutus col-100 floatLft flexDisplay justifyCenter"
            id="about"
          >
            <div className="wrapper">
              <br />
              <br />
              <br />
              <br />
              <br />
              <h2 className="ap-common-head col-100 floatLft textCenter primary-dark">
                About <span className="primary-green">Us</span>
              </h2>
              <p className="ap-aboutText col-100 floatLft textCenter primary-dark">
                MFD Karein Shuru is a pan-India initiative of Association of
                Mutual Funds of India (AMFI) to create <br /> Mutual Fund
                Distributors (MFDs) for the entire Mutual Fund industry. Mutual
                Fund industry ispoised to reach over ₹100 <br /> crore of Assets
                Under Management (AUM) by 2030, it is important that awide base
                of MFDs is created to ensure better <br /> access of
                well-regulated MF products to potential investors across the
                country. <br />
                <br />
                As a part of the initiative, AMFI is providing free training,
                handholding and end-to-end operational support for everyone who
                intends to start their own business as a mutual fund
                distributor.
                <br />
                <br />
                Centre for Investment Education and Learning Private Limited
                (CIEL) is an authorised training and execution partner of AMFI.
                <br />
                <br />
                CIEL (Centre for Investment Education and Learning Private
                Limited) is an authorised service provider for AMFI for this
                project. CIEL is one of the largest learning-solutions company
                in the Banking, Financial Services and Insurance (BFSI) sector,
                focused on competency-building, for over a decade.
              </p>
            </div>
          </section>
          <section
            className="ap-yellowBlock col-100 floatLft flexDisplay justifyCenter"
            id="process"
          >
            <div className="wrapper">
              <div className="ap-yellowInner col-100 floatLft">
                <div className="ap-startedDiv col-100 floatLft">
                  <br />
                  <br />
                  <br />
                  <h2 className="ap-common-head col-100 floatLft textCenter primary-dark">
                    How to get <span className="primary-green">started</span>
                  </h2>
                  <div className="ap-startedMain col-100 floatLft flexDisplay justifyCenter">
                    <div className="ap-startedWrp">
                      <ul className="flexDisplay justifySpace alignStart  flexWrap relative col-100 floatLft">
                        <li>
                          <div className="ap-startedIco bg-primary relative">
                            <img src={startedImg1} alt="Started Icon" />
                            <span className="ap-count absolute">01</span>
                          </div>
                          <h2 className="ap-benefitName primary-dark col-100 floatLft">
                            Pay ₹1500 towards <br /> NISM Examination Fee
                          </h2>
                          <div className="ap-lineImg absolute">
                            <img src={arrowLeft} alt="Line Image" />
                          </div>
                        </li>
                        <li>
                          <div className="ap-startedIco bg-primary relative">
                            <img src={startedImg2} alt="Started Icon" />
                            <span className="ap-count absolute">02</span>
                          </div>
                          <h2 className="ap-benefitName primary-dark col-100 floatLft">
                            Login to the <br /> Platform and submit <br /> your
                            details
                          </h2>
                          <div className="ap-curvelineImg absolute">
                            <img src={curveArrow} alt="Line Image" />
                          </div>
                        </li>
                        <li className="ap-noMar">
                          <div className="ap-startedIco bg-primary relative">
                            <img src={startedImg3} alt="Started Icon" />
                            <span className="ap-count absolute">04</span>
                          </div>
                          <h2 className="ap-benefitName primary-dark col-100 floatLft">
                            Support for obtaining ARN and <br /> Empanelment
                            with Mutual Funds
                          </h2>
                          <div className="ap-lineImg absolute">
                            <img src={arrowRight} alt="Line Image" />
                          </div>
                        </li>
                        <li className="ap-noMar">
                          <div className="ap-startedIco bg-primary relative">
                            <img src={startedImg4} alt="Started Icon" />
                            <span className="ap-count absolute">03</span>
                          </div>
                          <h2 className="ap-benefitName primary-dark col-100 floatLft">
                            Access the study material <br /> for the NISM VA
                            Exam, <br /> and live trainer-led <br /> sessions
                          </h2>
                        </li>
                      </ul>
                      <div className="ap-payFeesBtn-wrp col-100 floatLft textCenter">
                        <a
                          href="https://rzp.io/l/mfd-karein-shuru"
                          className="inlineBlk bg-primary"
                        >
                          Pay NISM Exam Fees
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="ap-lineSeparator col-100 floatLft"></div>
                  <h2 className="ap-common-head col-100 floatLft textCenter primary-dark">
                    <span className="primary-green">Seamless support</span> by
                    CIEL
                  </h2>
                  <ul className="ap-supportList col-100 floatLft flexDisplay justifySpace alignStart flexWrap">
                    <li className="inlineBlk ap-supportInner">
                      <div className="ap-supportCard col-100 floatLft textCenter">
                        <div className="ap-supportIco">
                          <img src={supportIco1} alt="Support Image" />
                        </div>
                        <h2 className="ap-supportTitle col-100 floatLft textCenter primary-dark">
                          Get Trained <br /> Without Any Cost
                        </h2>
                        <ul className="ap-supportPoints col-100 floatLft textLeft">
                          <li className="col-100 floatLft relative primary-dark">
                            Free Virtual training for NISM VA <br /> Exam, using
                            self-study material <br /> and trainer-led sessions
                          </li>
                        </ul>
                      </div>
                    </li>
                    <li className="inlineBlk ap-supportInner">
                      <div className="ap-supportCard col-100 floatLft textCenter">
                        <div className="ap-supportIco">
                          <img src={supportIco2} alt="Support Image" />
                        </div>
                        <h2 className="ap-supportTitle col-100 floatLft textCenter primary-dark">
                          Operational <br /> Support
                        </h2>
                        <ul className="ap-supportPoints col-100 floatLft textLeft">
                          <li className="col-100 floatLft relative primary-dark">
                            Operational support towards NISM Profile
                          </li>
                          <li className="col-100 floatLft relative primary-dark">
                            Creation, NISM VA Exam Enrolment <br /> &
                            coordination for Exam dates
                          </li>
                          <li className="col-100 floatLft relative primary-dark">
                            Support in getting your ARN <br /> (AMFI
                            registration Number)
                          </li>
                          <li className="col-100 floatLft relative primary-dark">
                            Support in Empanelment with all <br /> Mutual Funds
                          </li>
                        </ul>
                      </div>
                    </li>
                    <li className="inlineBlk ap-supportInner">
                      <div className="ap-supportCard col-100 floatLft textCenter">
                        <div className="ap-supportIco">
                          <img src={supportIco3} alt="Support Image" />
                        </div>
                        <h2 className="ap-supportTitle col-100 floatLft textCenter primary-dark">
                          Seamless <br /> Induction Process
                        </h2>
                        <ul className="ap-supportPoints col-100 floatLft textLeft">
                          <li className="col-100 floatLft relative primary-dark">
                            Free Mutual Fund Industry Induction <br /> Training
                            Course post your <br /> empanelment
                          </li>
                        </ul>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </section>

          <section className="ap-testimonials col-100 floatLft flexDisplay justifyCenter">
            <div className="wrapper">
              <TestiSlider />
            </div>
          </section>

          <section className="ap-FAQ  col-100 floatLft flexDisplay justifyCenter">
            <div className="wrapper" id="faq">
              <br />
              <br />
              <br />
              <br />
              <br />
              <h2 className="ap-common-head col-100 floatLft textCenter primary-dark">
                Frequently Asked Questions (FAQ)
              </h2>
              <div className="ap-accordWrp col-100 floatLft" id="accord">
                <AccordionSect />
                <div className="ap-knowMore-click col-100 floatLft textCenter">
                  <a
                    href="https://mfdkareinshuru.com/en/"
                    className="primary-dark"
                  >
                    Know more
                  </a>
                </div>
              </div>
            </div>
          </section>

          <Footer />
          <Formik
            initialValues={{
              mobileNumber: this.state.mobileNumber,
              fullName: this.state.fullName,
              emailId: this.state.emailID,
              message: this.state.message,
              OTP: "",
              termsandconditions: false,
            }}
            validationSchema={Yup.object().shape({
              mobileNumber: Yup.string()
                .matches(/^[2-9]\d{9}$/, "Please enter the valid mobile number")
                .required("You must specify a Mobile Number")
                .when((value) => {
                  if (value !== undefined) {
                    if (value.length === 10 && this.isNumber(value)) {
                      this.setState({
                        showGenerateOtp: true,
                        mobileNumber: value,
                      });
                    }
                    if (value.length < 10 || value.length > 10) {
                      this.setState({ showGenerateOtp: false });
                    }
                  } else {
                    this.setState({ showGenerateOtp: false });
                  }
                }),
              fullName: Yup.string()
                .min(2, "Too Short!")
                .max(50, "Too Long!")
                .required("You must specify a  Name"),

              emailId: Yup.string()
                .email("Invalid E-Mail")
                .required("You must specify a E-Mail Id"),
              message: Yup.string().required("You must specify a Message"),
            })}
            onSubmit={(fields, { resetForm }, setFieldValue) => {
              if (
                fields.termsandconditions === false ||
                fields.termsandconditions === undefined
              ) {
                warning(
                  "Please select terms and conditions",
                  warningNotification
                );
              } else {
                if (this.state.isValidOtp) {
                  let data = {
                    mobileNumber: fields.mobileNumber,
                    fullName: fields.fullName,
                    emailID: fields.emailId,
                    enquiryMessage: fields.message,
                    enquiryType: 1,
                  };
                  LoginService.TokenUser((res) => {
                    localStorage.setItem("jwt-token", res.responseObject);

                    GeneralEnquiryService.PostEnquiry(data, (res) => {
                      if (res.status === "success") {
                        success(
                          fields.fullName +
                            " , Thank you for the Enquiry, we would contact you.",
                          successNotification
                        );
                        resetForm({});
                        this.setState({
                          display: "none",
                        });
                        window.location.reload();
                      }
                    });
                  });
                } else {
                  warning("Enter Valid OTP", warningNotification);
                }
              }
            }}
            render={({
              errors,
              touched,
              handleSubmit,
              setFieldValue,
              value,
            }) => (
              <React.Fragment>
                <Form
                  onSubmit={handleSubmit}
                  className="form_name"
                  id="getintouch"
                  style={{ display: this.state.display }}
                >
                  <div className="modal-dialog modal-md" role="document">
                    <div className="modal-content">
                      <button
                        type="reset"
                        className="close md-hidden lg-hidden sm-hidden"
                        data-dismiss="modal"
                        aria-label="Close"
                        onClick={this.handleClose}
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                      <section className="registration">
                        <div className="modal-body">
                          <div className="container">
                            <div className="row">
                              <div className="col-md-12">
                                <div className="modal-head">
                                  <div
                                    className="section-title-black"
                                    style={{ textAlign: "center" }}
                                  >
                                    <h2>Get In Touch</h2>
                                    <p>
                                      Leave us your message and we will get back
                                      to you
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="col-md-12">
                              <div className="form-group">
                                <label>
                                  FULL NAME{" "}
                                  <span className="validation"> *</span>
                                </label>
                                <Field
                                  type="text"
                                  name="fullName"
                                  id="fullName"
                                  autoComplete="fullName"
                                  className={
                                    "form-control" +
                                    (errors.fullName && touched.fullName
                                      ? " is-invalid"
                                      : "")
                                  }
                                  placeholder="Full Name"
                                />
                                <ErrorMessage
                                  name="fullName"
                                  className="validation"
                                  component="div"
                                />
                                <label>
                                  EMAIL <span className="validation"> *</span>
                                </label>
                                <Field
                                  type="email"
                                  name="emailId"
                                  id="emailId"
                                  autoComplete="emailId"
                                  className={
                                    "form-control" +
                                    (errors.emailId && touched.emailId
                                      ? " is-invalid"
                                      : "")
                                  }
                                  placeholder="Email ID"
                                />
                                <ErrorMessage
                                  name="emailId"
                                  className="validation"
                                  component="div"
                                />
                                <label>
                                  MOBILE<span className="validation"> *</span>
                                </label>
                                <Field
                                  type="text"
                                  name="mobileNumber"
                                  id="mobileNumber"
                                  autoComplete="mobileNumber"
                                  maxlength="10"
                                  className={
                                    "form-control" +
                                    (errors.mobileNumber && touched.mobileNumber
                                      ? " is-invalid"
                                      : "")
                                  }
                                  placeholder="Mobile Number"
                                />
                                {this.state.showGenerateOtp &&
                                  !this.state.isOTPGenerated && (
                                    <div>
                                      <a
                                        className="otp-resend"
                                        // onClick={this.handleGenerateOtp}
                                        onClick={this.startTimer}
                                      >
                                        Generate OTP
                                      </a>
                                    </div>
                                  )}

                                <ErrorMessage
                                  name="mobileNumber"
                                  className="validation"
                                  component="div"
                                />
                                {this.state.showOTPBox && (
                                  <div className="">
                                    <label>
                                      ENTER OTP{" "}
                                      <span className="validation"> *</span>
                                    </label>
                                    <Field
                                      type="text"
                                      name="OTP"
                                      id="OTP"
                                      maxlength="4"
                                      onBlur={this.handleValidateOtp}
                                      className={"form-control"}
                                      placeholder=""
                                      style={{ width: "100%" }}
                                    />
                                    {this.state.showTimer ? (
                                      <div>
                                        {this.state.showResendOtp && (
                                          <React.Fragment>
                                            <div className="otp-resend">
                                              <a
                                                onClick={(e) => {
                                                  this.startTimer();
                                                }}
                                              >
                                                Resend OTP
                                              </a>
                                            </div>
                                          </React.Fragment>
                                        )}
                                        <h5 className="timer">
                                          {this.state.time.m} :{" "}
                                          {this.state.time.s} Time Left
                                        </h5>
                                      </div>
                                    ) : (
                                      ""
                                    )}

                                    <ErrorMessage
                                      name="OTP"
                                      component="div"
                                      className="validation"
                                    />
                                  </div>
                                )}
                                <label>
                                  MESSAGE <span className="validation"> *</span>
                                </label>
                                <Field
                                  as="textarea"
                                  rows="2"
                                  cols="50"
                                  name="message"
                                  id="message"
                                  autoComplete="message"
                                  className={
                                    "form-control" +
                                    (errors.message && touched.message
                                      ? " is-invalid"
                                      : "")
                                  }
                                />
                                <ErrorMessage
                                  name="message"
                                  className="validation"
                                  component="div"
                                />
                              </div>
                            </div>
                            <div className="col-md-12 col-sm-12 col-xs-12">
                              <Field
                                className="form-check-input"
                                type="checkbox"
                                name="termsandconditions"
                                style={{
                                  marginRight: "10px",
                                  border: "2px solid black",
                                  height: "20px",
                                  width: "20px",
                                }}
                              />

                              <span
                                class="form-check-label"
                                for="gridCheck"
                                style={{ marginRight: "15px" }}
                              >
                                <a href={TC} target="_blank">
                                  Terms and Conditions
                                </a>
                                <span className="validation"> *</span>
                              </span>
                            </div>
                          </div>
                          {!this.state.isMaintainenceActivated ? (
                            <div className="getintouchbtn">
                              <button type="submit" className="btn-5">
                                Submit
                              </button>{" "}
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </section>
                    </div>
                  </div>
                </Form>

                <div
                  className="modal fade"
                  id="enq"
                  tabIndex="-1"
                  role="dialog"
                  aria-labelledby="modalLabel"
                  aria-hidden="true"
                >
                  <Form onSubmit={handleSubmit} className="form_name">
                    <div className="modal-dialog modal-lg" role="document">
                      <div className="modal-content">
                        <button
                          type="reset"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                          onClick={this.handleClose}
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                        <section className="registration">
                          <div className="modal-body">
                            <div className="container">
                              <div className="row">
                                <div className="col-md-8">
                                  <div className="modal-head">
                                    <div
                                      className="section-title-black"
                                      style={{ textAlign: "center" }}
                                    >
                                      <h2>Get In Touch</h2>
                                      <p>
                                        Leave us your message and we will get
                                        back to you
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="col-md-8">
                                <div className="form-group">
                                  <label>
                                    FULL NAME{" "}
                                    <span className="validation"> *</span>
                                  </label>
                                  <Field
                                    type="text"
                                    name="fullName"
                                    id="fullName"
                                    autoComplete="fullName"
                                    className={
                                      "form-control" +
                                      (errors.fullName && touched.fullName
                                        ? " is-invalid"
                                        : "")
                                    }
                                    placeholder="Full Name"
                                  />
                                  <ErrorMessage
                                    name="fullName"
                                    className="validation"
                                    component="div"
                                  />
                                  <label>
                                    EMAIL <span className="validation"> *</span>
                                  </label>
                                  <Field
                                    type="email"
                                    name="emailId"
                                    id="emailId"
                                    autoComplete="emailId"
                                    className={
                                      "form-control" +
                                      (errors.emailId && touched.emailId
                                        ? " is-invalid"
                                        : "")
                                    }
                                    placeholder="Email ID"
                                  />
                                  <ErrorMessage
                                    name="emailId"
                                    className="validation"
                                    component="div"
                                  />
                                  <label>
                                    MOBILE{" "}
                                    <span className="validation"> *</span>
                                  </label>
                                  <Field
                                    type="text"
                                    name="mobileNumber"
                                    id="mobileNumber"
                                    autoComplete="mobileNumber"
                                    className={
                                      "form-control" +
                                      (errors.mobileNumber &&
                                      touched.mobileNumber
                                        ? " is-invalid"
                                        : "")
                                    }
                                    placeholder="Mobile Number"
                                  />

                                  <ErrorMessage
                                    name="mobileNumber"
                                    className="validation"
                                    component="div"
                                  />
                                </div>
                                <div className="form-group">
                                  <label>
                                    MESSAGE{" "}
                                    <span className="validation"> *</span>
                                  </label>
                                  <Field
                                    as="textarea"
                                    rows="8"
                                    cols="50"
                                    name="message"
                                    id="message"
                                    autoComplete="message"
                                    className={
                                      "form-control" +
                                      (errors.message && touched.message
                                        ? " is-invalid"
                                        : "")
                                    }
                                  />
                                  <ErrorMessage
                                    name="message"
                                    className="validation"
                                    component="div"
                                  />
                                </div>
                              </div>
                              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12"></div>
                              <div className="col-md-12 col-sm-12 col-xs-12">
                                <Field
                                  className="form-check-input"
                                  type="checkbox"
                                  name="termsandconditions"
                                  style={{ marginRight: "10px" }}
                                />
                                <span
                                  class="form-check-label"
                                  for="gridCheck"
                                  style={{ marginRight: "15px" }}
                                >
                                  <a href={TC} target="_blank">
                                    Terms and Conditions
                                  </a>
                                  <span className="validation"> *</span>
                                </span>
                              </div>
                            </div>
                            {!this.state.isMaintainenceActivated ? (
                              <button type="submit" className="btn-4 mt-30">
                                Submit
                              </button>
                            ) : (
                              ""
                            )}
                          </div>
                        </section>
                      </div>
                    </div>
                  </Form>
                </div>
              </React.Fragment>
            )}
          />
        </div>
      </React.Fragment>
    );
  }
}

// function amfiPage() {
//   return (
//     <div>
//         <Header />
//         <a href="#" className="ap-whatsApp-click"><img src={whatsappIco} alt="WhatsApp Icon" /></a>
//         <a href="#" className="ap-commonFloatbtn ap-firstFloat">Why MFD Karein Shuru?</a>
//         <a href="#" className="ap-commonFloatbtn ap-secondFloat">Get In Touch</a>
//         <div className="ap-topStrip bg-primary textCenter col-100 floatLft">
//             <div className='wrapper'><h2 className="ap-stripHead col-100 floatLft">Mutual Fund Industry Initiative | Free Training</h2></div>
//         </div>
//         <section className='ap-banner-main col-100 floatLft flexDisplay justifyCenter'>
//             <div className='wrapper'>
//                 <div className="ap-bannerCnt-wrp col-100 floatLft flexDisplay justifySpace alignStart">
//                     <div className="ap-bannerFixed-txt floatLft">
//                         <h2 className="ap-common-head col-100 floatLft textLeft primary-dark">Welcome, <span className='primary-green'>future Mutual <br /> Fund Distributors</span></h2>
//                         <p className='ap-bannerPara col-100 floatLft primary-dark'>Toh, aapki journey karein shuru?</p>
//                     </div>
//                     <div className="ap-banner-sliderWrp floatRgt">
//                         <BannerSlider />
//                     </div>
//                 </div>
//             </div>
//         </section>
//         <div className="ap-topStrip ap-mt-none bg-primary textCenter col-100 floatLft">
//             <div className='wrapper'><h2 className="ap-stripHead col-100 floatLft">Training for NISM VA Exam, Handholding for Obtaining ARN, Enabling Empanelment with all AMCs and Operational Support</h2></div>
//         </div>
//         <section className='ap-benefits col-100 floatLft flexDisplay justifyCenter'>
//             <div className='wrapper'>
//                 <h3 className='ap-benefitsSmall col-100 floatLft textCenter primary-dark'>Benefits of Becoming</h3>
//                 <h2 className='ap-benefintsBig col-100 floatLft textCenter primary-green'>Mutual Fund Distributer</h2>
//                 <ul className='col-100 floatLft flexDisplay justifySpace alignStart'>
//                     <li className='flexDisplay flexColumn alignCenter textCenter'>
//                         <div className="ap-benefitsIco bg-primary flexDisplay justifyCenter alignCenter"><img src={benefitsImg1} alt="Benefits Icon" /></div>
//                         <h2 className="ap-benefitName primary-dark col-100 floatLft">Be your own boss</h2>
//                     </li>
//                     <li className='flexDisplay flexColumn alignCenter textCenter'>
//                         <div className="ap-benefitsIco bg-primary flexDisplay justifyCenter alignCenter"><img src={benefitsImg2} alt="Benefits Icon" /></div>
//                         <h2 className="ap-benefitName primary-dark col-100 floatLft">Continuous lifelong earnings</h2>
//                     </li>
//                     <li className='flexDisplay flexColumn alignCenter textCenter'>
//                         <div className="ap-benefitsIco bg-primary flexDisplay justifyCenter alignCenter"><img src={benefitsImg3} alt="Benefits Icon" /></div>
//                         <h2 className="ap-benefitName primary-dark col-100 floatLft">Flexibility to work anytime anywhere</h2>
//                     </li>
//                     <li className='flexDisplay flexColumn alignCenter textCenter'>
//                         <div className="ap-benefitsIco bg-primary flexDisplay justifyCenter alignCenter"><img src={benefitsImg4} alt="Benefits Icon" /></div>
//                         <h2 className="ap-benefitName primary-dark col-100 floatLft">No capital requirement to start business</h2>
//                     </li>
//                     <li className='flexDisplay flexColumn alignCenter textCenter'>
//                         <div className="ap-benefitsIco bg-primary flexDisplay justifyCenter alignCenter"><img src={benefitsImg5} alt="Benefits Icon" /></div>
//                         <h2 className="ap-benefitName primary-dark col-100 floatLft">Help others manage their finances</h2>
//                     </li>
//                     <li className='flexDisplay flexColumn alignCenter textCenter'>
//                         <div className="ap-benefitsIco bg-primary flexDisplay justifyCenter alignCenter"><img src={benefitsImg6} alt="Benefits Icon" /></div>
//                         <h2 className="ap-benefitName primary-dark col-100 floatLft">Gain recognition for your efforts</h2>
//                     </li>
//                 </ul>
//             </div>
//         </section>
//         <section className='ap-aboutus col-100 floatLft flexDisplay justifyCenter'>
//             <div className='wrapper'>
//                 <h2 className="ap-common-head col-100 floatLft textCenter primary-dark">About <span className='primary-green'>Us</span></h2>
//                 <p className='ap-aboutText col-100 floatLft textCenter primary-dark'>
//                 MFD Karein Shuru is a pan-India initiative of Association of Mutual Funds of India (AMFI) to create <br /> Mutual Fund Distributors (MFDs) for the entire Mutual Fund industry. Mutual Fund industry ispoised to reach over ₹100 <br /> crore of Assets Under Management (AUM) by 2030, it is important that awide base of MFDs is created to ensure better <br /> access of well-regulated MF products to potential investors across the country. <br /><br />
//                 As a part of the initiative, AMFI is providing free training, handholding and end-to-end operational support for everyone who intends to start their own business as a mutual fund distributor.
//                 <br /><br />
//                 Centre for Investment Education and Learning Private Limited (CIEL) is an authorised training and execution partner of AMFI.
//                 <br /><br />
//                 CIEL (Centre for Investment Education and Learning Private Limited) is an authorised service provider for AMFI for this project. CIEL is one of the largest learning-solutions company in the Banking, Financial Services and Insurance (BFSI) sector, focused on competency-building, for over a decade.
//                 </p>
//             </div>
//         </section>
//         <section className='ap-yellowBlock col-100 floatLft flexDisplay justifyCenter'>
//             <div className='wrapper'>
//                 <div className="ap-yellowInner col-100 floatLft">
//                     <div className="ap-startedDiv col-100 floatLft">
//                         <h2 className="ap-common-head col-100 floatLft textCenter primary-dark">How to get <span className='primary-green'>started</span></h2>
//                         <div className="ap-startedMain col-100 floatLft flexDisplay justifyCenter">
//                             <div className="ap-startedWrp">
//                                 <ul className='flexDisplay justifySpace alignStart  flexWrap relative col-100 floatLft'>
//                                     <li>
//                                         <div className="ap-startedIco bg-primary relative">
//                                             <img src={startedImg1} alt="Started Icon" />
//                                             <span className='ap-count absolute'>01</span>
//                                         </div>
//                                         <h2 className="ap-benefitName primary-dark col-100 floatLft">Pay ₹1500 towards <br /> NISM Examination Fee</h2>
//                                         <div className='ap-lineImg absolute'><img src={arrowLeft} alt="Line Image" /></div>
//                                     </li>
//                                     <li>
//                                         <div className="ap-startedIco bg-primary relative">
//                                             <img src={startedImg2} alt="Started Icon" />
//                                             <span className='ap-count absolute'>02</span>
//                                         </div>
//                                         <h2 className="ap-benefitName primary-dark col-100 floatLft">Login to the <br /> Platform and submit <br /> your details</h2>
//                                         <div className='ap-curvelineImg absolute'><img src={curveArrow} alt="Line Image" /></div>
//                                     </li>
//                                     <li className='ap-noMar'>
//                                         <div className="ap-startedIco bg-primary relative">
//                                             <img src={startedImg3} alt="Started Icon" />
//                                             <span className='ap-count absolute'>04</span>
//                                         </div>
//                                         <h2 className="ap-benefitName primary-dark col-100 floatLft">Support for obtaining ARN and <br /> Empanelment with Mutual Funds</h2>
//                                         <div className='ap-lineImg absolute'><img src={arrowRight} alt="Line Image" /></div>
//                                     </li>
//                                     <li className='ap-noMar'>
//                                         <div className="ap-startedIco bg-primary relative">
//                                             <img src={startedImg4} alt="Started Icon" />
//                                             <span className='ap-count absolute'>03</span>
//                                         </div>
//                                         <h2 className="ap-benefitName primary-dark col-100 floatLft">Access the study material <br /> for the NISM VA Exam, <br /> and live trainer-led <br /> sessions</h2>

//                                     </li>
//                                 </ul>
//                                 <div className="ap-payFeesBtn-wrp col-100 floatLft textCenter">
//                                     <a href="#" className='inlineBlk bg-primary'>Pay NIMS Fees</a>
//                                 </div>

//                             </div>

//                         </div>
//                         <div className="ap-lineSeparator col-100 floatLft"></div>
//                         <h2 className="ap-common-head col-100 floatLft textCenter primary-dark"><span className='primary-green'>Seamless support</span> by CIEL</h2>
//                         <ul className="ap-supportList col-100 floatLft flexDisplay justifySpace alignStart flexWrap">
//                             <li className='inlineBlk ap-supportInner'>
//                                 <div className="ap-supportCard col-100 floatLft textCenter">
//                                     <div className="ap-supportIco"><img src={supportIco1} alt="Support Image" /></div>
//                                     <h2 className="ap-supportTitle col-100 floatLft textCenter primary-dark">Get Trained <br /> Without Any Cost</h2>
//                                     <ul className="ap-supportPoints col-100 floatLft textLeft">
//                                         <li className='col-100 floatLft relative primary-dark'>Free Virtual training for NISM VA <br /> Exam, using self-study material <br /> and trainer-led sessions</li>
//                                     </ul>
//                                 </div>
//                             </li>
//                             <li className='inlineBlk ap-supportInner'>
//                                 <div className="ap-supportCard col-100 floatLft textCenter">
//                                     <div className="ap-supportIco"><img src={supportIco2} alt="Support Image" /></div>
//                                     <h2 className="ap-supportTitle col-100 floatLft textCenter primary-dark">Operational <br /> Support</h2>
//                                     <ul className="ap-supportPoints col-100 floatLft textLeft">
//                                         <li className='col-100 floatLft relative primary-dark'>Operational support towards NISM Profile</li>
//                                         <li className='col-100 floatLft relative primary-dark'>Creation,  NISM VA Exam Enrolment <br /> & coordination for Exam dates</li>
//                                         <li className='col-100 floatLft relative primary-dark'>Support in getting your ARN <br /> (AMFI registration Number)</li>
//                                         <li className='col-100 floatLft relative primary-dark'>Support in Empanelment with all <br /> Mutual Funds</li>
//                                     </ul>
//                                 </div>
//                             </li>
//                             <li className='inlineBlk ap-supportInner'>
//                                 <div className="ap-supportCard col-100 floatLft textCenter">
//                                     <div className="ap-supportIco"><img src={supportIco3} alt="Support Image" /></div>
//                                     <h2 className="ap-supportTitle col-100 floatLft textCenter primary-dark">Seamless <br /> Induction Process</h2>
//                                     <ul className="ap-supportPoints col-100 floatLft textLeft">
//                                         <li className='col-100 floatLft relative primary-dark'>Free Mutual Fund Industry Induction <br /> Training Course post your <br /> empanelment</li>
//                                     </ul>
//                                 </div>
//                             </li>
//                         </ul>
//                     </div>
//                 </div>
//             </div>
//         </section>

//         <section className='ap-testimonials col-100 floatLft flexDisplay justifyCenter'>
//             <div className='wrapper'>
//                 <TestiSlider />
//             </div>
//         </section>

//         <section className='ap-FAQ col-100 floatLft flexDisplay justifyCenter'>
//             <div className='wrapper'>
//                 <h2 className="ap-common-head col-100 floatLft textCenter primary-dark">Frequently Asked Questions (FAQ)</h2>
//                 <div className="ap-accordWrp col-100 floatLft" id="accord">
//                     <AccordionSect />
//                     <div className="ap-knowMore-click col-100 floatLft textCenter"><a href="#" className='primary-dark'>Know more</a></div>
//                 </div>
//             </div>
//         </section>

//         <Footer />
//     </div>
//   )
// }

export default amfiPage;
