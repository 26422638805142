import React, { Component } from "react";
import { Circle, Line } from "rc-progress";
import { ContactlessOutlined } from "@material-ui/icons";
import { zhCN } from "date-fns/locale";

export class userProfileComplitiomBar extends Component {
  constructor(props) {
    super(props);
    // alert( (
    //   this.props.personnelProfileData.learnerQualification[0] || ""
    // ).educationQualificationDocumentURL)
    //
    let userProfileCompletedData = {
      mobileNo: this.props.personnelProfileData.mobileNo,
      emailID: this.props.personnelProfileData.emailID,
      gender: this.props.personnelProfileData.gender,
      firstName: this.props.personnelProfileData.firstName,
      middleName: this.props.personnelProfileData.middleName,
      lastName: this.props.personnelProfileData.lastName,
      // whoareu: this.props.personnelProfileData.learnerType,
      // currentProfession: this.props.personnelProfileData.learnerProfession,
      // ARNEUINNO: this.props.personnelProfileData.euinNumber,
      //  contactNo: this.props.personnelProfileData.contactNo==""?null:this.props.personnelProfileData.contactNo,
      secondaryEmailID: this.props.personnelProfileData.secondaryEmailID,
      dob: this.props.personnelProfileData.dob,
      panNumber: this.props.personnelProfileData.panNumber,
      aadharNumber: this.props.personnelProfileData.aadharNumber,
      bankName: this.props.personnelProfileData.bankName,
      branch: this.props.personnelProfileData.branchName,
      branchAddress: this.props.personnelProfileData.branchAddress,
      accountType: this.props.personnelProfileData.accountType,
      acccountNumber: this.props.personnelProfileData.accountNumber,
      // perPostalCode: this.props.personnelProfileData.perPostalCode,

      passportNumber: this.props.personnelProfileData.passportNumber,
      fatherName: this.props.personnelProfileData.learnerFatherName,
      salutation: this.props.personnelProfileData.salutation,
      professionalQualificationoccupation: (
        this.props.personnelProfileData.learnerProfessionalQualification[0] || ""
      ).occupation,
      professionalQualificationcompanyName: (
        this.props.personnelProfileData.learnerProfessionalQualification[0] || ""
      ).companyName,
      professionalQualificationdesignation: (
        this.props.personnelProfileData.learnerProfessionalQualification[0] || ""
      ).designation,
      professionalQualificationtotalExperience: (
        this.props.personnelProfileData.learnerProfessionalQualification[0] || ""
      ).totalExperience,
      professionalQualificationannualIncome: (
        this.props.personnelProfileData.learnerProfessionalQualification[0] || ""
      ).annualIncome,
      professionalQualificationtoDate: (
        this.props.personnelProfileData.learnerProfessionalQualification[0] || ""
      ).toDate,
      professionalQualificationfromDate: (
        this.props.personnelProfileData.learnerProfessionalQualification[0] || ""
      ).fromDate,

      // CPEDetailspassingCertificateNo: (
      //   this.props.personnelProfileData.learnerCpeDetails[0] || ""
      // ).passingCertificateNo,
      // CPEDetailscpeCertificateNo: (
      //   this.props.personnelProfileData.learnerCpeDetails[0] || ""
      // ).cpeCertificateNo,
      // CPEDetailsdateOfPassingTest: (
      //   this.props.personnelProfileData.learnerCpeDetails[0] || ""
      // ).dateOfPassingTest,
      // CPEDetailsdateOfCpe: (
      //   this.props.personnelProfileData.learnerCpeDetails[0] || ""
      // ).dateOfCpe,
      learnerQualificationinstituteName: (
        this.props.personnelProfileData.learnerQualification[0] || ""
      ).instituteName,
      learnerQualificationqualificationType: (
        this.props.personnelProfileData.learnerQualification[0] || ""
      ).qualificationType,
      learnerQualificationpassingYear: (
        this.props.personnelProfileData.learnerQualification[0] || ""
      ).passingYear
        ? (this.props.personnelProfileData.learnerQualification[0] || "")
          .passingYear
        : '',
      learnerQualificationpercentage: (
        this.props.personnelProfileData.learnerQualification[0] || ""
      ).percentage,
      learnerQualificationeducationalQualification: (
        this.props.personnelProfileData.learnerQualification[0] || ""
      ).educationQualificationDocumentURL,
      profilePhotoURL: this.props.personnelProfileData.profilePhotoURL,
      //   resumeDocumentURL: this.props.personnelProfileData.resumeDocumentURL,
      // resumeVideoURL: this.props.personnelProfileData.resumeVideoURL,
      panCardURL: this.props.personnelProfileData.panCardURL,
      aadharCardURL: this.props.personnelProfileData.aadharCardURL,
      BackaadharCardBackURL: this.props.personnelProfileData.aadharCardBackURL,

      // currentProfession: this.props.personnelProfileData.learnerProfession,
      // ARNEUINNO: this.props.personnelProfileData.euinNumber
    };
    console.log(userProfileCompletedData)
    // if (this.props.personnelProfileData.lookingFor === "1002") {
    //   userProfileCompletedData['ARNNO'] = this.props.personnelProfileData.arn === undefined ? "" : this.props.personnelProfileData.arn;
    //   userProfileCompletedData['NameOfARNHolder'] = this.props.personnelProfileData.nameOfARNHolder == undefined ? "" : this.props.personnelProfileData.nameOfARNHolder;
    // }
    // else {
    //   userProfileCompletedData['ARNuserFeildsnameOfMutualFundEmpanelled'] = this.props.personnelProfileData.nameOfMutualFundEmpanelled === undefined ? "" : (
    //     this.props.personnelProfileData.nameOfMutualFundEmpanelled[0] || ""
    //   ).nameOfMutualFundEmpanelled;
    // }
    // if (this.props.personnelProfileData.lookingFor === "1003") {

    //   userProfileCompletedData['CPEDetailspassingCertificateNo'] = this.props.personnelProfileData.learnerCpeDetails[0] ? (
    //     this.props.personnelProfileData.learnerCpeDetails[0] || ""
    //   ).passingCertificateNo : "";
    //   userProfileCompletedData['CPEDetailscpeCertificateNo'] = this.props.personnelProfileData.learnerCpeDetails[0] ? (
    //     this.props.personnelProfileData.learnerCpeDetails[0] || ""
    //   ).cpeCertificateNo : "";
    //   userProfileCompletedData['CPEDetailsdateOfPassingTest'] = this.props.personnelProfileData.learnerCpeDetails[0] ? (
    //     this.props.personnelProfileData.learnerCpeDetails[0] || ""
    //   ).dateOfPassingTest : "";
    //   userProfileCompletedData['CPEDetailsdateOfCpe'] = this.props.personnelProfileData.learnerCpeDetails[0] ? (
    //     this.props.personnelProfileData.learnerCpeDetails[0] || ""
    //   ).dateOfCpe : ""
    // }
    if (this.props.personnelProfileData.contactNo !== undefined && this.props.personnelProfileData.contactNo !== "") {
      userProfileCompletedData["contactNo"] = this.props.personnelProfileData.contactNo
    }
    let userProfileFieldWeight = {
      mobileNo: 2,
      emailID: 2,
      gender: 1,
      firstName: 1,
      middleName: 1,
      lastName: 1,
      contactNo: 1,
      secondaryEmailID: 1,
      dob: 1,
      panNumber: 10,
      panCardURL: 1,
      aadharCardURL: 0,
      aadharNumber: 0,
      learnerQualificationinstituteName: 1,
      learnerQualificationqualificationType: 1,
      learnerQualificationeducationalQualification: 1,
      learnerQualificationpassingYear: 1,
      learnerQualificationpercentage: 1,
      profilePhotoURL: 1,

      bankName: 1,
      branch: 1,
      branchAddress: 1,
      accountType: 1,
      acccountNumber: 1,

      passportNumber: 1,
      fatherName: 1,
      salutation: 1,
      professionalQualificationoccupation: 1,
      professionalQualificationcompanyName: 1,
      professionalQualificationdesignation: 1,
      professionalQualificationtotalExperience: 1,
      professionalQualificationannualIncome: 1,
      professionalQualificationfromDate: 1,
      professionalQualificationtoDate: 1,
      BackaadharCardBackURL: 1,
      // CPEDetailspassingCertificateNo: 1,
      // CPEDetailscpeCertificateNo: 1,
      // CPEDetailsdateOfPassingTest: 1,
      // PEDetailsdateOfCpe: 1,
      //   whoareu: 1,
      //   currentProfession: 1,
      //  ARNEUINNO: 1
      // resumeDocumentURL: 1,
      //   resumeVideoURL: 1,
      //   perPostalCode: 1,

    };
    // if (this.props.personnelProfileData.lookingFor === "1002") {
    //   userProfileFieldWeight['ARNNO'] = 1;
    //   userProfileFieldWeight['NameOfARNHolder'] = 1;

    // }
    // else if (this.props.personnelProfileData.lookingFor === "1001") {
    //   userProfileFieldWeight['ARNuserFeildsnameOfMutualFundEmpanelled'] = 1;
    // }
    // else if (this.props.personnelProfileData.lookingFor === "1003") {



    //   userProfileFieldWeight['CPEDetailspassingCertificateNo'] = 1;
    //   userProfileFieldWeight['CPEDetailscpeCertificateNo'] = 1;
    //   userProfileFieldWeight['CPEDetailsdateOfPassingTest'] = 1;
    //   userProfileFieldWeight['CPEDetailsdateOfCpe'] = 1;

    // }
    let peraddress;
    let corsAddress;
    this.props.personnelAddressData.forEach(function (addr) {
      if (addr.addressType == "permanent") {
        peraddress = addr;
      }
    });
    this.props.personnelAddressData.forEach(function (addr) {
      if (addr.addressType == "correspondence") {
        corsAddress = addr;
      }
    });

    let userProfileAddressData = {
      perStreet: peraddress ? peraddress.street : '',
      perStreet2: peraddress ? peraddress.street2 : '',
      percityId: peraddress ? peraddress.cityId : '',
      perstateId: peraddress ? peraddress.stateId : '',
      percountryId: peraddress ? peraddress.countryId : '',
      perPostalCode: peraddress ? peraddress.postalCode : '',
      perStreet3: peraddress ? peraddress.street3 : '',
      addressProofURL: this.props.personnelProfileData.proofOfAddressURL,
      peraddressproof1: this.props.personnelProfileData.proofOfAddress,
      corStreet3: corsAddress ? corsAddress.street3 : '',
      corStreet: corsAddress ? corsAddress.street : '',
      corStreet2: corsAddress ? corsAddress.street2 : '',
      corscityId: corsAddress ? corsAddress.cityId : '',
      corsstateId: corsAddress ? corsAddress.stateId : '',
      corscountryId: corsAddress ? corsAddress.countryId : '',
      corPostalCode: corsAddress ? corsAddress.postalCode : ''
    };

    let userProfileAddressDataWeight = {
      perStreet: 1,
      perStreet2: 1,
      percityId: 1,
      perstateId: 1,
      percountryId: 1,
      perPostalCode: 1,
      peraddressproof1: 1,
      corStreet: 1,
      corStreet2: 1,
      corscityId: 1,
      corsstateId: 1,
      corscountryId: 1,
      corPostalCode: 1,
      perStreet3: 1,
      addressProofURL: 1,
      corStreet3: 1,
    };

    var obj = userProfileAddressData;
    var keys = Object.keys(obj);
    var filteredAddressData = keys.filter(function (key) {
      return obj[key];
    });

    let combinedweightvalueForAddress = 0;
    let fiterweighAddress = [];
    fiterweighAddress.push(userProfileAddressDataWeight);
    filteredAddressData.forEach(filteredElement => {
      combinedweightvalueForAddress += fiterweighAddress[0][filteredElement];
    });


    var obj = userProfileCompletedData;

    var keys = Object.keys(obj);

    var filtered = keys.filter(function (key) {
      return obj[key];
    });

    let combinedweightvalue = 0;
    let fiterweigh = [];
    fiterweigh.push(userProfileFieldWeight);
    filtered.forEach(filteredElement => {
      combinedweightvalue += fiterweigh[0][filteredElement];
    });


    let combinedper = combinedweightvalue + combinedweightvalueForAddress;
    let count = Object.keys(userProfileAddressDataWeight).length + Object.keys(userProfileFieldWeight).length
    let firstObj = [...Object.keys(userProfileFieldWeight)]
    localStorage.setItem("combinedweightvalue", JSON.stringify(userProfileCompletedData))
    localStorage.setItem("combinedweightvalueForAddress", JSON.stringify(userProfileAddressData))

    localStorage.setItem('profilecombinedper', combinedper)
    // alert(combinedper)
    // alert(Object.keys(userProfileAddressDataWeight).length)

    let finalValue = (combinedper / count) * 100
    // alert(finalValue)
    localStorage.setItem('AllFeildsCount', count)

    localStorage.setItem('profilecomlitionvalue', Math.round(finalValue))
    this.state = {
      profileComplitionPercent: Math.round(finalValue),
      userProfileCompletedData: userProfileCompletedData,
      userProfileFieldWeight: userProfileFieldWeight
    };
  }

  render() {
    return (
      <div>
        <center>
          <div className="profile-graph">
            <Line
              percent={parseInt(localStorage.getItem('profilecomlitionvalue'))}
              //{this.state.profileComplitionPercent}
              strokeWidth="4"
              strokeColor="#109619"
              width="100%"
              height="auto"
            />
          </div>
          <span className="profile-completion-title">
            Your profile is {parseInt(localStorage.getItem('profilecomlitionvalue'))}% Completed
            {/* {this.state.profileComplitionPercent}% Completed */}
          </span>
        </center>
      </div>
    );
  }
}

export default userProfileComplitiomBar;
