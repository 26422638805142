// eslint-disable-next-line no-unused-vars
import React, { Component } from "react";
import ReactTable from "react-table";
import "react-table/react-table.css";
import DashboardHeader from "../../components/dashboardheader/dashboardheader";
import Finxfooter from "../../containers/finxfooter/finxfooter";
import Axios from "axios";
import { PaymentService } from "../../services/payment.service";
import * as moment from "moment";
import { ReattemptTestService } from "../../services/reattemptTest.service"
import { updateStatus } from "../../services/preAssessment.Service"

class Checkout extends Component {
    constructor(props) {
        super(props);
        this.state = {
            personaldetails: this.props.location.state.props.personaldetails === undefined ? this.props.location.state.props[0].personaldetails : this.props.location.state.props.personaldetails,
            productdetails: this.props.location.state.props.data === undefined ? this.props.location.state.props[0].data : this.props.location.state.props.data,
            orderdetails: this.props.location.state.orderDetails,
            load: true
        };
    }
    componentDidMount() {
        if (localStorage.getItem('reload') === 'true') {
            localStorage.setItem('reload', 'false');
            window.location.reload();
        }

        // setTimeout(() => {
        //     document.paytm_form.submit();
        // }, 2000);
    }
    // updateStatus = () => {
    //     var data1 = localStorage.getItem("PersonnelData")

    //     let data = {
    //         "ExcecutionTracker": [
    //             {
    //                 "action": "NISM VA PAYMENT COMPLETE",

    //                 "workflowId": JSON.parse(data1).responseObject.learnerID,
    //                 "workflowName": "ARN",
    //                 "comment": "test"

    //             }
    //         ]
    //     }

    //     updateStatus(data, res => {
    //         console.log(res)
    //         if (res.data.status === "success" || res.data.status === "SUCCESS") {

    //             //window.location.reload()
    //         }
    //     })


    // }
    handleBackClick = () => {
        window.location.replace('/learnerDashboard');
    }
    handleClick = () => {

        var CheckoutObj = {
            learnerPartyID: this.state.orderdetails.learnerPartyID,
            productID: this.state.productdetails.length > 1 ? this.state.productdetails[1].productId : this.state.productdetails[0].productId,
            productName: this.state.productdetails.length > 1 ? this.state.productdetails[1].productName : this.state.productdetails[0].productName,
            paymentID: this.state.orderdetails.orderID,
            firstName: this.state.personaldetails.firstName,
            middleName: this.state.personaldetails.middleName,
            lastName: this.state.personaldetails.lastName,
            gender: 1,
            emailID: this.state.personaldetails.emailID,
            mobileNo: this.state.personaldetails.mobileNo,
            contactNo: "",
            dob: this.state.personaldetails.dateOfBirth
        };
        //console.log(JSON.stringify(CheckoutObj));
        localStorage.setItem("PaymentDet", JSON.stringify(CheckoutObj));
        localStorage.setItem("PaymentObj1", JSON.stringify(CheckoutObj));
        debugger
        var PayObj = {
            orderID: this.state.orderdetails.orderID,
            learnerPartyID: this.state.orderdetails.learnerPartyID,
            totalOrderPrice: this.state.orderdetails.totalOrderPrice
            //this.state.orderdetails.totalOrderPrice
        };
        //  this.updateStatus();
        PaymentService.Makepayment(PayObj, res => {
            this.props.history.push("/checkout-paytm", { paytm: res.data });
        });
    };

    render() {
        // console.log()
        console.log(this.state.orderdetails)
        return (
            <React.Fragment>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                            <div className="checkout">
                                <div className="col-md-2 col-lg-2 ml-30"></div>
                                <div className="col-md-7 col-lg-7 col-sm-12 col-xs-12 mt-50">
                                    <div className="bg-box">
                                        <div className="payment-detail">
                                            <h2 className="sub-header">Personal Details</h2>
                                            <div className="table-responsive">
                                                <table className="paydetail-table">
                                                    <tbody>
                                                        <tr>
                                                            <td>Order Number</td>
                                                            <td>:</td>
                                                            <td>
                                                                <b>{this.state.orderdetails.orderID}</b>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>Mobile No</td>
                                                            <td>:</td>
                                                            <td>
                                                                <b>{this.state.personaldetails.mobileNo}</b>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>Email</td>
                                                            <td>:</td>
                                                            <td>
                                                                <b>{this.state.personaldetails.emailID}</b>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>First Name</td>
                                                            <td>:</td>
                                                            <td>
                                                                <b>{this.state.personaldetails.firstName}</b>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>Last Name</td>
                                                            <td>:</td>
                                                            <td>
                                                                <b>{this.state.personaldetails.lastName}</b>
                                                            </td>
                                                        </tr>
                                                        {/* <tr>
                                                    <td>Address</td>
                                                    <td>:</td>
                                                    <td><b>C-3 Lorem Ipsum Dollar Amet Maharshtra India 411101</b></td>
                                                </tr> */}
                                                    </tbody>
                                                </table>
                                                <h2 className="sub-header mt-30">Coupon Code</h2>
                                                <div className="coupon-code">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Coupon Code?"
                                                        disabled
                                                    />
                                                    <div className="apply-coupon">
                                                        <button type="button" className="btn-5">
                                                            Apply
                                                     </button>
                                                    </div>
                                                </div>
                                                <h2 className="sub-header mt-30">Order Summary</h2>
                                                <table className="paydetail-table">
                                                    <thead>
                                                        <tr>
                                                            <th>
                                                                <b>Product</b>
                                                            </th>
                                                            <th>
                                                                <b>Amount</b>
                                                            </th>
                                                        </tr>
                                                    </thead>

                                                    {this.state.productdetails && this.state.productdetails.map(e => {
                                                        return (
                                                            <tbody>
                                                                {e.productPrice != 0 ?
                                                                    <tr>

                                                                        <td>{e.productName}</td>
                                                                        <td>
                                                                            <i className="fa fa-inr" aria-hidden="true"></i>{" "}
                                                                            {e.productPrice}
                                                                        </td>

                                                                    </tr>
                                                                    : ''}

                                                            </tbody>
                                                        )
                                                    })}
                                                </table>
                                            </div>
                                            <div classname="row">
                                                <div col-md-4 col-lg-4 ml-30>

                                                    <button
                                                        type="button"
                                                        className="btn-4 mt-45 mb-8 center-block inlinepayment"
                                                        onClick={this.handleClick} >
                                                        Make Payment </button>

                                                    <button
                                                        type="button"
                                                        className="btn-4 mt-40 mb-8 center-block inlinepayment"
                                                        onClick={this.handleBackClick} >
                                                        Back </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default Checkout;
