import React, { Component } from 'react'
import DashboardHeader from '../../components/dashboardheader/dashboardheader'
import Finxfooter from '../finxfooter/finxfooter'
import AdminDashboard from '../../components/admin/admin-dashboard';
import { connect } from "react-redux";
import { getProductsError, getProducts, getProductsPending } from '../../reducer/userReducer';
import { bindActionCreators } from 'redux';
import fetchProductsAction from '../../services/fetchUser';

const mapStateToProps = state => ({
    products: getProducts(state),

})

const mapDispatchToProps = dispatch => bindActionCreators({
    fetchProducts: fetchProductsAction
}, dispatch)

const connectFunction = connect(mapStateToProps, mapDispatchToProps)

const learnerdashboardlanding = connectFunction(
    class extends Component {

        constructor(props) {
            super(props);

        }

        componentDidMount() {
            const { fetchProducts } = this.props;
            // fetchProducts();

        }

        render() {
            const { products } = this.props;

            return (
                <React.Fragment >
                    <DashboardHeader {...this.props} />
                    <AdminDashboard {...this.props} />
                    <Finxfooter {...this.props} />
                </React.Fragment >
            )
        }
    })

export default learnerdashboardlanding;
