import React, { Component } from "react";
import { Formik, Field, Form, ErrorMessage, FieldArray ,getIn} from "formik";
import * as Yup from "yup";
import * as moment from "moment";
import { RegisterUserService } from "../../services/registration.service";
import FileBase64 from "react-file-base64";
import { LoginService } from "../../services/login.service";
import UserProfileComplitiomBar from "./userProfileComplitiomBar";
import { RegionManagementService } from "../../services/regionManagement.service";
import {
  success,
  error,
  successNotification,
  errorNotification,
  warning,
  warningNotification
} from "../notification/notifications";
import { fetchProcessType,getAMCMaster } from "../../services/fetchLearners";
import { PopUpPortal } from "../../containers/popups/PopUpPortal";
import ReactPlayer from "react-player";
import $ from "jquery";
import {
  camelCase,
  capitalCase,
  constantCase,
  dotCase,
  headerCase,
  noCase,
  paramCase,
  pascalCase,
  pathCase,
  sentenceCase,
  snakeCase
} from "change-case";
import { LookUpService } from "../../services/lookupService"
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import Modal from "react-responsive-modal";
import { Warning } from "@material-ui/icons";
require('moment-precise-range-plugin');

export const TextValidationMesg = "you must enter the text";
export let validateStatus='DRAFTED'
const currProf=[
  {
     "codeValueId":1071,
     "codeValue":"Financial Distributor",
    
  },
  {
     "codeValueId":1072,
     "codeValue":"Information Technology",
    
  },
  {
     "codeValueId":1073,
     "codeValue":"Medicine",
   
  },
  {
     "codeValueId":1074,
     "codeValue":"Engineering",
     
  },
  {
    "codeValueId":1075,
    "codeValue":"Education",
    
 },
 {
  "codeValueId":1076,
  "codeValue":"Govt. Employee",
  
},
{
  "codeValueId":1077,
  "codeValue":"Other",
  
}
]
class UserProfile extends Component {
  constructor(props) {
    super(props);
   if(this.props.personnelData.dob !== undefined){
    let suspendStartDt = this.props.personnelData.dob.split(" ");
    let suspendStartDate = suspendStartDt[0].split("/");

    this.suspendStartDate2 =
      suspendStartDate[2] +
      "-" +
      suspendStartDate[1] +
      "-" +
      suspendStartDate[0];
  }
      if(this.props.personnelData.learnerProfessionalQualification)
      {
      let learnerProfessionalQualification=this.props.personnelData.learnerProfessionalQualification
    learnerProfessionalQualification.map((ele, index) => {
      // let fromdate = ele.fromDate.split(" ");
      // let fromdate_Temp = fromdate[0].split("/");
      // ele.fromDate =  fromdate_Temp[2] +
      // "-" +
      // fromdate_Temp[1] +
      // "-" +
      // fromdate_Temp[0];

      // let toDate = ele.toDate.split(" ");
      // let toDate_Temp = toDate[0].split("/");
      // ele.toDate =  toDate_Temp[2] +
      // "-" +
      // toDate_Temp[1] +
      // "-" +
      // toDate_Temp[0];

      // ele.fromdate = moment(ele.fromdate).format('YYYY-MM-DD');
      // ele.toDate = moment(ele.toDate).format('YYYY-MM-DD');
      // alert(ele.todate)
      // alert(new Date(moment(ele.todate).format('YYYY-MM-DD')))
// ele.fromDate2=new Date(moment(ele.fromdate).format('YYYY-MM-DD'))
// ele.toDate2=new Date(moment(ele.fromdate).format('YYYY-MM-DD'))

    });

   

    
  }
  


  



    // // father birth date spilt and bind
    // if (this.props.personnelData.learnerFatherDOB) {
    //   let suspendStartDateFatherBirthDt = (
    //     (this.props.personnelData || "").learnerFatherDOB || ""
    //   ).split(" ");
    //   let suspendStartDateFatherBirthDate = (
    //     suspendStartDateFatherBirthDt[0] || ""
    //   ).split("/");
    //   this.suspendStartDateFatherBirthDate2 =
    //     suspendStartDateFatherBirthDate[2] +
    //     "-" +
    //     suspendStartDateFatherBirthDate[1] +
    //     "-" +
    //     suspendStartDateFatherBirthDate[0];
    // }

    // // mother birth date spilt and bind
    // if (this.props.personnelData.learnerMotherDOB) {
    //   let suspendStartDateMotherBirthDt = (
    //     (this.props.personnelData || "").learnerMotherDOB || ""
    //   ).split(" ");
    //   let suspendStartDateMotherBirthDate = (
    //     suspendStartDateMotherBirthDt[0] || ""
    //   ).split("/");
    //   this.suspendStartDateMotherBirthDate2 =
    //     suspendStartDateMotherBirthDate[2] +
    //     "-" +
    //     suspendStartDateMotherBirthDate[1] +
    //     "-" +
    //     suspendStartDateMotherBirthDate[0];
    // }

    //seperate the incoming address to the permanr address and correspondence address
    let peraddress;
    let corsAddress;
    this.props.addressData.forEach(function (addr) {
      if (addr.addressType == "permanent") {
        peraddress = addr;
      }
    });
    this.props.addressData.forEach(function (addr) {
      if (addr.addressType == "correspondence") {
        corsAddress = addr;
      }
    });
   let addressFlag=false
//      if(peraddress.street!=="" && peraddress.street2!=="" && peraddress.street3 !=="" &&peraddress.postalCode!=="")
// {
//     if(peraddress.street==corsAddress.street && peraddress.street2===corsAddress.street2 && peraddress.street3 ===corsAddress.street3 &&peraddress.postalCode==corsAddress.postalCode
//       && peraddress.cityId==corsAddress.cityId &&peraddress.stateId===corsAddress.stateId)
//       {
//         // this.setState({
//         //   addressFlag:true
//         // })
//         addressFlag=true
//       }
//     }
//       else{
//         // this.setState({
//         //   addressFlag:false
//         // })
//         addressFlag=false
//       }
      
    var todaydate = new Date();
    this.state = {
      today: moment(todaydate).format("YYYY-MM-DD"),
      show: false,
      dob: this.suspendStartDate2,
      aadharNumber: this.props.personnelData.aadharNumber,
      //collegeName: this.props.personnelData.collegeName?this.props.personnelData.collegeName:'',
      collegeName: this.props.personnelData.collegeName === "undefined" ?'': this.props.personnelData.collegeName,
     // isFinXInternship: this.props.personnelData.isFinXInternship,
     isFinXInternship: this.props.personnelData.isFinXInternship  === "undefined" ?'': this.props.personnelData.isFinXInternship,
      perAddressType:peraddress? peraddress.addressType:'',
      perAddressID: peraddress?peraddress.addressId:'',
      perStreet: peraddress?peraddress.street:'',
      perStreet2: peraddress?peraddress.street2:'',
      perStreet3:peraddress?peraddress.street3:'',
      perPostalCode: peraddress?peraddress.postalCode:'',
      //percityId: peraddress.cityId,
      percityId: peraddress?peraddress.cityId:'',
      perCityName: peraddress?peraddress.city:'',
      perstateId: peraddress?peraddress.stateId:'',
      perStateName:peraddress? peraddress.state:'',
      percountryId: peraddress!==undefined?peraddress.countryId:1,
      perCountryName: peraddress?peraddress.country:'',
      corAddressType:corsAddress? corsAddress.addressType:'',
      corAddressID:corsAddress? corsAddress.addressId:'',
      corStreet: corsAddress?corsAddress.street:'',
      corStreet2: corsAddress?corsAddress.street2:'',
      corStreet3:peraddress?corsAddress.street3:'',
      corPostalCode:corsAddress? corsAddress.postalCode:'',
      corcityId: corsAddress.cityId,
     
      corCityName:corsAddress? corsAddress.city:'',
      corstateId: corsAddress?corsAddress.stateId:'',
      corStateName:corsAddress? corsAddress.state:'',
      corcountryId: corsAddress?corsAddress.countryId:1,
      corCountryName: corsAddress?corsAddress.coutnry:'',
      learnerFatherDOB: this.suspendStartDateFatherBirthDate2,
      learnerMotherDOB: this.suspendStartDateMotherBirthDate2,
      resumeDocumentName: "",
      countryList: [],
      countryListForCors: [],
      stateListForCor: [],
      cityListForCor: [],
      stateListForPer: [],
      cityListForPer: [],
      employeTypeForParents: [],
      checkAddressFlag: "",
      listOfYear: [],
      addressFlag: addressFlag,
      dob_2_validate_message: "",
      dob_3_validate_message: "",
      processType:'',
      whoareu: [],
      occupation: [],
      year: '',
      month: '',
      days: '',
      ValidationState: 'DRAFTED',
      documents:[],
      educationalQualificationDocument: [],
      corAddressProof: '',
    perAddressProof: '',
      currentDate: '',
      panCardData: '',
      adharCardData: '',
      ProfilePhotoData: '',
      addressPoofCardData: '',
      videoResumeData: '',
      ResumeDocumentData: '',
      pancardTempURL: '',
      newAdharCardUrl:'',
      newAddressProof:'',
      AuualCome:[],
      nameOfMutual:[],
      ARNEUINNO:'',
      currentProfession:[],
      loading1: "none",
      loading: false,
      
  listOfYear1:[],
  listOfMonth:[],
  listOfDay:[],
  listOfYear2:[],

    };
  }
  
  submitHandler(e) {
    e.preventDefault();
  }
  titleCase(str) {

    return str.replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });

}
 goto(url)
    {
   // window.location=url;
    var e = document.getElementById(url); 
    e.scrollIntoView(true); 
    }
    replaceAll(str, find, replace) {
      var escapedFind=find.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "\\$1");
     // alert(str.replace(new RegExp(escapedFind, 'g'), replace))
      return str.replace(new RegExp(escapedFind, 'g'), replace);
    }
  componentDidMount() {

    if(this.state.perStreet === "" || this.state.perStreet === undefined){
      this.setState({
        perStreet: "",
        perStreet2: "",
        perStreet3:"",
        perPostalCode: "",
        percityId: "",
        perstateId: "",
        percountryId: "",
        checkAddressFlag: ""
      });
      
    }else {
    }
    
 var year = new Date().getFullYear();
 year = year-18
 var listOfYear = [];
 for (var i = 0; i < 100; i++) {
   var current = year - i;
   listOfYear.push(current);

 }

 var year1 = new Date().getFullYear();
 year1 = year1
 var listOfYear2 = [];
 for (var i = 0; i < 100; i++) {
   var current1 = year1 - i;
   listOfYear2.push(current1);

 }
 var listOfMonth = [];
 // for (var i = 1; i <= 12; i++) {
 //   // var current = year - i;
 //   listOfMonth.push(i);

 // }

 var listOfMonth = [{ "key": 1, "value": "Jan" },
 { "key": 2, "value": "Feb" },
 { "key": 3, "value": "Mar" },
 { "key": 4, "value": "Apr" },
 { "key": 5, "value": "May" },
 { "key": 6, "value": "Jun" },
 { "key": 7, "value": "Jul" },
 { "key": 8, "value": "Aug" },
 { "key": 9, "value": "Sep" },
 { "key": 10, "value": "Oct" },
 { "key": 11, "value": "Nov" },
 { "key": 12, "value": "Dec" },
 ];

 var listOfDay = [];
 for (var i = 1; i <= 31; i++) {
   //    var current = year - i;
   listOfDay.push(i);

 }
 this.setState({ listOfMonth: listOfMonth });
 this.setState({ listOfYear1: listOfYear });
 this.setState({ listOfDay: listOfDay });
 this.setState({ listOfYear2: listOfYear2 });


    //alert()
    this.setState({ loading1: "block" });
    this.setState({ loading: true });
    window.addEventListener("scroll", e => onScroll(e), true);

   


    

    LookUpService.getOccuption(
      "codeTypeId=101&sortOrder=ASC",
      res => {

        this.setState({
          whoareu: res.responseListObject,
          // countryListForPer: res.responseListObject
        }
        
        
        );
        this.setState({ loading: false });
        // this.setState({ loading1: "none" });
        this.setState({ loading1: "none" });
       
      }
    );

     LookUpService.getWhoareu(
            "codeTypeId=102&sortOrder=ASC",
            res => {
  
              this.setState({
                occupation: res.responseListObject,
                
               // countryListForPer: res.responseListObject
              });
              this.setState({ loading: false });
              this.setState({ loading1: "none" });
            }
          );

          LookUpService.getWhoareu(
            "codeTypeId=105&sortOrder=ASC",
            res => {
  
              this.setState({
                AuualCome: res.responseListObject,
               // countryListForPer: res.responseListObject
              });
              this.setState({ loading: false });
              this.setState({ loading1: "none" });
            }
          );


          LookUpService.getWhoareu(
            "codeTypeId=106&sortOrder=ASC",
            res => {
  
              this.setState({
                AddressProof: res.responseListObject,
               // countryListForPer: res.responseListObject
              });
              this.setState({ loading: false });
              this.setState({ loading1: "none" });
            }
          );
          LookUpService.getLookingFor(
            "codeTypeId=107&sortOrder=ASC",
            res => {
      
              this.setState({
                currentProfession: res.responseListObject,
                // countryListForPer: res.responseListObject
              });
              this.setState({ loading: false });
              this.setState({ loading1: "none" });
            }
          );

        
    function onScroll(event) {
      var scrollPos = $("section#create-user").offset().top;
      ////console.log("hiiiiiiiii" + scrollPos)

      $("section.page-section").each(function () {
        var scrollid = $(this).attr("id");
        var scrollPos = $("section#" + scrollid).offset().top;
        if (scrollPos > 100 && scrollPos < 300) {
          ////console.log("Scroll position :" + scrollid + scrollPos)
          $("#topheader .tab li").removeClass("active");
          $("a[href='#" + [scrollid] + "']")
            .parent("li")
            .addClass("active");
        }
      });
    
  
  
        // LookUpService.getWhoareu(
        //     "codeTypeId=102",
        //     res => {
  
        //       this.setState({
        //         whoareu: res.responseListObject,
        //        // countryListForPer: res.responseListObject
        //       });
        //     }
        //   );
      // $('#topheader .tab a').each(function () {
      //     var currLink = $(this);
      //     var refElement = $(currLink.attr("href"));
      //     if (refElement.position().top <= scrollPos && refElement.position().top + refElement.height() > scrollPos) {
      //         $('#topheader .tab li a').removeClass("active"); //added to remove active class from all a elements
      //         currLink.addClass("active");
      //     }
      //     else{
      //         currLink.removeClass("active");
      //     }
      // });
    }

   

    

    //qulification year dropdown bind start  here
    var year = new Date().getFullYear();
    
    var listOfYear = [];
    for (var i = 0; i < 100; i++) {
      var current = year - i;
      listOfYear.push(current);
      this.setState({ listOfYear: listOfYear });
    }
    //qulification year dropdown bind ends  here

    LoginService.TokenUser(res => {
      localStorage.setItem("jwt-token", res.responseObject);
    });

    RegionManagementService.getCountry(res => {
      this.setState({
        countryList: res.responseListObject
      });
      RegionManagementService.getState(
        "countryId=1" ,
        //+ this.state.corcountryId,
        res => {
          this.setState({
            stateListForCor: res.responseListObject
          });
          this.setState({ loading: false });
          this.setState({ loading1: "none" });
        }
      );
      RegionManagementService.getCity(
        "stateId=" + this.state.corstateId,
        res => {
          this.setState({
            cityListForCor: res.responseListObject
          });
          this.setState({ loading: false });
          this.setState({ loading1: "none" });
        }
      );
      RegionManagementService.getCity(
        "stateId=" + this.state.corstateId,
        res => {
          this.setState({
            cityListForCor: res.responseListObject
          });
          this.setState({ loading: false });
          this.setState({ loading1: "none" });
        }
      );
      RegionManagementService.getState(
        "countryId=1",
        // + this.state.percountryId,
        res => {
          this.setState({
            stateListForPer: res.responseListObject
          });
          this.setState({ loading: false });
          this.setState({ loading1: "none" });
        }
      );
      RegionManagementService.getCity(
        "stateId=" + this.state.perstateId,
        res => {
          this.setState({
            cityListForPer: res.responseListObject
          });
          this.setState({ loading: false });
          this.setState({ loading1: "none" });
        }
      );
    });
   
    RegisterUserService.getEmployeType(res => {
      this.setState({
        employeTypeForParents: res.responseListObject
      });
      this.setState({ loading: false });
      this.setState({ loading1: "none" });
    });
    fetchProcessType("learnerPartyID="+localStorage.getItem("userid-Token"),
    res => {
      
      this.setState({
        processType:res.responseObject.lookingFor
       
      
      });
      this.setState({ loading: false });
      this.setState({ loading1: "none" });
    });


    getAMCMaster(
    res => {
      this.setState({
        nameOfMutual: res.responseListObject
        // res.responseObject.learnerType
      });
      this.setState({ loading: false });
      this.setState({ loading1: "none" });
    }); 
  }

   getWords(monthCount) {
    function getPlural(number, word) {
        return number === 1 && word.one || word.other;
    }

    var months = { one: 'month', other: 'months' },
        years = { one: 'year', other: 'years' },
        m = monthCount % 12,
        y = Math.floor(monthCount / 12),
        result = [];

   // y && result.push(y + ' ' + getPlural(y, years));
    m && result.push(m );
    return result
}
   diff_years(date, toDate) 
  {
 
  //  var diff =(dt2.getTime() - dt1.getTime()) / 1000;
  //   diff /= (60 * 60 * 24);
   
  
  //  return Math.abs(Math.round(diff/365.25));


 // var date='2020-11-01'
  date = date.split('-');
   toDate=toDate.split('-');
 var today = new Date(toDate[0],toDate[1],toDate[2]);
 var year = today.getFullYear();
 var month = today.getMonth() ;
 var day = today.getDate();
 var yy = parseInt(date[0]);
 var mm = parseInt(date[1]);
 var dd = parseInt(date[2]);
 var years, months, days;
 // months
 months = month - mm;
 if (day < dd) {
     months = months - 1;
 }
 // years
 years = year - yy;
 if (month * 100 + day < mm * 100 + dd) {
     years = years - 1;
     months = months + 12;
 }
 // days
 days = Math.floor((today.getTime() - (new Date(yy + years, mm + months - 1, dd)).getTime()) / (24 * 60 * 60 * 1000));
 console.log( years,months);
 return years+'.'+months
  }
   diff_months(dt2, dt1) 
  {
 
   var diff =(dt2.getTime() - dt1.getTime()) / 1000;
    diff /= (60 * 60 * 24 * 7 * 4);
   return Math.abs(Math.round(diff));
   
  }


   checkName(name) {
     name=name.split(".")
    var regexp1=new RegExp("^[0-9A-Za-z- ]+$");

//    var url = "http://www.website.com/page.php?data=" + document.getElementById("url").value;

    if (!regexp1.test(name[0])) {
     //  alert("Only numbers, letters, hypens, periods, spaces and underscores ");
       return false
    } 
    else{
      return true
    }
}
  removeByAttr = (arr, attr, value) => {
    var i = arr.length;
    while (i--) {
      if (arr[i] && arr[i][attr] === value) {
        arr.splice(i, 1);
      }
    }
  };
  WhyTheFillForm = () => {
  //  this.setState({ linkModalloading :true, showWhyTheFillForm: true });
    
  window.$('#roadmap1').modal('show')
  }

  onWhyTheFillFormCloseModal = () => {
    this.setState({ linkModalloading:false, showWhyTheFillForm: false });
  };
  
    
  isValidDate = (fromDate,toDate) => {
    
    if(fromDate!==""){
     let fromDate1= fromDate.split('-')
     if(fromDate1[1]==2 && fromDate1[2]>29){
warning("Please select valid Date",warningNotification)
return false
     }else if (fromDate1[1] == 4 || fromDate1[1] == 6 ||fromDate1[1] ==9 || fromDate1[1] ==11 )

 {
   if(fromDate1[2]>30){
    warning("Please select valid Date",warningNotification)
return false
   }else{
    return true
   }
 }
 else{
   return true
 }
    }
   
    if(toDate!==""){
      let toDate1= fromDate.split('-')
      if(toDate1[1]==2 && toDate1[2]>29){
 warning("Please select valid Date",warningNotification)
 return false

      }else if (toDate1[1] == 4 || toDate1[1] == 6 ||toDate1[1] ==9 || toDate1[1] ==11 )
 
  {
    if(toDate1[2]>30){
     warning("Please select valid Date",warningNotification)
     return false
    }else{
      return true
    }
  }else{
    return true
  }
     }
    }

  calculateExp = (fromYear,fromMonth,fromDay,toYear,toMonth,toDay) => {
    
    let fromDate1=""
    if((fromYear!==undefined && fromYear!=="" && fromYear!=="0") && (fromMonth!==undefined && fromMonth!=="" && fromMonth!=="0") && (fromDay!==undefined && fromDay!==""  && fromDay!=="0")){
      fromDate1=fromYear + "-" + fromMonth + "-" + fromDay
      let flag=this.isValidDate(fromDate1,"")

    }else{
       fromDate1=""
      
    }
   
    let toDate1=""
    if((toYear!==undefined && toYear!==""  && toYear!=="0") && (toMonth!==undefined && toMonth!=="" && toMonth!=="0") && (toDay!==undefined && toDay!=="" && toDay!=="0")){
      toDate1 =toYear + "-" + toMonth + "-" + toDay 
     let flag=this.isValidDate("",toDate1)
                     }else{
                       toDate1=""
                       
                     } 

                   
                    
                 if(fromDate1!="" && toDate1!=""){
                  if(fromDate1>toDate1)
                  {
                   // warning("To Date should be greater than from date ",warningNotification)
                    return false
                  }else{
                      let yearsDiff=this.diff_years(fromDate1,toDate1)
                      this.updateWeightage("professionalQualificationfromDate",fromDate1)
                      this.updateWeightage("professionalQualificationtoDate",toDate1)
                      this.updateWeightage("professionalQualificationannualIncome",yearsDiff)

                      return yearsDiff
                  }
                      //alert(yearsDiff)
                 
                }
                
                     // alert(yearsDiff)
                      // if(yearsDiff=="NaN.NaN"){
                      // setFieldValue(
                      // (values.professionalQualification[index].totalExperience = 0.0)
                      // );
                      // }
                   //  }
    }

  updateWeightage(key,value){
    
//alert()
    let AllFeildsCount=  parseInt(localStorage.getItem('AllFeildsCount'))
  let count=localStorage.getItem("profilecombinedper")
 // localStorage.setItem("profilecombinedper",parseInt(count)+1)
        
 let data= JSON.parse(localStorage.getItem("combinedweightvalue"))
//alert(key)
   if(!data.hasOwnProperty(key) && value!="")
   {
//alert('!data.hasOwnProperty(key) && value!=""')
count=parseInt(count)+1
localStorage.setItem("profilecombinedper",count)

// alert(count)
// alert( (count/AllFeildsCount)*100)

let finalValue=(count/AllFeildsCount)*100
if(finalValue>100){
  finalValue=100
}
if(finalValue!=="NAN"){


localStorage.setItem('profilecomlitionvalue', Math.round(finalValue))
data[key]=value
localStorage.setItem("combinedweightvalue",JSON.stringify(data))   
}
// var percentComplete = parseInt((Object.keys(this.state.answerJSONList).length * 100) / resJSON.length)
   }
   else if(data[key]=="" && value!="")
   {
//alert('data[key]=="" && value!=""')
count=parseInt(count)+1

// alert(count)
// alert( (count/AllFeildsCount)*100)
localStorage.setItem("profilecombinedper",count)

let finalValue=(count/AllFeildsCount)*100
if(finalValue>100){
  finalValue=100
}
if(finalValue!=="NAN"){

localStorage.setItem('profilecomlitionvalue', Math.round(finalValue))
data[key]=value
localStorage.setItem("combinedweightvalue",JSON.stringify(data))  
}                                             // var percentComplete = parseInt((Object.keys(this.state.answerJSONList).length * 100) / resJSON.length)
   }
   else if(data.hasOwnProperty(key) &&  value===""){
     // data.contactNo=e.target.value
    //alert("data.hasOwnProperty(key) &&  value===")
      delete data[key];
      localStorage.setItem("combinedweightvalue",JSON.stringify(data))
count=parseInt(count)-1
// alert(count)
// alert( (count/AllFeildsCount)*100)

localStorage.setItem("profilecombinedper",count)
let finalValue=(count/AllFeildsCount)*100
if(finalValue>100){
  finalValue=100
}
if(finalValue!=="NAN"){

localStorage.setItem('profilecomlitionvalue', Math.round(finalValue))
}
     // localStorage.setItem("userProfileFieldWeight",JSON.stringify(data))
   }
  }
   
  updateAddressWeightage(key,value){
    
    console.log(value)
//alert()
    let AllFeildsCount=  parseInt(localStorage.getItem('AllFeildsCount'))
  let count=localStorage.getItem("profilecombinedper")
 // localStorage.setItem("profilecombinedper",parseInt(count)+1)
        
 let data= JSON.parse(localStorage.getItem("combinedweightvalueForAddress"))
if(key=="sameasabove"){
  
count=parseInt(count)+value
localStorage.setItem("profilecombinedper",count)

// alert(count)
// alert( (count/AllFeildsCount)*100)

let finalValue=(count/AllFeildsCount)*100
if(finalValue>100){
  finalValue=100
}
if(finalValue!=="NAN"){

localStorage.setItem('profilecomlitionvalue', Math.round(finalValue))
data[key]=value
localStorage.setItem("combinedweightvalueForAddress",JSON.stringify(data))
}
}
else if(key=="unCheckSameasabove")
{
  delete data[key];
  localStorage.setItem("combinedweightvalue",JSON.stringify(data))
count=parseInt(count)-value
// alert(count)
// alert( (count/AllFeildsCount)*100)
localStorage.setItem("profilecombinedper",count)
let finalValue=(count/AllFeildsCount)*100
if(finalValue>100){
  finalValue=100
}
if(finalValue!=="NAN"){

localStorage.setItem('profilecomlitionvalue', Math.round(finalValue))
}
}
else{
   if(!data.hasOwnProperty(key) && value!="")
   {

count=parseInt(count)+1
localStorage.setItem("profilecombinedper",count)

// alert(count)
// alert( (count/AllFeildsCount)*100)

let finalValue=(count/AllFeildsCount)*100
if(finalValue>100){
  finalValue=100
}
if(finalValue!=="NAN"){

localStorage.setItem('profilecomlitionvalue', Math.round(finalValue))
data[key]=value
localStorage.setItem("combinedweightvalueForAddress",JSON.stringify(data))  
}                                             // var percentComplete = parseInt((Object.keys(this.state.answerJSONList).length * 100) / resJSON.length)
   }
   else if(data[key]=="" && value!="")
   {

count=parseInt(count)+1

// alert(count)
// alert( (count/AllFeildsCount)*100)
localStorage.setItem("profilecombinedper",count)

let finalValue=(count/AllFeildsCount)*100
if(finalValue>100){
  finalValue=100
}
if(finalValue!=="NAN"){

localStorage.setItem('profilecomlitionvalue', Math.round(finalValue))
data[key]=value
localStorage.setItem("combinedweightvalueForAddress",JSON.stringify(data))
}                                               // var percentComplete = parseInt((Object.keys(this.state.answerJSONList).length * 100) / resJSON.length)
   }
   else if(data.hasOwnProperty(key) &&  value===""){
     // data.contactNo=e.target.value
    
      delete data[key];
      localStorage.setItem("combinedweightvalue",JSON.stringify(data))
count=parseInt(count)-1
// alert(count)
// alert( (count/AllFeildsCount)*100)
localStorage.setItem("profilecombinedper",count)
let finalValue=(count/AllFeildsCount)*100
if(finalValue>100){
  finalValue=100
}
if(finalValue!=="NAN"){

localStorage.setItem('profilecomlitionvalue', Math.round(finalValue))
}
     // localStorage.setItem("userProfileFieldWeight",JSON.stringify(data))
   }
  }
}
  //same as address
  assignPerAddressSameAsCores = event => {
    if (event.target.checked) {
      
      this.setState({
        perStreet: this.state.corStreet,
        perStreet2: this.state.corStreet2,
        perStreet3: this.state.corStreet3,
        perPostalCode: this.state.corPostalCode,
        percityId: this.state.corcityId,
        perstateId: this.state.corstateId,
        percountryId: this.state.corcountryId,
        checkAddressFlag: "checked"
      });
    } else {
      this.setState({
        perStreet: "",
        perStreet2: "",
        perStreet3:"",
        perPostalCode: "",
        percityId: "",
        perstateId: "",
        percountryId: "",
        checkAddressFlag: ""
      });
    }
  };

  render() {
  // alert(this.state.dob.split("-")[2])
    let title='1.Fresher: In case you are a Fresher, then the rest of the fields in the row will be disabled. 2.Employed: Mention name of the current company. 3.Self Employed: Mention legal entity name under which you are conducting business.In case no name registered, mention “Self”. 4.Not Employed: Mention name of the last company you were employed'
    var roledata = this.props.userRoleAccess;

    let dob_2_validate_message = "";
    let dob_3_validate_message = "";
    
 
    return (
      <Formik
        enableReinitialize={true}
      //   initialValues={{
      //     learnerPartyID: localStorage.getItem("userid-Token"), //4919,
      //     learnerID: this.props.personnelData.learnerID,
          
          
      //     peraddressproof1:this.props.personnelData.proofOfAddress,
          

      //     learnerQualification: this.props.personnelData.learnerQualification,
      //     mobileNo: this.props.personnelData.mobileNo,
      //     emailID: this.props.personnelData.emailID,
      //     gender: this.props.personnelData.gender,
      //     secondaryEmailID: this.props.personnelData.secondaryEmailID,
      //     dob: this.state.dob,
      //     firstName: this.props.personnelData.firstName,
      //     middleName: this.props.personnelData.middleName,
      //     lastName: this.props.personnelData.lastName,
      //     contactNo: this.props.personnelData.contactNo,
      //     panNumber: this.props.personnelData.panNumber,
      //     aadharNumber: this.props.personnelData.aadharNumber,
      //     perAddressType: this.state.perAddressType,
      //     perAddressID: this.state.perAddressID,
      //     perStreet: this.state.perStreet,
      //     perStreet2: this.state.perStreet2,
      //     perStreet3: this.state.perStreet3,
      //     perPostalCode: this.state.perPostalCode,
      //    // percityId: this.state.percityId,
      //  //   perstateId: this.state.perstateId,
      //    percityId: this.props.addressData[1].cityId,
      //      perstateId: this.props.addressData[1].stateId,
      //     percountryId: 1,
      //     //this.state.percountryId,
      //     corAddressType: this.state.corAddressType,
      //     corAddressID: this.state.corAddressID,
      //     corStreet: this.state.corStreet,
      //     corStreet2: this.state.corStreet2,
      //     corStreet3: this.state.corStreet3,
      //     corPostalCode: this.state.corPostalCode,
      //     corcityId: this.props.addressData[0].cityId,
      //     corstateId: this.props.addressData[0].stateId,
      //     corcountryId:1,
      //     // this.state.corcountryId,
      //     resumeDocumentURL: this.props.personnelData.resumeDocumentURL,
      //     resumeDocumentNameDuringEdit: this.props.personnelData.resumeDocumentURL?
      //      ( this.props.personnelData.resumeDocumentURL || ""
      //     ).substring(
      //       (this.props.personnelData.resumeDocumentURL || "").lastIndexOf(
      //         "/"
      //       ) + 1,
      //       (this.props.personnelData.resumeDocumentURL || "").length
      //     ):'',
   
      //     resumeDocumentNameDuringEditName:this.props.personnelData.resumeDocumentURL? (
      //       this.props.personnelData.resumeDocumentURL.split("?")[0]).substring(
      //         (this.props.personnelData.resumeDocumentURL || "").lastIndexOf(
      //           "/"
      //         ) + 1).replaceAll("%20"," ").replaceAll("%28","(").replaceAll("%29",")"):'',
      //     resumeVideoURL: this.props.personnelData.resumeVideoURL,
      //     profilePhotoURL: this.props.personnelData.profilePhotoURL,
      //     aadharCardURL: this.props.personnelData.aadharCardURL,
      //     BackaadharCardBackURL:this.props.personnelData.aadharCardBackURL,
      //     panCardURL: this.props.personnelData.panCardURL,


      //     panpdfName:this.props.personnelData.panCardURL? (
      //       this.props.personnelData.panCardURL.split("?")[0]).substring(
      //         (this.props.personnelData.panCardURL || "").lastIndexOf(
      //           "/"
      //         ) + 1).replaceAll("%20"," ").replaceAll("%28","(").replaceAll("%29",")"):'',
      //         addressProofName1:this.props.personnelData.proofOfAddressURL? (
      //           this.props.personnelData.proofOfAddressURL.split("?")[0]).substring(
      //             (this.props.personnelData.proofOfAddressURL || "").lastIndexOf(
      //               "/"
      //             ) + 1).replaceAll("%20"," ").replaceAll("%28","(").replaceAll("%29",")"):'',
      //         aadharpdfName:this.props.personnelData.aadharCardURL? (
      //           this.props.personnelData.aadharCardURL.split("?")[0]).substring(
      //             (this.props.personnelData.aadharCardURL || "").lastIndexOf(
      //               "/"
      //             ) + 1).replaceAll("%20"," ").replaceAll("%28","(").replaceAll("%29",")"):'',
      //             backaadharpdfName:this.props.personnelData.aadharCardBackURL? (
      //               this.props.personnelData.aadharCardBackURL.split("?")[0]).substring(
      //                 (this.props.personnelData.aadharCardBackURL || "").lastIndexOf(
      //                   "/"
      //                 ) + 1).replaceAll("%20"," ").replaceAll("%28","(").replaceAll("%29",")"):'',


      //                 aadharCardBackURL:this.props.personnelData.aadharCardBackURL,
      //     learnerFatherName: this.props.personnelData.learnerFatherName,
      //     learnerFatherDOB: this.state.learnerFatherDOB,
      //     learnerFatherEmployementType: this.props.personnelData
      //       .learnerFatherEmployementType,
      //     learnerFatherCompanyName: this.props.personnelData
      //       .learnerFatherCompanyName,
      //     learnerMotherName: this.props.personnelData.learnerMotherName,
      //     learnerMotherDOB: this.state.learnerMotherDOB,
      //     learnerMotherEmployementType: this.props.personnelData
      //       .learnerMotherEmployementType,
      //     learnerMotherCompanyName: this.props.personnelData
      //       .learnerMotherCompanyName,
      //     checkAddressFlag: this.state.checkAddressFlag,
      //     document: [],
      //     resumeDocumentName: "",
      //     panCardDocumentName: "",
      //     aadharCardDocumentName: "",
      //     documentSizeCheckForProfileCheck: false,
      //     documentTypeCheckForProfileImage: false,
      //     documentTypeCheckForResume: false,
      //     documentSizeCheckForResume: false,
      //     documentTypeCheckForAadharImage: false,
      //     documentSizeCheckForVideoCheck: false,
      //     documentSizeCheckForPanCard: false,
      //     documentSizeCheckForAadharCard: false,
      //     documentTypeCheckForPANImage: false,
      //     documentTypeCheckForVideo: false,
      //     countryList: this.state.countryList,
      //     countryListForCors: this.state.countryListForCors,
      //     stateListForCor: this.state.stateListForCor,
      //     cityListForCor: this.state.cityListForCor,
      //     stateListForPer: this.state.stateListForPer,
      //     cityListForPer: this.state.cityListForPer,
      //     addressFlag: this.state.addressFlag,
      //     downloadResumeAfterSelecting: "",
      //     whoareu: this.props.personnelData.learnerType,
      //     bankName: this.props.personnelData.bankName,
      //     branch: this.props.personnelData.branchName,
      //     branchAddress: this.props.personnelData.branchAddress,
      //     accountType: this.props.personnelData.accountType,
      //     acccountNumber: this.props.personnelData.accountNumber,

        
      //     ARNNO:this.props.personnelData.arn,
      //     NameOfARNHolder: this.props.personnelData.nameOfARNHolder,
         
        
      //     passportNumber: this.props.personnelData.passportNumber,

      //     fatherName: this.props.personnelData.learnerFatherName,
      //     salutation: this.props.personnelData.salutation,
         
      //     professionalQualification: this.props.personnelData.learnerProfessionalQualification,
      //     ARNuserFeilds: this.props.personnelData.nameOfMutualFundEmpanelled,
      //     CPEDetails: this.props.personnelData.learnerCpeDetails,
      //     addressProofCardURL:this.props.personnelData.proofOfAddressURL,
      //     ifscCode:this.props.personnelData.ifscCode,
      //     //this.props.personnelData.proofOfAddressURL,
      //     //this.props.personnelData.proofOfAddressURL,
      //     submitStatus:'',
      //     profileCompletion: "26",
      //     edudocument:[],
      //     PanValidation:false,
      //     ARNEUINNO: this.props.personnelData.euinNumber,
      //     applicationStatus:this.props.personnelData.applicationStatus,
      //     currentProfession:this.props.personnelData.learnerProfession,
      //     sameasAboveCount:0,
      //     disabledForCielops:localStorage.getItem('roleId')==6 || localStorage.getItem('roleId')==7?true:false,
      //     currProfessionList:this.props.personnelData.learnerType=="1013" ||this.props.personnelData.learnerType=="1015"?currProf: [],
      //     disablecurreProf:this.props.personnelData.learnerType=="1013" ||this.props.personnelData.learnerType=="1015"?false: true,
      //     nameOfCurrProf:this.props.personnelData.learnerType=="1015"?"Last Profession":"Current Profession",
      //     disabledprofDetails:false
      //    // event.target.value=="1013" || event.target.value=="1015"
      //   }}



        initialValues={{
          learnerPartyID: localStorage.getItem("userid-Token"), //4919,
          learnerID: this.props.personnelData.learnerID,
          
          
          peraddressproof1:this.props.personnelData.proofOfAddress,
          

          learnerQualification: this.props.personnelData.learnerQualification,
          mobileNo: this.props.personnelData.mobileNo,
          emailID: this.props.personnelData.emailID,
          gender: this.props.personnelData.gender,
          secondaryEmailID: this.props.personnelData.secondaryEmailID,
          dob: this.state.dob,
          year:this.state.dob!==undefined?this.state.dob.split("-")[0]:'',
          day:this.state.dob!==undefined?parseInt(this.state.dob.split("-")[2],10):'',
          month:this.state.dob!==undefined?parseInt(this.state.dob.split("-")[1],10):'',
          firstName: this.props.personnelData.firstName,
          middleName: this.props.personnelData.middleName,
          lastName: this.props.personnelData.lastName,
          contactNo: this.props.personnelData.contactNo,
          panNumber: this.props.personnelData.panNumber,
          aadharNumber: this.props.personnelData.aadharNumber,
          collegeName: this.props.personnelData.collegeName === "undefined" ?'': this.props.personnelData.collegeName,
          //collegeName: this.props.personnelData.collegeName?this.props.personnelData.collegeName:'',
         // isFinXInternship: this.props.personnelData.isFinXInternship,
          isFinXInternship: this.props.personnelData.isFinXInternship === "undefined" ?'':this.props.personnelData.isFinXInternship,
          perAddressType: this.state.perAddressType,
          perAddressID: this.state.perAddressID,
          perStreet: this.state.perStreet,
          perStreet2: this.state.perStreet2,
          perStreet3: this.state.perStreet3,
          perPostalCode: this.state.perPostalCode,
         // percityId: this.state.percityId,
       //   perstateId: this.state.perstateId,
          percityId: (this.state.perStreet=== "" || this.state.perStreet=== undefined) ? "" : this.props.addressData[1].cityId,
          perstateId: (this.state.perStreet=== "" || this.state.perStreet=== undefined) ? "" : this.props.addressData[1].stateId,
        // percityId: this.props.addressData[1].cityId,
          // perstateId: this.props.addressData[1].stateId,
          percountryId: 1,
          //this.state.percountryId,
          corAddressType: this.state.corAddressType,
          corAddressID: this.state.corAddressID,
          corStreet: this.state.corStreet,
          corStreet2: this.state.corStreet2,
          corStreet3: this.state.corStreet3,
          corPostalCode: this.state.corPostalCode,
          corcityId: this.props.addressData[0].cityId,
          corstateId: this.props.addressData[0].stateId,
          corcountryId:1,
          // this.state.corcountryId,
          resumeDocumentURL: this.props.personnelData.resumeDocumentURL,
          resumeDocumentNameDuringEdit: this.props.personnelData.resumeDocumentURL?
           ( this.props.personnelData.resumeDocumentURL || ""
          ).substring(
            (this.props.personnelData.resumeDocumentURL || "").lastIndexOf(
              "/"
            ) + 1,
            (this.props.personnelData.resumeDocumentURL || "").length
          ):'',
         
          resumeDocumentNameDuringEditName:(this.replaceAll(this.replaceAll(this.replaceAll(this.props.personnelData.resumeDocumentURL? (
            this.props.personnelData.resumeDocumentURL.split("?")[0]).substring(
              (this.props.personnelData.resumeDocumentURL || "").lastIndexOf(
                "/"
              ) + 1)
             
              :'',"%29",""),"%28",""),"%20","") ),
          // this.props.personnelData.resumeDocumentURL? (
          //   this.props.personnelData.resumeDocumentURL.split("?")[0]).substring(
          //     (this.props.personnelData.resumeDocumentURL || "").lastIndexOf(
          //       "/"
          //     ) + 1)
          //     // .replaceAll("%20"," ").replaceAll("%28","(").replaceAll("%29",")")
          //     :'',
          resumeVideoURL: this.props.personnelData.resumeVideoURL,
          profilePhotoURL: this.props.personnelData.profilePhotoURL,
          aadharCardURL: this.props.personnelData.aadharCardURL,
          BackaadharCardBackURL:this.props.personnelData.aadharCardBackURL,
          panCardURL: this.props.personnelData.panCardURL,


          panpdfName:(this.replaceAll(this.replaceAll(this.replaceAll(this.props.personnelData.panCardURL? (
            this.props.personnelData.panCardURL.split("?")[0]).substring(
              (this.props.personnelData.panCardURL || "").lastIndexOf(
                "/"
              ) + 1)
             
              :'',"%29",""),"%28",""),"%20","") ),
          // this.props.personnelData.panCardURL? (
          //   this.props.personnelData.panCardURL.split("?")[0]).substring(
          //     (this.props.personnelData.panCardURL || "").lastIndexOf(
          //       "/"
          //     ) + 1)
          //     // .replaceAll("%20"," ").replaceAll("%28","(").replaceAll("%29",")")
          //     :'',
              addressProofName1:(this.replaceAll(this.replaceAll(this.replaceAll(this.props.personnelData.proofOfAddressURL? (
                this.props.personnelData.proofOfAddressURL.split("?")[0]).substring(
                  (this.props.personnelData.proofOfAddressURL || "").lastIndexOf(
                    "/"
                  ) + 1)
                 
                  :'',"%29",""),"%28",""),"%20","") ),
              // this.props.personnelData.proofOfAddressURL? (
              //   this.props.personnelData.proofOfAddressURL.split("?")[0]).substring(
              //     (this.props.personnelData.proofOfAddressURL || "").lastIndexOf(
              //       "/"
              //     ) + 1)
              //     // .replaceAll("%20"," ").replaceAll("%28","(").replaceAll("%29",")")
              //     :'',

              aadharpdfName:(this.replaceAll(this.replaceAll(this.replaceAll(this.props.personnelData.aadharCardURL? (
                this.props.personnelData.aadharCardURL.split("?")[0]).substring(
                  (this.props.personnelData.aadharCardURL || "").lastIndexOf(
                    "/"
                  ) + 1)
                 
                  :'',"%29",""),"%28",""),"%20","") ),

                  // aadharpdfName:this.props.personnelData.aadharCardURL?(this.props.personnelData.aadharCardURL.split("?")[0]).substring(
                  //   (this.props.personnelData.aadharCardURL || "").lastIndexOf(
                  //     "/"
                  //   ) + 1):'',
                
              // this.props.personnelData.aadharCardURL? (
              //   this.props.personnelData.aadharCardURL.split("?")[0]).substring(
              //     (this.props.personnelData.aadharCardURL || "").lastIndexOf(
              //       "/"
              //     ) + 1)
              //     // .replaceAll("%20"," ").replaceAll("%28","(").replaceAll("%29",")")
              //     :'',
                  backaadharpdfName:(this.replaceAll(this.replaceAll(this.replaceAll(this.props.personnelData.aadharCardBackURL? (
                    this.props.personnelData.aadharCardBackURL.split("?")[0]).substring(
                      (this.props.personnelData.aadharCardBackURL || "").lastIndexOf(
                        "/"
                      ) + 1)
                     
                      :'',"%29",""),"%28",""),"%20","") ),
                  // this.props.personnelData.aadharCardBackURL? (
                  //   this.props.personnelData.aadharCardBackURL.split("?")[0]).substring(
                  //     (this.props.personnelData.aadharCardBackURL || "").lastIndexOf(
                  //       "/"
                  //     ) + 1)
                  //     // .replaceAll("%20"," ").replaceAll("%28","(").replaceAll("%29",")")
                  //     :'',


                      aadharCardBackURL:this.props.personnelData.aadharCardBackURL,
          learnerFatherName: this.props.personnelData.learnerFatherName,
          learnerFatherDOB: this.state.learnerFatherDOB,
          learnerFatherEmployementType: this.props.personnelData
            .learnerFatherEmployementType,
          learnerFatherCompanyName: this.props.personnelData
            .learnerFatherCompanyName,
          learnerMotherName: this.props.personnelData.learnerMotherName,
          learnerMotherDOB: this.state.learnerMotherDOB,
          learnerMotherEmployementType: this.props.personnelData
            .learnerMotherEmployementType,
          learnerMotherCompanyName: this.props.personnelData
            .learnerMotherCompanyName,
          checkAddressFlag: this.state.checkAddressFlag,
          document: [],
          resumeDocumentName: "",
          panCardDocumentName: "",
          aadharCardDocumentName: "",
          documentSizeCheckForProfileCheck: false,
          documentTypeCheckForProfileImage: false,
          documentTypeCheckForResume: false,
          documentSizeCheckForResume: false,
          documentTypeCheckForAadharImage: false,
          documentSizeCheckForVideoCheck: false,
          documentSizeCheckForPanCard: false,
          documentSizeCheckForAadharCard: false,
          documentTypeCheckForPANImage: false,
          documentTypeCheckForVideo: false,
          countryList: this.state.countryList,
          countryListForCors: this.state.countryListForCors,
          stateListForCor: this.state.stateListForCor,
          cityListForCor: this.state.cityListForCor,
          stateListForPer: this.state.stateListForPer,
          cityListForPer: this.state.cityListForPer,
          addressFlag: this.state.addressFlag,
          downloadResumeAfterSelecting: "",
          whoareu: this.props.personnelData.learnerType,
          bankName: this.props.personnelData.bankName,
          branch: this.props.personnelData.branchName,
          branchAddress: this.props.personnelData.branchAddress,
          accountType: this.props.personnelData.accountType,
          acccountNumber: this.props.personnelData.accountNumber,

        
          ARNNO:this.props.personnelData.arn,
          NameOfARNHolder: this.props.personnelData.nameOfARNHolder,
         
        
          passportNumber: this.props.personnelData.passportNumber,

          fatherName: this.props.personnelData.learnerFatherName,
          salutation: this.props.personnelData.salutation,
         
          professionalQualification: this.props.personnelData.learnerProfessionalQualification,
          ARNuserFeilds: this.props.personnelData.nameOfMutualFundEmpanelled,
          CPEDetails: this.props.personnelData.learnerCpeDetails,
          addressProofCardURL:this.props.personnelData.proofOfAddressURL,
          ifscCode:this.props.personnelData.ifscCode,
          //this.props.personnelData.proofOfAddressURL,
          //this.props.personnelData.proofOfAddressURL,
          submitStatus:'',
          profileCompletion: "26",
          edudocument:[],
          PanValidation:false,
          ARNEUINNO: this.props.personnelData.euinNumber,
          applicationStatus:this.props.personnelData.applicationStatus,
          currentProfession:this.props.personnelData.learnerProfession,
          sameasAboveCount:0,
          disabledForCielops:localStorage.getItem('roleId')==6 || localStorage.getItem('roleId')==7?true:false,
          currProfessionList:this.props.personnelData.learnerType=="1013" ||this.props.personnelData.learnerType=="1015"?currProf: [],
          disablecurreProf:this.props.personnelData.learnerType=="1013" ||this.props.personnelData.learnerType=="1015"?false: true,
          nameOfCurrProf:this.props.personnelData.learnerType=="1015"?"Last Profession":"Current Profession",
          disabledprofDetails:false,
          listOfMonth:this.state.listOfMonth,
          listOfDay:this.state.listOfDay,
          tolistOfDay:this.state.listOfDay,
          tolistOfMonth:this.state.listOfMonth,

          fromlistOfDay:this.state.listOfDay,
          fromlistOfMonth:this.state.listOfMonth

         // event.target.value=="1013" || event.target.value=="1015"
        }}





        validationSchema={() => Yup.lazy((values) => {
          const isCPF = values.submitStatus === 'SUBMITTED'
          return isCPF? Yup.object().shape({
           
            
            // whoareu: Yup.string()
            // .required("You must select a who are you field"),
            fatherName: Yup.string()
            .matches(/^[a-zA-Z ]{1,50}$/, TextValidationMesg+' and upto 50 letter')

            .required("You must specify a Father's Name"),
            ifscCode:Yup.string()
            .matches(/^[A-Z]{4}0[A-Z0-9]{6}$/, 'You must enter valid IFSC code with capital letters')

            .required("You must specify a IFSC code"),
           // .matches(/^[a-zA-Z ]{1,15}$/, TextValidationMesg+' and upto 15 letter'),
            middleName: Yup.string()
            .matches(/^[a-zA-Z]{1,50}$/, TextValidationMesg+' and upto 50 letter. Space not allowed'),
            firstName: Yup.string().required("You must specify a First Name")
              .matches(/^[a-zA-Z]{1,50}$/, TextValidationMesg+' and upto 50 letter. Special characters not allowed'),
            lastName: Yup.string().required("You must specify a Last Name")
              .matches(/^[a-zA-Z]{1,50}$/, TextValidationMesg+' and upto 50 letter. Special characters allowed'),
            emailID: Yup.string()
              .required("You must specify a Email ID")
              .email("Please enter the valid email ID"),
            mobileNo: Yup.string()
              .required("You must specify a Mobile Number")
              .matches(/^[6-9]\d{9}$/, "Please enter the valid mobile number"),
            panNumber: Yup.string()
          .required("You must specify a Pan Number")
            
            .matches(
              /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/,
              "Please enter the valid PAN number with capital letters"
            ),

            // .when("PanValidation", {
            //   is: true,
            //   then: Yup.string().required("You must specify a Pan Number")
            // })
            salutation: Yup.string(),
         //   .required("You must select a salutation"),
            // aadharNumber: Yup.string()
            //   .required("You must specify a Aadhaar number")
            //   .matches(/^\d{12}$/, "Please enter the valid Aadhaar number"),

            // dob: Yup.string()
            //   .required("Date of Birth is required")
            //   .test(
            //     "dob",
            //     "Birth Date should be greater then 18 years",
            //     value => {
            //       let isvalid = moment().diff(moment(value), "years") >= 18;
            //       return isvalid;
            //     }
            //   ),

            // learnerFatherDOB: Yup.lazy(key => {
            //   return Yup.string()
            //     .default(() => "")
            //     .when("dob", (dob, schema) => {
                  
            //       return schema.test({
            //         test: EndIntensity => !!dob && EndIntensity < dob,
            //         message:
            //           "Father birth date should be greater than learner birth date"
            //       });
            //     });
            // }),
            // learnerMotherDOB: Yup.lazy(key => {
            //   return Yup.string()
            //     .default(() => "")
            //     .when("dob", (dob, schema) => {
            //       return schema.test({
            //         test: EndIntensity => !!dob && EndIntensity < dob,
            //         message:
            //           "Mother birth date should be greater than learner birth date"
            //       });
            //     });
            // }),

            gender: Yup.string().required("You must specify a Gender"),
            contactNo: Yup.string().matches(
              /^[6-9]\d{9}$/,
              "Please enter the valid mobile number"
            ),
            secondaryEmailID: Yup.string().email(
              "Please enter the valid email ID"
            ),
            corStreet: Yup.string().required("You must specify a Address"),
            corStreet2: Yup.string(),
            corcityId: Yup.string().required("You must specify a City"),
            corstateId: Yup.string().required("You must specify a State"),
            corcountryId: Yup.string().required("You must specify a Country"),
            corPostalCode: Yup.string()
              .required("You must specify a PIN Code")
              .matches(/^[0-9]\d{5}$/, "Please enter the valid PIN code"),
            perStreet: Yup.string().required("You must specify a Address"),
            perStreet2: Yup.string(),
            percountryId: Yup.string().required("You must specify a Country"),
            percityId: Yup.string().required("You must specify a City"),
            perstateId: Yup.string().required("You must specify a State"),
           perPostalCode: Yup.string()
              .required("You must specify a PIN Code")
              .matches(/^[0-9]\d{5}$/, "Please enter the valid PIN code"),
            passportNumber: Yup.string()

              .matches(/^(?!^0+$)[a-zA-Z0-9]{6,9}$/, "Please enter the valid passport number"),
            branch: Yup.string()

              .required("You must specify a branch"),
             // .matches(/^[a-zA-Z ]{1,180}$/, TextValidationMesg),
            branchAddress: Yup.string()
              .required("You must specify a branch address"),
            accountType: Yup.string()
              .required("You must specify a account type"),
            acccountNumber: Yup.string()
              .matches(/^[0-9]{9,18}$/, "Please enter valid account Number")
              .required("You must specify a acccount number"),
            bankName: Yup.string()
              .required("You must specify a bank name"),
             // .matches(/^[a-zA-Z ]{1,180}$/, TextValidationMesg),
              peraddressproof1:Yup.string()
              .required("You must specify a address proof"),
            // professionalQualification: Yup.array()
            //   .of(
            //     Yup.object().shape({
            //       occupation: Yup.string().required('Required'),
            //       companyName: Yup.string().required('Required')
            //         .matches(/^[a-zA-Z ]{1,180}$/, TextValidationMesg),
            //       annualIncome: Yup.string().required('Required'),

            //     })
            //  ),
            // CPEDetails: Yup.array()
            //   .of(
            //     Yup.object().shape({
            //       passingCertificateNo: Yup.string()
            //         .matches(/^[a-zA-Z0-9 ]{1,180}$/, "Passing Certificate Number should be alphanumric"),
            //       dateOfPassingTest: Yup.string(),
            //       //  .matches(/^[a-zA-Z ]{1,180}$/,TextValidationMesg),
            //       cpeCertificateNo: Yup.string()
            //         .matches(/^[a-zA-Z0-9 ]{1,180}$/, "CPE Certificate Number should be alphanumric"),
            //       dateOfCpe: Yup.string(),

            //     })
             // ),  
              ARNNO: Yup.string()
              .matches(/^[a-zA-Z0-9 ]{1,15}$/, "you must specify 15 digit number only"),
              NameOfARNHolder: Yup.string()
              .matches(/^[a-zA-Z ]{1,15}$/, TextValidationMesg),
              ARNEUINNO:Yup.string()
              .matches(/^[a-zA-Z0-9 ]{1,15}$/, "you must specify 15 digit number only")
          }):
          Yup.object().shape({
            ifscCode:Yup.string()
            .matches(/^[A-Z]{4}0[A-Z0-9]{6}$/, 'You must enter valid IFSC code with capital letters'),
            NameOfARNHolder: Yup.string()
            .matches(/^[a-zA-Z ]{1,15}$/, TextValidationMesg),
            ARNEUINNO:Yup.string()
            .matches(/^[a-zA-Z0-9 ]{1,15}$/, "you must specify 15 digit number only"),
            ARNNO: Yup.string()
            .matches(/^[a-zA-Z0-9 ]{1,15}$/, "you must specify 15 digit number only"),
            professionalQualification: Yup.array()
              .of(
                Yup.object().shape({
                  occupation: Yup.string(),
                  companyName: Yup.string()
                    .matches(/^[a-zA-Z ]{1,180}$/, TextValidationMesg),
                  annualIncome: Yup.string(),
                  // these constraints take precedence
                  //salary: Yup.string().min(3, 'cmon').required('Required'), // these constraints take precedence
                })
              ),
            // CPEDetails: Yup.array()
            //   .of(
            //     Yup.object().shape({
            //       passingCertificateNo: Yup.string()
            //         .matches(/^[a-zA-Z0-9 ]{1,180}$/, "Passing Certificate Number should be alphanumric"),
            //       dateOfPassingTest: Yup.string(),
            //       //  .matches(/^[a-zA-Z ]{1,180}$/,TextValidationMesg),
            //       cpeCertificateNo: Yup.string()
            //         .matches(/^[a-zA-Z0-9 ]{1,180}$/, "CPE Certificate Number should be alphanumric"),
            //       dateOfCpe: Yup.string(),
            //       // .matches(/^[a-zA-Z ]{1,180}$/,TextValidationMesg),  
            //       // these constraints take precedence
            //       //salary: Yup.string().min(3, 'cmon').required('Required'), // these constraints take precedence
            //     })
            //   ),
            middleName: Yup.string()
            .matches(/^[a-zA-Z]{1,50}$/, TextValidationMesg+' and upto 50 letter. Space not allowed'),
              fatherName: Yup.string()
              .matches(/^[a-zA-Z]{1,50}$/, TextValidationMesg+' and upto 50 letter. Space not allowed'),
  
            firstName: Yup.string()
              .matches(/^[a-zA-Z]{1,50}$/, TextValidationMesg+' and upto 50 letter. Special characters not allowed'),
            lastName: Yup.string().required("You must specify a Last Name")
              .matches(/^[a-zA-Z]{1,50}$/, TextValidationMesg+' and upto 50 letter.  Special characters not allowed'),

            emailID: Yup.string().email("Please enter the valid email ID"),
            mobileNo: Yup.string()

              .matches(/^[6-9]\d{9}$/, "Please enter the valid mobile number"),
            panNumber: Yup.string().matches(
              /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/,
              "Please enter the valid PAN number with capital letters"
            ),
            // aadharNumber: Yup.string()
            //   .matches(/^\d{12}$/, "Please enter the valid Aadhaar number"),
            // dob: Yup.string()

            //   .test(
            //     "dob",
            //     "Birth Date should be greater then 18 years",
            //     value => {
            //       let isvalid = moment().diff(moment(value), "years") >= 18;
            //       return isvalid;
            //     }
            //   ),

            // learnerFatherDOB: Yup.lazy(key => {
            //   return Yup.string()
            //     .default(() => "")
            //     .when("dob", (dob, schema) => {
            //       return schema.test({
            //         test: EndIntensity => !!dob && EndIntensity < dob,
            //         message:
            //           "Father birth date should be greater than learner birth date"
            //       });
            //     });
            // }),
            // learnerMotherDOB: Yup.lazy(key => {
            //   return Yup.string()
            //     .default(() => "")
            //     .when("dob", (dob, schema) => {
            //       return schema.test({
            //         test: EndIntensity => !!dob && EndIntensity < dob,
            //         message:
            //           "Mother birth date should be greater than learner birth date"
            //       });
            //     });
            // }),

            gender: Yup.string(),
            contactNo: Yup.string().matches(
              /^[6-9]\d{9}$/,
              "Please enter the valid mobile number"
            ),
            secondaryEmailID: Yup.string().email(
              "Please enter the valid email ID"
            ),
            // corStreet: Yup.string(),
            // corStreet2: Yup.string(),
            // corcityId: Yup.string(),
            // corstateId: Yup.string(),
            // corcountryId: Yup.string(),
            corPostalCode: Yup.string()
              .matches(/^[0-9]\d{5}$/, "Please enter the valid PIN code"),
            // perStreet: Yup.string(),
            // perStreet2: Yup.string(),
            // percityId: Yup.string(),
            // perstateId: Yup.string(),
            // percountryId: Yup.string(),
            perPostalCode: Yup.string()
              .matches(/^[0-9]\d{5}$/, "Please enter the valid PIN code"),
            passportNumber: Yup.string()

              .matches(/^(?!^0+$)[a-zA-Z0-9]{6,9}$/, "Please enter the valid passport number"),
            branch: Yup.string(),
          //  .matches(/^[a-zA-Z ]{1,180}$/, TextValidationMesg),

        
            branchAddress: Yup.string(),
            accountType: Yup.string(),
           
            acccountNumber: Yup.string()
              .matches(/^[0-9]{9,18}$/, "Please enter valid account Number")
            ,

            bankName: Yup.string()

             // .matches(/^[a-zA-Z ]{1,180}$/, TextValidationMesg)
          })
        })}
  
        
        onSubmit={values => {
         
          let DOB = values['day'] + "/" + values['month'] + "/" + values['year'] + " " + "00:00:00"
          let DOB1 = values['year'] + "-" + values['month'] + "-" + values['day'] 
          let fromDate1 = ""
          if ((values['year'] === undefined || values['year'] === "")|| (values['month'] === undefined || values['month'] === "") || (values['day'] === undefined || values['day'] === "")) {
          warning("Please Select valid date of birth",warningNotification)
          } else {
         

          
            values.professionalQualification.map(ele => {

              let fromDate1=""
              if(( ele.fromYear!==undefined &&  ele.fromYear!=="0") && (ele.fromMonth!==undefined && ele.fromMonth!=="0") && (ele.fromDay!==undefined &&ele.fromDay!=="0")){
                fromDate1=ele.fromDay + "/" + ele.fromMonth + "/" + ele.fromYear +" "+"00:00:00"
             
              }else{
                 fromDate1=""
                
              }
             
              let toDate1=""
              if(( ele.toyear!==undefined &&  ele.toyear!=="0") && (ele.toMonth!==undefined && ele.toMonth!=="0") && (ele.toDay!==undefined && ele.toDay!=="0")){
               toDate1=ele.toDay + "/" + ele.toMonth + "/" + ele.toyear+" "+"00:00:00"
  
             
                               }else{
                                 toDate1=""
                                 
                               } 
                              

                                 
                               
  if(fromDate1!==""  && toDate1!==""){
    let fromDate=  fromDate1
    let toDate=  toDate1
    ele.fromDate=fromDate
    ele.toDate=toDate
  }else{
    ele.fromDate= ele.fromDate
    ele.toDate= ele.toDate
  }
           
            
             });
let isValidDOB=this.isValidDate(DOB1,"")

         if(isValidDOB)
         {
          
          // this.setState({ loading1: "block" });
         let valData=[]
         let docData=[]
         let docData1=[]
         let docData2=[]
         let doc_temp=[]
         let doc_temp1=[]
         let doc_temp2=[]
         let doc_temp3=[]
         let doc_temp4=[]
         let eduQualificationvalData=[]
      
        
       let isValidflag=true
       if(values.panNumber!==undefined )
       {
        LoginService.TokenUser(res => {
                                               
                                           
          localStorage.setItem(
            "jwt-token",
            res.responseObject
          );
        });
      // RegisterUserService.checkPAN(
      //   "learnerID=" +
      //   this.props.personnelData
      //     .learnerID +
      //   "&panNumber=" +
      // values.panNumber +
      //   "&aadharNumber="+"&learnerPartyID="+localStorage.getItem("userid-Token"),
      //   res => {
      //     if (res.status === "fail") {
      
      //       error(res.reasonText, {
      //         ...errorNotification
      //        // container: "top-center"
      //       });
      //       isValidflag=false

      //     }
        
       
      // }
    
      // );
    
    }
      if(values.aadharNumber!==undefined)
      {
      // RegisterUserService.checkPAN(
      //   "learnerID=" +
      //   this.props.personnelData
      //     .learnerID +
      //   "&panNumber=" +
      //   "&aadharNumber=" +
      //   values.aadharNumber+"&learnerPartyID="+localStorage.getItem("userid-Token"),
      //   res => {
      //     if (res.status === "fail") {
           
      //       error(res.reasonText, {
      //         ...errorNotification
      //      //   container: "top-center"
      //       });

      //       isValidflag=false
      //     }
      //   }
      // );
    
    }
      if(isValidflag){
        
          if (values.submitStatus==="SUBMITTED" && values.hasOwnProperty('professionalQualification') && values.professionalQualification !== "" && values.professionalQualification !== 0) {
            if( values.professionalQualification.length>0){
           values.professionalQualification.map(ele => {
             if(ele.occupation=="1021")
             {
              valData.push(ele.occupation)
              valData.push("test")
              valData.push("test")
              valData.push("test")
             }else{
             //  alert(ele.totalExperience)
            valData.push(ele.occupation)
            valData.push(ele.companyName)
            valData.push(ele.annualIncome)
            valData.push(ele.totalExperience)
             }
            
            });
          }
          else{
            valData.push("")
            valData.push("")
            valData.push("")
            valData.push("")
          }
          }
          
          if (values.submitStatus==="SUBMITTED" && values.hasOwnProperty('learnerQualification') && values.learnerQualification !== "" && values.learnerQualification !== 0) {
            if( values.learnerQualification.length>0){
            values.learnerQualification.map(ele => {
             
   //let doc=ele.hasOwnProperty('document') && ele.document!=null ?ele.document[0].documentHash:ele.educationQualificationDocumentURL
   //      eduQualificationvalData.push(doc)
             eduQualificationvalData.push(ele.instituteName)
             eduQualificationvalData.push(ele.passingYear)
             eduQualificationvalData.push(ele.percentage)
             eduQualificationvalData.push(ele.qualificationType)
            
             
             });
            }else{
              eduQualificationvalData.push("")
              eduQualificationvalData.push("")
              eduQualificationvalData.push("")
              eduQualificationvalData.push("") 
            }
           }
          if(values.submitStatus==="SUBMITTED" && (values.addressProofCardURL===undefined || values.panCardURL===undefined || values.aadharCardURL===undefined || values.aadharCardBackURL===undefined))
{
  // && values.whoareu!=="1011"
  if(values.document.length>0)
  {
  for(let i=0;i<values.document.length;i++){

    
  
 if(values.document[i].documentType=="AddressProof")
 {
   doc_temp3.push(values.document[i])
 }
//  else{
//   docData.push('requiredAddressProof')
//  }
  if(values.document[i].documentType=="PanCard" )
  {
    doc_temp.push(values.document[i])

  }
 
  // if(values.document[i].documentType=="AadharCard")
  // {
  //   doc_temp1.push(values.document[i])

  // }
  // if(values.document[i].documentType=="AadharCardBackSide")
  // {
  //   doc_temp2.push(values.document[i])

  // }

  if(values.document[i].documentType=="ProfilePhoto")
  {
    doc_temp4.push(values.document[i])

  }

// else{
//   docData.push('requiredPanCard')
// }
  };
}
// else{
//   doc_temp.push(values.document[{}])
// }

}

 if(doc_temp.length==0)
   

   docData.push('requiredPanCard')
 
//}
// if(doc_temp1.length==0){
 
//   docData1.push('requiredAadharCard')

// }
if(doc_temp3.length==0){
 
  docData.push('requiredAddressProof')

}

// if(doc_temp2.length==0){
 
  
//   docData1.push('requiredAadharCardBackSide')
 

// }
if(doc_temp4.length==0){
 
  
  docData2.push('requiredProilePhoto')
 

}

let pan=values.panCardURL===undefined && values.whoareu!==1011?true:false

console.log(valData.includes(undefined))
if(valData.includes(undefined)===true || valData.includes("")==true )
{
  
       warning("Please select mandatory Fields of Professsional Qualification", warningNotification);

    
}
//|| pan
else if(values.submitStatus==="SUBMITTED" && (values.addressProofCardURL===undefined  ) && docData.length>0)
{

 if(docData.length>0){
  if(docData.includes("requiredPanCard") && docData.includes("requiredAddressProof")){
    //warning("Please upload PAN card and Address Proof documents", warningNotification);
    warning("Please upload all required documents", warningNotification)
  }
 
  else if(docData.includes("requiredPanCard"))
  {
    warning("Please upload all required documents", warningNotification)
 //   warning("Please upload PAN card document", warningNotification);
  }
  else if(docData.includes("requiredAddressProof")){
    warning("Please upload all required documents", warningNotification)
  //  warning("Please upload Address Proof", warningNotification);
  }
 
  // else if(docData.includes("requiredPanCard") && docData.includes("requiredAddressProof")){
  //   warning("Please upload PAN card and Address Proof documents", warningNotification);

  // }
  
}
else{
  warning("Please upload all required documents", warningNotification)
}
}

// else if(values.submitStatus==="SUBMITTED" && (values.aadharCardURL===undefined || values.aadharCardBackURL===undefined) && docData1.length>0)
// {

//  if(docData1.length>0){
  
//   if(docData1.includes("requiredAadharCardBackSide") && docData1.includes("requiredAadharCard")){
//    // warning("Please upload Aadhaar back part and Aadhaar front part documents", warningNotification);
//    warning("Please upload all required documents", warningNotification)
//   }
 
//   else if(docData1.includes("requiredAadharCardBackSide")){
//     warning("Please upload all required documents", warningNotification)
//  //   warning("Please upload  Aadhaar back part", warningNotification);
//   }
//   else if(docData1.includes("requiredAadharCard")){
//     warning("Please upload all required documents", warningNotification)
//     //warning("Please upload  Aadhaar front part", warningNotification);
//   }
//   // else if(docData.includes("requiredPanCard") && docData.includes("requiredAddressProof")){
//   //   warning("Please upload PAN card and Address Proof documents", warningNotification);

//   // }
  
// }
// else{
//   warning("Please upload all required documents", warningNotification)
// }
// }
else if(values.submitStatus==="SUBMITTED" && (values.profilePhotoURL===undefined ) && docData2.length>0)
{

 if(docData2.length>0){
  
  if(docData2.includes("requiredProilePhoto") ){
   // warning("Please upload Aadhaar back part and Aadhaar front part documents", warningNotification);
   warning("Please upload all required documents", warningNotification)
  }
 
 
  
}
else{
  warning("Please upload all required documents", warningNotification)
}
}
else if(eduQualificationvalData.includes(undefined)===true || eduQualificationvalData.includes("")==true )
{

  warning("please fill mandatory Fields of Educational Qualification", warningNotification);

}
else{
         if (values.hasOwnProperty('learnerQualification') && values.learnerQualification !== "") {
          values.learnerQualification.map(ele => {
            delete ele.learnerQualificationID;
           // delete ele.educationQualificationDocumentId
          });
        }
            // if (values.edudocument) {
            //   if (values.hasOwnProperty('learnerQualification') && values.learnerQualification !== "" && values.learnerQualification.length !=0) {
            //     values.learnerQualification.map((ele, index) => {
            //       let document=[]
            //       document.push(values.edudocument[index])
              
            //     ele.document = document
  
            //     });
            //   }
            // }
  
            if (values.hasOwnProperty('professionalQualification') && values.professionalQualification !== "" && values.professionalQualification !== 0) {
              
              values.professionalQualification.map(ele => {
                if( moment(ele.fromDate, 'YYYY-MM-DD', true).isValid()==true || moment(ele.toDate, 'YYYY-MM-DD', true).isValid()==true)
                {
                ele.fromDate = moment(ele.fromDate).format("DD/MM/YYYY HH:mm:ss");
                ele.toDate = moment(ele.toDate).format("DD/MM/YYYY HH:mm:ss");
                } 
              
              });
            
            }
  
            // if (values.hasOwnProperty('CPEDetails') && values.CPEDetails !== "" && values.CPEDetails !==0) {
             
            //   values.CPEDetails.map(ele => {
            //     if( moment(ele.dateOfPassingTest, 'YYYY-MM-DD', true).isValid()==true || moment(ele.dateOfCpe, 'YYYY-MM-DD', true).isValid()==true)
            //     {
            //     ele.dateOfPassingTest = moment(ele.dateOfPassingTest).format("DD/MM/YYYY HH:mm:ss");
            //     ele.dateOfCpe = moment(ele.dateOfCpe).format("DD/MM/YYYY HH:mm:ss");
            //     }
            //   });
            
            // }
            
          this.setState({ loading: true });
          let address = [];
          let perAddress = {
            addressType: "permanent",
            addressId: values.perAddressID,
            street: values.perStreet,
            street2: values.perStreet2,
            street3: values.perStreet3,
            cityId: values.percityId,
            stateId: values.perstateId,
            countryId: values.percountryId,
            postalCode: values.perPostalCode,
           
          };
          let corAddress = {
            addressType: "correspondence",
            addressId: values.corAddressID,
            street: values.corStreet,
            street2: values.corStreet2,
            street3: values.corStreet3,
            cityId: values.corcityId,
            stateId: values.corstateId,
            countryId: values.corcountryId,
            postalCode: values.corPostalCode,
          
          };
          address.push(perAddress);
          address.push(corAddress);
         
          
      let percetentage=localStorage.getItem('profilecomlitionvalue')
      // if( values.submitStatus==="SUBMITTED" && percetentage>"60")
      // {
          let data = {
            userID: localStorage.getItem("userid"),
            partyID: localStorage.getItem("userid-Token"),
            learnerPartyID: values.learnerPartyID,
            learnerID: values.learnerID,
            roleID: localStorage.roleId,
            mobileNo: values.mobileNo,
            emailID: values.emailID,
            gender: values.gender,
            firstName: values.firstName,
            middleName: values.middleName,
            lastName: values.lastName,
            contactNo: values.contactNo,
            secondaryEmailID: values.secondaryEmailID,
            dob: DOB,
            // values.dob
            //   ? moment(values.dob).format("DD/MM/YYYY HH:mm:ss")
            //   : values.dob,
            panNumber: values.panNumber,
            aadharNumber:  "000000000000",
            learnerFatherName: values.learnerFatherName
              ? values.learnerFatherName
              : " ",
            learnerFatherDOB: values.learnerFatherDOB
              ? moment(values.learnerFatherDOB).format("DD/MM/YYYY HH:mm:ss")
              : " ",
            learnerFatherEmployementType: values.learnerFatherEmployementType
              ? values.learnerFatherEmployementType
              : 0,
            learnerFatherCompanyName: values.learnerFatherCompanyName
              ? values.learnerFatherCompanyName
              : " ",
            learnerMotherName: values.learnerMotherName
              ? values.learnerMotherName
              : " ",
            learnerMotherDOB: values.learnerMotherDOB
              ? moment(values.learnerMotherDOB).format("DD/MM/YYYY HH:mm:ss")
              : " ",
            learnerMotherEmployementType: values.learnerMotherEmployementType
              ? values.learnerMotherEmployementType
              : 0,
            learnerMotherCompanyName: values.learnerMotherCompanyName
              ? values.learnerMotherCompanyName
              : " ",
            learnerQualification: values.learnerQualification.length!=0?values.learnerQualification.filter(x => !!x):[],
            address,
            isActive: 1,
            document: values.document,
            resumeDocumentURL: values.resumeDocumentURL,
           // profilePhotoURL: values.profilePhotoURL,
            resumeVideoURL: values.resumeVideoURL,
            passportNumber: values.passportNumber,
              bankName: values.bankName,
              branchName: values.branch,
              branchAddress: values.branchAddress,
              accountType: values.accountType,
              accountNumber: values.acccountNumber,
      
              arn: values.ARNNO,
              nameOfARNHolder: values.NameOfARNHolder,
              nameOfMutualFundEmpanelled: values.ARNuserFeilds !==undefined ? values.ARNuserFeilds.filter(x => !!x) : null,
       
              postalCode: values.perPostalCode,
              salutation: values.salutation,
              learnerProfessionalQualification: values.professionalQualification !== "" && values.professionalQualification !== 0 ? values.professionalQualification.filter(x => !!x) : null,
              learnerFatherName: values.fatherName,
              //learnerCpeDetails: values.CPEDetails !== "" && values.CPEDetails !== 0 ? values.CPEDetails.filter(x => !!x) : null,
              learnerCpeDetails:[],
              proofOfAddress: values.peraddressproof1,
              applicationStatus: values.submitStatus,
              learnerType: values.whoareu,
              profileCompletion: localStorage.getItem('profilecomlitionvalue'),
              isTourCompleted:1,
              euinNumber:values.ARNEUINNO,
              isAddressSame:true,
              learnerProfession:values.currentProfession,
              workflowName:this.state.processType === "1001"?"ARN":"ARN",
              ifscCode:values.ifscCode,
              isAmfiUser: this.props.personnelData.isAmfiUser

            


          };
          console.log("update", data);
          RegisterUserService.updateUserProfile(data, res => {
            console.log(data.applicationStatus)
            if (res.status === "success") {
              this.setState({ loading: false });
              this.setState({ loading1: "none" });
              
  
              localStorage.setItem("reload", "true");
              if(localStorage.getItem('roleId')==5)
              {
                if(data.applicationStatus==="SUBMITTED")
                {

                  // success(
                  //   'Your profile has been "Submitted". Please click on "Initiate Course" below FEAT to proceed.',
                  //   successNotification
                  // );
                  success(
                    'You have successfully completed your profile, initiate the NISM VA preparatory course now!',
                    successNotification
                  );
                  setTimeout(() => {
                    this.props.history.push("/learnerDashboard");
                                    }, 5000)
                  
                }
                else{
                  success(
                    'Your profile has been "Saved as a Draft". Please provide all mandatory information to "Submit" your profile and undertake FEAT.',
                    successNotification
                  );
                  setTimeout(() => {
                    window.location.reload();
                                    }, 5000)
                }
            //  this.props.history.push("/learnerDashboard");
              }
              else{
               
                setTimeout(() => {
                  this.props.history.push("/dashboard");
                                  }, 5000)
                
              }
            } else {
              // this.setState({ loading1: "none" });
              this.setState({ loading: false });
              error(res.reasonText, {
                ...errorNotification
               // container: "top-center"
              });
              // else {
              //   if (res.status === "fail") {
              //     this.setState({ loading: false });
              //     success("Error", errorNotification);
              //     this.props.history.push("/learnerDashboard");
              //   }
            }
          });
       // }
        // else if(values.submitStatus==="DRAFTED"){
        //   let data = {
        //     userID: localStorage.getItem("userid"),
        //     partyID: localStorage.getItem("userid-Token"),
        //     learnerPartyID: values.learnerPartyID,
        //     learnerID: values.learnerID,
        //     roleID: localStorage.roleId,
        //     mobileNo: values.mobileNo,
        //     emailID: values.emailID,
        //     gender: values.gender,
        //     firstName: values.firstName,
        //     middleName: values.middleName,
        //     lastName: values.lastName,
        //     contactNo: values.contactNo,
        //     secondaryEmailID: values.secondaryEmailID,
        //     dob: DOB,
        //     // values.dob
        //     //   ? moment(values.dob).format("DD/MM/YYYY HH:mm:ss")
        //     //   : values.dob,
        //     panNumber: values.panNumber,
        //     aadharNumber: values.aadharNumber,
        //     learnerFatherName: values.learnerFatherName
        //       ? values.learnerFatherName
        //       : " ",
        //     learnerFatherDOB: values.learnerFatherDOB
        //       ? moment(values.learnerFatherDOB).format("DD/MM/YYYY HH:mm:ss")
        //       : " ",
        //     learnerFatherEmployementType: values.learnerFatherEmployementType
        //       ? values.learnerFatherEmployementType
        //       : 0,
        //     learnerFatherCompanyName: values.learnerFatherCompanyName
        //       ? values.learnerFatherCompanyName
        //       : " ",
        //     learnerMotherName: values.learnerMotherName
        //       ? values.learnerMotherName
        //       : " ",
        //     learnerMotherDOB: values.learnerMotherDOB
        //       ? moment(values.learnerMotherDOB).format("DD/MM/YYYY HH:mm:ss")
        //       : " ",
        //     learnerMotherEmployementType: values.learnerMotherEmployementType
        //       ? values.learnerMotherEmployementType
        //       : 0,
        //     learnerMotherCompanyName: values.learnerMotherCompanyName
        //       ? values.learnerMotherCompanyName
        //       : " ",
        //     learnerQualification: values.learnerQualification.length!=0?values.learnerQualification.filter(x => !!x):[],
        //     address,
        //     isActive: 1,
        //     document: values.document,
        //     resumeDocumentURL: values.resumeDocumentURL,
        //     profilePhotoURL: values.profilePhotoURL,
        //     resumeVideoURL: values.resumeVideoURL,
        //     passportNumber: values.passportNumber,
        //       bankName: values.bankName,
        //       branchName: values.branch,
        //       branchAddress: values.branchAddress,
        //       accountType: values.accountType,
        //       accountNumber: values.acccountNumber,
      
        //       arn: values.ARNNO,
        //       nameOfARNHolder: values.NameOfARNHolder,
        //       nameOfMutualFundEmpanelled: values.ARNuserFeilds !==undefined ? values.ARNuserFeilds.filter(x => !!x) : null,
       
        //       postalCode: values.perPostalCode,
        //       salutation: values.salutation,
        //       learnerProfessionalQualification: values.professionalQualification !== "" && values.professionalQualification !== 0 ? values.professionalQualification.filter(x => !!x) : null,
        //       learnerFatherName: values.fatherName,
        //       learnerCpeDetails: values.CPEDetails !== "" && values.CPEDetails !== 0 ? values.CPEDetails.filter(x => !!x) : null,
        //       proofOfAddress: values.peraddressproof1,
        //       applicationStatus: values.submitStatus,
        //       learnerType: values.whoareu,
        //       profileCompletion: localStorage.getItem('profilecomlitionvalue'),
        //       isTourCompleted:1,
        //       euinNumber:values.ARNEUINNO,
        //       isAddressSame:true,
        //       learnerProfession:values.currentProfession,
        //       workflowName:this.state.processType === "1001"?"ARN":"ARN",
        //       ifscCode:values.ifscCode


            


        //   };
        //   //console.log("update", data);
        //   RegisterUserService.updateUserProfile(data, res => {
        //     console.log(data.applicationStatus)
        //     if (res.status === "success") {
        //       this.setState({ loading: false });
        //       this.setState({ loading1: "none" });
              
  
        //       localStorage.setItem("reload", "true");
        //       if(localStorage.getItem('roleId')==5)
        //       {
        //         if(data.applicationStatus==="SUBMITTED")
        //         {

        //           success(
        //             'Your profile has been "Submitted". Please click on "Initiate Course" below FEAT to proceed.',
        //             successNotification
        //           );
        //           setTimeout(() => {
        //             this.props.history.push("/learnerDashboard");
        //                             }, 5000)
                  
        //         }
        //         else{
        //           success(
        //             'Your profile has been "Saved as a Draft". Please provide all mandatory information to "Submit" your profile and undertake FEAT.',
        //             successNotification
        //           );
        //           setTimeout(() => {
        //             window.location.reload();
        //                             }, 5000)
        //         }
        //     //  this.props.history.push("/learnerDashboard");
        //       }
        //       else{
               
        //         setTimeout(() => {
        //           this.props.history.push("/dashboard");
        //                           }, 5000)
                
        //       }
        //     } else {
        //       // this.setState({ loading1: "none" });
        //       this.setState({ loading: false });
        //       error(res.reasonText, {
        //         ...errorNotification
        //        // container: "top-center"
        //       });
        //       // else {
        //       //   if (res.status === "fail") {
        //       //     this.setState({ loading: false });
        //       //     success("Error", errorNotification);
        //       //     this.props.history.push("/learnerDashboard");
        //       //   }
        //     }
        //   });
        // }
        // else{
        //   this.setState({ loading: false });
        //   warning("You have either not filled all mandatory fields or not uploaded all document for NISM or your profile might be less than 60%. In case you don't have your documents ready, please save your profile as 'Save Draft'. Add the documents to move to the next section",warningNotification)
        // }
      }
      }
        }}
      }
    }
        render={({
          errors,
          touched,
          handleSubmit,
          handleChange,
          isValid,
          values,
          setFieldValue,
          formProps,
          validateField,
          handleBlur,
          form,
          ...formikProps
         
        }) => (
         
            <React.Fragment>
           {/* <div
              className=""
              id="roadmap1"
             tabindex="-1" role="dialog" aria-labelledby="modalLabel"    data-keyboard="true" aria-hidden="true"
            > */}

<div
              className="modal fade"
              id="roadmap1"
              tabindex="-1" role="dialog" aria-labelledby="modalLabel" data-keyboard="true" aria-hidden="true"
            >
              <div className="modal-dialog modal-md" role="document">
                <div class="modal-content">
                  <div className="modal-header">
                    <div class="scr-board">
                      <h2>Why Fill The Form? </h2> 
                       <div class="bottom-line"></div>
                    </div>
                    {/* <h4 className="modal-title">FinX Core Login</h4> */}
                  </div>
                  <div className="modal-body">
                  {/* The Profile Form is a means of gathering your information which would help us in serving you more efficiently through your journey towards becoming a Mutual Fund Advisor. */}
                  <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                                  <p><b>Welcome to the ExpertMFD Platform!</b></p> <br />
                                <p>You are on the pathway towards becoming a successful Financial Entrepreneur.</p>

                                <p>We will be collecting the following information from you to create your <b>NISM Profile</b> and undertake your enrolment for the NISM VA MF Certification Examination. 
                                  Fill all the &#8220;<b>Compulsory Fields</b>&#8221;, which have been marked with a red asterisk (<span class="validation1">*</span>)</p>
                                  <ul>
                                <li><b>The profile details are required by National Institute of Securities Markets (NISM) 
                                  for your Profile Creation & NISM VA Exam Registration </b></li>
                                <li><b>Please Keep the following documents ready </b>
                                  <ul>
                                    <li>Scanned PAN (File size less than 1 MB)</li>
                                    {/* <li>Scanned Aadhaar - Front (File size less than 1 MB)</li>
                                    <li>Scanned Aadhaar - Back (File size less than 1 MB)</li> */}
                                    <li>Scanned Proof of Address - Electricity Bill / Ration Card / Passport / Voter ID (File size less than 1 MB)</li>
                                    <li>Scanned copy of your latest degree certificate</li>
                                    <li>Profile Image <span class="validation">(only JPEG/JPG/PNG formats accepted)</span></li>
                                    <li>Bank Account Information ( IFSC Code, Bank name, Bank A/C Number etc.) <br />
                                    <span class="validation">Please note that the file you upload should NOT 
                                    have ANY special character in the name of the attachment file.</span>
                                          </li>
                                    </ul>
                                </li>
                                <li>Click on &#8220;<b>Submit</b>&#8221; after filling all the compulsory fields. Click on &#8220;<b>Save Draft</b>&#8221; if you 
                                  intend on updating your profile at a later date. Kindly note that &#8220;<b>Submitting your Profile</b>&#8221; 
                                  is a <i>mandatory requirement</i> to continue with the ExpertMFD Journey</li>
                                <li><b>Please note you will be able to submit the profile details & move to next section ONLY after filing all mandatory information 
                                  (Marked as <span class="validation1">*</span>, including upload of scanned documents)</b></li>
                                  <li><b>The Bank details are taken so that we can refund your Training & NISM Examination Fee, once you start MF 
                                  distribution business</b></li>
                                  <li><b>The form may take 15 mins of your precious time. Please be patient</b></li>
                                  </ul>
                                
                                  </div>
                  </div>
                  <div class="modal-footer">
                    {/* <button
                      type="button"
                      className="btn-5 mt-40 mr-20"
                      name="btnYes"
                    onClick={this.getPaymentLink}
                    >
                      Yes
                    </button> */}
                    <button
                      type="button"
                      className="btn-5 mt-40"
                      name="btnNo"
                      onClick={e=>{
                        window.$('#roadmap1').modal('hide')
                      }}
                    >
                      Ok
                    </button>
                  </div>
                </div>
              </div>
            </div>
              {this.state.loading && (
                <PopUpPortal
                  HidePopup={this.state.loading}
                  IsVisible={this.state.loading}
                />
              )}
              <section class="dashboard-tab user-profile">
                <div class="container">
                <div class="row">
                {/* <div className="col-lg-12 col-md-12 col-sm-6 col-xs-12">
                  <span>
                  <h2>User Profile
                  <a onClick={this.WhyTheFillForm} ><i className="fa fa-question-circle-o" aria-hidden="true" style={{fontSize:"24px", paddingLeft: "5px"}}></i></a>
                  </h2>  </span>
                  </div> */}
                    {/* <div id="loading-bar-spinner" class="spinner" style={{ display: this.state.loading1 }}>
                        <div class="spinner-icon">
                        </div>
                      </div> */}
                      <div className="col-md-3 col-lg-3 col-sm-12 col-xs-12">
                        <div className="profile-left-sidebar" >
                          <div className="profile-sidebar">
                            <div className="table-header">
                            <div class="container-fluid">
<div id="topheader">
  <nav>

    <ul class="tab">
      <div className="col-lg-12 col-md-12 col-sm-6 col-xs-12 hidden-xs hidden-sm pl pr">
      <div class="container-fluid">
<div id="topheader">
  <nav>
  {/* {values.mobileNo} */}

    <ul class="tab">
      <div className="col-lg-12 col-md-12 col-sm-6 col-xs-12 hidden-xs hidden-sm pl pr">
      <li className="active">
          <a 
           onClick={e=>{this.goto('distributor-profile')}}
          
          //href="#distributor-profile"
          >
            {/* Distributor Profile */}
            Profile Instructions
          </a>

        </li>
        <li className="">
          <a
                     onClick={e=>{this.goto('create-user')}}

         // href="#create-user"
          >

            Personal Details{" "}
          </a>
         
          {
          
          values.mobileNo &&
            values.contactNo &&
            values.emailID &&
            values.gender &&
            values.secondaryEmailID &&
            values.firstName &&
            values.middleName &&
            values.lastName &&
            values.dob &&
            values.panNumber &&
            values.aadharNumber ? (''
              // <span className="pull-right">
              //   {" "}
              //   <i
              //     className="fa fa-check-circle"
              //     aria-hidden="true"
              //   ></i>{" "}
             // </span>
            ) : (''
              // <span className="pull-right">
              //   {" "}
              //   <i
              //     className="fa fa-times-circle red"
              //     aria-hidden="true"
              //   ></i>
              // </span>
            )}
        </li>
        
        <li>
          <a 
          //href="#create-address"
          
          onClick={e=>{this.goto('create-address')}}
          >
            Address Details{" "}
          </a>

          {values.perStreet &&
            values.perStreet2 &&
            values.perPostalCode &&
            values.percityId &&
            values.perstateId &&
            values.percountryId ? (''
            // <span className="pull-right">
            //   {" "}
            //   <i
            //     className="fa fa-check-circle"
            //     aria-hidden="true"
            //   ></i>{" "}
            // </span>
          ) : (''
            // <span className="pull-right">
            //   {" "}
            //   <i
            //     className="fa fa-times-circle red"
            //     aria-hidden="true"
            //   ></i>
            // </span>
          )&&
            values.corStreet &&
            values.corStreet2 &&
            values.corPostalCode &&
            values.corcityId &&
            values.corstateId &&
            values.corcountryId ? (''
              // <span className="pull-right">
              //   {" "}
              //   <i
              //     className="fa fa-check-circle"
              //     aria-hidden="true"
              //   ></i>{" "}
              // </span>
            ) : (''
              // <span className="pull-right">
              //   {" "}
              //   <i
              //     className="fa fa-times-circle red"
              //     aria-hidden="true"
              //   ></i>
              // </span>
            )}
        </li>
        <li className="">
          <a 
            onClick={e=>{this.goto('banking-details')}}
          //href="#banking-details"
          >
            Banking Details
          </a>
        </li>
        {(values.learnerQualification[0] || "")
          .passingYear ? (
            <li>
              <a
               onClick={e=>{this.goto('create-qualification')}}
              
              
              //href="#create-qualification"
              >
                Professional Details
        </a>
            
            </li>
          ) : (
            <li>
              <a
              // href="#create-qualification"
              onClick={e=>{this.goto('create-qualification')}}

              >
              Professional Details
        </a>
              
            </li>
          )}
        {(values.learnerFatherEmployementType ===
          "6" ||
          values.learnerFatherEmployementType ===
          6 ||
          values.learnerFatherEmployementType ===
          "8" ||
          values.learnerFatherEmployementType ===
          8) &&
          (values.learnerMotherEmployementType ===
            "6" ||
            values.learnerMotherEmployementType ===
            6 ||
            values.learnerMotherEmployementType ===
            "8" ||
            values.learnerMotherEmployementType ===
            8) ? (
            <li>
              <a 
              onClick={e=>{this.goto('educational-details')}}
              //href="#educational-details"
              >
               Educational  Details{" "}
              </a>
            
            </li>
          ) : (values.learnerFatherEmployementType ===
            "6" ||
            values.learnerFatherEmployementType ===
            6 ||
            values.learnerFatherEmployementType ===
            "8" ||
            values.learnerFatherEmployementType ===
            8) &&
            (values.learnerMotherEmployementType !==
              "6" ||
              values.learnerMotherEmployementType !==
              6 ||
              values.learnerMotherEmployementType !==
              "8" ||
              values.learnerMotherEmployementType !==
              8) ? (
              <li>
                <a href="#create-parent">
                  Professional Details{" "}
                </a>
                {values.learnerFatherName &&
                  values.learnerFatherDOB &&
                  values.learnerFatherEmployementType &&
                  values.learnerMotherName &&
                  values.learnerMotherDOB &&
                  values.learnerMotherEmployementType &&
                  values.learnerMotherCompanyName ? (''
                    // <span className="pull-right">
                    //   {" "}
                    //   <i
                    //     className="fa fa-check-circle"
                    //     aria-hidden="true"
                    //   ></i>{" "}
                    // </span>
                  ) : (''
                    // <span className="pull-right">
                    //   {" "}
                    //   <i
                    //     className="fa fa-times-circle red"
                    //     aria-hidden="true"
                    //   ></i>
                    // </span>
                  )}
              </li>
            ) : (values.learnerFatherEmployementType !==
              "6" ||
              values.learnerFatherEmployementType !==
              6 ||
              values.learnerFatherEmployementType !==
              "8" ||
              values.learnerFatherEmployementType !==
              8) &&
              (values.learnerMotherEmployementType ===
                "6" ||
                values.learnerMotherEmployementType ===
                6 ||
                values.learnerMotherEmployementType ===
                "8" ||
                values.learnerMotherEmployementType ===
                8) ? (
                <li>
                  <a
                  // href="#educational-details"
                  
                  onClick={e=>{this.goto('educational-details')}}
                  >
                    Educational Details{" "}
                  </a>
                 
                </li>
              ) : (
                <li>
                  <a 
                  //href="#educational-details"
                  onClick={e=>{this.goto('educational-details')}}
                  >
                    Educational Details{" "}
                  </a>
                  {values.learnerFatherName &&
                    values.learnerFatherDOB &&
                    values.learnerFatherEmployementType &&
                    values.learnerMotherName &&
                    values.learnerMotherDOB &&
                    values.learnerMotherEmployementType &&
                    values.learnerFatherCompanyName &&
                    values.learnerMotherCompanyName ? (
                      ''
                      // <span className="pull-right">
                      //   {" "}
                      //   <i
                      //     className="fa fa-check-circle"
                      //     aria-hidden="true"
                      //   ></i>{" "}
                      // </span>
                    ) : (
                      ''
                      // <span className="pull-right">
                      //   {" "}
                      //   <i
                      //     className="fa fa-times-circle red"
                      //     aria-hidden="true"
                      //   ></i>
                      // </span>
                    )}
                </li>
              )}
       {this.state.processType==="1003"?
           <li className="">
          <a
          // href="#CPE-details"
          onClick={e=>{this.goto('CPE-details')}}
          >
          NISM Certificate Details 
          </a>
        </li>:''}
 {/* {this.state.processType==="1001"? */}
 {/* uncomment from li to li for ARN derails */}
          {/* <li>
          <a 
          //href="#ARN-details"
          
          onClick={e=>{this.goto('ARN-details')}}
          >
            ARN Details Sneha
      </a>
      </li> */}

 {/* :this.state.processType==="1002"? */}
      {/* <li>
          <a 
          //href="#employer-ARN-details"
          
          onClick={e=>{this.goto('employer-ARN-details')}}

          >
          Employer ARN Details
      </a>
      </li> */}
       {/* :''} */}
        <li>
          <a
          
         
          
          onClick={e=>{this.goto('create-profile')}}
          >
            Profile Details
      </a>
          {(values.resumeDocumentURL ||
            values.resumeDocumentName) &&
            values.profilePhotoURL &&
            values.resumeVideoURL ? (
              ''
             
            ) : (
              ''
             
            )}
        </li>

      </div>
      <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <UserProfileComplitiomBar
          personnelProfileData={
            this.props.personnelData
          }
          personnelAddressData={
            this.props.addressData
          }
        />
      </div>
      <li>
   <a onClick={this.WhyTheFillForm} > User Profile
     <i className="fa fa-question-circle-o" aria-hidden="true"
     style={{fontSize:"20px", paddingLeft: "5px", paddingright: "8px"}}></i></a>
    </li> 
    </ul>
  </nav>
</div>
</div>

      </div>
      {/* <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <UserProfileComplitiomBar
          personnelProfileData={
            this.props.personnelData
          }
          personnelAddressData={
            this.props.addressData
          }
        />
      </div>
      <li>
   <a onClick={this.WhyTheFillForm} > User Profile
     <i className="fa fa-question-circle-o" aria-hidden="true"
     style={{fontSize:"20px", paddingLeft: "5px", paddingright: "8px"}}></i></a>
    </li>  */}
    </ul>
  </nav>
</div>
</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-9 col-lg-9 col-sm-12 col-xs-12">
                        <main class="content-pos-fixed">
                          <Form
                            onSubmit={handleSubmit}
                            onKeyDown={onKeyDown => {
                              if (
                                (onKeyDown.charCode || onKeyDown.keyCode) ===
                                13 &&
                                onKeyDown.target.name != "corcountryId" &&
                                onKeyDown.target.name != "corstateId" &&
                                onKeyDown.target.name != "corcityId" &&
                                onKeyDown.target.name != "percountryId" &&
                                onKeyDown.target.name != "perstateId" &&
                                onKeyDown.target.name != "percityId" &&
                                onKeyDown.target.name !=
                                "learnerFatherEmployementType" &&
                                onKeyDown.target.name !=
                                "learnerMotherEmployementType" &&
                                onKeyDown.target.name != "submitForm" &&
                                onKeyDown.target.name != "gender"
                              ) {
                                onKeyDown.preventDefault();
                              }
                            }}
                            className="form-group"
                          >
                            <div className="bg-box">


                            <section
                                className="create-user page-section profile-box"
                                id="distributor-profile"
                              >
                                <div className="row" >
                                  <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                                    <h2 className="sub-header">
                                    Profile Instructions
                                  </h2>
                                  <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                                  <p><b>Welcome to the ExpertMFD Platform!</b></p> <br />
                                <p>You are on the pathway towards becoming a successful Financial Entrepreneur.</p>

                                <p>We will be collecting the following information from you to create your <b>NISM Profile</b> and undertake your enrolment for the NISM VA MF Certification Examination. 
                                  Fill all the “<b>Compulsory Fields</b>”, which have been marked with a red asterisk (<span class="validation">*</span>)</p>
                                  <ul>
                                <li><b>The profile details are required by National Institute of Securities Markets (NISM) 
                                  for your Profile Creation & NISM VA Exam Registration </b></li>
                                <li><b>Please Keep the following documents ready </b>
                                  <ul>
                                    <li>Scanned PAN (File size less than 1 MB)</li>
                                    {/* <li>Scanned Aadhaar - Front (File size less than 1 MB)</li>
                                    <li>Scanned Aadhaar - Back (File size less than 1 MB)</li> */}
                                    <li>Scanned Proof of Address - Electricity Bill / Ration Card / Passport / Voter ID (File size less than 1 MB)</li>
                                    <li>Scanned copy of your latest degree certificate</li>
                                    <li>Profile Image <span class="validation">(only JPEG/JPG/PNG formats accepted)</span></li>
                                    <li>Bank Account Information ( IFSC Code, Bank name, Bank A/C Number etc.) <br />
                                    <span class="validation">Please note that the file you upload should NOT 
                                    have ANY special character in the name of the attachment file.</span>
                                          </li>
                                    </ul>
                                </li>
                                <li>Click on “<b>Submit</b>” after filling all the compulsory fields. Click on “<b>Save Draft</b>” if you 
                                  intend on updating your profile at a later date. Kindly note that “<b>Submitting your Profile</b>” 
                                  is a <i>mandatory requirement</i> to continue with the ExpertMFD Journey</li>
                                <li><b>Please note you will be able to submit the profile details & move to next section ONLY after filing all mandatory information 
                                  (Marked as <span class="validation">*</span>, including upload of scanned documents)</b></li>
                                  <li><b>The Bank details are taken so that we can refund your Training & NISM Examination Fee, once you start MF 
                                  distribution business</b></li>
                                  <li><b>The form may take 15 mins of your precious time. Please be patient</b></li>
                                  </ul>
                                
                                  </div>
                                    <div className="form-group" style={{
                                  display:"none"
                                }}>
                                      <div className="row">
                                      <div className="col-md-12 col-sm-12 col-xs-12" style={{display:"none"}}>
                                          <label>Process Type </label>
                                          <div className="displayFlex">
                                          <div>
                                            <Field
                                              className="form-check-input"
                                              type="checkbox"
                                              name="ARN"
                                              checked={this.state.processType === "1001" ? true : false}
                                              disabled={true}
                                              style={{marginRight: "10px"}}
                                            />
                                              <label
                                              class="form-check-label"
                                              for="gridCheck"
                                              style={{marginRight: "15px"}}
                                            >
                                              ARN
                                              </label>
                                              </div>
                                              <div>
                                            <Field
                                              className="form-check-input"
                                              type="checkbox"
                                              name="CPE"
                                              checked={this.state.processType === "1003" ? true : false}
                                              disabled={true}
                                              style={{marginRight: "10px"}}

                                              
                                            />
                                            <label
                                              class="form-check-label"
                                              for="gridCheck"
                                              style={{marginRight: "15px"}}
                                            >
                                             NISM Certification Details
                                              </label>
                                              </div>
                                              <div>
                                            <Field
                                              className="form-check-input"
                                              type="checkbox"
                                              name="EUIN"
                                              checked={this.state.processType === "1002" ? true : false}
                                              disabled={true}
                                              style={{marginRight: "10px"}}

                                            />
                                              <label
                                              class="form-check-label"
                                              for="gridCheck"
                                              style={{marginRight: "15px"}}
                                            >
                                              EUIN
                                              </label>
                                              </div>
                                              <div>
                                              <Field
                                              className="form-check-input"
                                              type="checkbox"
                                              name="nismCertificate"
                                              checked={this.state.processType === "1004" ? true : false}
                                              disabled={true}
                                              style={{marginRight: "10px"}}

                                            />
                                              <label
                                              class="form-check-label"
                                              for="gridCheck"
                                              style={{marginRight: "15px"}}
                                            >
                                              NISM Certification
                                              </label>
                                              </div>
                                          </div>

                                        </div>
                                        <div className="col-md-4 col-sm-4 col-xs-12" style={{display:"none"}}>
                                          <label>
                                            Who Are You?
                                          <span className="validation"> *</span>
                                          </label>
                                          <Field
                                            as="select"
                                            name="whoareu"
                                            id="whoareu"
                                            disabled={values.disabledForCielops}
                                            onChange={event => {
                                              setFieldValue(
                                                (values.whoareu =event.target.value
                                                  ))
                                            if(event.target.value=="1011")
                                            {
                                              setFieldValue(
                                                (values.PanValidation =false
                                                  )
                                              );
                                            }
                                            else{
                                              setFieldValue(
                                                (values.PanValidation =true
                                                  )
                                              );
                                            }
if(event.target.value=="1015"){
  setFieldValue(values.nameOfCurrProf="Last Profession")
}
                                            if(event.target.value=="1011" || event.target.value=="1012"){
                                              setFieldValue(values.currProfessionList=[])
                                              setFieldValue(values.disablecurreProf=true)


                                            }
                                            else if(event.target.value=="1013" || event.target.value=="1015"){
                                              console.log(this.state.currentProfession)
                                              setFieldValue(values.disablecurreProf=false)
setFieldValue(values.currProfessionList=currProf)
                                            }
else{
  setFieldValue(values.currProfessionList=[])
  setFieldValue(values.disablecurreProf=true)

}
                                               
                                               
                                            }}
                                            onBlur={event => {
                                              this.updateWeightage(event.target.name,event.target.value)
                                            }}
                                            className={
                                              "form-control" +
                                              (errors.whoareu && touched.whoareu
                                                ? " is-invalid"
                                                : "")
                                            }
                                          >
                                            {/* <option>--Select--</option>
                                            {(this.state.whoareu || []).map(
                                              team => (
                                                <option
                                                  key={team.codeValueId}
                                                  value={team.codeValueId}
                                                >
                                                  {(team.codeValue)}
                                                </option>
                                              )
                                            )} */}
                                             <option value="1011">Student</option>
                                              <option value="1012">Entrepreneur</option>
                                              <option value="1013">Professional</option>
                                              <option value="1014">Home Maker</option>
                                              <option value="1015">Retired</option>
                                          </Field>
                                          <ErrorMessage
                                            name="whoareu"
                                            component="div"
                                            className="validation"
                                          />
                                        </div>
                                        <div className="col-md-4 col-sm-4 col-xs-12">

<label>
   {/* current profession{" "} */}
   {values.nameOfCurrProf} {" "}
    {/* <span className="validation"> *</span> */}
  </label>
 
      <div>
        <Field
          as="select"
          name="currentProfession"
          id="currentProfession"
          onChange={handleChange}
          disabled={values.disabledForCielops || values.disablecurreProf}
          onBlur={event => {
            this.updateWeightage(event.target.name,event.target.value)
          }}
          className={
            "form-control" +
            (errors.lookingFor &&
              touched.lookingFor
              ? " is-invalid"
              : '')
          }
        >
          <option value="">--Select--</option>
          {(values.currProfessionList || []).map(
            team => (
              <option
                key={team.codeValueId}
                value={team.codeValueId}
              >
                {team.codeValue}
              </option>
            )
          )}

        </Field>
        <ErrorMessage
          name="currentProfession"
          component="div"
          className="validation"
        /></div></div>
                                        <div className="col-md-4 col-sm-4 col-xs-12">
                                          <label>ARN/EUIN Number</label>
                                          <Field
                                            type="text"
                                            name="ARNEUINNO"
                                            placeholder="ARN/EUIN Number"
                                            className="form-control"
                                            disabled={values.disabledForCielops}
                                            onBlur={event => {
                                              this.updateWeightage(event.target.name,event.target.value)
                                            }}
                                          />
                  { errors.ARNEUINNO && <div  className="validation">{errors.ARNEUINNO}</div>}

                                           {/* <ErrorMessage
                                            name="ARNEUINNO"
                                            component="div"
                                            className="validation"
                                          /> */}
                                        </div>
                                        </div>
                                        <div className="row">
                                       
                                       
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </section>
                              <section
                                className="create-user page-section profile-box"
                                id="create-user"
                              >
                                <div className="row">
                                  <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                                    <h2 className="sub-header">
                                      Personal Details
                                  </h2>
                                    <div className="form-group">
                                    {this.state.isFinXInternship != '' && this.state.isFinXInternship != null && this.state.isFinXInternship != undefined && this.state.isFinXInternship ===1?
                                      <div className="row">
                                        <div className="col-md-12 col-sm-12 col-xs-12">
                                          <label style={{ color: '#6cac3c' }}>I am a student of {this.state.collegeName} college and want to be part of the FINX Internship program</label>
                                        </div>
                                      </div>
                                      : ''}
                                      <div className="row">
                                        <div className="col-md-4 col-sm-4 col-xs-12">
                                          <label>User ID </label>
                                          <Field
                                            type="text"
                                            name="learnerPartyID"
                                            disabled
                                            className="form-control"
                                          />
                                        </div>
                                        <div className="col-md-4 col-sm-4 col-xs-12">
                                          <label>
                                            Mobile No{" "}
                                            <span className="validation"> *</span>
                                          </label>
                                          <Field
                                            type="text"
                                            name="mobileNo"
                                            id="mobileNo"
                                            onChange={handleChange}
                                            disabled={
                                              values.mobileNo ? true : false
                                            }
                                            id="mobileNo"
                                            autoComplete="mobileNo"
                                            className={
                                              "form-control" +
                                              (errors.mobileNo && touched.mobileNo
                                                ? " is-invalid"
                                                : "")
                                            }
                                            
                                            placeholder="Mobile Number"
                                          />
                                          <ErrorMessage
                                            name="mobileNo"
                                            className="validation"
                                            component="div"
                                          />
                                        </div>
                                        <div className="col-md-4 col-sm-4 col-xs-12">
                                          <label>
                                            Email Id
                                          <span className="validation"> *</span>
                                          </label>
                                          <Field
                                            type="text"
                                            name="emailID"
                                            id="emailID"
                                            onChange={handleChange}
                                            disabled={
                                              values.emailID ? true : false
                                            }
                                            autoComplete="emailID"
                                            className={
                                              "form-control" +
                                              (errors.emailID && touched.emailID
                                                ? " is-invalid"
                                                : "")
                                            }
                                            placeholder="Email ID"
                                          />
                                          <ErrorMessage
                                            name="emailID"
                                            className="validation"
                                            component="div"
                                          />
                                        </div>
                                      </div>
                                      <div className="col-md-12 col-sm-12 col-xs-12"></div>
                                      <div className="row">
                                        <div className="col-md-4 col-sm-4 col-xs-12">
                                          <label>
                                            Gender
                                          <span className="validation"> *</span>
                                          </label>
                                          <Field
                                            as="select"
                                            name="gender"
                                            id="gender"
                                            disabled={values.disabledForCielops}
                                            onChange={handleChange}
                                            onBlur={
                                              e=>{
                                                this.updateWeightage(e.target.name,e.target.value)
                                      
                                              }
                                            }
                                            className={
                                              "form-control" +
                                              (errors.gender && touched.gender
                                                ? " is-invalid"
                                                : "")
                                            }
                                          >
                                            <option value="">
                                              Select Gender
                                          </option>
                                            <option value="1">Male</option>
                                            <option value="2">Female</option>
                                            <option value="3">Other</option>
                                          </Field>
                                          {/* <ErrorMessage
                                            name="gender"
                                            component="div"
                                            className="validation"
                                          /> */}
                                          { errors.gender && <div  className="validation">{errors.gender}</div>}
                                        </div>
                                        <div className="col-md-4 col-sm-4 col-xs-12">
                                          <label>
                                            Select Salutation
                                          {/* <span className="validation"> *</span> */}
                                          </label>
                                          <Field
                                            as="select"
                                            name="salutation"
                                            id="salutation"
                                            disabled={values.disabledForCielops}
                                            onChange={handleChange}
                                            onBlur={
                                              e=>{
                                                this.updateWeightage(e.target.name,e.target.value)
                                      
                                              }
                                            }
                                            className={
                                              "form-control" +
                                              (errors.salutation && touched.salutation
                                                ? " is-invalid"
                                                : "")
                                            }
                                          >
                                            <option value="">
                                              Select Salutation
                                          </option>
                                            <option value="1">Mr.</option>
                                            <option value="2">Mrs.</option>
                                            <option value="3">Miss.</option>
                                          </Field>
                                          {/* <ErrorMessage
                                            name="salutation"
                                            component="div"
                                            className="validation"
                                          /> */}
                                          { errors.salutation && <div  className="validation">{errors.salutation}</div>}
                                        </div>
                                        <div className="col-md-4 col-sm-4 col-xs-12">
                                          <label>
                                            First Name{" "}
                                            <span className="validation"> *</span>
                                          </label>
                                          <Field
                                            type="text"
                                            name="firstName"
                                            id="firstName"
                                            disabled={values.disabledForCielops}
                                            onChange={handleChange}
                                            autoComplete="firstName"
                                            onBlur={
                                              e=>{
                                                this.updateWeightage(e.target.name,e.target.value)
                                      
                                              }
                                            }
                                            className={
                                              "form-control" +
                                              (errors.firstName &&
                                                touched.firstName
                                                ? " is-invalid"
                                                : "")
                                            }
                                            placeholder="First Name"
                                          />
                              { errors.firstName && <div  className="validation">{errors.firstName}</div>}

                                          {/* <ErrorMessage
                                            name="firstName"
                                            className="validation "
                                            component="div"
                                          /> */}
                                        </div>
                                        <div className="col-md-12 col-sm-12 col-xs-12"></div>
                                        <div className="col-md-4 col-sm-4 col-xs-12">
                                          <label>Middle Name</label>
                                          <Field
                                            type="text"
                                            name="middleName"
                                            id="middleName"
                                            disabled={values.disabledForCielops}
                                            onChange={handleChange}
                                            onBlur={
                                           //   formProps.handleBlur,
                                              e=>{
                                               
                                                this.updateWeightage(e.target.name,e.target.value)
                                      
                                              }
                                            }
                                            autoComplete="middleName"
                                            className="form-control"
                                            placeholder="Middle Name"
                                          />
                                 { errors.middleName && <div  className="validation">{errors.middleName}</div>}

                                             {/* <ErrorMessage
                                            name="middleName"
                                            className="validation"
                                            component="div"
                                          /> */}
                                        </div>
                                     
                                        <div className="col-md-4 col-sm-4 col-xs-12">
                                          <label>
                                            Last Name
                                          <span className="validation">
                                              {" "}
                                              *
                                          </span>{" "}
                                          </label>
                                          <Field
                                            type="text"
                                            name="lastName"
                                            id="lastName"
                                            disabled={values.disabledForCielops}
                                            onChange={handleChange}
                                            onBlur={
                                              e=>{
                                                this.updateWeightage(e.target.name,e.target.value)
                                      
                                              }
                                            }
                                            autoComplete="lastName"
                                            className={
                                              "form-control" +
                                              (errors.lastName && touched.lastName
                                                ? " is-invalid"
                                                : "")
                                            }
                                            placeholder="Last Name"
                                          />
                                        { errors.lastName && <div  className="validation">{errors.lastName}</div>}
                                          {/* <ErrorMessage
                                            name="lastName"
                                            className="validation"
                                            component="div"
                                          /> */}
                                        </div>
                                        <div className="col-md-4 col-sm-4 col-xs-12">
                                          <label>Father's Name

                                          <span className="validation"> *</span>
                                          </label>
                                          <Field
                                            type="text"
                                            name="fatherName"
                                            id="fatherName"
                                            disabled={values.disabledForCielops}
                                            onChange={handleChange}
                                            onBlur={
                                           
                                              e=>{
                                                console.log(errors)
                                              //  formProps.setTouched({...form.touched,[values.fatherName]: true });
                                                //
                                            

                                              //  validateField('fatherName')
                                              this.updateWeightage(e.target.name,e.target.value)
}
                                              
                                            }
                                            autoComplete="fatherName"
                                            className="form-control"
                                            placeholder="Father Name"
                                          />
                                             { errors.fatherName && <div  className="validation">{errors.fatherName}</div>}

                                             {/* <ErrorMessage
                                            name="fatherName"
                                            className="validation"
                                            component="div"
                                          /> */}
                                        </div>
                                      </div>
                                      <div className="col-md-12 col-sm-12 col-xs-12"></div>
                                      <div className="row">
                                        
                                        <div className="col-md-4 col-sm-4 col-xs-12">
                                          <label>Secondary Contact No</label>
                                          <Field
                                            type="text"
                                            name="contactNo"
                                            maxlength="10"
                                            onChange={handleChange}
                                            autoComplete="contactNo"
                                            
                                            disabled={values.disabledForCielops}
                                            onBlur={
                                              e=>{
                                                this.updateWeightage(e.target.name,e.target.value)
                                      
                                              }
                                            }
                                            className={
                                              "form-control" +
                                              (errors.contactNo &&
                                                touched.contactNo
                                                ? " is-invalid"
                                                : "")
                                            }
                                            placeholder="Mobile Number"
                                          />
                                         { errors.contactNo && <div  className="validation">{errors.contactNo}</div>}
                                          {/* <ErrorMessage
                                            name="contactNo"
                                            className="validation"
                                            component="div"
                                          /> */}
                                        </div>
                                        <div className="col-md-4 col-sm-4 col-xs-12">
                                          <label>Secondary Email ID</label>
                                          <Field
                                            type="text"
                                            name="secondaryEmailID"
                                            onChange={handleChange}
                                            autoComplete="secondaryEmailID"
                                            disabled={values.disabledForCielops}
                                            onBlur={
                                              e=>{
                                                this.updateWeightage(e.target.name,e.target.value)
                                      
                                              }
                                            }
                                            className={
                                              "form-control" +
                                              (errors.secondaryEmailID &&
                                                touched.secondaryEmailID
                                                ? " is-invalid"
                                                : "")
                                            }
                                            placeholder="Email ID"
                                          />
                                          { errors.secondaryEmailID && <div  className="validation">{errors.secondaryEmailID}</div>}
                                          {/* <ErrorMessage
                                            name="secondaryEmailID"
                                            className="validation"
                                            component="div"
                                          /> */}
                                        </div>
                                        <div className="col-md-4 col-sm-4 col-xs-12">
                                          <label>
                                            Birth Date
                                          <span className="validation"> *</span>
                                          </label>
                                
                                          {/* <Field
                                            type="date"
                                            onChange={handleChange}
                                            name="dob"
                                            max={this.state.today}
                                            disabled={values.disabledForCielops}
                                            onBlur={
                                              e=>{
                                                this.updateWeightage(e.target.name,e.target.value)
                                      
                                              }
                                            }
                                            id="dob"
                                            className={
                                              "form-control" +
                                              (errors.dob && touched.dob
                                                ? " is-invalid"
                                                : "")
                                            }
                                          /> */}


{/* 
<DatePicker selected={values.dob!==""?new Date(values.dob):''}
  disabled={values.disabledForCielops}
                                                                autoComplete="off"
                                                                placeholderText="dd-mm-yyyy"
                                                                dateFormat="dd-MM-yyyy"
                                                                maxLength="50"


                                                                value={values.dob!==""?new Date(values.dob):""}
                                                                onChangeRaw={event => {

                                                                    let temp = event.target.value

                                                                    if (temp !== undefined) {
                                                                        if (temp.includes("/")) {
                                                                            setFieldValue(values.dob = "")
                                                                            setFieldValue(values.errmsg1 = "Date should be in dd-mm-yyyy format.")

                                                                        }
                                                                        else {
                                                                       //   setFieldValue(values.dob = "")

                                                                            // setFieldValue(values.toDate = date)
                                                                            setFieldValue(values.errmsg1 = "")

                                                                        }
                                                                    }
                                                                }
                                                                }
                                                                onKeyDown={
                                                                  e=>{
                                                                    if(e.key=="Tab"){
                                                                     // ("#panNumber").focus();
                                                                document.getElementById("panNumber").focus();

                                                                    }
                                                                    
                                                                  console.log(e.target.value)
                                                                  }
                                                                }
                                                                // onBlur={
                                                                //   e=>{
                                                                //     this.updateWeightage("dob",e.target.value)
                                                          
                                                                //   }
                                                                // }
                                                                //   maxDate={new Date()}
                                                                // disabled={true}
                                                                //value={this.state.acceptData.length > 0 ? new Date(this.state.acceptData[0].executionTracker[0].additionalDetails.requestedDate) : ''}
                                                                onChange={date => {
                                                                  
                                                                  if(date==null)
                                                                  {
                                                                    setFieldValue(values.dob = "")
                                                                  }
                                                                    else{
                                                                      setFieldValue(values.dob = date)

                                                                    }   

                                                                        // if (moment(date).format("YYYY-MM-DD") !== "Invalid date") {
                                                                        //   setFieldValue(values.dateOfBirth = moment(date).format("YYYY-MM-DD"))
                                                                        // }
                                                                        // else {
                                                                        //   setFieldValue(values.dateOfBirth = "")
                                                                        // }
                                                                      //  setFieldValue(values.dob = moment(date).format("YYYY-MM-DD"))

                                                                    

                                                                    // setFieldValue(values.dateOfBirth = date)
                                                                }}
                                                                className={
                                                                    "form-control" +
                                                                    (errors.dob &&
                                                                        touched.dob
                                                                        ? " is-invalid"
                                                                        : "")
                                                                }

                                                            /> */}


<div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 pl pr">
                                            <div className="col-md-3 col-lg-3 col-sm-3 col-xs-3 pl pr">
                                              <Field
                                                as="select"
                                                name="day"
                                                id="day"
                                                // onChange={handleChange}
                                              //  disabled={this.state.isDisabled}
                                                //  disabled
                                                disabled={values.disabledForCielops}

                                                onChange={
                                                  e => {
                                                   
                                                    
                                                    var listOfMonth = []
                                                    setFieldValue(values.day = e.target.value)
                                                    if (e.target.value == 31) {
                                                      listOfMonth = [{ "key": 1, "value": "Jan" },
                                              
                                                      { "key": 3, "value": "Mar" },
                                              
                                                      { "key": 5, "value": "May" },
                                              
                                                      { "key": 7, "value": "Jul" },
                                                      { "key": 8, "value": "Aug" },
                                              
                                                      { "key": 10, "value": "Oct" },
                                              
                                                      { "key": 12, "value": "Dec" },
                                                      ];
                                              
                                              
                                                    } else if (e.target.value == 30) {
                                                      listOfMonth = [
                                              
                                                        { "key": 1, "value": "Jan" },
                                              
                                                        { "key": 3, "value": "Mar" },
                                                        { "key": 4, "value": "Apr" },
                                                        { "key": 5, "value": "May" },
                                                        { "key": 6, "value": "Jun" },
                                                        { "key": 7, "value": "Jul" },
                                                        { "key": 8, "value": "Aug" },
                                                        { "key": 9, "value": "Sep" },
                                                        { "key": 10, "value": "Oct" },
                                                        { "key": 11, "value": "Nov" },
                                                        { "key": 12, "value": "Dec" },
                                              
                                                      ];
                                              
                                              
                                                    }
                                                    else if (e.target.value == 28 || e.target.value == 29) {
                                                      listOfMonth = [{ "key": 1, "value": "Jan" },
                                                      { "key": 2, "value": "Feb" },
                                                      { "key": 3, "value": "Mar" },
                                                      { "key": 4, "value": "Apr" },
                                                      { "key": 5, "value": "May" },
                                                      { "key": 6, "value": "Jun" },
                                                      { "key": 7, "value": "Jul" },
                                                      { "key": 8, "value": "Aug" },
                                                      { "key": 9, "value": "Sep" },
                                                      { "key": 10, "value": "Oct" },
                                                      { "key": 11, "value": "Nov" },
                                                      { "key": 12, "value": "Dec" },
                                                      ];
                                                    } else {
                                                      listOfMonth = [{ "key": 1, "value": "Jan" },
                                                      { "key": 2, "value": "Feb" },
                                                      { "key": 3, "value": "Mar" },
                                                      { "key": 4, "value": "Apr" },
                                                      { "key": 5, "value": "May" },
                                                      { "key": 6, "value": "Jun" },
                                                      { "key": 7, "value": "Jul" },
                                                      { "key": 8, "value": "Aug" },
                                                      { "key": 9, "value": "Sep" },
                                                      { "key": 10, "value": "Oct" },
                                                      { "key": 11, "value": "Nov" },
                                                      { "key": 12, "value": "Dec" },
                                                      ];
                                              
                                                    }
                                              setFieldValue(values.listOfMonth= listOfMonth)

                                                    // this.setState({
                                                    //   listOfMonth: listOfMonth
                                                    // })
                                                  }}
                                              
                                                className={
                                                  "form-control" +
                                                  (errors.day && touched.day
                                                    ? "is-invalid"
                                                    : "")
                                                }
                                                className="form-control"
                                              >
                                                <option value="">DD</option>
                                                {(
                                                  values.listOfDay ||
                                                  []
                                                ).map(d => (
                                                  <option
                                                    value={d}
                                                  >
                                                    {d}
                                                  </option>
                                                ))}
                                              </Field>
                                            </div>
                                            <div className="col-md-4 col-lg-4 col-sm-4 col-xs-4 pl pr">
                                              <Field
                                                as="select"
                                                name="month"
                                                id="month"
                                                //   onChange={handleChange}
                                           //     disabled={this.state.isDisabled}
                                                //  disabled
                                                onChange={
                                                  e => {
                                                    

                                                    setFieldValue(values.month = e.target.value)
                                                    if (e.target.value == 2) {
                                                      var listOfDay = [];
                                                      for (var i = 1; i <= 29; i++) {
                                                        //    var current = year - i;
                                                        listOfDay.push(i);
                                                       // this.setState({ listOfDay: listOfDay });
                                                      }
                                                    } else if (e.target.value == 1 || e.target.value == 3 || e.target.value == 5 || e.target.value == 7 || e.target.value == 8 || e.target.value == 10 || e.target.value == 12) {
                                                      var listOfDay = [];
                                                      for (var i = 1; i <= 31; i++) {
                                                        //    var current = year - i;
                                                        listOfDay.push(i);
                                                      //  this.setState({ listOfDay: listOfDay });
                                                      }
                                                    }
                                                    else {
                                                      var listOfDay = [];
                                                      for (var i = 1; i <= 30; i++) {
                                                        //    var current = year - i;
                                                        listOfDay.push(i);
                                                      //  this.setState({ listOfDay: listOfDay });
                                                      }
                                                    }
                                                    setFieldValue(values.listOfDay=listOfDay)
                                                  }
                                                }
                                                className={
                                                  "form-control" +
                                                  (errors.month && touched.month
                                                    ? "is-invalid"
                                                    : "")
                                                }
                                                disabled={values.disabledForCielops}

                                                className="form-control"
                                              >
                                                <option value="">MM</option>
                                                {(
                                                 values.listOfMonth ||
                                                  []
                                                ).map(m => (
                                                  <option
                                                    value={m.key}
                                                  >
                                                    {m.value}
                                                  </option>
                                                ))}
                                              </Field>
                                            </div>
                                            <div className="col-md-5 col-lg-5 col-sm-5 col-xs-5 pl pr">
                                              <Field
                                                as="select"
                                                name="year"
                                                id="year"
                                               // onChange={handleChange}
                                            //    disabled={this.state.isDisabled}
                                                //  disabled
                                                className={
                                                  "form-control" +
                                                  (errors.year && touched.year
                                                    ? "is-invalid"
                                                    : "")
                                                }
                                                disabled={values.disabledForCielops}

                                                className="form-control"
                                              >
                                                <option value="">YYYY</option>
                                                {(
                                                  this.state.listOfYear1 ||
                                                  []
                                                ).map(year => (
                                                  <option
                                                    value={year}
                                                  >
                                                    {year}
                                                  </option>
                                                ))}
                                              </Field>
                                            </div>
                                          </div>



                                          { errors.dob && <div  className="validation">{errors.dob}</div>}
                                          {/* <ErrorMessage
                                            name="dob"
                                            className="validation"
                                            component="div"
                                          /> */}
                                        </div>
                                      </div>
                                      <div className="col-md-12 col-sm-12 col-xs-12"></div>
                                      <div className="row">
                                   
                                        <div className="col-md-4 col-sm-4 col-xs-12" id="pan">
                                          <label>PAN
                                          
                                        
                                            
                                          <span className="validation"> *</span></label>
                                          <Field
                                            type="text"
                                            name="panNumber"
                                            id="panNumber"
                                            maxlength="10"
                                            disabled={values.disabledForCielops}
                                           onChange={handleChange}
                                            onChange={event => {
                                            let pan=event.target.value
                                           pan= pan.toUpperCase() 
                                            setFieldValue(
                                              (values.panNumber =pan
                                                )
                                            );
                                            }}
                                            onBlur={event => {
                                              //alert(event.target.value.length)
                                             // if(event.target.value.length>9){
                                              this.updateWeightage(event.target.name,event.target.value)
                                             
                                              LoginService.TokenUser(res => {
                                               
                                           
                                                localStorage.setItem(
                                                  "jwt-token",
                                                  res.responseObject
                                                );
                                              });
                                              // RegisterUserService.checkPAN(
                                              //   "learnerID=" +
                                              //   this.props.personnelData
                                              //     .learnerID +
                                              //   "&panNumber=" +
                                              //   event.target.value +
                                              //   "&aadharNumber="+"&learnerPartyID="+localStorage.getItem("userid-Token"),
                                              //   res => {
                                              //     if (res.status === "fail") {
                                              //       setFieldValue(
                                              //         (values.panNumber = "")
                                              //       );
                                              //       error(res.reasonText, {
                                              //         ...errorNotification
                                              //        // container: "top-center"
                                              //       });
                                              //     }
                                              //   }
                                              // );

                                              }
                                            
                                            }
                                            className="form-control"
                                            placeholder="PAN NO"
                                          />

                                          <div className="col-md-12 col-sm-12">
                                            <span class="file-size-limit">
                                              PAN is compulsory for NISM
                                              Registration
                                          </span>
                                          </div>
                                          { errors.panNumber && <div  className="validation">{errors.panNumber}</div>}
                                          
                                        </div>
                                        <div className="col-md-4 col-sm-4 col-xs-12" style={{ display: "none" }}>
                                          <label>
                                            Aadhaar No{" "}
                                            <span className="validation"> *</span>
                                          </label>
                                          <Field
                                            type="text"
                                            name="aadharNumber"
                                            id="aadharNumber"
                                            maxlength="12"
                                            disabled={values.disabledForCielops}
                                            onChange={handleChange}
                                            onBlur={event => {
                                              this.updateWeightage(event.target.name,event.target.value)

                                              LoginService.TokenUser(res => {
                                                localStorage.setItem(
                                                  "jwt-token",
                                                  res.responseObject
                                                );
                                              });
                                              // RegisterUserService.checkPAN(
                                              //   "learnerID=" +
                                              //   this.props.personnelData
                                              //     .learnerID +
                                              //   "&panNumber=" +
                                              //   "&aadharNumber=" +
                                              //   event.target.value+"&learnerPartyID="+localStorage.getItem("userid-Token"),
                                              //   res => {
                                              //     if (res.status === "fail") {
                                              //       setFieldValue(
                                              //         (values.aadharNumber = "")
                                              //       );
                                              //       error(res.reasonText, {
                                              //         ...errorNotification
                                              //        // container: "top-center"
                                              //       });
                                              //     }
                                              //   }
                                              // );
                                            
                                            }}
                                            className={
                                              "form-control" +
                                              (errors.aadharNumber &&
                                                touched.aadharNumber
                                                ? " is-invalid"
                                                : "")
                                            }
                                            placeholder="Aadhaar NO"
                                          />
                                          { errors.aadharNumber && <div  className="validation">{errors.aadharNumber}</div>}
                                          {/* <ErrorMessage
                                            name="aadharNumber"
                                            className="validation"
                                            component="div"
                                          /> */}
                                        </div>
                                        <div className="col-md-4 col-sm-4 col-xs-12">
                                          <label>
                                            Passport No{" "}
                                           
                                          </label>
                                          <Field
                                            type="text"
                                            name="passportNumber"
                                            id="passportNumber"
                                            maxlength="9"
                                            disabled={values.disabledForCielops}
                                            onChange={handleChange}
                                            onBlur={event => {
                                              this.updateWeightage(event.target.name,event.target.value)
                                            }}
                                            className={
                                              "form-control" +
                                              (errors.passportNumber &&
                                                touched.passportNumber
                                                ? " is-invalid"
                                                : "")
                                            }
                                            placeholder="Passport NO"
                                          />
                                          {/* <ErrorMessage
                                            name="passportNumber"
                                            className="validation"
                                            component="div"
                                          /> */}
                                          { errors.passportNumber && <div  className="validation">{errors.passportNumber}</div>}
                                        </div>
                                        <div className="col-md-12 col-sm-12 col-xs-12"></div>
                                        <div className="col-md-4 col-sm-4 col-xs-12">
                                          <label>Upload PAN
                                          {/* <span className="validation"> *</span> */}
                                       
                                            
                                            <span className="validation"> *</span>
                                          </label>
                                          <div className="profile-detail">
                                            <div className="circle">
                                              <img
                                                className="profile-pic"
                                                src={values.panCardURL}
                                              />
                                              <div className="profile">
                                                <i
                                                  className="fa fa-user"
                                                  aria-hidden="true"
                                                ></i>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="profile-add">
                                          <a 
                                  
                                  style={values.disabledForCielops ? {  pointerEvents: "none",
                                  cursor: "default" } : {  }}
                                  
                                  >
                                            <span className="icon btn-file text-center">
                                              <i
                                                className="fa fa-plus-circle"
                                                aria-hidden="true"
                                              ></i>
                                              <FileBase64
                                                multiple={true}
                                                name="panCardURL"
                                                onChange={handleChange}
                                                onDone={event => {
                                                  // str.lastIndexOf()
                                                  // const checkType = event[0].name.split(
                                                  //   "."
                                                  // );
                                                  const checkType =event[0].name.split(/\.(?=[^\.]+$)/)
                                                  if (
                                                    [
                                                      "jpg",
                                                      "JPG",
                                                      "jpeg",
                                                      "JPEG",
                                                      "png",
                                                      "PNG",
                                                      "pdf"
                                                    ].indexOf(checkType[1]) < 0
                                                  ) {
                                                    setFieldValue(
                                                      (values.documentTypeCheckForPANImage = true)
                                                    );
                                                  } else if (
                                                    event[0].file.size <= 1000000
                                                  ) {

                                                    

                                                  if(this.checkName(event[0].name))
                                                  {

                                                   
                                                    values.document.push({
                                                      documentHash:
                                                        event[0].base64,
                                                      documentType: "PanCard",
                                                      documentName: event[0].name
                                                    });
                                                    this.updateWeightage("panCardURL",event[0].name)
                                                    setFieldValue(
                                                      (values.documentTypeCheckForPANImage = false)
                                                    );
                                                    setFieldValue(
                                                      (values.panCardName = event[0].name)
                                                    );
                                                    setFieldValue(
                                                      (values.documentSizeCheckForPanCard = false)
                                                    );
                                                    setFieldValue(
                                                      (values.documentNameValidation = false)
                                                    );
                                                    setFieldValue(
                                                      (values.panCardURL =
                                                        event[0].base64)
                                                    );
                                                      }
                                                      else{
                                                        setFieldValue(
                                                          (values.documentNameValidation = true)
                                                        );
                                                        
                                                       // warning("File/Document name can only contain number, letter, hyphens, space.", warningNotification);
                                                      }
                                                  } else {
                                                    setFieldValue(
                                                      (values.documentNameValidation = false)
                                                    );
                                                    setFieldValue(
                                                      (values.documentSizeCheckForPanCard = true)
                                                    );
                                                  }
                                                }}
                                              />
                                              <h4>Add PAN</h4>
                                            </span>
                                            </a>
                                            <h4 style={{wordBreak: "break-all"}}>
  {values.panCardName ?'':values.panpdfName?values.panpdfName.split(".")[1]=="pdf"?values.panpdfName:'':''}
                                              {values.panCardName && values.panCardName.split(".")[1]=="pdf"?"("+values.panCardName+")":''}
                                            </h4>
                                            {/* <span className="icon">
                                              <i
                                                className="fa fa-times-circle"
                                                aria-hidden="true"
                                              ></i>
                                            </span>
                                            <h4>Remove PAN</h4> */}
                                            <div className="col-md-12 col-sm-12">
                                             
                                              <span class="file-size-limit">
                                              The file should be in PNG/JPG/PDF format, with file-size less than 1MB, with no special characters in the file-name
                                                {/* Supported Formats: .jpg, .jpeg,pdf,
                                              .png only.  <br />
                                                File Size: Max 1 MB */}
                                            </span>
                                            </div>
                                            {values.documentSizeCheckForPanCard ? (
                                              <p style={{ color: "red" }}>
                                                File Size is too large(Maximum
                                                File Size:1MB).
                                            </p>
                                            ) : (
                                                ""
                                              )}
                                            {values.documentTypeCheckForPANImage ? (
                                              <p style={{ color: "red" }}>
                                                Invalid image format
                                            </p>
                                            ) : (
                                                ""
                                              )}
                 
                                         {values.documentNameValidation ? (
                                              <p style={{ color: "red" }}>
                                               File/Document name can only contain number, letter, hyphens, space.
                                            </p>
                                            ) : (
                                                ""
                                              )}
                                          </div>
                                        </div>

                                        <div className="col-md-4 col-sm-4 col-xs-12" style={{ display: "none" }}>
                                        
                                          <label>Upload Aadhaar
                                          <span className="validation"> *</span>
                                          </label>
                                          <div className="profile-detail">
                                            <div className="circle">
                                              <img
                                                className="profile-pic"
                                                src={values.aadharCardURL}
                                              />
                                              <div className="profile">
                                                <i
                                                  className="fa fa-user"
                                                  aria-hidden="true"
                                                ></i>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="profile-add">
                                          <a 
                                  
                                  style={values.disabledForCielops ? {  pointerEvents: "none",
                                  cursor: "default" } : {  }}
                                  
                                  >
                                            <span className="icon btn-file text-center">
                                       
                                              <i
                                                className="fa fa-plus-circle"
                                                aria-hidden="true"
                                              ></i>
                                              <FileBase64
                                                multiple={true}
                                                name="aadharCardURL"
                                                onChange={handleChange}
                                                onDone={event => {
                                                  // const checkType = event[0].name.split(
                                                  //   "."
                                                  // );
                                                  const checkType =event[0].name.split(/\.(?=[^\.]+$)/)

                                                  if (
                                                    [
                                                      "jpg",
                                                      "JPG",
                                                      "jpeg",
                                                      "JPEG",
                                                      "png",
                                                      "PNG",
                                                      "pdf"
                                                    ].indexOf(checkType[1]) < 0
                                                  ) {
                                                    setFieldValue(
                                                      (values.documentTypeCheckForAadharImage = true)
                                                    );
                                                  } else if (
                                                    event[0].file.size <= 1000000
                                                  ) {


                                                    if(this.checkName(event[0].name))
                                                    {
                                                    values.document.push({
                                                      documentHash:
                                                        event[0].base64,
                                                      documentType: "AadharCard",
                                                      documentName: event[0].name
                                                    });
                                                    this.updateWeightage("aadharCardURL",event[0].name)
                                                    setFieldValue(
                                                      (values.documentTypeCheckForAadharImage = false)
                                                    );
                                                    setFieldValue(
                                                      (values.documentSizeCheckForAadharCard = false)
                                                    );
                                                    setFieldValue(
                                                      (values.adharDocumentNameValidation = false)
                                                    );
                                                    setFieldValue(
                                                      (values.aadharCardDocumentName =
                                                        event[0].name)
                                                    );
                                                    setFieldValue(
                                                      (values.aadharCardURL =
                                                        event[0].base64)
                                                    );
                                                      }
                                                      else{
                                                       // warning("File/Document name can only contain number, letter, hyphens, space.", warningNotification);
                                                       setFieldValue(
                                                        (values.adharDocumentNameValidation = true)
                                                      );
                                                      }
                                                  } else {
                                                    setFieldValue(
                                                      (values.adharDocumentNameValidation = false)
                                                    );
                                                    setFieldValue(
                                                      (values.documentSizeCheckForAadharCard = true)
                                                    );

                                                  }
                                                }}
                                              />
                                               <h4>Add Aadhaar Front Part</h4>
                                             
                                            </span>
                                            </a>
                                            
                                            <h4 style={{wordBreak: "break-all"}}>
                                             
  {values.aadharCardDocumentName ?'':values.aadharpdfName?values.aadharpdfName.split(".")[1]=="pdf"?values.aadharpdfName:'':''}
                                               {values.aadharCardDocumentName && values.aadharCardDocumentName.split(".")[1]=="pdf"?"("+values.aadharCardDocumentName+")":''}
                                                </h4>
                                          
                                            <div className="col-md-12 col-sm-12">
                                              <span class="file-size-limit">
                                              The file should be in PNG/JPG/PDF format, with file-size less than 1MB, with no special characters in the file-name
                                                {/* Supported Formats: .jpg, .jpeg,pdf,
                                              .png only. <br />
                                                File Size: Max 1 MB */}
                                            </span>
                                            </div>
                                            {values.documentSizeCheckForAadharCard ? (
                                              <p style={{ color: "red" }}>
                                                File Size is too large(Maximum
                                                File Size:1MB).
                                            </p>
                                            ) : (
                                                ""
                                              )}
                                            {values.documentTypeCheckForAadharImage ? (
                                              <p style={{ color: "red" }}>
                                                Invalid image format
                                            </p>
                                            ) : (
                                                ""
                                              )}
                                                {values.adharDocumentNameValidation ? (
                                              <p style={{ color: "red" }}>
                                               File/Document name can only contain number, letter, hyphens, space.
                                            </p>
                                            ) : (
                                                ""
                                              )}
                                          </div>
                                          </div>
                                          
                                        <div className="col-md-4 col-sm-4 col-xs-12" style={{ display: "none" }}>
                                          <label>Upload Aadhaar
                                          <span className="validation"> *</span>
                                          </label>
                                          <div className="profile-detail">
                                            <div className="circle">
                                              <img
                                                className="profile-pic"
                                                src={values.BackaadharCardBackURL}
                                              />
                                              <div className="profile">
                                                <i
                                                  className="fa fa-user"
                                                  aria-hidden="true"
                                                ></i>
                                              </div>
                                            </div>
                                          </div>

                                          <div className="profile-add">
                                          <a 
                                  
                                  style={values.disabledForCielops ? {  pointerEvents: "none",
                                  cursor: "default" } : {  }}
                                  
                                  >
                                            <span className="icon btn-file text-center">
                                              <i
                                                className="fa fa-plus-circle"
                                                aria-hidden="true"
                                              ></i>
                                              <FileBase64
                                                multiple={true}
                                                name="AadharCardBackSide"
                                                onChange={handleChange}
                                                onDone={event => {
                                                  // const checkType = event[0].name.split(
                                                  //   "."
                                                  // );
                                                  const checkType =event[0].name.split(/\.(?=[^\.]+$)/)

                                                  if (
                                                    [
                                                      "jpg",
                                                      "JPG",
                                                      "jpeg",
                                                      "JPEG",
                                                      "png",
                                                      "PNG",
                                                      "pdf"
                                                    ].indexOf(checkType[1]) < 0
                                                  ) {
                                                    setFieldValue(
                                                      (values.documentTypeCheckForBackAadharImage = true)
                                                    );
                                                  } else if (
                                                    event[0].file.size <= 1000000
                                                  ) {


                                                    if(this.checkName(event[0].name))
                                                    {
                                                    values.document.push({
                                                      documentHash:
                                                        event[0].base64,
                                                      documentType: "AadharCardBackSide",
                                                      documentName: event[0].name
                                                    });
                                                this.updateWeightage("BackaadharCardBackURL",event[0].name)
                                                    setFieldValue(
                                                      (values.documentTypeCheckForBackAadharImage = false)
                                                    );
                                                    setFieldValue(
                                                      (values.documentSizeCheckForBackAadharCard = false)
                                                    );
                                                    setFieldValue(
                                                      (values.BackadharDocumentNameValidation = false)
                                                    );
                                                    setFieldValue(
                                                      (values.BackaadharCardDocumentName =
                                                        event[0].name)
                                                    );
                                                    setFieldValue(
                                                      (values.BackaadharCardBackURL =
                                                        event[0].base64)
                                                    );
                                                      }
                                                      else{
                                                       // warning("File/Document name can only contain number, letter, hyphens, space.", warningNotification);
                                                       setFieldValue(
                                                        (values.BackadharDocumentNameValidation = true)
                                                      );
                                                      }
                                                  } else {
                                                    setFieldValue(
                                                      (values.BackadharDocumentNameValidation = false)
                                                    );
                                                    setFieldValue(
                                                      (values.BackdocumentSizeCheckForAadharCard = true)
                                                    );

                                                  }
                                                }}
                                              />
                                               <h4>Add Aadhaar Back Part</h4>
                                             
                                            </span>
                                            </a>
                                            <h4 style={{wordBreak: "break-all"}}>
  {values.BackaadharCardDocumentName ?'':values.backaadharpdfName?values.backaadharpdfName.split(".")[1]=="pdf"?values.backaadharpdfName:'':''}
                                               {values.BackaadharCardDocumentName && values.BackaadharCardDocumentName.split(".")[1]=="pdf"?"("+values.BackaadharCardDocumentName+")":''}
                                                </h4>
                                          
                                            <div className="col-md-12 col-sm-12">
                                              <span class="file-size-limit">
                                              The file should be in PNG/JPG/PDF format, with file-size less than 1MB, with no special characters in the file-name
                                                {/* Supported Formats: .jpg, .jpeg,pdf,
                                              .png only. <br />
                                                File Size: Max 1 MB */}
                                            </span>
                                            </div>
                                            {values.documentSizeCheckForBackAadharCard ? (
                                              <p style={{ color: "red" }}>
                                                File Size is too large(Maximum
                                                File Size:1MB).
                                            </p>
                                            ) : (
                                                ""
                                              )}
                                            {values.documentTypeCheckForBackAadharImage ? (
                                              <p style={{ color: "red" }}>
                                                Invalid image format
                                            </p>
                                            ) : (
                                                ""
                                              )}
                                                {values.BackadharDocumentNameValidation ? (
                                              <p style={{ color: "red" }}>
                                               File/Document name can only contain number, letter, hyphens, space.
                                            </p>
                                            ) : (
                                                ""
                                              )}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </section>
                               

                             
                              {/* <UserAddressDetails /> */}
                              <section
                                className="create-address page-section profile-box"
                                id="create-address"
                              >
                                <h2 className="sub-header">Address Details</h2>
                                <div className="address-border">
                                  <div className="form-group">
                                    <div className="row">
                                      <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                                        <h5 className="address-heading">
                                          Correspondence Address
                                      </h5>
                                      </div>
                                      <div className="col-md-4 col-sm-4 col-xs-12">
                                        <label>
                                          Address Line 1
                                        <span className="validation"> *</span>
                                        </label>
                                        <Field
                                          type="text"
                                          id="corStreet"
                                          name="corStreet"
                                          onChange={handleChange}
                                          disabled={values.disabledForCielops}
                                          autoComplete="corStreet"
                                          
                                          onBlur={event => {
                                            this.updateAddressWeightage(event.target.name,event.target.value)
                                          }}
                                          className={
                                            "form-control" +
                                            (errors.corStreet && touched.corStreet
                                              ? " is-invalid"
                                              : "")
                                          }
                                          placeholder="Address"
                                        />
                                        { errors.corStreet && <div  className="validation">{errors.corStreet}</div>}
                                        {/* <ErrorMessage
                                          name="corStreet"
                                          className="validation"
                                          component="div"
                                        /> */}
                                      </div>
                                      <div className="col-md-4 col-sm-4 col-xs-12">
                                        <label>Address Line 2</label>
                                        <Field
                                          type="text"
                                          name="corStreet2"
                                          onChange={handleChange}
                                          disabled={values.disabledForCielops}
                                          onBlur={event => {
                                            this.updateAddressWeightage(event.target.name,event.target.value)
                                          }}
                                          autoComplete="corStreet2"
                                          className="form-control"
                                          placeholder="Address"
                                        />
                                      </div>
                                      <div className="col-md-4 col-sm-4 col-xs-12">
                                        <label>Address Line 3</label>
                                        <Field
                                          type="text"
                                          name="corStreet3"
                                          onChange={handleChange}
                                          disabled={values.disabledForCielops}
                                          onBlur={event => {
                                            this.updateAddressWeightage(event.target.name,event.target.value)
                                          }}
                                          autoComplete="corStreet3"
                                          className="form-control"
                                          placeholder="Address"
                                        />
                                      </div>
                                      
                                    </div>
                                    <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12"></div>
                                    <div className="row">
                                    <div className="col-md-4 col-sm-4 col-xs-12">
                                        <label>
                                          Country
                                        <span className="validation"> *</span>
                                        </label>
                                        <Field
                                          as="select"
                                          name="corcountryId"
                                          id="corcountryId"
                                          disabled={values.disabledForCielops}
                                          onChange={event => {
                                            
                                            LoginService.TokenUser(res => {
                                              localStorage.setItem(
                                                "jwt-token",
                                                res.responseObject
                                              );
                                            });
                                            if (
                                              event.target.value !== undefined && event.target.value!="0"
                                            ) {
                                              setFieldValue(
                                                (values.corcountryId =
                                                  event.target.value)
                                              );
                                              values.stateListForCor = [];
                                              values.cityListForCor = [];
                                              RegionManagementService.getState(
                                                "countryId=" + event.target.value,
                                                res => {
                                                  setFieldValue(
                                                    (values.stateListForCor =
                                                      res.responseListObject)
                                                  );
                                                }
                                              );
                                            }else{
                                              
                                              setFieldValue(
                                                (values.corcountryId =
                                                  "")
                                              );
                                              setFieldValue(
                                                (values.corcityId =""
                                                  )
                                              );
                                              setFieldValue(
                                                (values.corstateId =""
                                                  )
                                              );
                                              setFieldValue(
                                                (values.stateListForCor =[]
                                                  )
                                              );
                                              setFieldValue(
                                                (values.cityListForCor =[]
                                                  )
                                              );
                                            }
                                          }}
                                          onBlur={event => {
                                            this.updateAddressWeightage(event.target.name,event.target.value)
                                          }}
                                          className={
                                            "form-control" +
                                            (errors.corcountryId &&
                                              touched.corcountryId
                                              ? " is-invalid"
                                              : "")
                                          }
                                        >
                                          <option value="0">---Select----</option>
                                          {values.countryList.map(team =>
                                            team.isActive ? (
                                              <option
                                                key={team.countryId}
                                                value={team.countryId}
                                              >
                                                {this.titleCase(team.countryName)}
                                              </option>
                                            ) : (
                                                ""
                                              )
                                          )}
                                        </Field>
                                        {/* <ErrorMessage
                                          name="corcountryId"
                                          component="div"
                                          className="validation"
                                        /> */}
                                        { errors.corcountryId && <div  className="validation">{errors.corcountryId}</div>}
                                      </div>
                                      <div className="col-md-4 col-sm-4 col-xs-12">
                                        <label>
                                          State
                                        <span className="validation"> *</span>
                                        </label>
                                        <Field
                                          as="select"
                                          name="corstateId"
                                          disabled={values.disabledForCielops}
                                          id="corstateId"
                                          onChange={event => {
                                           
                                            console.log(event.target.value)
                                            if(event.target.value=="")
                                            {
                                              setFieldValue(
                                                (values.corcityId =""
                                                  )
                                              );
                                              setFieldValue(
                                                (values.corstateId =""
                                                  )
                                              );
                                            }
                                            else{
                                            LoginService.TokenUser(res => {
                                              localStorage.setItem(
                                                "jwt-token",
                                                res.responseObject
                                              );
                                            });
                                            if (
                                              event.target.value !== undefined
                                            ) {
                                              setFieldValue(
                                                (values.corstateId =
                                                  event.target.value)
                                              );
                                              RegionManagementService.getCity(
                                                "stateId=" + event.target.value,
                                                res => {
                                                  setFieldValue(
                                                    (values.cityListForCor =
                                                      res.responseListObject)
                                                  );
                                                }
                                              );
                                            }
                                          }
                                          }}
                                          onBlur={event => {
                                            this.updateAddressWeightage(event.target.name,event.target.value)
                                          }}
                                          className={
                                            "form-control" +
                                            (errors.corstateId &&
                                              touched.corstateId
                                              ? " is-invalid"
                                              : "")
                                          }
                                        >
                                          <option value="">---Select----</option>
                                          {(values.stateListForCor || []).map(
                                            team => (
                                              <option
                                                key={team.stateId}
                                                value={team.stateId}
                                              >
                                                {this.titleCase(team.stateName)}
                                              </option>
                                            )
                                          )}
                                        </Field>
                                        {/* <ErrorMessage
                                          name="corstateId"
                                          component="div"
                                          className="validation"
                                        /> */}
                                        { errors.corstateId && <div  className="validation">{errors.corstateId}</div>}
                                      </div>
                                      <div className="col-md-4 col-sm-4 col-xs-12">
                                        <label>
                                          City
                                        <span className="validation"> *</span>
                                       
                                        </label>
                                        <Field
                                          as="select"
                                          name="corcityId"
                                          id="corcityId"
                                          disabled={values.disabledForCielops}
                                          onChange={event => {
                                            if (
                                              event.target.value !== undefined
                                            ) {
                                              setFieldValue(
                                                (values.corcityId =
                                                  event.target.value)
                                              );
                                            }
                                          }}
                                          className={
                                            "form-control" +
                                            (errors.corcityId && touched.corcityId
                                              ? " is-invalid"
                                              : "")
                                          }
                                        >
                                          <option value="">---Select----</option>
                                          {(values.cityListForCor || []).map(
                                            team => (
                                              <option
                                                key={team.cityId}
                                                value={team.cityId}
                                              >
                                                {this.titleCase(team.cityName)}
                                              </option>
                                            )
                                          )}
                                           onBlur={event => {
                                            this.updateAddressWeightage(event.target.name,event.target.value)
                                          }}
                                        </Field>
                                        {/* <ErrorMessage
                                          name="corcityId"
                                          component="div"
                                          className="validation"
                                        /> */}
                                         { errors.corcityId && <div  className="validation">{errors.corcityId}</div>}
                                      </div>
                                      <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12"></div>
                                      <div className="col-md-4 col-sm-4 col-xs-12">
                                        <label>
                                          pin Code
                                        <span className="validation"> *</span>
                                        </label>
                                        <Field
                                          type="text"
                                          name="corPostalCode"
                                          id="corPostalCode"
                                          maxlength="6"
                                          disabled={values.disabledForCielops}
                                          onChange={handleChange}
                                          onBlur={event => {
                                          

                                            fetch('https://api.postalpincode.in/pincode/'+event.target.value)
                                            .then(response => response.json())
                                            .then(records => {
                                              console.log(records)
                                              if(records[0].Status==="Success")
                                              {
                                                setFieldValue(values.corPostalCode=event.target.value)
                                                this.updateAddressWeightage("corPostalCode",event.target.value)
                                              }
                                              else{
                                                
                                                setFieldValue(values.corPostalCode="")
                                                this.updateAddressWeightage("corPostalCode","")

                                              }
                                      
                                          }
                                       
                                          )
                                          .catch(error => 
                                            {
                                            });
  
  
                                              }}
                                          autoComplete="corPostalCode"
                                          // onBlur={event => {
                                          //   this.updateAddressWeightage(event.target.name,event.target.value)
                                          // }}
                                          className={
                                            "form-control" +
                                            (errors.corPostalCode &&
                                              touched.corPostalCode
                                              ? " is-invalid"
                                              : "")
                                          }
                                          placeholder="PIN"
                                        />
                                        {/* <ErrorMessage
                                          name="corPostalCode"
                                          className="validation"
                                          component="div"
                                        /> */}
                                        { errors.corPostalCode && <div  className="validation">{errors.corPostalCode}</div>}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="address-border">
                                  <div className="form-group">
                                    <div className="row">
                                      <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                                        <div class="row row-no-margin">
                                          <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                                            <div class="col-md-9 col-lg-9 col-sm-9 col-xs-12 pl">
                                              <h5 class="address-heading">
                                                Permanent Address{" "}
                                              </h5>
                                            </div>
                                            <div class="col-md-3 col-lg-3 col-sm-3 col-xs-12">
                                              <div class="form-check pull-right">
                                             
                                                <Field
                                                  className="form-check-input"
                                                  type="checkbox"
                                                  name="sameAsAbove"
                                                  style={{marginRight: "10px"}}
                                                  checked={values.addressFlag}
                                                  onClick={event => {
                                                    
                                                    if (event.target.checked) {
                                                      let count=0
                                                     
     if( values.corStreet!=="" &&  values.corStreet!==undefined)
     {
      this.updateAddressWeightage("perStreet","perStreet")
       count=count+1
     }if( values.corStreet2!=="" && values.corStreet2!==undefined)
     {
      this.updateAddressWeightage("perStreet2","perStreet2")
       count=count+1
     }if( values.corStreet3!=="" && values.corStreet3!==undefined)
     {
      this.updateAddressWeightage("perStreet3","perStreet3")
       count=count+1
     }if( values.corcountryId!=="" && values.corcountryId!==undefined)
     {
      this.updateAddressWeightage("percountryId","percountryId")
       count=count+1
     }if( values.corstateId!=="" && values.corstateId!==undefined)
     {
      this.updateAddressWeightage("perstateId","perstateId")
       count=count+1
     }if( values.corcityId!=="" && values.corcityId!==undefined)
     {
      this.updateAddressWeightage("percityId","percityId")
       count=count+1
     }
     if( values.corPostalCode!=="" && values.corPostalCode!==undefined)
     {
      this.updateAddressWeightage("perPostalCode","percityId")
       count=count+1
     }

   
    // setFieldValue(
    //   (values.sameasAboveCount =
    //    count)
    // );

                                                    //  this.updateAddressWeightage("sameasabove",count)

                                                      values.stateListForPer =
                                                        values.stateListForCor;
                                                      values.cityListForPer =
                                                        values.cityListForCor;
                                                      values.perStreet =
                                                        values.corStreet;
                                                      values.perStreet2 =
                                                        values.corStreet2;
                                                        values.perStreet3 =
                                                        values.corStreet3;
                                                      values.percountryId =
                                                        values.corcountryId;
                                                      values.perstateId =
                                                        values.corstateId;
                                                      values.percityId =
                                                        values.corcityId;
                                                      values.perPostalCode =
                                                        values.corPostalCode;
                                                      values.addressFlag ="checked"
                                                      //  "checked";
                                                    } else {
                                                  //this.updateAddressWeightage("unCheckSameasabove",values.sameasAboveCount)
                                                  
                                                      values.perStreet = "";
                                                      values.perStreet2 = "";
                                                      values.perStreet3 = "";
                                                      values.percountryId = "";
                                                      values.perstateId = "";
                                                      values.percityId = "";
                                                      values.perPostalCode = "";
                                                      values.addressFlag = "";
                                                      values.stateListForPer = [];
                                                      values.cityListForPer = [];
                                                    }
                                                  }}
                                                />
                                                <label
                                                  class="form-check-label"
                                                  for="gridCheck"
                                                >
                                                  Same as Above
                                              </label>
                                              </div>
                                            </div>
                                          </div>
                                        </div>{" "}
                                      </div>
                                    </div>
                                    <div class="row">
                                      <div className="col-md-4 col-sm-4 col-xs-12">
                                        <label>
                                          Address Line 1{" "}
                                          <span className="validation"> *</span>
                                        </label>
                                        <Field
                                          type="text"
                                          name="perStreet"
                                          id="perStreet"
                                          autoComplete="perStreet"
                                          disabled={values.disabledForCielops}
                                          onChange={handleChange}
                                          onBlur={event => {
                                            this.updateAddressWeightage(event.target.name,event.target.value)
                                          }}
                                          className={
                                            "form-control" +
                                            (errors.perStreet && touched.perStreet
                                              ? " is-invalid"
                                              : "")
                                          }
                                          placeholder="Address"
                                        />
                                        {/* <ErrorMessage
                                          name="perStreet"
                                          className="validation"
                                          component="div"
                                        /> */}
                                         { errors.perStreet && <div  className="validation">{errors.perStreet}</div>}
                                      </div>
                                      <div className="col-md-4 col-sm-4 col-xs-12">
                                        <label>Address Line 2</label>
                                        <Field
                                          type="text"
                                          name="perStreet2"
                                          id="perStreet2"
                                          onChange={handleChange}
                                          disabled={values.disabledForCielops}
                                          onBlur={event => {
                                            this.updateAddressWeightage(event.target.name,event.target.value)
                                          }}
                                          autoComplete="perStreet2"
                                          className="form-control"
                                          placeholder="Address"
                                        />
                                      </div>
                                      <div className="col-md-4 col-sm-4 col-xs-12">
                                        <label>Address Line 3</label>
                                        <Field
                                          type="text"
                                          name="perStreet3"
                                          id="perStreet4"
                                          onChange={handleChange}
                                          disabled={values.disabledForCielops}
                                          onBlur={event => {
                                            this.updateAddressWeightage(event.target.name,event.target.value)
                                          }}
                                          autoComplete="perStreet3"
                                          className="form-control"
                                          placeholder="Address"
                                        />
                                      </div>
                                      
                                    </div>
                                    <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12"></div>
                                    <div class="row">
                                    <div className="col-md-4 col-sm-4 col-xs-12">
                                        <label>
                                          Country
                                        <span className="validation"> *</span>
                                        </label>
                                        <Field
                                          as="select"
                                          name="percountryId"
                                          id="percountryId"
                                          disabled={values.disabledForCielops}
                                          onChange={event => {
                                            
                                            LoginService.TokenUser(res => {
                                              localStorage.setItem(
                                                "jwt-token",
                                                res.responseObject
                                              );
                                            });
                                            if (
                                              event.target.value !== undefined && event.target.value!=="0"
                                            ) {
                                              setFieldValue(
                                                (values.percountryId =
                                                  event.target.value)
                                              );
                                              values.stateListForPer = [];
                                              values.cityListForPer = [];

                                              RegionManagementService.getState(
                                                "countryId=" + event.target.value,
                                                res => {
                                                  setFieldValue(
                                                    (values.stateListForPer =
                                                      res.responseListObject)
                                                  );
                                                }
                                              );
                                            }else{
                                              setFieldValue(
                                                (values.percountryId =
                                                  "")
                                              );
                                              setFieldValue(
                                                (values.percityId =""
                                                  )
                                              );
                                              setFieldValue(
                                                (values.perstateId =""
                                                  )

                                              );

                                              setFieldValue(
                                                (values.cityListForPer =[]
                                                  )

                                              );
                                              setFieldValue(
                                                (values.stateListForPer =[]
                                                  )

                                              );


                                              
                                            }
                                          }}
                                          onBlur={event => {
                                            this.updateAddressWeightage(event.target.name,event.target.value)
                                          }}
                                        
                                          className={
                                            "form-control" +
                                            (errors.percountryId &&
                                              touched.percountryId
                                              ? " is-invalid"
                                              : "")
                                          }
                                        >
                                          <option value="0">---Select----</option>
                                          {values.countryList.map(team =>
                                            team.isActive ? (
                                              <option
                                                key={team.countryId}
                                                value={team.countryId}
                                              >
                                                {this.titleCase(team.countryName)}
                                              </option>
                                            ) : (
                                                ""
                                              )
                                          )}
                                        </Field>
                                        { errors.percountryId && <div  className="validation">{errors.percountryId}</div>}
                                        {/* <ErrorMessage
                                          name="percountryId"
                                          component="div"
                                          className="validation"
                                        /> */}
                                      </div>
                                      <div className="col-md-4 col-sm-4 col-xs-12">
                                        <label>
                                          State
                                        <span className="validation"> *</span>
                                        </label>
                                        <Field
                                          as="select"
                                          name="perstateId"
                                          id="perstateId"
                                          disabled={values.disabledForCielops}
                                          onChange={event => {
                                            if(event.target.value=="")
                                            {
                                              setFieldValue(
                                                (values.percityId =""
                                                  )
                                              );
                                              setFieldValue(
                                                (values.perstateId =""
                                                  )
                                              );
                                            }
                                            else{
                                            LoginService.TokenUser(res => {
                                              localStorage.setItem(
                                                "jwt-token",
                                                res.responseObject
                                              );
                                            });
                                            if (
                                              event.target.value !== undefined
                                            ) {
                                              setFieldValue(
                                                (values.perstateId =
                                                  event.target.value)
                                              );

                                              RegionManagementService.getCity(
                                                "stateId=" + event.target.value,
                                                res => {
                                                  setFieldValue(
                                                    (values.cityListForPer =
                                                      res.responseListObject)
                                                  );
                                                }
                                              );
                                            }
                                          }
                                          }}
                                          onBlur={event => {
                                            this.updateAddressWeightage(event.target.name,event.target.value)
                                          }}
                                          className={
                                            "form-control" +
                                            (errors.perstateId &&
                                              touched.perstateId
                                              ? " is-invalid"
                                              : "")
                                          }
                                        >
                                          <option value="">---Select----</option>
                                          {(values.stateListForPer || []).map(
                                            team => (
                                              <option
                                                key={team.stateId}
                                                value={team.stateId}
                                              >
                                                {this.titleCase(team.stateName)}
                                              </option>
                                            )
                                          )}
                                        </Field>
                                        { errors.perstateId && <div  className="validation">{errors.perstateId}</div>}
                                        {/* <ErrorMessage
                                          name="perstateId"
                                          component="div"
                                          className="validation"
                                        /> */}
                                      </div>
                                      <div className="col-md-4 col-sm-4 col-xs-12">
                                        <label>
                                          City
                                        <span className="validation"> *</span>
                                        </label>
                                        <Field
                                          as="select"
                                          name="percityId"
                                          id="percityId"
                                          disabled={values.disabledForCielops}
                                          onChange={event => {
                                            if (event.target.value !== undefined)
                                              setFieldValue(
                                                (values.percityId =
                                                  event.target.value)
                                              );
                                          }}
                                          onBlur={event => {
                                            this.updateAddressWeightage(event.target.name,event.target.value)
                                          }}
                                          className={
                                            "form-control" +
                                            (errors.percityId && touched.percityId
                                              ? " is-invalid"
                                              : "")
                                          }
                                        >
                                          <option value="">---Select----</option>
                                          {(values.cityListForPer || []).map(
                                            team => (
                                              <option
                                                key={team.cityId}
                                                value={team.cityId}
                                              >
                                                {this.titleCase(team.cityName)}
                                              </option>
                                            )
                                          )}
                                        </Field>
                                        {/* <ErrorMessage
                                          name="percityId"
                                          component="div"
                                          className="validation"
                                        /> */}
                                         { errors.percityId && <div  className="validation">{errors.percityId}</div>}
                                      </div>
                                      <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12"></div>
                                      <div className="col-md-4 col-sm-4 col-xs-12">
                                        <label>
                                        pin Code
                                        <span className="validation"> *</span>
                                        </label>
                                        <Field
                                          type="text"
                                          name="perPostalCode"
                                          id="perPostalCode"
                                          disabled={values.disabledForCielops}
                                          maxlength="6"
                                          onChange={handleChange}
                                          // onBlur={event => {
                                          //   this.updateAddressWeightage(event.target.name,event.target.value)
                                          // }}
                                          onBlur={event => {
                                          

                                            fetch('https://api.postalpincode.in/pincode/'+event.target.value)
                                            .then(response => response.json())
                                            .then(records => {
                                              console.log(records)
                                              if(records[0].Status==="Success")
                                              {
                                                setFieldValue(values.perPostalCode=event.target.value)
                                                this.updateAddressWeightage("perPostalCode",event.target.value)
                                              }
                                              else{
                                                setFieldValue(values.perPostalCode="")
                                                this.updateAddressWeightage("perPostalCode","")

                                              }
                                      
                                          }
                                       
                                          )
                                          .catch(error => 
                                            {
                                            });
  
  
                                              }}
                                          autoComplete="perPostalCode"
                                          className={
                                            "form-control" +
                                            (errors.perPostalCode &&
                                              touched.perPostalCode
                                              ? " is-invalid"
                                              : "")
                                          }
                                          placeholder="PIN"
                                        />
                                        {/* <ErrorMessage
                                          name="perPostalCode"
                                          className="validation"
                                          component="div"
                                        /> */}
                                          { errors.perPostalCode && <div  className="validation">{errors.perPostalCode}</div>}
                                      </div>
                                      <div className="col-md-4 col-sm-4 col-xs-12">                                      <label>
                                        Proof Of Address
                                        
                                        <span className="validation"> *</span>
                                        </label>
                                      <Field
                                          as="select"
                                          name="peraddressproof1"
                                          id="peraddressproof1"
                                          disabled={values.disabledForCielops}
                                          onChange={handleChange}
                                          onBlur={event => {
                                            this.updateAddressWeightage(event.target.name,event.target.value)
                                          }}
                                          className={
                                            "form-control" +
                                            (errors.peraddressproof1 && touched.peraddressproof1
                                              ? " is-invalid"
                                              : "")
                                          }
                                        >
                                          <option value="">---Select----</option>
                                          {(this.state.AddressProof || []).map(
                                            team => (
                                              <option
                                                key={team.codeValueId}
                                                value={team.codeValueId}
                                              >
                                                {team.codeValue}
                                              </option>
                                            )
                                          )}
                                        </Field>
                                        <ErrorMessage
                                          name="peraddressproof1"
                                          component="div"
                                          className="validation"
                                        />
                                        <span class="file-size-limit"> NISM requires an Address Proof apart from your 
                                        Aadhaar. Please choose one of the documents from the dropdown and attach a 
                                        scanned image of the same </span>
                                        </div>
                                      <div className="col-md-4 col-sm-4 col-xs-12">
                                          <label>Upload Address Proof
                                          <span className="validation"> *</span>

                                          </label>
                                          <div className="profile-detail">
                                            <div className="circle">
                                              <img
                                                className="profile-pic"
                                                src={values.addressProofCardURL}
                                              />
                                              <div className="profile">
                                                <i
                                                  className="fa fa-user"
                                                  aria-hidden="true"
                                                ></i>
                                              </div>
                                            </div>
                                          </div>
                                      <div className="profile-add">
                                      <a 
                                  
                                  style={values.disabledForCielops ? {  pointerEvents: "none",
                                  cursor: "default" } : {  }}
                                  
                                  >
                                            <span className="icon btn-file text-center">
                                              <i
                                                className="fa fa-plus-circle"
                                                aria-hidden="true"
                                              ></i>
                                              <FileBase64
                                                multiple={true}
                                                name="addressProofCardURL"
                                                onChange={handleChange}
                                                onDone={event => {
                                                  // const checkType = event[0].name.split(
                                                  //   "."
                                                  // );
                                                  const checkType =event[0].name.split(/\.(?=[^\.]+$)/)

                                                  if (
                                                    [
                                                      "jpg",
                                                      "JPG",
                                                      "jpeg",
                                                      "JPEG",
                                                      "png",
                                                      "PNG",
                                                      "pdf"
                                                    ].indexOf(checkType[1]) < 0
                                                  ) {
                                                    setFieldValue(
                                                      (values.documentTypeCheckForAdharImage = true)
                                                    );
                                                  }
                                                  
                                                  else if (
                                                    event[0].file.size <= 1000000
                                                  ) {


                                                    if(this.checkName(event[0].name))
                                                    {

                                                    values.document.push({
                                                      documentHash:
                                                        event[0].base64,
                                                      documentType: "AddressProof",
                                                      documentName: event[0].name
                                                    });
                                                    this.updateAddressWeightage("addressProofURL",event[0].name)
                                                    setFieldValue(
                                                      (values.documentTypeCheckForaddressProofCardURLImage = false)
                                                    );
                                                    setFieldValue(
                                                      (values.documentSizeCheckForaddressProofCardURLCard = false)
                                                    );
                                                    setFieldValue(
                                                      (values.AressProofDocumentNameValidation = false)
                                                    );

                                                    //setFieldValue((values.panCardDocumentName = event[0].name));
                                                    setFieldValue(
                                                      (values.addressProofCardURL =
                                                        event[0].base64)
                                                    );
                                                    setFieldValue(
                                                      (values.addressProofName =
                                                        event[0].name)
                                                    );
                                                      }
                                                      else{
                                                        setFieldValue(
                                                          (values.AressProofDocumentNameValidation = true)
                                                        );

                                                      //  warning("File/Document name can only contain number, letter, hyphens, space.", warningNotification);
                                                      }
                                                  } else {
                                                    setFieldValue(
                                                      (values.AressProofDocumentNameValidation = false)
                                                    );

                                                    setFieldValue(
                                                      (values.documentSizeCheckForaddressProofCardURL = true)
                                                    );
                                                  }
                                                }}
                                              />
                                              <h4>Add Address Proof</h4>
                                              <h4 style={{wordBreak: "break-all"}}>
                                                
  {values.addressProofName ?'':values.addressProofName1?values.addressProofName1.split(".")[1]=="pdf"?values.addressProofName1:'':''}
                                              {values.addressProofName && values.addressProofName.split(".")[1]=="pdf"?"("+values.addressProofName+")":''}
                                            </h4>
                                            </span>
                                           </a>
                                            <div className="col-md-12 col-sm-12">
                                              <span class="file-size-limit">
                                              The file should be in PNG/JPG/PDF format, with file-size less than 1MB, with no special characters in the file-name
                                                {/* Supported Formats: .jpg, .jpeg,pdf,
                                              .png only. <br />
                                                File Size: Max 1 MB */}
                                            </span>
                                            </div>
                                            {values.documentSizeCheckForaddressProofCardURL ? (
                                              <p style={{ color: "red" }}>
                                                File Size is too large(Maximum
                                                File Size:1MB).
                                            </p>
                                            ) : (
                                                ""
                                              )}
                                            {values.documentTypeCheckForaddressProofCardURL ? (
                                              <p style={{ color: "red" }}>
                                                Invalid image format
                                            </p>
                                            ) : (
                                                ""
                                              )}
                                                {values.AressProofDocumentNameValidation ? (
                                              <p style={{ color: "red" }}>
                                               File/Document name can only contain number, letter, hyphens, space.
                                            </p>
                                            ) : (
                                                ""
                                              )}
                                          </div>
                                          </div>
                                    </div>
                                  </div>
                                </div>
                              </section>

                              {/* <section
                                className="create-parent page-section"
                                id="create-parent"
                              >
                                <div className="row">
                                  <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                                    <h2 className="sub-header">Parent Details</h2>
                                    <div className="address-border">
                                      <div className="form-group">
                                        <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 pl pr">
                                          <h5 className="address-heading">
                                            Father Details
                                        </h5>
                                        </div>
                                        <div className="form-group">
                                          <div className="row">
                                            <div className="col-md-4 col-sm-4 col-xs-12">
                                              <label>Father Name </label>
                                              <Field
                                                type="text"
                                                name="learnerFatherName"
                                                id="learnerFatherName"
                                                onChange={handleChange}
                                                autoComplete="learnerFatherName"
                                                className="form-control"
                                                placeholder="Father Name"
                                              />
                                            </div>
                                            <div className="col-md-4 col-sm-4 col-xs-12">
                                              <label>Father Birth Date</label>
                                              <Field
                                                type="date"
                                                onChange={handleChange}
                                                max={this.state.today}
                                                name="learnerFatherDOB"
                                                id="learnerFatherDOB"
                                                className="form-control"
                                                placeholder=""
                                              />
                                              <ErrorMessage
                                                name="learnerFatherDOB"
                                                component="div"
                                                className="validation"
                                              />
                                            </div>
                                            <div className="col-md-4 col-sm-4 col-xs-12">
                                              <label>Occupation Type</label>
                                              <Field
                                                as="select"
                                                name="learnerFatherEmployementType"
                                                onChange={event => {
                                                  setFieldValue(
                                                    (values.learnerFatherEmployementType =
                                                      event.target.value)
                                                  );
                                                  if (
                                                    event.target.value === "6" ||
                                                    event.target.value === "8"
                                                  ) {
                                                    setFieldValue(
                                                      (values.learnerFatherCompanyName =
                                                        "")
                                                    );
                                                  }
                                                }}
                                                id="state"
                                                className={
                                                  "form-control" +
                                                  (errors.learnerFatherEmployementType &&
                                                    touched.learnerFatherEmployementType
                                                    ? " is-invalid"
                                                    : "")
                                                }
                                              >
                                                <option value="">
                                                  ---Select----
                                              </option>
                                                {(
                                                  this.state
                                                    .employeTypeForParents || []
                                                ).map(team => (
                                                  <option
                                                    key={team.codeValueId}
                                                    value={team.codeValueId}
                                                  >
                                                    {team.codeValue}
                                                  </option>
                                                ))}
                                              </Field>
                                              <ErrorMessage
                                                name="learnerFatherEmployementType"
                                                component="div"
                                                className="validation"
                                              />
                                            </div>
                                          </div>
                                          <div className="row">
                                            {values.learnerFatherEmployementType ===
                                              "6" ||
                                              values.learnerFatherEmployementType ===
                                              "8" ||
                                              values.learnerFatherEmployementType ===
                                              6 ||
                                              values.learnerFatherEmployementType ===
                                              8 ? (
                                                ""
                                              ) : (
                                                <div className="col-md-4 col-sm-4 col-xs-12">
                                                  <label>Company Name </label>
                                                  <Field
                                                    type="text"
                                                    name="learnerFatherCompanyName"
                                                    onChange={handleChange}
                                                    autoComplete="learnerFatherCompanyName"
                                                    className="form-control"
                                                    placeholder="Company Name"
                                                  />
                                                </div>
                                              )}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="address-border">
                                      <div className="form-group">
                                        <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 pl pr">
                                          <h5 className="address-heading">
                                            Mother Details
                                        </h5>
                                        </div>
                                        <div className="row">
                                          <div className="col-md-4 col-sm-4 col-xs-12">
                                            <label>Mother Name </label>
                                            <Field
                                              type="text"
                                              name="learnerMotherName"
                                              onChange={handleChange}
                                              autoComplete="learnerMotherName"
                                              className="form-control"
                                              placeholder="Full Name"
                                            />
                                          </div>
                                          <div className="col-md-4 col-sm-4 col-xs-12">
                                            <label>Mother Birth Date</label>

                                            <Field
                                              type="date"
                                              max={this.state.today}
                                              name="learnerMotherDOB"
                                              id="learnerMotherDOB"
                                              onChange={handleChange}
                                              className="form-control"
                                              placeholder=""
                                            />
                                            <ErrorMessage
                                              name="learnerMotherDOB"
                                              component="div"
                                              className="validation"
                                            />
                                          </div>
                                          <div className="col-md-4 col-sm-4 col-xs-12">
                                            <label>Occupation Type</label>
                                            <Field
                                              as="select"
                                              name="learnerMotherEmployementType"
                                              onChange={event => {
                                                setFieldValue(
                                                  (values.learnerMotherEmployementType =
                                                    event.target.value)
                                                );
                                                if (
                                                  event.target.value === "6" ||
                                                  event.target.value === "8"
                                                ) {
                                                  setFieldValue(
                                                    (values.learnerMotherCompanyName =
                                                      "")
                                                  );
                                                }
                                              }}
                                              id="state"
                                              className={
                                                "form-control" +
                                                (errors.learnerMotherEmployementType &&
                                                  touched.learnerMotherEmployementType
                                                  ? " is-invalid"
                                                  : "")
                                              }
                                            >
                                              <option value="">
                                                ---Select----
                                            </option>
                                              {(
                                                this.state
                                                  .employeTypeForParents || []
                                              ).map(team => (
                                                <option
                                                  key={team.codeValueId}
                                                  value={team.codeValueId}
                                                >
                                                  {team.codeValue}
                                                </option>
                                              ))}
                                            </Field>
                                            <ErrorMessage
                                              name="learnerMotherEmployementType"
                                              component="div"
                                              className="validation"
                                            />
                                          </div>
                                        </div>
                                        <div className="row">
                                          {values.learnerMotherEmployementType ===
                                            "6" ||
                                            values.learnerMotherEmployementType ===
                                            "8" ||
                                            values.learnerMotherEmployementType ===
                                            6 ||
                                            values.learnerMotherEmployementType ===
                                            8 ? (
                                              ""
                                            ) : (
                                              <div className="col-md-4 col-sm-4 col-xs-12">
                                                <label>Company Name </label>
                                                <Field
                                                  type="text"
                                                  name="learnerMotherCompanyName"
                                                  onChange={handleChange}
                                                  autoComplete="learnerMotherCompanyName"
                                                  className="form-control"
                                                  placeholder="Company Name"
                                                />
                                              </div>
                                            )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </section> */}

                               {/* banking */}
                               <section
                                className="create-user page-section profile-box"
                                id="banking-details"
                              >
                                <div className="row">
                                  <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                                    <h2 className="sub-header">
                                      Banking Details
                                  </h2>
                                    <div className="form-group">
                                      <div className="row">
                                      <div className="col-md-4 col-sm-4 col-xs-12">
                                          <label>IFSC Code
                                          <span className="validation"> *</span>

                                          </label>
                                          <Field
                                            type="text"
                                            name="ifscCode"
                                            pattern="[A-Z0-9]{11}"                                            disabled={values.disabledForCielops}
                                            maxlength="11"
                                            autocapitalize="characters"
                                            onChange={event => {
                                              let ifscCode=event.target.value
                                    ifscCode= ifscCode.toUpperCase() 
                                              setFieldValue(
                                                (values.ifscCode =ifscCode
                                                  ))
                                              //     setFieldValue(
                                              //       (values.ifscCode1 =event.target.value
                                              //         ))
                                              }}
                                            onBlur={event => {
                                             

                                          fetch('https://ifsc.razorpay.com/'+event.target.value)
                                          .then(response => response.json())
                                          .then(records => {
                                            console.log(records)

                                            let arr=["Vijaya Bank","Dena Bank","Syndicate Bank","Allahabad Bank","Oriental Bank of Commerce","United Bank of India","State Bank of Bikaner & Jaipur","State Bank of Hyderabad","State Bank of Indore","State Bank of Mysore","State Bank of Patiala","State Bank of Saurashtra","State Bank of Travancore","Bhartiya Mahila Bank","Andhra Bank","Corporation Bank"]
                                      //      mystring.replace(/r/g, '')
console.log(arr.includes(records.BANK))
if(arr.includes(records.BANK)===false){

  
  this.updateWeightage("bankName",records.BANK)
  this.updateWeightage("branch",records.BRANCH.replace(/r/g, ''))
  this.updateWeightage("branchAddress",records.ADDRESS.replace(/r/g, ''))
  setFieldValue(values.bankName=records.BANK)
  setFieldValue(values.branch=records.BRANCH)
  setFieldValue(values.branchAddress=records.ADDRESS)
   
  // setFieldValue(
  //   (values.ifscCode =event.target.value
  //     )
  // );


}else{
  warning("IFSC code is Not in Use. This Bank is already merged in to another bank. Please use different IFSC Code",warningNotification)
  this.updateWeightage("bankName","")
  this.updateWeightage("branch","")
  this.updateWeightage("branchAddress","")
  this.updateWeightage("accountType","")
  this.updateWeightage("acccountNumber","")

  setFieldValue(values.bankName="")
  setFieldValue(values.branch="")
  setFieldValue(values.branchAddress="")
  setFieldValue(values.accountType="")
  setFieldValue(values.acccountNumber="")
  // setFieldValue(
  //   (values.ifscCode =event.target.value
  //     )
  // );
}
                                           
                                           
                                          // this.setState({
                                          // records: records
                                          // })
                                        }
                                     
                                        )
                                        .catch(error => 
                                          {
                                            this.updateWeightage("bankName","")
                                            this.updateWeightage("branch","")
                                            this.updateWeightage("branchAddress","")
                                            this.updateWeightage("accountType","")
                                            this.updateWeightage("acccountNumber","")
                                            setFieldValue(values.bankName="")
                                            setFieldValue(values.branch="")
                                            setFieldValue(values.branchAddress="")
                                            setFieldValue(values.accountType="")
                                            setFieldValue(values.acccountNumber="")
                                          });


                                            }}
                                            className="form-control"
                                          />
                                          { errors.ifscCode && <div  className="validation">{errors.ifscCode}</div>}
                                          {/* <ErrorMessage
                                            name="bankName"
                                            className="validation"
                                            component="div"
                                          /> */}
                                        </div>
                                        <div className="col-md-4 col-sm-4 col-xs-12">
                                          <label>Name Of The Bank
                                          <span className="validation"> *</span>

                                          </label>
                                          <Field
                                            type="text"
                                            name="bankName"
                                            disabled={true}
                                            onBlur={event => {
                                     //         this.updateWeightage(event.target.name,event.target.value)
                                            }}
                                            className="form-control"
                                          />
                                          { errors.bankName && <div  className="validation">{errors.bankName}</div>}
                                          {/* <ErrorMessage
                                            name="bankName"
                                            className="validation"
                                            component="div"
                                          /> */}
                                        </div>
                                        <div className="col-md-4 col-sm-4 col-xs-12">
                                          <label>Branch

                                          <span className="validation"> *</span>
                                          </label>

                                          <Field
                                            type="text"
                                            name="branch"
                                            disabled={true}
                                            onBlur={event => {
                                            //  this.updateWeightage(event.target.name,event.target.value)
                                            }}
                                            className="form-control"
                                          />
                                             { errors.branch && <div  className="validation">{errors.branch}</div>}
                                          {/* <ErrorMessage
                                            name="branch"
                                            className="validation"
                                            component="div"
                                          /> */}
                                        </div>
                                        
                                        <div className="col-md-12 col-sm-12 col-xs-12"></div>
                                        <div className="col-md-4 col-sm-4 col-xs-12">
                                          <label>Branch Address
                                          <span className="validation"> *</span>

                                          </label>
                                          <Field
                                            type="text"
                                            name="branchAddress"
                                            disabled={true}
                                            onBlur={event => {
                                             // this.updateWeightage(event.target.name,event.target.value)
                                            }}
                                            className="form-control"
                                          />
                                           { errors.branchAddress && <div  className="validation">{errors.branchAddress}</div>}
                                          {/* <ErrorMessage
                                            name="branchAddress"
                                            className="validation"
                                            component="div"
                                          /> */}
                                        </div>
                                        <div className="col-md-4 col-sm-4 col-xs-12">
                                          <label>Account Type
                                          <span className="validation"> *</span>

                                          </label>
                                         <Field
                                           as="select"
                                            name="accountType"
                                            disabled={values.disabledForCielops}
                                            onBlur={event => {
                                              this.updateWeightage(event.target.name,event.target.value)
                                            }}
                                            className="form-control"
                                          >
                                           <option value="">
                                              Select Account Type
                                          </option>
                                            <option value="1">Saving</option>
                                            <option value="2">Current</option>
                                         
                                          </Field> 
                                          { errors.accountType && <div  className="validation">{errors.accountType}</div>}
                                          {/* <ErrorMessage
                                            name="accountType"
                                            className="validation"
                                            component="div"
                                          /> */}
                                        </div>
                                        <div className="col-md-4 col-sm-4 col-xs-12">
                                          <label>Account Number
                                          <span className="validation"> *</span>

                                          </label>
                                          <Field
                                            type="text"
                                            name="acccountNumber"
                                            minLength="9"
                                            maxlength="18"
                                            disabled={values.disabledForCielops}
                                            onBlur={event => {
                                              this.updateWeightage(event.target.name,event.target.value)
                                            }}
                                            className="form-control"
                                          />
                                           { errors.acccountNumber && <div  className="validation">{errors.acccountNumber}</div>}
                                          {/* <ErrorMessage
                                            name="acccountNumber"
                                            className="validation"
                                            component="div"
                                          /> */}
                                        </div>
                                       
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </section>
                               <section
                                className="create-qualification page-section profile-box"
                                id="create-qualification"
                              >
                                
                                <div className="row">
                                  <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                                    <h2 className="sub-header">
                                      Professional Qualification Details
                                      <span className='validation'>*</span>
                                  </h2>
                                    <ErrorMessage
                                      name={`professionalQualification[0].occupation`}
                                      component="div"
                                      className="validation"
                                    />
                                    <div className="">
                                      <FieldArray
                                        name="professionalQualification"
                                        onChange={handleChange}
                                        render={arrayHelpers1 => (
                                          <div>
                                            {values.professionalQualification &&
                                              values.professionalQualification.length >
                                              0 ? (
                                                <div className="table-row">
                                                  <table
                                                    className="table table-bordered table-hover"
                                                    id="tab_logic"
                                                  >
                                                    <thead>
                                                      <tr>
                                                        <th className="text-center">
                                                          {" "}
                                                         Occupation
                                                         <span className="validation"> *</span>
                                                        </th>
                                                        <th className="text-center">
                                                          {" "}
                                                          Company Name
                                                          <span className="validation"> *</span> 
                                                          <a href="#" id="tooltip-icon" data-toggle="tooltip" lable="Name of Personal Assessment"
                                                          title=" 1. Fresher: In case you are a Fresher, then the rest of the fields in the row will be disabled. &#13;&#10;
                                                          2. Employed: Mention name of the current company. &#13;&#10;
                                                          3. Self Employed: Mention legal entity name under which you are conducting business.In case no name registered, mention “Self”. &#13;&#10;
                                                          4. Not Employed: Mention name of the last company you were employed">
                            <i className="fa fa-info-circle" aria-hidden="true"
                                     style={{fontSize:"14px", paddingLeft: "5px", paddingright: "8px"}}></i>  </a>
                                                    </th>
                                                        <th className="text-center">
                                                          {" "}
                                                          Designation{" "}
                                                        </th>
                                                        <th className="text-center">
                                                          {" "}
                                                          From{" "}
                                                        </th>
                                                        <th className="text-center">
                                                          {" "}
                                                          To

                                                    </th>
                                                        <th className="text-center">
                                                          {" "}
                                                         Total Experience (Years)
                                                         <span className="validation"> *</span>  
                                                         {" "}
                                                        </th>
                                                        <th className="text-center">
                                                          {" "}
                                                         Annual Income<span className="validation"> *</span>{" "}
                                                        </th>
                                                        <th className="text-center">
                                                          {" "}
                                                          Action{" "}
                                                        </th>
                                                      </tr>
                                                    </thead>
                                                    <tbody>
                                                      {values.professionalQualification.map(

                                                        (friend, index) => (
                        
     
                                                          <tr key={index}>
                                                            <td>

                                                              <Field
                                                                as="select"
                                                                name={`professionalQualification.${index}.occupation`}
                                                                id="occupation" className="form-control"
                                                                disabled={values.disabledForCielops}
                                                                onChange={e=>{
                                                                  
                                                                  if(e.target.value=="1021"){
                                                                    if(friend==""){
                                                                      values.professionalQualification.splice(index, 1);
                                                                      let obj={}
                                                                      obj["occupation"]=""
                                                                      obj["companyName"]=""
                                                                      obj["designation"]=""
                                                                  //    obj["fromDate"]=""
                                                                      obj["totalExperience"]=""
                                                                   //   obj["toDate"]=""
                                                                      obj["annualIncome"]=""
                                                                    values.professionalQualification.push(obj)
                                                                      // setFieldValue(
                                                                      //   (values.professionalQualification=obj)    )
                                                              
                                                                    
                                                                    }
                                                              













                                                              setFieldValue(
                                                              (values.professionalQualification[index].occupation = e.target.value)
                                                              
                                                              )




                                                              setFieldValue(
                                                                (values.professionalQualification[index].fromDay = "0")
                                                                
                                                                )                   
                                                                   setFieldValue(
                                                                  (values.professionalQualification[index].fromMonth = "0")
                                                                  
                                                                  )                     
                                                                   setFieldValue(
                                                                    (values.professionalQualification[index].fromYear =  "0")
                                                                    
                                                                    )
            
                                                                      setFieldValue(
                                                                        (values.professionalQualification[index].toDay = "0")
                                                                        
                                                                        )                   
                                                                           setFieldValue(
                                                                          (values.professionalQualification[index].toMonth = "0")
                                                                          
                                                                          )                     
                                                                           setFieldValue(
                                                                            (values.professionalQualification[index].toyear = "0")
                                                                            
                                                                            )
                                                                            setFieldValue(
                                                                              (values.professionalQualification[index].totalExperience ="0")
                                                                              
                                                                              )










                                                           
                                                              setFieldValue(
                                                              (values.professionalQualification[index].companyName = "")    )
                                                              
                                                              
                                                              setFieldValue(
                                                              (values.professionalQualification[index].designation ="")    )
                                                              // setFieldValue(
                                                              // (values.professionalQualification[index].fromDate ="")    )
                                                              
                                                              
                                                              setFieldValue(
                                                              (values.professionalQualification[index].totalExperience ="")    )
                                                              // setFieldValue(
                                                              // (values.professionalQualification[index].toDate ="")    )
                                                              setFieldValue(
                                                              (values.professionalQualification[index].annualIncome ="")    )
                                                                  }
                                                                  else if(e.target.value=="1025"){
                                                                    if(friend==""){
                                                                      values.professionalQualification.splice(index, 1);
                                                                      let obj={}
                                                                      obj["occupation"]=""
                                                                      obj["companyName"]=""
                                                                      obj["designation"]=""
                                                                 //     obj["fromDate"]=""
                                                                      obj["totalExperience"]=""
                                                                  //    obj["toDate"]=""
                                                                      obj["annualIncome"]=""
                                                                    values.professionalQualification.push(obj)
                                                                      // setFieldValue(
                                                                      //   (values.professionalQualification=obj)    )
                                                              
                                                                    
                                                                    }
                                                              
                                                              setFieldValue(
                                                              (values.professionalQualification[index].occupation = e.target.value)
                                                              
                                                              )
                                                           
                                                            
                                                                  }
                                                                  else if(e.target.value=="1022" || e.target.value=="1024"){
                                                                    warning('If you opted for a profession as "Employed" or "Self employed" then System will consider todays date as "To Date". However, you can change "To Date" anytime.',warningNotification)
                                                                    if(friend==""){
                                                                      values.professionalQualification.splice(index, 1);
                                                                      let obj={}
                                                                      obj["occupation"]=""
                                                                      obj["companyName"]=""
                                                                      obj["designation"]=""
                                                                 //     obj["fromDate"]=""
                                                                      obj["totalExperience"]=""
                                                                  //    obj["toDate"]=""
                                                                      obj["annualIncome"]=""
                                                                    values.professionalQualification.push(obj)
                                                                      // setFieldValue(
                                                                      //   (values.professionalQualification=obj)    )
                                                              
                                                                    
                                                                    }
                                                              let date=new Date()
                                                            let  date1=moment(date).format("D-M-YYYY")
                                                              setFieldValue(
                                                              (values.professionalQualification[index].occupation = e.target.value)
                                                              
                                                              )
                                                              setFieldValue(
                                                                (values.professionalQualification[index].fromDay = date1.split('-')[0])
                                                                
                                                                )                   
                                                                   setFieldValue(
                                                                  (values.professionalQualification[index].fromMonth = date1.split('-')[1])
                                                                  
                                                                  )                     
                                                                   setFieldValue(
                                                                    (values.professionalQualification[index].fromYear =  date1.split('-')[2])
                                                                    
                                                                    )


            
                                                                      setFieldValue(
                                                                        (values.professionalQualification[index].toDay = date1.split('-')[0])
                                                                        
                                                                        )                   
                                                                           setFieldValue(
                                                                          (values.professionalQualification[index].toMonth = date1.split('-')[1])
                                                                          
                                                                          )                     
                                                                           setFieldValue(
                                                                            (values.professionalQualification[index].toyear =  date1.split('-')[2])
                                                                            
                                                                            )
                                                                            setFieldValue(
                                                                              (values.professionalQualification[index].totalExperience = 0.0)
                                                                              
                                                                              )
                                                                  }
                                                                  else{
                                                                  
                                                                    if(values.professionalQualification[index]==""){
                                                                      values.professionalQualification.splice(index, 1);
                                                                      let obj={}
                                                                      obj["occupation"]=""
                                                                      obj["companyName"]=""
                                                                      obj["designation"]=""
                                                                   //   obj["fromDate"]=""
                                                                      obj["totalExperience"]=""
                                                                   //   obj["toDate"]=""
                                                                      obj["annualIncome"]=""
                                                                    values.professionalQualification.push(obj)
                                                                      // setFieldValue(
                                                                      //   (values.professionalQualification=obj)    )
                                                              
                                                                    
                                                                    }
                                                                   
                                                                    setFieldValue(
                                                                      (values.professionalQualification[index].occupation = e.target.value)    )
                                                              
                                                                  }
                                                                }}
                                                                onBlur={event => {
                                                                  this.updateWeightage("professionalQualificationoccupation",event.target.value)
                                                                }}

                                                              >
                                                                <option value="0">---Select----</option>
                                                                {/* <option>aaa</option> */}
                                                                {(this.state.occupation || []).map(
                                            team => (
                                              <option
                                                key={team.codeValueId}
                                                value={team.codeValueId}
                                              >
                                                {team.codeValue}
                                              </option>
                                            )
                                          )}
                                                              </Field>
                                                              <ErrorMessage
                                                                name={`professionalQualification.${index}.occupation`}
                                                                component="div"
                                                                className="validation"
                                                              />

                                                            </td>
                                                            <td>
                                                              <Field
                                                                name={`professionalQualification.${index}.companyName`}
                                                                className="form-control"
                                                              //  disabled={values.disabledForCielops || values.disabledprofDetails}
                                                              disabled={
                                                                values.disabledForCielops?true:friend.occupation ==
                                                                  "1021"
                                                                  ? true
                                                                  : false
                                                              }
                                                                onBlur={event => {
                                                                  this.updateWeightage("professionalQualificationcompanyName",event.target.value)
                                                                }}
                                                              />
                                                              <ErrorMessage
                                                                name={`professionalQualification.${index}.companyName`}
                                                                component="div"
                                                                className="validation"
                                                              />
                                                            </td>
                                                            <td>
                                                              <Field
                                                                name={`professionalQualification.${index}.designation`}
                                                                className="form-control"
                                                                disabled={
                                                                  values.disabledForCielops?true:friend.occupation ==
                                                                  "1021" 
                                                                    ? true:false
                                                                  
                                                                }
                                                              onBlur={event => {
                                                                this.updateWeightage("professionalQualificationdesignation",event.target.value)
                                                              }}
                                                              />
                                                            </td>
                                                            <td >
                                                           
                                                            
                                                              {/* <Field
                                                                type="date"
                                                                name={`professionalQualification.${index}.fromDate`}
                                                                disabled={
                                                                  values.disabledForCielops?true:friend.occupation ==
                                                                  "1021" 
                                                                    ? true
                                                                    : false
                                                                }
                                                                
                                                              
                                                                value={moment(friend.fromDate,"DD/MM/YYYY HH:mm:ss").format('YYYY-MM-DD[T]HH:mm:ss').split('T')[0]}
                                                                max={moment(new Date()).format("YYYY-MM-DD")}

                                                                className="form-control"
                                                                onChange={(e) => {
                                                                  
                                                             let fromDate=   moment(e.target.value,"YYYY-MM-DD").format('DD/MM/YYYY HH:mm:ss')
                                                             //  let a='12/10/2020 11:11:11'
                                                                  setFieldValue(
                                                                    (values.professionalQualification[index].fromDate =fromDate )
                                                                  )

                                                                  var FromDate = e.target.value
                                                                  var Todate = values.professionalQualification[index].toDate
                                                                 
                                                                  if(Todate!=="" && FromDate!=="")
                                                                  {
                                                                  if(Todate !==undefined && FromDate!=="")
                                                                  {
                                                                    Todate=   moment(Todate,"DD/MM/YYYY HH:mm:ss").format('YYYY-MM-DD')

                                                                    //if (Todate !== undefined && FromDate !== undefined) {
                                                                      if ((Date.parse(Todate) <= Date.parse(FromDate))) {
                                                                        //  alert();
                                                                        warning("End date should be greater than Start date", warningNotification);
                                                                        setFieldValue(
                                                                          (values.professionalQualification[index].fromDate = "")
                                                                        )
                                                                        setFieldValue(
                                                                          (values.professionalQualification[index].toDate = "")
                                                                        );
                                                                        setFieldValue(
                                                                          (values.professionalQualification[index].totalExperience = "")
                                                                        );
                                                                        
                                                                      }
                                                                      else{
                                                                                           
                                                                  let yearsDiff=this.diff_years(FromDate,Todate)
   
                                                                  
                                                                 
                                                                  setFieldValue(
                                                                    (values.professionalQualification[index].totalExperience = yearsDiff)
                                                                  );
                                                                  }
                                                                }
                                                                  else{
                                                                    setFieldValue(
                                                                      (values.professionalQualification[index].totalExperience ="")
                                                                    ); 
                                                                  }
                                                                }
                                                              else{
                                                                Todate=   moment(new Date()).format('DD/MM/YYYY HH:mm:ss')

                                                                setFieldValue(
                                                                  (values.professionalQualification[index].toDate =Todate)
                                                                )
                                                              }
                                                              
                                                              }
                                                              }
                                                              onBlur={event => {
                                                                this.updateWeightage("professionalQualificationfromDate",event.target.value)
                                                              }}
                                                             
                                                              /> */}
                                                             
                                                           {/* moment(e.target.value,"YYYY-MM-DD").format('DD/MM/YYYY HH:mm:ss') 
                                                           moment(Todate,"DD/MM/YYYY HH:mm:ss").format('YYYY-MM-DD')                   */}
                                                         
                                                         <div >
{                                                        
}                                            <div className="">
                                              <Field
                                                as="select"
                                                name={`professionalQualification.${index}.fromDay`}
                                                value={
                                                  parseInt(friend.fromDay?friend.fromDay:friend.fromDate===undefined?"": friend.fromDate.split("/")[0],10)
                                                 }
                                               
                                               
                                                onBlur={e=>{
                                               //   alert(e.target.value)
                                               let date=e.target.value
                                                 // this.calculateExp(0,0,e.target.value,0,0,0)
                                                 let res= this.calculateExp(friend.fromYear,friend.fromMonth,date,friend.toyear,friend.toMonth,friend.toDay)
                                              //   alert(res)
                                              if(res==false){
                                                setFieldValue(
                                                  (values.professionalQualification[index].fromDay ="0")
                                                  
                                                  )                   
                                                     setFieldValue(
                                                    (values.professionalQualification[index].fromMonth ="0")
                                                    
                                                    )                     
                                                     setFieldValue(
                                                      (values.professionalQualification[index].fromYear = "0")
                                                      
                                                      )
                                                      setFieldValue(
                                                          (values.professionalQualification[index].toDay = "0")
                                                          
                                                          )                   
                                                             setFieldValue(
                                                            (values.professionalQualification[index].toMonth ="0")
                                                            
                                                            )                     
                                                             setFieldValue(
                                                              (values.professionalQualification[index].toyear = "0")
                                                              
                                                              )
                                                              setFieldValue(
                                                                (values.professionalQualification[index].totalExperience = "")
                                                                );
                                                             
                                              }
                                                 else if(res!==undefined){
                                                  setFieldValue(
                                                    (values.professionalQualification[index].totalExperience = res)
                                                    );
                                                 }
                          
                                                }}
                                                onChange={e=>{
                                                  
                                                  if(friend==""){
                                                    values.professionalQualification.splice(index, 1);
                                                    let obj={}
                                                    obj["occupation"]=""
                                                    obj["companyName"]=""
                                                    obj["designation"]=""
                                                    obj["fromDate"]=""
                                                    obj["totalExperience"]=""
                                                    obj["toDate"]=""
                                                    obj["annualIncome"]=""
                                                  values.professionalQualification.push(obj)
                                                    // setFieldValue(
                                                    //   (values.professionalQualification=obj)    )
                                            
                                                  
                                                  }
                                            
                                            setFieldValue(
                                            (values.professionalQualification[index].fromDay = e.target.value)
                                            
                                            )
                                            var listOfMonth = []
                                          
                                          
                                          if (e.target.value == 31) {
                                          listOfMonth = [{ "key": 1, "value": "Jan" },
                                          
                                          { "key": 3, "value": "March" },
                                          
                                          { "key": 5, "value": "May" },
                                          
                                          { "key": 7, "value": "July" },
                                          { "key": 8, "value": "Aug" },
                                          
                                          { "key": 10, "value": "Oct" },
                                          
                                          { "key": 12, "value": "Dec" },
                                          ];
                                          
                                          
                                          } else if (e.target.value == 30) {
                                          listOfMonth = [
                                          
                                          { "key": 1, "value": "Jan" },
                                          
                                          { "key": 3, "value": "March" },
                                          { "key": 4, "value": "April" },
                                          { "key": 5, "value": "May" },
                                          { "key": 6, "value": "June" },
                                          { "key": 7, "value": "July" },
                                          { "key": 8, "value": "Aug" },
                                          { "key": 9, "value": "Sept" },
                                          { "key": 10, "value": "Oct" },
                                          { "key": 11, "value": "Nov" },
                                          { "key": 12, "value": "Dec" },
                                          
                                          ];
                                          
                                          
                                          }
                                          else if (e.target.value == 28 || e.target.value == 29) {
                                          listOfMonth = [{ "key": 1, "value": "Jan" },
                                          { "key": 2, "value": "Feb" },
                                          { "key": 3, "value": "March" },
                                          { "key": 4, "value": "April" },
                                          { "key": 5, "value": "May" },
                                          { "key": 6, "value": "June" },
                                          { "key": 7, "value": "July" },
                                          { "key": 8, "value": "Aug" },
                                          { "key": 9, "value": "Sept" },
                                          { "key": 10, "value": "Oct" },
                                          { "key": 11, "value": "Nov" },
                                          { "key": 12, "value": "Dec" },
                                          ];
                                          } else {
                                          listOfMonth = [{ "key": 1, "value": "Jan" },
                                          { "key": 2, "value": "Feb" },
                                          { "key": 3, "value": "March" },
                                          { "key": 4, "value": "April" },
                                          { "key": 5, "value": "May" },
                                          { "key": 6, "value": "June" },
                                          { "key": 7, "value": "July" },
                                          { "key": 8, "value": "Aug" },
                                          { "key": 9, "value": "Sept" },
                                          { "key": 10, "value": "Oct" },
                                          { "key": 11, "value": "Nov" },
                                          { "key": 12, "value": "Dec" },
                                          ];
                                          
                                          }
                                          setFieldValue(values.professionalQualification[index].fromlistOfMonth= listOfMonth)

                                          //setFieldValue(values.fromlistOfMonth= listOfMonth)
                                                 
                                                }}
                                                disabled={
                                                  values.disabledForCielops?true:friend.occupation ==
                                                  "1021" 
                                                    ? true
                                                    : false
                                                }
                                                className={
                                                  "form-control" +
                                                  (errors.from && touched.from
                                                    ? "is-invalid"
                                                    : "")
                                                }
                                                className="form-control"
                                              >
                                                <option value="">DD</option>
                                                {(
                                                  friend.fromlistOfDay!==undefined? friend.fromlistOfDay: values.fromlistOfDay  ||
                                                  []
                                                ).map(d => (
                                                  <option
                                                    value={d}
                                                  >
                                                    {d}
                                                  </option>
                                                ))}
                                              </Field>
                                            </div>
                                            <div className="">
                                           
                                              <Field
                                                as="select"
                                                name={`professionalQualification.${index}.fromMonth`}
                                             
                                       value={
                                        parseInt(friend.fromMonth?friend.fromMonth:friend.fromDate===undefined?"": friend.fromDate.split("/")[1],10)
                                       }

                                       onChange={
                                        e => {
                                          
                                
                                          if(friend==""){
                                            values.professionalQualification.splice(index, 1);
                                            let obj={}
                                            obj["occupation"]=""
                                            obj["companyName"]=""
                                            obj["designation"]=""
                                            obj["fromDate"]=""
                                            obj["totalExperience"]=""
                                            obj["toDate"]=""
                                            obj["annualIncome"]=""
                                          values.professionalQualification.push(obj)
                                            // setFieldValue(
                                            //   (values.professionalQualification=obj)    )
                                    
                                          
                                          }
                                    
                                    setFieldValue(
                                    (values.professionalQualification[index].fromMonth = e.target.value)
                                    
                                    )
                                          if (e.target.value == 2) {
                                            var listOfDay = [];
                                            for (var i = 1; i <= 29; i++) {
                                              //    var current = year - i;
                                              listOfDay.push(i);
                                             // this.setState({ listOfDay: listOfDay });
                                            }
                                          } else if (e.target.value == 1 || e.target.value == 3 || e.target.value == 5 || e.target.value == 7 || e.target.value == 8 || e.target.value == 10 || e.target.value == 12) {
                                            var listOfDay = [];
                                            for (var i = 1; i <= 31; i++) {
                                              //    var current = year - i;
                                              listOfDay.push(i);
                                            //  this.setState({ listOfDay: listOfDay });
                                            }
                                          }
                                          else {
                                            var listOfDay = [];
                                            for (var i = 1; i <= 30; i++) {
                                              //    var current = year - i;
                                              listOfDay.push(i);
                                            //  this.setState({ listOfDay: listOfDay });
                                            }
                                          }
                                          setFieldValue(values.professionalQualification[index].fromlistOfDay= listOfDay)

                                          //setFieldValue(values.fromlistOfDay=listOfDay)
                                        }
                                      }
                                                className={
                                                  "form-control" +
                                                  (errors.month && touched.month
                                                    ? "is-invalid"
                                                    : "")
                                                }
                                                className="form-control"
                                                onBlur={e=>{
                                                 // this.calculateExp(0,e.target.value,0,0,0,0)
                                                  let res=this.calculateExp(friend.fromYear,e.target.value,friend.fromDay,friend.toyear,friend.toMonth,friend.toDay)
                                                  if(res==false){
                                                    setFieldValue(
                                                      (values.professionalQualification[index].totalExperience = "")
                                                      );
                                                    setFieldValue(
                                                      (values.professionalQualification[index].fromDay ="0")
                                                      
                                                      )                   
                                                         setFieldValue(
                                                        (values.professionalQualification[index].fromMonth ="0")
                                                        
                                                        )                     
                                                         setFieldValue(
                                                          (values.professionalQualification[index].fromYear = "0")
                                                          
                                                          )
                                                          setFieldValue(
                                                              (values.professionalQualification[index].toDay = "0")
                                                              
                                                              )                   
                                                                 setFieldValue(
                                                                (values.professionalQualification[index].toMonth ="0")
                                                                
                                                                )                     
                                                                 setFieldValue(
                                                                  (values.professionalQualification[index].toyear = "0")
                                                                  
                                                                  )
                                                  }
                                                     else if(res!==undefined){
                                                      setFieldValue(
                                                        (values.professionalQualification[index].totalExperience = res)
                                                        );
                                                     }
                                                }}
                                                disabled={
                                                  values.disabledForCielops?true:friend.occupation ==
                                                  "1021" 
                                                    ? true
                                                    : false
                                                }
                                              >
                                                <option value="">MM</option>
                                                {(
                                                 friend.fromlistOfMonth!==undefined?friend.fromlistOfMonth: values.fromlistOfMonth ||
                                                  []
                                                ).map(m => (
                                                  <option
                                                    value={m.key}
                                                  >
                                                    {m.value}
                                                  </option>
                                                ))}
                                              </Field>
                                            </div>
                                            <div className="">
                        
                                              <Field
                                                as="select"
                                                name={`professionalQualification.${index}.fromYear`}
                                               // id="year"
                                               // onChange={handleChange}
                                            //    disabled={this.state.isDisabled}
                                                //  disabled
                                                // value={
                                                //   friend.fromYear?friend.fromYear: friend.fromYear===undefined?"":friend.fromDate===undefined?"": 
                                                //   (friend.fromDate.split("/")[2]).split(" ")[0]
                                                //  }
                                                //  value={
                                                //   friend.fromYear?friend.fromYear:friend.fromDate===undefined?'':friend.fromDate===undefined?"":  (friend.fromDate.split("/")[2]).split(" ")[0]
                                                //  }
                                                // value={
                                                // friend.fromYear?friend.fromYear:friend.fromDate===undefined?'':friend.fromDate.split("/")[2]
                                                //  }

                                                 value={
                                                  parseInt(friend.fromYear?friend.fromYear:friend.fromDate===undefined?'': friend.fromDate.split("/")[2])
                                                 }
                                                className={
                                                  "form-control" +
                                                  (errors.year && touched.year
                                                    ? "is-invalid"
                                                    : "")
                                                }
                                                onBlur={e=>{
                                                  //this.calculateExp(e.target.value,0,0,0,0,0)
                                                 let res= this.calculateExp(e.target.value,friend.fromMonth,friend.fromDay,friend.toyear,friend.toMonth,friend.toDay)
                                                 if(res==false){
                                                  setFieldValue(
                                                    (values.professionalQualification[index].totalExperience = "")
                                                    );
                                                  setFieldValue(
                                                    (values.professionalQualification[index].fromDay ="0")
                                                    
                                                    )                   
                                                       setFieldValue(
                                                      (values.professionalQualification[index].fromMonth ="0")
                                                      
                                                      )                     
                                                       setFieldValue(
                                                        (values.professionalQualification[index].fromYear = "0")
                                                        
                                                        )
                                                        setFieldValue(
                                                            (values.professionalQualification[index].toDay = "0")
                                                            
                                                            )                   
                                                               setFieldValue(
                                                              (values.professionalQualification[index].toMonth ="0")
                                                              
                                                              )                     
                                                               setFieldValue(
                                                                (values.professionalQualification[index].toyear = "0")
                                                                
                                                                )
                                                }
                                                   else if(res!==undefined){
                                                    setFieldValue(
                                                      (values.professionalQualification[index].totalExperience = res)
                                                      );
                                                   }
                                                }}
                                                disabled={
                                                  values.disabledForCielops?true:friend.occupation ==
                                                  "1021" 
                                                    ? true
                                                    : false
                                                }
                                                className="form-control"
                                              >
                                                <option value="">YYYY</option>
                                                {(
                                                  this.state.listOfYear2 ||
                                                  []
                                                ).map(year => (
                                                  <option
                                                    value={year}
                                                  >
                                                    {year}
                                                  </option>
                                                ))}
                                              </Field>
                                            </div>
                                          </div>
                                                            </td>
                                                            <td >
                                                            {/* {friend.toDate} */}
{/*                                                             
                                                              <Field
                                                                type="date"
                                                                name={`professionalQualification.${index}.toDate`}
                                                                max={moment(new Date()).format("YYYY-MM-DD")}
                                                               min= {moment(friend.fromDate,"DD/MM/YYYY HH:mm:ss").format('YYYY-MM-DD[T]HH:mm:ss').split('T')[0]}
                                                                value={moment(friend.toDate,"DD/MM/YYYY HH:mm:ss").format('YYYY-MM-DD[T]HH:mm:ss').split('T')[0]}
                                                                className="form-control"
                                                                disabled={
                                                                  values.disabledForCielops?true:friend.occupation ==
                                                                  "1021" 
                                                                    ? true
                                                                    : false
                                                                }
                                                              
                                                              //  min={friend.fromDate}
                                                                onChange={(e) => {
                                                                  
                                                                  let todate=   moment(e.target.value,"YYYY-MM-DD").format('DD/MM/YYYY HH:mm:ss')
                                                               
                                                                  setFieldValue(
                                                                    (values.professionalQualification[index].toDate = todate)
                                                                  )
                                                                  

                                                                  var Todate = e.target.value

                                                                  var FromDate = values.professionalQualification[index].fromDate
                                                                  if (Todate !== undefined && FromDate !== undefined) {
                                                                    if ((Date.parse(Todate) <= Date.parse(FromDate))) {
                                                                      //  alert();
                                                                      warning("End date should be greater than Start date", warningNotification);
                                                                      setFieldValue(
                                                                        (values.professionalQualification[index].fromDate = "")
                                                                      )
                                                                      setFieldValue(
                                                                        (values.professionalQualification[index].toDate = "")
                                                                      );
                                                                    }
                                                                    else {
                                                                      
                                                                      
                                                                      if(Todate !=="" && FromDate!=="")
                                                                      {
                                                                       FromDate=   moment(FromDate,"DD/MM/YYYY HH:mm:ss").format('YYYY-MM-DD')
                                                                    
                                                                     let yearsDiff=this.diff_years(FromDate,Todate)
 
                                                              
                                                                      setFieldValue(
                                                                        (values.professionalQualification[index].totalExperience = yearsDiff)
                                                                      );
                                                                      }
                                                                      else{
                                                                        setFieldValue(
                                                                          (values.professionalQualification[index].totalExperience ="")
                                                                        ); 
                                                                      }
                                                                    
                                                                    }
                                                                  }
                                                                }
                                                                }
                                                                onBlur={event => {
                                                                  this.updateWeightage("professionalQualificationtoDate",event.target.value)
                                                                }}
                                                              /> */}
<div className="" >
                                            <div className="">
                                            
                                              <Field
                                                as="select"
                                                name={`professionalQualification.${index}.toDay`}
                                                onBlur={e=>{
                            // this.calculateExp(0,0,0,0,0,e.target.value)
                                                  let res=this.calculateExp(friend.fromYear,friend.fromMonth,friend.fromDay,friend.toyear,friend.toMonth,e.target.value)
                                                  if(res==false){
                                                    setFieldValue(
                                                      (values.professionalQualification[index].totalExperience = "")
                                                      );
                                                    setFieldValue(
                                                      (values.professionalQualification[index].fromDay ="0")
                                                      
                                                      )                   
                                                         setFieldValue(
                                                        (values.professionalQualification[index].fromMonth ="0")
                                                        
                                                        )                     
                                                         setFieldValue(
                                                          (values.professionalQualification[index].fromYear = "0")
                                                          
                                                          )
                                                          setFieldValue(
                                                              (values.professionalQualification[index].toDay = "0")
                                                              
                                                              )                   
                                                                 setFieldValue(
                                                                (values.professionalQualification[index].toMonth ="0")
                                                                
                                                                )                     
                                                                 setFieldValue(
                                                                  (values.professionalQualification[index].toyear = "0")
                                                                  
                                                                  )
                                                  }
                                                     else if(res!==undefined){
                                                      setFieldValue(
                                                        (values.professionalQualification[index].totalExperience = res)
                                                        );
                                                     }
                                                }}
                                                disabled={
                                                  values.disabledForCielops?true:friend.occupation ==
                                                  "1021" 
                                                    ? true
                                                    : false
                                                }
                                                id="today"
                                                value={
                                                  parseInt(friend.toDay?friend.toDay:friend.toDate===undefined?'': friend.toDate.split("/")[0],10)
                                                 }
                                                // onChange={handleChange}
                                              //  disabled={this.state.isDisabled}
                                                //  disabled
                                               
                                            onChange={e=>{
                                              
                                              if(friend==""){
                                                values.professionalQualification.splice(index, 1);
                                                let obj={}
                                                obj["occupation"]=""
                                                obj["companyName"]=""
                                                obj["designation"]=""
                                                obj["fromDate"]=""
                                                obj["totalExperience"]=""
                                                obj["toDate"]=""
                                                obj["annualIncome"]=""
                                              values.professionalQualification.push(obj)
                                                // setFieldValue(
                                                //   (values.professionalQualification=obj)    )
                                        
                                              
                                              }
                                        
                                        setFieldValue(
                                        (values.professionalQualification[index].toDay = e.target.value)
                                        
                                        )
                                        var listOfMonth = []
    
      
      if (e.target.value == 31) {
        listOfMonth = [{ "key": 1, "value": "Jan" },

        { "key": 3, "value": "March" },

        { "key": 5, "value": "May" },

        { "key": 7, "value": "July" },
        { "key": 8, "value": "Aug" },

        { "key": 10, "value": "Oct" },

        { "key": 12, "value": "Dec" },
        ];


      } else if (e.target.value == 30) {
        listOfMonth = [

          { "key": 1, "value": "Jan" },

          { "key": 3, "value": "March" },
          { "key": 4, "value": "April" },
          { "key": 5, "value": "May" },
          { "key": 6, "value": "June" },
          { "key": 7, "value": "July" },
          { "key": 8, "value": "Aug" },
          { "key": 9, "value": "Sept" },
          { "key": 10, "value": "Oct" },
          { "key": 11, "value": "Nov" },
          { "key": 12, "value": "Dec" },

        ];


      }
      else if (e.target.value == 28 || e.target.value == 29) {
        listOfMonth = [{ "key": 1, "value": "Jan" },
        { "key": 2, "value": "Feb" },
        { "key": 3, "value": "March" },
        { "key": 4, "value": "April" },
        { "key": 5, "value": "May" },
        { "key": 6, "value": "June" },
        { "key": 7, "value": "July" },
        { "key": 8, "value": "Aug" },
        { "key": 9, "value": "Sept" },
        { "key": 10, "value": "Oct" },
        { "key": 11, "value": "Nov" },
        { "key": 12, "value": "Dec" },
        ];
      } else {
        listOfMonth = [{ "key": 1, "value": "Jan" },
        { "key": 2, "value": "Feb" },
        { "key": 3, "value": "March" },
        { "key": 4, "value": "April" },
        { "key": 5, "value": "May" },
        { "key": 6, "value": "June" },
        { "key": 7, "value": "July" },
        { "key": 8, "value": "Aug" },
        { "key": 9, "value": "Sept" },
        { "key": 10, "value": "Oct" },
        { "key": 11, "value": "Nov" },
        { "key": 12, "value": "Dec" },
        ];

      }
// setFieldValue(values.tolistOfMonth= listOfMonth)
setFieldValue(values.professionalQualification[index].tolistOfMonth= listOfMonth)
                            
                                            }}
                                            
                                                className={
                                                  "form-control" +
                                                  (errors.today && touched.today
                                                    ? "is-invalid"
                                                    : "")
                                                }
                                                className="form-control"
                                              >
                                                <option value="">DD</option>
                                                {(
                                               friend.tolistOfDay!==undefined?friend.tolistOfDay: values.tolistOfDay ||
                                                  []
                                                ).map(d => (
                                                  <option
                                                    value={d}
                                                  >
                                                    {d}
                                                  </option>
                                                ))}
                                              </Field>
                                            </div>
                                            <div className="">
                                              <Field
                                                as="select"
                                                name={`professionalQualification.${index}.toMonth`}
                                                value={
                                                  parseInt(friend.toMonth?friend.toMonth:friend.toDate===undefined?'': friend.toDate.split("/")[1],10)
                                                 }
                                                
                                              
                                                id="tomonth"
                                                //   onChange={handleChange}
                                           //     disabled={this.state.isDisabled}
                                                //  disabled
                                                onBlur={e=>{
                                                  let res=this.calculateExp(friend.fromYear,friend.fromMonth,friend.fromDay,friend.toyear,e.target.value,friend.toDay)
                                                  if(res==false){
                                                    setFieldValue(
                                                      (values.professionalQualification[index].totalExperience = "")
                                                      );
                                                    setFieldValue(
                                                      (values.professionalQualification[index].fromDay ="0")
                                                      
                                                      )                   
                                                         setFieldValue(
                                                        (values.professionalQualification[index].fromMonth ="0")
                                                        
                                                        )                     
                                                         setFieldValue(
                                                          (values.professionalQualification[index].fromYear = "0")
                                                          
                                                          )
                                                          setFieldValue(
                                                              (values.professionalQualification[index].toDay = "0")
                                                              
                                                              )                   
                                                                 setFieldValue(
                                                                (values.professionalQualification[index].toMonth ="0")
                                                                
                                                                )                     
                                                                 setFieldValue(
                                                                  (values.professionalQualification[index].toyear = "0")
                                                                  
                                                                  )
                                                  }
                                                     else if(res!==undefined){
                                                      setFieldValue(
                                                        (values.professionalQualification[index].totalExperience = res)
                                                        );
                                                     }
                                                }}
                                                onChange={
                                                  e => {
                                                    
                                          
                                                    if(friend==""){
                                                      values.professionalQualification.splice(index, 1);
                                                      let obj={}
                                                      obj["occupation"]=""
                                                      obj["companyName"]=""
                                                      obj["designation"]=""
                                                      obj["fromDate"]=""
                                                      obj["totalExperience"]=""
                                                      obj["toDate"]=""
                                                      obj["annualIncome"]=""
                                                    values.professionalQualification.push(obj)
                                                      // setFieldValue(
                                                      //   (values.professionalQualification=obj)    )
                                              
                                                    
                                                    }
                                              
                                              setFieldValue(
                                              (values.professionalQualification[index].toMonth = e.target.value)
                                              
                                              )
                                                    if (e.target.value == 2) {
                                                      var listOfDay = [];
                                                      for (var i = 1; i <= 29; i++) {
                                                        //    var current = year - i;
                                                        listOfDay.push(i);
                                                       // this.setState({ listOfDay: listOfDay });
                                                      }
                                                    } else if (e.target.value == 1 || e.target.value == 3 || e.target.value == 5 || e.target.value == 7 || e.target.value == 8 || e.target.value == 10 || e.target.value == 12) {
                                                      var listOfDay = [];
                                                      for (var i = 1; i <= 31; i++) {
                                                        //    var current = year - i;
                                                        listOfDay.push(i);
                                                      //  this.setState({ listOfDay: listOfDay });
                                                      }
                                                    }
                                                    else {
                                                      var listOfDay = [];
                                                      for (var i = 1; i <= 30; i++) {
                                                        //    var current = year - i;
                                                        listOfDay.push(i);
                                                      //  this.setState({ listOfDay: listOfDay });
                                                      }
                                                    }
                                                    //setFieldValue(values.tolistOfDay=listOfDay)
                                                    setFieldValue(values.professionalQualification[index].tolistOfDay= listOfDay)

                                                  }
                                                }
                                                // value={
                                                //   parseInt(friend.tomonth?friend.tomonth: friend.toDate.split("/")[1],10)
                                                //  }
                                                disabled={
                                                  values.disabledForCielops?true:friend.occupation ==
                                                  "1021" 
                                                    ? true
                                                    : false
                                                }
                                                className={
                                                  "form-control" +
                                                  (errors.tomonth && touched.tomonth
                                                    ? "is-invalid"
                                                    : "")
                                                }
                                             
                                                className="form-control"
                                              >
                                                <option value="">MM</option>
                                                {(
                                            friend.tolistOfMonth!==undefined?friend.tolistOfMonth:values.tolistOfMonth ||
                                                  []
                                                ).map(m => (
                                                  <option
                                                    value={m.key}
                                                  >
                                                    {m.value}
                                                  </option>
                                                ))}
                                              </Field>
                                            </div>
                                            <div className="">
                                             
                                              <Field
                                                as="select"
                                                name={`professionalQualification.${index}.toyear`}
                                                onBlur={e=>{
                                               //   this.calculateExp(0,0,0,e.target.value,0,0)
                                                 let res= this.calculateExp(friend.fromYear,friend.fromMonth,friend.fromDay,e.target.value,friend.toMonth,friend.toDay)
                                                 if(res==false){
                                                  setFieldValue(
                                                    (values.professionalQualification[index].totalExperience = "")
                                                    );
                                                  setFieldValue(
                                                    (values.professionalQualification[index].fromDay ="0")
                                                    
                                                    )                   
                                                       setFieldValue(
                                                      (values.professionalQualification[index].fromMonth ="0")
                                                      
                                                      )                     
                                                       setFieldValue(
                                                        (values.professionalQualification[index].fromYear = "0")
                                                        
                                                        )
                                                        setFieldValue(
                                                            (values.professionalQualification[index].toDay = "0")
                                                            
                                                            )                   
                                                               setFieldValue(
                                                              (values.professionalQualification[index].toMonth ="0")
                                                              
                                                              )                     
                                                               setFieldValue(
                                                                (values.professionalQualification[index].toyear = "0")
                                                                
                                                                )
                                                }
                                                   else if(res!==undefined){
                                                    setFieldValue(
                                                      (values.professionalQualification[index].totalExperience = res)
                                                      );
                                                   }

                                                }}
                                                
                                                id="toyear"
                                               // onChange={handleChange}
                                            //    disabled={this.state.isDisabled}
                                                //  disabled
                                                disabled={
                                                  values.disabledForCielops?true:friend.occupation ==
                                                  "1021" 
                                                    ? true
                                                    : false
                                                }
                                                // value={
                                                //   friend.toyear?friend.toyear:friend.toDate===undefined?'':friend.toDate===undefined?"":  (friend.toDate.split("/")[2]).split(" ")[0]
                                                //  }
                                                value={
                                                  parseInt(friend.toyear?friend.toyear:friend.toDate===undefined?'': friend.toDate.split("/")[2])
                                                 }
                                                className={
                                                  "form-control" +
                                                  (errors.toyear && touched.toyear
                                                    ? "is-invalid"
                                                    : "")
                                                }
                                                className="form-control"
                                              >
                                                <option value="">YYYY</option>
                                                {(
                                                  this.state.listOfYear2 ||
                                                  []
                                                ).map(year => (
                                                  <option
                                                    value={year}
                                                  >
                                                    {year}
                                                  </option>
                                                ))}
                                              </Field>
                                            </div>
                                          </div>
                                                            </td>
                                                            <td>
                                                              <Field

                                                                name={`professionalQualification.${index}.totalExperience`}
                                                                className="form-control"
                                                                disabled={
                                                                  values.disabledForCielops?true:friend.occupation ==
                                                                  "1021" 
                                                                    ? true
                                                                    : false
                                                                }
                                                                onBlur={(e) => {
                                                                  this.updateWeightage("professionalQualificationtotalExperience",e.target.value)
                                                                  
                                                                  var numbers = /^[0-9.]{1,100}$/;
                                                                let  inputtxt=e.target.value
                                                                  if(inputtxt.match(numbers)==null)
                                                                  {
                                                                    warning("Total Experience should be numeric and demical value only. eg-1.1", warningNotification);
                                                                    setFieldValue(
                                                                      (values.professionalQualification[index].totalExperience = "")
                                                                    )
                                                                  }else{
                                                                    (values.professionalQualification[index].totalExperience =friend.totalExperience)
                                                                  }
                                                                  
                                                                }}
                                                               
                                                              />

                                                            </td>

                                                            <td>
                                                              <Field
                                                                as="select"
                                                                name={`professionalQualification.${index}.annualIncome`}
                                                                disabled={
                                                                  values.disabledForCielops?true:friend.occupation ==
                                                                  "1021"
                                                                    ? true
                                                                    : false
                                                                }
                                                                id="annualIncome" className="form-control"
                                                                onBlur={event => {
                                                                  this.updateWeightage("professionalQualificationannualIncome",event.target.value)
                                                                }}

                                                              >
                                                                <option value="0">---Select----</option>
                                                             
                                                                {(this.state.AuualCome || []).map(
                                            team => (
                                              <option
                                                key={team.codeValueId}
                                                value={team.codeValueId}
                                              >
                                                {team.codeValue}
                                              </option>
                                            )
                                          )}
                                                              </Field>

                                                              <ErrorMessage
                                                                name={`professionalQualification.${index}.annualIncome`}
                                                                component="div"
                                                                className="validation"
                                                              />
                                                            </td>
                                                            <td>
                                                              <button
                                                                type="button"
                                                                className="btn-8 ml-5 mr-5 mt-10"
                                                                disabled={values.disabledForCielops}
                                                                onClick={() => {

                                                                
                                                                

                                

                                                                
                                                                  arrayHelpers1.insert(
                                                                    index + 1,
                                                                    ""
                                                                  );
                                                                }}
                                                              >
                                                                <i
                                                                  class="fa fa-plus"
                                                                  aria-hidden="true"
                                                                ></i>
                                                              </button>
                                                              <button
                                                                type="button"
                                                                className="btn-8 ml-5 mr-5 mt-10"
                                                                disabled={values.disabledForCielops}
                                                                onClick={() => {



                                                                  arrayHelpers1.remove(
                                                                    index
                                                                  );
                                                                }}
                                                              >
                                                                <i
                                                                  class="fa fa-minus"
                                                                  aria-hidden="true"
                                                                ></i>
                                                              </button>
                                                            </td>
                                                          </tr>
                                                        )
                                                      )}
                                                    </tbody>
                                                  </table>
                                                </div>
                                              ) : (
                                                <button
                                                  type="button"
                                                  className="btn-4"
                                                  onClick={() => {
                                                    arrayHelpers1.push("");
                                                  }}
                                                >
                                                  Add Professional Qualification
                                                </button>
                                              )}
                                          </div>
                                        )}
                                      />
                                    </div>
                                  </div>
                                <p className="hidden-md hidden-lg" style={{paddingLeft:"15px"}}>
                                Scroll to the right for more fields.</p>
                                </div>
                              </section>
                              <section
                                className="create-qualification page-section profile-box"
                                id="educational-details"
                              >
                                <div className="row">
                                  <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                                    <h2 className="sub-header">
                                    Educational Qualification Details
                                    <span className='validation'>*</span> <br />
                                    <span class="file-size-limit" style={{textTransform: "capitalize"}}>Please put the latest Education Qualification first</span>
                                    {/* <a href="#" id="tooltip-icon" data-toggle="tooltip" lable="" title="Please put the latest Education Qualification 
                                      first">
                                        <i class="fa fa-info-circle" aria-hidden="true" style="font-size: 14px; padding-left: 5px;" /> 
                                       </a> */}
                                  </h2>
                                  {/* <span>General Instruction:  </span> */}
                                    <div className="">
                                      <FieldArray
                                        name="learnerQualification"
                                        onChange={handleChange}
                                        render={arrayHelpers => (
                                          <div>
                                            {values.learnerQualification &&
                                              values.learnerQualification.length >
                                              0 ? (
                                                <div className="table-row">
                                                  <table
                                                    className="table table-bordered table-hover"
                                                    id="tab_logic"
                                                  >
                                                    <thead>
                                                      <tr>
                                                        <th className="text-center">
                                                          {" "}
                                                         University/College  Name
                                                         <span className="validation"> *</span>  
                                                    </th>
                                                        {/* <th className="text-center">
                                                          {" "}
                                                          Qualification Category{" "}
                                                        </th> */}
                                                        <th className="text-center">
                                                          {" "}
                                                          Degree
                                                          <span className="validation"> *</span>  {" "}
                                                        </th>
                                                        <th className="text-center">
                                                          {" "}
                                                          Passing Year
                                                          <span className="validation"> *</span>  
                                                    </th>
                                                        {/* <th className="text-center">
                                                          {" "}
                                                          Pursuing Year{" "}
                                                        </th> */}
                                                        <th className="text-center">
                                                          {" "}
                                                          CGPA / Percentage  <span className="validation"> *</span>  {" "}
                                                        </th>
                                                        <th className="text-center">
                                                          {" "}
                                                          Educational Qualification Document 
                                                           {/* <span className="validation"> *</span>  {" "} */}
                                                        </th>
                                                        <th className="text-center">
                                                          {" "}
                                                          Action{" "}
                                                        </th>
                                                      </tr>
                                                    </thead>
                                                    <tbody>
                                                      {values.learnerQualification.map(
                                                        (friend, index) => (
                                                          <tr key={index}>
                                                            <td>
                                                              <Field
                                                                name={`learnerQualification.${index}.instituteName`}
                                                                className="form-control"
                                                                disabled={values.disabledForCielops}
                                                                value={
                                                                  friend.instituteName
                                                                }
                                                                onBlur={event => {
                                                                  this.updateWeightage("learnerQualificationinstituteName",event.target.value)
                                                                }}
                                                              />
                                                            </td>
                                                            
                                                            <td>
                                                              <Field
                                                                as="select"
                                                                name={`learnerQualification.${index}.qualificationType`}
                                                                className="form-control"
                                                                disabled={values.disabledForCielops}
                                                                onBlur={event => {
                                                                  this.updateWeightage("learnerQualificationqualificationType",event.target.value)
                                                                }}
                                                                onChange={event => {
                                                                  setFieldValue(
                                                                    (friend.qualificationType =
                                                                      event.target.value)
                                                                  );
                                                                  if (
                                                                    event.target
                                                                      .value ===
                                                                    "Under Graduate" ||
                                                                    event.target
                                                                      .value ===
                                                                    "under Graduate"
                                                                  ) {
                                                                    setFieldValue(
                                                                      (friend.passingYear =
                                                                        "")
                                                                    );
                                                                  } else {
                                                                    setFieldValue(
                                                                      (friend.persuingYear =
                                                                        "")
                                                                    );
                                                                  }
                                                                }}
                                                              // value={
                                                              //   friend.qualificationType
                                                              // }
                                                              >
                                                                <option value="">
                                                                  Select Education
                                                            </option>
                                                            <option value="Below Higher Secondary">
                                                                 Below Higher Secondary
                                                            </option>
                                                                <option value="Higher Secondary/diploma">
                                                                  Higher Secondary/diploma 
                                                            </option>
                                                                <option value="Graduate">
                                                                  Graduate
                                                            </option>
                                                                <option value="Post Graduate">
                                                                  Post Graduate
                                                            </option>
                                                              </Field>
                                                            </td>
                                                            <td>
                                                              <Field
                                                                as="select"
                                                                className="form-control"
                                                               
                                                                name={`learnerQualification.${index}.passingYear`}
                                                                type="number"
                                                                disabled={
                                                                  values.disabledForCielops?true:friend.qualificationType ==
                                                                    "Under Graduate" ||
                                                                    friend.qualificationType ==
                                                                    "under Graduate"
                                                                    ? true
                                                                    : false
                                                                }
                                                                value={
                                                                  friend.qualificationType ==
                                                                    "Under Graduate" ||
                                                                    friend.qualificationType ==
                                                                    "under Graduate"
                                                                    ? ""
                                                                    : friend.passingYear
                                                                }
                                                                onBlur={event => {
                                                                  this.updateWeightage("learnerQualificationpassingYear",event.target.value)
                                                                }}
                                                              >
                                                                <option value="">
                                                                  Select
                                                            </option>
                                                                {(
                                                                  this.state
                                                                    .listOfYear ||
                                                                  []
                                                                ).map(year => (
                                                                  <option
                                                                    value={year}
                                                                  >
                                                                    {year}
                                                                  </option>
                                                                ))}
                                                              </Field>
                                                            </td>
                                                      
                                                            <td>
                                                              <Field
                                                                className="form-control"
                                                                disabled={values.disabledForCielops}
                                                                name={`learnerQualification.${index}.percentage`}
                                                                value={friend.percentage}
                                                                onBlur={event => {
                                                                  this.updateWeightage("learnerQualificationpercentage",event.target.value)
                                                                }}
                                                              />
                                                            </td>
                                                          
                                                            <td style={{textAlign:"center"}} >
                                                            <span  style={{wordBreak: "break-all", paddingRight: "20px"}}>
                                                            
                                                           
                                                 
                                                            {/* {friend.docName==undefined?
                                                            
                                                            friend.educationQualificationDocumentURL?(
            friend.educationQualificationDocumentURL.split("?")[0]).substring(
              (friend.educationQualificationDocumentURL || "").lastIndexOf(
                "/"
              ) + 1).replaceAll("%20"," ").replaceAll("%28","(").replaceAll("%29",")")
              
              :'':friend.docName} */}
                                                            
                                                            {friend.docName==undefined?
                                                            
                                                            friend.educationQualificationDocumentURL?(
            friend.educationQualificationDocumentURL.split("?")[0]).substring(
              (friend.educationQualificationDocumentURL || "").lastIndexOf(
                "/"
              ) + 1)
              // .replaceAll("%20"," ").replaceAll("%28","(").replaceAll("%29",")")
              
              :'':friend.docName }  

                                            </span><br />
                                       
                                                       <a className="icon btn-file text-center"  style={values.disabledForCielops ? {  pointerEvents: "none",
                                  cursor: "default" } : {  }}>
                                            <i
                                              className="fa fa-paperclip color_magento"
                                              aria-hidden="true" style={{fontSize:"25px"}}
                                            ></i>
                                                
<FileBase64
  multiple={false}
  name={`learnerQualification.${index}.educationalQualification`}
  onChange={handleChange}
  
  onDone={event => {
    
    // const checkType = event.name.split(
    //   "."
    // );
    const checkType =event.name.split(/\.(?=[^\.]+$)/)

    if (
      [
        "jpg",
        "JPG",
        "jpeg",
        "JPEG",
        "png",
        "PNG",
        "pdf",
       "docx",
       "rtf",
        "doc"
      ].indexOf(checkType[1]) < 0
    ) {
      setFieldValue(
        (values.documentSizeCheckForEduQualification = true)
      );
    } else if (
      event.file.size <= 5000000
    ) {

      if(this.checkName(event.name))
        {                                            
      // values.edudocument.push({
      //   documentHash: event.base64,
      //   documentType:'PostGraduation',
      //  //
      //   documentName: event.name
      // });
      this.updateWeightage("learnerQualificationeducationalQualification",event.name)
      values.learnerQualification[index].document=[]
      values.learnerQualification[index].document.push({
        documentHash: event.base64,
        documentType:'PostGraduation',
       //
        documentName: event.name
      });
    
 
      setFieldValue(
        (values.learnerQualification[index].docName      
          =event.name)
      );

      setFieldValue(
        (values.documentSizeCheckForEduQualification = false)
      );
      setFieldValue(
        (values.documentSizeCheckForEduQualification = false)
      );
      setFieldValue(
        (values.learnerQualification[index].eduQualificationDocumentNameValidation = false)
      );

      // setFieldValue(
      //   (friend.educationQualificationDocumentURL= "")
      // );
       // }
      }
      else{
        setFieldValue(
          (values.learnerQualification[index].eduQualificationDocumentNameValidation = true)
        );

       // warning("File/Document name can only contain number, letter, hyphens, space. ", warningNotification);
      }
     

    } else {
      setFieldValue(
        (values.learnerQualification[index].eduQualificationDocumentNameValidation = false)
      );

      setFieldValue(
        (values.documentSizeCheckForEduQualification = true)
      );
    }
  }}
/>
 </a> 
 <span class="file-size-limit">
    The file should be in PNG/JPG/PDF format, with file-size less than 1MB, with no special characters in the file-name
  </span>
{values.documentSizeCheckForEduQualification ? (
  <p style={{ color: "red" }}>
    File Size is too large(Maximum
    File Size:5MB).
  </p>
) : (
    ""
  )}
{values.documentTypeCheckForEduQualification ? (
  <p style={{ color: "red" }}>
    Invalid image format
  </p>
) : (
    ""
  )}



{values.learnerQualification[index].eduQualificationDocumentNameValidation ? (
                                              <p style={{ color: "red" }}>
                                               File/Document name can only contain number, letter, hyphens, space.
                                            </p>
                                            ) : (
                                                ""
                                              )}



</td>
                                                            <td>
                                                              <button
                                                                type="button"
                                                                disabled={values.disabledForCielops}
                                                                className="btn-8 ml-5 mr-5 mt-10"
                                                                onClick={() => {

                                                                
                                                                  
                                                                
                                                                 
                                                       

                                                                  arrayHelpers.insert(
                                                                    index + 1,
                                                                    ""
                                                                  );
                                                                }}
                                                              >
                                                                <i
                                                                  class="fa fa-plus"
                                                                  aria-hidden="true"
                                                                ></i>
                                                              </button>
                                                              <button
                                                                type="button"
                                                                disabled={values.disabledForCielops}
                                                                className="btn-8 ml-5 mr-5 mt-10"
                                                                onClick={() => {
                                                                  arrayHelpers.remove(
                                                                    index
                                                                  );
                                                                }}
                                                              >
                                                                <i
                                                                  class="fa fa-minus"
                                                                  aria-hidden="true"
                                                                ></i>
                                                              </button>
                                                            </td>
                                                          </tr>
                                                        )
                                                      )}
                                                    </tbody>
                                                  </table>
                                                </div>
                                              ) : (
                                                <button
                                                  type="button"
                                                  className="btn-4"
                                                  onClick={() => {
                                                    arrayHelpers.push("");
                                                  }}
                                                >
                                                  Add a Qualification
                                            </button>
                                              )}
                                          </div>
                                        )}
                                      />
                                    </div>
                                  </div>
                                  <p className="hidden-md hidden-lg" style={{paddingLeft:"15px"}}>
                                  Scroll to the right for more fields.</p>

                                </div>
                              </section>
                             
 {/* CPE Details */}
 <section
                                className="create-qualification page-section profile-box"
                                id="CPE-details"
                                style={this.state.processType !== "1003" ? { display: "none" } : { display: "block" }}
                              >
                                <div className="row">
                                  <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                                    <h2 className="sub-header">
                                    NISM Certificate Details
                                  </h2>

                                    <div className="">
                                      <FieldArray
                                        name="CPEDetails"
                                       
                                        onChange={handleChange}
                                        render={arrayHelpers1 => (

                                          <div>
                                            {values.CPEDetails &&
                                              values.CPEDetails.length >
                                              0 ? (
                                                <div className="table-row">
                                                  <table
                                                    className="table table-bordered table-hover"
                                                    id="tab_logic"
                                                  >
                                                    <thead>
                                                      <tr>
                                                        <th className="text-center">
                                                          {" "}
                                                          Passing Certificate No.
                                                         {/* <span className="validation"> *</span> */}
                                                        </th>
                                                        <th className="text-center">
                                                          {" "}
                                                          Date Of Passing Test
                                                    </th>
                                                        <th className="text-center">
                                                          {" "}
                                                          CPE Certificate No.{" "}
                                                        </th>
                                                        <th className="text-center">
                                                          {" "}
                                                       Expiry Date Of CPE{" "}
                                                        </th>


                                                        <th className="text-center">
                                                          {" "}
                                                          Action{" "}
                                                        </th>
                                                      </tr>
                                                    </thead>
                                                    <tbody>
                                                      {values.CPEDetails.map(
                                                       
                                                        (friend, index) => (
                                                          <tr key={index}>
                                                            <td>
                                                           
                                                              <Field
                                                                type="text"
                                                                disabled={values.disabledForCielops}
                                                                // name="PassingCertificateNo"
                                                                name={`CPEDetails.${index}.passingCertificateNo`}
                                                                onBlur={event => {
                                                                  this.updateWeightage("CPEDetailspassingCertificateNo",event.target.value)
                                                                }}
                                                                className="form-control"
                                                              />
                                                              
                                                              <ErrorMessage
                                                                name={`CPEDetails.${index}.passingCertificateNo`}
                                                                component="div"
                                                                className="validation"
                                                              />

                                                            </td>
                                                            <td>
                                                           
                                                              <Field
                                                                type="date"
                                                                disabled={values.disabledForCielops}
                                                                // name="dateOfPassingTest"
                                                              
                                                                name={`CPEDetails.${index}.dateOfPassingTest`}
                                                                onBlur={event => {
                                                                  this.updateWeightage("CPEDetailsdateOfPassingTest",event.target.value)
                                                                }}
                                                             //   value={moment(friend.dateOfPassingTest,"DD/MM/YYYY HH:mm:ss").format('YYYY-MM-DD[T]HH:mm:ss').split('T')[0]}
                                                             value= {moment(moment(friend.dateOfPassingTest,"YYYY-MM-DD").format('DD/MM/YYYY HH:mm:ss'),"DD/MM/YYYY HH:mm:ss").format('YYYY-MM-DD[T]HH:mm:ss').split('T')[0]}
                                                                className="form-control"
                                                                // onChange={(e) => {
                                                                //   let dateOfPassingTest=   moment(e.target.value,"YYYY-MM-DD").format('DD/MM/YYYY HH:mm:ss')
                                                                //   alert(values.CPEDetails[index].dateOfPassingTest)
                                                                //      setFieldValue(
                                                                //        (values.CPEDetails[index].dateOfPassingTest = dateOfPassingTest)
                                                                //      )
                                                                //    }}
                                                              />
                                                              <ErrorMessage
                                                                name={`CPEDetails.${index}.dateOfPassingTest`}
                                                                component="div"
                                                                className="validation"
                                                              />
                                                            </td>
                                                            <td>
                                                              <Field
                                                                type="text"
                                                                disabled={values.disabledForCielops}
                                                                //  name="CPECertificateNo"
                                                                name={`CPEDetails.${index}.cpeCertificateNo`}
                                                                className="form-control"
                                                                onBlur={event => {
                                                                  this.updateWeightage("CPEDetailscpeCertificateNo",event.target.value)
                                                                }}
                                                              />
                                                              <ErrorMessage
                                                                name={`CPEDetails.${index}.cpeCertificateNo`}
                                                                component="div"
                                                                className="validation"
                                                              />
                                                            </td>

                                                            <td>
                                                          
                                                              <Field
                                                                type="date"
                                                                disabled={values.disabledForCielops}
                                                                //   name="dateOfCPE"
                                                                name={`CPEDetails.${index}.dateOfCpe`}
                                                               // value= {moment(moment(friend.dateOfPassingTest,"YYYY-MM-DD").format('DD/MM/YYYY HH:mm:ss'),"DD/MM/YYYY HH:mm:ss").format('YYYY-MM-DD[T]HH:mm:ss').split('T')[0]}
                                                               onBlur={event => {
                                                                this.updateWeightage("PEDetailsdateOfCpe",event.target.value)
                                                              }}
                                                                value={moment( moment(friend.dateOfCpe,"YYYY-MM-DD").format('DD/MM/YYYY HH:mm:ss'),"DD/MM/YYYY HH:mm:ss").format('YYYY-MM-DD[T]HH:mm:ss').split('T')[0]}
                                                         
                                                                
                                                                className="form-control"
                                                              />
                                                              <ErrorMessage
                                                                name={`CPEDetails.${index}.dateOfCpe`}
                                                                component="div"
                                                                className="validation"
                                                              />
                                                            </td>



                                                            <td>
                                                              <button
                                                                type="button"
                                                                className="btn-8 ml-5 mr-5 mt-10"
                                                                disabled={values.disabledForCielops}
                                                                onClick={() => {
                                                                  
                                                                  arrayHelpers1.insert(
                                                                    index + 1,
                                                                    ""
                                                                  );
                                                                }}
                                                              >
                                                                <i
                                                                  class="fa fa-plus"
                                                                  aria-hidden="true"
                                                                ></i>
                                                              </button>
                                                              <button
                                                                type="button"
                                                                className="btn-8 ml-5 mr-5 mt-10"
                                                                disabled={values.disabledForCielops}
                                                                onClick={() => {
                                                                  arrayHelpers1.remove(
                                                                    index
                                                                  );
                                                                }}
                                                              >
                                                                <i
                                                                  class="fa fa-minus"
                                                                  aria-hidden="true"
                                                                ></i>
                                                              </button>
                                                            </td>
                                                          </tr>
                                                        )
                                                      )}
                                                    </tbody>
                                                  </table>
                                                </div>
                                              ) : (
                                                <button
                                                  type="button"
                                                  className="btn-4"
                                                  onClick={() => {
                                                    arrayHelpers1.push("");
                                                  }}
                                                >
                                                  Add CPE Details
                                                </button>
                                              )}
                                          </div>
                                        )}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </section>
                              <section
                                className="create-parent page-section profile-box"
                                id="employer-ARN-details"
                                style={this.state.processType !== "1002" ? { display: "none" } : { display: "block" }}
                              >
                                <div className="row">
                                  <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                                    <h2 className="sub-header">Employer ARN Details</h2>
                                    <div className="address-border">
                                      <div className="form-group">

                                        <div className="form-group">
                                          <div className="row">
                                            <div className="col-md-4 col-sm-4 col-xs-12">
                                              <label>ARN NO. </label>
                                              <Field
                                                type="text"
                                                name="ARNNO"
                                                id="ARNNO"
                                                disabled={values.disabledForCielops}
                                                onChange={handleChange}
                                                onBlur={event => {
                                                  this.updateWeightage(event.target.name,event.target.value)
                                                }}
                                                autoComplete="ARNNO"
                                                className="form-control"
                                                placeholder="ARN NO"
                                              />
                                              <ErrorMessage
                                                name="ARNNO"
                                                component="div"
                                                className="validation"
                                              />
                                            </div>
                                            <div className="col-md-4 col-sm-4 col-xs-12">
                                              <label>Name</label>
                                              <Field
                                                type="text"
                                                onChange={handleChange}
                                                disabled={values.disabledForCielops}
                                                onBlur={event => {
                                                  this.updateWeightage(event.target.name,event.target.value)
                                                }}
                                                max={this.state.today}
                                                name="NameOfARNHolder"
                                                id="NameOfARNHolder"
                                                className="form-control"
                                                placeholder="Name Of ARN Holder"
                                              />
                                              <ErrorMessage
                                                name="NameOfARNHolder"
                                                component="div"
                                                className="validation"
                                              />
                                            </div>

                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                  </div>
                                </div>
                              </section>
{/* Uncomment this section for ARN detais */}
                              {/* <section
                                className="create-qualification page-section profile-box"
                                id="ARN-details"
                                // style={this.state.processType !== "1001" ? { display: "none" } : { display: "none" }}

                              >
                                <div className="row">
                                  <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                                    <h2 className="sub-header">
                                   ARN Details Manoj
                                                                      </h2>

                                    <div className="">
                                      <FieldArray
                                        name="ARNuserFeilds"
                                        onChange={handleChange}
                                        render={arrayHelpers => (
                                          <div>
                                            {values.ARNuserFeilds &&
                                              values.ARNuserFeilds.length >
                                              0 ? (
                                                <div className="table-row">
                                                  <table
                                                    className="table table-bordered table-hover"
                                                    id="tab_logic"
                                                  >
                                                    <thead>
                                                      <tr>
                                                        <th className="text-center">
                                                          {" "}
                                                          Name of AMC
                                                    </th>
                                                        


                                                        <th className="text-center">
                                                          {" "}
                                                          Action{" "}
                                                        </th>
                                                      </tr>
                                                    </thead>
                                                    <tbody>
                                                      {values.ARNuserFeilds.map(
                                                        (friend, index) => (
                                                          <tr key={index}>
                                                           
                                                            <td>
                                                              <Field
                                                                as="select"
                                                                disabled={values.disabledForCielops}
                                                                name={`ARNuserFeilds.${index}.nameOfMutualFundEmpanelled`}
                                                                id="nameOfMutualFundEmpanelled"
                                                                onChange={handleChange}
                                                                onBlur={event => {
                                                                  this.updateWeightage("ARNuserFeildsnameOfMutualFundEmpanelled",event.target.value)
                                                                }}
                                                                className={
                                                                  "form-control" +
                                                                  (errors.NameOfMutualFund && touched.NameOfMutualFund
                                                                    ? " is-invalid"
                                                                    : "")
                                                                }
                                                              >
                                                                <option value="">
                                                                  Select


                                                                  
                                          </option>
                                          {(this.state.nameOfMutual || []).map(
                                            team => (
                                              <option
                                                key={team.mstAmcId}
                                                value={team.mstAmcId}
                                              >
                                                {team.mstAmcName}
                                              </option>
                                            )
                                          )}
                                                              </Field>
                                                            </td>


                                                            <td>
                                                              <button
                                                                type="button"
                                                                className="btn-8 ml-5 mr-5 mt-10"
                                                                disabled={values.disabledForCielops}
                                                                onClick={() => {
                                                                  
                                                                  console.log(values.ARNuserFeilds)
                                                                  // if(values.ARNuserFeilds.length>1){
                                                                    let values1=values.ARNuserFeilds
                                                                    // for(
                                                                    //   let i=0;i<values.ARNuserFeilds.length;i++
                                                                    // ){
                                                                    //   if(values.ARNuserFeilds[i])
                                                                    // }

                                                                    var valueArr = values1.map(function(item){ return item.nameOfMutualFundEmpanelled });
var isDuplicate = valueArr.some(function(item, idx){ 
    return valueArr.indexOf(item) != idx 
});

  if(isDuplicate){
    warning("Please Select unique records",warningNotification)
    values.ARNuserFeilds.splice(values.ARNuserFeilds.length-1,1)
    }

         console.log(isDuplicate)                                                         
      // let data=this.state.nameOfMutual+index
      // let i1=index+1
      // let i=this.state.nameOfMutual+i1
      // data.splice(15,1)
      // this.setState({
      //   i:data
      // })
                                                                  arrayHelpers.insert(
                                                                    index + 1,
                                                                    ""
                                                                  );
                                                                }}
                                                              >
                                                                <i
                                                                  class="fa fa-plus"
                                                                  aria-hidden="true"
                                                                ></i>
                                                              </button>
                                                              <button
                                                                type="button"
                                                                disabled={values.disabledForCielops}
                                                                className="btn-8 ml-5 mr-5 mt-10"
                                                                onClick={() => {
                                                                  

                                                                  arrayHelpers.remove(
                                                                    index
                                                                  );
                                                                }}
                                                              >
                                                                <i
                                                                  class="fa fa-minus"
                                                                  aria-hidden="true"
                                                                ></i>
                                                              </button>
                                                            </td>
                                                          </tr>
                                                        )
                                                      )}
                                                    </tbody>
                                                  </table>
                                                </div>
                                              ) : (
                                                <button
                                                  type="button"
                                                  className="btn-4"
                                                  onClick={() => {
                                                    arrayHelpers.push("");
                                                  }}
                                                >
                                                  Add  ARN Information
                                                </button>
                                              )}
                                          </div>
                                        )}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </section>
                               */}
                              <section
                                className="create-profile page-section profile-box"
                                id="create-profile"
                                
                              >
                                <h2 className="sub-header">Profile Details</h2>
                                <div className="form-group">
                                  <div className="row">
                                    
                                    <div className="col-md-4 col-sm-4 col-xs-12">
                                      <label>Upload Profile Image</label>
                                      <span className='validation'>*</span>

                                      <div className="profile-detail">
                                        <div className="circle">
                                          <img
                                            className="profile-pic"
                                            src={values.profilePhotoURL}
                                          />
                                          <div className="profile">
                                            <i
                                              className="fa fa-user"
                                              aria-hidden="true"
                                            ></i>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="hidden-md hidden-lg hidden-sm col-xs-12">
                                        <div className="profile-add">
                                        {/* {values.disabledForCielops==true?<span ></span>: */}
                                          <a className="icon btn-file" 
                                          disabled={values.disabledForCielops}
                                         
                                          >
                                            <i
                                              className="fa fa-plus-circle"
                                              aria-hidden="true"
                                            ></i>
                                            <FileBase64
                                              multiple={true}
                                              onChange={handleChange}
                                              onDone={event => {
                                                
                                                if (
                                                  [
                                                    "image/jpg",
                                                    "image/jpeg",
                                                    "image/png"
                                                  ].indexOf(event[0].type) < 0
                                                ) {
                                                  setFieldValue(
                                                    (values.documentTypeCheckForProfileImage = true)
                                                  );
                                                } else if (
                                                  event[0].file.size <= 1000000
                                                ) {
                                                  if(this.checkName(event[0].name))
                                                    {
                                                  values.document.push({
                                                    documentHash: event[0].base64,
                                                    documentType: "ProfilePhoto",
                                                    documentName: event[0].name
                                                  });
                                                  this.updateWeightage("profilePhotoURL",event[0].name)
                                                  setFieldValue(
                                                    (values.documentTypeCheckForProfileImage = false)
                                                  );
                                                  setFieldValue(
                                                    (values.documentSizeCheckForProfileCheck = false)
                                                  );
                                                  setFieldValue(
                                                    (values.profileImageDocumentNameValidation = false)
                                                  );

                                                  values.profilePhotoURL =
                                                    event[0].base64;
                                                  setFieldValue(
                                                    (values.profilePhotoURL =
                                                      event[0].base64)
                                                  );
                                                    }
                                                    else{
                                                      setFieldValue(
                                                        (values.profileImageDocumentNameValidation = true)
                                                      );

                                                   //   warning("File/Document name can only contain number, letter, hyphens, space. ", warningNotification);
                                                    }
                                                } else {
                                                  setFieldValue(
                                                    (values.profileImageDocumentNameValidation = false)
                                                  );

                                                  setFieldValue(
                                                    (values.documentSizeCheckForProfileCheck = true)
                                                  );
                                                }
                                              }}
                                            />
                                         
                                            {" "}
                                            <h4>Add Image</h4>
                                          </a>
        {/* } */}
                                          <div className="col-md-12 col-sm-12">
                                            <span class="file-size-limit">
                                            Please upload your latest colored passport size photo in jpg/jpeg,png format.
                                               <br />
                                              File Size: Max 1 MB
                                        </span>
                                          </div>
                                          {values.documentSizeCheckForProfileCheck ? (
                                            <p style={{ color: "red" }}>
                                              File Size is too large(Maximum File
                                              Size:1MB).
                                        </p>
                                          ) : (
                                              ""
                                            )}
                                          {values.documentTypeCheckForProfileImage ? (
                                            <p style={{ color: "red" }}>
                                              Invalid image format
                                        </p>
                                          ) : (
                                              ""
                                            )}
                                            

  {values.profileImageDocumentNameValidation ? (
                                              <p style={{ color: "red" }}>
                                               File/Document name can only contain number, letter, hyphens, space.
                                            </p>
                                            ) : (
                                                ""
                                              )}
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-md-4 col-sm-4 col-xs-12" style={{display:"none"}}>
                                      <label>Upload Text Resume</label>
                                      <div className="text-resume">
                                        <h3>Select File To Upload</h3>
                                      </div>
                                      <div className="profile-add text-center" style={{wordBreak:"break-all", padding:"11px 25px 11px 6px"
                                           }}>
                                        {values.resumeDocumentNameDuringEdit !=
                                          "" ? (
                                            <span>
                                              {values.resumeDocumentNameDuringEditName}
                                            </span>
                                          ) : values.resumeDocumentName ? (
                                            <span >{values.resumeDocumentName}</span>
                                          ) : (
                                              <span>
                                                <span className="icon">
                                                  <i
                                                    className="fa fa-times-circle"
                                                    aria-hidden="true"
                                                  ></i>
                                                </span>
                                                <h4>No File Chosen</h4>
                                              </span>
                                            )}

                                        <div>
                                        <a className="icon btn-file text-center"
                                              style={values.disabledForCielops ? {  pointerEvents: "none",
                                              cursor: "default" } : {  }}
                                          >
                                            <i
                                              className="fa fa-paperclip color_magento"
                                              aria-hidden="true"
                                            ></i>
                                            {values.documentSizeCheckForResume ? (
                                              <p style={{ color: "red" }}>
                                                File Size is too large(Maximum
                                                File Size:2MB).
                                            </p>
                                            ) : (
                                                ""
                                              )}
                                            {values.documentTypeCheckForResume ? (
                                              <p style={{ color: "red" }}>
                                                Invalid Format
                                            </p>
                                            ) : (
                                                ""
                                              )}
                                            <FileBase64
                                              multiple={true}
                                              name="resumeDocumentURL"
                                              onChange={handleChange}
                                              onDone={event => {
                                                
                                                // const checkType = event[0].name.split(
                                                //   "."
                                                // );
                                                const checkType =event[0].name.split(/\.(?=[^\.]+$)/)

                                                var regexp1=new RegExp("^[\p{L},\-\s‘’]+(?!\.)$");

                                                //    let regexp1=/^[\p{L},\-\s‘’]+(?!\.)$
                                                    let result=(!regexp1.test(event[0].name))
                                                  //  alert(ans)
                                                if(result==true)
                                                  {
                                                if (
                                                  [
                                                    "pdf",
                                                    "docx",
                                                    "rtf",
                                                    "doc"
                                                    // "application/pdf",
                                                    // "application/doc",
                                                    // "application/docx",
                                                    // "application/rtf",
                                                    // "application/msword",
                                                    // "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                                  ].indexOf(checkType[1]) < 0
                                                ) {
                                                  setFieldValue(
                                                    (values.documentTypeCheckForResume = true)
                                                  );
                                                } else if (
                                                  event[0].file.size <= 2000000
                                                ) {
                          
                                                  if(this.checkName(event[0].name))
                                                  {
                                                  
                                                  values.document.push({
                                                    documentHash: event[0].base64,
                                                    documentType:
                                                      "ResumeDocument",
                                                    documentName: event[0].name
                                                  });
                                                  this.updateWeightage("resumeDocumentURL",event[0].name)

                                                  setFieldValue(
                                                    (values.documentTypeCheckForResume = false)
                                                  );
                                                  setFieldValue(
                                                    (values.documentSizeCheckForResume = false)
                                                  );
                                                  setFieldValue(
                                                    (values.resumeDocumentNameValidation = false)
                                                  );

                                                  setFieldValue(
                                                    (values.resumeDocumentName =
                                                      event[0].name)
                                                  );
                                                  setFieldValue(
                                                    (values.resumeDocumentNameDuringEdit =
                                                      "")
                                                  );
                                                  setFieldValue(
                                                    (values.downloadResumeAfterSelecting =
                                                      event[0].base64)
                                                  );
                                                    }
                                                    else{
                                                      setFieldValue(
                                                        (values.resumeDocumentNameValidation = true)
                                                      );

                                                     // warning("File/Document name can only contain number, letter, hyphens, space. ", warningNotification);
                                                    } 
                                                    
                                                } else {
                                                  setFieldValue(
                                                    (values.resumeDocumentNameValidation = false)
                                                  );

                                                  setFieldValue(
                                                    (values.documentSizeCheckForResume = true)
                                                  );
                                                }
                                              }
                                                else{
                                                  setFieldValue(
                                                    (values.resumeDocumentNameValidation = true)
                                                  );
                                                }
                                              }}
                                            />
                                          </a>
                                        </div>
                                      </div>
                                      <div className="hidden-md hidden-lg hidden-sm col-xs-12">
                                        <div className="profile-add">
                                          <a className="icon btn-file">
                                          
                                            {values.downloadResumeAfterSelecting ? (
                                              <a
                                                href={
                                                  values.downloadResumeAfterSelecting
                                                }
                                                className="download"
                                                download={values.resumeDocumentName}
                                              >
                                                <i
                                                  className="fa fa-download"
                                                  aria-hidden="true"
                                                ></i>
                                                <h4> Download Resume </h4>
                                              </a>
                                            ) : (
                                              values.resumeDocumentURL || ""
                                            ).includes("pdf") ? (
                                                  <a
                                                    href={values.resumeDocumentURL}
                                                    className="download"
                                                    target="_blank"
                                                  >
                                                    <i
                                                      className="fa fa-download"
                                                      aria-hidden="true"
                                                    ></i>
                                                    <h4> Download Resume </h4>
                                                  </a>
                                                ) : values.resumeDocumentURL ? (
                                                  <a
                                                    href={values.resumeDocumentURL}
                                                    className="download"
                                                    // disabled={true}
                                                  >
                                                    <i
                                                      className="fa fa-download"
                                                      aria-hidden="true"
                                                    ></i>
                                                    <h4> Download Resume </h4>
                                                  </a>
                                                ) : (
                                                    <span>
                                                      <i
                                                        className="fa fa-download"
                                                        aria-hidden="true"
                                                      ></i>
                                                      <h4> Download Resume </h4>
                                                    </span>
                                                  )}
                                          </a>
                                          <div className="col-md-12 col-sm-12">
                                            <span class="file-size-limit">
                                              Supported Formats: .doc,.docx, .pdf,
                                              .rtf only. File Size: Max 2 MB
                                        </span>

                                        {values.resumeDocumentNameValidation ? (
                                              <p style={{ color: "red" }}>
                                               File/Document name can only contain number, letter, hyphens, space.
                                            </p>
                                            ) : (
                                                ""
                                              )}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-md-4 col-sm-4 col-xs-12" style={{display:"none"}}>
                                      <label>Upload Video Resume</label>
                                      <div className="profile-detail">
                                        <div className="circle">
                                          <div className="profile-pic">
                                            <div className="profile">
                                              <ReactPlayer
                                                width="100%"
                                                height="90"
                                                url={values.resumeVideoURL}
                                                playing={false}
                                                controls
                                              ></ReactPlayer>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="hidden-md hidden-lg hidden-sm col-xs-12">
                                        <div className="profile-add">
                                        {/* {values.disabledForCielops==true?<span ></span>: */}

                                        <a 
                                  
                                  style={values.disabledForCielops ? {  pointerEvents: "none",
                                  cursor: "default" } : {  }}
                                  
                                  >
                                          <span className="icon btn-file text-center"                                                                 disabled={values.disabledForCielops}
                                          >
                                            <i
                                              className="fa fa-plus-circle"
                                              aria-hidden="true"
                                            ></i>
                                            <FileBase64
                                              multiple={true}
                                              name="resumeVideoURL"
                                              onChange={handleChange}
                                              onDone={event => {
                                                if (
                                                  [
                                                    "video/mp4",
                                                    "video/avi",
                                                    "video/mpeg"
                                                  ].indexOf(event[0].type) < 0
                                                ) {
                                                  setFieldValue(
                                                    (values.documentTypeCheckForVideo = true)
                                                  );
                                                } else if (
                                                  event[0].file.size <= 3000000
                                                ) {

                                                  if(this.checkName(event[0].name))
                                                  {
                                                  
                                                  values.document.push({
                                                    documentHash: event[0].base64,
                                                    documentType: "VideoResume",
                                                    documentName: event[0].name
                                                  });
                                                  this.updateWeightage("resumeVideoURL",event[0].name)

                                                  setFieldValue(
                                                    (values.documentTypeCheckForVideo = false)
                                                  );
                                                  setFieldValue(
                                                    (values.documentSizeCheckForVideoCheck = false)
                                                  );
                                                  setFieldValue(
                                                    (values.VideodocumentNameValidation = false)
                                                  );
                                                  setFieldValue(
                                                    (values.resumeVideoURL =
                                                      event[0].base64)
                                                  );
                                                    }
                                                    else{
                                                      setFieldValue(
                                                        (values.VideodocumentNameValidation = true)
                                                      );
                                                   //   warning("File/Document name can only contain number, letter, hyphens, space.", warningNotification);
                                                    }
                                                } else {
                                                  setFieldValue(
                                                    (values.VideodocumentNameValidation = false)
                                                  );
                                                  setFieldValue(
                                                    (values.documentSizeCheckForVideoCheck = true)
                                                  );
                                                }
                                              }}
                                            />
                                        
                                          <h4>Add Video</h4>
                                          </span>
                                          </a>
                                          {/* } */}
                                          {/* <span className="icon">
                                            <i
                                              className="fa fa-times-circle"
                                              aria-hidden="true"
                                            ></i>
                                          </span>
                                          <h4>Remove Video</h4> */}
                                          {values.documentSizeCheckForVideoCheck ? (
                                            <p style={{ color: "red" }}>
                                              File Size is too large(Maximum File
                                              Size:3MB).
                                        </p>
                                          ) : (
                                              ""
                                            )}
                                          {values.documentTypeCheckForVideo ? (
                                            <p style={{ color: "red" }}>
                                              Invalid Format
                                        </p>
                                          ) : (
                                              ""
                                            )}
                                              {values.VideodocumentNameValidation ? (
                                              <p style={{ color: "red" }}>
                                               Video name should contains numbers, letters, hypens, spaces.
                                            </p>
                                            ) : (
                                                ""
                                              )}
                                          <div className="col-md-12 col-sm-12">
                                            <span class="file-size-limit">
                                              Supported Formats: .mp4, .avi, .mpeg
                                          only. <br />
                                              File Size: Max 3 MB
                                        </span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-md-4 col-sm-4 hidden-xs">
                                      <div className="profile-add">
                                      {/* {values.disabledForCielops==true?<span ></span>: */}
                                        <a className="icon btn-file"
                                  
                                    style={values.disabledForCielops ? {  pointerEvents: "none",
                                    cursor: "default" } : {  }}
                                    
                                    >
                                          <i
                                            className="fa fa-plus-circle"
                                            aria-hidden="true"
                                          ></i>
                                          <FileBase64
                                            multiple={true}
                                            onChange={handleChange}
                                            onDone={event => {
                                              if (
                                                [
                                                  "image/jpg",
                                                  "image/jpeg",
                                                  "image/png"
                                                ].indexOf(event[0].type) < 0
                                              ) {
                                                setFieldValue(
                                                  (values.documentTypeCheckForProfileImage = true)
                                                );
                                              } else if (
                                                event[0].file.size <= 1000000
                                              ) {
                                                if(this.checkName(event[0].name))
                                                {
                                                values.document.push({
                                                  documentHash: event[0].base64,
                                                  documentType: "ProfilePhoto",
                                                  documentName: event[0].name
                                                });
                                                this.updateWeightage("profilePhotoURL",event[0].name)
                                                setFieldValue(
                                                  (values.documentTypeCheckForProfileImage = false)
                                                );
                                                setFieldValue(
                                                  (values.documentSizeCheckForProfileCheck = false)
                                                );
                                                setFieldValue(
                                                  (values.profileImageDocumentNameValidation = false)
                                                );

                                                values.profilePhotoURL =
                                                  event[0].base64;
                                                setFieldValue(
                                                  (values.profilePhotoURL =
                                                    event[0].base64)
                                                );
                                                  }
                                                  else{
                                                    setFieldValue(
                                                      (values.profileImageDocumentNameValidation = true)
                                                    );
  
                                                //    warning("File/Document name can only contain number, letter, hyphens, space.", warningNotification);
                                                  }
                                              } else {
                                                setFieldValue(
                                                  (values.profileImageDocumentNameValidation = false)
                                                );

                                                setFieldValue(
                                                  (values.documentSizeCheckForProfileCheck = true)
                                                );
                                              }
                                            }}
                                          />
                                       
                                       
                                          {" "}
                                          <h4>Add Image</h4>
                                        </a>
                                        {/* } */}
                                        {/* <span className="icon">
                                          <i
                                            className="fa fa-times-circle"
                                            aria-hidden="true"
                                          ></i>
                                        </span>
                                        <h4>Remove Image</h4> */}
                                        <div className="col-md-12 col-sm-12">
                                          <span class="file-size-limit">
                                            {/* Supported Formats: .jpg, .jpeg, .png
                                          only. <br /> */}
                                           Please upload your latest colored passport size photo in jpg/jpeg, png format. <br />
                                            File Size: Max 1 MB
                                        </span>
                                        </div>
                                        {values.documentSizeCheckForProfileCheck ? (
                                          <p style={{ color: "red" }}>
                                            File Size is too large(Maximum File
                                            Size:1MB).
                                        </p>
                                        ) : (
                                            ""
                                          )}
                                        {values.documentTypeCheckForProfileImage ? (
                                          <p style={{ color: "red" }}>
                                            Invalid image format
                                        </p>
                                        ) : (
                                            ""
                                          )}
                                            {values.profileImageDocumentNameValidation ? (
                                              <p style={{ color: "red" }}>
                                               File/Document name can only contain number, letter, hyphens, space.
                                            </p>
                                            ) : (
                                                ""
                                              )}

                                      </div>
                                    </div>

                                    <div className="col-md-4 col-sm-4 hidden-xs" style={{display:"none"}}>
                                      <div className="profile-add">
                                        <a className="icon btn-file">
                                     
                                          {values.downloadResumeAfterSelecting ? (
                                            <a
                                              href={
                                                values.downloadResumeAfterSelecting
                                              }
                                              className="download"
                                              download={values.resumeDocumentName}
                                            >
                                              <i
                                                className="fa fa-download"
                                                aria-hidden="true"
                                              ></i>
                                              <h4> Download Resume </h4>
                                            </a>
                                          ) : (
                                            values.resumeDocumentURL || ""
                                          ).includes("pdf") ? (
                                                <a
                                                  href={values.resumeDocumentURL}
                                                  className="download"
                                                  target="_blank"
                                                >
                                                  <i
                                                    className="fa fa-download"
                                                    aria-hidden="true"
                                                  ></i>
                                                  <h4> Download Resume </h4>
                                                </a>
                                              ) : values.resumeDocumentURL ? (
                                                <a
                                                  href={values.resumeDocumentURL}
                                                  className="download"
                                                >
                                                  <i
                                                    className="fa fa-download"
                                                    aria-hidden="true"
                                                  ></i>
                                                  <h4> Download Resume </h4>
                                                </a>
                                              ) : (
                                                  <span>
                                                    <i
                                                      className="fa fa-download"
                                                      aria-hidden="true"
                                                    ></i>
                                                    <h4> Download Resume </h4>
                                                  </span>
                                                )}
                                        </a>
                                        <div className="col-md-12 col-sm-12">
                                          <span class="file-size-limit">
                                            Supported Formats: .doc,.docx, .pdf,
                                            .rtf only. File Size: Max 2 MB
                                        </span>
                                        {values.resumeDocumentNameValidation ? (
                                              <p style={{ color: "red" }}>
                                               File/Document name can only contain number, letter, hyphens, space.
                                            </p>
                                            ) : (
                                                ""
                                              )}

                                        </div>
                                      </div>
                                    </div>

                                    <div style={{display:"none"}} className="col-md-4 col-sm-4 hidden-xs">
                                      <div className="profile-add">
                                      <a 
                                  
                                  style={values.disabledForCielops ? {  pointerEvents: "none",
                                  cursor: "default" } : {  }}
                                  
                                  >
                                        <span className="icon btn-file text-center">
                                          <i
                                            className="fa fa-plus-circle"
                                            aria-hidden="true"
                                          ></i>
                                          <FileBase64
                                            multiple={true}
                                            name="resumeVideoURL"
                                            onChange={handleChange}
                                            onDone={event => {
                                              if (
                                                [
                                                  "video/mp4",
                                                  "video/avi",
                                                  "video/mpeg"
                                                ].indexOf(event[0].type) < 0
                                              ) {
                                                setFieldValue(
                                                  (values.documentTypeCheckForVideo = true)
                                                );
                                              } else if (
                                                event[0].file.size <= 3000000
                                              ) {

                                                if(this.checkName(event[0].name))
                                                  {
                                                values.document.push({
                                                  documentHash: event[0].base64,
                                                  documentType: "VideoResume",
                                                  documentName: event[0].name
                                                });
                                                this.updateWeightage("resumeVideoURL",event[0].name)
                                                setFieldValue(
                                                  (values.documentTypeCheckForVideo = false)
                                                );
                                                setFieldValue(
                                                  (values.documentSizeCheckForVideoCheck = false)
                                                );
                                                setFieldValue(
                                                  (values.VideodocumentNameValidation = false)
                                                );

                                                setFieldValue(
                                                  (values.resumeVideoURL =
                                                    event[0].base64)
                                                );
                                                  }
                                                  else{
                                                   // warning("File/Document name can only contain number, letter, hyphens, space.", warningNotification);
                                                   setFieldValue(
                                                    (values.VideodocumentNameValidation = true)
                                                  );

                                                  }
                                              } else {
                                                setFieldValue(
                                                  (values.VideodocumentNameValidation = false)
                                                );

                                                setFieldValue(
                                                  (values.documentSizeCheckForVideoCheck = true)
                                                );
                                              }
                                            }}
                                          />
                                     
                                        <h4>Add Video</h4>
                                        </span>
                                        </a>
                                        {/* <span className="icon">
                                            <i
                                              className="fa fa-times-circle"
                                              aria-hidden="true"
                                            ></i>
                                          </span>
                                          <h4>Remove Video</h4> */}
                                        {values.documentSizeCheckForVideoCheck ? (
                                          <p style={{ color: "red" }}>
                                            File Size is too large(Maximum File
                                            Size:3MB).
                                        </p>
                                        ) : (
                                            ""
                                          )}
                                        {values.documentTypeCheckForVideo ? (
                                          <p style={{ color: "red" }}>
                                            Invalid Format
                                        </p>
                                        ) : (
                                            ""
                                          )}
                                          {values.VideodocumentNameValidation ? (
                                              <p style={{ color: "red" }}>
                                               Video name should contains numbers, letters, hypens, spaces.
                                            </p>
                                            ) : (
                                                ""
                                              )}

                                        <div className="col-md-12 col-sm-12">
                                          <span class="file-size-limit">
                                            Supported Formats: .mp4, .avi, .mpeg
                                          only. <br />
                                            File Size: Max 3 MB
                                        </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </section>
                              <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 mb-20 pl pr profile-footer">

                              {
                               
                              roledata.map((item, key) => (
                                        item.description === 'Update User Profile' ? 
                                        item.objectActionId.applicationObjectActionName === 'My User Profile' ? 
                                        item.objectActionId.applicationObjectId.applicationObjectName === 'Save' ?
                                
                              <div >
<button
                                  onClick={async () => {
                                    
                                   
                        //            this.setState({documents:values.document})
                                    
                                  //  this.setState({ValidationState:'SUBMITTED'})
                                    setFieldValue(
                                      (values.submitStatus = 'SUBMITTED')
                                    );
                                    console.log(values)
                                  
                                    const curErrors = await formikProps.validateForm();
                                    console.log(curErrors)
                                    let errorData=[]
                                    errorData.push(curErrors)
                                   
                                    const curErrorsKeys = Object.keys(curErrors);

                                    if (curErrorsKeys.length) {

                                      if(errorData.length>0){
                                        warning("You have either not filled all mandatory fields or not uploaded all document for NISM. In case you don't have your documents ready, please save your profile as 'Save Draft'. Add the documents to move to the next section",warningNotification)
                                      }
                                      const el = document.getElementById(
                                        curErrorsKeys[0]
                                      );

                                      if (el) el.focus();
                                    }
                                  }}
                                  className="btn-4 pull-right"
                                  name="submitForm"
                                  type="submit"
                                >
                                  Submit
                              </button>
                              {/* localStorage.getItem('profilecomlitionvalue')>70 &&  */}
                                {values.applicationStatus=="SUBMITTED"?"":
                                
                                <button
                                  onClick={async () => {
                                console.log(values)
                               

setFieldValue(
  (values.submitStatus = 'DRAFTED')
);
console.log(values)

                                    const curErrors = await formikProps.validateForm();
                                    const curErrorsKeys = Object.keys(curErrors);

                                    if (curErrorsKeys.length) {
                                      const el = document.getElementById(
                                        curErrorsKeys[0]
                                      );

                                      if (el) el.focus();
                                    }
                                  }}
                                  className="btn-4 pull-right"
                                  name="submitForm"
                                  type="submit"
                                >
                                  Save Draft
                              </button>
        }
                                
                              </div>
                              : "" : "":""))
                              }
                              <button
                                  onClick={async () => {
                                    
                                    if(localStorage.getItem('roleId')==5)
                                    {
                                    this.props.history.push("/learnerDashboard");
                                    }
                                    else{
                                      this.props.history.push("/dashboard");
                                    }
                        
                                  }}
                                  className="btn-4 pull-right"
                                  name="submitForm"
                                  type="button"
                                >
                                  Cancel
                              </button>
                           
                                          
                                  
</div>
                              {/* <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 mb-20">
                                <button
                                  onClick={async () => {
                                    const curErrors = await formikProps.validateForm();
                                    const curErrorsKeys = Object.keys(curErrors);

                                    if (curErrorsKeys.length) {
                                      const el = document.getElementById(
                                        curErrorsKeys[0]
                                      );

                                      if (el) el.focus();
                                    }
                                  }}
                                  className="btn-4 pull-right"
                                  name="submitForm"
                                  type="submit"
                                >
                                  Submit
                              </button>
                              </div> */}
                            </div>
                          </Form>
                        </main>
                      </div>
                  </div>
                </div>

                <Modal
            open={this.state.showWhyTheFillForm}
            onClose={this.onWhyTheFillFormCloseModal}
            center
          >
            <div
              className=""
              id="roadmap"
             tabindex="-1" role="dialog" aria-labelledby="modalLabel"    data-keyboard="true" aria-hidden="true"
            >
              <div className="modal-dialog modal-md" role="document">
                <div class="modal-content">
                  <div className="modal-header">
                    <div class="scr-board">
                      <h2>Why Fill The Form? </h2> 
                       <div class="bottom-line"></div>
                    </div>
                    {/* <h4 className="modal-title">FinX Core Login</h4> */}
                  </div>
                  <div className="modal-body">
                  The Profile Form is a means of gathering your information which would help us in serving you more efficiently through your journey towards becoming a Mutual Fund Advisor.
                  </div>
                  <div class="modal-footer">
                    {/* <button
                      type="button"
                      className="btn-5 mt-40 mr-20"
                      name="btnYes"
                    onClick={this.getPaymentLink}
                    >
                      Yes
                    </button> */}
                    <button
                      type="button"
                      className="btn-5 mt-40"
                      name="btnNo"
                      onClick={this.onWhyTheFillFormCloseModal}
                    >
                      Ok
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Modal>
              </section>
            </React.Fragment>
          )}
      />
    );
  }
}

export default UserProfile;