import React, { Component } from "react";
import DashboardHeader from "../../components/dashboardheader/dashboardheader";
import Finxfooter from "../finxfooter/finxfooter";
import ViewRoles from "../../components/roles/view-roles";
import { connect } from "react-redux";
import * as moment from "moment";
import { bindActionCreators } from "redux";
import { LoginService } from "../../services/login.service";
import AdminHeader from "../../components/dashboardheader/adminheader";
import { success, successNotification, error, errorNotification, warning, warningNotification } from "../../components/notification/notifications";

import { fetchTermsAndCondition, fetchTestInstruction } from '../../services/preAssessment.Service';
import { getPreAssessmentTermAndCondition, getPreAssessmentInstruction } from '../../reducer/preAssessmentReducer';
import FeatTest from "../../components/preAssessment/feattest";

const mapStateToProps = state => ({
  termCondition: getPreAssessmentTermAndCondition(state.PreAssessmentReducer),
  testIntroduction: getPreAssessmentInstruction(state.PreAssessmentReducer),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchTermsAndCondition: fetchTermsAndCondition,
      fetchTestInstruction: fetchTestInstruction
    },
    dispatch
  );

const connectFunction = connect(mapStateToProps, mapDispatchToProps);

const PreAssessment = connectFunction(
  class extends Component {
    constructor(props) {
      super(props);
      this.state = {
        demo: "demo test",
        termAndCondition: null,
        testIntroduction: null
      };
    }

    componentDidMount() {
      let partyId = JSON.parse(localStorage.login).partyId;
      var termConditionPayload = {
        "productId": this.props.location.state.productId,
        "testId": 12,
        "userid": partyId
      }
      this.setState({
        termAndCondition: this.props.fetchTermsAndCondition(termConditionPayload),
        testIntroduction: this.props.fetchTestInstruction(termConditionPayload)
      });
    }


    render() {


      return (
        <React.Fragment>
          <DashboardHeader />
          {/* <ProgressBar {...this.props} /> */}
          <FeatTest {...this.props} />
          <Finxfooter />
        </React.Fragment>
      );
    }
  }
);

export default PreAssessment;
