import { Global_var } from '../global/global_var';
import RestDataSource from './restdatasource';
import { LoginService } from './login.service';

export const LearnerAssessmentService = {
    fetchLearnerPrequisiteByProductID
};

function fetchLearnerPrequisiteByProductID(learnerpartyProdID, fn, fnError) {
    LoginService.TokenUser(res => {
        if (res.status === "success") {
            localStorage.setItem("jwt-token", res.responseObject);
            var url = Global_var.BASEURL + Global_var.URL_LEARNER_ASSESSMENT_PREQUISITE;
            return new RestDataSource(url, fnError).GetOneByParam(learnerpartyProdID, (res) => {
                if (res != null) {
                    if (res.headers["jtitoken"] != null) {
                        localStorage.setItem('jti-token', res.headers.jtitoken);
                    }
                    fn(res.data);
                }
            });
        }
    });
}


