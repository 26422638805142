import { Global_var } from "../global/global_var";
import RestDataSource from "./restdatasource";
import { LoginService } from "./login.service";
import { func } from "prop-types";
import {
  fetchUserProfileData,
  fetchUserAddressData
} from "../action/userProfile";

export const RegisterUserService = {
  RegisterUser,
  getUserPersonnelDetails,
  getUserAddress,
  updateUserProfile,
  uploadUserFiles,
  AHRegisterUser,
  AHRegisterUserBulk,
  getEmployeType,
  updateMyTour,
  getAdminUserAddress,
  bulkUpload,
  checkPAN,
  validateUserData,
  createdBy,
  paymentLink,
  AHActivateUser,
  sendMail,
  certificateDownload,
  downloadDocumet,
  uploadDocumet,
  downloadZip,
  downloadDocumet1,
  sendMailKYD,
  sendKYDMail,
  sendSms,
  emailAuthntication,
  bulkPaymentUpload,
  examDateUpload,
};

function RegisterUser(user, fn, fnError) {
  LoginService.TokenUser(res => {
    if (res.status === "success") {
      //   let URL='http://192.168.12.113:9005'
      localStorage.setItem("jwt-token", res.responseObject);
      var url = Global_var.BASEURL + Global_var.URL_FC_REGISTER_USER;
      // Global_var.BASEURL
      //var mobilevalidat = JSON.stringify(ProductByName);
      return new RestDataSource(url, fnError).Store(user, res => {
        if (res != null) {
          if (res.headers["jtitoken"] != null) {
            localStorage.setItem("jti-token", res.headers.jtitoken);
          }
          fn(res.data);
        }
      });
    }
  }, fnError);
}

function AHRegisterUser(user, fn, fnError) {
  LoginService.TokenUser(res => {
    if (res.status === "success") {
      localStorage.setItem("jwt-token", res.responseObject);
      var url = Global_var.BASEURL + Global_var.URL_AH_REGISTER_USER;

      //var mobilevalidat = JSON.stringify(ProductByName);
      return new RestDataSource(url, fnError).Store(user, res => {
        if (res != null) {
          if (res.headers["jtitoken"] != null) {
            localStorage.setItem("jti-token", res.headers.jtitoken);
          }
          fn(res.data);
        }
      });
    }
  }, fnError);
}


function AHRegisterUserBulk(user, fn, fnError) {
  LoginService.TokenUser(res => {
    if (res.status === "success") {
      localStorage.setItem("jwt-token", res.responseObject);
      var url = Global_var.BASEURL + Global_var.URL_AH_REGISTER_USER_BULK;

      //var mobilevalidat = JSON.stringify(ProductByName);
      return new RestDataSource(url, fnError).Store(user, res => {
        if (res != null) {
          if (res.headers["jtitoken"] != null) {
            localStorage.setItem("jti-token", res.headers.jtitoken);
          }
          fn(res.data);
        }
      });
    }
  }, fnError);
}

function getUserPersonnelDetails(user, fn, fnError) {
  //user="learnerPartyID=199986"

  return dispatch => {
    //   var url="http://192.168.12.113:8061/api/v1/learner"
    var url = Global_var.BASEURL + Global_var.URL_USERDATA;
    return new RestDataSource(url, fnError).GetOneByParam(user, res => {
      if (res != null) {
        if (res.headers["jtitoken"] != null) {
          localStorage.setItem("jti-token", res.headers.jtitoken);
        }
        dispatch(fetchUserProfileData(res.data));
      }
    });
  };
}

function getUserAddress(user, fn, fnError) {
  // user="partyId=199986"


  return dispatch => {
    // var url = 'http://192.168.12.113:9015/api/v1/party/address'
    var url = Global_var.BASEURL + Global_var.URL_USERADDRESS;
    return new RestDataSource(url, fnError).GetOneByParam(user, res => {
      if (res != null) {
        if (res.headers["jtitoken"] != null) {
          localStorage.setItem("jti-token", res.headers.jtitoken);
        }

        dispatch(fetchUserAddressData(res.data));
        fn(res.data);
      }
    });
  };
}

function getAdminUserAddress(user, fn, fnError) {
  var url = Global_var.BASEURL + Global_var.URL_USERADDRESS;
  return new RestDataSource(url, fnError).GetOneByParam(user, res => {
    if (res != null) {
      if (res.headers["jtitoken"] != null) {
        localStorage.setItem("jti-token", res.headers.jtitoken);
      }

      fn(res.data);
    }
  });
}

function updateUserProfile(user, fn) {
  LoginService.TokenUser(res => {
    localStorage.setItem("jwt-token", res.responseObject);
    // let url = "http://192.168.12.113:9001/fc-admin/api/v1/admin/updateuser"
    let url = Global_var.BASEURL + Global_var.URL_UPDATEUSERPROFILE;
    return new RestDataSource(url).Update(user, res => fn(res.data));
  });
}

function uploadUserFiles(file, fn) {
  LoginService.TokenUser(res => {
    localStorage.setItem("jwt-token", res.responseObject);
    var url = Global_var.BASEURL + Global_var.URL_UPLOADFILES;
    return new RestDataSource(url).Store(file, res => fn(res));
  });
}
function getEmployeType(fn, fnError) {
  let emptype = "codeTypeName=employment";
  var url = Global_var.BASEURL + Global_var.URL_GETLOOKUP_BY_CD_TYPE_NAME;
  return new RestDataSource(url, fnError).GetOneByParam(emptype, res => {
    if (res != null) {
      if (res.headers["jtitoken"] != null) {
        localStorage.setItem("jti-token", res.headers.jtitoken);
      }
      fn(res.data);
    }
  });
}

function updateMyTour(data, fn, fnError) {
  let url = Global_var.BASEURL + Global_var.URL_USERDATA;
  return new RestDataSource(url, fnError).Update(data, res => {
    fn(res.data);
    if (res != null) {
      if (res.headers["jtitoken"] != null) {
        localStorage.setItem("jti-token", res.headers.jtitoken);
      }
    }
  });
}

function bulkUpload(file, fn) {
  //var url = Global_var.BASEURL + Global_var.URL_BULK_UPLOAD;
  var url = Global_var.BASEURL + Global_var.URL_BULK_UPLOAD_SCORE
  return new RestDataSource(url).Store(file, res => {
    if (res != null) {

      if (res.headers["jtitoken"] != null) {
        localStorage.setItem("jti-token", res.headers.jtitoken);
      }
      fn(res);
    }
  });
}

function bulkPaymentUpload(file, fn) {
    var url = Global_var.BASEURL + Global_var.URL_BULK_PAYMENT_UPLOAD
    return new RestDataSource(url).Store(file, res => {
      if (res != null) {
  
        if (res.headers["jtitoken"] != null) {
          localStorage.setItem("jti-token", res.headers.jtitoken);
        }
        fn(res);
      }
    });
  }
function examDateUpload(file, fn) {
    var url = Global_var.BASEURL + Global_var.URL_EXAMDATE_UPLOAD
    return new RestDataSource(url).Store(file, res => {
      if (res != null) {
        if (res.headers["jtitoken"] != null) {
          localStorage.setItem("jti-token", res.headers.jtitoken);
        }
        fn(res);
      }
    });
  }
  

function checkPAN(user, fn, fnError) {

  var url = Global_var.BASEURL + Global_var.URL_Check_PAN;
  return new RestDataSource(url, fnError).GetOneByParam(user, res => {
    if (res != null) {
      if (res.headers["jtitoken"] != null) {
        localStorage.setItem("jti-token", res.headers.jtitoken);
      }

      fn(res.data);
    }
  });
}

function validateUserData(user, fn, fnError) {
  var url = Global_var.BASEURL + Global_var.URL_Validate_New_User;
  return new RestDataSource(url, fnError).GetOneByParam(user, res => {
    if (res != null) {
      if (res.headers["jtitoken"] != null) {
        localStorage.setItem("jti-token", res.headers.jtitoken);
      }

      fn(res.data);
    }
  });
}

function createdBy(user, fn, fnError) {
  var url = Global_var.BASEURL + Global_var.URL_CREATED_BY;
  return new RestDataSource(url, fnError).GetOneByParam(user, res => {
    if (res != null) {
      if (res.headers["jtitoken"] != null) {
        localStorage.setItem("jti-token", res.headers.jtitoken);
      }

      fn(res.data);
    }
  });
}

function AHActivateUser(user, fn, fnError) {
  LoginService.TokenUser(res => {
    if (res.status === "success") {
      localStorage.setItem("jwt-token", res.responseObject);
      var url = Global_var.BASEURL + Global_var.URL_AH_ACTIVATE_TEST_USER;

      //var mobilevalidat = JSON.stringify(ProductByName);
      return new RestDataSource(url, fnError).Store(user, res => {
        if (res != null) {
          if (res.headers["jtitoken"] != null) {
            localStorage.setItem("jti-token", res.headers.jtitoken);
          }
          fn(res.data);
        }
      });
    }
  }, fnError);
}

function paymentLink(data, fn) {
  LoginService.TokenUser(res => {
    localStorage.setItem("jwt-token", res.responseObject);
    var url = Global_var.BASEURL + Global_var.URL_SEND_EMAIL;
    // let url = "http://192.168.10.107:9000/fc-learner/api/v1/learner/send/email"
    // let url = Global_var.BASEURL + Global_var.URL_UPDATEUSERPROFILE;
    return new RestDataSource(url).Store(data, res => fn(res.data));
  });
}

function sendMail(data, fn) {
  LoginService.TokenUser(res => {
    localStorage.setItem("jwt-token", res.responseObject);
    var url = Global_var.BASEURL + Global_var.URL_SEND_MAIL;

    return new RestDataSource(url).Store(data, res => fn(res.data));
  });
}

function sendMailKYD(data, fn) {
  LoginService.TokenUser(res => {
    localStorage.setItem("jwt-token", res.responseObject);
    var url = Global_var.BASEURL + Global_var.URL_SEND_MAIL_KYD;

    return new RestDataSource(url).Store(data, res => fn(res.data));
  });
}

function certificateDownload(data, fn) {
  LoginService.TokenUser(res => {
    localStorage.setItem("jwt-token", res.responseObject);
    var url = Global_var.BASEURL + Global_var.URL_CERTIFICATE_DOWNLOAD;

    return new RestDataSource(url).Store(data, res => fn(res.data));
  });
}

function downloadDocumet(data, fn, fnError) {
  LoginService.TokenUser(res => {
    localStorage.setItem("jwt-token", res.responseObject);

    var url = Global_var.BASEURL + Global_var.URL_DOCUMENT_DOWNLOAD;

    return new RestDataSource(url, fnError).GetOneByParam(data, res => {
      if (res != null) {
        if (res.headers["jtitoken"] != null) {
          localStorage.setItem("jti-token", res.headers.jtitoken);
        }

        fn(res.data);
      }
    });
  });
}

function downloadDocumet1(data, fn, fnError) {
  LoginService.TokenUser(res => {
    localStorage.setItem("jwt-token", res.responseObject);

    var url = Global_var.BASEURL + Global_var.URL_ADD_PRODUCT_THUMBNAIL_VIDEO;

    return new RestDataSource(url, fnError).GetOneByParam(data, res => {
      if (res != null) {
        if (res.headers["jtitoken"] != null) {
          localStorage.setItem("jti-token", res.headers.jtitoken);
        }

        fn(res.data);
      }
    });
  });
}
function uploadDocumet(data, fn, fnError) {
  //alert(Global_var.BASEURL + Global_var.URL_DOCUMENT_DOWNLOAD)
  LoginService.TokenUser(res => {
    localStorage.setItem("jwt-token", res.responseObject);
    var url = Global_var.BASEURL + Global_var.URL_ADD_PRODUCT_THUMBNAIL_VIDEO;

    return new RestDataSource(url).Store(data, res => fn(res.data));
  });
}

function downloadZip(data, fn, fnError) {
  LoginService.TokenUser(res => {
    localStorage.setItem("jwt-token", res.responseObject);
    var url = Global_var.BASEURL + Global_var.URL_ZIP;
    return new RestDataSource(url).Store(data, res => fn(res.data));
  });
}

function sendKYDMail(data, fn) {
  LoginService.TokenUser(res => {
    localStorage.setItem("jwt-token", res.responseObject);
    var url = Global_var.BASEURL + Global_var.URL_KYD_MAIL;

    return new RestDataSource(url).Store(data, res => fn(res.data));
  });
}

function sendSms(data, fn) {
  LoginService.TokenUser(res => {
    localStorage.setItem("jwt-token", res.responseObject);
    var url = Global_var.BASEURL + Global_var.URL_SEND_SMS;
  //  var url = "http://172.16.16.99:9006" + Global_var.URL_SEND_SMS;
    return new RestDataSource(url).Store(data, res => fn(res.data));
  });
}

function emailAuthntication(emailID, fn, fnError) {
  var url = Global_var.BASEURL + Global_var.URL_AUTHENTICATE_EMAIL;
  return new RestDataSource(url, fnError).GetOneByParam(emailID, res => {
    if (res != null) {
      if (res.headers["jtitoken"] != null) {
        localStorage.setItem("jti-token", res.headers.jtitoken);
      }

      fn(res.data);
    }
  });
}