import React from 'react';
import banner1 from '../../assets/images/banner1.jpg';
import banner2 from '../../assets/images/banner2.jpg';
import banner3 from '../../assets/images/banner3.jpg';
import banner4 from '../../assets/images/banner4.jpg';

import $ from "jquery";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import FinxRegistration from "../../components/mainpage/finxregistration/finxregistration";
import daplogo from '../../assets/images/dapIcon.png';
import { Global_var } from "../../global/global_var";

// const responsive = {
//     desktop: {
//         breakpoint: { max: 3000, min: 1024 },
//         items: 5,
//         slidesToSlide: 1, // optional, default to 1.
//         partialVisibilityGutter: 40

//     },
//     tablet: {
//         breakpoint: { max: 1024, min: 464 },
//         items: 5,
//         slidesToSlide: 1, // optional, default to 1.
//     },
//     mobile: {
//         breakpoint: { max: 464, min: 0 },
//         items: 5,
//         slidesToSlide: 1, // optional, default to 1.
//     },
// };
class cfxroadmap extends React.Component {
    //function cfxroadmap(props) {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    componentDidMount() {
        
        localStorage.setItem("Register", "false")
        localStorage.setItem("Login", "true")

        if (localStorage.getItem("showLogin") !== "true") {
           // window.$('#feat-Reg').modal('show')
            // window.$('#feat-Reg').modal({backdrop: 'static', keyboard: false},'show')
            localStorage.setItem("showLogin", "true")
        }

        var header = document.getElementById("navbarSupportedContent1");
        var btns = header.getElementsByClassName("nav-item");
        for (var i = 0; i < btns.length; i++) {
            btns[i].addEventListener("click", function () {
                var current = document.getElementsByClassName("active");
                current[0].className = current[0].className.replace(" active", "");
                this.className += " active";
            });
        }

        $('body').bind('click', function (e) {
            if ($(e.target).closest('#navbarSupportedContent1').length == 0) {
                // click happened outside of .navbar, so hide
                var opened = $('.navbar-collapse').hasClass('collapse in');
                if (opened === true) {
                    $('.navbar-collapse').removeClass('in');
                }
            }
        });

        $("#hideMenu").click(function () {
            $(".navbar-collapse").removeClass("in");
        });
        $("#hideMenu1").click(function () {
            $(".navbar-collapse").removeClass("in");
        });
        $("#hideMenu2").click(function () {
            $(".navbar-collapse").removeClass("in");
        });
        $("#hideMenu3").click(function () {
            $(".navbar-collapse").removeClass("in");
        });
        $("#hideMenu4").click(function () {
            $(".navbar-collapse").removeClass("in");
        });
        $("#hideMenu5").click(function () {
            $(".navbar-collapse").removeClass("in");
        });
        $("#hideMenu6").click(function () {
            $(".navbar-collapse").removeClass("in");
        });
        $("#hideMenu7").click(function () {
            $(".navbar-collapse").removeClass("in");
        });
        $("#hideMenu8").click(function () {
            localStorage.setItem("Register", "true")
            localStorage.setItem("Login", "false")
            $(".navbar-collapse").removeClass("in");
        });
        $("#hideMenu9").click(function () {
            localStorage.setItem("Login", "true")
            localStorage.setItem("Register", "false")
            $(".navbar-collapse").removeClass("in");
        });


    }
    handleOpen = (event, value) => {
        event.preventDefault();
        let uatUrl = "https://uatservices.expertmfd.com/thor-gateway-expertmfd"; //UAT
        let prodUrl = "https://services.expertmfd.com/thor-gateway-expertmfd"; //DNS  production --enable this for prod build
        let testUrl = "https://emfdmicro.njtechdesk.com/thor-gateway-expertmfd"; //DEV
        // "https://rcp.njtechdesk.com/"     // TEST RCP
        // "https://uat.rcp.ciel.co.in/"     // UAT RCP
        // "https://rcp.ciel.co.in/"         // PROD RCP
        let testRcpUrl,testRcpUrl1;
        if(Global_var.BASEURL === testUrl){
            testRcpUrl = "https://rcp.njtechdesk.com/";
        } else if (Global_var.BASEURL === uatUrl){
            testRcpUrl = "https://uat.rcp.ciel.co.in/";
        } else if(Global_var.BASEURL === prodUrl) {
            testRcpUrl = "https://rcp.ciel.co.in/";
        }
        
        if(value === "Register") {
            testRcpUrl1 = `${testRcpUrl}?openModal=${value}`;
            window.open(testRcpUrl1,'_blank')
        } else if(value === "Login"){
            testRcpUrl1 = `${testRcpUrl}?openModal=${value}`;
            window.open(testRcpUrl1,'_blank')
        }
    }
    render() {
        return (
            <React.Fragment>

                <section className="dap-header" >
                    <nav className="navbar navbar navbar-fixed-top">
                        {/*  Brand/logo */}
                        {/* <div className="navbar-header">
                                <button type="button" className="navbar-toggle collapsed" data-toggle="modal" data-target="#flipFlop" aria-expanded="false">
                                    <span data-toggle="modal"    data-keyboard="true" data-target="#login">Login &nbsp; <i className="fa fa-sign-in"
                                        aria-hidden="true"></i></span>
                                </button> */}
                        {/* </div>  */}

                        {/* <div className="container-fluid padding-pl-pr"> */}
                        {/*  Brand/logo */}
                        {/* <div className="navbar-header">
                                <button type="button" className="navbar-toggle collapsed" data-toggle="modal" data-target="#flipFlop" aria-expanded="false">
                                    <span data-toggle="modal"    data-keyboard="true" data-target="#login">Login &nbsp; <i className="fa fa-sign-in"
                                        aria-hidden="true"></i></span>
                                </button>
                                <a className="navbar-brand" href="/"><img src={daplogo} alt="Dap Logo" /></a>
                            </div> */}
                        {/* <button id="hideMenu9" class="navbar-toggler toggler-example" type="button" data-toggle="collapse" data-target="#navbarSupportedContent1"
                            aria-controls="navbarSupportedContent1" aria-expanded="false" aria-label="Toggle navigation"><span class="dark-blue-text"><i
                                class="fa fa-bars"></i></span></button> */}
                        <button class="navbar-toggler " type="button" data-toggle="collapse" data-target="#navbarSupportedContent1"
                            aria-controls="navbarSupportedContent1" aria-expanded="false" aria-label="Toggle navigation">
                            <span class="dark-blue-text"><i
                                class="fa fa-bars"></i></span>
                        </button>

                        {/* <div className="col-md-12">  */}
                        <a className="navbar-brand" href="/"><img src={daplogo} alt="Dap Logo" /></a>
                        {/* </div>  */}

                        {/* <div className="col-md-12"> */}
                        <div class="collapse navbar-collapse" id="navbarSupportedContent1">

                            <ul class="navbar-nav mr-auto mt-2 mt-lg-0">
                                <li class="nav-item active" id="hideMenu">
                                    <a class="nav-link" href="#home">Home </a>
                                </li>
                                <li class="nav-item" id="hideMenu1">
                                    <a class="nav-link" href="#about">About</a>
                                </li>
                                <li class="nav-item" id="hideMenu2">
                                    <a class="nav-link" href="#benefits">Benefits</a>
                                </li>
                                <li class="nav-item" id="hideMenu3">
                                    <a class="nav-link disabled" href="#processflow">ExpertMFD Process</a>
                                </li>
                                {/* <li class="nav-item">
                                    <a class="nav-link disabled" href="#testimonials">Testimonials</a>
                                </li> */}
                                <li class="nav-item" id="hideMenu4">
                                    <a class="nav-link disabled" href="#videos">Videos</a>
                                </li>
                                <li class="nav-item" id="hideMenu5">
                                    <a class="nav-link disabled" href="#referralprogram">Referral</a>
                                </li>
                                <li class="nav-item" id="hideMenu6">
                                    <a class="nav-link disabled" href="#faq">FAQs</a>
                                </li>
                                <li class="nav-item" id="hideMenu7">
                                    <a class="nav-link disabled" href="#ourpartners">Our Partners</a>
                                </li>
                                <li class="nav-item" id="hideMenu8">
                                    <a class="nav-link disabled" onClick={(e)=>{this.handleOpen(e,"Register")}} href style={{color: "#085dab "}} >Register
                                    {/* href="#" data-keyboard="true" data-toggle="modal" data-keyboard="true" data-target="#feat-Reg" data-backdrop="static" data-keyboard="false" style={{color: "#085dab "}}>Register */}
                                    </a>
                                    {/* <a class="nav-link disabled" href="#signup">Registers</a> */}
                                </li>
                                <li class="nav-item" id="hideMenu9">
                                     <a class="nav-link disabled" onClick={(e)=>{this.handleOpen(e,"Login")}} href style={{color: "#085dab "}} >Login
                                     {/* href="#" data-keyboard="true" data-toggle="modal" data-keyboard="true" data-target="#feat-Reg" data-backdrop="static" data-keyboard="false" style={{color: "#085dab "}}>Login */}
                                    </a> 
                                </li>
                                {/* <li class="nav-item">
                                    <a class="nav-link disabled" href="#"><i class="fa fa-search" aria-hidden="true"></i></a>
                                </li> */}
                            </ul>
                        </div>

                        {/* </div>  */}
                    </nav>

                </section>

                <section className="banner section-padding" id="home">
                    <Carousel infiniteLoop useKeyboardArrows autoPlay autoPlaySpeed={3000}>
                        <div>
                            <a className="padding-01" onClick={(e)=>{this.handleOpen(e,"Register")}} href>
                            {/* href="#" data-keyboard="true" data-toggle="modal" data-keyboard="true" data-target="#feat-Reg" > */}
                                <img src={banner1} />
                            </a>
                            {/* <p className="legend">Legend 1</p> */}

                            {/* <div className="caption">
                                <div className="col-md-7 col-sm-7 col-xs-12">
                                    <h2 className="banner-title">
                                        LOREM IPSUM LOREM IPSUM
                    </h2>
                                    <p className="banner-text">
                                        Lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum
                    </p>
                                    <div className="banner-button">

                                        <span className="right-icon">
                                            <a href=""
                                                data-keyboard="true" data-toggle="modal"    data-keyboard="true" data-target="#feat-Reg" >
                                                <button type="button" className="banner-btn">
                                                    LOGIN/SIGNUP
                            </button>
                                            </a>

                                        </span>

                                    </div>
                                </div>
                            </div> */}
                        </div>
                        <div>
                            <a href="#about" className="padding-01"> <img src={banner2} /> </a>
                            {/* <div className="caption">
                                <div className="col-md-7 col-sm-7 col-xs-12">
                                    <h2 className="banner-title">
                                        LOREM IPSUM LOREM IPSUM
                    </h2>
                                    <p className="banner-text">
                                        Lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum
                    </p>
                                    <div className="banner-button">
                                        <span className="right-icon">
                                            <a href=""
                                                data-keyboard="true" data-toggle="modal"    data-keyboard="true" data-target="#feat-Reg" >
                                                <button type="button" className="banner-btn">
                                                    LOGIN/SIGNUP
                            </button>
                                            </a>

                                        </span>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                        <div>
                            <a href="#processflow" className="padding-01"> <img src={banner3} /> </a>
                            {/* <div className="caption">
                                <div className="col-md-7 col-sm-7 col-xs-12">
                                    <h2 className="banner-title">
                                        LOREM IPSUM LOREM IPSUM
                    </h2>
                                    <p className="banner-text">
                                        Lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum
                    </p>
                                    <div className="banner-button">
                                        <span className="right-icon">
                                            <a href=""
                                                data-keyboard="true" data-toggle="modal"    data-keyboard="true" data-target="#feat-Reg" >
                                                <button type="button" className="banner-btn">
                                                    LOGIN/SIGNUP
                            </button>
                                            </a>

                                        </span>                                </div>
                                </div>
                            </div> */}
                        </div>
                        <div>
                            <a href="#benefits" className="padding-01">  <img src={banner4} /> </a>
                            {/* <div className="caption">
                                <div className="col-md-7 col-sm-7 col-xs-12">
                                    <h2 className="banner-title">
                                        LOREM IPSUM LOREM IPSUM
                    </h2>
                                    <p className="banner-text">
                                        Lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum
                    </p>
                                    <div className="banner-button">
                                        <span className="right-icon">
                                            <a href=""
                                                data-keyboard="true" data-toggle="modal"    data-keyboard="true" data-target="#feat-Reg" >
                                                <button type="button" className="banner-btn">
                                                    LOGIN/SIGNUP
                            </button>
                                            </a>

                                        </span>                               </div>
                                </div>
                            </div> */}
                        </div>
                        {/* <div>
                            <img src={banner1} />
                            <div className="caption">
                                <div className="col-md-7 col-sm-7 col-xs-12">
                                    <h2 className="banner-title">
                                        LOREM IPSUM LOREM IPSUM
                    </h2>
                                    <p className="banner-text">
                                        Lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum
                    </p>
                                    <div className="banner-button">
                                        <span className="right-icon">
                                            <a href="#login"
                                                data-keyboard="true" data-toggle="modal"    data-keyboard="true" data-target="#feat-Reg" >
                                                <button type="button" className="banner-btn">
                                                    LOGIN/SIGNUP
                            </button>
                                            </a>

                                        </span>                                 </div>
                                </div>
                            </div>
                        </div> */}
                    </Carousel>

                </section>
                <FinxRegistration {...this.props} />
            </React.Fragment>
        );
    }
}

export default cfxroadmap;
