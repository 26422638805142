// eslint-disable-next-line no-unused-vars
import React, { Component } from 'react';

class CreateUser extends Component {
    constructor(props) {
        super(props);

    }
    render() {
        return (
            <React.Fragment>
                <section className="dashboard-tab user-tab">
                    <div className="padding-rl-50">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-12 col-sm-12 col-xs-12">
                                    <div className="table-header">
                                        <h3 className="text-center">
                                            <span className="left-icon"><i className="fa fa-th-large" aria-hidden="true"></i></span> Create User
                                <span className="right-icon"><a href=""><i className="fa fa-info" aria-hidden="true"></i></a></span>
                                        </h3>
                                    </div>
                                    <div className="bg-box">
                                        <section className="create-user">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <div className="row">
                                                            <div className="col-md-4 col-sm-6 col-xs-12">
                                                                <label>BUSINESS UNIT</label>
                                                                <input type="text" className="form-control" placeholder="BU Name" required />
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-4 col-sm-6 col-xs-12">
                                                                <label>FULL NAME </label>
                                                                <input type="text" className="form-control" placeholder="Full Name" required />
                                                            </div>
                                                            <div className="col-md-4 col-sm-6 col-xs-12">
                                                                <label>MIDDLE NAME</label>
                                                                <input type="text" className="form-control" placeholder="Middle Name" required />
                                                            </div>
                                                            <div className="col-md-4 col-sm-6 col-xs-12">
                                                                <label>LAST NAME </label>
                                                                <input type="text" className="form-control" placeholder="Last Name" required />
                                                            </div>
                                                            <div className="col-md-4 col-sm-6 col-xs-12">
                                                                <label>LOGIN NAME</label>
                                                                <input type="text" className="form-control" placeholder="admin" required />
                                                            </div>
                                                            <div className="col-md-4 col-sm-6 col-xs-12">
                                                                <label>PASSWORD</label>
                                                                <input type="text" className="form-control" placeholder="admin@123" required />
                                                            </div>
                                                            <div className="col-md-4 col-sm-6 col-xs-12">
                                                                <label>ROLL ID</label>
                                                                <select type="select" className="form-control" placeholder="Educational Qualification" required>
                                                                    <option>25471</option>
                                                                </select>
                                                            </div>
                                                            <div className="col-md-4 col-sm-6 col-xs-12">
                                                                <label>CREATED BY</label>
                                                                <select type="select" className="form-control" placeholder="Educational Qualification" required>
                                                                    <option>Admin</option>
                                                                </select>
                                                            </div>
                                                            <div className="col-md-4 col-sm-6 col-xs-12">
                                                                <label>ACTIVE FROM</label>
                                                                <input type="date" className="form-control" placeholder="" required />

                                                            </div>

                                                            <div className="col-md-4 col-sm-6 col-xs-12">
                                                                <label>ACTIVE TILL</label>
                                                                <input type="date" className="form-control" placeholder="" required />

                                                            </div>
                                                            <div className="col-md-4 col-sm-6 col-xs-12">
                                                                <label>CREATED ON</label>
                                                                <input type="text" className="form-control" placeholder="08/01/2019" required />
                                                            </div>
                                                            <div className="col-md-4 col-sm-6 col-xs-12">
                                                                <label>LAST MODIFIED BY</label>
                                                                <input type="text" className="form-control" placeholder="Admin" required />
                                                            </div>
                                                            <div className="col-md-4 col-sm-6 col-xs-12">
                                                                <label>LAST MODIFIED ON</label>
                                                                <input type="text" className="form-control" placeholder="30/05/2019" required />
                                                            </div>
                                                            <div className="col-md-4 col-sm-6 col-xs-12">
                                                                <label>LAST LOGIN DATE-TIME</label>
                                                                <input type="text" className="form-control" placeholder="" required />
                                                            </div>
                                                            <div className="col-md-8">
                                                                <a href="#">
                                                                    <button type="button" className="btn-1 mt-30 pull-right">Submit</button>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </React.Fragment>

        );
    }

}

export default CreateUser;