import React, { Component } from "react";
import {
  BrowserRouter as Router,
  Route,
  Link,
  Redirect,
  Switch,
  withRouter,
} from "react-router-dom";
import { hashHistory } from "react-router";
import CreateUser from "./create-user/create-user";
import LandingPage from "../containers/landingPage/landingPage";
import Finxdashboardlanding from "../containers/finxdashboardlanding/finxdashboardlanding";
import Learnerdashboardlanding from "../containers/finxdashboardlanding/learnerdashboardlanding";
import Changepassword from "../components/mainpage/forgotpassword/changepassword";
import Forgotpassword from "../components/mainpage/forgotpassword/forgotpassword";
import ForgotpasswordNext from "../components/mainpage/forgotpassword/forgotpasswordmobile";
import CheckoutContainer from "../containers/checkout/checkout-container";
import AdminDashboard from "../containers/admin/admin-dashboard-container";
import GdpiScore from "../containers/gdpi/gdpi-score";
import { Nav, NavItem, NavLink } from "reactstrap";
import CheckoutPaytm from "./checkout/checkout-paytm";
import Roles from "../containers/roles/roles";
import AdminProfile from "../containers/admin/admin-user";
import AdminList from "../containers/admin/admin-list";
import AdminEdit from "../containers/admin/admin-user-edit";
import AdminBulkUploadUser from "../components/admin/admin-bulk-upload";
import AdminBulkUploadgdpi from "../components/admin/admin-bulk-upload-gdpi";
import AdminAddUserCourse from "../components/admin/adminAddUserCourse";
import Razorpay from "./checkout/razorpay";

import ErrorPage from "./paymentstatus/Error";
import ThankYouPage from "./paymentstatus/ThankYou";
import Finxprivacypolicy from "../containers/finxfooter/finxprivacypolicy";
import Finxdisclaimer from "../containers/finxfooter/finxdisclaimer";
import Finxcookiespolicy from "../containers/finxfooter/finxcookiespolicy";
//import Privacydetails from '../Privacydetails/Privacydetails';
import Privacydetails from "../containers/finxfooter/privacydetails";
import Disclaimer from "../containers/finxfooter/disclaimer";
import Cookies from "../containers/finxfooter/cookies";
import Admindashboardlanding from "../containers/finxdashboardlanding/admindashboardlanding";
import Newadmin from "../components/admin-dashboard/admin";
import AdminSocialStreaming from "../components/admin-dashboard/adminSocialStreaming";
import ResentActivity from "../components/admin-dashboard/recentActivity";
import NewAdminProfile from "../components/admin-dashboard/newAdminProfile";
import Roadmap from "../components/roadmap";
import MyAlerts from "../components/learner-dashboard/myalerts";
import AdminAlert from "../components/admin-dashboard/adminalerts";
import AdminNotification from "../components/admin-dashboard/adminnotification";
import Dashboardheader from "../components/dashboardheader/dashboardheader";
import AdminProfileEdit from "./admin-dashboard/adminEditProfile";
import MyTour from "./learner-dashboard/mytour";
import MyMessagesInbox from '../components/learner-dashboard/mymessagesinbox';
import MyMessagesCreate from '../components/learner-dashboard/mymessagescreate'
import MyMessagesCompose from '../components/learner-dashboard/mymessagescreate_old'

//import AdminProductModalList from "../components/admin/adminProductModalList";
//import AdminProductModalEdit from "../components/admin/adminProductModalEdit";
// import FinxHeader from "../containers/finxheader/finxheader";
//import AddProduct from "../components/product/addProduct"
import ProductAddList from "../containers/product-list/productAddContainers";
import ProductList from "../containers/product-list/productListContainers";
import ProductEdit from "../containers/product-list/productEditContainers";
import MyPrequisite from "./learner-dashboard/myprequesite";
//import EditProduct from "../components/product/editProduct";
import OrderReportLanding from "../containers/learner-report/orderReportLanding";
import EnrollmentLanding from "../containers/report/enrollmentLanding";

import PreAssessment from "../containers/preAssessment/preAssessment"
import ModulesList from "../containers/modules/modulesListContainers";
import LearningPath from "../containers/learningPath/learningPathContainers";
import Finxfaq from "../containers/finxfooter/finxfaq";
import Notification from "../components/mainpage/notification";
import AdminHeader from "../components/dashboardheader/adminheader";
import FinxHeader from "../containers/finxheader/finxheader";
import AmfiFinxHeader from "../containers/finxheader/amfifinxheader";
import SentEmailList from "../containers/sentemail/sentemail";
import SentEmail from "../components/sentemail/senteEmailList";
import UserActivity from "../components/report/usermanagement";
import UserActivityLanding from "../containers/report/useractivityLanding";
import AssessmentReportLanding from "../containers/report/assessmentLanding";
import FinixFooterLinks from "../containers/finxfooter/finxfooterlinks";
import FinxFooterLinks from "../containers/finxfooter/finxfooterlinks";
import AdminAddProductUser from "../components/product/addProductUsers";
import UnassignedUsersReportLanding from "../containers/report/unassignedUsersLanding";
import CfxAssessmentReport from '../components/report/cfxAssessmentReport';
import Distributordashboardlanding from "../containers/distributorDashboard/distributorDashboardAuthenticate";

//const BrowserHistory = require('react-router/lib/BrowserHistory').default;
// import Thankyoupressesment from "../components/preAssessment/thankyou";
import ReattemptTest from "../containers/reattempt-Test/reattemptTest";

import StatusStepper from "../components/statusStepper/statusStepper";
import Funnel from "../components/distributorList/funnel"
import DateRequest from "../containers/dateRequest/dateRequest"
import ViewAssesmentResult from "../components/view-assessment-result/viewAssessmentResult";
import UploadScore from "../components/cielopsUploadScore/uploadScore";
import BulkPayment from "../components/cielopsBulkPayment/bulkPayment";
import BulkExamDate from "../components/cielopsUploadDateRequest/uploadDateRequest";
import Schedular from "../components/Schedular/schedular.js";
import CielReport from "../components/ciel-report/cielReport";
import EmpanellementReport from "../components/ciel-report/empanellementReport";
import RegisterSuccess from "../components/mainpage/finxregistration/registerSuccess"
import EmailVerification from "../components/mainpage/finxregistration/emailVerification"

import AmfiPage from "../containers/amfiPage/amfiPage";
// import Homepage from "../containers/amfiPage/Homepage.jsx";

export default class Routes extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Router>
        <div>
          <Link to="/"></Link>
          <Link to="/learnerDashboard"></Link>
          <Link to="/userprofile"></Link>
          <Link to="/changepassword"></Link>
          <Link to="/forgotpassword"></Link>
          <Link to="/forgotpasswordnext"></Link>
          <Link to="/checkout"></Link>
          <Link to="/checkout-paytm"></Link>
          <Link to="/admin-list"></Link>
          <Link to="/admin-dashboard"></Link>
          <Link to="/roles"></Link>
          <Link to="/gdpi"></Link>
          <Link to="/error"></Link>
          <Link to="/thankyou"></Link>
          <Link to="/Dap-privacy-policy"></Link>
          <Link to="/finxdisclaimer"></Link>
          <Link to="/Dap-cookies-policy"></Link>
          <Link to="/privacy-details"></Link>
          <Link to="/disclaimer"></Link>
          <Link to="/cookies"></Link>
          <Link to="/new-admin"></Link>
          <Link to="/admin-profile"></Link>
          <Link to="/AdminProfile"></Link>
          <Link to="/admin-edit-user-profile"></Link>
          <Link to="/course-list"></Link>
          <Link to="/add-course"></Link>
          <Link to="/roadmap"></Link>
          <link to="/dashboardheader"></link>
          {<link to="/edit-course"></link>}
          <link to="/adminProductModalList"></link>
          {/* <Link to="/adminProductModalEdit"></Link> */}
          <link to="/welcome-page"></link>
          <link to="/my-tour"></link>
          <link to="/myalerts"></link>
          <link to="/modules-list"></link>
          <Link to="/learning-path"></Link>
          <link to="/adminalert"></link>
          <link to="/adminnotification"></link>
          <link to="/report-enrollment"></link>
          <Link to="/my-Subcription-list"></Link>
          <Link to="/faq"></Link>
          <link to="/notification"></link>
          <link to="/adminheader"></link>
          <link to="/finxheader"></link>
          <link to="/amfifinxheader"></link>
          <Link to="/send-mail"></Link>
          <link to="/send-mail-list"></link>
          <link to="/add-learner-course"></link>
          <link to="/add-course-learner"></link>
          <link to="/user-activity-report"></link>
          <link to="/user-activity"></link>
          <link to="/assessment-report"></link>
          <Link to="/finixfooter-links"></Link>
          <Link to="/my-inbox"></Link>
          <Link to="/my-inbox-create"></Link>
          <Link to="/my-inbox-compose"></Link>

          <Link to="/dashboard"></Link>
          <Link to="/amcDashboard"></Link>
          <Link to="/fitTest"></Link>
          <Link to="/preAssessment"></Link>
          <Link to="/Thankyoupressesment"></Link>
          <Link to="/PreassessmentView"></Link>
          <Link to="/StatusStepper"></Link>
          <Link to="/Funnel"></Link>
          <Link to="/dateRequest"></Link>
          <Link to="/ViewAssessmentResult"></Link>
          <Link to="/uploadScore"></Link>
          <Link to="/bulkPayment"></Link>
          <Link to="/bulkExamDate"></Link>
          <Link to="/ViewAssessmentResult#behavioural"></Link><Link to="/ViewAssessmentResult#factual"></Link>


          <Link to="/Schedular"></Link>
          <Link to="/CielReport"></Link>
          <Link to="/EmpanellementReport"></Link>
          <Link to="/RegisterSuccess"></Link>
          <Link to="/EmailVerification"></Link>
          <Link to="/amfiLogin"></Link>
         

          <Route 
            exact
            path="/ciel-report"
            render={(props) => {
              return <CielReport {...props} />;
            }}
          ></Route>

          <Route
            exact
            path="/empanellement-report"
            render={(props) => {
              return <EmpanellementReport {...props} />;
            }}
          ></Route>
           <Route
            exact
            path="/registerSuccess"
            render={(props) => {
              return <RegisterSuccess {...props} />;
            }}
          ></Route>

          <Route
            exact
            path="/emailVerification/:eid"
            render={(props) => {
              return <EmailVerification {...props} />;
            }}
          ></Route>
          
          <Route
            exact
            path="/Schedular"
            render={(props) => {
              return <Schedular {...props} />;
            }}
          ></Route>
          <Route
            exact
            path="/"
            render={(props) => {
              return <LandingPage {...props} />;
            }}
          ></Route>
          <Route
            exact
            path="/src=tw"
            render={(props) => {
              return <LandingPage {...props} />;
            }}
          ></Route>
          <Route
            exact
            path="/src=fb"
            render={(props) => {
              return <LandingPage {...props} />;
            }}
          ></Route>

          <Route
            exact
            path="/CfxAssessmentReport/:id/:prodid"
            render={(props) => {
              return <CfxAssessmentReport {...props} />;
            }}
          ></Route>
          <Route
            exact
            path="/learnerDashboard"
            render={(props) => {

              return localStorage.getItem('roleId') == 5 && localStorage.getItem('ischangepass') !== "1" ?
                <Learnerdashboardlanding {...props} /> : window.location.href = "/";

            }}
          ></Route>
          <Route
            path="/userprofile"
            render={(props) => {
              return <Finxdashboardlanding {...props} />;
            }}
          ></Route>
          <Route
            exact
            path="/changepassword"
            render={(props) => {
              return <Changepassword {...props} />;
            }}
          ></Route>
          <Route
            exact
            path="/forgotpassword"
            render={(props) => {
              return <Forgotpassword {...props} />;
            }}
          ></Route>
          <Route
            exact
            path="/forgotpasswordnext"
            render={(props) => {
              return <ForgotpasswordNext {...props} />;
            }}
          ></Route>
          <Route
            path="/checkout"
            render={(props) => {
              return <CheckoutContainer {...props} />;
            }}
          ></Route>
          <Route
            path="/checkout-paytm"
            render={(props) => {
              return <CheckoutPaytm {...props} />;
            }}
          ></Route>

          <Route
            path="/razorpay"
            render={(props) => {
              return <Razorpay {...props} />;
            }}
          ></Route>

          <Route
            path="/my-inbox"
            render={(props) => {
              return <MyMessagesInbox {...props} />;
            }}
          ></Route>
          <Route
            path="/my-inbox-create"
            render={(props) => {
              return <MyMessagesCreate {...props} />;
            }}
          ></Route>
          <Route
            exact
            path="/my-inbox-compose"
            render={(props) => {
              return <MyMessagesCompose {...props} />;
            }}
          ></Route>
          <Route
            path="/preAssessment"
            render={(props) => {
              return <PreAssessment {...props} />;
            }}
          ></Route>
          <Route
            path="/admin-user-list"
            render={(props) => {
              return <AdminList {...props} />;
            }}
          ></Route>

          <Route
            path="/add-learner-course"
            render={(props) => {
              return <AdminAddUserCourse {...props} />;
            }}
          ></Route>

          <Route
            path="/add-course-learner"
            render={(props) => {
              return <AdminAddProductUser {...props} />;
            }}
          ></Route>

          <Route
            path="/roles"
            render={(props) => {
              return <Roles {...props} />;
            }}
          ></Route>

          <Route
            path="/gdpi"
            render={(props) => {
              //  return <GdpiScore {...props} />;
              return localStorage.getItem('roleId') == 6 || localStorage.getItem('roleId') == 7 ? window.location.href = "/" : window.location.href = "/";
              //<GdpiScore {...props} /> ;
            }}
          ></Route>
          <Route
            path="/userbulkupload"
            render={(props) => {
              return <AdminBulkUploadUser {...props} />;
            }}
          ></Route>
          <Route
            path="/adminbulkuploadgdpi"
            render={(props) => {
              return <AdminBulkUploadgdpi {...props} />;
            }}
          ></Route>
          <Route
            path="/error"
            render={(props) => {
              return <ErrorPage {...props} />;
            }}
          ></Route>
          <Route
            path="/thankyou"
            render={(props) => {
              return <ThankYouPage {...props} />;
            }}
          ></Route>
          <Route
            path="/Dap-privacy-policy"
            render={(props) => {
              return <Finxprivacypolicy {...props} />;
            }}
          ></Route>
          <Route
            path="/Dap-disclaimer"
            render={(props) => {
              return <Finxdisclaimer {...props} />;
            }}
          ></Route>
          <Route
            path="/Dap-cookies-policy"
            render={(props) => {
              return <Finxcookiespolicy {...props} />;
            }}
          ></Route>
          <Route
            path="/privacy-details"
            render={(props) => {
              return <Privacydetails {...props} />;
            }}
          ></Route>
          <Route
            path="/disclaimer"
            render={(props) => {
              return <Disclaimer {...props} />;
            }}
          ></Route>
          <Route
            path="/cookies"
            render={(props) => {
              return <Cookies {...props} />;
            }}
          ></Route>
          <Route
            path="/admin-dashboard"
            render={(props) => {
              return <Admindashboardlanding {...props} />;
            }}
          ></Route>
          <Route
            path="/new-admin"
            render={(props) => {
              return <Newadmin {...props} />;
            }}
          ></Route>
          <Route
            path="/admin-profile"
            render={(props) => {
              return <NewAdminProfile {...props} />;
            }}
          ></Route>
          <Route
            path="/admin-user-profile"
            render={(props) => {
              return <AdminProfile {...props} />;
            }}
          ></Route>
          <Route
            path="/admin-edit-user-profile"
            render={(props) => {
              return <AdminEdit {...props} />;
            }}
          ></Route>
          <Route
            path="/course-list"
            render={(props) => {
              return <ProductList {...props} />;
            }}
          ></Route>

          <Route
            path="/add-course"
            render={(props) => {
              return <ProductAddList {...props} />;
            }}
          ></Route>

          <Route
            path="/admin-profile-edit"
            render={(props) => {
              return <AdminProfileEdit {...props} />;
            }}
          ></Route>
          <Route
            path="/roadmap"
            render={(props) => {
              return <Roadmap {...props} />;
            }}
          ></Route>

          <Route
            path="/myalerts"
            render={(props) => {
              return <MyAlerts {...props} />;
            }}
          ></Route>
          <Route
            exact
            path="/my-tour"
            render={(props) => {
              return <MyTour {...props} />;
            }}
          ></Route>

          <Route
            path="/dashboardheader"
            render={(props) => {
              return <Dashboardheader {...props} />;
            }}
          ></Route>

          <Route
            path="/edit-course"
            render={(props) => {
              return <ProductEdit {...props} />;
            }}
          ></Route>
          <Route
            path="/my-Subcription-list"
            render={(props) => {
              return <MyPrequisite {...props} />;
            }}
          ></Route>

          <Route
            path="/modules-list"
            render={(props) => {
              return <ModulesList {...props} />;
            }}
          ></Route>

          <Route
            path="/learning-path"
            render={(props) => {
              return <LearningPath {...props} />;
            }}
          ></Route>

          <Route
            path="/report-enrollment"
            render={(props) => {
              return <EnrollmentLanding {...props} />;
            }}
          ></Route>
          <Route
            path="/my-orders"
            render={(props) => {
              return <OrderReportLanding {...props} />;
            }}
          ></Route>
          <Route
            path="/adminalert"
            render={(props) => {
              return <AdminAlert {...props} />;
            }}
          ></Route>

          <Route
            path="/adminnotification"
            render={(props) => {
              return <AdminNotification {...props} />;
            }}
          ></Route>

          <Route
            path="/notification"
            render={(props) => {
              return <Notification {...props} />;
            }}
          ></Route>

          <Route
            path="/adminheader"
            render={(props) => {
              return <AdminHeader {...props} />;
            }}
          ></Route>
          <Route
            path="/faq"
            render={(props) => {
              return <Finxfaq {...props} />;
            }}
          ></Route>
          <Route
            path="/finxheader"
            render={(props) => {
              return <FinxHeader {...props} />;
            }}
          ></Route>
          <Route
            path="/amfifinxheader"
            render={(props) => {
              return <AmfiFinxHeader {...props} />;
            }}
          ></Route>
          <Route
            path="/send-mail"
            render={(props) => {
              return <SentEmailList {...props} />;
            }}
          ></Route>
          <Route
            path="/send-mail-list"
            render={(props) => {
              return <SentEmail {...props} />;
            }}
          ></Route>
          <Route
            path="/user-activity-report"
            render={(props) => {
              return <UserActivity {...props} />;
            }}
          ></Route>
          <Route
            path="/user-activity"
            render={(props) => {
              return <UserActivityLanding {...props} />;
            }}
          ></Route>
          <Route
            path="/assessment-report"
            render={(props) => {
              return <AssessmentReportLanding {...props} />;
            }}
          ></Route>

          <Route
            path="/finixfooter-links"
            render={(props) => {
              return <FinxFooterLinks {...props} />;
            }}
          ></Route>
          <Route
            path="/unassigned-users-report"
            render={(props) => {
              return <UnassignedUsersReportLanding {...props} />;
            }}
          ></Route>

          <Route
            path="/dashboard"
            render={(props) => {
              return localStorage.getItem('roleId') == 6 || localStorage.getItem('roleId') == 7 ? <Distributordashboardlanding {...props} /> : window.location.href = "/";
            }}
          ></Route>
          <Route
            path="/amcDashboard"
            render={(props) => {
              return <Distributordashboardlanding {...props} />;
            }}
          ></Route>



          {/* <Route
            exact
            path="/Thankyoupressesment"
            render={(props) => {
              return <Thankyoupressesment {...props} />;
            }}
          ></Route> */}
          <Route
            exact
            path="/PreassessmentView"
            render={(props) => {
              return localStorage.getItem('roleId') == 6 ? <ReattemptTest {...props} /> : window.location.href = "/";



            }}
          ></Route>
          <Route
            exact
            path="/StatusStepper"
            render={(props) => {
              return <StatusStepper {...props} />;
            }}
          ></Route>


          <Route
            exact
            path="/Funnel"
            render={(props) => {
              return <Funnel {...props} />;
            }}
          ></Route>

          <Route
            exact
            path="/dateRequest"
            render={(props) => {
              return localStorage.getItem('roleId') == 6 ?
                <DateRequest {...props} /> : window.location.href = "/";





            }}
          ></Route>
          <Route
            exact
            path="/:page/:learnerPartyId"
            render={(props) => {
              return <LandingPage {...props} />;
            }}
          ></Route>
          <Route
            exact
            path="/ViewAssessmentResult"
            render={(props) => {
              return <ViewAssesmentResult {...props} />;
            }}
          ></Route>
          <Route
            exact
            path="/ViewAssessmentResult#behavioural"
            render={(props) => {
              return <ViewAssesmentResult {...props} />;
            }}
          ></Route>
          <Route
            exact
            path="/ViewAssessmentResult#factual"
            render={(props) => {
              return <ViewAssesmentResult {...props} />;
            }}
          ></Route>

          <Route
            exact
            path="/uploadScore"
            render={(props) => {
              return localStorage.getItem('roleId') == 6 ? <UploadScore {...props} /> : window.location.href = "/";
            }}
          ></Route>

          <Route
            exact
            path="/bulkPayment"
            render={(props) => {
              return localStorage.getItem('roleId') == 6 ? <BulkPayment {...props} /> : window.location.href = "/";
            }}
          ></Route>
          <Route
            exact
            path="/bulkExamDate"
            render={(props) => {
              return localStorage.getItem('roleId') == 6 ? <BulkExamDate {...props} /> : window.location.href = "/";
            }}
          ></Route>
          
          {/* <Route
            exact
            path="/amfiLogin"
            render={(props) => {
              return <Homepage {...props} />;
            }}
          ></Route> */}
          <Route
            exact
            path="/amfiLogin"
            render={(props) => {
              return <AmfiPage {...props} />;
            }}
          ></Route>
          
        </div>
      </Router>
    );
  }
}
