import React, { Component } from "react";
import DashboardHeader from "../../components/dashboardheader/dashboardheader";
import Finxfooter from "../finxfooter/finxfooter";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import DistributorList from "../../components/distributorList/distributorListBySearch";
import { LoginService } from "../../services/login.service";
import { PopUpPortal } from "../popups/PopUpPortal";
import $ from "jquery";
import { getAlertNotificationEvents, getAlertNotificationEventsPending, getAlertNotificationEventsError } from "../../reducer/alertsNotificationReducer"
import { getNotificationEvents, getNotificationEventsPending, getNotificationEventsError } from "../../reducer/notificationReducer";
import { fetchDistributor } from "../../services/distributor.service";
import { fetchCalenderEvents, fetchAlertNotificationEvents, fetchNotificationEvents, fetchProductDetailsByPartyId, fetchProductDetailsAllIsActive, getProgressOverview } from "../../services/fetchLearnerDashboard.service";
import { getFunnelCount } from "../../services/distributor.service";
import { getStatusCount, getStatusCountError, getStatusCountPending } from "../../reducer/funnelStatusCountReducer";
import { getDistributorProfile, getDistributorProfileError, getDistributorProfilePending } from "../../reducer/disributorListProfile";
import Unauthorized from "../../components/unauthorized/Unauthorized";
import { fetchUserRoleAccess } from "../../services/userAccess.service";
import { getUserRoleAcess } from "../../reducer/roleAccessReducer";
import LandingPage from "../landingPage/landingPage";
import "./cielStyle.css";
//Bind data to frontend
const mapStateToProps = state => (

    {
        distributorProfileUser: getDistributorProfile(state.distributorListReducer),
        pendingdistributorProfileUser: getDistributorProfilePending(state.distributorListReducer),
        errordistributorProfileUser: getDistributorProfileError(state.distributorListReducer),

        statusCountList: getStatusCount(state.funnelStatusCountReducer),
        pendingStatusCountList: getStatusCountPending(state.funnelStatusCountReducer),
        errorStatusCountList: getStatusCountError(state.funnelStatusCountReducer),

        alertNotificationEvents: getAlertNotificationEvents(
            state.alertNotificationEventsReducer
        ),

        pendingAlertNotificationEvents: getAlertNotificationEventsPending(
            state.alertNotificationEventsReducer
        ),

        errorAlertNotificationEvents: getAlertNotificationEventsError(
            state.alertNotificationEventsReducer
        ),

        notificationEvents: getNotificationEvents(state.notificationEventsReducer),
        pendingNotificationEvents: getNotificationEventsPending(
            state.notificationEventsReducer
        ),
        errorNotificationEvents: getNotificationEventsError(
            state.notificationEventsReducer
        ),
        userRoleAccess: getUserRoleAcess(state.roleAccessReducer)
    });
//Give API call
const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            fetchDistributor: fetchDistributor,
            fetchAlertNotificationEvents: fetchAlertNotificationEvents,
            fetchNotificationEvents: fetchNotificationEvents,
            getFunnelCount: getFunnelCount,
            fetchUserRoleAccess: fetchUserRoleAccess
        },
        dispatch
    );

const connectFunction = connect(mapStateToProps, mapDispatchToProps);

const distributorDashboardAuthenticate = connectFunction(
    class extends Component {
        constructor(props) {
            super(props);
            this.state = {
                loading: true
            };
        }

        componentDidMount() {

            let RoleId = localStorage.getItem('roleId')
            let partyID = localStorage.getItem("ciel-userid-Token");
            const { fetchNotificationEvents,
                fetchDistributor, getFunnelCount, fetchUserRoleAccess } = this.props;
            LoginService.TokenUser(res => {
                if (res.status === "success") {
                    localStorage.setItem("jwt-token", res.responseObject);

                   // fetchDistributor("firstName=&lastName=&fromDate=&toDate=&emailId=&panNumber=&cityId=0&roleId=" + localStorage.getItem("roleId") + "&applicationStatus=&stage=&additionalDetail=false&examLocation=&examFromDate=&examToDate=&stateId=&learnerId=0&amcId=0");
                    fetchAlertNotificationEvents();
                    //Uncoment fetchNotificationEvents if reuired
                    //fetchNotificationEvents("partyId=" + partyID);
                    getFunnelCount();//"partyId=" + partyID
                    const roleDetails = {
                        roleId: {
                            roleId: localStorage.getItem('roleId')
                        }
                    }
                    fetchUserRoleAccess(roleDetails);
                    // if (localStorage.getItem('reload') === 'true') {
                    //     localStorage.setItem('reload', 'false');
                    //     window.location.reload();
                    // }
                }
            });
        }

        render() {

            const { distributorProfileUser, pending, fetchUserAddressData,
                alertNotificationEvents,
                pendingAlertNotificationEvents,
                notificationEvents,
                pendingNotificationEvents,
                pendingStatusCountList,
                statusCountList,
                pendingdistributorProfileUser,
                userRoleAccess

            } = this.props;
            localStorage.setItem("PersonnelData", JSON.stringify(this.props.personnelData))
            localStorage.setItem('AdminAlertNotification', JSON.stringify(alertNotificationEvents))
            //localStorage.setItem('AdminNotification', JSON.stringify(notificationEvents))

            console.log(userRoleAccess)
            console.log("userRoleAccess")

            if (!pendingNotificationEvents && !pendingAlertNotificationEvents
                && !pendingStatusCountList && !pendingdistributorProfileUser) {
                return (
                    <React.Fragment>
                        {/* {localStorage.getItem("roleId") == 6 || localStorage.getItem("roleId") == 7 ? */}
                        <React.Fragment>
                            <DashboardHeader
                                alertNotificationEvents={this.props.alertNotificationEvents}
                                //statusCountList={statusCountList}
                                notificationEvents={this.props.notificationEvents}
                                adminUser={distributorProfileUser}
                                {...this.props}

                            />
                            <DistributorList
                                distributorProfileUser={distributorProfileUser}
                                statusCountList={statusCountList}
                                //getAdminAddress={fetchUserAddressData}
                                fetchDistributor={fetchDistributor}
                                userRoleAccess={userRoleAccess}
                                {...this.props}
                            />
                            <Finxfooter />
                        </React.Fragment>
                        {/* : */}
                        {/* <LandingPage {...this.props} />
                            //  <Unauthorized />

                        } */}
                    </React.Fragment>
                );
            } else {
                return (<React.Fragment>
                    <DashboardHeader
                        adminProfileUser={this.props.pending}
                        {...this.props}

                    />
                </React.Fragment>)
            }
        }
    }
);

export default distributorDashboardAuthenticate;
