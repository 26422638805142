import React, { Component } from "react";
import { LoginService } from "../../services/login.service";
import { success, successNotification, error, errorNotification, warning, warningNotification } from "../notification/notifications";

import { fetchQuestionOption, updateTermsAndCondition, updateScore, updateStatus, reattemptSendMail } from "../../services/preAssessment.Service"
import Countdown from 'react-countdown';
import Circle from 'react-circle';
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import * as moment from "moment";

import { ReattemptTestService } from "../../services/reattemptTest.service"
class FeatTest extends Component {
    constructor(props) {

        super(props);
        if (localStorage.getItem('login') !== null) {
            this.state = {
                data: [],
                data1: []
            };


        }
        else {
            window.location.href = '/';
        }

    }


    onScrollStep = () => {
        if (window.pageYOffset === 0) {
            clearInterval(this.state.intervalId);
        }
        window.scroll(0, window.pageYOffset - this.props.scrollStepInPx);
    }

    scrollToTop = () => {
        let intervalId = setInterval(this.onScrollStep, this.props.delayInMs);
        this.setState({ intervalId: intervalId });
    }
    componentDidMount() {




        let tempdata = {
            "workflowId": this.props.personnelProfileData.learnerID,
            // "204092",
            "history": true
        }
        ReattemptTestService.getStatuscompleted(tempdata, res => {
            let tem = res.data.responseListObject
            // let temp1 = []
            // tem.map(e => {
            //     let userid = e.createdBy
            //     ReattemptTestService.getRoleForAudit("userId=" + userid, res1 => {
            //         //  res.data.responseListObject['roleName']=
            //         e['roleName'] = res1.responseObject.userRoleMatrixList[0].roleId.roleName


            //     })
            //     temp1 = tem

            // })
            // console.log(tem)
            this.setState({
                data: tem

            })

            console.log("this.state.data audit:: "+this.state.data)
        })
    }



    render() {
        return (
            <div id="auditBox" >
                <div className="container-fluid" id="preassessment" style={{ paddingTop: "0px !important" }}>
                    {this.state.data !==null && this.state.data.length > 0 && this.state.data.map((e, i) => {

                        return (
                            <div class="boxbg gridChange assess-body">
                                {/* <div class="question-style"> Sr. No. {i + 1}</div> */}
                                <div class="auditStage">{e.state.applicationStatus}</div>
                                <div id="assessmentOption">
                                    <div className="row">
                                        <div className="col-md-3 col-sm-6 col-xs-12">
                                            <h4 className="alpabate">Status</h4>
                                            <p>{e.state.applicationStatus}</p>
                                        </div>
                                        <div className="col-md-3 col-sm-6 col-xs-12">
                                            <h4 className="alpabate">Action Owner</h4>
                                            <p>{e.createdBy === 102 ? "CIEL OPS" : e.createdBy === 103 ? "AMC" : "DISTRIBUTOR"}</p>
                                        </div>
                                        <div className="col-md-3 col-sm-6 col-xs-12">
                                            <h4 className="alpabate">Start Date</h4>

                                            {/* {moment("13/02/2021", "DD/MM/YYYY").format("DD-MM-YYYY")} */}
                                            <p>{e.createdDate && moment(e.createdDate.split(" ")[0], "DD/MM/YYYY").format("DD-MM-YYYY")}</p>
                                        </div>
                                        <div className="col-md-3 col-sm-6 col-xs-12">
                                            <h4 className="alpabate">End Date</h4>
                                            <p>{e.lastModifiedDate && moment(e.lastModifiedDate.split(" ")[0], "DD/MM/YYYY").format("DD-MM-YYYY")}</p>
                                        </div>
                                        {/* <div className="col-md-4 col-sm-6 col-xs-12">
                                            <h4 className="alpabate">Comment</h4>
                                            <p>{e.comment}</p>
                                        </div> */}
                                    </div>
                                </div >
                            </div >
                        )
                    })}
                </div >
            </div >
        );
    }
}

export default FeatTest;
