import React, { Component } from "react";
import DashboardHeader from "../../components/dashboardheader/dashboardheader";
import Finxfooter from "../finxfooter/finxfooter";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import UserProfile from "../../components/userprofile/userProfileLandingPage";
import { RegisterUserService } from "../../services/registration.service";
import { LoginService } from "../../services/login.service";
import { getAlertNotificationEvents, getAlertNotificationEventsPending, getAlertNotificationEventsError } from "../../reducer/alertsNotificationReducer";
import { getNotificationEvents, getNotificationEventsPending, getNotificationEventsError } from "../../reducer/notificationReducer";
import { fetchAlertNotificationEvents, fetchNotificationEvents } from "../../services/fetchLearnerDashboard.service";
import { fetchUserRoleAccess } from '../../services/userAccess.service';
import { getUserRoleAcess, getUserRoleAcessError, getUserRoleAcessPending } from "../../reducer/roleAccessReducer";

import {
  getPersonnelData,
  getPersonnelAddressData
} from "../../reducer/userProfileReducer";

const mapStateToProps = state => ({
  personnelData: getPersonnelData(state.userProfileReducers),
  addressData: getPersonnelAddressData(state.userProfileReducers),

  alertNotificationEvents: getAlertNotificationEvents(
    state.alertNotificationEventsReducer
  ),
  pendingAlertNotificationEvents: getAlertNotificationEventsPending(
    state.alertNotificationEventsReducer
  ),
  errorAlertNotificationEvents: getAlertNotificationEventsError(
    state.alertNotificationEventsReducer
  ),

  notificationEvents: getNotificationEvents(state.notificationEventsReducer),
  pendingNotificationEvents: getNotificationEventsPending(
    state.notificationEventsReducer
  ),
  errorNotificationEvents: getNotificationEventsError(
    state.notificationEventsReducer
  ),
  userRoleAccess: getUserRoleAcess(state.roleAccessReducer),
  pendingUserRoleAccess: getUserRoleAcessPending(state.roleAccessReducer),
  errorUserRoleAccess: getUserRoleAcessError(state.roleAccessReducer),

});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchUserProfileData: RegisterUserService.getUserPersonnelDetails,
      fetchUserAddressData: RegisterUserService.getUserAddress,
      fetchAlertNotificationEvents: fetchAlertNotificationEvents,
      fetchNotificationEvents: fetchNotificationEvents,
      fetchUserRoleAccess: fetchUserRoleAccess
    },
    dispatch
  );

const connectFunction = connect(mapStateToProps, mapDispatchToProps);

const finxdashboardlanding = connectFunction(
  class extends Component {
    constructor(props) {
      super(props);
    }

    componentDidMount() {

      //  if (localStorage.getItem('userid-Token') !== null && localStorage.getItem('login') !== null) {
      // 	if(localStorage.getItem('roleId') == 5)
      // 	{
      // 		//window.location.href = '/learnerDashboard';
      // 		window.location.replace('/learnerDashboard');

      // 	}
      // 	else
      // 	{
      // 		//window.location.href = '/admin-dashboard';
      // 		window.location.replace('/admin-dashboard');

      // 	}

      //   }
      let RoleId = localStorage.getItem('roleId')
      const { fetchUserProfileData, fetchUserAddressData,
        fetchAlertNotificationEvents,
        fetchNotificationEvents,
        fetchUserRoleAccess
      } = this.props;
      let partyId = 0
      
      if (localStorage.getItem("UrllearnerPartyId") && localStorage.getItem("UrllearnerPartyId") !== "null") {
        //if (localStorage.getItem("UrllearnerPartyId") !== "null") {
        partyId = localStorage.getItem("UrllearnerPartyId");
        // }

      }
      else {
        partyId = localStorage.getItem("userid-Token");
      }

      fetchUserProfileData("learnerPartyID=" + partyId);
      fetchUserAddressData("partyId=" + partyId, (res) => {
        console.log("learner dashboard landing ", res);
      });
      fetchAlertNotificationEvents();
      fetchNotificationEvents("partyId=" + partyId);



      const roleDetails = {
        roleId: {
          roleId: RoleId
        }
      }
      fetchUserRoleAccess(roleDetails);
    }

    render() {
      // alert()
      var alertdata = JSON.parse(localStorage.getItem("alertd"))
      var notealertdata = JSON.parse(localStorage.getItem("notealert"))


      const { personnelData, addressData,
        alertNotificationEvents,
        pendingAlertNotificationEvents,
        errorAlertNotigicationEvents,
        notificationEvents,
        pendingNotificationEvents,
        errorNotificationEvents,
        fetchUserRoleAccess
      } = this.props;
      console.log(fetchUserRoleAccess)
      if (
        personnelData.status === "success" &&
        addressData.status === "success" &&
        !pendingAlertNotificationEvents
        && !pendingNotificationEvents
      ) {
        return (
          <React.Fragment>
            <DashboardHeader
              //alertNotificationEvents={alertNotificationEvents}
              //notificationEvents={notificationEvents}
              {...this.props}
            />
            <UserProfile
              {...this.props}
              personnelData={personnelData.responseObject}
              addressData={addressData.responseListObject}
              fetchUserRoleAccess={fetchUserRoleAccess}
            />
            <Finxfooter />
          </React.Fragment>
        );
      } else {
        return null;
      }
    }
  }
);

export default finxdashboardlanding;
