import React, { Component } from "react";
import DashboardHeader from "../../components/dashboardheader/dashboardheader";
import Finxfooter from "../finxfooter/finxfooter";

import { connect } from "react-redux";
import {
  getLearners,
  getLearnersPending,
  getLearnersError
} from "../../reducer/learnerReducer";
import { bindActionCreators } from "redux";
import { fetchLearners, fetchGdpiStatus } from "../../services/fetchLearners";

import GdpiLearnerList from "../../components/gdpi/gdpiLearnerList";
import { LoginService } from "../../services/login.service";
import {
  error,
  errorNotification
} from "../../components/notification/notifications";
import AdminHeader from "../../components/dashboardheader/adminheader";
import $ from "jquery";
import { getGdpiStatus } from "../../reducer/gdpiStatusReducer";
import { PopUpPortal } from "../../containers/popups/PopUpPortal";
import { getAlertNotificationEvents, getAlertNotificationEventsPending, getAlertNotificationEventsError } from "../../reducer/alertsNotificationReducer";
import { getNotificationEvents, getNotificationEventsPending, getNotificationEventsError } from "../../reducer/notificationReducer";
import { fetchAlertNotificationEvents, fetchNotificationEvents } from "../../services/fetchLearnerDashboard.service";

const mapStateToProps = state => ({
  learners: getLearners(state.learnerReducer),
  pending: getLearnersPending(state.learnerReducer),
  gdpiStatus: getGdpiStatus(state.gdpiStatusReducer),
  err: getLearnersError(state.learnerReducer),
  alertNotificationEvents: getAlertNotificationEvents(
    state.alertNotificationEventsReducer
  ),
  pendingAlertNotificationEvents: getAlertNotificationEventsPending(
    state.alertNotificationEventsReducer
  ),
  errorAlertNotificationEvents: getAlertNotificationEventsError(
    state.alertNotificationEventsReducer
  ),
  notificationEvents: getNotificationEvents(state.notificationEventsReducer),
  pendingNotificationEvents: getNotificationEventsPending(
    state.notificationEventsReducer
  ),
  errorNotificationEvents: getNotificationEventsError(
    state.notificationEventsReducer
  ),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchLearners: fetchLearners,
      fetchGdpiStatus: fetchGdpiStatus,
      fetchAlertNotificationEvents: fetchAlertNotificationEvents,
      fetchNotificationEvents: fetchNotificationEvents,
    },
    dispatch
  );

const connectFunction = connect(mapStateToProps, mapDispatchToProps);

const gdpiScore = connectFunction(
  class extends Component {
    constructor(props) {
      super(props);
      this.state = {
        loading: true
      };
    }

    componentDidMount() {

      const { fetchLearners, fetchGdpiStatus,
        fetchAlertNotificationEvents,
        fetchNotificationEvents,
      } = this.props;
      let partyID = localStorage.getItem("userid-Token");

      LoginService.TokenUser(res => {

        if (res.status === "success") {
          localStorage.setItem("jwt-token", res.responseObject);
          fetchLearners();
          fetchGdpiStatus();
        }
      });
      fetchAlertNotificationEvents();
      fetchNotificationEvents("partyId=" + partyID);
    }

    render() {
      const { learners, pending, fetchLearners, gdpiStatus,
        alertNotificationEvents,
        pendingAlertNotificationEvents,
        errorAlertNotigicationEvents,
        notificationEvents,
        pendingNotificationEvents,
        errorNotificationEvents,
      } = this.props;

      //console.log("alertNotificationEvents",this.props.alertNotificationEvents)
      //console.log("notificationEvents",this.props.notificationEvents)

      if (pending) {
        return (
          <React.Fragment>
            <PopUpPortal
              HidePopup={this.state.loading}
              IsVisible={this.state.loading}
            />

            <AdminHeader
              //alertNotificationEvents={alertNotificationEvents}
              //notificationEvents={notificationEvents}
              {...this.props}
            />
            <GdpiLearnerList
              learners={learners}
              error={error}
              fetchLearners={fetchLearners}
              gdpiStatus={gdpiStatus}
              {...this.props}
            />
            <Finxfooter />
          </React.Fragment>
        );
      } else {

        return (
          <React.Fragment>
            <AdminHeader
              //alertNotificationEvents={alertNotificationEvents}
              {...this.props}
              //notificationEvents={notificationEvents}
              {...this.props}
            />
            <GdpiLearnerList
              learners={learners}
              error={error}
              fetchLearners={fetchLearners}
              gdpiStatus={gdpiStatus}
              {...this.props}
            />
            <Finxfooter />
          </React.Fragment>
        );
      }
    }
  }
);

export default gdpiScore;
