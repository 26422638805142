import React from 'react';
import whatsappIco from '../../../assets/images/whatsapp-ico.png'
import facebookIco from '../../../assets/images/facebook-ico.png'
import instaIco from '../../../assets/images/insta-ico.png'
import linkedIco from '../../../assets/images/linked-ico.png'
import twitterIco from '../../../assets/images/twit-ico.png'
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Typography from '@mui/material/Typography';
import './Footer.css'

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    textAlign:'center'
  };

function Footer() {
    const [open, setOpenCookie] = React.useState(false);
    const handleOpenCookie = () => setOpenCookie(true);
    const handleCloseCookie = () => setOpenCookie(false);
  return (
    
    <footer className='col-100 floatLft flexDisplay justifyCenter'style={{position:'relative'}} >
        <div className='wrapper'>
            <ul className="ap-footMenu col-100 floatLft flexDisplay justifySpace">
            <li className="inlineBlk"><a href="#faq">FAQs</a></li>
                <li className="inlineBlk"><a href="https://ciel.co.in/ciel-disclaimer.html" target="_blank">Disclaimer</a></li>
                <li className="inlineBlk"><button onClick={handleOpenCookie} style={{marginTop:'15px',fontSize:'16px'}} >Cookie Policy</button></li>
                <li className="inlineBlk"><a href="https://ciel.co.in/ciel-terms-of-use.html" target="_blank">Terms & Conditions</a></li>
                <li className="inlineBlk"><a href="https://ciel.co.in/privacy_policy.html" target="_blank">Privacy Policy</a></li>
                <li className="inlineBlk"><a href="https://mfdkareinshuru.com/en/" target="_blank">AMFI</a></li>
            </ul>
            <div className="ap-footWrp col-100 floatLft flexDisplay justifySpace alignEnd flexWrap">
                <div className="ap-footDesc floatLft textLeft">
                    <h3>Office Address: Centre for Investment Education & Learning</h3>
                    <p>Unit no. 320, Plot No. 105, Champaklal Industrial Estate Next to Sion Telephone Exchange (situated at Sion Koliwada, Road, Sion East, Mumbai, Maharashtra 400022</p>
                    <h3>Email Address: <a href="mailto:expertmfd@ciel.co.in">expertmfd@ciel.co.in</a></h3>
                </div>
                <div className="ap-footSocial floatRgt textRight">
                    <div className="ap-socialWrp">
                    <a href="https://twitter.com/ciellearning" target="_blank"><img src={twitterIco} alt="Twitter Icon" /></a>
                        <a href="https://www.facebook.com/ciellearning" target="_blank"><img src={facebookIco} alt="Facebook Icon" /></a>
                        <a href="https://www.instagram.com/ciellearning/" target="_blank"><img src={instaIco} alt="Instagram Icon" /></a>
                        <a href="https://www.linkedin.com/company/ciellearning/" target="_blank"><img src={linkedIco} alt="linkedIn Icon" /></a>
                    </div>
                    <p><a href="www.expertmfd.com" target="_blank">www.expertmfd.com</a>  I  All Rights Reserved 2022</p>
                </div>
            </div>
        </div>
        {/* Cookie structure added 28-09-2022 */}
        
        <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleCloseCookie}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                timeout: 500,
                }}
            >
                <Fade in={open}>
                <Box sx={style}>
                
                    <Typography id="transition-modal-description" sx={{ mt: 2 }}>
                        © 2022 Centre for Investment Education & Learning. All Rights Reserved
                        <br />
                        We use cookies for web analytics and marketing. By continuing to browse the website you consent to the terms of our Privacy Policy.
                    </Typography>
                </Box>
                </Fade>
            </Modal>
    </footer>
  )
}

export default Footer