import React from "react";
import featmodal from "../../../assets/images/feat-modal.png";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import * as moment from "moment";
import TextField from "@material-ui/core/TextField";
import { RegisterUserService } from "../../../services/registration.service";
import { MobileValidationService } from "../../../services/mobile.validation.service";
import { PopUpPortal } from "../../../containers/popups/PopUpPortal";
import { LoginService } from "../../../services/login.service";
import { ProductService } from "../../../services/product.service";
import { OrderService } from "../../../services/order.service";
import { RegionManagementService } from "../../../services/regionManagement.service";
import { LookUpService } from "../../../services/lookupService"
import { pascalCase } from "change-case";
//import { Multiselect } from 'multiselect-react-dropdown';
//import Select from 'react-select'
//import {Login} from "../../../containers/finxheader/finxheader";
import Finxheader from '../../../containers/finxheader/finxheader';
//import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import TC from "../../../assets/pdf/T-C_ExpertMFD.pdf";
import {
  error,
  success,
  warning,
  warningNotification,
  errorNotification,
  successNotification
} from "../../notification/notifications";
import ReactCustomFlagSelect from "react-custom-flag-select";
import $ from "jquery";
import { InstituteService } from "../../../services/fetchInstitute";
import { importNamespaceSpecifier } from "@babel/types";
import { Warning } from "@material-ui/icons";
import DatePicker from 'react-date-picker';
import RegisterSuccess from "./registerSuccess";
import { withRouter } from 'react-router-dom';

class finxregistration extends React.Component {
  constructor(props) {
    super(props);
    var currentDate = new Date();

    this.state = {
      countryCode: "91",
      mobileNumber: '',
      OTP: '',
      firstName: '',
      lastName: '',
      collegeName: '',
      islearnerTypeStudent:false,
      //instituteName: '',
      educationQualification: '',
      emailId: '',
      passedOutYear: '',
      // listOfYear: [],
      pursuingYear: '',
      dateOfBirth: "",
      // new Date(),
      // moment(currentDate).format("DD/MM/YYYY"),
      today: moment(currentDate).format("YYYY-MM-DD"),
      popupVisibility: false,
      productId: '',
      productdetails: [],
      checkout: [],
      showGenerateOtp: false,
      showResendOtp: false,
      MobileVerified: false,
      isMobileUnique: false,
      showOTPBox: false,
      showTimer: false,
      isOTPGenerated: false,
      loading: false,
      flag: "false",
      isPaymentPending: false,
      isRegistered: false,
      instituteName: [],
      instituteNameisReg: '',
      user_obj: [],
      OTPTokenCount: 0,
      time: {},
      seconds: 46,
      disableopt: false,
      stateList: [],
      cityList: [],
      stateListForCor: [],
      cityListForCor: [],
      countryList: [],
      countryListForCors: [],
      lookingForList: [],
      ca_state: '',
      ca_city: '',
      pinNo: '',
      gender: '',
      lookingFor: '',
      showStateCity: false,
      display: 'none',
      signuptabName: "tab",
      logintabName: "tab active",
      currentProfession: [],
      btnDisabled: false,
      countotp: 0,
      isValidOtp: false,
      whoareu: '',
      resetOtpshow: true,
      Date: "",
      errrmsg: "",
      loading1: "none",
      genOtpdisabled: false,
      otperrormsg: false,
      isDisabled: true,
      listOfMonth: [],
      listOfYear1: [],
      listOfDay: [],
      corcityId:'',
      corCityName:'',
      corstateId: '',
      corStateName:'',
      whoareu1: '',
      isMaintainenceActivated: false,
    };

    this.timer = 0;
    this.startTimer = this.startTimer.bind(this);
    this.countDown = this.countDown.bind(this);
  }

  titleCase(str) {
    return str.replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
}


  getAge(DOB) {

    var today = new Date();
    var birthDate = new Date(DOB);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  }

  secondsToTime = (secs) => {

    let hours = Math.floor(secs / (60 * 60));

    let divisor_for_minutes = secs % (60 * 60);
    let minutes = Math.floor(divisor_for_minutes / 60);

    let divisor_for_seconds = divisor_for_minutes % 60;
    let seconds = Math.ceil(divisor_for_seconds);

    let obj = {
      "h": hours,
      "m": minutes,
      "s": seconds
    };
    return obj;
  }


  componentDidMount() {
    this.setState({ islearnerTypeStudent: false }) 
    if(localStorage.getItem("Login") === "true"){
     // alert("Login")
      document.getElementById('signup').style.display = "block";
      document.getElementById('login').style.display = "none";
      this.setState({ signuptabName: "tab active" })
      this.setState({ logintabName: "tab" })
    } 
    else if (localStorage.getItem("Register") === "true") {
     // alert("Register")
      document.getElementById('signup').style.display = "none";
      document.getElementById('login').style.display = "block";
      this.setState({ signuptabName: "tab" })
      this.setState({ logintabName: "tab active" })
    }


    var year = new Date().getFullYear();
    year = year - 18
    var listOfYear = [];
    for (var i = 0; i < 100; i++) {
      var current = year - i;
      listOfYear.push(current);

    }
    var listOfMonth = [];
    // for (var i = 1; i <= 12; i++) {
    //   // var current = year - i;
    //   listOfMonth.push(i);

    // }

    var listOfMonth = [{ "key": 1, "value": "Jan" },
    { "key": 2, "value": "Feb" },
    { "key": 3, "value": "Mar" },
    { "key": 4, "value": "Apr" },
    { "key": 5, "value": "May" },
    { "key": 6, "value": "Jun" },
    { "key": 7, "value": "Jul" },
    { "key": 8, "value": "Aug" },
    { "key": 9, "value": "Sep" },
    { "key": 10, "value": "Oct" },
    { "key": 11, "value": "Nov" },
    { "key": 12, "value": "Dec" },
    ];

    var listOfDay = [];
    for (var i = 1; i <= 31; i++) {
      //    var current = year - i;
      listOfDay.push(i);

    }
    this.setState({ listOfMonth: listOfMonth });
    this.setState({ listOfYear1: listOfYear });
    this.setState({ listOfDay: listOfDay });
    
    LoginService.TokenUser(res => {
      localStorage.setItem(
        "jwt-token",
        res.responseObject
      );
    });

    localStorage.setItem("TokenName", "jti");
    LoginService.TokenUser(
      res => {
        localStorage.setItem("jti-token", res.responseObject);
        if (res.status === "success") {
          localStorage.setItem("TokenName", "jwt");
        }
      }, err => {
        warning(err, warningNotification);
      })
    RegionManagementService.getState(
      "countryId=1",
      res => {

        this.setState({
          stateList: res.responseListObject,
          // countryListForPer: res.responseListObject
        });
      }
    );
    // "codeTypeId=100"
    //"codeTypeName=LearnerType"
    LookUpService.getLookingFor(
      "codeTypeId=100",
      res => {
        // for (let i = 0; i < res.responseListObject.length - 1; i++) {
        //   this.compare_item(res.responseListObject[i].codeValue, res.responseListObject[i + 1].codeValue)
        // }
        this.setState({
          lookingForList: res.responseListObject,
          // countryListForPer: res.responseListObject
        });
      }
    );

    LookUpService.getLookingFor(
      "codeTypeId=107",
      res => {

        this.setState({
          currentProfession: res.responseListObject,
          // countryListForPer: res.responseListObject
        });
      }
    );
    //year dropdown bind start  here
    // var year = new Date().getFullYear();
    // var listOfYear = [];
    // for (var i = 0; i < 40; i++) {
    //   var current = year - i;
    //   listOfYear.push(current);
    // }

    // this.setState({ listOfYear: listOfYear }); //.sort()

    $(document).ready(function () {
      $("#educationQualification").change(function () {
        if (this.value == 0) {
          localStorage.setItem("flag", "true");
        } else {
          localStorage.setItem("flag", "false");
        }
      });



    });

    let timeLeftVar = this.secondsToTime(this.state.seconds);
    this.setState({ time: timeLeftVar });

    RegionManagementService.getCountry(res => {
      this.setState({
        countryList: res.responseListObject
      });
      RegionManagementService.getState(
        "countryId=1" ,
        //+ this.state.corcountryId,
        res => {
          this.setState({
            stateListForCor: res.responseListObject
          });
          this.setState({ loading: false });
          this.setState({ loading1: "none" });
        }
      );
      RegionManagementService.getCity(
        "stateId=" + this.state.corstateId,
        res => {
          this.setState({
            cityListForCor: res.responseListObject
          });
          this.setState({ loading: false });
          this.setState({ loading1: "none" });
        }
      );
      RegionManagementService.getCity(
        "stateId=" + this.state.corstateId,
        res => {
          this.setState({
            cityListForCor: res.responseListObject
          });
          this.setState({ loading: false });
          this.setState({ loading1: "none" });
        }
      );
      RegionManagementService.getState(
        "countryId=1",
        // + this.state.percountryId,
        res => {
          this.setState({
            stateListForPer: res.responseListObject
          });
          this.setState({ loading: false });
          this.setState({ loading1: "none" });
        }
      );
      RegionManagementService.getCity(
        "stateId=" + this.state.perstateId,
        res => {
          this.setState({
            cityListForPer: res.responseListObject
          });
          this.setState({ loading: false });
          this.setState({ loading1: "none" });
        }
      );
    });
   
  
  }

  startTimer() {


    // this.setState({ OTP: nulldebugger })
    // $(".notification-container-top-center").hide();
    //  if (this.state.disableopt === false) {
    this.handleGenerateOtp()
    this.setState({ time: {}, seconds: 46, disableopt: true })
    //if (!this.state.showGenerateOtp) {
    this.setState({
      //  showGenerateOtp: false,
      otperrormsg: false
      // genOtpdisabled: true
    })
    //}

    // }
    // else {
    //   this.setState({ time: {}, seconds: 31, disableopt: false })
    //   console.log("click multiple times");
    // }



    if (this.timer == 0 && this.state.seconds == 46) {
      this.timer = setInterval(this.countDown, 1000);
    }
    else {

      // if (this.state.seconds !== 0) {
      //   clearInterval(this.timer);
      // } else {
      clearInterval(this.timer);
      this.timer = setInterval(this.countDown, 1000)
      //}
    }
  }
  validatePIN(pin) {
    return /^(\d{4}|\d{6})$/.test(pin);
  }
  countDown() {

    // Remove one second, set state so a re-render happens.
    let seconds = this.state.seconds - 1 <= 0 ? 0 : this.state.seconds - 1;
    this.setState({ time: this.secondsToTime(seconds), seconds: seconds });

    // Check if we're at zero.
    if (seconds == 0) {

      clearInterval(this.timer);
      // this.setState({ disableopt : false })

    }
    if (seconds === 0) {

      this.setState({
        showResendOtp: true,


      });
    }
    else {
      this.setState({
        showResendOtp: false,


      });
    }
  }

  isNumber(e) {
    const re = /^[0-9\b]+$/;
    if (re.test(e)) {
      return true;
    }
  }
  showPopup = () => {
    this.setState({ popupVisibility: true });
  };

  hidePopup = () => {
    this.setState({ popupVisibility: false });
  };

  validateEmail = value => {
    let error;
    if (!value) {
      error = "Required";
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
      error = "Invalid email address";
    }
    return error;
  };

  GenerateOTP = () => {
    // LookUpService.getLookingFor(
    //   "codeTypeId=100",
    //   res => {

    //     this.setState({
    //       lookingForList: res.responseListObject,
    //       // countryListForPer: res.responseListObject
    //     });
    //   }
    // );
    // LookUpService.getLookingFor(
    //   "codeTypeId=107",
    //   res => {

    //     this.setState({
    //       currentProfession: res.responseListObject,
    //       // countryListForPer: res.responseListObject
    //     });
    //   }
    // );




    this.setState({ isMobileUnique: true, isOTPGenerated: true });

    if (this.state.isMobileUnique) {

      MobileValidationService.MobileOTP(
        this.state.mobileNumber,
        this.state.countryCode,
        res => {

          if (res.status === "success") {
            success(
              "You will receive otp in 45 secs.",
              successNotification
            );
          }
          return true;
        }
      );
      this.setState({
        showGenerateOtp: false,
        showResendOtp: false,
        showOTPBox: true,
        showTimer: true,
      });
    }
  }

  handleGenerateOtp = () => {

    RegionManagementService.getState(
      "countryId=1" ,
      //+ this.state.corcountryId,
      res => {
        this.setState({
          stateListForCor: res.responseListObject
        });
        this.setState({ loading: false });
        this.setState({ loading1: "none" });
      }
    );

    let mobile = this.state.mobileNumber;

    if (mobile.toString().length === 10) {
      let productId = this.state.productdetails.productID !== undefined ? this.state.productdetails.productID : 0
      mobile = "mobileNo=" + mobile + "&productID=" + productId
      // + this.state.productdetails.productID;
      MobileValidationService.MobileValidation(
        mobile,
        res => {

          if (res.status === 'success') {
            if (res.reasonCode === 'FC-01') {

              this.GenerateOTP();
            } else if (res.reasonCode === 'FC-02') {
              // warning('Mobile number already registered.Please use to log into DAP platform to proceed. Incase of login troubles, please reach us at support@finxpert.org or 9999999999.', warningNotification);
              warning(res.reasonText, { ...warningNotification, container: "top-center" });
            } else if (res.reasonCode === 'FC-03') {
              // alert()
              warning('Mobile number already registered.Please use to log into ExpertMFD platform to proceed. Incase of login troubles, please reach us at expertmfd@ciel.co.in or 9922953305.', {
                ...warningNotification, dismiss: {
                  duration: 0, onScreen: false,
                  showIcon: true,
                }
              }

              );
              //     this.setState({ Date: "", firstName: "", lastName: '', gender: "", whoareu: "", emailId: "", loading: false, disabledbtn: false, currentProfession: "", lookingFor: "" });

              this.setState({ isPaymentPending: false, isRegistered: true, user_obj: res.responseObject, MobileVerified: false });
              setTimeout(() => {
                this.setState({ disableopt: false, isRegistered: false });
              }, 5000);
              // setTimeout(() => {
              //   this.GenerateOTP();
              // }, 5000);

            } else if (res.reasonCode === 'FC-04') {

              warning('Mobile number already registered.Please use to log into ExpertMFD platform to proceed. Incase of login troubles, please reach us at expertmfd@ciel.co.in or 9922953305.', warningNotification);

              this.setState({ isPaymentPending: true, isRegistered: true, user_obj: res.responseObject });
              //   this.GenerateOTP();
            }

          }

        },
        error => {
          //console.log(error);
        }
      );
    }
  };
  isValidDate = (fromDate, toDate) => {
    
    if (fromDate !== "") {
      let fromDate1 = fromDate.split('-')
      if (fromDate1[1] == 2 && fromDate1[2] > 29) {
        warning("Please select valid DOB", warningNotification)
        return false
      } else if (fromDate1[1] == 4 || fromDate1[1] == 6 || fromDate1[1] == 9 || fromDate1[1] == 11) {
        if (fromDate1[2] > 30) {
          warning("Please select valid Date", warningNotification)
          return false
        } else {
          return true
        }
      }
      else {
        return true
      }
    }

    if (toDate !== "") {
      let toDate1 = fromDate.split('-')
      if (toDate1[1] == 2 && toDate1[2] > 29) {
        warning("Please select valid Date", warningNotification)
        return false

      } else if (toDate1[1] == 4 || toDate1[1] == 6 || toDate1[1] == 9 || toDate1[1] == 11) {
        if (toDate1[2] > 30) {
          warning("Please select valid Date", warningNotification)
          return false
        } else {
          return true
        }
      } else {
        return true
      }
    }
  }
  handleValidateOtp = e => {
    //this.state.countotp = this.state.countotp + 1

    this.setState({
      countotp: this.state.countotp + 1
    })
    let mobile = this.state.mobileNumber;
    let countrycd = this.state.countryCode;
    let validateOtp = e.target.value;
    if (validateOtp !== '' && !this.state.MobileVerified) {
      MobileValidationService.MobileOTPValidation(
        mobile,
        countrycd,
        validateOtp,
        res => {
          //console.log(res.status);
          if (res.status === "success") {
            this.setState({ time: {}, seconds: 0, showTimer: false, })
            // success("Mobile Number Verified successfully", successNotification);
            this.setState({ isValidOtp: true, isDisabled: false })
            this.setState({
              showGenerateOtp: false,
              showResendOtp: false,
              MobileVerified: true,
              showOTPBox: true,
              otperrormsg: false
            });

            if (this.state.isRegistered) {

              let Trd = moment(this.state.user_obj.dob, "DD/MM/YYYY");
              success("You are already registered with ExpertMFD. To start BFSI journey, please login to ExpertMFD.", warningNotification);

              $("#feat-Reg").modal('hide');


            }
            if (this.state.educationQualification === 'under Graduate') {
              localStorage.setItem("flag", "true");
            } else {
              localStorage.setItem("flag", "false");
            }


          } else {

            this.setState({ loading: false });

            if (res.reasonCode === 'DM_02') {
              let otpcounter = parseInt(this.state.OTPTokenCount) + 1;
              this.setState({ OTPTokenCount: otpcounter });
              //  if (this.state.OTPTokenCount >= 4) {
              //warning("Click on Resend OTP", warningNotification);
              // this.setState({ loading: false, isLogined: false, showTimer: false, showResendOtp: false, OTP: '' });
              //warning('You have made 3 unsuccessful attempts to Login to ExpertMFD.', warningNotification);
              // setTimeout(() => {
              //   window.location.href = '/';
              // }, 5000);
              // } else {
              this.setState({ isValidOtp: false, otperrormsg: true, isDisabled: true })
              // alert(res.reasonText)
              //document.getElementById("OTP").innerHTML = ""

              //     warning(res.reasonText, warningNotification);

              //              }
            } else if (res.reasonCode === 'SM_11') {
              //warning("Token is getting refreshed", warningNotification);
              window.location.href = '/';
            } else if (res.reasonCode === 'SM-03') {
              warning("Invalid Login Credentials. Please try again", warningNotification);
              this.setState({ loading: false, isLogined: false, showTimer: false, showResendOtp: false });
            } else if (res.reasonCode === 'SM-07') {
              this.setState({ loading: false, isLogined: true, showTimer: false, showResendOtp: false });
            } else if (res.reasonCode === 'SM-12') {
              this.setState({ loading: false, isLogined: false, showTimer: false, showResendOtp: false });
              //  warning('You have made 3 unsuccessful attempts to Login to ExpertMFD. Please contact expertmfd@ciel.co.in or 9922953305 for login support', warningNotification);
            } else if (res.reasonCode === 'SM-13') {
              this.setState({ loading: false, isLogined: false, showTimer: false, showResendOtp: false });
              //warning('Your Account has been Suspended. Please contact expertmfd@ciel.co.in or 9922953305 for login support', warningNotification);
            } else {
              // this.setState({ loading: false, isLogined: false, showTimer: false, showResendOtp: false });

              // warning(res.reasonText, warningNotification);
            }

          }
          return true;
        },
        error => {
          //console.log(error);
        }
      );
    } else {
      if (!this.state.MobileVerified) {
        //  alert(this.state.countotp)
        if (this.state.countotp == 0) {

          warning("Please Enter OTP", warningNotification);

        }
      }
    }
  };

  handleCountryChange = e => {
    this.setState({ countryCode: e.target.value });
  };
  handleEducationDropdown = e => {
    if (e.target.value == 0) {
      this.setState({ flag: "true" });
    } else {
      this.setState({
        flag: "false"
      });
    }
  };

  handleClose = () => {

    //alert()
    this.setState({
      countryCode: "91",
      mobileNumber: '',
      OTP: '',
      firstName: '',
      collegeName: '',
      lastName: '',
      instituteName: '',
      educationQualification: '',
      emailId: '',
      passedOutYear: '',
      listOfYear: [],
      pursuingYear: '',
      dateOfBirth: '',
      popupVisibility: false,
      productId: '',
      productdetails: [],
      prodDet: [],
      checkout: [],
      showGenerateOtp: false,
      showResendOtp: false,
      MobileVerified: false,
      isMobileUnique: false,
      showOTPBox: false,
      isOTPGenerated: false,
      loading: false,
      flag: "false",
      isPaymentPending: false,
      isRegistered: false,
      instituteName: [],
      instituteNameisReg: '',
      user_obj: [],
      currentProfession: [],
      btnDisabled: false,
      userLoginName: "",
      userPassword: "",
      lookingFor: "",
      listOfDay: []

    });
    window.location.reload()
    // window.location.reload();
    // window.$('#newsLetter').modal('hide')
  }

  switchTab = (evt, inp) => {

    //  var i, tabcontent, tablinks;
    // tabcontent = document.getElementsByClassName("tabcontent");
    if (inp == "signup") {
      this.setState({ display: 'none' })
      this.setState({ signuptabName: "tab active" })
      this.setState({ logintabName: "tab" })
      document.getElementById('login').style.display = "none";
      document.getElementById('signup').style.display = "block";
      evt.currentTarget.className += " active";
    }
    else {
      this.setState({ display: 'block' })
      this.setState({ signuptabName: "tab" })
      this.setState({ logintabName: "tab active" })
      document.getElementById('signup').style.display = "none";
      document.getElementById('login').style.display = "block";
      evt.currentTarget.className += " active";
    }

  }
  // makePayment = () => {
  //   if (this.state.isRegistered && this.state.isPaymentPending) {
  //     this.setState({ loading: true });
  //     let learnobj = this.state.user_obj;
  //     var personaldetails = {
  //       partyId: learnobj.learnerPartyID,
  //       mobileNo: learnobj.mobileNo,
  //       firstName: learnobj.firstName,
  //       lastName: learnobj.lastName,
  //       emailID: learnobj.emailID,
  //       entryMode: learnobj.entryMode === undefined ? 4 : learnobj.entryMode,
  //       dateOfBirth: learnobj.dob
  //     };
  //     var productdetails = {
  //       productId: this.state.productdetails.productID,
  //       productName: this.state.productdetails.productName,
  //       productPrice: this.state.productdetails.productPrice,
  //       partnerID: this.state.productdetails.partnerID
  //     };

  //     this.state.checkout.push({
  //       personaldetails: personaldetails,
  //       productdetails: productdetails
  //     });
  //   }
  // }
  productcall = () => {

  }

  render() {

    return (
      <React.Fragment>
        <Formik
          enableReinitialize={true}
          initialValues={{
            islearnerTypeStudent: false,
            whoareu: '',
            mobileNumber: this.state.mobileNumber ? this.state.mobileNumber : '',
            OTP: this.state.OTP ? this.state.OTP : '',
            firstName: this.state.firstName ? this.state.firstName : '',
            lastName: this.state.lastName ? this.state.lastName : '',
            collegeName: this.state.collegeName ? this.state.collegeName : '',
            instituteName: this.state.instituteName ? this.state.instituteName : '',
            educationQualification: this.state.educationQualification ? this.state.educationQualification : '',
            emailId: this.state.emailId ? this.state.emailId : '',
            passedOutYear: this.state.passedOutYear ? this.state.passedOutYear : '',
            pursuingYear: this.state.pursuingYear ? this.state.pursuingYear : '',
            dateOfBirth: this.state.dateOfBirth ? this.state.dateOfBirth : '',
            countryCode: "91",
            productdetails: this.state.productdetails,
            instituteNameisReg: this.state.instituteNameisReg,
            otherCollegeName: '',
            ca_state: '',
            ca_city: '',
            pinNo: '',
            gender: '',
            lookingFor: this.state.lookingFor ? this.state.lookingFor : '',
            disabledbtn: "false",
            dateOfBirth1: '',
            corcityId: this.state.corcityId ? this.state.corcityId : '',
            corstateId: this.state.corstateId ? this.state.corstateId : '',
          }}

          validationSchema={
            this.state.islearnerTypeStudent === true ?
              Yup.object().shape({
                // pinNo: Yup.string()
                //   .matches(/^(\d{4}|\d{6})$/, "Please enter the valid pin number")
                //   .required("You must specify a pin no.")
                //   .when(value => {
                //     if (value !== undefined) {
                //       if (value.length === 6 && this.isNumber(value)) {
                //         this.setState({
                //           showStateCity: true,

                //         });
                //       }
                //       if (value.length < 6 || value.length > 6) {
                //         this.setState({ showStateCity: false });
                //       }
                //     } else {
                //       this.setState({ showStateCity: false });
                //     }
                //   }),
                mobileNumber: Yup.string()
                  .matches(/^[2-9]\d{9}$/, "Please enter the valid mobile number")
                  .required("You must specify a Mobile Number")
                  .when(value => {
                    if (value !== undefined) {
                      if (value.length === 10 && this.isNumber(value)) {
                        this.setState({
                          showGenerateOtp: true,
                          mobileNumber: value
                        });
                      }
                      if (value.length < 10 || value.length > 10) {
                        this.setState({ showGenerateOtp: false });
                      }
                    } else {
                      this.setState({ showGenerateOtp: false });
                    }
                  }),
                // OTP: Yup.number().positive()
                //   .required('OTP is required'),
                firstName: Yup.string()
                  .matches(/^[a-zA-Z ]{1,50}$/, 'you must enter the text upto 50 letter.Special characters not allowed')
                  .min(2, "Too Short!")
                  .max(50, "Too Long!")
                  .required("You must specify a First Name"),
                lastName: Yup.string()
                  .matches(/^[a-zA-Z ]{1,50}$/, 'you must enter the text upto 50 letter.Special characters not allowed')
                  .min(2, "Too Short!")
                  .max(50, "Too Long!")
                  .required("You must specify a Last Name"),

                emailId: Yup.string()
                  .email("Invalid E-Mail")
                  .required("You must specify a E-Mail Id"),
                // day: Yup.string()
                //   .required("You must specify a Date of Birth"),
                // month: Yup.string()
                //   .required("You must specify a Date of Birth"),
                // year: Yup.string()
                //   .required("You must specify a Date of Birth"),
                // dateOfBirth: Yup.string()
                //   .required("You must specify a Date of Birth"),
                // .default(() => '')
                // .test(
                //   "dateOfBirth",
                //   "Birth Date should be greater then 18 years",
                //   value => {



                //     return moment().diff(moment(value), "years") >= 18;
                //   }
                // ),


                gender:
                  Yup.string().required("You must specify a Gender"),

                corcityId: Yup.string().required("You must specify a City"),
                corstateId: Yup.string().required("You must specify a State"),

                // lookingFor: Yup.string()
                //   .required("You must select looking For"),
                whoareu: Yup.string()
                  .required("You must select who are you"),
                islearnerTypeStudent: Yup.boolean(),
                collegeName: Yup
                  .string()
                  .when("islearnerTypeStudent", {
                    is: false,
                    then: Yup
                      .string()
                      .max(50, "Too Long!")
                      .required("You must specify Graduation College Name")
                  })
              })
              :
              Yup.object().shape({
                // pinNo: Yup.string()
                //   .matches(/^(\d{4}|\d{6})$/, "Please enter the valid pin number")
                //   .required("You must specify a pin no.")
                //   .when(value => {
                //     if (value !== undefined) {
                //       if (value.length === 6 && this.isNumber(value)) {
                //         this.setState({
                //           showStateCity: true,

                //         });
                //       }
                //       if (value.length < 6 || value.length > 6) {
                //         this.setState({ showStateCity: false });
                //       }
                //     } else {
                //       this.setState({ showStateCity: false });
                //     }
                //   }),
                mobileNumber: Yup.string()
                  .matches(/^[2-9]\d{9}$/, "Please enter the valid mobile number")
                  .required("You must specify a Mobile Number")
                  .when(value => {
                    if (value !== undefined) {
                      if (value.length === 10 && this.isNumber(value)) {
                        this.setState({
                          showGenerateOtp: true,
                          mobileNumber: value
                        });
                      }
                      if (value.length < 10 || value.length > 10) {
                        this.setState({ showGenerateOtp: false });
                      }
                    } else {
                      this.setState({ showGenerateOtp: false });
                    }
                  }),
                // OTP: Yup.number().positive()
                //   .required('OTP is required'),
                firstName: Yup.string()
                  .matches(/^[a-zA-Z ]{1,50}$/, 'you must enter the text upto 50 letter.Special characters not allowed')
                  .min(2, "Too Short!")
                  .max(50, "Too Long!")
                  .required("You must specify a First Name"),
                lastName: Yup.string()
                  .matches(/^[a-zA-Z ]{1,50}$/, 'you must enter the text upto 50 letter.Special characters not allowed')
                  .min(2, "Too Short!")
                  .max(50, "Too Long!")
                  .required("You must specify a Last Name"),

                emailId: Yup.string()
                  .email("Invalid E-Mail")
                  .required("You must specify a E-Mail Id"),
                // day: Yup.string()
                //   .required("You must specify a Date of Birth"),
                // month: Yup.string()
                //   .required("You must specify a Date of Birth"),
                // year: Yup.string()
                //   .required("You must specify a Date of Birth"),
                // dateOfBirth: Yup.string()
                //   .required("You must specify a Date of Birth"),
                // .default(() => '')
                // .test(
                //   "dateOfBirth",
                //   "Birth Date should be greater then 18 years",
                //   value => {



                //     return moment().diff(moment(value), "years") >= 18;
                //   }
                // ),


                gender:
                  Yup.string().required("You must specify a Gender"),

                corcityId: Yup.string().required("You must specify a City"),
                corstateId: Yup.string().required("You must specify a State"),

                // lookingFor: Yup.string()
                //   .required("You must select looking For"),
                whoareu: Yup.string()
                  .required("You must select who are you")


              })}

          onReset={(values, { resetForm }) => {
            this.setState({
              countryCode: "91",
              //mobileNumber: '',
              // OTP: '',
              // firstName: '',
              // lastName: '',
              // collegeName: '',
              //instituteName: '',
              // educationQualification: '',
              // emailId: '',
              // passedOutYear: '',
              // listOfYear: [],
              // pursuingYear: '',
              // dateOfBirth: '',
              // popupVisibility: false,
              // productId: '',
              // productdetails: [],
              // checkout: [],
              // showGenerateOtp: false,
              // showResendOtp: false,
              // MobileVerified: false,
              // isMobileUnique: false,
              // showOTPBox: false,
              // isOTPGenerated: false,
              // loading: false,
              // flag: "false",
              // isPaymentPending: false,
              // isRegistered: false,
              // instituteName: [],
              // instituteNameisReg: '',
              // user_obj: [],
              // ca_state: '',
              // ca_city: '',
              // pinNo: '',
              // gender: '',
              // lookingFor: this.state.lookingFor ? this.state.lookingFor : ''
            });
            //resetForm();
          }}

          onSubmit={fields => {

            console.log(fields)
            if ((fields['year'] === undefined || fields['year'] === "") || (fields['month'] === undefined || fields['month'] === "") || (fields['day'] === undefined || fields['day'] === "")) {
              warning("Please select Date Of Birth", warningNotification);

            }
            else {
              let DOB = fields['day'] + "/" + fields['month'] + "/" + fields['year'] + " " + "00:00:00"
              let DOB1 = fields['year'] + "-" + fields['month'] + "-" + fields['day']
              // let isValidDOB = this.isValidDate(DOB1, "")
              // if (isValidDOB) {
              this.setState({ loading1: "block" });
              this.setState({
                disabledbtn: true
              })

              // this.refs.btn.setAttribute("disabled", "disabled");
              // alert(fields.termsandconditions)
              if (fields.termsandconditions === false || fields.termsandconditions === undefined) {
                this.setState({
                  disabledbtn: false,
                  loading1: "none"
                })


                warning("Please select Terms and Conditions", warningNotification);

              }
              else {
                if (this.state.isValidOtp) {
                  LoginService.TokenUser(
                    res => {
                      localStorage.setItem("jwt-token", res.responseObject);
                      if (res.status === "success") {
                        localStorage.setItem("TokenName", "jwt");
                        //let productName = "productName=PRE-ASSESSMENT";
                        //ProductService.ProductByName(productName, res => {
                        ProductService.fetchAllProduct(res => {

                          if (res.status === "success") {
                            if (res.reasonCode !== 'SM-20') {
                              let prod = res.responseListObject;
                              var prodList = [];
                              prod.forEach(element => {
                                if (element.isDefault === 1) {
                                  prodList.push(element);
                                }
                              });

                              this.setState({
                                prodDet: prodList,
                                // countryListForPer: res.responseListObject
                              });
                              this.setState({ productdetails: prodList });

                              const queryString = window.location.search;
                              const urlParams = new URLSearchParams(queryString);
                              let src = urlParams.get('src')
                              //    alert(queryString)

                              let mobileNumber = fields["mobileNumber"];
                              let OTP = fields["OTP"];
                              let firstName = fields["firstName"];
                              let lastName = fields["lastName"];
                              let collegeName = fields["collegeName"];

                              let dateOfBirth = DOB;
                              //fields["dateOfBirth"];
                              let emailId = fields["emailId"].toLowerCase();
                              let gender = fields["gender"];

                              let learnerType = "1001";
                              //fields["lookingFor"];
                              let currentProfession = fields["currentProfession"];

                              let cityId = fields["corcityId"];
                              let stateId = fields["corstateId"];
                              //alert(cityId +" "+ stateId)
                              // let postalCode = fields["pinNo"];
                              let productId = this.state.prodDet[0].productID;
                              let data = {
                                mobileNo: mobileNumber,
                                OTP: OTP,
                                firstName: firstName,
                                lastName: lastName,
                                emailID: emailId,
                                dob: DOB,
                                collegeName: collegeName,
                                //moment(dateOfBirth).format("DD/MM/YYYY HH:mm:ss"), //"15/12/1980",//.toString("dd/MM/yyyy"),
                                productId: productId,
                                gender: gender,
                                lookingFor: learnerType,
                                learnerProfession: currentProfession,
                                sourceName: src ? src : 'web',
                                //applicationStatus: "DRAFTED",
                                applicationStatus: "REGISTERED",
                                isTearmsAccepted: fields.termsandconditions == true ? 1 : 0,
                                // isFinXInternship: fields.isFinXInternship == true ? 1 : 0,

                                isFinXInternship: ((fields.whoareu === 1011 || fields.whoareu === "1011") && fields.isFinXInternship == true) ? 1:
                                ((fields.whoareu === 1011 || fields.whoareu === "1011") && fields.isFinXInternship != true) ? 0 : 2,

                                workflowName: learnerType == 1001 ? "ARN" : "",
                                learnerType: fields.whoareu,
                                cityId: cityId,
                                stateId: stateId,
                                //"ARN"   //learnerType
                                //  postalCode: postalCode,
                                // cityId: cityId,
                                // stateId: stateId,
                              };
                              //console.log(data);
                              var logincon = [];
                              RegisterUserService.RegisterUser(
                                data,
                                res => {
                                  if (res.status === "success") {
                                    debugger
                                   //login code
                                    localStorage.setItem("loginData", JSON.stringify(res.responseObject))
                                    //login code end
                                    var personaldetails = {
                                      partyId: res.responseObject.learnerPartyID,
                                      mobileNo: res.responseObject.mobileNo,
                                      firstName: res.responseObject.firstName,
                                      lastName: res.responseObject.lastName,
                                      emailID: res.responseObject.emailID,
                                      entryMode: res.responseObject.entryMode,
                                      dateOfBirth: res.responseObject.dob,
                                      collegeName: res.responseObject.collegeName
                                    };
                                    //  this.setState({ loading: false });
                                    //debugger
                                    //success("Congratulations! You have completed ExpertMFD registration successfully. Credentials to access will be shared shortly over your registered email id.", successNotification);
                                    // this.props.history.push('/registerSuccess');
                                    //window.location.replace('/registerSuccess');
                                    this.setState({ loading1: "none" });
                                    // window.$('#login-reg-tab').modal('hide')

                                    let prodcount = this.state.prodDet.length;
                                    let count = 0
                                    this.state.prodDet.forEach(prodelement => {
                                      count = count + 1;
                                      var productdetails = {
                                        productId: prodelement.productID,
                                        productName: prodelement.productName,
                                        productPrice: prodelement.productPrice,
                                        partnerID: prodelement.partnerID
                                      };
                                      this.state.checkout.push({
                                        personaldetails: personaldetails,
                                        productdetails: productdetails
                                      });

                                      var CheckoutObj = {
                                        learnerPartyID: personaldetails.partyId,
                                        productID: productdetails.productId,
                                        productName: productdetails.productName,
                                        paymentID: 0,
                                        firstName: personaldetails.firstName,
                                        middleName: personaldetails.lastName,
                                        lastName: personaldetails.lastName,
                                        gender: 1,
                                        emailID: personaldetails.emailID,
                                        mobileNo: personaldetails.mobileNo,
                                        contactNo: "",
                                        dob: personaldetails.dateOfBirth,

                                      };

                                      if (productdetails.productId !== undefined && personaldetails.partyId !== undefined) {
                                        RegisterUserService.AHRegisterUser(CheckoutObj, res => {

                                          // var checkoutActivate = {
                                          //   learnerPartyID: personaldetails.partyId,
                                          //   productID: productdetails.productId,
                                          //   testId: 12,
                                          // }

                                          let checkoutActivateList = [];
                                          checkoutActivateList.push({
                                            learnerPartyID: personaldetails.partyId,
                                            productID: productdetails.productId,
                                            testId: 12,
                                            //productModuleID: productdetails.productId,

                                            userName: personaldetails.firstName,
                                            emailId: personaldetails.emailID,
                                            mobileNumber: personaldetails.mobileNo,
                                          });
                                          if (res.status === 'success') {
                                            RegisterUserService.AHActivateUser(checkoutActivateList, resActivate => {


                                              setTimeout(() => {
                                                window.location.reload();
                                              }, 5000)

                                              if (resActivate.reasonCode === 'OK') {
                                                if (count === prodcount) {
                                                  // window.location.reload();
                                                }
                                              } else {
                                                this.setState({ loading1: "none" });
                                                error(res.reasonText, errorNotification);
                                              }
                                            })
                                            // success("You have scuccessfully subscribed to product " + productdetails.productName, successNotification);
                                            // this.UpdateMyProductDetails();
                                            setTimeout(() => {
                                              window.location.replace('/registerSuccess');
                                            }, 1500);
                                          } else {
                                            this.setState({ loading1: "none" });
                                            error(res.reasonText, errorNotification);
                                          }
                                        }, error => {
                                          this.setState({ loading1: "none" });
                                          error(error, errorNotification);
                                        })
                                      } else {
                                        warning('Product is not define. Please co-ordinate with the support', warningNotification);
                                      }
                                    });
                                  } else {
                                    this.setState({ loading1: "none" });
                                    this.setState({ loading: false, disabledbtn: false, currentProfession: "", lookingFor: "" });
                                    error(res.reasonText, errorNotification);
                                  }
                                },
                                error => {
                                  console.log(error);
                                }

                              );
                            } else {
                              this.setState({ loading1: "none" });
                              warning("Product Details " + res.reasonText, warningNotification);
                            }

                          } else if (res.status === 'fail') {
                            this.setState({ loading1: "none" });
                            error(
                              "Sorry for Inconvience! Error has been raised",
                              errorNotification
                            );
                          }
                        }, err => {
                          console.log(err);
                          error(
                            "Sorry for Inconvience! Error has been raised",
                            errorNotification
                          );
                        });
                      }
                    },
                    err => {
                      this.setState({ loading1: "none" });
                      console.log(err);
                      error(
                        "Sorry for Inconvience! Error has been raised",
                        errorNotification
                      );
                    }
                  );
                }
                else {
                  this.setState({ loading1: "none" });
                  this.setState({ loading: false, disabledbtn: false, currentProfession: "" });

                  error(
                    "Enter Valid OTP",
                    errorNotification
                  );
                }
              }

            }
          }
          }
          //  }

          render={({
            touched,
            errors,
            handleSubmit,
            handleChange,
            handleBlur,
            handleReset,
            isValid,
            values,
            setFieldValue
          }) => (
              <React.Fragment>
                {/* {this.state.loading && <PopUpPortal HidePopup={this.state.loading}
                IsVisible={this.state.loading} />} */}
                {/* {this.state.loading1 && (
                <PopUpPortal
                  HidePopup={this.state.loading1}
                  IsVisible={this.state.loading1}
                />
              )} */}

                <div
                  className="modal fade"
                  id="feat-Reg"
                  tabindex="-1" role="dialog" aria-labelledby="modalLabel" data-keyboard="true" aria-hidden="true"
                >
                  {/* <PopUpPortal
                  HidePopup={true}
                  IsVisible={true}
                /> */}



                  <div className="modal-dialog modal-lg" role="document">
                    <Form id="myForm">
                      <div className="modal-content">

                        <div id="loading-bar-spinner" class="spinner" style={{ display: this.state.loading1 }}>
                          <div class="spinner-icon">
                          </div>
                        </div>

                        <button
                          type="reset"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                          onClick={this.handleClose}
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                        {/* <button type="button" className="close" onClick={this.hidePopup} aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button> */}
                        <div className="col-md-12"></div>
                        <div className="tab-form">
                          <ul class="tab-group" id="login-reg-tab">

                            <li class={this.state.signuptabName}><a href="#signup" onClick={(e) => {
                              this.switchTab(e, 'signup')
                            }} >Register</a></li>
                            <li class={this.state.logintabName}><a href="#login" onClick={(e) => {
                              this.switchTab(e, 'login')
                            }} >Log In</a></li>
                            {/* onClick={this.switchTab('login')} */}

                          </ul>
                          <div class="tab-content">
                            <div id="signup">

                              <section className="registration">
                                <div className="modal-body">
                                  <div className="row">
                                    <div className="col-md-4 col-sm-5 col-xs-12">
                                      <div className="modal-head">
                                        <h3>ExpertMFD Registration</h3>
                                        <p>
                                          First step towards realising your
                                          professional/entrepreneurial dreams in indian
                                          BFSI sector
                                </p>
                                      </div>
                                      <img src={featmodal} className="img-responsive" />
                                    </div>
                                    <div className="col-md-8 col-sm-7 col-xs-12">

                                      <div className="form-group">
                                        <div className="row">
                                          <div className="col-md-6 col-sm-12 col-xs-12">
                                            <div>
                                              <label>
                                                MOBILE NUMBER
                                        <span className="validation"> *</span>
                                              </label>
                                              <div className="row">
                                                <div className="col-md-4 col-sm-4 col-xs-4">
                                                  {/* <select */}
                                                  <div
                                                    name="countryCode"
                                                    id=''
                                                    value={this.state.countryCode}
                                                    onChange={this.handleCountryChange}
                                                    text={this.state.countryCode}
                                                    className="form-control"
                                                  >
                                                    <option
                                                      data-countrycode="IN"
                                                      value="91"
                                                    >
                                                      +91
                                              </option>
                                                  </div>
                                                </div>
                                                <div className="col-md-8 col-lg-8 col-sm-8 col-xs-8">
                                                  <Field
                                                    type="text"
                                                    name="mobileNumber"
                                                    pattern="[0-9]{10}"
                                                    maxlength="10"
                                                    placeholder=''
                                                    id="mobileNumber"
                                                    className={"form-control"}
                                                    onBlur={e => {
                                                      setFieldValue(values.stateListForCor = this.state.stateListForCor)
                                                      console.log("stateListForCor:::", values.stateListForCor)
                                                    }}

                                                  />
                                                </div>
                                              </div>
                                              <ErrorMessage
                                                name="mobileNumber"
                                                component="div"
                                                className="validation"
                                              />
                                            </div>
                                            {/* {this.state.showGenerateOtp &&
                                            !this.state.isOTPGenerated && (
                                              <div>
                                                <input type="button"
                                                  className="otp-resend"
                                                  // onClick={this.handleGenerateOtp}
                                                  onClick={this.startTimer}

                                                  style={this.state.genOtpdisabled === true ? {
                                                    pointerEvents: "none",
                                                    cursor: "default"
                                                  } : {}}
                                                  value="Generate OTP"
                                                />



                                              </div>
                                            )} */}
                                          </div>
                                          {this.state.showGenerateOtp &&
                                            !this.state.isOTPGenerated && (
                                              <div className="col-md-6 col-sm-6 col-xs-12">
                                                <button type="button"
                                                  className="btn-1 mt-40"
                                                  // onClick={this.handleGenerateOtp}
                                                  onClick={this.startTimer}

                                                  style={this.state.genOtpdisabled === true ? {
                                                    pointerEvents: "none",
                                                    cursor: "default"
                                                  } : {}}
                                                  value="Generate OTP"
                                                >Generate OTP </button>



                                              </div>
                                            )}
                                          {this.state.showOTPBox && (
                                            <div className="col-md-6 col-sm-6 col-xs-12">

                                              <label>
                                                ENTER OTP{" "}
                                                <span className="validation"> *</span>
                                              </label>
                                              <Field
                                                type="text"
                                                name="OTP"
                                                id="OTP"
                                                maxlength="4"
                                                onBlur={this.handleValidateOtp}
                                                className={"form-control enter-otp"}
                                                placeholder=''
                                                style={{ width: "100%" }}
                                                autocomplete="off"
                                                readonly
                                              />
                                              {this.state.showTimer ?
                                                <div>
                                                  {this.state.showResendOtp && (
                                                    <React.Fragment>
                                                      <div className="otp-resend">
                                                        <a
                                                          onClick={e => {
                                                            setFieldValue(values.OTP = "")
                                                            this.startTimer()

                                                          }}
                                                        // onClick={this.handleGenerateOtp}
                                                        >
                                                          Resend OTP
                                          </a>

                                                      </div>
                                                    </React.Fragment>
                                                  )}
                                                  <h5 className="timer">{this.state.time.m} : {this.state.time.s} Time Left</h5>
                                                </div> : ''}

                                              {this.state.otperrormsg === true ? <span class="validation">Invalid OTP </span> : ''}

                                              <ErrorMessage
                                                name="OTP"
                                                component="div"
                                                className="validation"
                                              />
                                            </div>
                                          )}
                                          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12"></div>
                                          <div className="col-md-6 col-sm-6 col-xs-12">
                                            <label>
                                              Looking For{" "}
                                              <span className="validation"> *</span>
                                            </label>
                                            {this.state.isRegistered ? (
                                              <div>
                                                <Field
                                                  type="text"
                                                  name="lookingFor"
                                                  id="lookingFor"
                                                  disabled
                                                  className={"form-control"}
                                                  placeholder='' />
                                              </div>
                                            )
                                              : (
                                                <div>
                                                  <Field
                                                    as="select"
                                                    name="lookingFor"
                                                    id="lookingFor"
                                                    //  defaultValue="1001"
                                                    disabled={this.state.isDisabled}
                                                    onChange={e => {
                                                      if (e.target.value == 1001) {
                                                        setFieldValue("lookingFor", e.target.value)
                                                      }
                                                      // else {
                                                      //   //  warning("Registration is allowed only for 'Becoming a New Distributor'", warningNotification)
                                                      //   warning("Please Select Looking For", warningNotification)

                                                      //   setFieldValue("lookingFor", "")

                                                      // }
                                                    }}
                                                    className={
                                                      "form-control" +
                                                      (errors.lookingFor &&
                                                        touched.lookingFor
                                                        ? " is-invalid"
                                                        : '')
                                                    }
                                                  >
                                                    {/* <option>--Select--</option>
                                                  <option value="1003">CPE</option>
                                                  <option value="1004">NISM Certification</option>
                                                  <option value="1002">Applying for EUIN</option> */}
                                                    {/* <option>--Select--</option> */}
                                                    <option value="1001">Becoming a Mutual fund Distributor</option>

                                                    {/* {(this.state.lookingForList || []).map(
                                                    team => (
                                                      <option
                                                        key={team.codeValueId}
                                                        value={team.codeValueId}
                                                      >
                                                        {team.codeValue}
                                                      </option>
                                                    )
                                                  )} */}

                                                  </Field>
                                                  <ErrorMessage
                                                    name="lookingFor"
                                                    component="div"
                                                    className="validation"
                                                  /></div>)}



                                          </div>
                                          <div className="col-md-6 col-sm-6 col-xs-12">
                                            <label>
                                              EMAIL ID{" "}
                                              <span className="validation"> *</span>
                                            </label>
                                            <Field
                                              type="text"
                                              name="emailId"
                                              id="emailId"
                                              disabled={this.state.isRegistered ? true : this.state.isDisabled}
                                              onBlur={e => {
                                                LoginService.TokenUser(
                                                  res => {
                                                    localStorage.setItem("jwt-token", res.responseObject);
                                                  })
                                                if (e.target.value !== "") {
                                                  RegisterUserService.validateUserData("emailID=" + e.target.value, res1 => {
                                                    console.log(res1.reasonText)
                                                    if (res1.reasonText == "Email already exists") {
                                                      setFieldValue(values.emailId = "")
                                                      warning("Email already exists", warningNotification)
                                                      //  setFieldValue(values.emailerror = "Email already exists")

                                                    } else {
                                                      setFieldValue(values.emailId = e.target.value)
                                                      // setFieldValue(values.emailerror = "")

                                                    }
                                                  })
                                                }

                                              }}
                                              validate={this.validateEmail}
                                              className={"form-control"}
                                              placeholder=''
                                            />
                                            {/* <span style={{ color: "red" }}>  {values.emailerror}</span> */}
                                            <ErrorMessage
                                              name="emailId"
                                              component="div"
                                              className="validation"
                                            />
                                          </div>
                                          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12"></div>
                                          <div className="col-md-6 col-sm-6 col-xs-12">
                                            <label>
                                              FIRST NAME{" "}
                                              <span className="validation"> *</span>{" "}
                                            </label>
                                            <Field
                                              type="text"
                                              name="firstName"
                                              disabled={this.state.isRegistered ? true : this.state.isDisabled}
                                              id="firstName"
                                              className={"form-control"}
                                              placeholder=''

                                            />
                                            <ErrorMessage
                                              name="firstName"
                                              component="div"
                                              className="validation"
                                            />
                                          </div>
                                          <div className="col-md-6 col-sm-6 col-xs-12">
                                            <label>
                                              LAST NAME{" "}
                                              <span className="validation"> *</span>{" "}
                                            </label>
                                            <Field
                                              type="text"
                                              disabled={this.state.isRegistered ? true : this.state.isDisabled}
                                              name="lastName"
                                              id="lastName"
                                              className={"form-control"}
                                              placeholder=''
                                            />
                                            <ErrorMessage
                                              name="lastName"
                                              component="div"
                                              className="validation"
                                            />
                                          </div>

                                          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12"></div>

                                          <div className="col-md-6 col-sm-6 col-xs-12">
                                            <label>
                                              DATE OF BIRTH{" "}
                                              <span className="validation"> *</span>
                                            </label>
                                            <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 pl pr">
                                              <div className="col-md-3 col-lg-3 col-sm-3 col-xs-3 pl pr">
                                                <Field
                                                  as="select"
                                                  name="day"
                                                  id="day"
                                                  // onChange={handleChange}
                                                  disabled={this.state.isDisabled}
                                                  //  disabled
                                                  onChange={
                                                    e => {

                                                      var listOfMonth = []
                                                      setFieldValue(values.day = e.target.value)
                                                      if (e.target.value == 31) {
                                                        listOfMonth = [{ "key": 1, "value": "Jan" },

                                                        { "key": 3, "value": "Mar" },

                                                        { "key": 5, "value": "May" },

                                                        { "key": 7, "value": "Jul" },
                                                        { "key": 8, "value": "Aug" },

                                                        { "key": 10, "value": "Oct" },

                                                        { "key": 12, "value": "Dec" },
                                                        ];


                                                      } else if (e.target.value == 30) {
                                                        var listOfMonth = [{ "key": 1, "value": "Jan" },

                                                        { "key": 3, "value": "Mar" },
                                                        { "key": 4, "value": "Apr" },
                                                        { "key": 5, "value": "May" },
                                                        { "key": 6, "value": "Jun" },
                                                        { "key": 7, "value": "Jul" },
                                                        { "key": 8, "value": "Aug" },
                                                        { "key": 9, "value": "Sep" },
                                                        { "key": 10, "value": "Oct" },
                                                        { "key": 11, "value": "Nov" },
                                                        { "key": 12, "value": "Dec" },
                                                        ];


                                                      }
                                                      else if (e.target.value == 28 || e.target.value == 29) {
                                                        var listOfMonth = [{ "key": 1, "value": "Jan" },
                                                        { "key": 2, "value": "Feb" },
                                                        { "key": 3, "value": "Mar" },
                                                        { "key": 4, "value": "Apr" },
                                                        { "key": 5, "value": "May" },
                                                        { "key": 6, "value": "Jun" },
                                                        { "key": 7, "value": "Jul" },
                                                        { "key": 8, "value": "Aug" },
                                                        { "key": 9, "value": "Sep" },
                                                        { "key": 10, "value": "Oct" },
                                                        { "key": 11, "value": "Nov" },
                                                        { "key": 12, "value": "Dec" },
                                                        ];
                                                      } else {
                                                        var listOfMonth = [{ "key": 1, "value": "Jan" },
                                                        { "key": 2, "value": "Feb" },
                                                        { "key": 3, "value": "Mar" },
                                                        { "key": 4, "value": "Apr" },
                                                        { "key": 5, "value": "May" },
                                                        { "key": 6, "value": "Jun" },
                                                        { "key": 7, "value": "Jul" },
                                                        { "key": 8, "value": "Aug" },
                                                        { "key": 9, "value": "Sep" },
                                                        { "key": 10, "value": "Oct" },
                                                        { "key": 11, "value": "Nov" },
                                                        { "key": 12, "value": "Dec" },
                                                        ];

                                                      }

                                                      this.setState({
                                                        listOfMonth: listOfMonth
                                                      })
                                                    }}
                                                  className={
                                                    "form-control" +
                                                    (errors.day && touched.day
                                                      ? "is-invalid"
                                                      : "")
                                                  }
                                                  className="form-control"
                                                >
                                                  <option value="">DD</option>
                                                  {(
                                                    this.state.listOfDay ||
                                                    []
                                                  ).map(d => (
                                                    <option
                                                      value={d}
                                                    >
                                                      {d}
                                                    </option>
                                                  ))}
                                                </Field>
                                              </div>
                                              <div className="col-md-4 col-lg-4 col-sm-4 col-xs-4 pl pr">
                                                <Field
                                                  as="select"
                                                  name="month"
                                                  id="month"
                                                  //   onChange={handleChange}
                                                  disabled={this.state.isDisabled}
                                                  //  disabled
                                                  onChange={
                                                    e => {


                                                      setFieldValue(values.month = e.target.value)
                                                      if (e.target.value == 2) {
                                                        var listOfDay = [];
                                                        for (var i = 1; i <= 29; i++) {
                                                          //    var current = year - i;
                                                          listOfDay.push(i);
                                                          this.setState({ listOfDay: listOfDay });
                                                        }
                                                      } else if (e.target.value == 1 || e.target.value == 3 || e.target.value == 5 || e.target.value == 7 || e.target.value == 8 || e.target.value == 10 || e.target.value == 12) {
                                                        var listOfDay = [];
                                                        for (var i = 1; i <= 31; i++) {
                                                          //    var current = year - i;
                                                          listOfDay.push(i);
                                                          this.setState({ listOfDay: listOfDay });
                                                        }
                                                      }
                                                      else {
                                                        var listOfDay = [];
                                                        for (var i = 1; i <= 30; i++) {
                                                          //    var current = year - i;
                                                          listOfDay.push(i);
                                                          this.setState({ listOfDay: listOfDay });
                                                        }
                                                      }
                                                    }
                                                  }
                                                  className={
                                                    "form-control" +
                                                    (errors.month && touched.month
                                                      ? "is-invalid"
                                                      : "")
                                                  }
                                                  className="form-control"
                                                >
                                                  <option value="">MM</option>
                                                  {(
                                                    this.state.listOfMonth ||
                                                    []
                                                  ).map(m => (
                                                    <option
                                                      value={m.key}
                                                    >
                                                      {m.value}
                                                    </option>
                                                  ))}
                                                </Field>
                                              </div>
                                              <div className="col-md-5 col-lg-5 col-sm-5 col-xs-5 pl pr">
                                                <Field
                                                  as="select"
                                                  name="year"
                                                  id="year"
                                                  onChange={handleChange}
                                                  disabled={this.state.isDisabled}
                                                  //  disabled
                                                  className={
                                                    "form-control" +
                                                    (errors.year && touched.year
                                                      ? "is-invalid"
                                                      : "")
                                                  }
                                                  className="form-control"
                                                >
                                                  <option value="">YYYY</option>
                                                  {(
                                                    this.state.listOfYear1 ||
                                                    []
                                                  ).map(year => (
                                                    <option
                                                      value={year}
                                                    >
                                                      {year}
                                                    </option>
                                                  ))}
                                                </Field>
                                              </div>
                                            </div>
                                            {this.state.isRegistered ?
                                              <React.Fragment>
                                                {/* <Field
                                                type="text"
                                                name="dateOfBirth"
                                                id="dateOfBirth"
                                                disabled
                                                className={"form-control"}
                                                placeholder='' /> */}
                                              </React.Fragment>
                                              :
                                              <React.Fragment>

                                                {/* <Field
                                                max={this.state.today}
                                                disabled={this.state.isRegistered}
                                                type="date"
                                                name="dateOfBirth"
                                                id="dateOfBirth"
                                                className={"form-control"}
                                                placeholder=''
                                              />
                                              <ErrorMessage
                                                name="dateOfBirth"
                                                component="div"
                                                className="validation"
                                              /> */}


                                                {/* <DatePicker selected={this.state.Date}
                                                autoComplete="off"
                                                placeholderText="dd-mm-yyyy"
                                                dateFormat="dd-MM-yyyy"
                                                value={
                                                  this.state.Date
                                                }
                                                // readOnly
                                                //     dateFormat="dd/MM/yyyy"
                                                maxLength="50"
                                                disabled={this.state.isDisabled}
                                                maxDate={new Date()}
                                                onChangeRaw={event => {

                                                  console.log(typeof event.target.value)
                                                  let temp = event.target.value

                                                  if (temp !== undefined) {
                                                    if (temp.includes("/")) {
                                                      //  let temp1 = temp.replace("/", "-")
                                                      // alert(temp1)


                                                      this.setState({
                                                        Date: "",
                                                        errrmsg: "Date should be in dd-mm-yyyy format."
                                                      })

                                                    }
                                                    else {
                                                      this.setState({

                                                        errrmsg: ""
                                                      })
                                                    }
                                                  }
                                                }
                                                }

                                                onKeyDown={
                                                  e => {
                                                    if (e.key == "Tab") {
                                                      // ("#panNumber").focus();
                                                      document.getElementById("gender").focus();

                                                    }

                                                  }
                                                }
                                                // onBlur={e => {
                                                //   let temp1 = e.target.value.split("/")
                                                //   let temp2 = temp1[0] + '-' + temp1[1] + '-' + temp1[2]
                                                //   // let temp1 = moment(e.target.value).format("DD-MM-YYYY")
                                                //   //  alert(temp2)
                                                //   // alert(new Date(e.target.value))
                                                //   this.setState({
                                                //     Date: new Date(temp2)
                                                //   })
                                                // }}
                                                onChange={date => {


                                                  let age = this.getAge(date)
                                                  if (age < 18) {
                                                    this.setState({
                                                      errormsgdob: "Birth Date should be greater then 18 years"
                                                    })
                                                  }
                                                  else {
                                                    this.setState({
                                                      errormsgdob: ""
                                                    })
                                                  }
                                                  this.setState({
                                                    Date: date
                                                  })
                                                  if (moment(date).format("YYYY-MM-DD") !== "Invalid date") {
                                                    setFieldValue(values.dateOfBirth = moment(date).format("YYYY-MM-DD"))
                                                  }
                                                  else {
                                                    setFieldValue(values.dateOfBirth = "")
                                                  }


                                                  //setFieldValue(values.dateOfBirth = date)
                                                }}
                                                className={"form-control"}

                                              /> */}
                                                <div className="validation">{this.state.errrmsg}</div>
                                                <div className="validation">{this.state.errormsgdob}</div>

                                                {/* {errors.dateOfBirth && <div className="validation">{errors.dateOfBirth}</div>} */}
                                                {/* <ErrorMessage
                                                name="dateOfBirth"
                                                component="div"
                                                className="validation"
                                              /> */}

                                                {/* <DatePicker
                                                className={"form-control"}
                                                format="M-d-y"
                                                monthPlaceholder="MM"
                                                id="patientdob"
                                                //   calendarIcon={<img src={calendar} width="22px" />}
                                                dayPlaceholder="DD"
                                                yearPlaceholder="YYYY"
                                                className="visibledate"
                                                // value={new Date()}
                                                showLeadingZeros={false}
                                                // maxDate={this.state.today}
                                                minDate={new Date("01-01-1900")}
                                                maxDate={new Date("01-01-2099")}
                                                onChange={(value) => {
                                                  
                                                  console.log(value)
                                                  // setFieldValue(
                                                  //   (values.dateOfBirth = value)
                                                  // );

                                                  // this.setState({
                                                  //   dateOfBirth: value
                                                  // })

                                                }}
                                                value={moment(values.dateOfBirth, "DD/MM/YYYY").format('M-d-y')}


                                              /> */}


                                              </React.Fragment>
                                            }
                                          </div>







                                          <div class="col-md-6 col-sm-6 col-xs-12">
                                            <label>
                                              Gender{" "}
                                              <span className="validation">
                                                {" "}
                                              *
                                            </span>
                                            </label>
                                            <Field
                                              as="select"
                                              name="gender"
                                              id="gender"
                                              onChange={handleChange}
                                              disabled={this.state.isDisabled}
                                              //  disabled
                                              className={
                                                "form-control" +
                                                (errors.gender && touched.gender
                                                  ? "is-invalid"
                                                  : "")
                                              }
                                              className="form-control"
                                            >
                                              <option value="">--Select--</option>
                                              <option value="1">Male</option>
                                              <option value="2">Female</option>
                                              <option value="3">Other</option>
                                            </Field>
                                            <ErrorMessage
                                              name="gender"
                                              component="div"
                                              className="validation"
                                            />
                                          </div>
                                          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12"></div>

                                          <div className="col-md-6 col-sm-6 col-xs-12">
                                            <label>
                                              State{" "}
                                              <span className="validation">
                                                {" "}
                                              *
                                            </span>
                                            </label>
                                            <Field
                                              as="select"
                                              name="corstateId"
                                              id="corstateId"
                                              disabled={this.state.isDisabled}
                                              onChange={handleChange}
                                              className={
                                                "form-control" +
                                                (errors.corstateId &&
                                                  touched.corstateId
                                                  ? " is-invalid"
                                                  : "")
                                              }
                                              onChange={event => {
                                                if (event.target.value == "") {
                                                  this.setState({
                                                    corstateId: event.target.value
                                                  })
                                                  setFieldValue(
                                                    (values.corcityId = "")
                                                  );
                                                  setFieldValue(
                                                    (values.corstateId = ""
                                                    )
                                                  );
                                                }
                                                else {
                                                  LoginService.TokenUser(res => {
                                                    localStorage.setItem(
                                                      "jwt-token",
                                                      res.responseObject
                                                    );
                                                  });
                                                  if (
                                                    event.target.value !== undefined
                                                  ) {
                                                    setFieldValue(
                                                      (values.corstateId =
                                                        event.target.value)
                                                    );
                                                    // this.setState({
                                                    //   corstateId: event.target.value
                                                    //     })
                                                    RegionManagementService.getCity(
                                                      "stateId=" + event.target.value,
                                                      res => {
                                                        setFieldValue(
                                                          (values.cityListForCor =
                                                            res.responseListObject)
                                                        );

                                                        this.setState({
                                                          cityListForCor: res.responseListObject
                                                        });
                                                      }
                                                    );
                                                  }
                                                }
                                              }}
                                            >
                                              <option value="">--Select--</option>
                                              {(this.state.stateListForCor || []).map(
                                                (team) => (
                                                  <option
                                                    key={team.stateId}
                                                    value={team.stateId}
                                                  >
                                                    {this.titleCase(team.stateName)}
                                                  </option>
                                                )
                                              )}
                                            </Field>
                                            <ErrorMessage
                                              name="corstateId"
                                              component="div"
                                              className="validation"
                                            />
                                          </div>

                                          <div className="col-md-6 col-sm-6 col-xs-12">
                                            <label>
                                              City
                                        <span className="validation"> *</span>

                                            </label>
                                            <Field
                                              as="select"
                                              name="corcityId"
                                              id="corcityId"
                                              disabled={this.state.isDisabled}
                                              onChange={event => {
                                                setFieldValue(values.corcityId = event.target.value)

                                              }}
                                              onChange={handleChange}
                                              className={
                                                "form-control" +
                                                (errors.corcityId &&
                                                  touched.corcityId
                                                  ? " is-invalid"
                                                  : "")
                                              }
                                            >
                                              <option value="">--Select--</option>
                                              {(this.state.cityListForCor || []).map(
                                                (team) => (
                                                  <option
                                                    key={team.cityId}
                                                    value={team.cityId}
                                                  >
                                                    {this.titleCase(team.cityName)}
                                                  </option>
                                                )
                                              )}
                                            </Field>
                                            <ErrorMessage
                                              name="corcityId"
                                              component="div"
                                              className="validation"
                                            />
                                          </div>



                                          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12"></div>
                                          <div className="col-md-6 col-sm-6 col-xs-12">

                                            <label>
                                              Who Are You?
                                          <span className="validation"> *</span>
                                            </label>
                                            <Field
                                              as="select"
                                              name="whoareu"
                                              id="whoareu"
                                              //  disabled={values.disabledForCielops}
                                              disabled={this.state.isDisabled}

                                              className={
                                                "form-control" +
                                                (errors.whoareu && touched.whoareu
                                                  ? " is-invalid"
                                                  : "")
                                              }
                                              onClick={event => {
                                                this.setState({ whoareu1: event.target.value });
                                                if (event.target.value === "1011" || event.target.value === 1011) {
                                                  this.setState({ islearnerTypeStudent: true })
                                                } else {
                                                  this.setState({ islearnerTypeStudent: false })
                                                }
                                              }}
                                            >
                                              <option value="">--Select--</option>

                                              <option value="1011">Student</option>
                                              <option value="1012">Self Employed</option>
                                              <option value="1013">Professional</option>
                                              <option value="1014">Home Maker</option>
                                              <option value="1015">Retired</option>
                                            </Field>
                                            <ErrorMessage
                                              name="whoareu"
                                              component="div"
                                              className="validation"
                                            />

                                            {/* <label>
                                            current profession{" "}
                                           
                                          </label>

                                          <div>
                                            <Field
                                              as="select"
                                              name="currentProfession"
                                              id="currentProfession"
                                              onChange={handleChange}
                                              className={
                                                "form-control" +
                                                (errors.currentProfession &&
                                                  touched.currentProfession
                                                  ? " is-invalid"
                                                  : '')
                                              }
                                            >
                                              <option>--Select--</option>
                                              {/* {(this.state.currentProfession || []).map(
                                                team => (
                                                  <option
                                                    key={team.codeValueId}
                                                    value={team.codeValueId}
                                                  >
                                                    {team.codeValue}
                                                  </option>
                                                )
                                              )} */}
                                            {/* <option value="1071">Financial Distributor</option>
                                          <option value="1072">Professional</option>
                                          <option value="1073">Insurance Agent</option>
                                          <option value="1074">Other</option>

                                            </Field>
                                        <ErrorMessage
                                          name="currentProfession"
                                          component="div"
                                          className="validation"
                                        /></div> */}



                                          </div>

                                          {this.state.whoareu1 === "1011" || this.state.whoareu1 === 1011 ?
                                            <div className="col-md-6 col-sm-6 col-xs-12">
                                              <label>
                                                GRADUATION COLLEGE NAME{" "}
                                                <span className="validation"> *</span>{" "}
                                              </label>
                                              <Field
                                                type="text"
                                                name="collegeName"
                                                id="collegeName"
                                                maxlength="50"
                                                className={"form-control"}
                                                placeholder=''
                                              />
                                              <ErrorMessage
                                                name="collegeName"
                                                component="div"
                                                className="validation"
                                              />
                                            </div>
                                            : ''}

                                          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12"></div>
                                          {this.state.whoareu1 === "1011" || this.state.whoareu1 === 1011 ?
                                            <div className="col-md-12 col-sm-12 col-xs-12">
                                              <Field
                                                className="form-check-input"
                                                type="checkbox"
                                                name="isFinXInternship"

                                                disabled={this.state.isDisabled}
                                                style={{ marginRight: "10px", marginTop: "15px" }}
                                              />
                                              <span
                                                class="form-check-label"
                                                for="gridCheck"
                                                style={{ marginRight: "15px" }}
                                              >
                                                <label>
                                                  I WANT TO BE PART OF THE FINX INTERNSHIP PROGRAM{" "}
                                                </label>
                                              </span>
                                            </div>
                                            : ''}
                                          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12"></div>
                                          <div className="col-md-12 col-sm-12 col-xs-12">

                                            <Field
                                              className="form-check-input"
                                              type="checkbox"
                                              name="termsandconditions"

                                              disabled={this.state.isDisabled}
                                              style={{ marginRight: "10px", marginTop: "15px" }}
                                            />
                                            <span
                                              class="form-check-label"
                                              for="gridCheck"
                                              style={{ marginRight: "15px" }}
                                            >
                                              <a href={TC} target="_blank" >Terms and Conditions</a>
                                              <span className="validation"> *</span>
                                            </span>
                                          </div>

                                          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12"></div>
                                          {/* {values.gender != '' && values.mobileNumber != '' && values.OTP != '' && values.firstName != '' && values.lastName != '' && values.dateOfBirth != '' && values.termsandconditions === true && this.state.isValidOtp === true && this.state.errormsgdob == "" ? */}
                                          {this.state.isDisabled == false ?
                                            <div className="col-md-6 col-sm-6 col-xs-12">

                                              <button

                                                type="submit"
                                                disabled={this.state.disabledbtn}
                                                onClick={handleSubmit}

                                                className="btn-4 mt-30 pull-right"
                                              >
                                                Submit
                                              </button>

                                            </div> : ''}



                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </section>
                            </div>


                            <div id="login" style={{ display: this.state.display }}>
                              <section className="registration">
                                <div className="modal-body">
                                  <div className="row">
                                    <div className="col-md-4 col-sm-5 col-xs-12">
                                      <div className="modal-head">
                                        <h3>ExpertMFD Login</h3>
                                        <p>
                                          First step towards realising your
                                          professional/entrepreneurial dreams in indian
                                          BFSI sector
                                </p>
                                      </div>
                                      <img src={featmodal} className="img-responsive" />
                                    </div>
                                    <div className="col-md-8 col-sm-7 col-xs-12">
                                      <div className="form-group">
                                        <div className="row">

                                          <Finxheader {...this.props} />

                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </section>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Form>
                  </div>
                  {/* </PopUpPortal> */}

                </div>

                {this.state.loading1 && (
                  <div id="loading-bar-spinner" class="spinner">
                    <div class="spinner-icon">
                    </div>
                  </div>
                  // <PopUpPortal
                  //   HidePopup={this.state.loading1}
                  //   IsVisible={this.state.loading1}
                  // />
                )}
              </React.Fragment>
            )
          }
        />
      </React.Fragment>
    );
  }
}

export default withRouter(finxregistration);