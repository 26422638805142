import { fetchProductsPending, fetchProductsSuccess, fetchProductsError } from '../action/userAction';
import RestDataSource from './restdatasource';
// import { Global_var } from '../global/global_var';

function fetchProducts() {

    return dispatch => {
        //console.log('Dispatch')
        return new RestDataSource('')
            .GetData(res => dispatch(fetchProductsSuccess(res)));
    }
}

// function fetchProducts() {

//     return dispatch => {
//         //console.log('Dispatch')
//         var FetchAssessHubURL = Global_var.BASEURL +  Global_var.URL_USER_ASSESSHUB;
//         return new RestDataSource(FetchAssessHubURL).GetData(res => dispatch(fetchProductsSuccess(res)));

//     }
// }


export default fetchProducts;