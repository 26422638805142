import React, { Component } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Paper from '@material-ui/core/Paper';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { RegisterUserService } from '../../services/registration.service';
import learnerIcon from '../../assets/images/learners.png';
import { errorNotification, error, warning, warningNotification, success, successNotification } from '../notification/notifications';
import Finxfooter from '../../containers/finxfooter/finxfooter';
import { PopUpPortal } from '../../containers/popups/PopUpPortal';
import { updateStatus } from "../../services/preAssessment.Service"


const useStyles = makeStyles(theme => ({
    appBar: {
        position: 'relative',
    },
    layout: {
        width: 'auto',
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
            width: 600,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
    paper: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        padding: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
            marginTop: theme.spacing(6),
            marginBottom: theme.spacing(6),
            padding: theme.spacing(3),
        },
    },
    stepper: {
        padding: theme.spacing(3, 0, 5),
    },
    buttons: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    button: {
        marginTop: theme.spacing(3),
        marginLeft: theme.spacing(1),
    },
}));
const steps = ['Payment', 'Thankyou'];


const Learner = (props) => {
    const classes = useStyles();
    const [activeStep] = React.useState(2);
    localStorage.setItem('Thankyouprops', JSON.stringify(props));
    const handleBack = (props) => {
        var data1 = localStorage.getItem("PersonnelData")
        localStorage.setItem('reload', 'true');
        success('You have made the payment for the NISM VA Preparatory Course. Please click on "Initiate Course" under NISM VA Preparatory Course to access the material on the LMS.',successNotification);
               setTimeout(() => {
                    window.location.href = '/learnerDashboard'
                }, 2000);
        // let data = {
        //     "ExcecutionTracker": [
        //         {
        //             "action": "NISM VA PAYMENT COMPLETE",

        //             "workflowId": JSON.parse(data1).responseObject.learnerID,
        //             "workflowName": JSON.parse(data1).responseObject.lookingFor === "1001" ? "ARN" : "ARN",

        //             "comment": ""

        //         }
        //     ]
        // }

        // updateStatus(data, res => {
        //     console.log(res)
        //     if (res.data.status === "success" || res.data.status === "SUCCESS") {

        //         //window.location.reload()
        //     }
        // })
        // if (localStorage.getItem('login') !== null) {
        //     if (JSON.parse(localStorage.getItem('login'))['role'][0].roleId === 5) {


        //         if (JSON.parse(localStorage.getItem('PaymentDet')).productName === "NISM VA Preparatory Course") {

        //             localStorage.setItem('reload', 'true');
        //             success(
        //                 'You have made the payment for the NISM VA Preparatory Course. Please click on "Initiate Course" under NISM VA Preparatory Course to access the material on the LMS.',
        //                 successNotification
        //             );
        //         }
        //         else {
        //             localStorage.setItem('reload', 'true');
        //             success(
        //                 'You have made the payment for the Re-attempt of the NISM VA Certification Examination. Please confirm your NISM Exam Re-attempt Date.',
        //                 successNotification
        //             );
        //         }

        //         localStorage.setItem('reload', 'true');
        //         setTimeout(() => {
        //             window.location.href = '/learnerDashboard'
        //         }, 5000);
        //     }
        // } else {
        //     window.location.href = '/';
        // }
    };

    return (
        <React.Fragment>

            <CssBaseline />
            <React.Fragment>
                {(
                    <div className="container mb-50p">
                        <div className="col-md-7 col-sm-7 col-md-offset-3 col-sm-offset-3 mt-30">
                            <div className="reset">
                                <h3 align="center">
                                    Thank You </h3>
                            </div>
                            <Stepper activeStep={activeStep} className={classes.stepper}>
                                {steps.map(label => (
                                    <Step key={label}>
                                        <StepLabel>{label}</StepLabel>
                                    </Step>
                                ))}
                            </Stepper>
                            <div className="modal-content">
                                <section className="registration resetpass-modal">
                                    <div className="modal-body">
                                        <div className="row">
                                            <div className="col-md-4 col-sm-6 col-xs-12">
                                                <div className="modal-head">
                                                </div>
                                                <img src={learnerIcon} className="img-responsive" />
                                            </div>
                                            <div className="col-md-7 col-md-offset-1 col-sm-6 col-xs-12" >
                                                <div className="reset">
                                                    <h3>Congratulations! You have completed {" "}
                                                        {/* {JSON.parse(localStorage.getItem('PaymentDet')).productName} */}
                                                        {JSON.parse(localStorage.getItem('PaymentObj1')).productName}
                                                        {JSON.parse(localStorage.getItem('login')) === null ? " registration " : " subscription "} successfully .
                                                  </h3>
                                                    
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-10 col-sm-10 col-xs-12 mt-50">
                                                        <button type="submit" onClick={handleBack} className="btn-4 pull-right" name="Submit">Ok</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>



                )}
            </React.Fragment>
            {/* </Paper> */}
            <Finxfooter />
            {/* </main> */}
        </React.Fragment>
    );

}


export default class ThankYouPage extends Component {
    constructor(props) {
        super(props);
        debugger;
        this.state = {
            learner: {
                id: "",
                firstName: "",
                lastName: "",
                email: "",
                mobile: "",
            },
            AHCredentials: '',
            laoding: false,
            flag: false
        }
        //this.dataSource = new RestDataSource("http://192.168.10.103:8080/Dap-usermanagment/learner/");
    }
    componentWillMount() {
        this.setState(
            state =>
                (state.loading = false)
        );

        // this.setState(
        //     state =>
        //         (state.loading = true)
        // );
        // var AHobj = JSON.parse(localStorage.getItem('PaymentDet'));
        // RegisterUserService.AHRegisterUser(AHobj, res => {
        //     if (res.status === 'success') {
        //         var data1 = localStorage.getItem("PersonnelData")
        //         let data = {
        //             "ExcecutionTracker": [
        //                 {
        //                     "action": "NISM VA PAYMENT COMPLETE",
        //                     "workflowId": JSON.parse(data1).responseObject.learnerID,
        //                     "workflowName": JSON.parse(data1).responseObject.lookingFor === "1001" ? "ARN" : "ARN",
        //                     "comment": ""
        //                 }
        //             ]
        //         }

        //         updateStatus(data, res => {
        //             if (res.status === "success") {
        //                 if (AHobj.productID == 203402) {
        //                     let data_temp = {
        //                         "ExcecutionTracker": [
        //                             {
        //                                 "action": "NISM PROFILE CREATE",
        //                                 "workflowId": JSON.parse(data1).responseObject.learnerID,
        //                                 "workflowName": JSON.parse(data1).responseObject.lookingFor === "1001" ? "ARN" : "ARN",
        //                                 "comment": ""
        //                             }
        //                         ]
        //                     }

        //                     updateStatus(data_temp, res => {

        //                     })
        //                 }
        //             }
        //         })


        //         this.setState(
        //             state =>
        //                 (state.loading = false)
        //         );
        //     } else {
        //         this.setState(
        //             state =>
        //                 (state.loading = false)
        //         ); error(res.reasonText, errorNotification);
        //     }
        // }, error => {
        //     console.log(error);
        // })

    }
    render() {
        return (
            <React.Fragment>
                <PopUpPortal
                    HidePopup={this.state.loading}
                    IsVisible={this.state.loading}
                />
                <React.Fragment>
                    < Learner key={this.state.learner.id} name={this.props.name} id={this.props.id} {...this.props}
                    />
                </React.Fragment>
            </React.Fragment>
        )
    }
}