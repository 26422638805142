import React from 'react';
import Finxproduct from '../finxproduct/finxproduct';
import Cfxroadmap from '../cfxroadmap/cfxroadmap';
import Corporatesimage from '../finxcorpartnership/finxcorpartnership';
import Finxfooter from '../finxfooter/finxfooter';
import Aboutus from '../../components/mainpage/aboutus/aboutus';
import Generalenquiry from '../../components/mainpage/generalenquiry/genralenquiry';
import { withRouter } from 'react-router-dom';


function landingPage(props) {

    return (
        <React.Fragment>
            {/* <Finxheader {...props} /> */}
            <Finxproduct {...props} />
            {/* <NewsLetter /> */}
            <Cfxroadmap />
            <Aboutus />
            <Generalenquiry />
            <Corporatesimage />

            <Finxfooter />
        
        </React.Fragment>
    );
}

export default withRouter(landingPage);

