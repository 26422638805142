import React from 'react';
import sectionImage from '../../../assets/images/AboutDap.png';
import ProcessFlow from '../../../assets/images/ProcessFlowDAP.png';
import BenefitsPoints from '../../../assets/images/benefitsPoints.png';
import BenefitsIcon1 from '../../../assets/images/benefitIcon1.png';
import BenefitsIcon2 from '../../../assets/images/benefitIcon2.png';
import BenefitsIcon3 from '../../../assets/images/benefitIcon3.png';
function aboutus() {
    return (
        <React.Fragment>
            <section className="about section-padding" id="about">
                <div className="container">
                    <div className="row">
                        <div className="section-title-black">
                            <h2>ABOUT</h2></div>
                        <div className="col-md-8 col-sm-8 col-xs-12">
                            <div className="about-paragraph">
                                <p>
                                    “ExpertMFD” is a pan India, collaborative initiative to create Financial Entrepreneurs for the mutual fund industry. Indian Mutual Fund industry is amongst the fastest growing sectors of Banking, Financial Services & Insurance (BFSI), with Assets Under Management (AUM) of over INR 30 lakh crores as of 31 Jan 2021 (more than 3.5X increase in 10 years). There is enormous opportunity for potential entrepreneurs to participate in the growth story of the Industry.
                                    </p>
                                <p>
                                To enable this, the top Mutual Fund Companies;
                                    <a href="https://mutualfund.adityabirlacapital.com/" target="_blank"> Aditya Birla Sun Life Mutual Fund</a>,
                                    <a href="https://www.axismf.com/" target="_blank"> Axis Mutual Fund</a>,
                                    <a href="https://www.icicipruamc.com/" target="_blank"> ICICI Prudential Mutual Fund</a>,
                                    <a href="https://www.ltfs.com/companies/lnt-investment-management/lnt-mutual-fund.html" target="_blank"> L&T Mutual Fund</a>,
                                    <a href="https://mf.nipponindiaim.com/" target="_blank"> Nippon India Mutual Fund</a>,
                                    <a href="https://www.sbimf.com/" target="_blank"> SBI Mutual Fund</a>,
                                    <a href="https://www.sundarammutual.com/" target="_blank"> Sundaram Mutual Fund</a> and
                                    <a href="https://www.hdfcfund.com/" target="_blank"> HDFC Mutual Fund </a>
                                    {/* ICICI Prudential Mutual Fund, L&T Mutual Fund, SBI
                                    Mutual Fund, Axis Mutual Fund, Aditya Birla Sun Life Mutual Fund, UTI Mutual Fund, Sundaram Mutual Fund
                                    and Nippon India Mutual Fund */}
                                    have collaborated with <a href="https://www1.nseindia.com/index_nse.htm" target="_blank"> NSE (National Stock Exchange) </a>and
                                    <a href="https://ciel.co.in/" target="_blank"> CIEL (Centre for Investment Education and
                                   Learning) </a> to create a unique 360-degree MF ready platform to provide end to end services of on boarding, training, and scaling up the business of potential new to industry financial entrepreneurs.
                                    </p>
                                <p>
                                <a href="https://www1.nseindia.com/index_nse.htm" target="_blank">NSE (National Stock Exchange of India) </a> is supporting this industry-wide initiative by providing the participants with exclusive access to their NMF II Platform to effectively deal in mutual fund products. NSE is the world’s largest derivatives exchange by trading volume and the fourth largest in the world in the cash equities segment by number of trades.
                                        </p>
                                    <p><a href="https://ciel.co.in/" target="_blank">CIEL </a> is a leading skilling and capacity building organization with more than 300,000 learners across its certifications, executive development programs, investor awareness and capacity building initiatives.</p>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-4 col-xs-12">
                            <div data-aos="flip-right"> <img src={sectionImage} className="img-responsive" /></div>
                        </div>
                    </div>
                </div>
            </section>


            <section className="benefits section-padding" id="benefits">
                <div className="container">
                    <div className="row">
                        <div className="section-title-black">
                            <h2>BENEFITS</h2></div>
                        <div className="col-md-3 col-sm-3 col-xs-12">
                            <div className="benefits-box">
                                <div className="benefits-icon">
                                    <img src={BenefitsIcon1} className="img-responsive" />
                                </div>
                                <h3 className="benefits-title">
                                    HUGE SCOPE IN MF DISTRIBUTION
                            </h3>
                            </div>
                            <div className="benefits-box">
                                <div className="benefits-icon">
                                    <img src={BenefitsIcon2} className="img-responsive" />
                                </div>
                                <h3 className="benefits-title">
                                    FLEXIBLE WORK ENVIRONMENT
                            </h3>
                            </div>
                            <div className="benefits-box">
                                <div className="benefits-icon">
                                    <img src={BenefitsIcon3} className="img-responsive" />
                                </div>
                                <h3 className="benefits-title">
                                    SUPPORT & HANDHOLDING
                            </h3>
                            </div>
                        </div>
                        <div className="col-md-9 col-sm-9 col-xs-12">
                            <div data-aos="flip-right"> <img src={BenefitsPoints} className="img-responsive" /></div>

                        </div>
                    </div>
                </div>
            </section>

            <section className="processFlow section-padding" id="processflow">
                <div className="container">
                    <div className="row">
                        <div className="section-title-black">
                            <h2>ExpertMFD PROCESS & STEPS INVOLVED</h2>
                            {/* <p className="text-center">Ready to Fly??? Follow the Three Simple Steps:</p> */}
                        </div>

                        <div className="col-md-12 col-sm-12 col-xs-12">
                            <div data-aos="flip-right"> <img src={ProcessFlow} className="img-responsive" /></div>
                        </div>
                    </div>
                </div>
            </section>

            {/* <section className="testimonials section-padding" id="testimonials">
                <div className="container">
                    <div className="row">
                        <div className="section-title-black">
                            <h2>TESTIMONIALS</h2>
                        </div>

                        <div className="col-md-12 col-sm-12 col-xs-12">

                        </div>
                    </div>
                </div>
            </section> */}

            <section className="video section-padding" id="videos">
                <div className="container">
                    <div className="row">
                        <div className="section-title-black">
                            <h2>VIDEOS</h2>

                        </div>

                        <div className="col-md-12 col-sm-12 col-xs-12">
                            <div className="row">
                                {/* <div className="col-md-4 col-sm-6 col-xs-12">
                                    <video src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/3/h264.mov" controls></video>
                                </div>
                                <div className="col-md-4 col-sm-6 col-xs-12">
                                    <video src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/3/h264.mov" controls></video>
                                </div>
                                <div className="col-md-4 col-sm-6 col-xs-12">
                                    <video src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/3/h264.mov" controls></video>
                                </div> */}
                                <h4>Coming Soon</h4>
                            </div>

                        </div>
                    </div>
                </div>
            </section>

            <section className="referral section-padding" id="referralprogram">
                <div className="container">
                    <div className="row">
                        <div className="section-title-white">
                            <h2>REFERRAL PROGRAM</h2>
                        </div>

                        <div className="col-md-7 col-sm-7 col-xs-12">
                            <p>Participate and build a community of like-minded aspirational financial entrepreneurs. Help others in
                            the journey towards financial entrepreneurship</p>
                            <ul>
                                <li><i class="fa fa-check-circle" aria-hidden="true"></i>
                                    <span>Share registration link with the potential candidate</span>
                                </li>
                                <li><i class="fa fa-check-circle" aria-hidden="true"></i>
                                    <span>Help them register for the “ExpertMFD”</span>
                                </li>
                                {/* <li><i class="fa fa-check-circle" aria-hidden="true"></i>
                                    <span>Earn referral fees when the candidate clears the NISM examination</span>
                                </li> */}
                            </ul>
                            <div className="referral-button">
                                <button type="button" class="share-btn"> <i class="fa fa-share-alt-square" aria-hidden="true"></i> Link to Share</button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>



        </React.Fragment>
    );
}

export default aboutus;
