import React from 'react';
import { useState } from 'react';
import { Data } from './Data';
import plusIco from '../../../assets/images/plus-ico.png';
import minusIco from '../../../assets/images/minus-ico.png';
import './Accordion.css';
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';

const AccordionSect = () => {


  return (
    <div className='ap-accordionMain col-100 floatLft'>
        <Accordion allowZeroExpanded="true">
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                        What do I need to pay for enrolling in MFD Karein 
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <ul className="ap-faqData col-100 floatLft">
                        <li className='col-100 floatLft'>Start your own business with free training & operational support by AMFI</li>
                        <li className='col-100 floatLft'>Be your own boss</li>
                        <li className='col-100 floatLft'>Flexibility to work anytime, anywhere</li>
                        <li className='col-100 floatLft'>Set up a business which generates lifelong earnings</li>
                        <li className='col-100 floatLft'>Gain recognition for your efforts, along with continuous learning</li>
                        <li className='col-100 floatLft'>Becoming an Agent of Change by creating wealth for your customers</li>
                    </ul>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                        What is the MFD Karein Shuru Initiative?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <ul className="ap-faqData col-100 floatLft">
                        <li className='col-100 floatLft'>Start your own business with free training & operational support by AMFI</li>
                        <li className='col-100 floatLft'>Be your own boss</li>
                        <li className='col-100 floatLft'>Flexibility to work anytime, anywhere</li>
                        <li className='col-100 floatLft'>Set up a business which generates lifelong earnings</li>
                        <li className='col-100 floatLft'>Gain recognition for your efforts, along with continuous learning</li>
                        <li className='col-100 floatLft'>Becoming an Agent of Change by creating wealth for your customers</li>
                    </ul>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                        What are the eligibility criteria to register for the MFD Karein Shuru 
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <ul className="ap-faqData col-100 floatLft">
                        <li className='col-100 floatLft'>Start your own business with free training & operational support by AMFI</li>
                        <li className='col-100 floatLft'>Be your own boss</li>
                        <li className='col-100 floatLft'>Flexibility to work anytime, anywhere</li>
                        <li className='col-100 floatLft'>Set up a business which generates lifelong earnings</li>
                        <li className='col-100 floatLft'>Gain recognition for your efforts, along with continuous learning</li>
                        <li className='col-100 floatLft'>Becoming an Agent of Change by creating wealth for your customers</li>
                    </ul>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                        What are the steps to be followed for becoming a mutual fund distributor through the MFD Karein Shuru initiative?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <ul className="ap-faqData col-100 floatLft">
                        <li className='col-100 floatLft'>Start your own business with free training & operational support by AMFI</li>
                        <li className='col-100 floatLft'>Be your own boss</li>
                        <li className='col-100 floatLft'>Flexibility to work anytime, anywhere</li>
                        <li className='col-100 floatLft'>Set up a business which generates lifelong earnings</li>
                        <li className='col-100 floatLft'>Gain recognition for your efforts, along with continuous learning</li>
                        <li className='col-100 floatLft'>Becoming an Agent of Change by creating wealth for your customers</li>
                    </ul>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                        What support can I expect from CIEL during the entire journey?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <ul className="ap-faqData col-100 floatLft">
                        <li className='col-100 floatLft'>Start your own business with free training & operational support by AMFI</li>
                        <li className='col-100 floatLft'>Be your own boss</li>
                        <li className='col-100 floatLft'>Flexibility to work anytime, anywhere</li>
                        <li className='col-100 floatLft'>Set up a business which generates lifelong earnings</li>
                        <li className='col-100 floatLft'>Gain recognition for your efforts, along with continuous learning</li>
                        <li className='col-100 floatLft'>Becoming an Agent of Change by creating wealth for your customers</li>
                    </ul>
                </AccordionItemPanel>
            </AccordionItem>
        </Accordion>

        
    </div>
  )
}

export default AccordionSect