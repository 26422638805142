import React from "react";

class MyProductNoRecord extends React.Component {
    constructor(props) {
        super(props);
    }


    render() {
        return (
            <React.Fragment>
                <div className="course">
                    {this.props.CalledFrom === 'MyLearning' ?
                        "No Course has been Assigned to you." : ""}
                    {/* Hurray you have completed all courses */}
                </div>
            </React.Fragment>
        )
    }

}

export default MyProductNoRecord;
