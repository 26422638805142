import { Global_var } from '../global/global_var';
import RestDataSource from '../services/restdatasource';

export const CredentialService = {
    AHLearnerCredential,
    assignCource
};

function AHLearnerCredential(partyID, productID, fn, fnError) {
    let url = Global_var.BASEURL + Global_var.URL_AH_CREDENTIAL_USER;
    let params = "learnerPartyID=" + partyID + "&productID=" + productID;
    return new RestDataSource(url, fnError).GetOneByParam(params, (res) => {
        if (res.headers["jtitoken"] != null) {
            localStorage.setItem('jti-token', res.headers.jtitoken);
        }
        fn(res.data);
    });
}


function assignCource(data, fn) {
    var url = Global_var.BASEURL + Global_var.URL_ASSIGN_COURSE;

    return new RestDataSource(url).Store(data, res => fn(res.data));
}