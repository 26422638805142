import React from 'react';
import DashboardHeader from "../dashboardheader/dashboardheader";
import Finxfooter from "../../containers/finxfooter/finxfooter";
import { FetchNotificationService } from "../../services/fetchnotification";
import { fetchNotificationEvents } from '../../services/fetchLearnerDashboard.service';
import { getNotificationEvents, getNotificationEventsError, getNotificationEventsPending } from '../../reducer/notificationReducer';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import $ from "jquery";
import * as Yup from "yup";
import { Formik, Field, Form, ErrorMessage, yupToFormErrors } from "formik";
import { GeneralEnquiryService } from "../../services/generalenquiryService";
import { success, successNotification, error, errorNotification, warning, warningNotification } from "../notification/notifications";
import { fetchEmailBasedonRole } from '../../services/RoleBasedEmail.service';
import { getOtherUserEmailDetails, getOtherUserEmailDetailsError, getOtherUserEmailDetailsPending } from '../../reducer/OtherRoleAccessEMailReducer';
import Select, { createFilter } from 'react-select';
import FileBase64 from "react-file-base64";
import { fetchUserRoleAccess } from '../../services/userAccess.service';
import { getUserRoleAcess } from '../../reducer/roleAccessReducer';


// import AutoSelect from "./Select";

const mapStateToProps = state => ({
  notificationEvents: getNotificationEvents(state.notificationEventsReducer),
  pendingNotificationEvents: getNotificationEventsPending(
    state.notificationEventsReducer
  ),
  errorNotificationEvents: getNotificationEventsError(
    state.notificationEventsReducer
  ),

  otherUserEmailDetails: getOtherUserEmailDetails(state.OtherRoleAccessEMailReducer),
  pendingOtherUserEmail: getOtherUserEmailDetailsPending(state.OtherRoleAccessEMailReducer),
  errorOtherUserEmail: getOtherUserEmailDetailsError(state.OtherRoleAccessEMailReducer),
  userRoleAccess: getUserRoleAcess(state.roleAccessReducer)
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      //fetchNotificationEvents: fetchNotificationEvents,
      fetchEmailBasedonRole: fetchEmailBasedonRole,
      fetchUserRoleAccess: fetchUserRoleAccess,
     
      
    },
    dispatch
  );

const connectFunction = connect(mapStateToProps, mapDispatchToProps);

const MyMessagesCreate = connectFunction(
  class MyMessagesCreate extends React.Component {
    // class MyMessagesCreate extends React.Component {

    constructor(props) {
      super(props);
      this.state = {
        dropdownFlag: false,
        eMailList: [],
        selectedToEmail: '',
        userRoleAccess: '',
        //props.location.state.userRoleAccess.length>0?props.location.state.userRoleAccess:this.props.userRoleAccess,
        //props.location.state.userRoleAccess?'':
        optionSelected: null,
        intervalId: 0,
        isToEmailPresent: props.history.location.state.learnerDetails !== undefined ? 1 : 0,
        learnerDetails: props.history.location.state.learnerDetails
      }
    }
    onScrollStep = () => {
      if (window.pageYOffset === 0) {
        clearInterval(this.state.intervalId);
      }
      window.scroll(0, window.pageYOffset - this.props.scrollStepInPx);
    }

    scrollToTop = () => {
      let intervalId = setInterval(this.onScrollStep, this.props.delayInMs);
      this.setState({ intervalId: intervalId });
    }

    componentDidMount() {
      console.log(this.props)
      this.state = {
        eMailList: []
      }


      const roleDetails1 = {
        roleId: {
            roleId: localStorage.getItem('roleId')
        }
    }
    this.props.fetchUserRoleAccess(roleDetails1);

      // document.addEventListener("scroll", () => {
      //   if (window.scrollY > 170) {

      //     this.setState({ thePosition: true })
      //   } else {
      //     this.setState({ thePosition: false })
      //   }

      //   if (this.state.thePosition) {
      //     this.scrollToTop()
      //   }
      // });
      // window.scrollTo(0, 0);
    }

    dropdowndataset = () => {
      // dropdowndataset
      const data = this.props.otherUserEmailDetails
    }

    TomailSelect = (data) => {
      const selectedValue = data.value
      this.setState({
        selectedToEmail: selectedValue
      })

    }
   
    selectRole = (roleID) => {

      this.setState({ selectedToEmail: " " })
      let roleDetails = {
        roleId: parseInt(roleID.target.value)
      }

      this.setState({
        dropdownFlag: true,
        optionSelected: roleID.target.value,
        eMailList: this.props.fetchEmailBasedonRole(roleDetails)
      });

      //this.dropdowndataset()
    }

    onChangeParameter(selected, setFieldValue) {
      setFieldValue('ToEMailId', selected)
      //  this.props.getDevices(selected.value)
    }

    handleCancel = () => {
      this.props.history.goBack("-1");
      //window.$("#gdpi-score").modal('hide');
      // window.location.reload();
    };


    render(props) {
      var roledata = this.props.userRoleAccess
      //this.state.userRoleAccess;
      console.log(this.state.userRoleAccess)
      console.log(this.props.userRoleAccess)
      var isToEmailPresent = this.state.isToEmailPresent;
      var learnerDetails = this.state.learnerDetails;
//let userRoleAccess=this.props.userRoleAccess
      return (
        <React.Fragment>
          <DashboardHeader
            {...this.props} />

          <div >
            <Formik
              enableReinitialize={true}
              initialValues={{
                picked: '',
                ToEMailId: isToEmailPresent === 0 ? '' : learnerDetails.emailID,
                subject: '',
                emailId: '',
                message: '',
                notifyRefRecordId: isToEmailPresent === 0 ? '' : learnerDetails.learnerPartyID,
                document: [],
                sizeofDoc: 0,
                count: 0
              }}
              validationSchema={Yup.object().shape({
                //static validations
                // picked: Yup.string()
                //   .required('You must select atleast one Role'),
                ToEMailId: Yup.string()
                  .required('You must specify a Email'),
                subject: Yup.string()
                  .required('You must specify a subject'),
                // emailId: Yup.string()
                //   .required('Invalid E-Mail'),
                //.required('You must specify a E-Mail Id'),
                message: Yup.string()
                  .required('You must specify a Message'),
                // picked: Yup.string().when("isToEmailPresent", {
                //   is: 1,
                //   then: required('You must select altest one option'),

                // })
              }
              )}

              // onSubmit={(values, actions) => {
              //   //console.log("Submitted :" + JSON.stringify(values));
              // }}
              onSubmit={(fields, { resetForm }) => {
                //console.log("field", fields.subject)

                var adminPartyid = localStorage.getItem("userid-Token");
                var UserDetails = JSON.parse(localStorage.getItem('login'));
                //console.log("adminPartyid", adminPartyid)
                fields.document.map((e, index) => {
                  index = index + 1
                  e.documentType = "document" + index
                })
                let partyId= localStorage.getItem("userid-Token");
                let data = {
                  fromEmailId: UserDetails.emailId, //"dhanaji8612@pepisandbox.com",  //
                  toEmailIdOrMobileNo: !fields.ToEMailId.value ? fields.ToEMailId : fields.ToEMailId.value, //"sardarohan04@gmail.com", //
                  emailSubjectText: fields.subject,
                  emailSMSBodyText: fields.message,
                  ccEmailId: fields.emailId,
                  bccEmailId: "",
                  notifyTemplateId: "195500",
                  notifyEventId: "195501",
                  notifyRefRecordId: !fields.ToEMailId.partyid ? fields.notifyRefRecordId : fields.ToEMailId.partyid,
                  document: fields.document,
                  isAttachment: fields.document.length > 0 ? 1 : 0,
                  attachmentCount: fields.document.length,
                  senderPartyId:partyId
                  //enquiryType: 2
                }

                console.log("dataemail", data)
                GeneralEnquiryService.SendMail(data, res => {
                  //console.log("res", res)
                  if (res.status === "success") {
                    success(
                      "Message has been delivered to the user successfully.",
                      successNotification
                    );
                    setTimeout(() => {
                      window.location.reload();
                    }, 3000);
                   // window.location.reload();
                    //  this.hand/userbulkuploadleCancel();
                    // $(document).ready(function () {
                    //   $("#close").click();
                    // });
                    // resetForm({});
                  }
                });
              }}

              render={({
                errors, touched, handleSubmit, values, setFieldValue
              }) => (
                <React.Fragment>
                  <section class="mb-50 pt-50" id="send-message">
                    <div class="container">
                      <Form onSubmit={handleSubmit} className="form-group">
                        <section id="">
                          <div >
                            <div class="">
                              <div class="col-md-8 col-lg-8 col-sm-8 col-xs-12 col-md-offset-2 mb-30">
                                <div className="">
                                  {/* Option */}
                                  <div>

                                    {/* <div>Picked: {values.picked}</div> */}

                                    {isToEmailPresent === 0 && roledata.map((item, key) => (

                                      item.description === 'My Message Create' ?
                                        item.objectActionId.applicationObjectActionName === 'My Message Create' ?
                                          item.objectActionId.applicationObjectId.applicationObjectName === 'CIEL ROLE' ?
                                            <React.Fragment>
                                              <Field
                                                className="form-check-input"
                                                type="radio"
                                                name="picked"
                                                value="6"
                                                checked={this.state.optionSelected === "6" ? true : false}
                                                //  onClick={this.selectRole}
                                                onClick={e => {

                                                  setFieldValue(
                                                    (values.ToEMailId = "")
                                                  );
                                                  let roleDetails = {
                                                    roleId: parseInt(e.target.value)
                                                  }

                                                  this.setState({
                                                    dropdownFlag: true,
                                                    optionSelected: e.target.value,
                                                    eMailList: this.props.fetchEmailBasedonRole(roleDetails)
                                                  });
                                                }
                                                }
                                                style={{ marginRight: "10px" }}
                                              />
                                              <label
                                                class="form-check-label"
                                                htmlFor="gridCheck"
                                                style={{ marginRight: "15px" }}
                                              >
                                                CIEL
                                            </label>

                                            </React.Fragment>
                                            : '' : '' : ''))
                                    }
                                    {isToEmailPresent === 0 && roledata.map((item, key) => (
                                      item.description === 'My Message Create' ?
                                        item.objectActionId.applicationObjectActionName === 'My Message Create' ?
                                          item.objectActionId.applicationObjectId.applicationObjectName === 'AMC ROLE' ?
                                            localStorage.getItem('roleId') !== "5" ?
                                              <React.Fragment>

                                                <Field
                                                  className="form-check-input"
                                                  type="radio"
                                                  name="picked"
                                                  checked={this.state.optionSelected === "7" ? true : false}
                                                  value="7"
                                                  //   onClick={this.selectRole}
                                                  onClick={e => {

                                                    setFieldValue(
                                                      (values.ToEMailId = "")
                                                    );
                                                    let roleDetails = {
                                                      roleId: parseInt(e.target.value)
                                                    }

                                                    this.setState({
                                                      dropdownFlag: true,
                                                      optionSelected: e.target.value,
                                                      eMailList: this.props.fetchEmailBasedonRole(roleDetails)
                                                    });
                                                  }
                                                  }
                                                  style={{ marginRight: "10px" }}
                                                />
                                                <label
                                                  class="form-check-label"
                                                  htmlFor="gridCheck"
                                                  style={{ marginRight: "15px" }}
                                                >
                                                  AMC
                                            </label>

                                              </React.Fragment>
                                              : '' : '' : '' : ''))
                                    }
                                    {isToEmailPresent === 0 &&
                                      roledata.map((item, key) => (
                                        item.description === 'My Message Create' ?
                                          item.objectActionId.applicationObjectActionName === 'My Message Create' ?
                                            item.objectActionId.applicationObjectId.applicationObjectName === 'DISTRIBUTOR ROLE' ?
                                              <React.Fragment>
                                                <Field
                                                  className="form-check-input"
                                                  type="radio"
                                                  name="picked"
                                                  value="5"
                                                  checked={this.state.optionSelected === "5" ? true : false}
                                                  //   onClick={this.selectRole}
                                                  onClick={e => {

                                                    setFieldValue(
                                                      (values.ToEMailId = "")
                                                    );
                                                    let roleDetails = {
                                                      roleId: parseInt(e.target.value)
                                                    }

                                                    this.setState({
                                                      dropdownFlag: true,
                                                      optionSelected: e.target.value,
                                                      eMailList: this.props.fetchEmailBasedonRole(roleDetails)
                                                    });
let role= localStorage.getItem('roleId')
if(role!=7){
                                                     this.props.history.push("/my-inbox-create", {
                                          alerts: this.props.alertNotificationEvents,
                                          notification: this.props.notificationEvents,
                                          userRoleAccess: this.props.userRoleAccess,

                                        })
                                      }
                                                  }
                                                  }
                                                  style={{ marginRight: "10px" }}
                                                />
                                                <label
                                                  class="form-check-label"
                                                  htmlFor="gridCheck"
                                                  style={{ marginRight: "15px" }}
                                                >
                                                  Distributor
                                            </label>

                                              </React.Fragment>
                                              : '' : '' : ''))
                                    }
                                    <ErrorMessage
                                      name="picked"
                                      component="div"
                                      className="validation1"
                                    />

                                  </div>



                                  <div class="product-add">
                                    <div class="form-group">
                                      <div class="row">
                                        <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                                          <label>
                                            TO
                                          </label>
                                          {isToEmailPresent === 0 ?
                                            <Select isDisabled={this.state.dropdownFlag ? false : true}
                                              type="ToEMailId"
                                              onChange={this.TomailSelect}
                                              name="ToEMailId"
                                              value={values.ToEMailId}
                                              onChange={(value) => this.onChangeParameter(value, setFieldValue)}
                                              options={this.props.otherUserEmailDetails}

                                            />
                                            :
                                            <Field
                                              disabled="true"
                                              type="emailId"
                                              name="ToEMailId"
                                              id="ToEMailId"
                                              autoComplete="ToEMailId"
                                              className={
                                                "form-control" +
                                                (errors.ToEMailId &&
                                                  touched.ToEMailId
                                                  ? " is-invalid"
                                                  : "")
                                              }
                                              placeholder="TO"
                                            />
                                          }

                                          <ErrorMessage
                                            name="ToEMailId"
                                            component="div"
                                            className="validation1"
                                          />
                                        </div>

                                        <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                                          <label>
                                            CC
                                          </label>
                                          <Field
                                            type="emailId"
                                            name="emailId"
                                            id="emailId"
                                            autoComplete="emailId"
                                            className={
                                              "form-control" +
                                              (errors.emailId &&
                                                touched.emailId
                                                ? " is-invalid"
                                                : "")
                                            }
                                            placeholder="CC"
                                          />
                                          <ErrorMessage
                                            name="emailId"
                                            component="div"
                                            className="validation1"
                                          />
                                        </div>

                                        <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                                          <label>
                                            Subject
                                          <span className="validation1"> *</span>
                                          </label>
                                          <Field
                                            type="text"
                                            name="subject"
                                            id="subject"
                                            autoComplete="subject"
                                            className={
                                              "form-control" +
                                              (errors.subject &&
                                                touched.text
                                                ? " is-invalid"
                                                : "")
                                            }
                                            placeholder="Subject"
                                            className="form-control"
                                          />
                                          <ErrorMessage
                                            name="subject"
                                            component="div"
                                            className="validation1"
                                          />
                                        </div>
                                        <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                                          <label>
                                            Message
                                          <span className="validation1"> *</span>
                                          </label>
                                          <Field as="textarea" rows="8" cols="50" name="message"
                                            id="message"
                                            autoComplete="message"
                                            className={
                                              "form-control" +
                                              (errors.message && touched.message
                                                ? " is-invalid"
                                                : "")
                                            }
                                          />
                                          <ErrorMessage
                                            name="message"
                                            className="validation1"
                                            component="div"
                                          />
                                        </div>
                                        <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                                          <label>
                                            Upload Attachment
                                          {/* <span className="validation1"> *</span> */}
                                          </label>
                                          <FileBase64
                                            multiple={true}
                                            name="attachment"
                                            //   onChange={handleChange}
                                            onDone={event => {
                                              if (event.length <= 5) {
                                                
                                                let size = 0
                                                let name = []
                                                event.map(e => {
                                                  size = size + e.file.size
                                                })
                                                event.map(e => {
                                                  name.push(e.name.split(".")[1])
                                                })
                                                console.log(name)
                                                let ar2 = [
                                                  "jpg",
                                                  "JPG",
                                                  "jpeg",
                                                  "JPEG",
                                                  "png",
                                                  "PNG",
                                                  "pdf"
                                                ]

                                                const checkType = event[0].name.split(
                                                  "."
                                                );
                                                // if (!name.every(r => ar2.includes(r))) {
                                                //   warning("Invalid file format", warningNotification)

                                                //   // setFieldValue(
                                                //   //   (values.documentTypeCheckForPANImage = true)
                                                //   // );
                                                // } else


                                                if (
                                                  size <= 3000000
                                                ) {
                                                  event.map(e => {


                                                    values.document.push({
                                                      documentHash:
                                                        e.base64,
                                                      documentType: "firstDocument",
                                                      documentName: e.name
                                                    });
                                                  })

                                                }
                                              }
                                              else {
                                                warning("Only 5 files are allowed", warningNotification)
                                              }

                                              // else {
                                              //   warning(" File Size is too large(Maximum File Size:3MB).", warningNotification)
                                              // }
                                            }}
                                          />
                                        </div>
                                       
                                      </div>
                                      <div className="col-md-12 col-sm-12 pl">
                                            <span class="file-size-limit">
                                            Only 5 attachments are allowed with file size 1 MB
                                               
                                        </span>
                                          </div>
                                      {/* <div className="attachment">
                                          
                                          Only 5 attachments are allowed with file size 1 MB
                                          </div> */}
                                    </div>
                                    <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 mb-20">
                                      <button
                                        type="submit"
                                        class="btn-4 pull-right"
                                      >Send</button>
                                      <button
                                        type="button"
                                        id="close"
                                        class="btn-4 mr-20 pull-right"
                                        onClick={this.handleCancel}
                                        aria-label="Close"
                                      >
                                        Cancel
                                    </button>
                                    </div>
                                  </div>
                                </div>

                              </div>
                            </div>
                          </div>
                        </section>
                      </Form>
                    </div>
                  </section>
                </React.Fragment>
              )}
            />

          </div>

          <Finxfooter />
        </React.Fragment>)
    }

  }
)

export default MyMessagesCreate;

