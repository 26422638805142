import React from 'react';
import Slider from 'react-slick';
import bannerImg1 from '../../../assets/images/Home-page-img1.png';
import bannerImg2 from '../../../assets/images/Home-page-img2.png';
import bannerImg3 from '../../../assets/images/Home-page-img3.png';
import bannerImg4 from '../../../assets/images/Home-page-img4.png';
import bannerImg5 from '../../../assets/images/Home-page-img5.png';
import absImg1 from '../../../assets/images/Amft-icon.png';
import absImg2 from '../../../assets/images/cle-icon.png';
import './BannerSlider.css';
import { Carousel } from 'react-responsive-carousel';

function BannerSlider() {
    const settings = {
        dots: true,
        arrows: false,
        fade: true,
        speed: 500,
        autoplay: true,
        autoplaySpeed: 2000,
        slidesToShow: 1,
        SlidesToScroll: 1
    };
    return (
        <div className='ap-banner-slider col-100 floatLft relative'>
            <div className="ap-fixedLogoWrp absolute">
                <span><img src={absImg1} alt="Brand logo 1" /></span>
                <span><img src={absImg2} alt="Brand logo 2" /></span>
            </div>

            <Carousel infiniteLoop useKeyboardArrows autoPlay autoPlaySpeed={3000}>
                {/* <Slider {...settings}> */}
                <div className="ap-bannerItem col-100 floatLft">
                    <div className="ap-bannerInner col-100 floatLft flexDisplay justifySpace alignCenter relative">

                        <div className="ap-bannerImg floatLft"><img src={bannerImg1} alt="Banner Image 1" /></div>
                        <div className="ap-bannerQuote floatRgt">
                            <h2 className="ap-common-head col-100 floatLft textLeft primary-dark">Secure your <br /> <span className='primary-green'>Second Innings</span></h2>
                        </div>
                    </div>
                </div>
                <div className="ap-bannerItem col-100 floatLft">
                    <div className="ap-bannerInner col-100 floatLft flexDisplay justifySpace alignCenter relative">
                        <div className="ap-bannerImg floatLft"><img src={bannerImg4} alt="Banner Image 2" /></div>
                        <div className="ap-bannerQuote floatRgt">
                            <h2 className="ap-common-head col-100 floatLft textLeft primary-dark">An additional source of <br /> income is just a <span className='primary-green'>click away</span></h2>
                            <p className="ap-bannerSliderPara col-100 floatLft primary-dark">Grasp on this opportunity of a lifetime <br /> The Mutual Funds Industry has grown 37 times in 20 years. Why not grow with it? </p>
                        </div>
                    </div>
                </div>
                <div className="ap-bannerItem col-100 floatLft">
                    <div className="ap-bannerInner col-100 floatLft flexDisplay justifySpace alignCenter relative">
                        <div className="ap-bannerImg floatLft"><img src={bannerImg3} alt="Banner Image 2" /></div>
                        <div className="ap-bannerQuote floatRgt">
                            <h2 className="ap-common-head col-100 floatLft textLeft primary-dark">Getting back into the <span className='primary-green'>working <br /> life can be difficult</span></h2>
                            <p className="ap-bannerSliderPara col-100 floatLft primary-dark">Make it a smooth journey with us </p>
                            <p className='ap-bannerSliderPara col-100 floatLft primary-dark ap-parasmall'>Become a Mutual Funds Distributor and have a secure source of earning in a credible and regulated industry </p>
                        </div>
                    </div>
                </div>
                <div className="ap-bannerItem col-100 floatLft">
                    <div className="ap-bannerInner col-100 floatLft flexDisplay justifySpace alignCenter relative">
                        <div className="ap-bannerImg floatLft"><img src={bannerImg2} alt="Banner Image 4" /></div>
                        <div className="ap-bannerQuote floatRgt">
                            <h2 className="ap-common-head col-100 floatLft textLeft primary-dark">Want to set up your business in <br /> a <span className='primary-green'>credible & regulated industry?</span></h2>
                            <p className="ap-bannerSliderPara col-100 floatLft primary-dark">Become a Mutual Funds Distributor with us!</p>
                        </div>
                    </div>
                </div>
                <div className="ap-bannerItem col-100 floatLft">
                    <div className="ap-bannerInner col-100 floatLft flexDisplay justifySpace alignCenter relative">
                        <div className="ap-bannerImg floatLft"><img src={bannerImg5} alt="Banner Image 5" /></div>
                        <div className="ap-bannerQuote floatRgt">
                            <h2 className="ap-common-head col-100 floatLft textLeft primary-dark">An end of a journey can <br /> be a <span className='primary-green'>start of a new one</span></h2>
                            <p className="ap-bannerSliderPara col-100 floatLft primary-dark">Kickstart your career after graduation. <br />Become a Mutual Funds Distributor with us!</p>
                        </div>
                    </div>
                </div>
                {/* </Slider> */}
            </Carousel>
        </div>
    )
}

export default BannerSlider