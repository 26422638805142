import React, { Component } from "react";
import ReactTable from "react-table";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { PaymentHistorySearchService } from "../../services/paymentHistorySearchService";
import { LoginService } from "../../services/login.service";
import * as moment from "moment";
import * as Yup from "yup";
import { ExcelService } from "../Excel/excel";
import { PopUpPortal } from "../../containers/popups/PopUpPortal";
import { warning, warningNotification } from "../notification/notifications";

import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
export class myPayments extends Component {
  constructor(props) {
    super(props);
    var todaydate = new Date();
    this.state = {
      today: moment(todaydate).format("YYYY-MM-DD"),
      data: [],
      roleId: 0,
      loading: true,

      listOfYear1: [],
      listOfMonth: [],
      listOfDay: [],
      paymentStatus: ""

    };
    this.showAll = this.showAll.bind(this);

  }
  componentDidMount() {
    var year = new Date().getFullYear();
    year = year
    var listOfYear = [];
    for (var i = 0; i < 100; i++) {
      var current = year - i;
      listOfYear.push(current);

    }
    var listOfMonth = [];
    // for (var i = 1; i <= 12; i++) {
    //   // var current = year - i;
    //   listOfMonth.push(i);

    // }

    var listOfMonth = [{ "key": 1, "value": "Jan" },
    { "key": 2, "value": "Feb" },
    { "key": 3, "value": "Mar" },
    { "key": 4, "value": "Apr" },
    { "key": 5, "value": "May" },
    { "key": 6, "value": "Jun" },
    { "key": 7, "value": "Jul" },
    { "key": 8, "value": "Aug" },
    { "key": 9, "value": "Sep" },
    { "key": 10, "value": "Oct" },
    { "key": 11, "value": "Nov" },
    { "key": 12, "value": "Dec" },
    ];

    var listOfDay = [];
    for (var i = 1; i <= 31; i++) {
      //    var current = year - i;
      listOfDay.push(i);

    }
    this.setState({ listOfMonth: listOfMonth });
    this.setState({ listOfYear1: listOfYear });
    this.setState({ listOfDay: listOfDay });




    this.componentDetails(this.props.learnerPaymentHistoryData);
    this.setState({ loading: false });
  }

  componentDetails(learnerPaymentHistoryData) {
    let modifiedData = [];
    let modifiedData1 = [];
    // let studname = '';

    let isrecordpresent = false;
    for (let index = 0; index < learnerPaymentHistoryData.length; index++) {
      const element = learnerPaymentHistoryData[index];
      isrecordpresent = false;
      for (let index = 0; index < this.props.productDetailsAll.length; index++) {
        const ele = this.props.productDetailsAll[index];
        if (element.orderItemList.length > 0) {
          if (element.orderItemList[0].productID === ele.productID) {
            isrecordpresent = true;
            let datamodified = {
              orderID: element.orderID,
              learnerPartyID: element.learnerPartyID,
              orderPlacedOn: element.orderPlacedOn,
              orderItemList: element.orderItemList,
              totalItemPrice: element.totalItemPrice,
              totalOrderPrice: element.totalOrderPrice,
              paymentStatus: element.paymentStatus,
              transactionID: element.transactionID,
              productName: ele.productName
            }
            modifiedData1.push(datamodified);
          }
        }
      }
      if (!isrecordpresent) {
        let datamodified = {
          orderID: element.orderID,
          learnerPartyID: element.learnerPartyID,
          orderPlacedOn: element.orderPlacedOn,
          orderItemList: element.orderItemList,
          totalItemPrice: element.totalItemPrice,
          totalOrderPrice: element.totalOrderPrice,
          paymentStatus: element.paymentStatus,
          transactionID: element.transactionID,
          productName: ''
        }
        modifiedData1.push(datamodified);
      }
    }
    let isuserrecordpresent = false;

    for (let index = 0; index < modifiedData1.length; index++) {
      const element = modifiedData1[index];
      isuserrecordpresent = false;
      for (let index = 0; index < this.props.adminUser.length; index++) {
        const eleuser = this.props.adminUser[index];
        if (eleuser.partyID === element.learnerPartyID) {
          isuserrecordpresent = true;
          let datamodified = {
            orderID: element.orderID,
            learnerPartyID: element.learnerPartyID,
            orderPlacedOn: element.orderPlacedOn,
            orderItemList: element.orderItemList,
            totalItemPrice: element.totalItemPrice,
            totalOrderPrice: element.totalOrderPrice,
            paymentStatus: element.paymentStatus,
            productName: element.productName,
            transactionID: element.transactionID,
            learnerName: eleuser.name
          }
          modifiedData.push(datamodified);
        }
      }
      if (!isuserrecordpresent) {
        let datamodified = {
          orderID: element.orderID,
          learnerPartyID: element.learnerPartyID,
          orderPlacedOn: element.orderPlacedOn,
          orderItemList: element.orderItemList,
          totalItemPrice: element.totalItemPrice,
          totalOrderPrice: element.totalOrderPrice,
          paymentStatus: element.paymentStatus,
          productName: element.productName,
          transactionID: element.transactionID,
          learnerName: ''
        }
        modifiedData.push(datamodified);
      }
    }

    this.setState({ data: modifiedData });
  }

  exportToCSV = () => {
    this.setState({ loading: true });
    let modifiedData = [];
    let data = this.state.data;
    if (this.state.data.length > 0) {
      data.forEach(element => {
        var data1 = {
          Order_ID: element.orderID,
          Date: element.orderPlacedOn ? element.orderPlacedOn.split(" ")[0] : " ",


          Price: element.totalOrderPrice,
          Transaction_ID: element.transactionID,
          Payment_Status: element.paymentStatus == "Txn Success"
            ? "Success"
            : element.paymentStatus == "TXN_FAILURE"
              ? "FAIL"
              : element.paymentStatus == "Initiated" ||
                element.paymentStatus == "initiated"
                ? "Initiated"
                : element.paymentStatus,


        }
        modifiedData.push(data1);
      });

      ExcelService.ExcelData(modifiedData, 'Payment_Report-');
      this.setState({ loading: false });
    } else {
      this.setState({ loading: false });
      warning("No record found.", warningNotification)
    }
  };

  showAll() {

    this.setState({ loading: true });
    let data = {
      startDate: "",
      endDate: "",
      paymentStatus: ""
    };
    this.generateData(data);
    this.setState({ loading: false, paymentStatus: "" });
  }

  filterCaseInsensitive = ({ id, value }, row) => {
    return row[id] !== undefined ? String(row[id].toLowerCase()).includes(value.toLowerCase()) : true
  }

  generateData(data) {

    this.setState({ loading: true });
    LoginService.TokenUser(res => {
      localStorage.setItem("jwt-token", res.responseObject);
      let partyID = "";
      let RoleId = JSON.parse(localStorage.getItem("login"))["role"][0][
        "roleId"
      ];
      this.setState({ roleId: RoleId });
      if (RoleId === 5) {
        partyID = localStorage.getItem("userid-Token");
      } else {
        partyID = 0;
      }
      let params =
        "learnerPartyID=" +
        partyID +
        "&limit=-1&fromDate=" +
        data.startDate +
        "&toDate=" +
        data.endDate +
        "&searchText=" +
        this.state.paymentStatus + "&orderDir=asc";
      PaymentHistorySearchService.fetchPaymentSearchResult(
        params,
        res => {
          if (res.status === "success") {

            this.setState({ loading: true });
            // this.setState({ data: res.responseListObject });
            this.componentDetails(res.responseListObject);
            //resetForm({});
            this.setState({ loading: false });
          } else {
            this.setState({
              data: []
            });
          }
        }
      );
    });
  }

  render() {


    return (
      <Formik
        enableReinitialize
        initialValues={{
          paymentStatus: "",
          startDate: "",
          endDate: "",
          maxDate: new Date(),

          listOfMonth: this.state.listOfMonth,
          listOfDay: this.state.listOfDay,
          listOfMonth1: this.state.listOfMonth,
          listOfDay1: this.state.listOfDay,

          day: '',
          month: '',
          year: '',
          today: '',
          tomonth: '',
          toyear: ''

        }}
        validationSchema={Yup.object().shape({
          // endDate:new Date()
          //   //startDate: Yup.string().required("You must specify the start date"),
          // endDate: Yup.lazy(key => {
          //   return (
          //     Yup.string()
          //       .default(() => "")
          //       //.required("You must specify the end date")
          //       .when("startDate", (startDate, schema) => {
          //         return schema.test({
          //           test: EndIntensity =>
          //             !!startDate && EndIntensity >= startDate,
          //           message:
          //             "End date should be greater than or equal tostart date"
          //         });
          //       })
          //   );
          // })
        })}
        onSubmit={(values) => {

          //     this.setState({ loading: true });
          
          let fromDate1 = ""
          if ((values['year'] != undefined && values['year'] != "") || (values['month'] != undefined && values['month'] != "") || (values['day'] != undefined && values['day'] != "")) {
            fromDate1 = values['year'] + "-" + values['month'] + "-" + values['day']

          } else {
            fromDate1 = ""

          }

          let toDate1 = ""
          if ((values['toyear'] != undefined && values['toyear'] != "") || (values['tomonth'] != undefined && values['tomonth'] != "") || (values['today'] != undefined && values['today'] != "")) {
            toDate1 = values['toyear'] + "-" + values['tomonth'] + "-" + values['today']

          } else {
            toDate1 = ""

          }
          // if (fromDate1 === "" && toDate1 === "") {
          //   warning("Please select valid Date", warningNotification);
          // }

          //         else {



          if (fromDate1 !== "" && toDate1 !== "") {
            if (new Date(fromDate1) > new Date(toDate1)) {
              warning("To Date should be greater than From Date", warningNotification)
              fromDate1 = ""
              toDate1 = ""
            }
          }
          //   }
          var fromDate = ""
          var toDate = ""
          if (fromDate1 != "") {
            fromDate = moment(fromDate1).format("DD/MM/YYYY")
          }
          if (toDate1 != "") {
            toDate = moment(toDate1).format("DD/MM/YYYY")
          }
          let data = {
            startDate: fromDate,
            //  values.startDate
            //   ? moment(values.startDate).format("DD/MM/YYYY")
            //   : values.endDate
            //     ? moment(new Date()).format("DD/MM/YYYY")
            //     : "",
            endDate: toDate,
            // values.endDate
            //   ? moment(values.endDate).format("DD/MM/YYYY")
            //   : values.startDate
            //     ? moment(new Date()).format("DD/MM/YYYY")
            //     : "",
            paymentStatus: values.paymentStatus
          };
          this.generateData(data);
          //  this.setState({ loading: false });
        }
        }


        render={({ handleSubmit, handleChange, values, setFieldValue }) => (
          <React.Fragment>
            {this.state.loading && (
              <PopUpPortal
                HidePopup={this.state.loading}
                IsVisible={this.state.loading}
              />
            )}
            <section class="dashboard-tab user-tab pt-80 mb-50">
              <div class="padding-rl-50">
                <div class="container-fluid">
                  <div class="row">
                    <div class="col-md-12 col-sm-12 col-xs-12">
                      <div class="table-header">
                        <h3>
                          <div class="row">
                            <div class="col-md-8 col-sm-6 col-xs-8">
                              <span class="left-icon">
                                <i class="fa fa-list" aria-hidden="true"></i>
                              </span>{" "}
                              My Payments
                            </div>
                            <div class="col-md-4 col-sm-6 col-xs-4">
                              <span class="right-icon">
                                <a>
                                  <i onClick={() => this.exportToCSV()}

                                    class="fa fa-file-excel-o"
                                    aria-hidden="true"
                                  ></i>
                                </a>
                              </span>
                            </div>
                          </div>
                        </h3>
                      </div>
                      <div class="bg-box">
                        <Form onSubmit={handleSubmit} className="">
                          <div class="row">
                            <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                              <div className="filter">
                                <div className="form-group">
                                  <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 pl pr">
                                    <h5 className="filter-heading">
                                      Search Criteria
                                      </h5>
                                  </div>
                                  <div className="row">
                                    <div className="col-md-4 col-sm-4 col-xs-12">
                                      <label>Payment Status</label>
                                      <Field
                                        as="select"
                                        name="paymentStatus"
                                        class="form-control"
                                        value={this.state.paymentStatus}
                                        onChange={e => {
                                          setFieldValue(values.paymentStatus = e.target.value)
                                          this.setState({
                                            paymentStatus: e.target.value
                                          })
                                        }}
                                      >
                                        <option value="selected">
                                          Select Status
                                          </option>
                                        <option value="Txn Success">
                                          Success
                                          </option>
                                        <option value="TXN_FAILURE">
                                          Fail
                                          </option>
                                        <option value="Initiated">
                                          Initiate
                                          </option>
                                        <option value="User has not completed transaction">
                                          User has not completed transaction.
                                          </option>
                                        <option value="Session Expired Failure">
                                          Session Expired Failure
                                          </option>
                                      </Field>
                                    </div>
                                    <div className="col-md-4 col-sm-4 col-xs-12" >
                                      <label>Start Date</label>
                                      {/* <Field
                                        type="date"
                                        name="startDate"
                                        max={this.state.today}
                                        id="startDate"
                                        className="form-control"
                                        onChange={handleChange}
                                      /> */}

                                      {/* <DatePicker selected={values.startDate}
                                        autoComplete="off"
                                        placeholderText="dd-mm-yyyy"
                                        dateFormat="dd-MM-yyyy"
                                        maxLength="50"
                                        maxDate={new Date()}

                                        value={values.startDate}
                                        onChangeRaw={event => {

                                          let temp = event.target.value

                                          if (temp !== undefined) {
                                            if (temp.includes("/")) {

                                              setFieldValue(values.startDate = "")
                                              setFieldValue(values.errmsg1 = "Date should be in dd-mm-yyyy format.")

                                            }
                                            else {
                                              // setFieldValue(values.toDate = date)
                                              setFieldValue(values.errmsg1 = "")

                                            }
                                          }
                                        }
                                        }
                                        onChange={date => {

                                          setFieldValue(values.startDate = date)


                                          setFieldValue(values.startDate1 = moment(date).format("YYYY-MM-DD"))

                                          // setFieldValue(values.dateOfBirth = date)
                                        }}

                                        className={
                                          "form-control"

                                        }
                                      /> */}


                                      <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 pl pr">
                                        <div className="col-md-3 col-lg-3 col-sm-3 col-xs-3 pl pr">
                                          <Field
                                            as="select"
                                            name="day"
                                            id="day"
                                            // onChange={handleChange}
                                            //  disabled={this.state.isDisabled}
                                            //  disabled
                                            onChange={
                                              e => {

                                                
                                                var listOfMonth = []
                                                setFieldValue(values.day = e.target.value)
                                                if (e.target.value == 31) {
                                                  listOfMonth = [{ "key": 1, "value": "Jan" },

                                                  { "key": 3, "value": "Mar" },

                                                  { "key": 5, "value": "May" },

                                                  { "key": 7, "value": "Jul" },
                                                  { "key": 8, "value": "Aug" },

                                                  { "key": 10, "value": "Oct" },

                                                  { "key": 12, "value": "Dec" },
                                                  ];


                                                } else if (e.target.value == 30) {
                                                  listOfMonth = [

                                                    { "key": 1, "value": "Jan" },

                                                    { "key": 3, "value": "Mar" },
                                                    { "key": 4, "value": "Apr" },
                                                    { "key": 5, "value": "May" },
                                                    { "key": 6, "value": "Jun" },
                                                    { "key": 7, "value": "Jul" },
                                                    { "key": 8, "value": "Aug" },
                                                    { "key": 9, "value": "Sep" },
                                                    { "key": 10, "value": "Oct" },
                                                    { "key": 11, "value": "Nov" },
                                                    { "key": 12, "value": "Dec" },

                                                  ];


                                                }
                                                else if (e.target.value == 28 || e.target.value == 29) {
                                                  listOfMonth = [{ "key": 1, "value": "Jan" },
                                                  { "key": 2, "value": "Feb" },
                                                  { "key": 3, "value": "Mar" },
                                                  { "key": 4, "value": "Apr" },
                                                  { "key": 5, "value": "May" },
                                                  { "key": 6, "value": "Jun" },
                                                  { "key": 7, "value": "Jul" },
                                                  { "key": 8, "value": "Aug" },
                                                  { "key": 9, "value": "Sep" },
                                                  { "key": 10, "value": "Oct" },
                                                  { "key": 11, "value": "Nov" },
                                                  { "key": 12, "value": "Dec" },
                                                  ];
                                                } else {
                                                  listOfMonth = [{ "key": 1, "value": "Jan" },
                                                  { "key": 2, "value": "Feb" },
                                                  { "key": 3, "value": "Mar" },
                                                  { "key": 4, "value": "Apr" },
                                                  { "key": 5, "value": "May" },
                                                  { "key": 6, "value": "Jun" },
                                                  { "key": 7, "value": "Jul" },
                                                  { "key": 8, "value": "Aug" },
                                                  { "key": 9, "value": "Sep" },
                                                  { "key": 10, "value": "Oct" },
                                                  { "key": 11, "value": "Nov" },
                                                  { "key": 12, "value": "Dec" },
                                                  ];

                                                }
                                                setFieldValue(values.listOfMonth = listOfMonth)
                                                // this.setState({
                                                //   listOfMonth: listOfMonth
                                                // })
                                              }}


                                            className="form-control"
                                          >
                                            <option value="">DD</option>
                                            {(
                                              values.listOfDay ||
                                              []
                                            ).map(d => (
                                              <option
                                                value={d}
                                              >
                                                {d}
                                              </option>
                                            ))}
                                          </Field>
                                        </div>
                                        <div className="col-md-4 col-lg-4 col-sm-4 col-xs-4 pl pr">
                                          <Field
                                            as="select"
                                            name="month"
                                            id="month"
                                            //   onChange={handleChange}
                                            //     disabled={this.state.isDisabled}
                                            //  disabled
                                            onChange={
                                              e => {
                                                

                                                setFieldValue(values.month = e.target.value)
                                                if (e.target.value == 2) {
                                                  var listOfDay = [];
                                                  for (var i = 1; i <= 29; i++) {
                                                    //    var current = year - i;
                                                    listOfDay.push(i);
                                                    // this.setState({ listOfDay: listOfDay });
                                                  }
                                                } else if (e.target.value == 1 || e.target.value == 3 || e.target.value == 5 || e.target.value == 7 || e.target.value == 8 || e.target.value == 10 || e.target.value == 12) {
                                                  var listOfDay = [];
                                                  for (var i = 1; i <= 31; i++) {
                                                    //    var current = year - i;
                                                    listOfDay.push(i);
                                                    //  this.setState({ listOfDay: listOfDay });
                                                  }
                                                }
                                                else {
                                                  var listOfDay = [];
                                                  for (var i = 1; i <= 30; i++) {
                                                    //    var current = year - i;
                                                    listOfDay.push(i);
                                                    //  this.setState({ listOfDay: listOfDay });
                                                  }
                                                }
                                                setFieldValue(values.listOfDay = listOfDay)
                                              }
                                            }

                                            className="form-control"
                                          >
                                            <option value="">MM</option>
                                            {(
                                              values.listOfMonth ||
                                              []
                                            ).map(m => (
                                              <option
                                                value={m.key}
                                              >
                                                {m.value}
                                              </option>
                                            ))}
                                          </Field>
                                        </div>
                                        <div className="col-md-5 col-lg-5 col-sm-5 col-xs-5 pl pr">
                                          <Field
                                            as="select"
                                            name="year"
                                            id="year"

                                            className="form-control"
                                          >
                                            <option value="">YYYY</option>
                                            {(
                                              this.state.listOfYear1 ||
                                              []
                                            ).map(year => (
                                              <option
                                                value={year}
                                              >
                                                {year}
                                              </option>
                                            ))}
                                          </Field>
                                        </div>
                                      </div>
                                      <ErrorMessage
                                        name="startDate"
                                        component="div"
                                        className="validation"
                                      />
                                    </div>
                                    <div className="col-md-4 col-sm-4 col-xs-12">
                                      <label>End Date</label>
                                      {/* <Field
                                        type="date"
                                        name="endDate"
                                        id="endDate"
                                        max={this.state.today}
                                        // max={values.maxDate}
                                        className="form-control"
                                        onChange={handleChange}
                                      /> */}

                                      {/* <DatePicker selected={values.endDate}
                                        autoComplete="off"
                                        placeholderText="dd-mm-yyyy"
                                        dateFormat="dd-MM-yyyy"
                                        maxLength="50"
                                        maxDate={new Date()}

                                        value={values.endDate}
                                        onChangeRaw={event => {

                                          let temp = event.target.value

                                          if (temp !== undefined) {
                                            if (temp.includes("/")) {

                                              setFieldValue(values.endDate = "")
                                              setFieldValue(values.errmsg1 = "Date should be in dd-mm-yyyy format.")

                                            }
                                            else {
                                              // setFieldValue(values.toDate = date)
                                              setFieldValue(values.errmsg1 = "")

                                            }
                                          }
                                        }
                                        }
                                        onChange={date => {

                                          setFieldValue(values.endDate = date)


                                          setFieldValue(values.endDate1 = moment(date).format("YYYY-MM-DD"))

                                          // setFieldValue(values.dateOfBirth = date)
                                        }}
                                        className={
                                          "form-control"

                                        }

                                      /> */}


                                      <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 pl pr">
                                        <div className="col-md-3 col-lg-3 col-sm-3 col-xs-3 pl pr">
                                          <Field
                                            as="select"
                                            name="today"
                                            id="today"
                                            // onChange={handleChange}
                                            //  disabled={this.state.isDisabled}
                                            //  disabled
                                            onChange={
                                              e => {

                                                
                                                var listOfMonth = []
                                                setFieldValue(values.today = e.target.value)
                                                if (e.target.value == 31) {
                                                  listOfMonth = [{ "key": 1, "value": "Jan" },

                                                  { "key": 3, "value": "Mar" },

                                                  { "key": 5, "value": "May" },

                                                  { "key": 7, "value": "Jul" },
                                                  { "key": 8, "value": "Aug" },

                                                  { "key": 10, "value": "Oct" },

                                                  { "key": 12, "value": "Dec" },
                                                  ];


                                                } else if (e.target.value == 30) {
                                                  listOfMonth = [

                                                    { "key": 1, "value": "Jan" },

                                                    { "key": 3, "value": "Mar" },
                                                    { "key": 4, "value": "Apr" },
                                                    { "key": 5, "value": "May" },
                                                    { "key": 6, "value": "Jun" },
                                                    { "key": 7, "value": "Jul" },
                                                    { "key": 8, "value": "Aug" },
                                                    { "key": 9, "value": "Sep" },
                                                    { "key": 10, "value": "Oct" },
                                                    { "key": 11, "value": "Nov" },
                                                    { "key": 12, "value": "Dec" },

                                                  ];


                                                }
                                                else if (e.target.value == 28 || e.target.value == 29) {
                                                  listOfMonth = [{ "key": 1, "value": "Jan" },
                                                  { "key": 2, "value": "Feb" },
                                                  { "key": 3, "value": "Mar" },
                                                  { "key": 4, "value": "Apr" },
                                                  { "key": 5, "value": "May" },
                                                  { "key": 6, "value": "Jun" },
                                                  { "key": 7, "value": "Jul" },
                                                  { "key": 8, "value": "Aug" },
                                                  { "key": 9, "value": "Sep" },
                                                  { "key": 10, "value": "Oct" },
                                                  { "key": 11, "value": "Nov" },
                                                  { "key": 12, "value": "Dec" },
                                                  ];
                                                } else {
                                                  listOfMonth = [{ "key": 1, "value": "Jan" },
                                                  { "key": 2, "value": "Feb" },
                                                  { "key": 3, "value": "Mar" },
                                                  { "key": 4, "value": "Apr" },
                                                  { "key": 5, "value": "May" },
                                                  { "key": 6, "value": "Jun" },
                                                  { "key": 7, "value": "Jul" },
                                                  { "key": 8, "value": "Aug" },
                                                  { "key": 9, "value": "Sep" },
                                                  { "key": 10, "value": "Oct" },
                                                  { "key": 11, "value": "Nov" },
                                                  { "key": 12, "value": "Dec" },
                                                  ];

                                                }
                                                setFieldValue(values.listOfMonth1 = listOfMonth)
                                                // this.setState({
                                                //   listOfMonth: listOfMonth
                                                // })
                                              }}

                                            className="form-control"
                                          >
                                            <option value="">DD</option>
                                            {(
                                              values.listOfDay1 ||
                                              []
                                            ).map(d => (
                                              <option
                                                value={d}
                                              >
                                                {d}
                                              </option>
                                            ))}
                                          </Field>
                                        </div>
                                        <div className="col-md-4 col-lg-4 col-sm-4 col-xs-4 pl pr">
                                          <Field
                                            as="select"
                                            name="tomonth"
                                            id="tomonth"
                                            //   onChange={handleChange}
                                            //     disabled={this.state.isDisabled}
                                            //  disabled
                                            onChange={
                                              e => {
                                                

                                                setFieldValue(values.tomonth = e.target.value)

                                                if (e.target.value == 2) {
                                                  var listOfDay = [];
                                                  for (var i = 1; i <= 29; i++) {
                                                    //    var current = year - i;
                                                    listOfDay.push(i);
                                                    // this.setState({ listOfDay: listOfDay });
                                                  }
                                                } else if (e.target.value == 1 || e.target.value == 3 || e.target.value == 5 || e.target.value == 7 || e.target.value == 8 || e.target.value == 10 || e.target.value == 12) {
                                                  var listOfDay = [];
                                                  for (var i = 1; i <= 31; i++) {
                                                    //    var current = year - i;
                                                    listOfDay.push(i);
                                                    //  this.setState({ listOfDay: listOfDay });
                                                  }
                                                }
                                                else {
                                                  var listOfDay = [];
                                                  for (var i = 1; i <= 30; i++) {
                                                    //    var current = year - i;
                                                    listOfDay.push(i);
                                                    //  this.setState({ listOfDay: listOfDay });
                                                  }
                                                }
                                                setFieldValue(values.listOfDay1 = listOfDay)
                                              }
                                            }

                                            className="form-control"
                                          >
                                            <option value="">MM</option>
                                            {(
                                              values.listOfMonth1 ||
                                              []
                                            ).map(m => (
                                              <option
                                                value={m.key}
                                              >
                                                {m.value}
                                              </option>
                                            ))}
                                          </Field>
                                        </div>
                                        <div className="col-md-5 col-lg-5 col-sm-5 col-xs-5 pl pr">
                                          <Field
                                            as="select"
                                            name="toyear"
                                            id="toyear"
                                            // onChange={handleChange}
                                            //    disabled={this.state.isDisabled}
                                            //  disabled

                                            className="form-control"
                                          >
                                            <option value="">YYYY</option>
                                            {(
                                              this.state.listOfYear1 ||
                                              []
                                            ).map(year => (
                                              <option
                                                value={year}
                                              >
                                                {year}
                                              </option>
                                            ))}
                                          </Field>
                                        </div>
                                      </div>
                                      <ErrorMessage
                                        name="endDate"
                                        component="div"
                                        className="validation"
                                      />
                                    </div>
                                  </div>
                                  <div class="row">
                                    <div className="col-md-12 col-sm-12 mt-40">
                                      <button
                                        className="btn-5 mr-20 ml-20 pull-right"
                                        type="submit"
                                      >
                                        Search
                                          </button>
                                      <button
                                        className="btn-5 pull-right"
                                        type="reset"
                                        onClick={this.showAll}
                                      >
                                        Reset
                                          </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Form>
                        <div class="table-responsive">
                          <ReactTable
                            minRows={2}
                            data={this.state.data || []}
                            filterable
                            ref={(refReactTable) => { this.refReactTable = refReactTable; }}
                            defaultFilterMethod={this.filterCaseInsensitive}
                            defaultSorted={[
                              {
                                id: "lastModifiedDateTime",
                                desc: true
                              }
                            ]}
                            columns={[
                              {
                                columns: [
                                  {
                                    Header: "User ID",
                                    id: "learnerPartyID",
                                    accessor: d => (d.learnerPartyID || '').toString()
                                  },
                                  {
                                    Header: "User Name",
                                    id: "learnerName",
                                    accessor: d => (d.learnerName || '').toString()
                                  },
                                  {
                                    Header: "Course Name",
                                    id: "productID",
                                    accessor: d => (d.productName || '').toString(),

                                  },
                                  {
                                    Header: "Order ID",
                                    id: "orderID",
                                    accessor: d => (d.orderID || '').toString()
                                  },

                                  {
                                    Header: "Date",
                                    id: "orderPlacedOn",
                                    accessor: d =>
                                      d.orderPlacedOn ? moment(d.orderPlacedOn, "DD/MM/YYYY").format("DD-MM-YYYY") : ""
                                  },
                                  {
                                    Header: "Price",
                                    id: "totalOrderPrice",
                                    Cell: row => (
                                      <div style={{ textAlign: "right" }}>{row.value}</div>
                                    ),
                                    accessor: d =>
                                      (new Intl.NumberFormat('en-IN', {
                                        style: 'currency',
                                        currency: 'INR'
                                      }).format(d.totalOrderPrice) || '').toString()

                                  },
                                  {
                                    Header: "Transaction ID",
                                    id: "transactionID",
                                    accessor: d => (d.transactionID || '').toString()
                                  },
                                  {
                                    Header: "Payment Status",
                                    id: "paymentStatus",
                                    // accessor: d => ((d[1] || '').partyId || "").toString()
                                    accessor: d =>
                                      d.paymentStatus == "Txn Success"
                                        ? "Success"
                                        : d.paymentStatus == "TXN_FAILURE"
                                          ? "FAIL"
                                          : d.paymentStatus == "Initiated" ||
                                            d.paymentStatus == "initiated"
                                            ? "Initiated"
                                            : d.paymentStatus
                                  }
                                ]
                              }
                            ]}
                            defaultPageSize={10}
                            style={{
                              width: "100%",
                              maxHeight: "500px"
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </React.Fragment>
        )}
      />
    );
  }
}

export default myPayments;
