import React from 'react';
import DashboardHeader from "../dashboardheader/dashboardheader";
import ReactHTMLParser from "react-html-parser";
import Finxfooter from "../../containers/finxfooter/finxfooter";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { FetchNotificationService } from "../../services/fetchnotification";
import { getNotificationEvents, getNotificationEventsError, getNotificationEventsPending } from '../../reducer/notificationReducer';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { fetchNotificationEvents } from '../../services/fetchLearnerDashboard.service';
import ReactTable from "react-table";
import { ExcelService } from "../Excel/excel";
import { success, successNotification, error, errorNotification, warning, warningNotification } from "../notification/notifications";
import * as moment from "moment";
import $ from 'jquery';

//set data to container/ componant
const mapStateToProps = state => ({
  notificationEvents: getNotificationEvents(state.notificationEventsReducer),
  pendingNotificationEvents: getNotificationEventsPending(
    state.notificationEventsReducer
  ),
  errorNotificationEvents: getNotificationEventsError(
    state.notificationEventsReducer
  ),

});

//set state  data to API/ Service
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchNotificationEvents: fetchNotificationEvents

    },
    dispatch
  );

class MyMessagesInbox extends React.Component {
  constructor(props) {
    super(props);
    // this.state = {
    //   MessageDetails: [],
    // };
    this.state = {
      data: [],
      dataSent: [],
      dataDraft: [],
      MessageDetails: [],
      dataAllMail:[],
      alertcount: "",
      notificationcount: "",
      alertNotificationEvents: JSON.parse(localStorage.getItem('AdminAlertNotification')),
      //notificationEvents: JSON.parse(localStorage.getItem('AdminNotification')),
      isdisplay: "none",
      inboxCount:"",
    };
  }

  componentDidMount() {
    
    if (localStorage.getItem("roleId") == "6") {
      document.getElementById('draft1').style.display = "block";
      document.getElementById('allMail1').style.display = "block";
    }
    else {
      document.getElementById('draft1').style.display = "none";
      document.getElementById('allMail1').style.display = "none";

    }
    
    // if (localStorage.getItem("roleId") == "6") {
    //   document.getElementById('allMail1').style.display = "block";
    // }
    // else {
    //   document.getElementById('allMail1').style.display = "none";
    // }

    let partyID = localStorage.getItem("ciel-userid-Token");
    FetchNotificationService.fetchNotification("partyId=" + partyID, (res1) => {
      this.setState({
        data: res1.responseListObject,
        notificationEvents: res1.responseListObject
      })
    })

    FetchNotificationService.fetchNotificationSent("senderPartyId=" + partyID, (res) => {

      this.setState({
        dataSent: res.responseListObject
      })
    })

    FetchNotificationService.fetchNotificationDarft("senderPartyId=" + partyID + "&deliveryChannel=email", (res2) => {
      this.setState({
        dataDraft: res2.responseListObject
      })
    })

    FetchNotificationService.fetchNotificationEvents("partyId=" , (res4) => {
      console.log("res4.responseListObject:: ", res4.responseListObject)
      this.setState({
        dataAllMail: res4.responseListObject
      })
    })

    // FetchNotificationService.fetchNotificationEvents("partyId=" , (res4) => {
    //   let data = [];
    //   for (let i = 0; i < res4.responseListObject.length; i++) {
    //     let data1 = {}
    //     data1['notifyHistoryId'] = res4.responseListObject[i].notifyHistoryId
    //     data1['fromEmailId'] = res4.responseListObject[i].fromEmailId
    //     data1['fromEmailName'] = res4.responseListObject[i].fromEmailName
    //     data1['toEmailIdOrMobileNo'] = res4.responseListObject[i].toEmailIdOrMobileNo
    //     data1['emailSubjectText'] = res4.responseListObject[i].emailSubjectText
    //     data1['deliveryStatus'] = res4.responseListObject[i].deliveryStatus
    //     if (res4.responseListObject[i].notifyEvent.lastModifiedDateTime !== "") {
    //         data1['lastAttemptDatetime'] = res4.responseListObject[i].notifyEvent.lastModifiedDateTime
    //     }
    //     data.push(data1)

    // }
    //   this.setState({
    //     dataAllMail: data
    //   })
    //   console.log("res4.responseListObject:: ", this.state.dataAllMail)
    // })


    $(document).ready(function () {
      $('.tabs a').click(function () {

        $('.panel').hide();
        $('#inboxTab li a').removeClass('active');
        $(this).addClass('active');

        var panel = $(this).attr('href');
        $(panel).fadeIn(1000);

        return false;  // prevents link action

      });  // end click 

      $('.tabs li:first a').click();

    }); // end ready
  }
  convert(url) {
    // var text = document.getElementById("url").value;
    var exp = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;
    var text1 = url.replace(exp, "<a href='$1'>$1</a>");
    var exp2 = /(^|[^\/])(www\.[\S]+(\b|$))/gim;
    alert(text1.replace(exp2, '$1<a target="_blank" href="http://$2">$2</a>'))
    return text1.replace(exp2, '$1<a target="_blank" href="http://$2">$2</a>');
    // document.getElementById("converted_url").innerHTML = text1.replace(exp2, '$1<a target="_blank" href="http://$2">$2</a>');
  }


  // notification = (key) => {
  //   if (this.state.notificationEvents.length > 0) {
  //     this.props.history.push("/notification", {
  //       alertid: key,
  //       notification: this.state.notificationEvents
        
  //     })
  //     window.location.reload()
  //   }
  // }

  notification = (key, object) => {
    if(object.read_unread === false){
     // 
      //alert("kkk")
      let notifyHistory = {
        "notifyHistoryId": key,
      }
        FetchNotificationService.updateReadUnread(notifyHistory, (res2) => {
          //alert("ppp")
          if(res2.status === "success"){
          //  alert("lll")
          let senderPartyId = localStorage.getItem("ciel-userid-Token");
            FetchNotificationService.getCount("senderPartyId=" + senderPartyId, (res1) => {
              if(res1.status==="success"){
              this.setState({
                inboxCount: res1?.responseObject?.unreadCount
              })
            }
            })
          }
        })     
    } 
    console.log("object ::", object)
  
    if (this.state.notificationEvents.length > 0) {
      let arrayObj =[]
      arrayObj.push(object)
      this.props.history.push("/notification", {
        alertid: key,
        notification: arrayObj,
        value:"Inbox",
        inboxCount:this.state.inboxCount,
        
      })
       setTimeout(function () {
      window.location.reload()
       }, 1000)
    }
 
  }

  draftView = (key) => {
    if (this.state.dataDraft.length > 0) {
      this.props.history.push("/my-inbox-create", {
        alertid: key,
        notification: this.state.dataDraft
      })
      window.location.reload()
    }
  }


  // notificationSent = (key) => {
  //   if (this.state.dataSent.length > 0) {
  //     this.props.history.push("/notification", {
  //       alertid: key,
  //       notification: this.state.dataSent
  //     })
  //     window.location.reload()
  //   }
  // }

  notificationSent = (key, object) => {
    console.log("object ::", object)
    if (this.state.dataSent.length > 0) {
      let arrayObj =[]
      arrayObj.push(object)
      this.props.history.push("/notification", {
        alertid: key,
        notification: arrayObj,
        value:"Sent"
      })
      window.location.reload()
    }
  }

  notificationAllMail = (key, object) => {
    
    console.log("object ::", object)
      if (this.state.dataAllMail.length > 0) {
      let arrayObj =[]
      arrayObj.push(object)
      this.props.history.push("/notification", {
        alertid: key,
        notification: arrayObj,
        value:"Sent"
      })
      window.location.reload()
    }
  }


  filterCaseInsensitive = ({ id, value }, row) =>
    row[id] ? row[id].toLowerCase().includes(value.toLowerCase()) : true;
  eMailMessage = (values) => {
    let message = values.emailSMSBodyText.replaceAll('<br>', '')
    let message1 = message.replaceAll('<p>', '')
    let message2 = message1.replaceAll('</p>', '')

    // let message3 = this.convert(message2)


    // // <a href='
    // alert(message3)
    // message = values.emailSMSBodyText.replaceAll('</p>', '')
    values.emailSMSBodyText = message2;

    this.setState({ MessageDetails: values });
    console.log(this.state.MessageDetails);
  }
  exportToCSV = () => {

    let modifiedData = [];
    let data = this.state.data
    if (data.length > 0) {

      
      data.forEach((element, index) => {
        let d = element
        var data1 = {

          "From ID": element.fromEmailId,
          "From Name": element.fromEmailName,
          "Sender Mail": element.senderEmail === undefined ? '': element.senderEmail,
          "Subject": element.emailSubjectText,
          "Delivery Status": (element.deliveryStatus || '').toString() === 'completed' ? 'Success' : 'Failure',
          //"Delivery Date": moment(element.lastAttemptDatetime.split(" ")[0], "DD/MM/YYYY").format("DD-MM-YYYY")
          "Delivery Date": (d.lastAttemptDatetime.split(' ')[0].split('/')[0] + "-" + d.lastAttemptDatetime.split(' ')[0].split('/')[1] + "-" + d.lastAttemptDatetime.split(' ')[0].split('/')[2] + " " + d.lastAttemptDatetime.split(' ')[1] || ''),


        };
        modifiedData.push(data1);
      });

      ExcelService.ExcelData(modifiedData, 'ExpertMFD-InboxList');
    }
    else {
      warning("No record found", warningNotification)
    }
  };
  exportToSentCSV = () => {

    let modifiedData = [];
    let data = this.state.dataSent
    if (data.length > 0) {


      data.forEach((element, index) => {
        let d = element
        var data1 = {

          "From ID": element.fromEmailId,
          "From Name": element.fromEmailName,
          "To ID": element.fromEmailId,

          "Subject": element.emailSubjectText,
          "Delivery Status": (element.deliveryStatus || '').toString() === 'completed' ? 'Success' : 'Failure',
          //"Delivery Date": moment(element.lastAttemptDatetime.split(" ")[0], "DD/MM/YYYY").format("DD-MM-YYYY")
          "Delivery Date": (d.lastAttemptDatetime.split(' ')[0].split('/')[0] + "-" + d.lastAttemptDatetime.split(' ')[0].split('/')[1] + "-" + d.lastAttemptDatetime.split(' ')[0].split('/')[2] + " " + d.lastAttemptDatetime.split(' ')[1] || ''),

        };
        modifiedData.push(data1);
      });

      ExcelService.ExcelData(modifiedData, 'ExpertMFD-SentList');
    }
    else {
      warning("No record found", warningNotification)
    }
  };

  exportToAllMailCSV = () => {

    let modifiedData = [];
    let data = this.state.dataAllMail
    if (data.length > 0) {


      data.forEach((element, index) => {
        let d = element
        var data1 = {

          "From ID": element.fromEmailId,
          "From Name": element.fromEmailName,
          "To ID": element.toEmailIdOrMobileNo,

          "Subject": element.emailSubjectText,
          "Delivery Status": (element.deliveryStatus || '').toString() === 'completed' ? 'Success' : 'Failure',
          //"Delivery Date": moment(element.lastAttemptDatetime.split(" ")[0], "DD/MM/YYYY").format("DD-MM-YYYY")
         // "Delivery Date": (d.lastAttemptDatetime.split(' ')[0].split('/')[0] + "-" + d.lastAttemptDatetime.split(' ')[0].split('/')[1] + "-" + d.lastAttemptDatetime.split(' ')[0].split('/')[2] + " " + d.lastAttemptDatetime.split(' ')[1] || ''),
          "Delivery Date": (d.lastAttemptDatetime ||'').toString() !== ''?(d.lastAttemptDatetime.split(' ')[0].split('/')[0] + "-" + d.lastAttemptDatetime.split(' ')[0].split('/')[1] + "-" + d.lastAttemptDatetime.split(' ')[0].split('/')[2] + " " + d.lastAttemptDatetime.split(' ')[1] || ''):'',
        };
        modifiedData.push(data1);
      });

      ExcelService.ExcelData(modifiedData, 'ExpertMFD-AllMailList');
    }
    else {
      warning("No record found", warningNotification)
    }
  };

  createMail = () => {
    console.log(this.props)
    //window.location.href="/my-inbox-create"
    let role = localStorage.getItem("roleId")
    if (role === "6") {
      this.props.history.push("/my-inbox-create", {
        alerts: this.props.alertNotificationEvents,
        notification: this.props.notificationEvents,
        userRoleAccess: this.props.userRoleAccess,

      }
      )
    }
    else {
      this.props.history.push("/my-inbox-compose", {
        alerts: this.props.alertNotificationEvents,
        notification: this.props.notificationEvents,
        userRoleAccess: this.props.userRoleAccess,

      }
      )
    }
  }

  switchTab = (evt, inp) => {

    //  var i, tabcontent, tablinks;
    // tabcontent = document.getElementsByClassName("tabcontent");
    // if (inp == "factual") {
    //   this.setState({ display: 'none' })
    //   this.setState({ signuptabName: "tab active" })
    //   this.setState({ logintabName: "tab" })
    //   this.setState({ draftMessage: "tab" })
    //   document.getElementById('behavioural').style.display = "none";
    //   document.getElementById('draft').style.display = "none";
    //   document.getElementById('factual').style.display = "block";
    //   evt.currentTarget.className += " active";
    // }
    // else {
    //   this.setState({ display: 'block' })
    //   this.setState({ signuptabName: "tab" })
    //   this.setState({ logintabName: "tab active" })
    //   document.getElementById('factual').style.display = "none";
    //   document.getElementById('draft').style.display = "none";
    //   document.getElementById('behavioural').style.display = "block";
    //   evt.currentTarget.className += " active";
    // }


  }
  DeleteDraft = (data1) => {
    let d1 = {
      "draftHistoryId": data1.draftHistoryId,
    }
    var txt = "Are you sure you want to delete?"
    var r = window.confirm(txt);
    if (r == true) {
      FetchNotificationService.deleteDarft(d1, (res2) => {
        success("Draft deleted sucessfully.", successNotification)
        window.location.reload();
      })
    }
  }

  render(props) {
    return (
      <React.Fragment>
        <DashboardHeader
          {...this.props} />
        <div class="container">
          <div className="preassessmentDetailBox mb-50">
            <div className="pre-detail-tab">
              <div className="row">
              <div className="col-md-8 col-sm-8 col-xs-12">
              <ul class="tabs tab-group" id="inboxTab">
                <li><a href="#inbox">INBOX</a></li>
                <li><a href="#sent">SENT</a></li>
                <li id="draft1"><a href="#draft">DRAFT</a></li>
                <li id="allMail1"><a href="#allMail">ALL MAIL</a></li>
                {/* <li><a href="">COMPOSE</a></li> */}
              </ul>
              </div>
              <div className="col-md-4 col-sm-4 col-xs-12">
                <div className="composeBtn1">
              <button className="btn-2" onClick={this.createMail}>Compose</button>
              </div>
              </div>
              </div>
            </div>
            {!this.props.pendingNotificationEvents ?
              <section class="panel dashboard-tab user-tab pb-mb-5" id="inbox">
                <div class="">
                  <div class="container-fluid">
                    <div class="row">
                      <div class="col-md-12 col-sm-12 col-xs-12">
                        <div class="table-header">
                          <h3>
                            <div class="row">
                              <div class="col-md-6 col-sm-6 col-xs-6">
                                <span class="left-icon">
                                  <i class="fa fa-list" aria-hidden="true"></i>
                                </span>{" "}
                                Inbox
                        </div>
                              <div class="col-md-6 col-sm-6 col-xs-6">
                                <span class="right-icon">
                                  <a title="Export">
                                    <i
                                      onClick={() => this.exportToCSV()}
                                      class="fa fa-file-excel-o"
                                      aria-hidden="true"
                                    ></i>
                                  </a>
                                </span>

                              </div>
                            </div>
                          </h3>
                        </div>
                        <div class="bg-box">
                          <div class="table-responsive">
                            <ReactTable

                              minRows={2}
                              data={this.state.data || []}
                              filterable
                              ref={(refReactTable) => {
                                this.refReactTable = refReactTable;
                              }}
                              defaultFilterMethod={this.filterCaseInsensitive}
                              columns={[
                                {
                                  columns: [
                                                                       {
                                      Header: "From ID",
                                      id: "fromEmailId",
                                      //className:"unread",
                                      //className={" " +(props.original.read_unread ? 'unread': '' )},
                                      accessor: (d) =>
                                        (d.fromEmailId || "").toString(),
                                     
                                        Cell: props => {
                                        return (
                                          <div>
                                          <strong className={" " +(props.original.read_unread ? '': 'unread' )}>
                                                 {props.original.fromEmailId}
                                                 </strong>
                                          </div>
                                        );
                                      }

                                    },
                                    {
                                      Header: "From Name",
                                      id: "fromEmailName",
                                      // className:"unread",
                                      accessor: (d) =>
                                        (d.fromEmailName || "").toString(),
                                        Cell: props => {
                                        return (
                                          <div>
                                          <strong className={" " +(props.original.read_unread ? '': 'unread' )}>
                                                 {props.original.fromEmailName}
                                                 </strong>
                                          </div>
                                        );
                                      }
                                    },                                                                     {
                                      Header: "Sender Mail ID",
                                      id: "senderEmail",
                                      // className:"unread",
                                      accessor: (d) =>
                                        (d.senderEmail || "").toString(),
                                        Cell: props => {
                                        return (
                                          <div>
                                          <strong className={" " +(props.original.read_unread ? '': 'unread' )}>
                                                 {props.original.senderEmail}
                                                 </strong>
                                          </div>
                                        );
                                      }
                                    },
                                    {
                                      Header: "Subject",
                                      id: "emailSubjectText",
                                      // className:"unread",
                                      accessor: (d) =>
                                        (d.emailSubjectText || "").toString(),
                                      Cell: props => {
                                        return (
                                          <div>
                                            <a class="mar-left">
                                              {

                                                <div>
                                                  <a
                                                    title="View Email"
                                                   
                                                    aria-hidden="true" data-toggle="modal"
                                                    data-keyboard="true"
                                                    data-target="#modal-Message-Inbox"
                                                    onClick={() =>
                                                      // this.eMailMessage(props.original)
                                                      this.notification(props.original.notifyHistoryId, props.original)
                                                     // this.notification(props.original.notifyHistoryId)
                                                    }


                                                  >{props.original.emailSubjectText}</a>
                                                </div>
                                                //: ''
                                              }
                                            </a>
                                          </div>
                                        );
                                      }
                                      // },
                                      // ((d.productEntity || "").productName || '').toString()
                                    },
                                    {
                                      Header: "Delivery Status",
                                      id: "deliveryStatus",
                                      // className:"unread",
                                      accessor: (d) => (d.deliveryStatus || '').toString() === 'completed' ? 'Success' : 'Failure',
                                      Cell: props => {
                                        return (
                                          <div>
                                          <strong className={" " +(props.original.read_unread ? '': 'unread' )}>
                                                 {props.original.deliveryStatus.toString()  === 'completed' ? 'Success' : 'Failure'}
                                                 </strong>
                                          </div>
                                        );
                                      }
                                    },
                                    {
                                      Header: "Delivery Date",
                                      id: "lastAttemptDatetime",
                                      // className:"unread",
                                      accessor: (d) =>
                                        (d.lastAttemptDatetime.split(' ')[0].split('/')[0] + "-" + d.lastAttemptDatetime.split(' ')[0].split('/')[1] + "-" + d.lastAttemptDatetime.split(' ')[0].split('/')[2] + " " + d.lastAttemptDatetime.split(' ')[1] || ''),
                                        Cell: props => {
                                        return (
                                          <div>
                                          <strong className={" " +(props.original.read_unread ? '': 'unread' )}>
                                                 {props.original.lastAttemptDatetime.split(' ')[0].split('/')[0] + "-" + props.original.lastAttemptDatetime.split(' ')[0].split('/')[1] + "-" + props.original.lastAttemptDatetime.split(' ')[0].split('/')[2] + " " + props.original.lastAttemptDatetime.split(' ')[1] || ''}
                                                 </strong>
                                          </div>
                                        );
                                      }
                                    },
                                    // {
                                    //   Header: "Action",
                                    //   id: "action",
                                    //   filterable: false,
                                    //   sortable: false,
                                    //   Cell: props => {
                                    //     return (
                                    //       <div>
                                    //         <a class="mar-left">
                                    //           {
                                    //             //props.original.isActive == 1 ?
                                    //             <i
                                    //               title="View Email"
                                    //               className="fa fa-paper-plane"
                                    //               aria-hidden="true" data-toggle="modal"
                                    //                  data-keyboard="true"
                                    //               data-target="#modal-Message-Inbox"
                                    //               onClick={() =>
                                    //                 this.eMailMessage(props.original)
                                    //               }
                                    //             ></i>
                                    //             //: ''
                                    //           }
                                    //         </a>
                                    //       </div>
                                    //     );
                                    //   }
                                    // },

                                  ],
                                },
                              ]}
                              defaultPageSize={10}
                              style={{
                                width: "100%",
                                maxHeight: "500px",
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* </div>
            </div> */}
                    <div class="modal fade" id="modal-Message-Inbox" tabIndex="-1" role="dialog" aria-labelledby="modalLabel" data-keyboard="true" aria-hidden="true">
                      <div class="modal-dialog" style={{ width: "70%" }} >
                        <div class="modal-content">
                          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                          </button>
                          <Formik
                            enableReinitialize={true}
                            render={({
                              errors, touched
                            }) => (
                              <React.Fragment>
                                <Form className="form-group">
                                  <section >
                                    <div >
                                      <div class="row">
                                        <div class="col-md-2 col-lg-2 col-sm-2 col-xs-2">
                                        </div>
                                        <div class="col-md-8 col-lg-8 col-sm-8 col-xs-8">
                                          <div class="product-add">
                                            <div class="form-group">
                                              <div class="row">
                                                <div class="col-md-8 col-lg-8 col-sm-8 col-xs-8">
                                                  <label>
                                                    FROM
                                                </label>
                                                  <Field
                                                    type="text"
                                                    name="FromEMailId"
                                                    id="FromEMailId"
                                                    placeholder="FromEMailId"
                                                    value={this.state.MessageDetails.fromEmailName} //emailSubjectText  //emailSMSBodyText }
                                                    className={
                                                      "form-control"
                                                    }

                                                  >
                                                  </Field>

                                                </div>
                                                <div class="col-md-8 col-lg-8 col-sm-8 col-xs-8">
                                                  <label>
                                                    Subject
                                            </label>
                                                  <Field
                                                    type="text"
                                                    name="subject"
                                                    id="subject"
                                                    autoComplete="subject"
                                                    value={this.state.MessageDetails.emailSubjectText}  //emailSMSBodyText }
                                                    className={
                                                      "form-control" +
                                                      (errors.subject &&
                                                        touched.text
                                                        ? " is-invalid"
                                                        : "")
                                                    }
                                                    placeholder="Subject"
                                                    className="form-control"
                                                  />
                                                  <ErrorMessage
                                                    name="subject"
                                                    component="div"
                                                    className="validation"
                                                  />
                                                </div>
                                                <div class="col-md-8 col-lg-8 col-sm-8 col-xs-8">
                                                  <label>
                                                    Message
                                                     </label>
                                                  <Field as="textarea" rows="8" cols="50" name="message"
                                                    id="message"
                                                    autoComplete="message"
                                                    value={this.state.MessageDetails.emailSMSBodyText}
                                                    className={
                                                      "form-control"
                                                    }
                                                  />
                                                  <ErrorMessage
                                                    name="message"
                                                    className="validation"
                                                    component="div"
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div class="col-md-2 col-lg-2 col-sm-2 col-xs-2">
                                        </div>
                                      </div>
                                    </div>
                                  </section>
                                </Form>
                              </React.Fragment>
                            )}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </section>
              : ""}

            <section class="panel dashboard-tab user-tab pb-mb-5" id="sent">
              <div class="">
                <div class="container-fluid">
                  <div class="row">
                    <div class="col-md-12 col-sm-12 col-xs-12">
                      <div class="table-header">
                        <h3>
                          <div class="row">
                            <div class="col-md-6 col-sm-6 col-xs-6">
                              <span class="left-icon">
                                <i class="fa fa-list" aria-hidden="true"></i>
                              </span>{" "}
                              Sent
                        </div>
                            <div class="col-md-6 col-sm-6 col-xs-6">
                              <span class="right-icon">
                                <a title="Export">
                                  <i
                                    onClick={() => this.exportToSentCSV()}
                                    class="fa fa-file-excel-o"
                                    aria-hidden="true"
                                  ></i>
                                </a>
                              </span>

                            </div>
                          </div>
                        </h3>
                      </div>
                      <div class="bg-box">
                        <div class="table-responsive">
                          <ReactTable

                            minRows={2}
                            data={this.state.dataSent || []}
                            filterable
                            ref={(refReactTable) => {
                              this.refReactTable = refReactTable;
                            }}
                            defaultFilterMethod={this.filterCaseInsensitive}
                            columns={[
                              {
                                columns: [
                                  {
                                    Header: "From ID",
                                    id: "fromEmailId",
                                    accessor: (d) =>
                                      (d.fromEmailId || "").toString(),
                                  },
                                  {
                                    Header: "From Name",
                                    id: "fromEmailName",
                                    accessor: (d) =>
                                      (d.fromEmailName || "").toString(),
                                  },
                                  {
                                    Header: "To",
                                    id: "toEmailId",
                                    accessor: (d) =>
                                      (d.toEmailIdOrMobileNo || "").toString(),
                                  },
                                  {
                                    Header: "Subject",
                                    id: "emailSubjectText",
                                    accessor: (d) =>
                                      (d.emailSubjectText || "").toString(),
                                    Cell: props => {
                                      return (
                                        <div>
                                          <a class="mar-left">
                                            {

                                              <div>
                                                <a
                                                  title="View Email"
                                                  //      className="fa fa-paper-plane"
                                                  aria-hidden="true" data-toggle="modal"
                                                  data-keyboard="true"
                                                  data-target="#modal-Message-Inbox"
                                                  onClick={() =>
                                                    // this.eMailMessage(props.original)
                                                   // this.notificationSent(props.original.notifyHistoryId)
                                                    this.notificationSent(props.original.notifyHistoryId, props.original)
                                                  }


                                                >{props.original.emailSubjectText}</a>
                                              </div>
                                              //: ''
                                            }
                                          </a>
                                        </div>
                                      );
                                    }
                                  },
                                  {
                                    Header: "Delivery Status",
                                    id: "deliveryStatus",
                                    accessor: (d) => (d.deliveryStatus || '').toString() === 'completed' ? 'Success' : 'Failure'
                                  },
                                  {
                                    Header: "Delivery Date",
                                    id: "lastAttemptDatetime",
                                    accessor: (d) =>
                                      (d.lastAttemptDatetime.split(' ')[0].split('/')[0] + "-" + d.lastAttemptDatetime.split(' ')[0].split('/')[1] + "-" + d.lastAttemptDatetime.split(' ')[0].split('/')[2] + " " + d.lastAttemptDatetime.split(' ')[1] || ''),
                                  },
                                  // {
                                  //   Header: "Delivery Date",
                                  //   id: "lastAttemptDatetime",
                                  //   accessor: (d) =>
                                  //     (moment(d.lastAttemptDatetime.split(" ")[0], "DD/MM/YYYY").format("DD-MM-YYYY") || ""),
                                  // },
                                ],
                              },
                            ]}
                            defaultPageSize={10}
                            style={{
                              width: "100%",
                              maxHeight: "500px",
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* </div>
            </div> */}
                  <div class="modal fade" id="modal-Message-Inbox" tabIndex="-1" role="dialog" aria-labelledby="modalLabel" data-keyboard="true" aria-hidden="true">
                    <div class="modal-dialog" style={{ width: "70%" }} >
                      <div class="modal-content">
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                          <span aria-hidden="true">&times;</span>
                        </button>
                        <Formik
                          enableReinitialize={true}
                          render={({
                            errors, touched
                          }) => (
                            <React.Fragment>
                              <Form className="form-group">
                                <section >
                                  <div >
                                    <div class="row">
                                      <div class="col-md-2 col-lg-2 col-sm-2 col-xs-2">
                                      </div>
                                      <div class="col-md-8 col-lg-8 col-sm-8 col-xs-8">
                                        <div class="product-add">
                                          <div class="form-group">
                                            <div class="row">
                                              <div class="col-md-8 col-lg-8 col-sm-8 col-xs-8">
                                                <label>
                                                  FROM
                                                </label>
                                                <Field
                                                  type="text"
                                                  name="FromEMailId"
                                                  id="FromEMailId"
                                                  placeholder="FromEMailId"
                                                  value={this.state.MessageDetails.fromEmailName} //emailSubjectText  //emailSMSBodyText }
                                                  className={
                                                    "form-control"
                                                  }

                                                >
                                                </Field>

                                              </div>
                                              <div class="col-md-8 col-lg-8 col-sm-8 col-xs-8">
                                                <label>
                                                  Subject
                                            </label>
                                                <Field
                                                  type="text"
                                                  name="subject"
                                                  id="subject"
                                                  autoComplete="subject"
                                                  value={this.state.MessageDetails.emailSubjectText}  //emailSMSBodyText }
                                                  className={
                                                    "form-control" +
                                                    (errors.subject &&
                                                      touched.text
                                                      ? " is-invalid"
                                                      : "")
                                                  }
                                                  placeholder="Subject"
                                                  className="form-control"
                                                />
                                                <ErrorMessage
                                                  name="subject"
                                                  component="div"
                                                  className="validation"
                                                />
                                              </div>
                                              <div class="col-md-8 col-lg-8 col-sm-8 col-xs-8">
                                                <label>
                                                  Message
                                                     </label>
                                                <Field as="textarea" rows="8" cols="50" name="message"
                                                  id="message"
                                                  autoComplete="message"
                                                  value={this.state.MessageDetails.emailSMSBodyText}
                                                  className={
                                                    "form-control"
                                                  }
                                                />
                                                <ErrorMessage
                                                  name="message"
                                                  className="validation"
                                                  component="div"
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div class="col-md-2 col-lg-2 col-sm-2 col-xs-2">
                                      </div>
                                    </div>
                                  </div>
                                </section>
                              </Form>
                            </React.Fragment>
                          )}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </section>

            <section class="panel dashboard-tab user-tab pb-mb-5" id="draft">
              <div class="">
                <div class="container-fluid">
                  <div class="row">
                    <div class="col-md-12 col-sm-12 col-xs-12">
                      <div class="table-header">
                        <h3>
                          <div class="row">
                            <div class="col-md-6 col-sm-6 col-xs-6">
                              <span class="left-icon">
                                <i class="fa fa-list" aria-hidden="true"></i>
                              </span>{" "}
                              Draft
                        </div>
                            <div class="col-md-6 col-sm-6 col-xs-6">
                              {/* <span class="right-icon">
                                <a title="Export">
                                  <i
                                    onClick={() => this.exportToCSV()}
                                    class="fa fa-file-excel-o"
                                    aria-hidden="true"
                                  ></i>
                                </a>
                              </span> */}

                            </div>
                          </div>
                        </h3>
                      </div>
                      <div class="bg-box">
                        <div class="table-responsive">
                          <ReactTable

                            minRows={2}
                            data={this.state.dataDraft || []}
                            filterable
                            ref={(refReactTable) => {
                              this.refReactTable = refReactTable;
                            }}
                            defaultFilterMethod={this.filterCaseInsensitive}
                            columns={[
                              {
                                columns: [

                                  {
                                    Header: "Subject",
                                    id: "emailSubjectText",
                                    accessor: (d) =>
                                      (d.emailSubjectText || "").toString(),
                                    Cell: props => {
                                      return (
                                        <div>
                                          <a class="mar-left">
                                            {

                                              <div>
                                                <a
                                                  title="View Email"
                                                  //      className="fa fa-paper-plane"
                                                  aria-hidden="true" data-toggle="modal"
                                                  data-keyboard="true"
                                                  data-target="#modal-Message-Inbox"
                                                  onClick={() =>
                                                    this.draftView(props.original.draftHistoryId)
                                                  }


                                                >{props.original.email_subject}</a>
                                              </div>
                                              //: ''
                                            }
                                          </a>
                                        </div>
                                      );
                                    }
                                  },

                                  {
                                    Header: "Draft Date",
                                    id: "lastAttemptDatetime",
                                    accessor: (d) => (d.createdDateTime.split(' ')[0].split('/')[0] + "-" + d.createdDateTime.split(' ')[0].split('/')[1] + "-" + d.createdDateTime.split(' ')[0].split('/')[2] + " " + d.createdDateTime.split(' ')[1] || ''),
                                    //     d.createdDateTime
                                    //  (moment(d.lastAttemptDatetime.split(" ")[0], "DD/MM/YYYY").format("DD-MM-YYYY") || ""),
                                  },
                                  {
                                    Header: "Action",
                                    id: "fromEmailId",
                                    filterable: false,
                                    sortable: false,
                                    Cell: props => {
                                      return (
                                        <div>


                                          <a class="mar-left">

                                            <i
                                              title="Delete"
                                              // className="fa fa-view fa-2x"
                                              className="fa fa-trash"
                                              aria-hidden="true"


                                              onClick={e => this.DeleteDraft(props.original)}
                                            >
                                            </i>

                                          </a>

                                        </div>
                                      );
                                    }
                                  }

                                ],
                              },
                            ]}
                            defaultPageSize={10}
                            style={{
                              width: "100%",
                              maxHeight: "500px",
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* </div>
            </div> */}
                  <div class="modal fade" id="modal-Message-Inbox" tabIndex="-1" role="dialog" aria-labelledby="modalLabel" data-keyboard="true" aria-hidden="true">
                    <div class="modal-dialog" style={{ width: "70%" }} >
                      <div class="modal-content">
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                          <span aria-hidden="true">&times;</span>
                        </button>
                        <Formik
                          enableReinitialize={true}
                          render={({
                            errors, touched
                          }) => (
                            <React.Fragment>
                              <Form className="form-group">
                                <section >
                                  <div >
                                    <div class="row">
                                      <div class="col-md-2 col-lg-2 col-sm-2 col-xs-2">
                                      </div>
                                      <div class="col-md-8 col-lg-8 col-sm-8 col-xs-8">
                                        <div class="product-add">
                                          <div class="form-group">
                                            <div class="row">
                                              <div class="col-md-8 col-lg-8 col-sm-8 col-xs-8">
                                                <label>
                                                  FROM
                                                </label>
                                                <Field
                                                  type="text"
                                                  name="FromEMailId"
                                                  id="FromEMailId"
                                                  placeholder="FromEMailId"
                                                  value={this.state.MessageDetails.fromEmailName} //emailSubjectText  //emailSMSBodyText }
                                                  className={
                                                    "form-control"
                                                  }

                                                >
                                                </Field>

                                              </div>
                                              <div class="col-md-8 col-lg-8 col-sm-8 col-xs-8">
                                                <label>
                                                  Subject
                                            </label>
                                                <Field
                                                  type="text"
                                                  name="subject"
                                                  id="subject"
                                                  autoComplete="subject"
                                                  value={this.state.MessageDetails.emailSubjectText}  //emailSMSBodyText }
                                                  className={
                                                    "form-control" +
                                                    (errors.subject &&
                                                      touched.text
                                                      ? " is-invalid"
                                                      : "")
                                                  }
                                                  placeholder="Subject"
                                                  className="form-control"
                                                />
                                                <ErrorMessage
                                                  name="subject"
                                                  component="div"
                                                  className="validation"
                                                />
                                              </div>
                                              <div class="col-md-8 col-lg-8 col-sm-8 col-xs-8">
                                                <label>
                                                  Message
                                                     </label>
                                                <Field as="textarea" rows="8" cols="50" name="message"
                                                  id="message"
                                                  autoComplete="message"
                                                  value={this.state.MessageDetails.emailSMSBodyText}
                                                  className={
                                                    "form-control"
                                                  }
                                                />
                                                <ErrorMessage
                                                  name="message"
                                                  className="validation"
                                                  component="div"
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div class="col-md-2 col-lg-2 col-sm-2 col-xs-2">
                                      </div>
                                    </div>
                                  </div>
                                </section>
                              </Form>
                            </React.Fragment>
                          )}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </section>
         
            <section class="panel dashboard-tab user-tab pb-mb-5" id="allMail">
              <div class="">
                <div class="container-fluid">
                  <div class="row">
                    <div class="col-md-12 col-sm-12 col-xs-12">
                      <div class="table-header">
                        <h3>
                          <div class="row">
                            <div class="col-md-6 col-sm-6 col-xs-6">
                              <span class="left-icon">
                                <i class="fa fa-list" aria-hidden="true"></i>
                              </span>{" "}
                              All Mail
                        </div>
                            <div class="col-md-6 col-sm-6 col-xs-6">
                              <span class="right-icon">
                                <a title="Export">
                                  <i
                                    onClick={() => this.exportToAllMailCSV()}
                                    class="fa fa-file-excel-o"
                                    aria-hidden="true"
                                  ></i>
                                </a>
                              </span>

                            </div>
                          </div>
                        </h3>
                      </div>
                      <div class="bg-box">
                        <div class="table-responsive">
                          <ReactTable

                            minRows={2}
                            data={this.state.dataAllMail || []}
                            filterable
                            ref={(refReactTable) => {
                              this.refReactTable = refReactTable;
                            }}
                            defaultFilterMethod={this.filterCaseInsensitive}
                            columns={[
                              {
                                columns: [
                                  {
                                    Header: "From ID",
                                    id: "fromEmailId",
                                    accessor: (d) =>
                                      (d.fromEmailId || "").toString(),
                                  },
                                  {
                                    Header: "From Name",
                                    id: "fromEmailName",
                                    accessor: (d) =>
                                      (d.fromEmailName || "").toString(),
                                  },
                                  {
                                    Header: "To",
                                    id: "toEmailId",
                                    accessor: (d) =>
                                      (d.toEmailIdOrMobileNo || "").toString(),
                                  },
                                  {
                                    Header: "Subject",
                                    id: "emailSubjectText",
                                    accessor: (d) =>
                                      (d.emailSubjectText || "").toString(),
                                    Cell: props => {
                                      return (
                                        <div>
                                          <a class="mar-left">
                                            {

                                              <div>
                                                <a
                                                  title="View Email"
                                                  //      className="fa fa-paper-plane"
                                                  aria-hidden="true" data-toggle="modal"
                                                  data-keyboard="true"
                                                  data-target="#modal-Message-Inbox"
                                                  onClick={() =>
                                                   this.notificationAllMail(props.original.notifyHistoryId, props.original)
                                                  }


                                                >{props.original.emailSubjectText}</a>
                                              </div>
                                              //: ''
                                            }
                                          </a>
                                        </div>
                                      );
                                    }
                                  },
                                  {
                                    Header: "Delivery Status",
                                    id: "deliveryStatus",
                                    accessor: (d) => (d.deliveryStatus || '').toString() === 'completed' ? 'Success' : 'Failure'
                                  },
                                  {
                                    Header: "Delivery Date",
                                    id: "lastAttemptDatetime",
                                    accessor: (d) =>
                                    (d.lastAttemptDatetime ||'').toString() !== ''?(d.lastAttemptDatetime.split(' ')[0].split('/')[0] + "-" + d.lastAttemptDatetime.split(' ')[0].split('/')[1] + "-" + d.lastAttemptDatetime.split(' ')[0].split('/')[2] + " " + d.lastAttemptDatetime.split(' ')[1] || ''):''

                                   // (d.lastAttemptDatetime.split(' ')[0].split('/')[0] + "-" + d.lastAttemptDatetime.split(' ')[0].split('/')[1] + "-" + d.lastAttemptDatetime.split(' ')[0].split('/')[2] + " " + d.lastAttemptDatetime.split(' ')[1] || ''),
                                     // (d.notifyEvent.lastModifiedDateTime.split(' ')[0].split('/')[0] + "-" + d.notifyEvent.lastModifiedDateTime.split(' ')[0].split('/')[1] + "-" + d.notifyEvent.lastModifiedDateTime.split(' ')[0].split('/')[2] + " " + d.notifyEvent.lastModifiedDateTime.split(' ')[1] || ''),
                                  },
                                 
                                ],
                              },
                            ]}
                            defaultPageSize={10}
                            style={{
                              width: "100%",
                              maxHeight: "500px",
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* </div>
            </div> */}
                  <div class="modal fade" id="modal-Message-Inbox" tabIndex="-1" role="dialog" aria-labelledby="modalLabel" data-keyboard="true" aria-hidden="true">
                    <div class="modal-dialog" style={{ width: "70%" }} >
                      <div class="modal-content">
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                          <span aria-hidden="true">&times;</span>
                        </button>
                        <Formik
                          enableReinitialize={true}
                          render={({
                            errors, touched
                          }) => (
                            <React.Fragment>
                              <Form className="form-group">
                                <section >
                                  <div >
                                    <div class="row">
                                      <div class="col-md-2 col-lg-2 col-sm-2 col-xs-2">
                                      </div>
                                      <div class="col-md-8 col-lg-8 col-sm-8 col-xs-8">
                                        <div class="product-add">
                                          <div class="form-group">
                                            <div class="row">
                                              <div class="col-md-8 col-lg-8 col-sm-8 col-xs-8">
                                                <label>
                                                  FROM
                                                </label>
                                                <Field
                                                  type="text"
                                                  name="FromEMailId"
                                                  id="FromEMailId"
                                                  placeholder="FromEMailId"
                                                  value={this.state.MessageDetails.fromEmailName} //emailSubjectText  //emailSMSBodyText }
                                                  className={
                                                    "form-control"
                                                  }

                                                >
                                                </Field>

                                              </div>
                                              <div class="col-md-8 col-lg-8 col-sm-8 col-xs-8">
                                                <label>
                                                  Subject
                                            </label>
                                                <Field
                                                  type="text"
                                                  name="subject"
                                                  id="subject"
                                                  autoComplete="subject"
                                                  value={this.state.MessageDetails.emailSubjectText}  //emailSMSBodyText }
                                                  className={
                                                    "form-control" +
                                                    (errors.subject &&
                                                      touched.text
                                                      ? " is-invalid"
                                                      : "")
                                                  }
                                                  placeholder="Subject"
                                                  className="form-control"
                                                />
                                                <ErrorMessage
                                                  name="subject"
                                                  component="div"
                                                  className="validation"
                                                />
                                              </div>
                                              <div class="col-md-8 col-lg-8 col-sm-8 col-xs-8">
                                                <label>
                                                  Message
                                                     </label>
                                                <Field as="textarea" rows="8" cols="50" name="message"
                                                  id="message"
                                                  autoComplete="message"
                                                  value={this.state.MessageDetails.emailSMSBodyText}
                                                  className={
                                                    "form-control"
                                                  }
                                                />
                                                <ErrorMessage
                                                  name="message"
                                                  className="validation"
                                                  component="div"
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div class="col-md-2 col-lg-2 col-sm-2 col-xs-2">
                                      </div>
                                    </div>
                                  </div>
                                </section>
                              </Form>
                            </React.Fragment>
                          )}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </section>

          </div>

          {/* <div className="composeBtn">
            <button className="btn-2" onClick={this.createMail}>Compose</button>
          </div> */}
        </div>
        <Finxfooter />
      </React.Fragment >)
  }

}

export default connect(mapStateToProps, mapDispatchToProps)(MyMessagesInbox);

