import React, { Component } from 'react';
import 'react-table/react-table.css'


class AdminDashboard extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <React.Fragment>
                <section className="dashboard-tab section-padding mt-125">
                    <div className="padding-rl-50">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-3 col-sm-6 col-xs-12">
                                    <div className="board">
                                        <div className="row">
                                            <div className="col-md-5">
                                                <div className="board-img">
                                                    <i className="fa fa-user" aria-hidden="true"></i>
                                                </div>
                                            </div>
                                            <div className="col-md-7">
                                                <div className="board-process">
                                                    <h3>38</h3>
                                                    <h4>FEAT Reg</h4>
                                                    <div className="process-badges">Pending<span className="badge pending"> 0 </span></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3 col-sm-6 col-xs-12">
                                    <div className="board">
                                        <div className="row">
                                            <div className="col-md-5">
                                                <div className="board-img">
                                                    <i className="fa fa-trophy" aria-hidden="true"></i>
                                                </div>
                                            </div>
                                            <div className="col-md-7">
                                                <div className="board-process">
                                                    <h3>12</h3>
                                                    <h4>Pending GDPI</h4>
                                                    <div className="process-badges">Completed<span className="badge completed"> 26 </span></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3 col-sm-6 col-xs-12">
                                    <div className="board">
                                        <div className="row">
                                            <div className="col-md-5">
                                                <div className="board-img">
                                                    <i className="fa fa-user" aria-hidden="true"></i>
                                                </div>
                                            </div>
                                            <div className="col-md-7 ">
                                                <div className="board-process">
                                                    <h3>22</h3>
                                                    <h4>CFX Reg</h4>
                                                    <div className="process-badges">In Progress<span className="badge inprogress"> 16 </span></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3 col-sm-6 col-xs-12">
                                    <div className="board">
                                        <div className="row">
                                            <div className="col-md-5 ">
                                                <div className="board-img">
                                                    <i className="fa fa-question" aria-hidden="true"></i>
                                                </div>
                                            </div>
                                            <div className="col-md-7 ">
                                                <div className="board-process">
                                                    <h3>48</h3>
                                                    <h4>Enquires</h4>
                                                    <div className="process-badges">Pending<span className="badge pending"> 10 </span></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="dahboard-table">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-md-6 col-sm-12 col-xs-12">
                                        <div className="table-header">
                                            <h3><i className="fa fa-user" aria-hidden="true"></i> Recent 5 Enquires
                                    <span className="right-icon"><i className="fa fa-info" aria-hidden="true"></i></span>
                                                <span className="right-icon"><i className="fa fa-pencil" aria-hidden="true"></i></span>
                                            </h3>
                                        </div>
                                        <div className="bg-box">
                                            <div className="table-responsive">
                                                <table className="user-table">
                                                    <tbody>
                                                        <tr>
                                                            <th>First Name</th>
                                                            <th>Last Name</th>
                                                            <th>Active Till</th>
                                                        </tr>
                                                        <tr>
                                                            <td>Sachin</td>
                                                            <td>Deo</td>
                                                            <td>31 March 2020</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Sachin</td>
                                                            <td>Deo</td>
                                                            <td>31 March 2020</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Sachin</td>
                                                            <td>Deo</td>
                                                            <td>31 March 2020</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Sachin</td>
                                                            <td>Deo</td>
                                                            <td>31 March 2020</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-sm-12 col-xs-12">
                                        <div className="table-header">
                                            <h3> <i className="fa fa-book" aria-hidden="true"></i> Recent 5 Enquires <span className="right-icon"><i className="fa fa-pencil" aria-hidden="true"></i></span></h3></div>
                                        <div className="bg-box">
                                            <div className="table-responsive">
                                                <table className="user-table">
                                                    <tbody>
                                                        <tr>
                                                            <th>Suryadatta Institutes</th>
                                                            <th>Pune</th>
                                                        </tr>
                                                        <tr>
                                                            <td>Mitcon</td>
                                                            <td>Pune</td>
                                                        </tr>
                                                        <tr>
                                                            <td> Sinhagad Institute</td>
                                                            <td>Pune</td>
                                                        </tr>
                                                        <tr>
                                                            <td>JSPM College</td>
                                                            <td>Pune</td>
                                                        </tr>
                                                        <tr>
                                                            <td> SP College</td>
                                                            <td>Pune</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </React.Fragment>

        );
    }


}

export default AdminDashboard;