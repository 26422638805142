import React, { Component } from "react";
import DashboardHeader from "../../components/dashboardheader/dashboardheader";
import Finxfooter from "../finxfooter/finxfooter";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ReattemptTest from "../../components/reattempt-Test/reattemptTest";
import { LoginService } from "../../services/login.service";
import { PopUpPortal } from "../popups/PopUpPortal";
import $ from "jquery";
import { getAlertNotificationEvents, getAlertNotificationEventsPending, getAlertNotificationEventsError } from "../../reducer/alertsNotificationReducer"
import { getNotificationEvents, getNotificationEventsPending, getNotificationEventsError } from "../../reducer/notificationReducer";
import { fetchDataByProduct } from "../../services/reattemptTest.service";
import { fetchCalenderEvents, fetchAlertNotificationEvents, fetchNotificationEvents, fetchProductDetailsByPartyId, fetchProductDetailsAllIsActive, getProgressOverview } from "../../services/fetchLearnerDashboard.service";
// import { fetchProductDetailsAllIsActive, } from "../../services/fetchLearnerDashboard.service";
import { getproductDetailsAll, getproductDetailsAllPending, getproductDetailsAllError } from "../../reducer/productDetailsAllReducer";
import Unauthorized from "../../components/unauthorized/Unauthorized";

const mapStateToProps = state => (
    {
        attemptedList: state.reattemptedReducer.attemptedList,

        alertNotificationEvents: getAlertNotificationEvents(
            state.alertNotificationEventsReducer
        ),
        pendingAlertNotificationEvents: getAlertNotificationEventsPending(
            state.alertNotificationEventsReducer
        ),
        errorAlertNotificationEvents: getAlertNotificationEventsError(
            state.alertNotificationEventsReducer
        ),

        notificationEvents: getNotificationEvents(state.notificationEventsReducer),
        pendingNotificationEvents: getNotificationEventsPending(
            state.notificationEventsReducer
        ),
        errorNotificationEvents: getNotificationEventsError(
            state.notificationEventsReducer
        ),
        productDetailsAll: getproductDetailsAll(state.productDetailsAllReducer),
        pendingproductDetailsAll: getproductDetailsAllPending(state.productDetailsAllReducer),
        errorproductDetailsAll: getproductDetailsAllError(state.productDetailsAllReducer),

    });

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            fetchProductDetailsAllIsActive: fetchProductDetailsAllIsActive,
            fetchDataByProduct: fetchDataByProduct,
            fetchAlertNotificationEvents: fetchAlertNotificationEvents,
            fetchNotificationEvents: fetchNotificationEvents,

        },
        dispatch
    );

const connectFunction = connect(mapStateToProps, mapDispatchToProps);

const distributorDashboardAuthenticate = connectFunction(
    class extends Component {
        constructor(props) {
            super(props);
            this.state = {
                loading: true
            };
        }

        componentDidMount() {
            let RoleId = localStorage.getItem('roleId')
            let partyID = localStorage.getItem("userid-Token");
            const { fetchNotificationEvents,
                fetchDataByProduct, fetchProductDetailsAllIsActive } = this.props;
            LoginService.TokenUser(res => {
                if (res.status === "success") {
                    localStorage.setItem("jwt-token", res.responseObject);
                    fetchProductDetailsAllIsActive();
                    //  fetchDataByProduct("firstName=&lastName=&fromDate=&toDate=&   emailId=&panNumber=&cityId=0");
                    // fetchDataByProduct("productID=187422");
					fetchNotificationEvents("partyId=" + partyID);
                    fetchDataByProduct("productID=187422&firstName=&lastName=&fromDate=&toDate=&emailId=&score=0&Quatlets=&learnerPartyID=0&learnerID=0");
                }
            });
        }

        render() {

            const { attemptedList, pending, fetchUserAddressData,
                alertNotificationEvents,
                pendingAlertNotificationEvents,
                notificationEvents,
                pendingNotificationEvents,
                pendingUserRoleAccess,
                productDetailsAll
            } = this.props;



            localStorage.setItem("PersonnelData", JSON.stringify(this.props.personnelData))
            localStorage.setItem('AdminAlertNotification', JSON.stringify(alertNotificationEvents))
            //localStorage.setItem('AdminNotification', JSON.stringify(notificationEvents))

            let __FOUNDENCROACH;
            if (!pendingNotificationEvents && !pendingAlertNotificationEvents) {
                return (
                    <React.Fragment>
                        {/* {localStorage.getItem("roleId") == 6 ? */}
                        <React.Fragment>
                            <DashboardHeader
                                alertNotificationEvents={this.props.alertNotificationEvents}
                                notificationEvents={this.props.notificationEvents}
                                adminUser={attemptedList}
                                //adminProfileUser={this.props.adminProfileUser}
                                {...this.props}

                            />
                            <ReattemptTest
                                attemptedList={attemptedList}
                                getAdminAddress={fetchUserAddressData}
                                fetchDataByProduct={fetchDataByProduct}
                                productDetailsAll={productDetailsAll}
                                userRoleAccess={this.props.userRoleAccess}
                                {...this.props}
                            />



                            <Finxfooter />
                        </React.Fragment>
                        {/* // :
                            // <Unauthorized />
                    // } */}
                    </React.Fragment>
                );
            } else {
                return (<React.Fragment>
                    <DashboardHeader
                        adminProfileUser={this.props.pending}
                        {...this.props}

                    />
                </React.Fragment>)
            }
        }
    }
);

export default distributorDashboardAuthenticate;
