import React from 'react';
import DashboardHeader from "../dashboardheader/dashboardheader";
import Finxfooter from "../../containers/finxfooter/finxfooter";
import { FetchNotificationService } from "../../services/fetchnotification";
import { fetchNotificationEvents } from '../../services/fetchLearnerDashboard.service';
import { getNotificationEvents, getNotificationEventsError, getNotificationEventsPending } from '../../reducer/notificationReducer';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import $ from "jquery";
import * as Yup from "yup";
import { Formik, Field, Form, ErrorMessage, yupToFormErrors } from "formik";
import { GeneralEnquiryService } from "../../services/generalenquiryService";
import { success, successNotification, error, errorNotification, warning, warningNotification } from "../notification/notifications";
import { fetchEmailBasedonRole } from '../../services/RoleBasedEmail.service';
import { getOtherUserEmailDetails, getOtherUserEmailDetailsError, getOtherUserEmailDetailsPending } from '../../reducer/OtherRoleAccessEMailReducer';
import Select, { createFilter } from 'react-select';
import FileBase64 from "react-file-base64";
import { fetchUserRoleAccess } from '../../services/userAccess.service';
import { getUserRoleAcess } from '../../reducer/roleAccessReducer';
import ReactTable from "react-table";
import "react-table/react-table.css";
import { PopUpPortal } from "../../containers/popups/PopUpPortal";
import { Multiselect } from 'multiselect-react-dropdown';
import RichTextEditor from 'react-rte';
import ReactQuill from 'react-quill'; // ES6
import 'react-quill/dist/quill.snow.css'; // ES6
import { RegisterUserService } from '../../services/registration.service';
import { Global_var } from '../../global/global_var';

const mapStateToProps = state => ({
  notificationEvents: getNotificationEvents(state.notificationEventsReducer),
  pendingNotificationEvents: getNotificationEventsPending(
    state.notificationEventsReducer
  ),
  errorNotificationEvents: getNotificationEventsError(
    state.notificationEventsReducer
  ),

  otherUserEmailDetails: getOtherUserEmailDetails(state.OtherRoleAccessEMailReducer),
  pendingOtherUserEmail: getOtherUserEmailDetailsPending(state.OtherRoleAccessEMailReducer),
  errorOtherUserEmail: getOtherUserEmailDetailsError(state.OtherRoleAccessEMailReducer),
  userRoleAccess: getUserRoleAcess(state.roleAccessReducer)
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      //fetchNotificationEvents: fetchNotificationEvents,
      fetchEmailBasedonRole: fetchEmailBasedonRole,
      fetchUserRoleAccess: fetchUserRoleAccess,
    },
    dispatch
  );

const connectFunction = connect(mapStateToProps, mapDispatchToProps);

const MyMessagesCreate = connectFunction(
  class MyMessagesCreate extends React.Component {

    constructor(props) {
      super(props);
      this.state = {
       // notification: this.props.history.location.state !== undefined ? this.props.history.location.state.notification : JSON.parse(localStorage.getItem('AdminNotification')),
        alertid: this.props.history.location.state !== undefined ? this.props.location.state.alertid : 0,

        value: RichTextEditor.createEmptyValue(),
        value1: RichTextEditor.createEmptyValue(),
        dropdownFlag: false,
        eMailList: [],
        selectedToEmail: '',
        userRoleAccess: [],
        optionSelected: null,
        intervalId: 0,
        isToEmailPresent: props.history.location.state.learnerDetails !== undefined ? 1 : 0,
        learnerDetails: props.history.location.state.learnerDetails,

        listOfYear1: [],
        listOfMonth: [],
        listOfDay: [],
        distributorListData: [],
        composeEmail: false,
        composeSms: false,
        stage: [],
        listMaxRange: [],
        listMinRange: [],
        payment: '',
        minRange: '',
        maxRange: '',
        quartile: '',
        nismResult: '',
        options: [],
        selected: {},
        selectAll: 0,
        data_checked: [],
        SMSTemplate: [],
        emailTemplate: [],
        SMSTemplate1: [],
        emailTemplate1: [],
        isData: false,
        document: [],
        document1: [],
        fromDay: "",
        fromMonth: "",
        fromYear: "",
        toDay: "",
        toMonth: "",
        toYear: "",
        fromDate: "",
        toDate: "",
        docName: "",
        SMSmessage: "",
        value2: "",
        data_checkedlen: 0,
        updateId: "",
        selectedValue: [],
        editorHtml: '', theme: 'snow',
        docURL: ""
      }
      this.handleChange = this.handleChange.bind(this)
    }
    onScrollStep = () => {
      if (window.pageYOffset === 0) {
        clearInterval(this.state.intervalId);
      }
      window.scroll(0, window.pageYOffset - this.props.scrollStepInPx);
    }

    scrollToTop = () => {
      let intervalId = setInterval(this.onScrollStep, this.props.delayInMs);
      this.setState({ intervalId: intervalId });
    }

    componentDidMount() {
      if(this.props.history.location.state !== undefined){
        this.setState({
            notification: this.props.history.location.state.notification
          })
    } else {
        let partyID = localStorage.getItem("ciel-userid-Token");
        FetchNotificationService.fetchNotification("partyId=" + partyID, (res1) => {
        this.setState({
             notification: res1.responseListObject
        })
     })
    }
      //alert(this.state.alertid)
      let partyID = localStorage.getItem("ciel-userid-Token");
      FetchNotificationService.fetchNotificationSent("senderPartyId=" + partyID, (res) => {
        this.setState({
          dataSent: res.responseListObject
        })
      })

      FetchNotificationService.getStageList((res) => {
        let options = []
        for (let i = 0; i < res.responseListObject.length; i++) {
          let obj = {}
          obj = { "name": res.responseListObject[i].applicationStatus, "id": res.responseListObject[i].applicationStatus }
          options.push(obj)
        }
        this.setState({
          options: options
        })
      })

      FetchNotificationService.fetchNotificationDarftById("draftId=" + this.state.alertid, (res2) => {
        let op1 = []
        // let stage = []
        let selected = []

        let stage = res2.responseObject.stage.split(",")
        //stage.push(res2.responseObject.learnerdata[0].currentStage)

        for (let i = 0; i < stage.length; i++) {
          let obj1 = { "name": stage[i], "id": stage[i] }
          op1.push(obj1);
        }
        const newSelected = Object.assign({}, this.state.selected);

        res2.responseObject.learnerdata.map(e => {
          let obj = {}
          //  alert(e.learnerPartyID.toString())
          let id = e.learnerPartyID
          // console.log(id)
          obj[id] = true
          newSelected[e.learnerPartyID] = true
          //selected.push(obj)
        }
        )

        //   selected.push(obj2)
        let data_checkedObj = []
        let data_checkedObj1 = { "emailID": res2.responseObject.email_to, "mobileNo": res2.responseObject.sms_to }
        data_checkedObj.push(data_checkedObj1);
        if (res2.responseObject.learnerdata[0].currentStage) {
          let data = {
            "stage": stage,
            "deliveryChannel": ["email"]
          }
          FetchNotificationService.getTemplate(data, (res) => {
            let temp = []
            for (let i = 0; i < res.responseListObject.length; i++) {

              let obj = {}
              obj["notifyTemplateName"] = res.responseListObject[i].notifyTemplateId
              obj["notifyTemplateBody"] = res.responseListObject[i].notifyTemplateXml
              obj["notifyTemplateSubject"] = res.responseListObject[i].notifyTemplateName
              temp.push(obj)
            }


            this.setState({
              emailTemplate: res.responseListObject,
              emailTemplate1: temp,
              editorHtml: res.responseListObject[0].notifyTemplateXml
            })
          })
          let data1 = {
            "stage": stage,
            "deliveryChannel": ["sms"]
          }

          FetchNotificationService.getTemplate(data1, (res) => {
            let temp1 = []

            for (let i = 0; i < res.responseListObject.length; i++) {

              let obj1 = {}
              obj1["notifyTemplateName"] = res.responseListObject[i].notifyTemplateId
              obj1["notifyTemplateBody"] = res.responseListObject[i].notifyTemplateXml
              temp1.push(obj1)
            }

            this.setState({
              SMSTemplate: res.responseListObject,
              SMSTemplate1: temp1,
            })
          })
        }
        let document = []
        let document1 = []
        // RegisterUserService.downloadDocumet("documentId=" + res2.responseObject.documentId1 + "&bucketTag=" + Global_var.CONST_BUCKET_NAME, res3 => {
        //   let url = (this.replaceAll(this.replaceAll(this.replaceAll(res3.responseObject.documentUrl ? (
        //     res3.responseObject.documentUrl.split("?")[0]).substring(
        //       (res3.responseObject.documentUrl || "").lastIndexOf(
        //         "/"
        //       ) + 1)

        //     : '', "%29", ""), "%28", ""), "%20", ""))
        //   // alert(url)


        //   let obj = {
        //     documentName: url
        //   }
        //   document.push(obj)
        //   this.setState({
        //     document: document
        //   })


        // })
        RegisterUserService.downloadDocumet("documentId=" + res2.responseObject.documentId1 + "&bucketTag=" + Global_var.CONST_BUCKET_NAME, res3 => {
          let url = (this.replaceAll(this.replaceAll(this.replaceAll(res3.responseObject.documentUrl ? (
            res3.responseObject.documentUrl.split("?")[0]).substring(
              (res3.responseObject.documentUrl || "").lastIndexOf(
                "/"
              ) + 1)

            : '', "%29", ""), "%28", ""), "%20", ""))
          // alert(url)


          let obj = {
            documentName: url,
            //docid:
          }
          let objDoc = {
            documentHash: ""
            ,
            documentType: "document1",
            documentName: url,
            documentId: res2.responseObject.documentId1

          }
          document.push(objDoc)
          document1.push(objDoc)
          this.setState({
            document1: document1,
            document: document
          })


        })
        RegisterUserService.downloadDocumet("documentId=" + res2.responseObject.documentId2 + "&bucketTag=" + Global_var.CONST_BUCKET_NAME, res3 => {
          let url = (this.replaceAll(this.replaceAll(this.replaceAll(res3.responseObject.documentUrl ? (
            res3.responseObject.documentUrl.split("?")[0]).substring(
              (res3.responseObject.documentUrl || "").lastIndexOf(
                "/"
              ) + 1)

            : '', "%29", ""), "%28", ""), "%20", ""))
          // alert(url)


          let obj = {
            documentName: url
          }
          let objDoc = {
            documentHash: ""
            ,
            documentType: "document1",
            documentName: url,
            documentId: res2.responseObject.documentId1

          }
          document.push(objDoc)
          document1.push(objDoc)
          this.setState({
            document1: document1,
            document: document
          })


        })
        RegisterUserService.downloadDocumet("documentId=" + res2.responseObject.documentId3 + "&bucketTag=" + Global_var.CONST_BUCKET_NAME, res3 => {
          let url = (this.replaceAll(this.replaceAll(this.replaceAll(res3.responseObject.documentUrl ? (
            res3.responseObject.documentUrl.split("?")[0]).substring(
              (res3.responseObject.documentUrl || "").lastIndexOf(
                "/"
              ) + 1)

            : '', "%29", ""), "%28", ""), "%20", ""))
          // alert(url)


          let obj = {
            documentName: url
          }
          let objDoc = {
            documentHash: ""
            ,
            documentType: "document1",
            documentName: url,
            documentId: res2.responseObject.documentId1

          }
          document.push(objDoc)
          document1.push(objDoc)
          this.setState({
            document1: document1,
            document: document
          })


        })
        RegisterUserService.downloadDocumet("documentId=" + res2.responseObject.documentId4 + "&bucketTag=" + Global_var.CONST_BUCKET_NAME, res3 => {
          let url = (this.replaceAll(this.replaceAll(this.replaceAll(res3.responseObject.documentUrl ? (
            res3.responseObject.documentUrl.split("?")[0]).substring(
              (res3.responseObject.documentUrl || "").lastIndexOf(
                "/"
              ) + 1)

            : '', "%29", ""), "%28", ""), "%20", ""))
          // alert(url)


          let obj = {
            documentName1: url
          }
          let objDoc = {
            documentHash: ""
            ,
            documentType: "document1",
            documentName: url,
            documentId: res2.responseObject.documentId1

          }
          document.push(objDoc)
          document1.push(objDoc)
          this.setState({
            document1: document1,
            document: document
          })


        })
        RegisterUserService.downloadDocumet("documentId=" + res2.responseObject.documentId5 + "&bucketTag=" + Global_var.CONST_BUCKET_NAME, res3 => {
          let url = (this.replaceAll(this.replaceAll(this.replaceAll(res3.responseObject.documentUrl ? (
            res3.responseObject.documentUrl.split("?")[0]).substring(
              (res3.responseObject.documentUrl || "").lastIndexOf(
                "/"
              ) + 1)

            : '', "%29", ""), "%28", ""), "%20", ""))
          // alert(url)


          let obj = {
            documentName: url
          }
          let objDoc = {
            documentHash: ""
            ,
            documentType: "document1",
            documentName: url,
            documentId: res2.responseObject.documentId1

          }
          document.push(objDoc)
          document1.push(objDoc)
          this.setState({
            document1: document1,
            document: document
          })


        })
        

        let fronDate = res2.responseObject.filtersdata.fromDate
        let toDate = res2.responseObject.filtersdata.toDate
        let fromDay = fronDate && fronDate.split("-")[2]
        let fromMonth = fronDate && fronDate.split("-")[1]
        let fromYear = fronDate && fronDate.split("-")[0]
        let toDay = toDate && toDate.split("-")[2]
        let toMonth = toDate && toDate.split("-")[1]
        let toYear = toDate && toDate.split("-")[0]
        //documentId1
        this.setState({
          notify_sms_template_id: res2.responseObject.notify_sms_template_id,
          sms_body_text: res2.responseObject.sms_body_text,

          selected: newSelected,
          fromDay: fromDay,
          fromMonth: fromMonth,
          fromYear: fromYear,
          toDay: toDay,
          toMonth: toMonth,
          toYear: toYear,
          fromDate: res2.responseObject.filtersdata.fromDate,
          toDate: res2.responseObject.filtersdata.toDate,
          minRange: res2.responseObject.filtersdata.minScore,
          maxRange: res2.responseObject.filtersdata.maxScore,
          quartile: res2.responseObject.filtersdata.quartile,
          nismResult: res2.responseObject.filtersdata.nismResult,
          payment: res2.responseObject.filtersdata.paymentStatus,
          ToEMailId: res2.responseObject.email_to,
          ccemailId: res2.responseObject.email_cc,
          Emailsubject: res2.responseObject.email_subject,
          email_body_text: res2.responseObject.email_body_text,
          SMSToEMailId: res2.responseObject.sms_to,
          message: res2.responseObject.sms_body_text,
          notify_email_template_id: res2.responseObject.notify_email_template_id,
          composeEmail: res2.responseObject.chk_email === 1 ? true : false,
          composeSms: res2.responseObject.chk_sms === 1 ? true : false,
          distributorListData: res2.responseObject.learnerdata,
          selectedValue: op1,
          data_checked: res2.responseObject.learnerdata,
          stage: stage,
          editorHtml: res2.responseObject.email_body_text,
          SMSmessage: res2.responseObject.sms_body_text,
          data_checkedlen: res2.responseObject.learnerdata.length != 1 ? res2.responseObject.learnerdata.length - 1 : 0
          // value: "aaaaaaaaaaaaaa"
          //  notify_email_template_id: res2.responseObject.notify_email_template_id,

          // docName:"ABC"

        })
        


        console.log("***********************************************this.state", this.state)
        console.log(this.state.selected["265550"])

      })

      // let distributeData = {
      //   stage: [],
      //   fromDate: '',
      //   toDate: '',
      //   minRange: '',
      //   maxRange: '',
      //   payment: '',
      //   quartile: '',
      // }
      // FetchNotificationService.fetchDistributorListData(distributeData, res => {
      //   if (res.status === "success") {
      //     this.setState({
      //       distributorListData: res.responseListObject
      //     })
      //   }
      // });

      this.state = {
        eMailList: []
      }
      const roleDetails1 = {
        roleId: {
          roleId: localStorage.getItem('roleId')
        }
      }
      this.props.fetchUserRoleAccess(roleDetails1);

      var listMinRange0 = [];
      for (var i = 0; i <= 100; i++) {
        listMinRange0.push(i);
      }

      var listMaxRange0 = [];
      for (var i = 0; i <= 100; i++) {
        listMaxRange0.push(i);
      }

      var year = new Date().getFullYear();
      year = year
      var listOfYear = [];
      for (var i = 0; i < 100; i++) {
        var current = year - i;
        listOfYear.push(current);

      }
      var listOfMonth = [];

      var listOfMonth = [{ "key": 1, "value": "Jan" },
      { "key": 2, "value": "Feb" },
      { "key": 3, "value": "Mar" },
      { "key": 4, "value": "Apr" },
      { "key": 5, "value": "May" },
      { "key": 6, "value": "Jun" },
      { "key": 7, "value": "Jul" },
      { "key": 8, "value": "Aug" },
      { "key": 9, "value": "Sep" },
      { "key": 10, "value": "Oct" },
      { "key": 11, "value": "Nov" },
      { "key": 12, "value": "Dec" },
      ];
      var listOfDay = [];
      for (var i = 1; i <= 31; i++) {
        listOfDay.push(i);
      }
      this.setState({ listOfMonth: listOfMonth });
      this.setState({ listOfYear1: listOfYear });
      this.setState({ listOfDay: listOfDay });
      this.setState({ listMaxRange: listMaxRange0 })
      this.setState({ listMinRange: listMinRange0 })

    }

    dropdowndataset = () => {
      // dropdowndataset
      const data = this.props.otherUserEmailDetails
    }

    TomailSelect = (data) => {
      const selectedValue = data.value
      this.setState({
        selectedToEmail: selectedValue
      })

    }

    selectRole = (roleID) => {

      this.setState({ selectedToEmail: " " })
      let roleDetails = {
        roleId: parseInt(roleID.target.value)
      }

      this.setState({
        dropdownFlag: true,
        optionSelected: roleID.target.value,
        eMailList: this.props.fetchEmailBasedonRole(roleDetails)
      });

      //this.dropdowndataset()
    }

    onChangeParameter(selected, setFieldValue) {
      setFieldValue('ToEMailId', selected)
      //  this.props.getDevices(selected.value)
    }

    handleCancel = () => {
      this.props.history.goBack("-1");
      //window.$("#gdpi-score").modal('hide');
      // window.location.reload();
    };
    replaceAll(str, find, replace) {
      var escapedFind = find.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "\\$1");
      // alert(str.replace(new RegExp(escapedFind, 'g'), replace))
      return str.replace(new RegExp(escapedFind, 'g'), replace);
    }
    toggleRow(original) {
      
      let data = this.state.data_checked
      console.log(data)
      let data1 = []
      let obj = {}
      let flag = true
      let isData = this.state.isData

      const newSelected = Object.assign({}, this.state.selected);
      newSelected[original.learnerPartyID] = !this.state.selected[original.learnerPartyID];

      this.state.data_checked.map((e, i) => {
        if (e.learnerPartyID == original.learnerPartyID) {
          data.splice(i, 1);
          flag = false;
          if (data.length == 0) {
            isData = true
          }
        }
      })
      if (data.length > 0 && isData == false) {
        this.setState({
          isData: false,
        });

        if (flag == true) {
          obj['emailID'] = original.emailID
          obj['mobileNo'] = original.mobileNo
          obj['firstName'] = original.firstName
          obj['currentStage'] = original.currentStage
          obj['learnerPartyID'] = original.learnerPartyID
          data1.push(obj)
        }
      }
      else if (flag == true) {
        obj['emailID'] = original.emailID
        obj['mobileNo'] = original.mobileNo
        obj['firstName'] = original.firstName
        obj['currentStage'] = original.currentStage
        obj['learnerPartyID'] = original.learnerPartyID
        data1.push(obj)
      }

      let d1 = data.concat(data1)
      console.log("newSelected", newSelected)
      this.setState({
        selected: newSelected,
        data_checked: d1,
        selectAll: 2,
        data_checkedlen: d1.length != 1 ? d1.length - 1 : 0


      });
    }

    toggleSelectAll() {
      let newSelected = {};
      let data = []
      var AllData = []
      if (this.state.selectAll === 0) {
        this.state.distributorListData.forEach(x => {
          let obj = {}
          newSelected[x.learnerPartyID] = true;
          obj['emailID'] = x.emailID
          obj['mobileNo'] = x.mobileNo
          obj['firstName'] = x.firstName
          obj['learnerPartyID'] = x.learnerPartyID
          obj['currentStage'] = x.currentStage
          data.push(obj)
          AllData.push(x)
          console.log("AllData::", AllData)

        });
      }
      //alert(data.length-1)
      this.setState({




        selected: newSelected,
        selectAll: this.state.selectAll === 0 ? 1 : 0,
        data_checked: data,
        data_checkedlen: data.length - 1
      });
      // console.log("this.state.toggleAll", this.state.toggleAll)
    }



    onSelect = (selectedList, selectedItem) => {
      
      let stageName = []
      for (let i = 0; i < selectedList.length; i++) {
        stageName.push(selectedList[i].name)
      }
      this.setState({ stage: stageName });
      let data = {
        "stage": stageName,
        "deliveryChannel": ["email"]
      }

      FetchNotificationService.getTemplate(data, (res) => {
        let temp = []
        if (res.responseListObject !== null) {
          for (let i = 0; i < res.responseListObject.length; i++) {

            let obj = {}
            obj["notifyTemplateName"] = res.responseListObject[i].notifyTemplateId
            obj["notifyTemplateBody"] = res.responseListObject[i].notifyTemplateXml
            obj["notifyTemplateSubject"] = res.responseListObject[i].notifyTemplateName
            temp.push(obj)
          }


          this.setState({
            emailTemplate: res.responseListObject,
            emailTemplate1: temp,
            //  editorHtml: res.responseListObject[0].notifyTemplateXml,
          })
        }
        else {
          this.setState({
            emailTemplate: [],
            emailTemplate1: [],
            editorHtml: "",
          })
        }
      })

      let data1 = {
        "stage": stageName,
        "deliveryChannel": ["sms"]
      }
      FetchNotificationService.getTemplate(data1, (res) => {
        let temp1 = []
        // let obj1 = {}
        if (res.responseListObject !== null) {
          for (let i = 0; i < res.responseListObject.length; i++) {

            let obj1 = {}
            obj1["notifyTemplateName"] = res.responseListObject[i].notifyTemplateId
            obj1["notifyTemplateBody"] = res.responseListObject[i].notifyTemplateXml
            temp1.push(obj1)
          }
          this.setState({
            SMSTemplate: res.responseListObject,
            //  editorHtml: res.responseListObject[0].notifyTemplateXml,
            SMSTemplate1: temp1
          })
        }
        else {
          this.setState({
            SMSTemplate: [],

            SMSTemplate1: []
          })
        }
      })

    }

    onRemove = (selectedList, selectedItem) => {
      

      this.setState({
        SMSTemplate: "",
        SMSTemplate1: [],
        SMSmessage: "",
        Emailsubject:"",

        editorHtml: '',
        emailTemplate:"",
        emailTemplate1:[],

        
      })
      this.setState({
        SMSTemplate: [],
        emailTemplate:[]  
      })


      let stageName = []
      for (let i = 0; i < selectedList.length; i++) {
        stageName.push(selectedList[i].name)
      }

      let data1 = {
        "stage": stageName,
        "deliveryChannel": ["email"]
      }
      FetchNotificationService.getTemplate(data1, (res) => {
        let temp1 = []
       
        for (let i = 0; i < res.responseListObject.length; i++) {

          let obj1 = {}
          obj1["notifyTemplateName"] = res.responseListObject[i].notifyTemplateId
          obj1["notifyTemplateBody"] = res.responseListObject[i].notifyTemplateXml
          obj1["notifyTemplateSubject"] = res.responseListObject[i].notifyTemplateName
          temp1.push(obj1)
        }
        this.setState({
          emailTemplate: res.responseListObject,
          //  editorHtml: res.responseListObject[0].notifyTemplateXml,
          emailTemplate1: temp1
        })
      })

      let data2 = {
        "stage": stageName,
        "deliveryChannel": ["sms"]
      }
      FetchNotificationService.getTemplate(data2, (res) => {
        let temp1 = []
       
        for (let i = 0; i < res.responseListObject.length; i++) {

          let obj1 = {}
          obj1["notifyTemplateName"] = res.responseListObject[i].notifyTemplateId
          obj1["notifyTemplateBody"] = res.responseListObject[i].notifyTemplateXml
          temp1.push(obj1)
        }
        this.setState({
          SMSTemplate: res.responseListObject,
          // editorHtml: res.responseListObject[0].notifyTemplateXml,
          SMSTemplate1: temp1,
          SMSmessage: ""
        })
      })
      this.setState({ stage: stageName });

      this.setState({
        minRange: "",
        maxRange: "",
        quartile: "",
        nismResult: "",
        payment: "",
        fromYear: "",
        fromMonth: "",
        fromDay: "",
        toYear: "",
        toMonth: "",
        toDay: ""
      })



      // setTimeout(() => {

      //   let distributeData = {
      //     stage: this.state.stage,
      //     fromDate: this.state.fromDate,
      //     toDate: this.state.toDate,
      //     minScore: this.state.minRange,
      //     maxScore: this.state.maxRange,
      //     paymentStatus: this.state.payment,
      //     quartile: this.state.quartile,
      //     nismResult:this.state.nismResult

      //   }
      //   FetchNotificationService.fetchDistributorListData(distributeData, res => {
      //     if (res.status === "success") {
      //       this.setState({
      //         distributorListData: res.responseListObject
      //       })
      //     }
      //   });
      // }, 1000);

    }


    onChange = (value) => {
      
      this.setState({ value });
      this.setState({
        value2: value.toString('html')
      })
      if (this.props.onChange) {
        // Send the changes up to the parent component as an HTML string.
        // This is here to demonstrate using `.toString()` but in a real app it
        // would be better to avoid generating a string on each change.
        this.props.onChange(
          value.toString('html')
        );
      }
    };

    onChange1 = (value1) => {
      this.setState({ value1 });
      if (this.props.onChange) {
        // Send the changes up to the parent component as an HTML string.
        // This is here to demonstrate using `.toString()` but in a real app it
        // would be better to avoid generating a string on each change.
        this.props.onChange(
          value1.toString('html')
        );
      }
    };
    isValidDate = (fromDate, toDate) => {

      if (fromDate !== "") {
        let fromDate1 = fromDate.split('-')
        if (fromDate1[1] == 2 && fromDate1[2] > 29) {
          warning("Please select valid Date", warningNotification)
          return false
        } else if (fromDate1[1] == 4 || fromDate1[1] == 6 || fromDate1[1] == 9 || fromDate1[1] == 11) {
          if (fromDate1[2] > 30) {
            warning("Please select valid Date", warningNotification)
            return false
          } else {
            return true
          }
        }
        else {
          return true
        }
      }

      if (toDate !== "") {
        let toDate1 = fromDate.split('-')
        if (toDate1[1] == 2 && toDate1[2] > 29) {
          warning("Please select valid Date", warningNotification)
          return false

        } else if (toDate1[1] == 4 || toDate1[1] == 6 || toDate1[1] == 9 || toDate1[1] == 11) {
          if (toDate1[2] > 30) {
            warning("Please select valid Date", warningNotification)
            return false
          } else {
            return true
          }
        } else {
          return true
        }
      }
    }

    calculateDate = (fromYear, fromMonth, fromDay, toYear, toMonth, toDay) => {

      fromYear = this.state.fromYear
      fromMonth = this.state.fromMonth
      fromDay = this.state.fromDay
      toYear = this.state.toYear
      toMonth = this.state.toMonth
      toDay = this.state.toDay

      let fromDate1 = ""
      if ((fromYear !== undefined && fromYear !== "" && fromYear !== "0") && (fromMonth !== undefined && fromMonth !== "" && fromMonth !== "0") && (fromDay !== undefined && fromDay !== "" && fromDay !== "0")) {
        fromDate1 = fromYear + "-" + fromMonth + "-" + fromDay
        let flag = this.isValidDate(fromDate1, "")
        this.setState({
          fromDate: fromDate1
        })
      } else {
        fromDate1 = ""

      }

      let toDate1 = ""
      if ((toYear !== undefined && toYear !== "" && toYear !== "0") && (toMonth !== undefined && toMonth !== "" && toMonth !== "0") && (toDay !== undefined && toDay !== "" && toDay !== "0")) {
        toDate1 = toYear + "-" + toMonth + "-" + toDay
        let flag = this.isValidDate("", toDate1)
        this.setState({
          toDate: toDate1
        })
      } else {
        toDate1 = ""

      }



      if (fromDate1 != "" && toDate1 != "") {
        if (fromDate1 > toDate1) {
          // warning("To Date should be greater than from date ",warningNotification)
          return false
        } else {


          // let distributeData = {
          //   stage: this.state.stage,
          //   fromDate: this.state.fromDate,
          //   toDate: this.state.toDate,
          //   minRange: this.state.minRange,
          //   maxRange: this.state.maxRange,
          //   payment: this.state.payment,
          //   quartile: this.state.quartile,

          // }
          // FetchNotificationService.fetchDistributorListData(distributeData, res => {
          //   if (res.status === "success") {
          //     this.setState({
          //       distributorListData: res.responseListObject
          //     })
          //   }
          // });

        }
      }
    }



    handleChange(html) {
      this.setState({ editorHtml: html });
    }

    handleThemeChange(newTheme) {
      if (newTheme === "core") newTheme = null;
      this.setState({ theme: newTheme })
    }

    modules = {
      toolbar: [
        [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
        [{ size: [] }],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [{ 'list': 'ordered' }, { 'list': 'bullet' },
        { 'indent': '-1' }, { 'indent': '+1' }],
        ['link', 'image', 'video'],
        ['clean']
      ],
      clipboard: {
        // toggle to add extra line breaks when pasting HTML:
        matchVisual: false,
      }
    }
    formats = [
      'header',
      'bold', 'italic', 'underline', 'strike', 'blockquote',
      'list', 'bullet', 'indent',
      'link', 'image'
    ]
    render(props) {
      var roledata = this.props.userRoleAccess
      //this.state.userRoleAccess;
      console.log(this.state.userRoleAccess)
      console.log(this.props.userRoleAccess)
      var isToEmailPresent = this.state.isToEmailPresent;
      var learnerDetails = this.state.learnerDetails;
      //  alert(this.state.data_checked.length) 
      //  alert(this.state.data_checked.length-1)
      //let userRoleAccess=this.props.userRoleAccess
      return (
        <React.Fragment>
          <DashboardHeader
            {...this.props} />

          <div >
            <Formik

              enableReinitialize
              initialValues={{
                picked: '',
                // ToEMailId: isToEmailPresent === 0 ? '' : learnerDetails.emailID,
                ToEMailId: this.state.ToEMailId,
                subject: this.state.Emailsubject,
                emailId: '',
                //  message: this.state.email_body_text,
                notifyRefRecordId: isToEmailPresent === 0 ? '' : learnerDetails.learnerPartyID,
                document: [],
                sizeofDoc: 0,
                count: 0,
                fromDate: "",
                toDate: "",
                listOfMonth: this.state.listOfMonth,
                listOfDay: this.state.listOfDay,
                listOfMonth1: this.state.listOfMonth,
                listOfDay1: this.state.listOfDay,

                SMSToEMailId: this.state.SMSToEMailId,
                // message: this.state.message,
                // day: '',
                // month: '',
                // year: '',
                // today: '',
                // tomonth: '',
                // toyear: '',
                composeEmail: false,
                composeSms: false,
                minRange: '',
                maxRange: '',
                stage: [],
                payment: [],
                quartile: [],
                nismResult: []
              }}

              onSubmit={(fields, { resetForm }) => {
                //console.log("field", fields.subject)
                let fromDate1 = ""
                if ((fields['year'] !== undefined && fields['year'] !== "") || (fields['month'] !== undefined && fields['month'] !== "") || (fields['day'] !== undefined && fields['day'] !== "")) {
                  fromDate1 = fields['year'] + "-" + fields['month'] + "-" + fields['day']
                } else {
                  fromDate1 = ""
                }

                let toDate1 = ""
                if ((fields['toyear'] !== undefined && fields['toyear'] !== "") || (fields['tomonth'] !== undefined && fields['tomonth'] !== "") || (fields['today'] !== undefined && fields['today'] !== "")) {
                  toDate1 = fields['toyear'] + "-" + fields['tomonth'] + "-" + fields['today']
                } else {
                  toDate1 = ""
                }

                if (fromDate1 !== "" && toDate1 !== "") {
                  if (new Date(fromDate1) > new Date(toDate1)) {
                    warning("To Date should be greater than From Date", warningNotification)
                    fromDate1 = ""
                    toDate1 = ""
                  }
                }

                var adminPartyid = localStorage.getItem("userid-Token");
                var UserDetails = JSON.parse(localStorage.getItem('login'));
                fields.document.map((e, index) => {
                  index = index + 1
                  e.documentType = "document" + index
                })

                let data = {
                  fromEmailId: UserDetails.emailId, //"dhanaji8612@pepisandbox.com",  //
                  toEmailIdOrMobileNo: !fields.ToEMailId.value ? fields.ToEMailId : fields.ToEMailId.value, //"sardarohan04@gmail.com", //
                  emailSubjectText: fields.subject,
                  emailSMSBodyText: fields.message,
                  ccEmailId: fields.emailId,
                  bccEmailId: "",
                  notifyTemplateId: "195500",
                  notifyEventId: "195501",
                  notifyRefRecordId: !fields.ToEMailId.partyid ? fields.notifyRefRecordId : fields.ToEMailId.partyid,
                  document: fields.document,
                  isAttachment: fields.document.length > 0 ? 1 : 0,
                  attachmentCount: fields.document.length
                  //enquiryType: 2

                }

                console.log("dataemail", data)
                GeneralEnquiryService.SendMail(data, res => {
                  //console.log("res", res)
                  if (res.status === "success") {
                    success(
                      "Message has been delivered to the user successfully.",
                      successNotification
                    );
                    setTimeout(() => {
                      window.location.reload();
                    }, 3000);
                    // window.location.reload();
                    //  this.hand/userbulkuploadleCancel();
                    // $(document).ready(function () {
                    //   $("#close").click();
                    // });
                    // resetForm({});
                  }
                });
              }}

              render={({
                errors, touched, handleSubmit, values, setFieldValue
              }) => (
                <React.Fragment>

                  <PopUpPortal
                    HidePopup={this.state.loading}
                    IsVisible={this.state.loading}
                  />

                  <section class="mb-50 pt-50" id="send-message">
                    <div class="container">
                      <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 pl pr">
                        <div class="product-add">
                          <div class="form-group">
                            <div class="row">
                              <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                                <div className="AMCDistributor">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="amc"
                                    checked={true}
                                    //  value="7"
                                    //   onClick={this.selectRole}


                                    style={{ marginRight: "10px" }}
                                  />
                                  <label
                                    class="form-check-label"
                                    htmlFor="gridCheck"
                                    style={{ marginRight: "15px" }}
                                  >
                                    Distributor
                                            </label>
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="amc"
                                    // checked={true}
                                    // value="7"
                                    //   onClick={this.selectRole}
                                    onClick={
                                      e => {
                                        this.props.history.push("/my-inbox-compose", {
                                          alerts: this.props.alertNotificationEvents,
                                          notification: this.props.notificationEvents,
                                          userRoleAccess: this.props.userRoleAccess,

                                        }
                                        )
                                      }
                                    }

                                    style={{ marginRight: "10px" }}
                                  />
                                  <label
                                    class="form-check-label"
                                    htmlFor="gridCheck"
                                    style={{ marginRight: "15px" }}
                                  >
                                    AMC
                                            </label>
                                </div>
                              </div>
                              {/* <div className="scr-board"> */}
                              <div className="col-md-6 col-lg-6 col-sm-6 col-xs-12">
                                <label>
                                  Stage
                        </label>
                                <Multiselect
                                  options={this.state.options} // Options to display in the dropdown
                                  selectedValues={this.state.selectedValue} // Preselected value to persist in dropdown
                                  onSelect={this.onSelect} // Function will trigger on select event
                                  onRemove={this.onRemove} // Function will trigger on remove event
                                  displayValue="name" // Property name to display in the dropdown options
                                  className="form-control"
                                />

                                <ErrorMessage
                                  name="stage"
                                  component="div"
                                  className="validation"
                                />
                              </div>
                              <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12"></div>


                              {(this.state.stage.length === 1) && (this.state.stage[0] === "KYD-ARN APPLIED" || this.state.stage[0] === "KYD-ARN ACCEPTED" || this.state.stage[0] === "UNDER EMPANELMENT" || this.state.stage[0] == "EMPANELLED" || this.state.stage[0] === "REGISTERED" || this.state.stage[0] === "NISM EXAM DATE CONFIRMED" || this.state.stage[0] === "NISM CERTIFICATION COMPLETED") ?

                                // {this.state.stage.length === 0 && this.state.stage[0] === "KYD-ARN APPLIED" || this.state.stage[0] === "KYD-ARN ACCEPTED" || this.state.stage[0] === "UNDER EMPANELMENT" || this.state.stage[0] == "EMPANELLED" || this.state.stage[0] === "REGISTERED" || this.state.stage[0] === "NISM EXAM DATE CONFIRMED" ?
                                <div>
                                  <div className="col-md-4 col-lg-4 col-sm-6 col-xs-12">
                                    <label>
                                      Start Date
                                   </label>

                                    <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 pl pr">

                                      <div className="col-md-3 col-lg-3 col-sm-3 col-xs-3 pl pr">

                                        <Field
                                          as="select"
                                          name="day"
                                          id="day"
                                          // onChange={handleChange}
                                          //  disabled={this.state.isDisabled}
                                          //  disabled
                                          onChange={
                                            e => {


                                              var listOfMonth = []
                                              this.setState({
                                                fromDay: e.target.value
                                              })
                                              //setFieldValue(values.day = e.target.value)
                                              if (e.target.value == 31) {
                                                listOfMonth = [{ "key": 1, "value": "Jan" },

                                                { "key": 3, "value": "Mar" },

                                                { "key": 5, "value": "May" },

                                                { "key": 7, "value": "Jul" },
                                                { "key": 8, "value": "Aug" },

                                                { "key": 10, "value": "Oct" },

                                                { "key": 12, "value": "Dec" },
                                                ];


                                              } else if (e.target.value == 30) {
                                                listOfMonth = [

                                                  { "key": 1, "value": "Jan" },

                                                  { "key": 3, "value": "Mar" },
                                                  { "key": 4, "value": "Apr" },
                                                  { "key": 5, "value": "May" },
                                                  { "key": 6, "value": "Jun" },
                                                  { "key": 7, "value": "Jul" },
                                                  { "key": 8, "value": "Aug" },
                                                  { "key": 9, "value": "Sep" },
                                                  { "key": 10, "value": "Oct" },
                                                  { "key": 11, "value": "Nov" },
                                                  { "key": 12, "value": "Dec" },

                                                ];


                                              }
                                              else if (e.target.value == 28 || e.target.value == 29) {
                                                listOfMonth = [{ "key": 1, "value": "Jan" },
                                                { "key": 2, "value": "Feb" },
                                                { "key": 3, "value": "Mar" },
                                                { "key": 4, "value": "Apr" },
                                                { "key": 5, "value": "May" },
                                                { "key": 6, "value": "Jun" },
                                                { "key": 7, "value": "Jul" },
                                                { "key": 8, "value": "Aug" },
                                                { "key": 9, "value": "Sep" },
                                                { "key": 10, "value": "Oct" },
                                                { "key": 11, "value": "Nov" },
                                                { "key": 12, "value": "Dec" },
                                                ];
                                              } else {
                                                listOfMonth = [{ "key": 1, "value": "Jan" },
                                                { "key": 2, "value": "Feb" },
                                                { "key": 3, "value": "Mar" },
                                                { "key": 4, "value": "Apr" },
                                                { "key": 5, "value": "May" },
                                                { "key": 6, "value": "Jun" },
                                                { "key": 7, "value": "Jul" },
                                                { "key": 8, "value": "Aug" },
                                                { "key": 9, "value": "Sep" },
                                                { "key": 10, "value": "Oct" },
                                                { "key": 11, "value": "Nov" },
                                                { "key": 12, "value": "Dec" },
                                                ];

                                              }
                                              setFieldValue(values.listOfMonth = listOfMonth)
                                              // this.setState({
                                              //   listOfMonth: listOfMonth
                                              // })
                                            }}

                                          onBlur={e => {
                                            this.calculateDate(this.state.fromYear, this.state.fromMonth, e.target.value, this.state.toYear, this.state.toMonth, this.state.toDay)

                                          }}
                                          className={
                                            "form-control" +
                                            (errors.day && touched.day
                                              ? "is-invalid"
                                              : "")
                                          }
                                          className="form-control"
                                          value={this.state.fromDay}
                                        >
                                          <option value="">DD</option>
                                          {(
                                            values.listOfDay ||
                                            []
                                          ).map(d => (
                                            <option
                                              value={d}
                                            >
                                              {d}
                                            </option>
                                          ))}
                                        </Field>
                                      </div>
                                      <div className="col-md-4 col-lg-4 col-sm-4 col-xs-4 pl pr">
                                        <Field
                                          as="select"
                                          name="month"
                                          id="month"
                                          //   onChange={handleChange}
                                          //     disabled={this.state.isDisabled}
                                          //  disabled
                                          onChange={
                                            e => {

                                              this.setState({
                                                fromMonth: e.target.value
                                              })
                                              setFieldValue(values.month = e.target.value)
                                              if (e.target.value == 2) {
                                                var listOfDay = [];
                                                for (var i = 1; i <= 29; i++) {
                                                  //    var current = year - i;
                                                  listOfDay.push(i);
                                                  // this.setState({ listOfDay: listOfDay });
                                                }
                                              } else if (e.target.value == 1 || e.target.value == 3 || e.target.value == 5 || e.target.value == 7 || e.target.value == 8 || e.target.value == 10 || e.target.value == 12) {
                                                var listOfDay = [];
                                                for (var i = 1; i <= 31; i++) {
                                                  //    var current = year - i;
                                                  listOfDay.push(i);
                                                  //  this.setState({ listOfDay: listOfDay });
                                                }
                                              }
                                              else {
                                                var listOfDay = [];
                                                for (var i = 1; i <= 30; i++) {
                                                  //    var current = year - i;
                                                  listOfDay.push(i);
                                                  //  this.setState({ listOfDay: listOfDay });
                                                }
                                              }
                                              setFieldValue(values.listOfDay = listOfDay)
                                            }
                                          }
                                          onBlur={e => {
                                            this.calculateDate(this.state.fromYear, e.target.value, this.state.fromDay, this.state.toYear, this.state.toMonth, this.state.toDay)

                                          }}
                                          className={
                                            "form-control" +
                                            (errors.month && touched.month
                                              ? "is-invalid"
                                              : "")
                                          }
                                          className="form-control"
                                          value={this.state.fromMonth}
                                        >
                                          <option value="">MM</option>
                                          {(
                                            values.listOfMonth ||
                                            []
                                          ).map(m => (
                                            <option
                                              value={m.key}
                                            >
                                              {m.value}
                                            </option>
                                          ))}
                                        </Field>
                                      </div>
                                      <div className="col-md-5 col-lg-5 col-sm-5 col-xs-5 pl pr">
                                        <Field
                                          as="select"
                                          name="year"
                                          id="year"
                                          onChange={e => {
                                            this.setState({
                                              fromYear: e.target.value
                                            })
                                          }}
                                          //    disabled={this.state.isDisabled}
                                          //  disabled
                                          className={
                                            "form-control" +
                                            (errors.year && touched.year
                                              ? "is-invalid"
                                              : "")
                                          }
                                          onBlur={e => {
                                            this.calculateDate(e.target.value, this.state.fromMonth, this.state.fromDay, this.state.toYear, this.state.toMonth, this.state.toDay)

                                          }}
                                          className="form-control"
                                          value={this.state.fromYear}
                                        >
                                          <option value="">YYYY</option>
                                          {(
                                            this.state.listOfYear1 ||
                                            []
                                          ).map(year => (
                                            <option
                                              value={year}
                                            >
                                              {year}
                                            </option>
                                          ))}
                                        </Field>
                                      </div>
                                      <p className="validation">  {values.errmsg1}</p>

                                      {errors.fromDate && <div className="validation">{errors.fromDate}</div>}
                                    </div>
                                    <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 pl pr">
                                      <span class="file-size-limit">Please select Start date and End date</span>
                                    </div>
                                  </div>
                                  <div className="col-md-4 col-lg-4 col-sm-6 col-xs-12">
                                    <label>
                                      End Date
                                   </label>
                                    <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 pl pr">

                                      <div className="col-md-3 col-lg-3 col-sm-3 col-xs-3 pl pr">

                                        <Field
                                          as="select"
                                          name="today"
                                          id="today"
                                          // onChange={handleChange}
                                          //  disabled={this.state.isDisabled}
                                          //  disabled
                                          onChange={
                                            e => {

                                              this.setState({
                                                toDay: e.target.value
                                              })
                                              var listOfMonth = []
                                              setFieldValue(values.today = e.target.value)
                                              if (e.target.value == 31) {
                                                listOfMonth = [{ "key": 1, "value": "Jan" },

                                                { "key": 3, "value": "Mar" },

                                                { "key": 5, "value": "May" },

                                                { "key": 7, "value": "Jul" },
                                                { "key": 8, "value": "Aug" },

                                                { "key": 10, "value": "Oct" },

                                                { "key": 12, "value": "Dec" },
                                                ];


                                              } else if (e.target.value == 30) {
                                                listOfMonth = [

                                                  { "key": 1, "value": "Jan" },

                                                  { "key": 3, "value": "Mar" },
                                                  { "key": 4, "value": "Apr" },
                                                  { "key": 5, "value": "May" },
                                                  { "key": 6, "value": "Jun" },
                                                  { "key": 7, "value": "Jul" },
                                                  { "key": 8, "value": "Aug" },
                                                  { "key": 9, "value": "Sep" },
                                                  { "key": 10, "value": "Oct" },
                                                  { "key": 11, "value": "Nov" },
                                                  { "key": 12, "value": "Dec" },

                                                ];


                                              }
                                              else if (e.target.value == 28 || e.target.value == 29) {
                                                listOfMonth = [{ "key": 1, "value": "Jan" },
                                                { "key": 2, "value": "Feb" },
                                                { "key": 3, "value": "Mar" },
                                                { "key": 4, "value": "Apr" },
                                                { "key": 5, "value": "May" },
                                                { "key": 6, "value": "Jun" },
                                                { "key": 7, "value": "Jul" },
                                                { "key": 8, "value": "Aug" },
                                                { "key": 9, "value": "Sep" },
                                                { "key": 10, "value": "Oct" },
                                                { "key": 11, "value": "Nov" },
                                                { "key": 12, "value": "Dec" },
                                                ];
                                              } else {
                                                listOfMonth = [{ "key": 1, "value": "Jan" },
                                                { "key": 2, "value": "Feb" },
                                                { "key": 3, "value": "Mar" },
                                                { "key": 4, "value": "Apr" },
                                                { "key": 5, "value": "May" },
                                                { "key": 6, "value": "Jun" },
                                                { "key": 7, "value": "Jul" },
                                                { "key": 8, "value": "Aug" },
                                                { "key": 9, "value": "Sep" },
                                                { "key": 10, "value": "Oct" },
                                                { "key": 11, "value": "Nov" },
                                                { "key": 12, "value": "Dec" },
                                                ];

                                              }
                                              setFieldValue(values.listOfMonth1 = listOfMonth)
                                              // this.setState({
                                              //   listOfMonth: listOfMonth
                                              // })
                                            }}
                                          onBlur={e => {
                                            this.calculateDate(this.state.fromYear, this.state.fromMonth, this.state.fromDay, this.state.toYear, this.state.toMonth, e.target.value)
                                          }}
                                          className={
                                            "form-control" +
                                            (errors.today && touched.today
                                              ? "is-invalid"
                                              : "")
                                          }
                                          className="form-control"
                                          value={this.state.toDay}
                                        >
                                          <option value="">DD</option>
                                          {(
                                            values.listOfDay1 ||
                                            []
                                          ).map(d => (
                                            <option
                                              value={d}
                                            >
                                              {d}
                                            </option>
                                          ))}
                                        </Field>
                                      </div>
                                      <div className="col-md-4 col-lg-4 col-sm-4 col-xs-4 pl pr">
                                        <Field
                                          as="select"
                                          name="tomonth"
                                          id="tomonth"
                                          //   onChange={handleChange}
                                          //     disabled={this.state.isDisabled}
                                          //  disabled
                                          onChange={
                                            e => {

                                              this.setState({
                                                toMonth: e.target.value
                                              })
                                              setFieldValue(values.tomonth = e.target.value)

                                              if (e.target.value == 2) {
                                                var listOfDay = [];
                                                for (var i = 1; i <= 29; i++) {
                                                  //    var current = year - i;
                                                  listOfDay.push(i);
                                                  // this.setState({ listOfDay: listOfDay });
                                                }
                                              } else if (e.target.value == 1 || e.target.value == 3 || e.target.value == 5 || e.target.value == 7 || e.target.value == 8 || e.target.value == 10 || e.target.value == 12) {
                                                var listOfDay = [];
                                                for (var i = 1; i <= 31; i++) {
                                                  //    var current = year - i;
                                                  listOfDay.push(i);
                                                  //  this.setState({ listOfDay: listOfDay });
                                                }
                                              }
                                              else {
                                                var listOfDay = [];
                                                for (var i = 1; i <= 30; i++) {
                                                  //    var current = year - i;
                                                  listOfDay.push(i);
                                                  //  this.setState({ listOfDay: listOfDay });
                                                }
                                              }
                                              setFieldValue(values.listOfDay1 = listOfDay)
                                            }
                                          }
                                          onBlur={e => {
                                            this.calculateDate(this.state.fromYear, this.state.fromMonth, this.state.fromDay, this.state.toYear, e.target.value, this.state.toDay)
                                          }}
                                          className={
                                            "form-control" +
                                            (errors.tomonth && touched.tomonth
                                              ? "is-invalid"
                                              : "")
                                          }
                                          className="form-control"
                                          value={this.state.toMonth}
                                        >
                                          <option value="">MM</option>
                                          {(
                                            values.listOfMonth1 ||
                                            []
                                          ).map(m => (
                                            <option
                                              value={m.key}
                                            >
                                              {m.value}
                                            </option>
                                          ))}
                                        </Field>
                                      </div>
                                      <div className="col-md-5 col-lg-5 col-sm-5 col-xs-5 pl pr">
                                        <Field
                                          as="select"
                                          name="toyear"
                                          id="toyear"
                                          onChange={e => {

                                            this.setState({
                                              toYear: e.target.value
                                            })
                                          }}
                                          //    disabled={this.state.isDisabled}
                                          //  disabled
                                          className={
                                            "form-control" +
                                            (errors.toyear && touched.toyear
                                              ? "is-invalid"
                                              : "")
                                          }
                                          className="form-control"
                                          onBlur={e => {
                                            this.calculateDate(this.state.fromYear, this.state.fromMonth, this.state.fromDay, e.target.value, this.state.toMonth, this.state.toDay)
                                          }}
                                          value={this.state.toYear}
                                        >
                                          <option value="">YYYY</option>
                                          {(
                                            this.state.listOfYear1 ||
                                            []
                                          ).map(year => (
                                            <option
                                              value={year}
                                            >
                                              {year}
                                            </option>
                                          ))}
                                        </Field>
                                      </div>
                                    </div>
                                    <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 pl pr">
                                      <span class="file-size-limit">Please select Start date and End date</span>
                                    </div>
                                  </div>

                                </div>

                                : ''}
                              {/* Min & Max Range */}
                              {(this.state.stage.length === 1) && (this.state.stage[0] === "PROFILE COMPLETED" || this.state.stage[0] === "PROFILE COMPLETED" || this.state.stage[0] === "FEAT COMPLETED" || this.state.stage[0] === "NISM VA CERTIFICATION") ?

                                // {this.state.stage.length === 0 && this.state.stage[0] === "PROFILE COMPLETED" || this.state.stage[0] === "PROFILE COMPLETED" || this.state.stage[0] === "FEAT COMPLETED" || this.state.stage[0] === "NISM VA CERTIFICATION" ?
                                <div>
                                  <div className="col-md-4 col-lg-4 col-sm-6 col-xs-12">
                                    <label>
                                      Min Range
                                   </label>

                                    <Field
                                      type="Number"
                                      name="minRange"
                                      placeholder="MIN RANGE"
                                      className="form-control"
                                      value={this.state.minRange}
                                      onChange={evt => {
                                        var minVal = evt.target.value
                                        if (minVal < 0) {
                                          error("Minimum value cannot be less than 0.", errorNotification)
                                        } else {
                                          this.setState({ minRange: minVal })
                                        }
                                        setTimeout(() => {


                                          // let distributeData = {
                                          //   stage: this.state.stage,
                                          //   fromDate: this.state.fromDate,
                                          //   toDate: this.state.toDate,
                                          //   minRange: this.state.minRange,
                                          //   maxRange: this.state.maxRange,
                                          //   payment: this.state.payment,
                                          //   quartile: this.state.quartile,

                                          // }
                                          // FetchNotificationService.fetchDistributorListData(distributeData, res => {
                                          //   if (res.status === "success") {
                                          //     this.setState({
                                          //       distributorListData: res.responseListObject
                                          //     })
                                          //   }
                                          // });
                                        }, 1000);
                                      }
                                      }

                                    />
                                    {errors.minRange && <div className="validation">{errors.minRange}</div>}
                                    <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 pl pr">
                                      <span class="file-size-limit">Please enter range between 0 to 100</span>
                                    </div>
                                  </div>
                                  <div className="col-md-4 col-lg-4 col-sm-6 col-xs-12">
                                    <label>
                                      Max Range
                                   </label>

                                    <Field
                                      type="Number"
                                      name="maxRange"
                                      placeholder="MAX RANGE"
                                      value={this.state.maxRange}
                                      className="form-control"
                                      onChange={evt => {
                                        var maxVal = evt.target.value
                                        if (maxVal < 0) {
                                          error("Maximum value cannot be less than 0.", errorNotification)
                                        } else {
                                          this.setState({ maxRange: maxVal })
                                        }
                                        setTimeout(() => {

                                          //   console.log("stage payment", this.state.payment)
                                          //   console.log("stage min", this.state.minRange)
                                          //   console.log("stage max", this.state.maxRange)

                                          //   let distributeData = {
                                          //     stage: this.state.stage,
                                          //     fromDate: this.state.fromDate,
                                          //     toDate: this.state.toDate,
                                          //     minRange: this.state.minRange,
                                          //     maxRange: this.state.maxRange,
                                          //     payment: this.state.payment,
                                          //     quartile: this.state.quartile,

                                          //   }
                                          //   FetchNotificationService.fetchDistributorListData(distributeData, res => {
                                          //     if (res.status === "success") {
                                          //       this.setState({
                                          //         distributorListData: res.responseListObject
                                          //       })
                                          //     }
                                          //   });
                                        }, 1000);
                                      }
                                      }

                                    />
                                    {errors.maxRange && <div className="validation">{errors.maxRange}</div>}
                                    <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 pl pr">
                                      <span class="file-size-limit">Please enter range between 0 to 100</span>
                                    </div>
                                  </div>
                                </div>

                                : ''}

                              {/* Min & Max Range */}

                              {/* Value Dropdown */}
                              {(this.state.stage.length === 1) && (this.state.stage[0] === "NISM VA PAYMENT COMPLETED" || this.state.stage[0] === "NISM VA PAYMENT COMPLETED") ?

                                // {this.state.stage.length === 0 && this.state.stage[0] === "NISM VA PAYMENT COMPLETED" || this.state.stage[0] === "NISM VA PAYMENT COMPLETED" ?
                                <div>
                                  <div className="col-md-4 col-lg-4 col-sm-6 col-xs-12">
                                    <label>
                                      Payment
                                  </label>
                                    <Field
                                      as="select"
                                      name="payment"
                                      id="payment"
                                      autoComplete="Quartlet"
                                      className={
                                        "form-control" +
                                        (errors.Quartlet &&
                                          touched.Quartlet
                                          ? " is-invalid"
                                          : "")
                                      }
                                      onChange={evt => {
                                        // setFieldValue(
                                        //   "payment",
                                        //   [].slice
                                        //     .call(evt.target.selectedOptions)
                                        //     .map(option => option.value)
                                        // );
                                        this.setState({
                                          payment: evt.target.value
                                        })
                                        var pay = evt.target.value

                                      }}
                                      placeholder="Quartlet"
                                      value={this.state.payment}
                                    >
                                      <option value="">--Select--</option>

                                      <option value="Initiated">Payment Initiated</option>
                                      <option value="Txn Success">Payment Completed</option>
                                      <option value="Failed">Payment Failed</option>

                                    </Field>                                              </div>
                                  <p className="validation">  {values.errmsg1}</p>

                                  {errors.payment && <div className="validation">{errors.payment}</div>}

                                </div>

                                : ''}

                              {/* Payment */}

                              {/* <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12"></div> */}

                              {/* Quartile Dropdown */}
                              {(this.state.stage.length === 1) && (this.state.stage[0] === "FEAT COMPLETED" || this.state.stage[0] === "FEAT COMPLETED") ?

                                // {this.state.stage.length === 0 && this.state.stage[0] === "FEAT COMPLETED" || this.state.stage[0] === "FEAT COMPLETED" ?
                                <div>
                                  <div className="col-md-4 col-lg-4 col-sm-6 col-xs-12">
                                    <label>
                                      Quartile
                                  </label>
                                    <Field
                                      as="select"
                                      name="quartile"
                                      id="quartile"
                                      autoComplete="Quartlet"
                                      className={
                                        "form-control" +
                                        (errors.Quartlet &&
                                          touched.Quartlet
                                          ? " is-invalid"
                                          : "")
                                      }
                                      onChange={evt => {
                                        // setFieldValue(
                                        //   "quartile",
                                        //   [].slice
                                        //     .call(evt.target.selectedOptions)
                                        //     .map(option => option.value)
                                        // );
                                        var quartileVal = evt.target.value
                                        setTimeout(() => {
                                          this.setState({ quartile: quartileVal })
                                          console.log("stage quartile", this.state.quartile)

                                          // let distributeData = {
                                          //   stage: this.state.stage,
                                          //   fromDate: this.state.fromDate,
                                          //   toDate: this.state.toDate,
                                          //   minRange: this.state.minRange,
                                          //   maxRange: this.state.maxRange,
                                          //   payment: this.state.payment,
                                          //   quartile: this.state.quartile,

                                          // }
                                          // FetchNotificationService.fetchDistributorListData(distributeData, res => {
                                          //   if (res.status === "success") {
                                          //     this.setState({
                                          //       distributorListData: res.responseListObject
                                          //     })
                                          //   }
                                          // });
                                        }, 1000);
                                      }
                                      }
                                      placeholder="Quartlet"
                                      value={this.state.quartile}
                                    >
                                      <option value="">--Select--</option>

                                      <option value="Q1">Q1</option>
                                      <option value="Q2">Q2</option>
                                      <option value="Q3">Q3</option>
                                      <option value="Q4">Q4</option>
                                    </Field>
                                    {errors.quartile && <div className="validation">{errors.quartile}</div>}
                                    <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 pl pr">
                                      <span class="file-size-limit">Please select Quartile</span>
                                    </div>
                                    <p className="validation">  {values.errmsg1}</p>
                                  </div>
                                </div>

                                : ''}

                              {/* pass/Fail */}
                              {(this.state.stage.length === 1) && (this.state.stage[0] === "NISM CERTIFICATION COMPLETED") ?

                                // {this.state.stage.length === 0 && this.state.stage[0] === "FEAT COMPLETED" || this.state.stage[0] === "FEAT COMPLETED" ?
                                <div>
                                  {/* <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12"></div> */}

                                  <div className="col-md-4 col-lg-4 col-sm-6 col-xs-12">
                                    <label>
                                      Result
  </label>
                                    <Field
                                      as="select"
                                      name="nismResult"
                                      id="nismResult"
                                      autoComplete="Quartlet"
                                      className={
                                        "form-control" +
                                        (errors.Quartlet &&
                                          touched.Quartlet
                                          ? " is-invalid"
                                          : "")
                                      }
                                      onChange={evt => {
                                        var nismResultVal = evt.target.value
                                        setTimeout(() => {
                                          this.setState({ nismResult: nismResultVal })
                                          console.log("stage nismResult", this.state.nismResult)
                                        }, 1000);
                                      }
                                      }
                                      placeholder="Quartlet"
                                      value={this.state.nismResult}
                                    >
                                      <option value="">--Select--</option>

                                      <option value="Pass">Pass</option>
                                      <option value="Fail">Fail</option>

                                    </Field>                                              </div>
                                  <p className="validation">  {values.errmsg1}</p>

                                  {errors.nismResult && <div className="validation">{errors.nismResult}</div>}
                                </div>

                                : ''}

                              {/* PAss/Fail */}

                              <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12"></div>
                              <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                                <button
                                  type="submit"
                                  class="btn-4 pull-right"
                                  onClick={async () => {
                                    

                                    let distributeData = {
                                      stage: this.state.stage,
                                      fromDate: this.state.fromDate,
                                      toDate: this.state.toDate,
                                      minScore: Number(this.state.minRange),
                                      maxScore: Number(this.state.maxRange),
                                      paymentStatus: this.state.payment,
                                      quartile: this.state.quartile,
                                      nismResult: this.state.nismResult,
                                    }
                                    FetchNotificationService.fetchDistributorListData(distributeData, res => {
                                      console.log("distrData", res.responseListObject)
                                      if (res.status === "success") {
                                        this.setState({
                                          distributorListData: res.responseListObject
                                        })
                                      }
                                    });
                                    if ((this.state.minRange !== undefined && this.state.minRange !== "") && (this.state.maxRange !== undefined && this.state.maxRange !== "")) {
                                      if (parseInt(this.state.minRange) > parseInt(this.state.maxRange)) {
                                        this.setState({
                                          minRange: "",
                                          maxRange: ""
                                        })
                                        warning("Min range should not be greater than max range", warningNotification);

                                      }
                                      else {
                                        let distributeData = {
                                          stage: this.state.stage,
                                          fromDate: this.state.fromDate,
                                          toDate: this.state.toDate,
                                          minScore: Number(this.state.minRange),
                                          maxScore: Number(this.state.maxRange),
                                          paymentStatus: this.state.payment,
                                          quartile: this.state.quartile,
                                          nismResult: this.state.nismResult,
                                        }
                                        FetchNotificationService.fetchDistributorListData(distributeData, res => {
                                          if (res.status === "success") {
                                            this.setState({
                                              distributorListData: res.responseListObject
                                            })
                                          }
                                        });
                                      }
                                    }


                                    if (this.state.stage[0] === "PROFILE COMPLETED" || this.state.stage[0] === "PROFILE COMPLETED" || this.state.stage[0] === "NISM VA CERTIFICATION") {
                                      if (this.state.maxRange > 100) {
                                        this.setState({
                                          minRange: "",
                                          maxRange: ""
                                        })
                                        warning("Max range cannot be greater than 100", warningNotification)
                                      } else {
                                        let distributeData = {
                                          stage: this.state.stage,
                                          fromDate: this.state.fromDate,
                                          toDate: this.state.toDate,
                                          minScore: Number(this.state.minRange),
                                          maxScore: Number(this.state.maxRange),
                                          paymentStatus: this.state.payment,
                                          quartile: this.state.quartile,
                                          nismResult: this.state.nismResult,
                                        }
                                        FetchNotificationService.fetchDistributorListData(distributeData, res => {
                                          if (res.status === "success") {
                                            this.setState({
                                              distributorListData: res.responseListObject
                                            })
                                          }
                                        });
                                      }
                                    }


                                    if (this.state.stage[0] === "FEAT COMPLETED") {
                                      if (this.state.maxRange > 90) {
                                        this.setState({
                                          minRange: "",
                                          maxRange: ""
                                        })
                                        warning("Feat completed max range cannot be greater than 90", warningNotification)
                                      } else {
                                        let distributeData = {
                                          stage: this.state.stage,
                                          fromDate: this.state.fromDate,
                                          toDate: this.state.toDate,
                                          minScore: Number(this.state.minRange),
                                          maxScore: Number(this.state.maxRange),
                                          paymentStatus: this.state.payment,
                                          quartile: this.state.quartile,
                                          nismResult: this.state.nismResult,
                                        }
                                        FetchNotificationService.fetchDistributorListData(distributeData, res => {
                                          if (res.status === "success") {
                                            this.setState({
                                              distributorListData: res.responseListObject
                                            })
                                          }
                                        });
                                      }
                                    }

                                    if (this.state.fromDate !== undefined && this.state.fromDate !== "" && this.state.toDate !== undefined && this.state.toDate !== "") {
                                      if (new Date(this.state.fromDate) > new Date(this.state.toDate)) {
                                        warning("To Date should be greater than From Date", warningNotification)
                                        this.setState({
                                          minRange: "",
                                          maxRange: "",
                                          quartile: "",
                                          nismResult: "",
                                          payment: "",
                                          fromYear: "",
                                          fromMonth: "",
                                          fromDay: "",
                                          toYear: "",
                                          toMonth: "",
                                          toDay: "",
                                          fromDate: "",
                                          toDate: ""
                                        })

                                      }
                                      else {
                                        let distributeData = {
                                          stage: this.state.stage,
                                          fromDate: this.state.fromDate,
                                          toDate: this.state.toDate,
                                          minScore: Number(this.state.minRange),
                                          maxScore: Number(this.state.maxRange),
                                          paymentStatus: this.state.payment,
                                          quartile: this.state.quartile,
                                          nismResult: this.state.nismResult,
                                        }
                                        FetchNotificationService.fetchDistributorListData(distributeData, res => {
                                          if (res.status === "success") {
                                            this.setState({
                                              distributorListData: res.responseListObject
                                            })
                                          }
                                        });
                                      }
                                    }

                                    if (this.state.stage[0] == undefined) {
                                      warning("Please select stage", warningNotification)
                                    } else {
                                      let distributeData = {
                                        stage: this.state.stage,
                                        fromDate: this.state.fromDate,
                                        toDate: this.state.toDate,
                                        minScore: Number(this.state.minRange),
                                        maxScore: Number(this.state.maxRange),
                                        paymentStatus: this.state.payment,
                                        quartile: this.state.quartile,
                                        nismResult: this.state.nismResult,
                                      }
                                      FetchNotificationService.fetchDistributorListData(distributeData, res => {
                                        if (res.status === "success") {
                                          this.setState({
                                            distributorListData: res.responseListObject
                                          })
                                        }
                                      });

                                    }
                                    
                                    // let data_checked = this.state.data_checked
                                    // let datalen = this.state.distributorListData.length !== null ? this.state.distributorListData.length : 0
                                    // if (datalen > 0) {
                                    //   for (let i = 0; i < datalen; i++) {
                                    //     this.state.data_checked.map((e, i1) => {
                                    //       if (e.learnerPartyID != this.state.distributorListData[i]['learnerPartyID']) {
                                    //         data_checked.splice(i1, 1);

                                    //       }
                                    //     })
                                    //   }
                                    // }
                                    // const newSelected = Object.assign({}, this.state.selected);

                                    // this.state.distributorListData.map(e => {
                                    //   let obj = {}

                                    //   let id = e.learnerPartyID

                                    //   obj[id] = true
                                    //   newSelected[e.learnerPartyID] = true

                                    // }
                                    // )
                                    // this.setState({
                                    //   data_checked: data_checked,
                                    //   selected: newSelected
                                    // })


                                  }
                                  }
                                >Search</button>
                                <button
                                  type="button"
                                  id="close"
                                  class="btn-4 mr-20 pull-right"
                                  onClick={async () => {
                                    window.location.reload()
                                    
                                    this.setState({
                                      minRange: "",
                                      maxRange: "",
                                      stage: [],
                                      quartile: "",
                                      nismResult: "",
                                      payment: "",
                                      selectedValue: []

                                    })
                                    setFieldValue(
                                      (values.selectedValue = [])
                                    );
                                    setFieldValue(
                                      (values.status = 'RESET')
                                    );
                                    setFieldValue(
                                      (values.fromDate = '')
                                    );
                                    setFieldValue(
                                      (values.day = '')
                                    );
                                    setFieldValue(
                                      (values.month = '')
                                    );
                                    setFieldValue(
                                      (values.year = '')
                                    );
                                    setFieldValue(
                                      (values.today = '')
                                    );
                                    setFieldValue(
                                      (values.tomonth = '')
                                    );
                                    setFieldValue(
                                      (values.toyear = '')
                                    );

                                  }}
                                >
                                  Reset
                  </button>
                              </div>

                            </div>

                          </div>
                        </div>
                        {/* Grid  */}

                        <div class="table-header">
                          <h3>
                            <div class="row">
                              <div class="col-md-12 col-sm-12 col-xs-12">
                                <span class="left-icon">
                                  <i class="fa fa-list" aria-hidden="true"></i>
                                </span>{" "}
                                  Distributor List ({this.state.data_checked ? this.state.data_checked.length : 0})
                                  {/* ({this.state.distributorListData ? this.state.distributorListData.length : 0}) */}
                              </div>

                            </div>
                          </h3>
                        </div>
                        <div class="bg-box mb-30">
                          <div class="table-responsive">


                            <ReactTable
                              minRows={2}
                              data={this.state.distributorListData || []}
                              className="-striped -highlight"
                              filterable={false}
                              onPageChange={this.stateonpagechange}
                              defaultFilterMethod={this.filterCaseInsensitive}
                              ref={(refReactTable) => { this.refReactTable = refReactTable; }}

                              columns={[
                                {
                                  columns: [
                                    //sneha
                                    {

                                      id: "checkbox",
                                      accessor: "",
                                      Cell: ({ original }) => {

                                        return (
                                          <input
                                            type="checkbox"
                                            className="checkbox"
                                            checked={this.state.selected[original.learnerPartyID] == true}
                                            onChange={() => this.toggleRow(original)}
                                          //original.learnerPartyID
                                          />

                                        );
                                      },
                                      Header: x => {
                                        return (
                                          <input
                                            type="checkbox"
                                            className="checkbox"
                                            checked={this.state.selectAll === 1}
                                            ref={input => {
                                              if (input) {
                                                input.indeterminate = this.state.selectAll === 2;
                                              }
                                            }}
                                            onChange={() => this.toggleSelectAll()}
                                          />
                                        );
                                      },
                                      sortable: false,
                                      filterable: false,
                                      width: 45,
                                    },
                                    //sneha

                                    {
                                      Header: "Name",
                                      id: "firstName",
                                      accessor: (d) => d.firstName,
                                    },

                                    {
                                      Header: "Email",
                                      id: "emailID",
                                      accessor: (d) => d.emailID,
                                    },
                                    {
                                      Header: "Mobile Number",
                                      id: "mobileNo",
                                      accessor: (d) => d.mobileNo,
                                    },
                                    {
                                      Header: "Stage",
                                      id: "attemptedDate",
                                      accessor: (d) => d.currentStage,
                                    },
                                  ]
                                }
                              ]}
                              defaultPageSize={10}
                              style={{
                                width: "100%",
                                maxHeight: "500px"
                              }}
                            />


                          </div>
                        </div>
                      </div>


                      <Formik
                        enableReinitialize
                        initialValues={{
                          emailTemplate: this.state.notify_email_template_id,
                          ToEMailId: this.state.ToEMailId,
                          ccemailId: this.state.ccemailId,
                          Emailsubject: this.state.Emailsubject,
                          Emailmessage: this.state.email_body_text,
                          document: "",
                          SMSTemplte: this.state.notify_sms_template_id,
                          SMSToEMailId: this.state.SMSToEMailId,
                          SMSmessage: this.state.message,

                        }}

                        validationSchema={Yup.object().shape({
                          //   ToEMailId: Yup.string()
                          //     .required('You must specify a To email id'),

                          // Emailmessage: Yup.string()
                          //   .required('You must specify a email message'),

                          //   SMSToEMailId: Yup.string()
                          //   .required('You must specify a mobile number'),
                          //   SMSmessage: Yup.string()
                          //     .required('You must specify a subject'),

                        }
                        )}


                        onSubmit={(fields) => {
                          //  alert()
                          

                           if(this.state.editorHtml!==""){
                             
                             let html = this.state.editorHtml
                             let oldbr = html.replaceAll("<br>", "");
                            let phtml = oldbr.replaceAll("<p>", "");
                            let brhtml = phtml.replaceAll("</p>", "<br>");
                           // alert(brhtml)
                            this.setState({editorHtml: brhtml})
                           }
                          let temp = []
                          console.log("Vaishnavi Stage state", fields.flag)
                          //email_body_text._editorState._immutable.currentContent.blockMap.fsbui.text
                          this.state.data_checked.length > 0 && this.state.data_checked.map(e => {


                            let obj = {
                              "firstName": e.firstName,
                              "emailID": e.emailID,
                              "mobileNo": e.mobileNo,
                              "currentStage": e.currentStage,
                              "learnerPartyID": e.learnerPartyID.toString()
                            }
                            temp.push(obj)
                          })
                          let doc = this.state.document
                          doc.map((e, i) => {
                            i = i + 1
                            e["documentType"] = "document" + i
                          })
                          console.log(fields)
                          let partyID = localStorage.getItem("ciel-userid-Token");
                          let cc = fields.ccemailId !== undefined ? fields.ccemailId : ""
                          let data = {
                            "stage": this.state.stage.toString(),
                            "filtersdata": {
                              "fromDate": this.state.fromDate,
                              "toDate": this.state.toDate,
                              "minScore": this.state.minRange,
                              "maxScore": this.state.maxRange,
                              "quartile": this.state.quartile,
                              "nismResult": this.state.nismResult,
                              "fromPercentage": '',
                              "toPercentage": "",
                              "paymentStatus": this.state.payment
                            },
                            "learnerdata": temp,
                            "chk_email": this.state.composeEmail == true ? 1 : 0,
                            "chk_sms": this.state.composeSms == true ? 1 : 0,
                            "notify_email_event_id": 1,
                            "notify_email_template_id": parseInt(fields.emailTemplate) != null ? parseInt(fields.emailTemplate) : 0,
                            "notify_sms_event_id": 1,
                            "notify_sms_template_id": this.state.SMSTemplte,
                            // parseInt(fields.SMSTemplte) != null ? parseInt(fields.SMSTemplte) : 0,
                            "email_from": "noreply@gmail.com",
                            "email_from_name": "EXPERTMFD",
                            "ToEMailId": "",
                            "email_subject": fields.Emailsubject,
                            "email_bcc": "",
                            "email_cc": cc,
                            "email_body_text": this.state.editorHtml,
                            "sms_from": "1234567890",
                            "sms_from_name": "EXPERTMFD",
                            "sms_to": "",
                            "sms_body_text": this.state.SMSmessage,
                            "delivery_priority": "high",
                            "senderPartyId": partyID.toString(),
                            "isActive": 1,
                            "document": doc,
                            "isAttachment": 1,
                            "attachmentCount": this.state.document.length,
                            "updateId": this.state.alertid
                          }

                          if (fields.flag == "draft") {
                            
                            if (this.state.composeEmail === true && (this.state.editorHtml === undefined || this.state.editorHtml === "")) {
                              error("You must specify a email message.", errorNotification)
                            } else if (this.state.composeEmail === true && (fields.Emailsubject === undefined || fields.Emailsubject === "")) {
                              error("You must specify a email subject.", errorNotification)
                            } else if (this.state.composeEmail === true && this.state.data_checked.length < 1) {
                              error("You must specify a to email.", errorNotification)
                            } else if (this.state.composeSms === true && this.state.data_checked.length < 1) {
                              error("You must specify a mobile number.", errorNotification)
                            } else if (this.state.composeSms === true && (this.state.SMSmessage === undefined || this.state.SMSmessage === "")) {
                              error("You must select sms template.", errorNotification)
                            } else {
                              FetchNotificationService.ComposeDataStore(data, (res) => {
                                success("Draft Saved Successfully.", successNotification)

                                setTimeout(() => {
                                  window.location.href = "/my-inbox";
                                }, 5000);
                                // this.props.history.push("/my-inbox-compose", {
                                //   alerts: this.props.alertNotificationEvents,
                                //   notification: this.props.notificationEvents,
                                //   userRoleAccess: this.props.userRoleAccess,

                                // }
                                // )

                              })
                            }
                          }
                          else if (fields.flag == "UpdateDraft") {
                            if (this.state.composeEmail === true && (this.state.editorHtml === undefined || this.state.editorHtml === "")) {
                              error("You must specify a email message.", errorNotification)
                            } else if (this.state.composeEmail === true && (fields.Emailsubject === undefined || fields.Emailsubject === "")) {
                              error("You must specify a email subject.", errorNotification)
                            } else if (this.state.composeEmail === true && this.state.data_checked.length < 1) {
                              error("You must specify a to email.", errorNotification)
                            } else if (this.state.composeSms === true && this.state.data_checked.length < 1) {
                              error("You must specify a mobile number.", errorNotification)
                            } else if (this.state.composeSms === true && (this.state.SMSmessage === undefined || this.state.SMSmessage === "")) {
                              error("You must select sms template.", errorNotification)
                            } else {
                              let data = {
                                "draftHistoryId": this.props.history.location.state.alertid,
                                "draft_name": fields.Emailsubject,
                                "stage": this.state.stage.toString(),
                                "filtersdata": {
                                  "fromDate": this.state.fromDate,
                                  "toDate": this.state.toDate,
                                  "minScore": this.state.minRange,
                                  "maxScore": this.state.maxRange,
                                  "quartile": this.state.quartile,
                                  "nismResult": this.state.nismResult,
                                  "fromPercentage": '',
                                  "toPercentage": "",
                                  "paymentStatus": this.state.payment
                                },
                                "learnerdata": temp,
                                "chk_email": this.state.composeEmail == true ? 1 : 0,
                                "chk_sms": this.state.composeSms == true ? 1 : 0,
                                "notify_email_event_id": 1,
                                "notify_email_template_id": parseInt(fields.emailTemplate) != null ? parseInt(fields.emailTemplate) : 0,
                                "notify_sms_event_id": 1,
                                "notify_sms_template_id": this.state.SMSTemplte,
                                //parseInt(fields.SMSTemplte) != null ? parseInt(fields.SMSTemplte) : 0,
                                "email_from": "noreply@gmail.com",
                                "email_from_name": "EXPERTMFD",
                                "ToEMailId": "",
                                "email_subject": fields.Emailsubject,
                                "email_bcc": "",
                                "email_cc": cc,
                                "email_body_text": this.state.editorHtml,
                                "sms_from": "1234567890",
                                "sms_from_name": "EXPERTMFD",
                                "sms_to": "",
                                "sms_body_text": this.state.SMSmessage,
                                "delivery_priority": "high",
                                "senderPartyId": partyID.toString(),
                                "isActive": 1,
                                "document": doc,
                                "isAttachment": 1,
                                "attachmentCount": this.state.document.length,
                                "updateId": this.state.alertid
                              }
                              FetchNotificationService.updateDraftData(data, (res) => {
                                success("Draft Updated Successfully.", successNotification)
                                // window.location.reload();
                                setTimeout(() => {
                                  window.location.href = "/my-inbox";
                                }, 5000);
                                // this.props.history.push("/my-inbox-compose", {
                                //   alerts: this.props.alertNotificationEvents,
                                //   notification: this.props.notificationEvents,
                                //   userRoleAccess: this.props.userRoleAccess,

                                // }
                                // )
                              })
                            }
                          }
                          else if (fields.flag == "UpdateSent") {
                            if (this.state.composeEmail === true && (this.state.editorHtml === undefined || this.state.editorHtml === "")) {
                              error("You must specify a email message.", errorNotification)
                            } else if (this.state.composeEmail === true && (fields.Emailsubject === undefined || fields.Emailsubject === "")) {
                              error("You must specify a email subject.", errorNotification)
                            } else if (this.state.composeEmail === true && this.state.data_checked.length < 1) {
                              error("You must specify a to email.", errorNotification)
                            } else if (this.state.composeSms === true && this.state.data_checked.length < 1) {
                              error("You must specify a mobile number.", errorNotification)
                            } else if (this.state.composeSms === true && (this.state.SMSmessage === undefined || this.state.SMSmessage === "")) {
                              error("You must select sms template.", errorNotification)
                            } else {
                              let data = {
                                "draftHistoryId": this.props.history.location.state.alertid,
                                "draft_name": fields.Emailsubject,
                                "stage": this.state.stage.toString(),
                                "filtersdata": {
                                  "fromDate": this.state.fromDate,
                                  "toDate": this.state.toDate,
                                  "minScore": this.state.minRange,
                                  "maxScore": this.state.maxRange,
                                  "quartile": this.state.quartile,
                                  "nismResult": this.state.nismResult,
                                  "fromPercentage": '',
                                  "toPercentage": "",
                                  "paymentStatus": this.state.payment
                                },
                                "learnerdata": temp,
                                "chk_email": this.state.composeEmail == true ? 1 : 0,
                                "chk_sms": this.state.composeSms == true ? 1 : 0,
                                "notify_email_event_id": 1,
                                "notify_email_template_id": parseInt(fields.emailTemplate) != null ? parseInt(fields.emailTemplate) : 0,
                                "notify_sms_event_id": 1,
                                "notify_sms_template_id": this.state.SMSTemplte,
                                // "notify_sms_template_id": parseInt(fields.SMSTemplte) != null ? parseInt(fields.SMSTemplte) : 0,
                                "email_from": "noreply@gmail.com",
                                "email_from_name": "EXPERTMFD",
                                "ToEMailId": "",
                                "email_subject": fields.Emailsubject,
                                "email_bcc": "",
                                "email_cc": cc,
                                "email_body_text": this.state.editorHtml,
                                "sms_from": "1234567890",
                                "sms_from_name": "EXPERTMFD",
                                "sms_to": "",
                                "sms_body_text": this.state.SMSmessage,
                                "delivery_priority": "high",
                                "senderPartyId": partyID.toString(),
                                "isActive": 1,
                                "document": doc,
                                "isAttachment": 1,
                                "attachmentCount": this.state.document.length,
                                "updateId": this.state.alertid
                              }
                              FetchNotificationService.ComposeSentStore(data, (res) => {
                                success("Message has been delivered to the user successfully.", successNotification)
                                // window.location.reload();
                                window.location.href = "/my-inbox";
                                // this.props.history.push("/my-inbox-compose", {
                                //   alerts: this.props.alertNotificationEvents,
                                //   notification: this.props.notificationEvents,
                                //   userRoleAccess: this.props.userRoleAccess,

                                // }
                                // )
                              })
                            }
                          }
                          else {
                            if (this.state.composeEmail === true && (this.state.editorHtml === undefined || this.state.editorHtml === "")) {
                              error("You must specify a email message.", errorNotification)
                            } else if (this.state.composeEmail === true && (fields.Emailsubject === undefined || fields.Emailsubject === "")) {
                              error("You must specify a email subject.", errorNotification)
                            } else if (this.state.composeEmail === true && this.state.data_checked.length < 1) {
                              error("You must specify a to email.", errorNotification)
                            } else if (this.state.composeSms === true && this.state.data_checked.length < 1) {
                              error("You must specify a mobile number.", errorNotification)
                            } else if (this.state.composeSms === true && (this.state.SMSmessage === undefined || this.state.SMSmessage === "")) {
                              error("You must select sms template.", errorNotification)
                            } else {
                              FetchNotificationService.ComposeSentStore(data, (res) => {
                                success("Message has been delivered to the user successfully.", successNotification)
                                // window.location.reload();
                                setTimeout(() => {
                                  window.location.href = "/my-inbox";
                                }, 5000);
                                // this.props.history.push("/my-inbox-compose", {
                                //   alerts: this.props.alertNotificationEvents,
                                //   notification: this.props.notificationEvents,
                                //   userRoleAccess: this.props.userRoleAccess,

                                // }
                                // )
                              })
                            }
                          }

                        }}

                        render={({ errors, touched, handleSubmit, setFieldValue, values }) => (
                          <div className="preassessmentDetailBox">
                            <Form onSubmit={handleSubmit} className="form-group">
                              <div className="pre-detail-tab">
                                <div className="displayFlex">
                                  <div>
                                    <Field
                                      className="form-check-input"
                                      type="checkbox"
                                      name="chk_email"
                                      style={{ marginLeft: "15px" }}
                                      checked={this.state.composeEmail}
                                      onClick={e => {

                                        if (this.state.composeEmail === false) {
                                          this.setState({
                                            composeEmail: true
                                          })
                                        }
                                        else {
                                          this.setState({
                                            composeEmail: false
                                          })

                                        }
                                      }}
                                    />
                                    <label
                                      class="form-check-label"
                                      for="gridCheck"
                                      style={{ marginLeft: "15px" }}
                                    >
                                      <h3>Compose Email </h3>
                                    </label>
                                  </div>
                                  <div>
                                    <Field
                                      className="form-check-input"
                                      type="checkbox"
                                      name="chk_sms"
                                      checked={this.state.composeSms}
                                      onClick={e => {

                                        if (this.state.composeSms === false) {
                                          this.setState({
                                            composeSms: true
                                          })
                                        }
                                        else {
                                          this.setState({
                                            composeSms: false
                                          })

                                        }
                                      }}
                                      style={{ marginLeft: "15px" }}
                                    />
                                    <label
                                      class="form-check-label"
                                      for="gridCheck"
                                      style={{ marginLeft: "15px" }}
                                    >
                                      <h3>Compose SMS </h3>
                                    </label>
                                  </div>
                                </div>
                              </div>
                              {this.state.composeEmail === true && this.state.composeSms === true ?
                                <div>
                                  <section id="email">

                                    <div className="col-md-6 col-lg-6 col-sm-6 col-xs-12 mb-30">
                                      <div className="preassessmentDetailBox1">
                                        <div class="product-add">
                                          <div class="form-group">
                                            <div class="row">
                                              <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                                                <label>
                                                  Select Email Template 
                                  </label>
                                                <Field
                                                  as="select"
                                                  name="emailTemplate"
                                                  id="emailTemplate"
                                                  className="form-control"
                                                  onChange={e => {
                                                    setFieldValue(values.emailTemplate = e.target.value)
                                                    
                                                    for (let i = 0; i < this.state.emailTemplate1.length; i++) {
                                                      if (this.state.emailTemplate1[i]['notifyTemplateName'] == e.target.value) {
                                                        this.setState({
                                                          editorHtml: this.state.emailTemplate1[i]["notifyTemplateBody"]
                                                        })
                                                        setFieldValue(values.Emailsubject = this.state.emailTemplate1[i]["notifyTemplateSubject"])
                                                        this.setState({
                                                          Emailsubject: this.state.emailTemplate1[i]["notifyTemplateSubject"]
                                                        })
                                                      }
                                                    }
                                                  }}
                                                >

                                                  <option value="">--Select--</option>

                                                  {(this.state.emailTemplate || []).map(
                                                    team => (
                                                      <option
                                                        key={team.notifyTemplateId}
                                                        value={team.notifyTemplateId}
                                                      >
                                                        {team.notifyTemplateName}
                                                      </option>
                                                    )
                                                  )}
                                                </Field>
                                              </div>
                                              <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                                                <label>
                                                  TO
                                                  <span className="validation1"> *</span>
                                                </label>

                                                <Field
                                                  disabled="true"
                                                  type="emailId"
                                                  name="ToEMailId"
                                                  id="ToEMailId"
                                                  autoComplete="ToEMailId"
                                                  className="form-control"
                                                  placeholder="TO"

                                                  value={this.state.data_checked.length > 0 ? this.state.data_checked.length == 1 ? this.state.data_checked[0].emailID : this.state.data_checked[0].emailID + "        " + "(" + this.state.data_checkedlen + ")More" : ""}
                                                  className={
                                                    "form-control" +
                                                    (errors.ToEMailId &&
                                                      touched.ToEMailId
                                                      ? " is-invalid"
                                                      : "")
                                                  }
                                                />
                                                {/* <ErrorMessage
                                                    name="ToEMailId"
                                                    component="div"
                                                    className="validation1"
                                                  /> */}
                                                <div>
                                                  {/* { this.state.data_checked.length>0 && this.state.data_checked.map(e=>{
                                                    return <span>{e.emailID}</span>
                                                  })} */}
                                                </div>
                                              </div>
                                              <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                                                <label>
                                                  CC
                                          </label>
                                                <Field
                                                  type="emailId"
                                                  name="ccemailId"
                                                  id="emailId"
                                                  autoComplete="emailId"
                                                  className={
                                                    "form-control" +
                                                    (errors.emailId &&
                                                      touched.emailId
                                                      ? " is-invalid"
                                                      : "")
                                                  }
                                                  placeholder="CC"
                                                />
                                                <ErrorMessage
                                                  name="emailId"
                                                  component="div"
                                                  className="validation1"
                                                />
                                              </div>
                                              <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                                                <label>
                                                  Subject
                                          <span className="validation1"> *</span>
                                                </label>
                                                <Field
                                                  type="text"
                                                  name="Emailsubject"
                                                  id="subject"
                                                  autoComplete="subject"
                                                  //  value={"aaaaaaa"}
                                                  // className={
                                                  //   "form-control" +
                                                  //   (errors.subject &&
                                                  //     touched.text
                                                  //     ? " is-invalid"
                                                  //     : "")
                                                  // }
                                                  placeholder="Subject"
                                                  className="form-control"
                                                />
                                                {/* <ErrorMessage
                                                  name="Emailsubject"
                                                  component="div"
                                                  className="validation1"
                                                  /> */}
                                              </div>
                                              <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                                                <label>
                                                  Email Body
                                          <span className="validation1"> *</span>
                                                </label>
                                                {/* <Field as="textarea" rows="8" cols="50" name="Emailmessage"
                                                id="message"
                                                autoComplete="message"
                                                className={
                                                  "form-control" +
                                                  (errors.message && touched.message
                                                    ? " is-invalid"
                                                    : "")
                                                }
                                              /> */}
                                                {/* <RichTextEditor
                                                  value={this.state.value}
                                                  onChange={this.onChange}
                                                /> */}
                                                <ReactQuill
                                                  //   theme={this.state.theme}
                                                  onChange={this.handleChange}
                                                  value={this.state.editorHtml}
                                                  modules={this.modules}
                                                  formats={this.formats}
                                                  theme="snow"
                                                // bounds={'.app'}
                                                // placeholder={this.props.placeholder}
                                                />
                                                <ErrorMessage
                                                  name="Emailmessage"
                                                  className="validation1"
                                                  component="div"
                                                />
                                              </div>
                                              <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                                                <label>
                                                  Upload Attachment
                                          </label>
                                                <div className="profile-add"
                                                  style={{
                                                    wordBreak: "break-all", padding: "11px 25px 11px 6px"
                                                  }}>

                                                  <p class="color_gray text-wrap-bulk-upload"
                                                    id="uploadedFileName">{this.state.document.length > 0 && this.state.document.map((e, i) => {

                                                      let i1 = i + 1
                                                      return <p><span>{i1}. {e.documentName}</span> <span className="icon">
                                                        <i
                                                          className="fa fa-remove"
                                                          aria-hidden="true"
                                                          style={{ color: "red" }}
                                                          onClick={e => {
                                                            
                                                            let document2 = this.state.document
                                                            let document1 = this.state.document1
                                                            document2.splice(i, 1)
                                                            //  document1.splice(i, 1)
                                                            this.setState({
                                                              document1: document1,
                                                              document: document2
                                                            })
                                                          }}
                                                        ></i>
                                                      </span><br /></p>
                                                    })}
                                                    <br /><br />
                                                  </p>

                                                  <div>
                                                    <a className="icon btn-file"
                                                    >
                                                      {this.state.docName ? "" :
                                                        <span>
                                                          <span className="icon">
                                                            <i
                                                              className="fa fa-times-circle"
                                                              aria-hidden="true"
                                                            ></i>
                                                          </span>
                                                          <h4>No File Chosen</h4>
                                                        </span>}
                                                      <i
                                                        className="fa fa-paperclip color_magento"
                                                        aria-hidden="true"
                                                      ></i>
                                                      <FileBase64
                                                        multiple={true}
                                                        name="attachment"
                                                        //   onChange={handleChange}
                                                        onDone={event => {
                                                          if (event.length <= 5) {

                                                            let size = 0
                                                            let name = []
                                                            event.map(e => {
                                                              size = size + e.file.size
                                                            })
                                                            event.map(e => {
                                                              name.push(e.name.split(".")[1])
                                                            })
                                                            console.log(name)
                                                            let ar2 = [
                                                              "jpg",
                                                              "JPG",
                                                              "jpeg",
                                                              "JPEG",
                                                              "png",
                                                              "PNG",
                                                              "pdf"
                                                            ]

                                                            const checkType = event[0].name.split(
                                                              "."
                                                            );
                                                            if (
                                                              size <= 3000000
                                                            ) {
                                                              let document = this.state.document
                                                              event.map((e, i) => {
                                                                i = i + 1
                                                                document.push({
                                                                  documentHash:
                                                                    e.base64,
                                                                  documentType: "document",
                                                                  documentName: e.name,
                                                                  documentId: 0
                                                                });
                                                              })
                                                              this.setState({
                                                                document: document,
                                                                document1: document,
                                                                docName: event[0].name
                                                              })

                                                            }
                                                          }
                                                          else {
                                                            warning("Only 5 files are allowed", warningNotification)
                                                          }
                                                        }}
                                                      />
                                                    </a>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="col-md-12 col-sm-12 pl">
                                              <span class="file-size-limit">
                                                Only 5 attachments are allowed with file size 1 MB
                                        </span>
                                            </div>
                                          </div>
                                          {/* <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 mb-20">
                                              <button
                                                type="submit"
                                                class="btn-4 pull-right"
                                              >Clear</button>
                                            </div> */}
                                        </div>
                                      </div>
                                    </div>

                                  </section>

                                  <section id="sms">

                                    <div className="col-md-6 col-lg-6 col-sm-6 col-xs-12 mb-30">
                                      <div className="preassessmentDetailBox1">
                                        <div class="product-add">
                                          <div class="form-group">
                                            <div class="row">
                                              <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                                                <label>
                                                  Select SMS Template
                                  </label>
                                                <Field
                                                  as="select"
                                                  name="SMSTemplte"
                                                  id="month"
                                                  className="form-control"
                                                  onChange={e => {
                                                    setFieldValue(values.SMSTemplte = e.target.value)
                                                    this.setState({
                                                      SMSTemplte: e.target.value
                                                    })
                                                    for (let i = 0; i < this.state.SMSTemplate1.length; i++) {
                                                      if (this.state.SMSTemplate1[i]['notifyTemplateName'] == e.target.value) {
                                                        this.setState({
                                                          SMSmessage: this.state.SMSTemplate1[i]["notifyTemplateBody"]
                                                        })
                                                      }
                                                    }
                                                  }}
                                                >
                                                  <option value="">--Select--</option>

                                                  {(this.state.SMSTemplate || []).map(
                                                    team => (
                                                      <option
                                                        key={team.notifyTemplateId}
                                                        value={team.notifyTemplateId}
                                                      >
                                                        {team.notifyTemplateName}
                                                      </option>
                                                    )
                                                  )}
                                                </Field>
                                              </div>
                                              <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                                                <label>
                                                  TO
                                                  <span className="validation1"> *</span>
                                                </label>
                                                <Field
                                                  disabled="true"
                                                  type="emailId"
                                                  name="SMSToEMailId"
                                                  id="ToEMailId"
                                                  autoComplete="ToEMailId"
                                                  className="form-control"
                                                  placeholder="TO"

                                                  value={this.state.data_checked.length > 0 ? this.state.data_checked.length == 1 ? this.state.data_checked[0].mobileNo : this.state.data_checked[0].mobileNo + "        " + "(" + this.state.data_checkedlen + ")More" : ""}
                                                  className={
                                                    "form-control" +
                                                    (errors.SMSToEMailId &&
                                                      touched.SMSToEMailId
                                                      ? " is-invalid"
                                                      : "")
                                                  }
                                                />
                                                {/* <ErrorMessage
                                                    name="SMSToEMailId"
                                                    component="div"
                                                    className="validation1"
                                                  /> */}
                                                {/* { this.state.data_checked.length>0 && this.state.data_checked.map(e=>{
                                                    return <span>{e.mobileNo}</span>
                                                  })} */}
                                              </div>
                                              <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                                                <label>
                                                  Message Body
                                          <span className="validation1"> *</span>
                                                </label>
                                                {/* <RichTextEditor
                                                    value={this.state.value1}
                                                    onChange={this.onChange1}
                                                  /> */}
                                                <Field as="textarea" rows="8" cols="50" name="message"
                                                  id="message"
                                                  autoComplete="message"
                                                  ///  value={ SMSTemplate.notifyTemplateXml}
                                                  className={
                                                    "form-control" +
                                                    (errors.message && touched.message
                                                      ? " is-invalid"
                                                      : "")

                                                  }
                                                  value={this.state.SMSmessage}
                                                  // onChange={e => {
                                                  //   this.setState({
                                                  //     SMSmessage: e.target.value
                                                  //   })
                                                  // }}

                                                  // value={"Template"}
                                                  disabled={true}
                                                />
                                                {/* <ErrorMessage
                                                  name="message"
                                                  className="validation1"
                                                  component="div"
                                                /> */}
                                              </div>
                                              {/* <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                                                  <button
                                                    type="submit"
                                                    class="btn-4 pull-right"
                                                  >Clear</button>
                                                </div> */}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                  </section>
                                </div>



                                : this.state.composeEmail === true ?
                                  <section id="email">

                                    <div className="col-md-6 col-lg-6 col-sm-6 col-xs-12 mb-30">
                                      <div className="preassessmentDetailBox1">
                                        <div class="product-add">
                                          <div class="form-group">
                                            <div class="row">
                                              <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                                                <label>
                                                  Select Email Template
                                  </label>
                                                <Field
                                                  as="select"
                                                  name="emailTemplate"
                                                  id="emailTemplate"
                                                  className="form-control"
                                                  onChange={e => {
                                                    setFieldValue(values.emailTemplate = e.target.value)
                                                    for (let i = 0; i < this.state.emailTemplate1.length; i++) {
                                                      if (this.state.emailTemplate1[i]['notifyTemplateName'] == e.target.value) {
                                                        this.setState({
                                                          editorHtml: this.state.emailTemplate1[i]["notifyTemplateBody"]
                                                        })
                                                        setFieldValue(values.Emailsubject = this.state.emailTemplate1[i]["notifyTemplateSubject"])
                                                        this.setState({
                                                          Emailsubject: this.state.emailTemplate1[i]["notifyTemplateSubject"]
                                                        })
                                                      }
                                                    }
                                                  }}
                                                >
                                                  <option value="">--Select--</option>

                                                  {(this.state.emailTemplate || []).map(
                                                    team => (
                                                      <option
                                                        key={team.notifyTemplateId}
                                                        value={team.notifyTemplateId}
                                                      >
                                                        {team.notifyTemplateName}
                                                      </option>
                                                    )
                                                  )}                                              </Field>
                                              </div>
                                              <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                                                <label>
                                                  TO
                                                  <span className="validation1"> *</span>
                                                </label>
                                                <Field
                                                  disabled="true"
                                                  type="emailId"
                                                  name="ToEMailId"
                                                  id="ToEMailId"
                                                  autoComplete="ToEMailId"
                                                  className="form-control"
                                                  placeholder="TO"

                                                  value={this.state.data_checked.length > 0 ? this.state.data_checked.length == 1 ? this.state.data_checked[0].emailID : this.state.data_checked[0].emailID + "        " + "(" + this.state.data_checkedlen + ")More" : ""}
                                                  className={
                                                    "form-control" +
                                                    (errors.ToEMailId &&
                                                      touched.ToEMailId
                                                      ? " is-invalid"
                                                      : "")
                                                  }
                                                />
                                                {/* <ErrorMessage
                                                    name="ToEMailId"
                                                    component="div"
                                                    className="validation1"
                                                  /> */}
                                                {/* { this.state.data_checked.length>0 && this.state.data_checked.map(e=>{
                                                    return <span>{e.emailID}</span>
                                                  })} */}
                                              </div>
                                              <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                                                <label>
                                                  CC
                                          </label>
                                                <Field
                                                  type="emailId"
                                                  name="ccemailId"
                                                  id="emailId"
                                                  autoComplete="emailId"
                                                  className={
                                                    "form-control" +
                                                    (errors.emailId &&
                                                      touched.emailId
                                                      ? " is-invalid"
                                                      : "")
                                                  }
                                                  placeholder="CC"
                                                />
                                                <ErrorMessage
                                                  name="emailId"
                                                  component="div"
                                                  className="validation1"
                                                />
                                              </div>
                                              <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                                                <label>
                                                  Subject
                                          <span className="validation1"> *</span>
                                                </label>
                                                <Field
                                                  type="text"
                                                  name="Emailsubject"
                                                  id="subject"
                                                  autoComplete="subject"
                                                  // className={
                                                  //   "form-control" +
                                                  //   (errors.subject &&
                                                  //     touched.text
                                                  //     ? " is-invalid"
                                                  //     : "")
                                                  // }
                                                  placeholder="Subject"
                                                  className="form-control"
                                                />
                                                {/* <ErrorMessage
                                                  name="Emailsubject"
                                                  component="div"
                                                  className="validation1"
                                                  /> */}
                                              </div>
                                              <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                                                <label>
                                                  Email Body
                                          <span className="validation1"> *</span>
                                                </label>
                                                {/* <RichTextEditor
                                                  value={this.state.value}
                                                  onChange={this.onChange}
                                                /> */}
                                                <ReactQuill
                                                  //  theme={this.state.theme}
                                                  onChange={this.handleChange}
                                                  value={this.state.editorHtml}
                                                  modules={this.modules}
                                                  formats={this.formats}
                                                  theme="snow"
                                                //  bounds={'.app'}
                                                //  placeholder={this.props.placeholder}
                                                />
                                                <ErrorMessage
                                                  name="Emailmessage"
                                                  className="validation1"
                                                  component="div"
                                                />
                                              </div>
                                              <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                                                <label>
                                                  Upload Attachment
                                          </label>
                                                <div className="profile-add"
                                                  style={{
                                                    wordBreak: "break-all", padding: "11px 25px 11px 6px"
                                                  }}>

                                                  <p class="color_gray text-wrap-bulk-upload"
                                                    id="">{this.state.document.length > 0 && this.state.document.map((e, i) => {

                                                      let i1 = i + 1
                                                      return <p id="uploadedFileName1"> {i1}. {e.documentName}   <i
                                                        className="fa fa-remove"
                                                        aria-hidden="true"
                                                        style={{ color: "red" }}
                                                        onClick={e => {
                                                          
                                                          let document2 = this.state.document
                                                          let document1 = this.state.document1
                                                          document2.splice(i, 1)
                                                          //  document1.splice(i, 1)
                                                          this.setState({
                                                            document1: document1,
                                                            document: document2
                                                          })
                                                        }}
                                                      ></i></p>
                                                    })}

                                                  </p>

                                                  <div>
                                                    <a className="icon btn-file"
                                                    >
                                                      {this.state.document.length > 0 ? "" :
                                                        <span>
                                                          <span className="icon">
                                                            <i
                                                              className="fa fa-times-circle"
                                                              aria-hidden="true"
                                                            ></i>
                                                          </span>
                                                          <h4>No File Chosen</h4>
                                                        </span>
                                                      }
                                                      <i
                                                        className="fa fa-paperclip color_magento"
                                                        aria-hidden="true"
                                                      ></i>
                                                      <FileBase64
                                                        multiple={true}
                                                        name="attachment"
                                                        //   onChange={handleChange}
                                                        onDone={event => {
                                                          if (event.length <= 5) {

                                                            let size = 0
                                                            let name = []
                                                            event.map(e => {
                                                              size = size + e.file.size
                                                            })
                                                            event.map(e => {
                                                              name.push(e.name.split(".")[1])
                                                            })
                                                            console.log(name)
                                                            let ar2 = [
                                                              "jpg",
                                                              "JPG",
                                                              "jpeg",
                                                              "JPEG",
                                                              "png",
                                                              "PNG",
                                                              "pdf"
                                                            ]

                                                            const checkType = event[0].name.split(
                                                              "."
                                                            );
                                                            if (
                                                              size <= 3000000
                                                            ) {
                                                              let document = this.state.document
                                                              event.map((e, i) => {
                                                                i = i + 1
                                                                document.push({
                                                                  documentHash:
                                                                    e.base64,
                                                                  documentType: "document" + i,
                                                                  documentName: e.name,
                                                                  documentId: 0
                                                                });
                                                              })
                                                              this.setState({
                                                                document: document,
                                                                document1: document,
                                                                docName: event[0].name

                                                              })
                                                            }
                                                          }
                                                          else {
                                                            warning("Only 5 files are allowed", warningNotification)
                                                          }
                                                        }}
                                                      />
                                                    </a>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="col-md-12 col-sm-12 pl">
                                              <span class="file-size-limit">
                                                Only 5 attachments are allowed with file size 1 MB
                                        </span>
                                            </div>
                                          </div>
                                          {/* <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 mb-20">
                                              <button
                                                type="submit"
                                                class="btn-4 pull-right"
                                              >Clear</button>
                                            </div> */}
                                        </div>
                                      </div>
                                    </div>

                                  </section>
                                  : this.state.composeSms === true ?
                                    <section id="sms">

                                      <div className="col-md-6 col-lg-6 col-sm-6 col-xs-12 mb-30">
                                        <div className="preassessmentDetailBox1">
                                          <div class="product-add">
                                            <div class="form-group">
                                              <div class="row">
                                                <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                                                  <label>
                                                    Select SMS Template
                                  </label>
                                                  <Field
                                                    as="select"
                                                    name="SMSTemplte"
                                                    id="month"
                                                    className="form-control"
                                                    onChange={e => {
                                                      setFieldValue(values.SMSTemplte = e.target.value)
                                                      this.setState({
                                                        SMSTemplte: e.target.value
                                                      })
                                                      for (let i = 0; i < this.state.SMSTemplate1.length; i++) {
                                                        if (this.state.SMSTemplate1[i]['notifyTemplateName'] == e.target.value) {
                                                          this.setState({
                                                            SMSmessage: this.state.SMSTemplate1[i]["notifyTemplateBody"]
                                                          })
                                                        }
                                                      }
                                                    }}
                                                  >
                                                    <option value="">--Select--</option>

                                                    {(this.state.SMSTemplate || []).map(
                                                      team => (
                                                        <option
                                                          key={team.notifyTemplateId}
                                                          value={team.notifyTemplateId}
                                                        >
                                                          {team.notifyTemplateName}
                                                        </option>
                                                      )
                                                    )}
                                                  </Field>
                                                </div>
                                                <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                                                  <label>
                                                    TO
                                                    <span className="validation1"> *</span>
                                                  </label>
                                                  <Field
                                                    disabled="true"
                                                    type="emailId"
                                                    name="SMSToEMailId"
                                                    id="ToEMailId"
                                                    autoComplete="ToEMailId"
                                                    className="form-control"
                                                    placeholder="TO"

                                                    value={this.state.data_checked.length > 0 ? this.state.data_checked.length == 1 ? this.state.data_checked[0].mobileNo : this.state.data_checked[0].mobileNo + "        " + "(" + this.state.data_checkedlen + ")More" : ""}
                                                    className={
                                                      "form-control" +
                                                      (errors.SMSToEMailId &&
                                                        touched.SMSToEMailId
                                                        ? " is-invalid"
                                                        : "")
                                                    }
                                                  />
                                                  {/* <ErrorMessage
                                                      name="SMSToEMailId"
                                                      component="div"
                                                      className="validation1"
                                                    /> */}
                                                  {/* { this.state.data_checked.length>0 && this.state.data_checked.map(e=>{
                                                    return <span>{e.mobileNo}</span>
                                                  })} */}
                                                </div>
                                                <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                                                  <label>
                                                    Message Body
                                          <span className="validation1"> *</span>
                                                  </label>
                                                  {/* <RichTextEditor
                                                      value={this.state.value1}
                                                      onChange={this.onChange1}
                                                    /> */}
                                                  <Field as="textarea" rows="8" cols="50" name="message"
                                                    id="message"
                                                    autoComplete="message"
                                                    className={
                                                      "form-control" +
                                                      (errors.message && touched.message
                                                        ? " is-invalid"
                                                        : "")
                                                    }
                                                    // onChange={e => {
                                                    //   this.setState({
                                                    //     SMSmessage: e.target.value
                                                    //   })
                                                    // }}
                                                    value={this.state.SMSmessage}
                                                    disabled={true}
                                                  />
                                                  {/* <ErrorMessage
                                                    name="message"
                                                    className="validation1"
                                                    component="div"
                                                  /> */}
                                                </div>
                                                {/* <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                                                    <button
                                                      type="submit"
                                                      class="btn-4 pull-right"
                                                    >Clear</button>
                                                  </div> */}
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>

                                    </section>
                                    : ''}
                              {this.state.composeSms === true || this.state.composeEmail === true ?
                                <div className="col-md-12 col-sm-12 col-xs-12 mt-10 mb-20">
                                  <button
                                    type="submit"
                                    class="btn-5 pull-right mr-5 mb-5"
                                    onClick={e => {
                                      //   setFieldValue(values.flag = "sent")
                                      if (this.props.history.location.state.alertid !== undefined) {
                                        setFieldValue(values.flag = "UpdateSent")
                                      } else {
                                        setFieldValue(values.flag = "sent")
                                      }
                                    }}
                                  >Send</button>
                                  <button
                                    type="submit"
                                    class="btn-5 pull-right mr-5 mb-5"
                                    onClick={e => {
                                      if (this.props.history.location.state.alertid !== undefined) {
                                        setFieldValue(values.flag = "UpdateDraft")
                                      } else {
                                        setFieldValue(values.flag = "draft")
                                      }
                                    }}

                                  >{this.props.history.location.state.alertid !== undefined ? "Update Draft" : "Save Draft"}</button>

                                  <button
                                    type="button"
                                    class="btn-5 pull-right mr-5 mb-5"
                                    onClick={e => {
                                      var txt = "Are you sure you want to exit?"
                                      var r = window.confirm(txt);
                                      if (r == true) {
                                        window.location.href = "/my-inbox"
                                      }
                                    }}
                                  >Cancel</button>
                                </div>
                                : ''}
                            </Form>
                          </div>

                        )}
                      />
                    </div>

                  </section>
                </React.Fragment>
              )}
            />

          </div>

          <Finxfooter />
        </React.Fragment>)
    }

  }
)

export default MyMessagesCreate;

MyMessagesCreate.modules = {
  toolbar: [
    [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
    [{ size: [] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [{ 'list': 'ordered' }, { 'list': 'bullet' },
    { 'indent': '-1' }, { 'indent': '+1' }],
    ['link', 'image', 'video'],
    ['clean']
  ],
  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: false,
  }
}

MyMessagesCreate.formats = [
  'header', 'font', 'size',
  'bold', 'italic', 'underline', 'strike', 'blockquote',
  'list', 'bullet', 'indent',
  'link', 'image', 'video'
]