export const Global_var = {
     //  BASEURL: "http://dap-prod-ecs-zuul-lb-718293874.ap-south-1.elb.amazonaws.com", //PRD
      // BASEURL: "https://uatservices.expertmfd.com/thor-gateway-expertmfd", //UAT
        BASEURL: "https://services.expertmfd.com/thor-gateway-expertmfd", //DNS  production --enable this for prod build
  //     BASEURL: "https://emfdmicro.njtechdesk.com/thor-gateway-expertmfd", //DEV
  
    URL_JWT_TRUST: "/expertmfd-thor-token-management/api/v1/jwt-token",
    URL_LOGIN: "/thor-authentication/api/v1/login",
    URL_LOGIN_MOBILE: "/thor-authentication/api/v1/loginWithMobile",
  
    URL_Session_DETAILS: "/thor-authentication/api/v1/party/getbyEmail", //?email=sk9@gmail.com
    URL_LOGOUT: "/thor-authentication/api/v1/logout",
  
    URL_FORGOTPASSWORD: "/thor-authentication/api/v1/user/forgotPassword",
    URL_CHANGEPASSWORD: "/thor-authentication/api/v1/user/changePassword",
    URL_UPDATEFORGOTPASSWORD: "/thor-authentication/api/v1/user/updateForgotPassword",
    URL_UPDATE_FLAG: "/fc-learner-dashboard/api/v1/learnerDashboard/updateFlag",
  
    // ProductList
    URL_PRODUCT_ALL: "/fc-product/api/v1/product/allproduct",
    URL_PRODUCT_BYNAME: "/fc-product/api/v1/product/productName", //?productName=CFX
    URL_PRODUCT_BYID: "/fc-product/api/v1/product", //?productName=CFX
    URL_PRODUCT_BY_PARTYID: "/fc-learner-dashboard/api/v1/learnerDashboard/getLearnerAssessmentWithProduct", //?learnerPartyId = 10
    URL_ADD_PRODUCT: "/fc-product/api/v1/product",
    URL_GETPARTNER: "/fc-product/api/v1/patner",
    URL_UPDATEPRODUCT: "/fc-product/api/v1/product",
    URL_UPDATEISACTIVEINACTIVE: "/fc-product/api/v1/product",
  
    URL_ADD_PRODUCT_THUMBNAIL_VIDEO: "/expertmfd-thor-documentmanagement/api/v1/document",
  
    // Modules
    URL_ADD_MODULES: "/fc-product/api/v1/module",
    URL_GETMODULES: "/fc-product/api/v1/module/allmodule",
    URL_GETMODULESBYID: "/fc-product/api/v1/module",
    URL_UPDATEMODULES: "/fc-product/api/v1/module",
    URL_UPDATEISACTIVEINACTIVEMODULE: "/fc-product/api/v1/module",
  
  
    // LearningPath
    URL_GETLEARNINGPATHBYID: "/fc-product/api/v1/prerequisite/getProductPreRequisite",
    //URL_GETLEARNINGPATH: "/fc-product/api/v1/prerequisite/allpath",
    URL_GETLEARNINGPATH: "/fc-product/api/v1/prerequisite/count",
    URL_GETLEARNINGAllPRODUCT: "/fc-product/api/v1/prerequisite",
    URL_ADD_LEARNINGPATH: "/fc-product/api/v1/prerequisite",
    URL_UPDATELEARNINGPATH: "/fc-product/api/v1/prerequisite",
    URL_PARENTPRODUCTID: "/fc-product/api/v1/prerequisite/getProductPreRequisite",
    URL_PARENTPRODUCTIDASSIGNUSER: "/fc-learner/api/v1/learnerassessment",
  
    //OTP Service
    URL_SENDOTP: "/thor-otp/api/v1/otp",
    //'/api/v1/otp',//?mobileNo=9307933259&countryCode=91
    URL_OTP_VALIDATE: "/thor-otp/api/v1/otp/validate",
    //?mobileNo=9307933259&countryCode=91&otp=508769
  
    URL_FC_REGISTER_USER: "/fc-registration/api/v1/register/dap",
    URL_AH_REGISTER_USER: "/fc-registration/api/v1/register/assesshub",
    URL_AH_ACTIVATE_TEST_USER: "/fc-learner/api/v1/assessment/test/_initiate",
    URL_AH_REGISTER_USER_BULK: "/fc-registration/api/v1/register/assessment/bulk",
    URL_AH_CREDENTIAL_USER: "/fc-learner/api/v1/learnerprovidercredetails",
    URL_USER_ASSESSHUB: "/fc-registration/hub/",
    URL_AH_CREDENTIAL_LOGIN: "https://www.assesshub.com/user/finxlogin.html?uname=",// r/Sqb13tW+6im2uKyz1VCfyuG0sqnh9FYj+MXnY6FFk=
    URL_GCUBE_CREDENTIAL_LOGIN: "https://lms.finxpert.org/Default.aspx?usertoken=",// r/Sqb13tW+6im2uKyz1VCfyuG0sqnh9FYj+MXnY6FFk=
    URL_GCUBE_CREDENTIAL_LOGIN1: "https://lms.finxpert.org/Default.aspx?usertoken=",
    URL_ASSIGN_COURSE: "/fc-registration/api/v1/register/assesshub/assignCource",
  
    URL_MOBILE_URL: "/fc-learner/api/v1/learner/validatemobile",
    URL_ORDER_INSERT: "/fc-payment/api/v1/order",
    URL_DO_Payment: "/fc-payment/Payment/doPayment",
  
    URL_USERDATA: "/fc-learner/api/v1/learner",
    URL_USERADDRESS: "/thor-partyservices/api/v1/party/address",
    URL_UPDATEUSERPROFILE: "/fc-admin/api/v1/admin/updateuser",
    URL_UPLOADFILES: "/api/v1/document",
    URL_GETCOUNTRY: "/thor-regionmanagement/api/v1/country",
    URL_GETSTATE: "/thor-regionmanagement/api/v1/state",
    URL_GETCITY: "/thor-regionmanagement/api/v1/city",
    URL_GETADDRESSBYPIN: "/thor-regionmanagement/api/v1/area/_get",
    URL_GETLOOKUP_BY_CD_TYPE_NAME: "/thor-lookupmanagement/api/v1/codeValue/byName",
    URL_USERLIST_BY_PRODUCT_ID: "/fc-learner/api/v1/learner/unassignedusers",
    URL_GETLOOKUP_BY_REG_LOOKING_FOR: "/thor-lookupmanagement/api/v1/codeValue",
    //GDPI
    URL_GDPI_LEARNER: "/fc-learner/api/v1/gdpi",
    URL_GDPI_BULKUPLOAD: "/fc-learner/api/v1/gdpi/gdpibulkupdate",
  
    //Admin Management
    URL_ADMIN_CREATE_USER: "/fc-admin/api/v1/admin/adduser",
    URL_FETCH_ADMIN_USER: "/thor-usermanagement/api/v1/user",
    URL_ADMIN_EDIT_USER: "/fc-admin/api/v1/admin/updateuser",
    URL_INACTIVE_ADMIN_USER: "/fc-admin/api/v1/admin/inactiveuser",
    URL_FETCH_ADMIN_USER_BY_ROLE_PARTY_ID: "/fc-admin/api/v1/admin/getuser",
  
    URL_BULK_USER_STATUS_CHANGE: '/fc-admin/api/v1/admin/inactiveuser',
  
    //Role Management
    URL_ROLES: "/thor-rolemanagement/api/v1/role",
    URL_ROLE_ACCESS_MANAGEMENT: "/thor-roleaccessmanagement/api/v1/access/_get",
    //Learner Dashboard
    URL_CALENDER_EVENTS: "/fc-learner-dashboard/api/v1/learnerDashboard/CalenderAndEvents",
    URL_ALERTS_NOTIFICATION_EVENTS: "/fc-learner-dashboard/api/v1/learnerDashboard/AlertsAndNotification",
    URL_PRODUCT_PRE_REQUISITE: "/fc-product/api/v1/prerequisite/getProductPreRequisite",
    URL_PRODUCT_ALL_EXCEPT_MAPPED: "/fc-product/api/v1/product/productlist",
    URL_NOTIFICATION_EVENTS: "/thor-notification/api/v1/queue/notification",
    URL_NOTIFICATION_EVENTS_SENT: "/thor-notification/api/v1/queue/send_notification",
    URL_NOTIFICATION_EVENTS_DRAFT: "/fc-learner/api/v1/mailNotify/getDraft",
    URL_GETTEMPLATE: "/fc-learner/api/v1/getTemplate",
    URL_NOTIFICATION_EVENTS_DRAFT_BY_ID: "/fc-learner/api/v1/mailNotify/getDraftById",
    URL_NOTIFICATION_UPDATE_DRAFT: "/fc-learner/api/v1/mailNotify/updateDraft",
  
    URL_ASSESSMENT_UPDATION: "/fc-learner/api/v1/learnerassessment/scoreupdate",
    URL_DISTRIBUTION_LIST: "/fc-learner/api/v1/learner/getStageWiseUser",
    URL_STAGE_LIST: "/fc-learner/api/v1/stage/getStage",
    URL_INBOX_MESSAGE_COUNT: "/thor-notification/api/v1/queue/getCount",
    URL_UPDATE_MESSAGE_COUNT: "/thor-notification/api/v1/queue/updateNotifyHistory",
  
    //Institute Name
    URL_INSTITUTE_LIST: "/fc-admin/api/v1/institute",
  
    //Generalinquiry
    URL_GENERALENQUIRY: "/fc-admin/api/v1/LearnerEnquiry",
    URL_ADMIN_SEND_EMAIL: "/fc-learner/api/v1/mailNotify/sendMail",
    //"/fc-admin/api/v1/mailNotify/sendMail",
  
    //get Progress Overview Details
    URL_GET_PROGRESS_OVERVIEW: "/fc-learner-dashboard/api/v1/learnerDashboard/getProgressOverviewDetails",
  
    URL_BULK_UPLOAD: "/fc-learner/api/v1/learner/bulkupload",
  
    URL_BULK_UPLOAD_SCORE: "/fc-learner/api/v1/learnerBulk",

    URL_BULK_PAYMENT_UPLOAD: "/fc-learner/api/v1/fc/payment/Update",
  
    URL_MODULE_COURSE_PRD_PARTY: "/fc-learner-dashboard/api/v1/learnerDashboard/getModuleAgainstCourse",
    //?productID=20849&learnerPartyID=73671
    URL_MODULE_BY_PRODUCT: "/fc-product/api/v1/module",
    //? productID = 20849
    URL_LEARNER_ASSESSMENT_PREQUISITE: '/fc-learner/api/v1/learnerassessment/prerequisite',
  
    //get payment history for learner
    URL_LEARNER_PAYMENT_HISTORY: "/fc-payment/api/v1/order",
    // get learner enrollment      
    URL_LEARNER_ENROLLMENT_HISTORY: "/fc-learner/api/v1/learner",
    URL_LEARNER_ROLE_USER_DETAILS: "/fc-learner/api/v1/learner/role/user_details/_get",
  
    // Chart service
    URL_REPORT_COURSE: "/fc-learner/api/v1/learnerreport/course",
    URL_REPORT_ENROLLMENT: "/fc-learner/api/v1/learnerreport/Enrollment",
    URL_REPORT_SESSION: "/thor-authentication/api/v1/sessionReport",
  
    // S3 BUCKET PATH
    URL_DOC_SAVE_PATH: "http://finxqa.s3.ap-south-1.amazonaws.com/",
  
    //UNBLOCKUSER
    URL_UNBLOCK_USER: "/thor-authentication/api/v1/user/updateblockeduser",
  
    //Check PAN Duplicate
    URL_Check_PAN: "/fc-learner/api/v1/learner/validateupdateuser",
  
    //validater user email mobile pan aadhar while adding
  
    URL_Validate_New_User: "/fc-learner/api/v1/learner/validateuser",
  
    URL_USER_MANAGEMENT: "/thor-usermanagement/api/v1/user/activity/report",
  
    URL_ASSESSMENT_REPORT: "/fc-learner/api/v1/learnerassessment",
    URL_CFX_ASSESSMENT_REPORT: "/fc-learner/api/v1/learnercfxreport/downloadcfxreport",
  
    URL_CREATED_BY: "/thor-usermanagement/api/v1/user",
    URL_UNASSIGNED_USERS_REPORT: "/fc-learner/api/v1/learner/unassignedusers",
  
  
    //updated by vaishnavi
    //URL_GETDISTRIBUTOR: 'http://192.168.12.113:9006/fc-learner/api/v1/learner/data',
    //URL_CITY_BY_ID: 'http://192.168.12.113:9006/thor-regionmanagement/api/v1/city/_get'
    URL_GETAMCMASTER: '/fc-registration/api/v1/register/amc/_get',
   // URL_GETDISTRIBUTOR: '/fc-learner/api/v1/learner/data', //originalURLDashboard

    URL_GETDISTRIBUTOR: '/fc-learner-dashboard/api/v1/CielOps/userDetails',
    URL_BATCH_GETDISTRIBUTOR: '/fc-learner-dashboard/api/v1/CielOps/batchReport',
    URL_CITY_BY_ID: '/thor-regionmanagement/api/v1/city/_get',
  
    // Send Email 
    URL_SEND_EMAIL: "/fc-learner/api/v1/learner/send/email",
    URL_ADD_NEWSLETTER: "/fc-admin/api/v1/subscription/saveSubScription",
  
  
    // PRE Assessment
    PREASSESSMENT_TERMS_CONDITION: "/fc-learner/api/v1/assessment/term_condition/_get",
    PREASSESSMENT_TEST_INSTRUCTION: "/fc-learner/api/v1/assessment/instruction/_get",
    PREASSESSMENT_TEST_QUESTION_OPTION: "/fc-learner/api/v1/assessment/test_questionaire/_get",
    PREASSESSMENT_TEST_TEMS_UPDATE: "/fc-learner/api/v1/assessment/term_condition/_update",
    PREASSESSMENT_TEST_SCORE_UPDATE: "/fc-learner/api/v1/assessment/test_score/_update",
  
    URL_REATTEMPT_TEST: "/fc-learner/api/v1/assessment/test/_initiate",
    URL_GET_ATTEMPTED_LEARNER: "/fc-learner/api/v1/assessment/list/_get",
    URL_WORKFLOW_SEACH: "/thor-workflowservices/api/v1/workflow/_search",
    URL_WORKFLOW_PROCESS: "/thor-workflowservices/api/v1/process/_search",
    URL_GETFUNEL_STATUS_COUNT: "/fc-learner/api/v1/stage/stage_count/_get",
    URL_WORKFLOW_STATUS_UPDATE: "/fc-learner/api/v1/stage/wf_journy/_update",
    URL_SEND_MAIL: "/fc-learner/api/v1/mail/_send",
    URL_SEND_MAIL_KYD: "/fc-learner/api/v1/mail/_sendMail",
    URL_CENTER: "/fc-learner-dashboard/api/v1/NISM_Location/get",
    URL_WORKFLOW_UPDATE: "/thor-workflowservices/api/v1/process/_update",
    URL_GET_DATE_LOCATION: "/thor-workflowservices/api/v1//process/_get",
    URL_CERTIFICATE_DOWNLOAD: "/fc-learner-dashboard/api/v1/CertificatePDF_generator",
    URL_DOCUMENT_DOWNLOAD: "/expertmfd-thor-documentmanagement/api/v1/document/publicurl",
    URL_WORKFLOW_TRANSITION: "/thor-workflowservices/api/v1/process/_transition",
    URL_GETJOB: "/fc-learner/api/v1/getJob",
    URL_GETCIELREPORT: "/fc-learner/api/v1/learnerreport",
    URL_GETEMPANELMENTREPORT: "/fc-learner/api/v1/learner/getLearnerWiseAmc",
    URL_KYD_MAIL:"/fc-learner/api/v1/mail/_sendMailToDistributer",
    URL_SEND_SMS:"/fc-learner/api/v1/sms/_sendSms",
  
    URL_ZIP: "/fc-learner/api/v1/learner/zip",
    URL_NOMINEE_CREATE: "/fc-learner/api/v1/learner/NominiDetails",
    URL_ACCEPT_KYD: "/fc-learner/api/v1/learner/amc",
    URL_ARN_PDF_GENERATOR: "/fc-learner-dashboard/api/v1/ARNPDF_generator",
    URL_KYD_PDF_GENERATOR: "/fc-learner-dashboard/api/v1/KYDPDF_generator",
    URL_AMC_LIST_APPROVAL: "/fc-learner/api/v1/learner/Amc/Approved",
    URL_GETAMC_LIST: "/fc-learner/api/v1/learner/getAmc",
    URL_NOTIFICATION_EVENTS_SAVE_DRAFT:"/fc-learner/api/v1/mailNotify/saveDraftMail",
    URL_NOTIFICATION_EVENTS_DELETE_DRAFT: "/fc-learner/api/v1/mailNotify/deleteDraft",
    URL_NOTIFICATION_EVENTS_SEND_MAIL_TO_LEARNER:"/fc-learner/api/v1/mailNotify/sendMailSMSToLearner",
    URL_REGION_MANAGEMENT_BY_PIN: "/thor-regionmanagement/api/v1/pin",
    URL_GET_APPROVED_AMC_LIST: "/fc-learner/api/v1/learner/getAprovedAmc",
   
    URL_VALIDATE_EMAIL: "/fc-learner-dashboard/api/v1/learnerDashboard/emailValidate", // called when click here to authenticate your email in welcome email
    URL_USER_EMAIL_FLAG: "/fc-learner-dashboard/api/v1/learnerDashboard/getUserEmailFlag", // called for email auth popup in student dashboard
    URL_OTP_EMAIL: "/fc-learner-dashboard/api/v1/learnerDashboard/_sendOTPMail", // send otp 
    URL_VALIDATE_OTP_EMAIL: "/fc-learner-dashboard/api/v1/learnerDashboard/validateEmailOtp", // to validate email and entered otp
    URL_AUTHENTICATE_EMAIL: "/fc-learner-dashboard/api/v1/learnerDashboard/Validate/email/patterns", // checks email pattern on blur of email
    URL_IS_OTP_VALIDATED:"/fc-admin/api/v1/admin/isOtpValidated",
    URL_PAYMENT_CHECK:"/fc-learner/api/v1/fc/Login/check",
    URL_VALIDATE_MOBILE_DATA:"/fc-learner/api/v1/learner/validate/MobileNo",
    URL_EXAMDATE_UPLOAD:"/fc-learner/api/v1/learner/bulkExamDateUpload",
  
    // CONST_BUCKET_NAME: "finx-bucket-tag"                //enable this for UAT
     CONST_BUCKET_NAME:  "expertmfd-bucket-tag"         // enable this for prod build
  };
  