import RestDataSource from './restdatasource';
import { Global_var } from '../global/global_var';
import { LoginService } from "./login.service";
export const FetchNotificationService = {
  fetchNotification,
  fetchNotificationSent,
  fetchNotificationDarft,
  getTemplate,
  fetchDistributorListData,
  getStageList,
  ComposeDataStore,
  deleteDarft,
  ComposeSentStore,
  fetchNotificationDarftById,
  updateDraftData,
  getCount,
  updateReadUnread,
  fetchNotificationEvents
};

function fetchNotification(Id, fn, fnError) {
  var url = Global_var.BASEURL + Global_var.URL_NOTIFICATION_EVENTS;

  return new RestDataSource(url, fnError).GetOneByParam(Id, (res) => {
    if (res != null) {
      if (res.headers["jtitoken"] != null) {
        localStorage.setItem("jti-token", res.headers.jtitoken);
      }
      fn(res.data);
    }
  });
}

function fetchNotificationSent(Id, fn, fnError) {
  var url = Global_var.BASEURL + Global_var.URL_NOTIFICATION_EVENTS_SENT;

  return new RestDataSource(url, fnError).GetOneByParam(Id, (res) => {
    if (res != null) {
      if (res.headers["jtitoken"] != null) {
        localStorage.setItem("jti-token", res.headers.jtitoken);
      }
      fn(res.data);
    }
  });
}

export function fetchNotificationEvents(Id, fn, fnError) {
  // alert("fetchNotificationEvents")
   var url = Global_var.BASEURL + Global_var.URL_NOTIFICATION_EVENTS;
   return new RestDataSource(url, fnError).GetOneByParam(Id, (res) => {
    if (res != null) {
      if (res.headers["jtitoken"] != null) {
        localStorage.setItem("jti-token", res.headers.jtitoken);
      }
      fn(res.data);
    }
  });
 }

function fetchNotificationDarft(Id, fn, fnError) {
  var url = Global_var.BASEURL + Global_var.URL_NOTIFICATION_EVENTS_DRAFT;
  //  var url = "http://172.16.16.99:9006" + Global_var.URL_NOTIFICATION_EVENTS_DRAFT;

  return new RestDataSource(url, fnError).GetOneByParam(Id, (res) => {
    if (res != null) {
      if (res.headers["jtitoken"] != null) {
        localStorage.setItem("jti-token", res.headers.jtitoken);
      }
      fn(res.data);
    }
  });
}

function fetchNotificationDarftById(Id, fn, fnError) {
  var url = Global_var.BASEURL + Global_var.URL_NOTIFICATION_EVENTS_DRAFT_BY_ID;
  //  var url = "http://172.16.16.99:9006" + Global_var.URL_NOTIFICATION_EVENTS_DRAFT_BY_ID;

  return new RestDataSource(url, fnError).GetOneByParam(Id, (res) => {
    if (res != null) {
      if (res.headers["jtitoken"] != null) {
        localStorage.setItem("jti-token", res.headers.jtitoken);
      }
      fn(res.data);
    }
  });
}

function fetchDistributorListData(data, fn) {
  LoginService.TokenUser(res => {
    localStorage.setItem("jwt-token", res.responseObject);
    var url = Global_var.BASEURL + Global_var.URL_DISTRIBUTION_LIST;
    //var url = "http://172.16.16.99:9006" + Global_var.URL_DISTRIBUTION_LIST;

    return new RestDataSource(url).Store(data, res => fn(res.data));
  });
}

function getStageList(fn, fnError) {
  var url = Global_var.BASEURL + Global_var.URL_STAGE_LIST;
  //var url = "http://172.16.16.99:9006" + Global_var.URL_STAGE_LIST;
  return new RestDataSource(url, fnError).GetData((res) => {
    if (res != null) {
      if (res.headers["jtitoken"] != null) {
        localStorage.setItem("jti-token", res.headers.jtitoken);
      }
      fn(res.data);
    }
  });
}

function getTemplate(Id, fn, fnError) {
  var url = Global_var.BASEURL + Global_var.URL_GETTEMPLATE;

  return new RestDataSource(url, fnError).Store(Id, (res) => {
    if (res != null) {
      if (res.headers["jtitoken"] != null) {
        localStorage.setItem("jti-token", res.headers.jtitoken);
      }
      fn(res.data);
    }
  });
}

function ComposeDataStore(data, fn) {
  LoginService.TokenUser(res => {
    localStorage.setItem("jwt-token", res.responseObject);
    var url = Global_var.BASEURL + Global_var.URL_NOTIFICATION_EVENTS_SAVE_DRAFT;

    return new RestDataSource(url).Store(data, res => fn(res.data));
  });
}

function ComposeSentStore(data, fn) {
  LoginService.TokenUser(res => {
    localStorage.setItem("jwt-token", res.responseObject);
    // var url =  "172.16.16.99:9006"+"/fc-learner/api/v1/mailNotify/sendMailSMSToLearner";
    var url = Global_var.BASEURL + Global_var.URL_NOTIFICATION_EVENTS_SEND_MAIL_TO_LEARNER;

    return new RestDataSource(url).Store(data, res => fn(res.data));
  });
}


function deleteDarft(data, fn) {
  LoginService.TokenUser(res => {
    localStorage.setItem("jwt-token", res.responseObject);
    // var url = Global_var.BASEURL + Global_var.URL_DISTRIBUTION_LIST;
    var url = Global_var.BASEURL + Global_var.URL_NOTIFICATION_EVENTS_DELETE_DRAFT;

    return new RestDataSource(url).Store(data, res => fn(res.data));
  });
}

function updateDraftData(data, fn) {
  LoginService.TokenUser(res => {
    localStorage.setItem("jwt-token", res.responseObject);
    // var url = "http://172.16.16.99:9006" + Global_var.URL_NOTIFICATION_UPDATE_DRAFT;
    var url = Global_var.BASEURL + Global_var.URL_NOTIFICATION_UPDATE_DRAFT;

    return new RestDataSource(url).Store(data, res => fn(res.data));
  });
}


function getCount(Id, fn, fnError) {
  var url = Global_var.BASEURL + Global_var.URL_INBOX_MESSAGE_COUNT;
  //  var url = "http://172.16.16.99:9006" + Global_var.URL_NOTIFICATION_EVENTS_DRAFT;

  return new RestDataSource(url, fnError).GetOneByParam(Id, (res) => {
    if (res != null) {
      if (res.headers["jtitoken"] != null) {
        localStorage.setItem("jti-token", res.headers.jtitoken);
      }
      fn(res.data);
    }
  });
}

function updateReadUnread(data, fn) {
  LoginService.TokenUser(res => {
    localStorage.setItem("jwt-token", res.responseObject);
    var url = Global_var.BASEURL + Global_var.URL_UPDATE_MESSAGE_COUNT;

    return new RestDataSource(url).Store(data, res => fn(res.data));
  });
}