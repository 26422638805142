import React, { Component } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Finxfooter from '../../../containers/finxfooter/finxfooter';
import ThankU from '../../../assets/images/Thank_u.png';
import { LoginService } from "../../../services/login.service";
import {
    error,
    success,
    warning,
    warningNotification,
    errorNotification,
    successNotification
} from "../../notification/notifications";
import $ from "jquery";

class RegisterSuccess extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userLoginName: "",
            userPassword: "",
            userLoginName1: "",
            userPassword1: "",
            isMaintainenceActivated: false,
            isMobile: false,
        };
    }

    handleBackClick = () => {
        let email = JSON.parse(localStorage.getItem('loginData'))["emailID"]
        let password = JSON.parse(localStorage.getItem('loginData'))["password"]
        this.setState({ userLoginName: email })
        this.setState({ userPassword: password })

        //login code start 
        if (!this.state.isMaintainenceActivated) {
            if (email !== '' && password !== '') {
                this.setState({ loading: true });
                const LoginDetails = {
                    userLoginName: email.trim(),
                    emailId: email.trim(),
                    userPassword: password.trim(),
                }
                console.log(LoginDetails)
                var logincon = [];
                this.setState({ loading: true });
                LoginService.SendUser(LoginDetails, this.state.isMobile, (res) => {

                    if (res.status === 'success') {
                        logincon = res;

                        this.setState({ credentials: logincon });
                        localStorage.setItem('eMail', logincon.responseObject.emailId);
                        localStorage.setItem('userid-Token', logincon.responseObject.partyId);
                        localStorage.setItem('userid', logincon.responseObject.userId);
                        localStorage.setItem('ciel-userid-Token', logincon.responseObject.partyId);
                        localStorage.setItem('roleId', logincon.responseObject.role[0].roleId);
                        localStorage.setItem("ischangepass", logincon.responseObject.forcePasswordChangeOnNextLoginFlag)
                        localStorage.setItem('roleName', logincon.responseObject.role[0].roleName);
                        localStorage.setItem('checkedtab', false);
                        localStorage.setItem('TokenName', 'jti');

                        this.setState(state => {
                            let oldstate = Object.assign({}, state);
                            localStorage.setItem('login', JSON.stringify(oldstate.credentials.responseObject));
                        })
                        LoginService.TokenUser(res => {
                            this.setState(state => {
                                let oldstate = Object.assign({}, state);
                                oldstate.credentials.responseObject.token = res.responseObject;
                                this.state = oldstate;
                                localStorage.setItem('jti-token', res.responseObject);
                                localStorage.setItem('login', JSON.stringify(oldstate.credentials.responseObject));
                            });

                        });
                        localStorage.setItem('TokenName', 'jwt');

                        LoginService.TokenUser(res => {
                            localStorage.setItem('jwt-token', res.responseObject);
                            this.setState({ loading: false });
                            if (this.state.credentials.responseObject["forcePasswordChangeOnNextLoginFlag"] === '1') {
                                localStorage.setItem('reload', 'true');
                                this.props.history.push('/changepassword', {
                                    forcePasswordChangeOnNextLoginFlag: this.state.credentials.responseObject["forcePasswordChangeOnNextLoginFlag"]
                                });
                            } else {
                                if (this.state.credentials.responseObject.role[0].roleId === 5) {
                                    // setTimeout(() => {
                                    // window.location.replace('/learnerDashboard');
                                    this.props.history.push('/learnerDashboard');
                                    //}, 5000);
                                    window.location.reload();
                                    localStorage.setItem('reload', 'true');

                                }
                                else if (this.state.credentials.responseObject.role[0].roleId === 6) {
                                    const queryString = window.location.search;
                                    const urlParams = new URLSearchParams(queryString);
                                    let page = urlParams.get('page')
                                    let UrllearnerPartyId = urlParams.get('learnerPartyId')
                                    localStorage.setItem("UrlPage", page)
                                    localStorage.setItem("UrllearnerPartyId", UrllearnerPartyId)
                                    if (page == "profile") {
                                        this.props.history.push('/userprofile');
                                    }
                                    else {
                                        this.props.history.push('/dashboard');
                                    }
                                    localStorage.setItem('reload', 'true');
                                }
                                else if (this.state.credentials.responseObject.role[0].roleId === 7) {
                                    $('.modal-backdrop').hide();
                                    this.props.history.push('/dashboard');
                                    localStorage.setItem('reload', 'true');
                                }
                                else if (this.state.credentials.responseObject.role[0].roleId === 2) {
                                    $('.modal-backdrop').hide();
                                    this.props.history.push('/admin-dashboard');
                                    localStorage.setItem('reload', 'true');
                                }
                            }
                            window.location.reload();
                        });
                    } else {
                        this.setState({ loading: false });

                        if (res.reasonCode === 'DM_02') {
                            let otpcounter = parseInt(this.state.OTPTokenCount) + 1;
                            this.setState({ OTPTokenCount: otpcounter });
                            if (this.state.OTPTokenCount >= 4) {
                                this.setState({ loading: false, isLogined: false, showResendOtp: true, showTimer: false });
                                warning('You have made 10 unsuccessful attempts to Login to ExpertMFD. Click on Resend OTP', warningNotification);
                            } else {
                                warning(res.reasonText + " count " + this.state.OTPTokenCount, warningNotification);
                            }
                        } else if (res.reasonCode === 'SM_11') {
                            warning(res.reasonText, { ...warningNotification, container: "top-center" });

                            window.location.href = '/';
                        } else if (res.reasonCode === 'SM-03') {
                            if (res.reasonText === 'InActive user') {
                                warning("Access unauthorised, Please contact expertmfd@ciel.co.in or 9922953305 for login support", warningNotification);
                            } else {
                                warning(res.reasonText, warningNotification);
                            }
                            this.setState({ loading: false, isLogined: false, showResendOtp: false, showTimer: false });
                        } else if (res.reasonCode === 'SM-07') {
                            this.setState({ loading: false, isLogined: true, showResendOtp: false, showTimer: false });
                        } else if (res.reasonCode === 'SM-12') {
                            this.setState({ loading: false, isLogined: false, showResendOtp: false, showTimer: false });
                            error('You have made 10 unsuccessful attempts to Login to ExpertMFD, Your account has been locked. Please contact nismsupport@ciel.co.in for login support.', errorNotification);
                        } else if (res.reasonCode === 'SM-13') {
                            this.setState({ loading: false, isLogined: false, showResendOtp: false, showTimer: false });
                            warning('Your Account has been Suspended. Please contact expertmfd@ciel.co.in or 9922953305 for login support', warningNotification);
                        }
                        else if (res.reasonCode === 'SM-04') {
                            this.setState({ loading: false, isLogined: false, showResendOtp: false, showTimer: false });
                            warning('Your Account has been Suspended. Please contact expertmfd@ciel.co.in or 9922953305 for login support', warningNotification);
                        }
                        else {
                            this.setState({ loading: false, isLogined: false, showResendOtp: false, showTimer: false });
                            warning(res.reasonText, warningNotification);
                        }
                    }
                })
            } else if (this.state.userLoginName1 !== '' && this.state.userPassword1 !== '') {
                this.setState({ loading: true });
                const LoginDetails = {
                    userLoginName: this.state.userLoginName1.trim(),
                    emailId: this.state.userLoginName1.trim(),
                    userPassword: this.state.userPassword1.trim(),
                }
                console.log(LoginDetails)
                var logincon = [];
                this.setState({ loading: true });
                LoginService.SendUser(LoginDetails, this.state.isMobile, (res) => {

                    if (res.status === 'success') {
                        logincon = res;

                        this.setState({ credentials: logincon });
                        localStorage.setItem('eMail', logincon.responseObject.emailId);
                        localStorage.setItem('userid-Token', logincon.responseObject.partyId);
                        localStorage.setItem('userid', logincon.responseObject.userId);
                        localStorage.setItem('ciel-userid-Token', logincon.responseObject.partyId);

                        localStorage.setItem('roleId', logincon.responseObject.role[0].roleId);
                        localStorage.setItem("ischangepass", logincon.responseObject.forcePasswordChangeOnNextLoginFlag)
                        localStorage.setItem('roleName', logincon.responseObject.role[0].roleName);
                        localStorage.setItem('checkedtab', false);
                        localStorage.setItem('TokenName', 'jti');

                        this.setState(state => {
                            let oldstate = Object.assign({}, state);
                            localStorage.setItem('login', JSON.stringify(oldstate.credentials.responseObject));
                        })
                        LoginService.TokenUser(res => {
                            this.setState(state => {
                                let oldstate = Object.assign({}, state);
                                oldstate.credentials.responseObject.token = res.responseObject;
                                this.state = oldstate;
                                localStorage.setItem('jti-token', res.responseObject);
                                localStorage.setItem('login', JSON.stringify(oldstate.credentials.responseObject));
                            });

                        });
                        localStorage.setItem('TokenName', 'jwt');

                        LoginService.TokenUser(res => {
                            localStorage.setItem('jwt-token', res.responseObject);
                            this.setState({ loading: false });
                            if (this.state.credentials.responseObject["forcePasswordChangeOnNextLoginFlag"] === '1') {
                                localStorage.setItem('reload', 'true');
                                this.props.history.push('/changepassword', {
                                    forcePasswordChangeOnNextLoginFlag: this.state.credentials.responseObject["forcePasswordChangeOnNextLoginFlag"]
                                });
                            } else {
                                if (this.state.credentials.responseObject.role[0].roleId === 5) {
                                    // setTimeout(() => {
                                    this.props.history.push('/learnerDashboard');
                                    // }, 5000);
                                    localStorage.setItem('reload', 'true');
                                }
                                else if (this.state.credentials.responseObject.role[0].roleId === 6) {
                                    $('.modal-backdrop').hide();
                                    const queryString = window.location.search;
                                    const urlParams = new URLSearchParams(queryString);
                                    let page = urlParams.get('page')
                                    let UrllearnerPartyId = urlParams.get('learnerPartyId')
                                    localStorage.setItem("UrlPage", page)
                                    localStorage.setItem("UrllearnerPartyId", UrllearnerPartyId)
                                    if (page == "profile") {
                                        this.props.history.push('/userprofile');
                                    }
                                    else {
                                        this.props.history.push('/dashboard');
                                    }
                                    localStorage.setItem('reload', 'true');
                                }
                                else if (this.state.credentials.responseObject.role[0].roleId === 7) {
                                    $('.modal-backdrop').hide();
                                    this.props.history.push('/dashboard');
                                    localStorage.setItem('reload', 'true');
                                }
                                else if (this.state.credentials.responseObject.role[0].roleId === 2) {
                                    $('.modal-backdrop').hide();
                                    this.props.history.push('/admin-dashboard');
                                    localStorage.setItem('reload', 'true');
                                }
                            }
                        });
                    } else {
                        this.setState({ loading: false });

                        if (res.reasonCode === 'DM_02') {
                            let otpcounter = parseInt(this.state.OTPTokenCount) + 1;
                            this.setState({ OTPTokenCount: otpcounter });
                            if (this.state.OTPTokenCount >= 4) {
                                this.setState({ loading: false, isLogined: false, showResendOtp: true, showTimer: false });
                                warning('You have made 10 unsuccessful attempts to Login to ExpertMFD. Click on Resend OTP', warningNotification);
                            } else {
                                warning(res.reasonText + " count " + this.state.OTPTokenCount, warningNotification);
                            }
                        } else if (res.reasonCode === 'SM_11') {
                            warning(res.reasonText, { ...warningNotification, container: "top-center" });

                            window.location.href = '/';
                        } else if (res.reasonCode === 'SM-03') {
                            if (res.reasonText === 'InActive user') {
                                warning("Access unauthorised, Please contact expertmfd@ciel.co.in or 9922953305 for login support", warningNotification);
                            } else {
                                warning(res.reasonText, warningNotification);
                            }
                            this.setState({ loading: false, isLogined: false, showResendOtp: false, showTimer: false });
                        } else if (res.reasonCode === 'SM-07') {
                            this.setState({ loading: false, isLogined: true, showResendOtp: false, showTimer: false });
                        } else if (res.reasonCode === 'SM-12') {
                            this.setState({ loading: false, isLogined: false, showResendOtp: false, showTimer: false });
                            error('You have made 10 unsuccessful attempts to Login to ExpertMFD, Your account has been locked. Please contact nismsupport@ciel.co.in for login support.', errorNotification);
                        } else if (res.reasonCode === 'SM-13') {
                            this.setState({ loading: false, isLogined: false, showResendOtp: false, showTimer: false });
                            warning('Your Account has been Suspended. Please contact expertmfd@ciel.co.in or 9922953305 for login support', warningNotification);
                        }
                        else if (res.reasonCode === 'SM-04') {
                            this.setState({ loading: false, isLogined: false, showResendOtp: false, showTimer: false });
                            warning('Your Account has been Suspended. Please contact expertmfd@ciel.co.in or 9922953305 for login support', warningNotification);
                        }
                        else {
                            this.setState({ loading: false, isLogined: false, showResendOtp: false, showTimer: false });
                            warning(res.reasonText, warningNotification);
                        }
                    }
                })
            } else {
                error("You must specify a valid credentials", errorNotification);
            }
        } else {
            error('System is unavailable on 2nd ( Monday ) March 2020 for upgradation starting from 7.00 PM to 10.00 PM. Inconvenience is regretted.Reach out to ExpertMFD Support Team for any clarifications.', errorNotification);
        }

    }
    render() {
        return (


            <React.Fragment>
                <CssBaseline />
                <div className="container mb-50p">
                    <div className="">
                        <div className="col-md-12 col-sm-12 col-xs-12 text-center" >
                            <img src={ThankU} className="img-responsive text-center margin-auto" width="300px" />
                            <div className="col-md-12 col-sm-12 col-xs-12">
                                <button type="submit" onClick={this.handleBackClick} className="btn-4 margin-auto" name="Submit">Ok</button>
                            </div>
                        </div>
                    </div>
                </div>

                <Finxfooter />
            </React.Fragment>


        );

    }
}

export default RegisterSuccess;