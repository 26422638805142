import { Global_var } from '../global/global_var';
import RestDataSource from '../services/restdatasource';
import { LoginService } from './login.service';

export const OrderService = {
    InsertOrder,
    RazorPayInsertOrder
};

function InsertOrder(OrderDetails, fn, fnError) {
    LoginService.TokenUser(res => {
        if (res.status === 'success') {
            localStorage.setItem('jwt-token', res.responseObject);
             var url = Global_var.BASEURL + Global_var.URL_ORDER_INSERT;
            //var mobilevalidat = JSON.stringify(ProductByName);
            return new RestDataSource(url, fnError).Store(OrderDetails, (res) => {
                
                if (res != null) {
                    if (res.headers["jtitoken"] != null) {
                        localStorage.setItem('jti-token', res.headers.jtitoken);
                    }
                    fn(res.data);
                }
            });
        }
    }, fnError);
    
    // LoginService.TokenUser(res => {
    //     localStorage.setItem('jwt-token', res.responseObject);
    //     if (res.status === 'success') {
    //         var url = Global_var.BASEURL + Global_var.URL_ORDER_INSERT;
    //         return new RestDataSource(url, fnError).Store(OrderDetails, (res) => fn(res));
    //     }
    // });
}

function RazorPayInsertOrder(OrderDetails, fn, fnError) {
    //alert("RazorPayInsertOrder")
    LoginService.TokenUser(res => {
        if (res.status === 'success') {
            localStorage.setItem('jwt-token', res.responseObject);
            var url = Global_var.BASEURL + Global_var.URL_ORDER_INSERT;
            //var url ="http://172.16.16.99:9003/fc-payment/api/v1/order";
            //var mobilevalidat = JSON.stringify(ProductByName);
            return new RestDataSource(url, fnError).Store(OrderDetails, (res) => {
                
                if (res != null) {
                    if (res.headers["jtitoken"] != null) {
                        localStorage.setItem('jti-token', res.headers.jtitoken);
                    }
                    fn(res.data);
                }
            });
        }
    }, fnError);
}



