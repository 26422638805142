import React from "react";
import { LoginService } from "../../services/login.service";
import { RegisterUserService } from "../../services/registration.service";
import UserProfileComplitiomBarOnLearnerDashboard from "./userProfileComplitionBarOnLearnerDashboard";
import { warning, warningNotification, successNotification, error, errorNotification } from "../notification/notifications";
import { PopUpPortal } from "../../containers/popups/PopUpPortal";
import ProfileImage from '../../assets/images/user_profile.jpg';
import Modal from "react-responsive-modal";
//import { logout } from "../../services/logout.service";
import { ReattemptTestService } from "../../services/reattemptTest.service";

class MyProfile extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      linkModalloading: false,
      loading: false,
      showApplyKYDARN: false,
      showWhyTheFillForm: false,
      AMCData: []
    }
  }
  componentDidMount() {

    ReattemptTestService.getAMCcount("learnerId=" + this.props.personnelProfileData.learnerID, res => {
      
      let title = ""
      res.responseListObject.map((e, i) => {
        let index = i + 1
        title = title + index + ". " + e.amcName
      })
      localStorage.setItem("AMCData", JSON.stringify(res.responseListObject))
      this.setState({
        AMCData: res.responseListObject,
        AMCNames: title
      })

      console.log(res.responseListObject)
    })
  }
  loginHandler = event => {
    event.preventDefault();
    this.setState({ loading: true });
    if (localStorage.getItem('login') !== null) {
      var data = {
        userName: JSON.parse(localStorage.getItem('login'))["userName"],
        userId: JSON.parse(localStorage.getItem('login'))["userId"]
      };

      LoginService.LogoutUser(data, false, res => {
        let subscribe = localStorage.getItem('nwesLetterEmailId')
        localStorage.clear();
        // if (subscribe) {
        //   localStorage.setItem('nwesLetterEmailId', 'SUBSCRIBED')
        // }
        //    logout.setSubscriber();
        if (res.status === 'success') {
          this.setState({ loading: false });
          //localStorage.clear();
          window.location.href = "/";
          //this.props.history.push('/');
        } else if (res.status === 'fail') {
          this.setState({ loading: false });
          if (res.reasonCode === 'SM_11') {
            window.location.href = '/';
          } else {
            error(res.reasonText, errorNotification);
          }

        }
      });
    }
    else {
      window.location.href = '/';
    }

  };
  changepassword = () => {
    this.props.history.push("/changepassword", {
      alerts: this.props.alertNotificationEvents,
      notification: this.props.notificationEvents
    })
  }
  KYDPaymentLink = () => {
    this.setState({ linkModalloading: true, showApplyKYDARN: true });
  }
  WhyTheFillForm = () => {
    this.setState({ linkModalloading: true, showWhyTheFillForm: true });
  }
  onCloseModal = () => {
    this.setState({ linkModalloading: false, showApplyKYDARN: false });
  };
  onWhyTheFillFormCloseModal = () => {
    this.setState({ linkModalloading: false, showWhyTheFillForm: false });
  };

  getPaymentLink = () => {

    var data = {
      // "learnerID":1,
      // "emailid":"himanshu.choudhary@extrapreneursindia.com",
      // "firstName":"himanshu",
      // "lastName":"choudhary"
    }

    var personalData = JSON.parse(localStorage.getItem("PersonnelData"))
    data["learnerID"] = personalData.responseObject.learnerID
    data["emailID"] = "himanshu.choudhary@extrapreneursindia.com"
    // data["emailID"] = personalData.responseObject.emailID
    data["firstName"] = personalData.responseObject.firstName
    data["lastName"] = personalData.responseObject.lastName
    data["mobileNo"] = personalData.responseObject.mobileNo

    RegisterUserService.paymentLink(data, res => {
      if (res.status === "success") {
        // alert("getPaymentLink Success")
        this.setState({ linkModalloading: false, showApplyKYDARN: false });
        warning("Email has been sent...", successNotification);
        // this.props.history.push("/my-inbox-create");
      } else if (res.status === "fail") {
        // this.setState({ loading: false });
        // error(res.reasonText, {
        //   ...errorNotification,
        //   container: "top-center"
        // });
        warning("Invalid email provided...", warningNotification);
        this.setState({ showApplyKYDARN: false });
        // else {
        //   if (res.status === "fail") {
        //     this.setState({ loading: false });
        //     success("Error", errorNotification);
        //     this.props.history.push("/learnerDashboard");
        //   }
      }
    });

  }


  userprofile = () => {
    this.props.history.push("/userprofile", {
      alertNotificationEvents: this.props.alertNotificationEvents,
      notificationEvents: this.props.notificationEvents
    })
  }

  render() {
    return (
      <React.Fragment>
        {this.state.loading && <PopUpPortal HidePopup={this.state.loading}
          IsVisible={this.state.loading} />}

        <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12">
          <h3 className="learner-title">My Profile</h3>
          <div className="profile-details">
            <div className="col-md-2 col-lg-2 col-sm-2 col-xs-2 padding-0">
              <div className="profile-icon">
                <img
                  src={this.props.personnelProfileData.profilePhotoURL ? this.props.personnelProfileData.profilePhotoURL : ProfileImage}
                  name="aboutme"
                  width="70"
                  height="70"
                  className="img-circle img-responsive"
                />
              </div>
            </div>

            <div className="col-md-10 col-lg-10 col-sm-10 col-xs-10 padding-0">
              <div className="profile-summary">
                <div className="col-md-12 col-sm-12">
                  <h3 className="user-name">
                    {this.props.personnelProfileData.firstName +
                      " " +
                      (this.props.personnelProfileData.lastName!==undefined?this.props.personnelProfileData.lastName:'')}
                  </h3>
                </div>
                <div className="col-md-12 col-sm-12">
                  <h5 className="user-contact-details">
                    {this.props.personnelProfileData.emailID}
                  </h5>
                  {/* </div>
                                <div className="col-md-6 col-lg-6 col-sm-12 col-xs-12 padding-0"> */}
                  <h5 className="user-contact-details">
                    {this.props.personnelProfileData.mobileNo}
                  </h5>
                </div>
                {/* <div className="col-md-12 col-sm-12">
                                    <h5 className="user-contact-details">Lorem Ipsum</h5>
                                </div> */}
              </div>
            </div>
            <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12">
              <div className="border-bottom"></div>
            </div>
            <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12">
              <div className="badge-certificate">
                <div className="col-md-6 col-lg-6 col-sm-6 col-xs-6">
                  <h3 className="badge-details" id="AMCCount">
                    <i className="fa fa-id-badge" aria-hidden="true"></i> AMC Count
                  </h3>
                  <span className="badge-number" onClick={e => {
                    window.$('#amcCount').modal('show')
                  }} title={this.state.AMCNames} >{this.state.AMCData !== null ? this.state.AMCData.length : 0}</span>
                </div>
                <div className="col-md-6 col-lg-6 col-sm-6 col-xs-6">
                  <h3 className="badge-details">
                    <i className="fa fa-certificate" aria-hidden="true"></i>{" "}
                    Certificates
                  </h3>
                  <span className="badge-number">0</span>
                </div>
              </div>
            </div>
            <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12">
              <div className="border-bottom"></div>
            </div>
            <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12">
              <div className="badge-certificate">
                <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                  <h3 className="badge-details">Profile Completion</h3>
                  <UserProfileComplitiomBarOnLearnerDashboard
                    personnelProfileData={this.props.personnelProfileData}
                    personnelAddressData={this.props.personnelAddressData}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12">
              <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 text-center mt-20">
                <a onClick={this.userprofile}>Update Profile</a>

              </div></div>
            {/* <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12">
              <div className="profile-link">
                <div className="col-md-4 col-lg-4 col-sm-4 col-xs-4 pr pl">
                  <a onClick={this.changepassword} >Change Password</a>
                
                </div>
                <div className="col-md-4 col-lg-4 col-sm-4 col-xs-4 pr">
                  <a onClick={this.userprofile}>My Profile</a>
                
                </div>
                <div className="col-md-3 col-lg-3 col-sm-3 col-xs-3 pr">
                  <a href="#" onClick={this.loginHandler}>Sign Out</a>
                </div>

                <div className="col-md-1 col-lg-1 col-sm-1 col-xs-1 pr">
                  <a onClick={this.WhyTheFillForm} ><i className="fa fa-question-circle-o" aria-hidden="true" style={{ fontSize: "24px" }}></i></a>
                </div>
              </div>
            </div> */}
            {/* <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12">
              <div className="profile-link">
                <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 text-center">
                  <a onClick={this.KYDPaymentLink} >Apply for KYD /ARN Process</a>
                
                </div>

              </div>
            </div> */}

            {/* <a href="/changepassword" {...this.props} >Change Password</a> */}
          </div>
          {this.state.linkModalloading && <PopUpPortal HidePopup={this.state.linkModalloading}
            IsVisible={this.state.linkModalloading} />}
          <Modal
            open={this.state.showApplyKYDARN}
            onClose={this.onCloseModal}
            center
          >
            <div
              className=""
              id="roadmap"
              tabindex="-1" role="dialog" aria-labelledby="modalLabel" data-keyboard="true" aria-hidden="true"
            >
              <div className="modal-dialog modal-md" role="document">
                <div class="modal-content">
                  <div className="modal-header">
                    <div class="scr-board">
                      <h2 >ExpertMFD </h2>
                      <div class="bottom-line"></div>
                    </div>
                    {/* <h4 className="modal-title">FinX Core Login</h4> */}
                  </div>
                  <div className="modal-body">
                    <div className="">
                      <div className="">
                        <div className="">
                          <div class="reset">
                            {/* <h3 className="message-text">Thanks for attempting FEAT. Your Feat score is available click on ProgressBar to view the score. Do you want to proceed for registering for CFx program</h3> */}
                            <h3 className="message-text">
                              Are you sure, do you want CIEL to apply for KYD Process,
                              on your behalf ?
                            </h3>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="modal-footer">
                    <button
                      type="button"
                      className="btn-5 mt-40 mr-20"
                      name="btnYes"
                      onClick={this.getPaymentLink}
                    >
                      Yes
                    </button>
                    <button
                      type="button"
                      className="btn-5 mt-40"
                      name="btnNo"
                      onClick={this.onCloseModal}
                    >
                      No
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Modal>






          <Modal
            open={this.state.showWhyTheFillForm}
            onClose={this.onWhyTheFillFormCloseModal}
            center
          >
            <div
              className=""
              id="roadmap"
              tabindex="-1" role="dialog" aria-labelledby="modalLabel" data-keyboard="true" aria-hidden="true"
            >
              <div className="modal-dialog modal-md" role="document">
                <div class="modal-content">
                  <div className="modal-header">
                    <div class="scr-board">
                      <h2>Why Fill The Form? </h2>
                      <div class="bottom-line"></div>
                    </div>
                    {/* <h4 className="modal-title">FinX Core Login</h4> */}
                  </div>
                  <div className="modal-body">
                    The Profile Form is a means of gathering your information which would help us in serving you more efficiently through your journey towards becoming a Mutual Fund Advisor.
                  </div>
                  <div class="modal-footer">
                    {/* <button
                      type="button"
                      className="btn-5 mt-40 mr-20"
                      name="btnYes"
                    onClick={this.getPaymentLink}
                    >
                      Yes
                    </button> */}
                    <button
                      type="button"
                      className="btn-5 mt-40"
                      name="btnNo"
                      onClick={this.onWhyTheFillFormCloseModal}
                    >
                      Ok
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Modal>
        </div>
      </React.Fragment>
    );
  }
}

export default MyProfile;
